/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from '@env/environment';
// list of all APIs used
export class API {
  public static BASE_URL = environment.base_url;
  public static INTERMEDIATE_URL = environment.intermediate_url;
  public static VERSION = '/v5';
  public static PATH = `/api${API.VERSION}`;

  // Driver details
  public static GET_TRIP_LIST = API.BASE_URL + API.PATH + '/acc/GetTripList';
  public static GET_DRIVER_LIST = API.BASE_URL + API.PATH + '/acc/GetDriverList';
  public static GET_FLEET_LEVEL_SNAPSHOT = API.BASE_URL + API.PATH + '/acc/GetFleetLevelSnapshot';
  public static GET_ASSET_REPORT_CONSOLIDATED = API.BASE_URL + API.PATH + '/acc/getLiveAssetList';


  // Trip search
  public static GET_UNIQUE_DRIVER_LIST = API.BASE_URL + API.PATH + '/acc/GetUniqueDriverList';
  public static GET_DRIVER_SNAPSHOT = API.BASE_URL + API.PATH + '/acc/GetDriverSnapShot';
  public static GET_DRIVER_TRIPLIST = API.BASE_URL + API.PATH + '/acc/GetDriverTripList';
  public static GET_DRIVING_INCIDENTS_PER_TRIP = API.BASE_URL + API.PATH + '/acc/GetDrivingIncidentsPerTrip';
  public static GET_VIOLATION_DISTRIBUTION = API.BASE_URL + API.PATH + '/acc/GetViolationDistribution';
  public static GET_DRIVER_SUMMARY_REPORT = API.BASE_URL + API.PATH + '/acc/GetDriverSummaryReport';

  // Trip details
  public static GET_TRIP_SNAPSHOT = API.BASE_URL + API.PATH + '/acc/GetTripSnapShot';
  public static GET_TRIP_EVENTS_VIDEO = API.BASE_URL + API.PATH + '/acc/GetTripEventsVideo';
  public static UPLOAD_CHALLENGES = API.BASE_URL + API.PATH + '/acc/doupload';

  // Settings
  public static GET_UNIQUE_DRIVERS_FOR_USER = API.BASE_URL + API.PATH + '/acc/GetUniqueDriversForaUser';
  public static GET_USER_CONFIG_FOR_DRIVER = API.BASE_URL + API.PATH + '/GetUserConfigForDriver';
  public static SET_USER_TAILGATING_CONFIG = API.BASE_URL + API.PATH + '/SetUserTGConfiguration';
  public static GET_GLOBAL_USER_CONFIG = API.BASE_URL + API.PATH + '/acc/GetGlobalUserConfiguration';
  public static SET_GLOBAL_USER_CONFIG = API.BASE_URL + API.PATH + '/SetGlobalUserConfiguration';

  // Gets the weights for fleet
  public static GET_WEIGHTSFOR_FLEET = API.BASE_URL + API.PATH + '/acc/GetWeightsForFleet';
  public static SET_WEIGHTSFOR_FLEET = API.BASE_URL + API.PATH + '/acc/SetWeightsForFleet';



  // Asset Management
  public static GET_ASSET_LIST_MIXED_FLEET = API.BASE_URL + API.PATH + '/GetAssetListMixedFleet';
  public static UPDATE_ASSET_DUTY_TYPES = API.BASE_URL + API.PATH + '/UpdateAssetDutyTypes';

  // Asset thresholds
  public static DUTY_TYPE_CONFIG = `${API.INTERMEDIATE_URL}/configuration`;


  // Package config
  public static REMOVE_ACCOUNT_CODE = API.BASE_URL + API.PATH + '/acc/RemoveAccountCode';
  public static GET_LIST_OF_ACCOUNTS = `${API.INTERMEDIATE_URL}/autocomplete/fleets`;
  public static GET_ASSET_LIST_OF_ALL_ORGS = API.BASE_URL + API.PATH + '/acc/GetAssetListForAllOrgs';
  public static REMOVE_ASSET = API.BASE_URL + API.PATH + '/acc/RemoveAsset';
  public static UPLOAD_ASSET_LIST = API.BASE_URL + '/api/uploadassetlist';
  public static UPDATE_ASSET_INFO = API.BASE_URL + '/api/v3' + '/UpdateAssetInfo';


  // Exception info
  public static GET_EXCEPTIONS = `${API.INTERMEDIATE_URL}/exceptions`;
  public static GET_VERSION_CRASH_COUNT = `${API.INTERMEDIATE_URL}/sdk-crash-count`;

  // Unconnected trips
  // public static GET_ANOMALOUS_TRIPS = API.BASE_URL + API.PATH + '/acc/GetAnomalousTrips';

  public static GET_ANOMALOUS_TRIPS = `${API.INTERMEDIATE_URL}/unconnected-trips`;

  public static AUTHENTICATE = `${API.INTERMEDIATE_URL}/authenticate`;

  public static ASSETS = `${API.INTERMEDIATE_URL}/assets`;

  public static FLEET_ASSETS = (fleetId) => `${API.INTERMEDIATE_URL}/assets?fleetId=${fleetId}`;

  public static ASSETS_UPDATE = (assetId) => `${API.INTERMEDIATE_URL}/assets/${assetId}`;

  // eslint-disable-next-line
  public static ASSETS_LIST = (fleetId, limit = 20, offset = 0) => `${API.INTERMEDIATE_URL}/assets?fleetId=${fleetId}&limit=${limit}&offset=${offset}`;

  public static FLEET_ASSET_UPDATE = (fleetId, assetId) => `${API.INTERMEDIATE_URL}/assets/${assetId}?fleetId=${fleetId}`;

  public static ADD_ACCOUNT_CODE = (fleetId) =>  `${API.INTERMEDIATE_URL}/fleets/${fleetId}`;
}
