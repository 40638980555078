<div class="slider-container">
  <ng-container *ngIf="(dataSourceInternal|| []).length <= 0 && !isFullLoading; then (noDataTemplate || defaultNoDatatemplate)">
  </ng-container>

  <ngx-slick-carousel class="carousel"
    #slickModal="slick-carousel"
    [config]="config"
    (afterChange)="afterChange($event)"
    (breakpoint)="onBreak($event)"
    >

    <ng-container *ngIf="!isFullLoading; else loading" >
      <div ngxSlickItem *ngFor="let slide of dataSourceInternal; index as i; trackBy: trackBy" class="slide">
        <ng-container *ngTemplateOutlet="itemTemplate || defaultTemplate; context: { $implicit: slide, index: i }"></ng-container>
      </div>

      <ng-container *ngIf="isPartialLoading" >
        <div ngxSlickItem *ngFor="let slide of ['a', 'b', 'c', 'd']; trackBy: trackBy" class="slide">
          <ng-container *ngTemplateOutlet="skeleton"></ng-container>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div ngxSlickItem *ngFor="let slide of ['a', 'b', 'c', 'd', 'e', 'f']; trackBy: trackBy" class="slide">
        <ng-container *ngTemplateOutlet="skeleton"></ng-container>
      </div>
    </ng-template>

  </ngx-slick-carousel>

  <button class="slide-button left"
    [hidden]="currentSlideIndex === 0 || dataSourceInternal?.length <= 0"
    (click)="slickModal.slickPrev()"
  >
    <img class="chevron-icon" src="assets/images/left-chevron.png" alt="">
  </button>

  <button class="slide-button right"
    [hidden]="currentSlideIndex + scrollSize >= totalSize || dataSourceInternal?.length <= 0"
    (click)="onButtonClick(slickModal.slickNext, slickModal)">
    <img class="chevron-icon" src="assets/images/right-chevron.png" alt="">
  </button>
</div>

<ng-template #defaultTemplate let-slide>
  <div class="default-view" >
    No default template for {{ slide }}
  </div>
</ng-template>

<ng-template #skeleton >
  <ng-container *ngTemplateOutlet="skeletonTemplate || defaultSkeletonTemplate"></ng-container>

  <ng-template #defaultSkeletonTemplate >
    <ngx-skeleton-loader
      appearance="line"
      animation="progress-dark"
      [theme]="{
        'margin-bottom': '0px',
        'background': '#D0D3D5 no-repeat',
        'height': '500px',
        'width': '440px',
        'margin-right': '20px'
      }"
    ></ngx-skeleton-loader>
  </ng-template>
</ng-template>

<ng-template #defaultNoDatatemplate >
  No data
</ng-template>
