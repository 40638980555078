<div class="row scoreboard-heading" >
    <div class="col-md-12 p-0" data-test="driver-scorecard-header-label">
        <div [ngSwitch]="showSortList">
            <span class="heading-left" >Drivers Scorecards</span>
            <div *ngSwitchCase="'enable'" class= "selectedlist" (click)="showList = !showList">
                {{ sortListBy }}  <span class="arrow-down" ></span>
            </div>
            <div *ngSwitchCase="'disable'" class= "selectedlist disabled-selectedList">
                {{ sortListBy }}  <span class="arrow-down" ></span>
            </div>
            <div class="show-list" *ngIf="showList">
                <div class="category {{sortListBy === 'Low-High Score' ? 'active-list' : ''}}"  (click)="selectScore('low-high')" >
                    Low-High Score
                </div>
                <div class="category {{sortListBy === 'High-Low Score' ? 'active-list' : ''}}" (click)="selectScore('high-low')">
                    High-Low Score
                </div>
                <div [class.disable]="drivercardtotal !== tripList.length"  class="category {{sortListBy === 'A-Z' ? 'active-list' : ''}}" (click)="selectScore('a-z')">
                    A-Z
                </div>
                <div [class.disable]="drivercardtotal !== tripList.length" class="category {{sortListBy === 'Z-A' ? 'active-list' : ''}}" (click)="selectScore('z-a')">
                    Z-A
                </div>
             </div>
            <div class="btn-group" data-test="driver-scorecards-table-view-button" role="group" aria-label="Basic example" (click)="toggleTable()" *ngIf="tripList.length !== 0, else showDisabledButton">
                    <span class="label" >Table View</span>
            </div>
            <ng-template #showDisabledButton>
                <div class="btn-group disabledButton" data-test="driver-scorecards-table-view-button" role="group" aria-label="Basic example">
                    <span class="label" >Table View</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>
<div *ngIf="(!isShowDummySkeleton && tripList.length >= 0 && show !== 'error'), else skeletonDriverScorecardsLoading">
    <div *ngIf="tripList.length > 0, else showNoDataAvailable">
        <div class="scoreboard-content" data-test="driver-scorecards-area" *ngIf="tripList">
            <div class="row scoreboard" data-test="driver-scorecards">
                <div class=" pos-r" *ngIf="prevarrow && tripList.length > 0">
                    <div class="carousel-controls testimonial-carousel-controls">
                            <button class="control prev" data-test="left-pagination-button"  (click)="prev()">
                                <img class="chevron-icon" src="assets/images/left-chevron.png" alt="">
                            </button>
                    </div>
                </div>
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (beforeChange)="afterChange($event)">
                    <div ngxSlickItem class="slide scoreboard-card" *ngFor="let driver of tripList; index as i">
                        <div *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonNavigateLoading">
                            <app-driver-score-item
                                [tripList]="tripList"
                                [driverIndex]="i"
                                [lineOption]="lineOptions[i]"
                                [showRange]="showRange"
                                [range]="range"
                                [scoreTrend]="driverScoreTrendList[i]"
                                [numberOfDays]="numberofdays"
                                [isShowSkeletonLoading]="false"
                            ></app-driver-score-item>
                        </div>
                    </div>
                </ngx-slick-carousel>
                <div class=" pos-r" *ngIf="nextarrow && tripList.length > 0">
                    <div class="carousel-controls testimonial-carousel-controls">
                        <button class="control next" data-test="right-pagination-button" (click)="next()">
                            <img class="chevron-icon" src="assets/images/right-chevron.png" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #skeletonDriverScorecardsLoading>
    <div *ngIf="dummyTripList.length > 0">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem class="slide scoreboard-card" *ngFor="let driver of dummyTripList; index as i">
                <app-driver-score-item
                    [isShowSkeletonLoading]="true"
                    ></app-driver-score-item>
            </div>
        </ngx-slick-carousel>
    </div>
</ng-template>

<ng-template #skeletonNavigateLoading>
    <app-driver-score-item
        [tripList]="tripList" [isShowSkeletonLoading]="true"
    ></app-driver-score-item>
</ng-template>

<ng-template #showNoDataAvailable>
    <!-- empty -->
    <div class="no-data-available-block">
        <div class="no-data-available-content">
            <span>
                No data available
            </span>
        </div>
    </div>
</ng-template>
