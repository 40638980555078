import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarMsg } from '../snackbar-custom-message/snackbar-custom-message.component';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-stackable-custom-snackbar',
  templateUrl: './stackable-custom-snackbar.component.html',
  styleUrls: ['./stackable-custom-snackbar.component.scss'],
})
export class StackableCustomSnackbarComponent implements OnInit, OnDestroy {
  public msgStack: SnackBarMsg[] = [];
  private _intervalId: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: number,
    private _snackbarService: SnackbarService
  ) {
    this._snackbarService.snackbarMsger$
      .pipe(
        filter(
          (msg) =>
            !this.msgStack.some((msgInfo) => msgInfo.textMsg === msg.textMsg)
        )
      )
      .subscribe((res) => {
        if (res) {
          if (this.msgStack.length === 0) {
            this.msgStack.push(res);
            this._intervalId = setInterval((_) => {
              this.msgStack.shift();
              if (this.msgStack.length === 0) {
                this.closeSnackbar();
              }
            }, data);
          } else {
            this.msgStack.push(res);
          }
        }
      });
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    if (this._intervalId) {
      this.msgStack = [];
      clearInterval(this._intervalId);
    }
  }

  public closeSnackbar() {
    clearInterval(this._intervalId);
    this._intervalId = null;
    this._snackbarService.closeCurrentSnackbar();
  }

  public execLinkAction(data: SnackBarMsg, index: number) {
    this.msgStack = this.msgStack.filter((_val, idx) => idx !== index);
    if (data.linkAction && typeof data.linkAction === 'function') {
      data.linkAction();
      this.closeSnackbar();
    }
  }
}
