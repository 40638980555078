export class ExceptionInfo {
  constructor(
    public date1: string,
    public date2: string,
    public dbname: string
  ) {}
}

export class ExceptionsParams {
  public startDate: string;
  public endDate: string;
  public limit?: number;
  public offset?: number;

  constructor({
    startDate,
    endDate,
    limit = 10,
    offset = 0,
  }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.limit = limit;
    this.offset = offset;
  }
}

export interface Exception {
  fleetId: string;
  driverId: string;
  eventType: string;
  timestampUTC: string;
  timestamp: string;
  assetId: string;
  device: {
    imei: string;
    model: string;
  };
  sdk: {
    version: string;
    versionCode: string;
  };
  logFile: string;
  threadName: string;
}

export class VersionCrashCount {
  constructor(public dbname: string) {}
}
