import { Component, OnDestroy, OnInit, Input } from '@angular/core';

import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from './../../../services/zcfleet.service';

import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { LastNameSortPipe } from '@modules/shared/pipes/last-name-sort/last-name-sort.pipe';

import { DefaultInputModelV2, ErrorHandling } from './../../../dashboard3.models';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Data } from '@modules/dashboard3/services/data.service';
import { GoogleTagManagerService, ToggleTableViewStatus } from '@app-core/services/google-tag-manager.service';
import { StorageService } from '@app-core/services/storage.service';
@Component({
  selector: 'app-fleet-all-driver-table',
  templateUrl: './fleet-all-driver-table.component.html',
  styleUrls: ['./fleet-all-driver-table.component.scss'],
  providers: [ArraySortPipe, LastNameSortPipe],
})
export class FleetAllDriverTableComponent implements OnInit , OnDestroy {

    @Input() public filterOptions: any;

    /** Flag for toggleing Driver table */
    public tableHidden = true;
    public filterChanged = true;

    public driverListPage = [];

    public reset = false;

    public show = 'loading';

    public limit = 20;
    public sortBy = 'driverName';
    public sort = 'asc';
    public modalParams;
    public modalSubscription;
    public totalDrivers = 0;
    public homeLocationList = [];
    public errorMessage = 'Something went wrong';
    public showRetryButton = true;
    public isShowNextPageSpinner = false;

    // Behaviour subject to pass data of each page into the paginator component
    private _list = new BehaviorSubject([]);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public listData = this._list.asObservable();

    private _offset = 0;

    constructor(
        private _router: Router,
        private _dateService: DateService,
        private _zcfleet: ZCFleetService,
        private _data: Data,
        private _gtmService: GoogleTagManagerService,
        private _storageService: StorageService
    ) {
      this.modalSubscription =  this._zcfleet.commonTableModal.subscribe(params => {
        this.modalParams =  params;
        this.refreshList();
      });
    }


    public ngOnInit() {
      this.show = 'loading';
      this.refreshList();
    }

    public ngOnchanges() {
      this.refreshList();
    }

    public ngOnDestroy() {
      this.modalSubscription.unsubscribe();
    }

    /**
     * @description: function to refresh the driver list table
     * @param:
     */
    public refreshList() {
      // set the offset to 0 and set reset flag to true
      this._offset = 0;
      this.reset = true;

      this.isShowNextPageSpinner = true;
      this.getDriversList()
        .then((res: any) => {
          this.show = res.length !== 0 ? 'data' : 'empty';
          this.isShowNextPageSpinner = false;
          this._list.next(res);
        })
        .catch(err => {
          this._getErrorMessage(err);
          this.show = 'error';
          this.isShowNextPageSpinner = false;
        });
    }

    public getMoreData(page) {
      // update limit and offset values and call the API to get the paginated
      // values
      ({ limit: this.limit, offset: this._offset } = page);
      this.show = 'data';
      this.getDriversList()
        .then((res: any) => {
          this.isShowNextPageSpinner = false;
          this._list.next(res);
        })
        .catch(err => {
          this._getErrorMessage(err);
          this.show = 'error';
          this.isShowNextPageSpinner = false;
        });
    }

    // Driver table
    public pageChange(e) {
      setTimeout(() => {
        this.driverListPage = e.list;
      }, 100);
    }

    public getAbsolute(val) {
      return Math.abs(val);
    }

    /**
     * @description: function to show and hide table
     * @param:
     */
    public toggleTable() {
      this.tableHidden = !this.tableHidden;
      const tableStatus = this.tableHidden ? ToggleTableViewStatus.CLOSE : ToggleTableViewStatus.OPEN;
      this._gtmService.toogleDriverDetailsTable(tableStatus);
    }

    public get driverCount() {
      return this.filterChanged ? '' : `(${this.totalDrivers})`;
    }

    /**
     * @description: function to navigate to particular driver page
     * @param: driver details with driver name and driver id
     */
    public gotoDriverDetail(driver) {
      if (driver && driver.driverId) {
        // Set driver name
        // this._data.driverName = driver.driverName;
        this._router.navigate(['/driver-detail'], {
          queryParams: {
            driverId: driver.driverId,
            driverName: driver.driverName,
          },
        });
      }
    }

    /**
     * @description: function to check whether a number is integer or not
     * @param: number
     */
    public checkIfInteger(num?) {
      if (Number.isInteger(num)) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * @description: function to get driver list
     * @param: date range, dutytype, score values
     */
    public getDriversList() {
      const { days, dutyType, minScore, maxScore } = this.filterOptions;
      let date = { from : null, to : null}  ;
      if ( days === 2) {
        date.from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
        date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
        if (this._dateService.customStartdate === undefined || this._dateService.customEndDate === undefined ) {
          date = this._data.customRange.data;
          this._dateService.customStartdate = this._data.customRange.data.from;
          this._dateService.customEndDate = this._data.customRange.data.to;
        }
      } else {
        date = this._dateService.getDateRangeInISO(days);
      }
      this._zcfleet.sort = this.sort;
      this._zcfleet.sortBy = this.sortBy;

      this.homeLocationList = this._storageService.getStorageValue('HOME_LOCATION_ABV');

      const params = new DefaultInputModelV2(
        date.from,
        date.to,
        dutyType,
        minScore,
        maxScore,
        this.limit,
        this._offset,
        this.sort,
        this.sortBy,
        this.homeLocationList
      );
      const OPTIONS = {
        params,
      };

      return new Promise((resolve, reject) => {
        this.isShowNextPageSpinner = true;
        this._zcfleet.getDriverListV2(OPTIONS).subscribe(
          res => {
            this.isShowNextPageSpinner = false;
            this.totalDrivers = res.totalCount;
            resolve(res.list);
          },
          err => {
            reject(err);
            this._getErrorMessage(err);
            this.show = 'error';
            this.isShowNextPageSpinner = false;
          }
        );
      });
    }

    private _getErrorMessage(err: ErrorHandling) {
      console.error(`Received Error: ${err}`);
      const errorHanding = this._zcfleet.getErrorMessage(err);
      this.errorMessage = errorHanding.message;
      this.showRetryButton = errorHanding.showRetryButton;
    }
}
