import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@app-core/services/loading.service';

const LIST_PATHNAME_DISABLE = [
  '/dashboard',
  '/incident-response-center',
];

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public loading = false;

  private _loadingCount = 0;

  public get isSpinnerEnabled() {
    return !LIST_PATHNAME_DISABLE.includes(window.location.pathname);
  }

  constructor(private _loadingService: LoadingService) {}

  public ngOnInit() {
    this.initLoading();
  }

  public initLoading() {
    this._loadingService.loader().subscribe((value: boolean) => {
      if (value) {
        this._loadingCount++;
        if (!this.loading) {
          this.loading = value && this.isSpinnerEnabled;
        }
      } else {
        if (this._loadingCount) {
          this._loadingCount--;
        }
        if (this._loadingCount === 0) {
          this.loading = value && this.isSpinnerEnabled;
        }
      }
    });
    this._loadingService.loaderReset$.subscribe((value) => {
      if (value) {
        this.resetLoading();
      }
    });
  }

  public resetLoading() {
    this._loadingCount = 0;
    this.loading = false;
  }
}
