export const ACCOUNT_ADDED_SUCCESS = 'Added new account';
export const ACCOUNT_ALREADY_EXISTS = 'User found - unable to add';
export const ASSET_IDS_ALREADY_PRESENT =
  'Asset IDs already present in database';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const PackageOptions = [
  {
    name: 'Off',
    value: 'Off',
  },
  {
    name: 'Base',
    value: 'Base',
  },
  {
    name: 'Base+Video',
    value: 'Base+Video',
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SortOption = [
  {
    name: 'Organization ID',
    value: 'orgID',
  },
  {
    name: 'Asset ID',
    value: 'assetID',
  },
  {
    name: 'Asset Number',
    value: 'assetNumber',
  },
  {
    name: 'GPSID',
    value: 'gpsID',
  },
];
