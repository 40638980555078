<div class="leadboard-card" (click)="clicked()">
    <div class="leadboard-card-head">
        <div class="driver-name">{{data?.driverName}}</div>
        <div class="top-event" [class.hidden]="noIncident">{{data?.topIncidentType}}</div>
    </div>
    <div class="leadboard-card-body">
        <div class="left">
            <div class="average-score" [class.text-green]="data?.v2Score >= 85" [class.text-orange]="data?.v2Score > 69 && data?.v2Score <= 84"
                [class.text-red]="data?.v2Score <= 69">
                {{data?.v2Score}}
            </div>
            <div class="score-label">Average Score</div>
        </div>
        <div class="right">
            <div *ngIf="!noIncident">
                <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateChart"
                    [oneToOne]="true">
                </highcharts-chart>
                <div class="chart-score">
                    <span class="pull-left">{{minScore}}</span>
                    <span>{{dateRangeText}}</span>
                    <span class="pull-right">{{maxScore}}</span>
                </div>
            </div>
            <div class="event" *ngIf="noIncident">
                <span>{{data?.topIncidentType}}</span>
            </div>
        </div>
    </div>
    <div class="view-link">
        <span>View Details</span>
    </div>
</div>
