import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface ColDescription {
  colKey: string;
  colDisplayName: string;
  sortable: boolean;
  type:
    | 'text'
    | 'bold-text'
    | 'internal-link'
    | 'external-link'
    | 'date-time'
    | 'custom';
  async?: boolean;
  getter: (any) => any;
  sortKey?: string;
}

export interface PaginationData<T> {
  data: T[];
  totalItems: number;
  totalPage: number;
  perPage: number;
  pageIndex: number;
  hasError?: boolean;
  selectFirstRow?: boolean;
  selectCustomRow?: number;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed, void',
        style({ height: '0px', minHeight: '0'})
      ),
      state('expanded', style({ height: '*'})),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
      transition(
        'expanded <=> void',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) public sorter: MatSort;

  @Input() public displayedColumns: string[];

  @Input() public dataSource$: Observable<PaginationData<any>>;
  @Input() public sortOrderChange$: Observable<[string, 'desc' | 'asc']>;

  @Input() public customTemplates: Record<string, TemplateRef<any>> = {};

  @Input() public colDescriptions: ColDescription[];

  @Input() public sortHeader = [];

  @Input() public isShowFirstLastButtons = true;

  @Input() public isDisabledClick = false;

  @Input() public isHidePageSize = true;

  @Input() public customPageSizeOptions = [5, 10, 15];

  @Input() public customItemsPerPageLabel = null;
  @Input() public isUseRipple = false;
  @Input() public rippleTemplate: TemplateRef<any>;
  @Input() public rippleTogglerTemplate: TemplateRef<any>;
  @Input() public allowMultipleRowExpand = false;
  @Input() public disableRowClickExpand = false;
  @Input() public notLoadingState$: Observable<boolean>;
  @Input() public loadingDummyData: any;

  @Output() public rowSelected: EventEmitter<any> = new EventEmitter();

  @Output() public pageChange: EventEmitter<any> = new EventEmitter();

  @Output() public sortChange: EventEmitter<[string, SortDirection]> = new EventEmitter();

  public initialPageSize = 10;
  public isEmptyData = false;
  public disablePaginator = false;
  public selection = new SelectionModel<any>(false, []);
  public expandedData: [number, any][] = [];
  constructor(private _matPaginatorIntl: MatPaginatorIntl) {}

  @Input() public getUniqueKey: (element: any) => string = (element: any) => JSON.stringify(element);

  public ngOnInit(): void {
    this.initDataSource();
  }

  /**
   * @description check if list data value included table current selected value
   * @param data table rows data
   */
  public checkSelection(data: any[]) {
    const result = data.find(row =>
      this.getUniqueKey(row) === this.getUniqueKey(this.selection.selected[0])
    );
    if (result) {
      this.selection.select(result);
    }
  }

  public ngAfterViewInit(): void {
    this.sortOrderChange$
      .pipe(
        tap((res) => {
          const getSortKey = (colDes: ColDescription) => (colDes.sortKey || colDes.colKey);
          const sortColDes = this.colDescriptions.find(item => item.colKey === res[0]);
          const sortKey = getSortKey(sortColDes);
          // must remove current sort header status before apply new sort
          this.sorter.sort({ id: null, start: 'desc', disableClear: false });
          this.sorter.sort({ id: res[0], start: res[1], disableClear: false });
          this.sortChange.emit([sortKey, res[1]]);
        })
      )
      .subscribe();
  }

  public initDataSource(): void {
    this.dataSource$.pipe(
      tap(res => {
        if (res && res['data']) {
          this.isEmptyData =
          res.data.filter((item) => Object.keys(item).length !== 0).length <= 0;
          this.disablePaginator = this.isEmptyData;
          /** Select and highlight the first row of the table */
          if (res.selectFirstRow && res.pageIndex === 0) {
            this.selection.select(res.data[0]);
          } else if (res.selectCustomRow > -1) {
            // select and highlight the custom row of the table if any
            this.selection.select(res.data[res.selectCustomRow]);
          } else {
            this.checkSelection(res.data);
          }

          this._matPaginatorIntl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
          ) => {
            if (length === 0 || pageSize === 0) {
              return `0 of ${length}`;
            }

            length = Math.max(length, 0);

            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex =
              startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;

            return `${startIndex + 1} - ${endIndex} of ${length}`;
          };

          if (this.customItemsPerPageLabel !== null) {
            this._matPaginatorIntl.itemsPerPageLabel = this.customItemsPerPageLabel;
          };

          res.data = this.buildSimilarRowEmpty(res.perPage, res.data);
          // Reset expanded state of table row
          this.expandedData = [];
        }
      })
    ).subscribe();
  }

  public onPageChange(event: PageEvent) {
    this.expandedData = []; // reset expanded state when pagination happen
    this.pageChange.emit({
      pageIndex: event.pageIndex, // index start is 0
      pageSize: event.pageSize,
    });
  }

  public onSortChange(keyColumn: string, sortKey: string) {
    const finalSortKey = sortKey || keyColumn;
    if (this.sortHeader.includes(keyColumn)){
      this.sortChange.emit([finalSortKey, this.sorter.direction]);
    }
  }

  public getRecordRowSelect(element, index: number) {
    this.selection.select(element);
    this.rowSelected.emit(Object.assign({}, { ...element, index }));
  }

  public checkEmpty(element): boolean {
    return Object.entries(element).length === 0;
  }

  public buildSimilarRowEmpty(initialPageSize: number, data?: any[]): Record <string ,unknown>[] {
    let arr = [];
    let rowNumber = initialPageSize;
    if (data.length > 0) {
      /** Calculate the number of blank rows, will be the result of perPage minus the length item */
      rowNumber = initialPageSize - data.length > 0 ? initialPageSize - data.length : 0;
      arr = [...data];
    }
    while (rowNumber > 0) {
      arr.push(Object.create(null));
      rowNumber -= 1;
    }
    return arr;
  }
  /**
   * Handle open/close expandable row
   *
   * @param rowData row data
   * @param rowIndex row index
   * @param event arrow toggler click event
   * @memberof TableComponent
   */
  public onClickExpand(rowData: any, rowIndex: number, event?: Event): void {
    if (event) {
      event.stopPropagation();
    } else if (this.disableRowClickExpand) {
      return;
    }
    const idx = this.expandedData.findIndex((item) => item[0] === rowIndex);
    if (idx !== -1) {
      this.expandedData.splice(idx, 1);
    } else if (this.allowMultipleRowExpand) {
      this.expandedData.push([rowIndex, rowData]);
    } else {
      this.expandedData = [[rowIndex, rowData]];
    }
    this.getRecordRowSelect(rowData, rowIndex);
  }

  /**
   * Check if the current row is expanded or not
   *
   * @param rowIndex row index
   * @return true if current row is expanded
   * @memberof TableComponent
   */
  public isExpandedRow(rowIndex: number): boolean {
    const idx = this.expandedData.findIndex((item) => item[0] === rowIndex);
    return idx !== -1;
  }
}
