import { Component, Input, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as Highcharts from 'highcharts';

import { CHART_OPTION } from '@app-core/constants/constants';
import { calcSumTotalIncidents } from '@modules/shared/utils';

interface DriverStat {
  driverLocation: string;
  driverName: string;
  averageScore: number;
  changeInScore: number;
  scorechange: string;
  rank: number;
  minScore: number;
  maxScore: number;
}

interface DriverState {
  driverState: any;
  driverName: string;
  driverLocation: string;
}

const MARKER = {
  enabled: false,
  radius: 5,
};

@Component({
  selector: 'app-division-graph',
  templateUrl: './division-graph.component.html',
  styleUrls: ['./division-graph.component.scss'],
})
export class DivisionGraphComponent implements OnInit {
  @Input() public set driverStat$(value: Observable<any>) {
    this.internalDriverStat$ = value.pipe(map((res) => this.processData(res)));
  }

  @Input() public set reload$(value: Observable<boolean>) {
    const driverStat = merge(value, this.internalDriverStat$);
    this.notLoadingState$ = driverStat.pipe(map((res) => res !== true));
  }

  @Input() public numberofdays: number;
  @Input() public isCustomDateRange: boolean;

  public minScore: number;
  public maxScore: number;
  public showGraph: boolean;
  public highcharts = Highcharts;
  public chartOptions = CHART_OPTION;
  public updateChart = false;

  public internalDriverStat$: Observable<DriverStat>;

  public notLoadingState$: Observable<boolean>;

  constructor() {}

  public ngOnInit(): void {}

  public processData(data: DriverState): DriverStat {
    const result: DriverStat = {
      driverLocation: '',
      driverName: '',
      averageScore: 0,
      changeInScore: 0,
      scorechange: '',
      rank: 0,
      minScore: 0,
      maxScore: 0,
    };
    result.driverName = data.driverName;
    result.driverLocation = data.driverLocation;
    const {
      change,
      rank,
      score,
      trend,
      tripdetails: tripDetails = {},
    } = data.driverState;
    result.rank = rank;
    result.averageScore = score;

    if (tripDetails && tripDetails.eventCount) {
      tripDetails.eventCount['total'] = calcSumTotalIncidents(
        data.driverState.tripdetails.eventCount
      );
    }
    if (change > 0) {
      result.scorechange = '+' + change.toString();
    } else {
      result.scorechange =
        change !== null && change !== undefined ? change.toString() : null;
    }
    result.changeInScore = change;
    if (!Number.isInteger(change)) {
      result.changeInScore = 200; // assigning a number out of range i.e -100 to 100
    }
    if (trend && trend.length) {
      this._generateGraph(trend);
      result.minScore = this.minScore;
      result.maxScore = this.maxScore;
    } else {
      this.showGraph = false;
    }
    return result;
  }

  private _generateGraph(list) {
    try {
      this.minScore = +list[0].v2Score;
      this.maxScore = +list[list.length - 1].v2Score;
      const totalScore = list.reduce((prev, cur) => prev + cur.v2Score, 0);
      let averageScore = +(+totalScore / list.length).toFixed(2);
      averageScore = +(averageScore / 100).toFixed(2);

      const trendScores = list.map((trend, index) => {
        if (index === list.length - 1) {
          const y = +(trend.v2Score / 100).toFixed(2);
          let marker;
          if (y < averageScore) {
            marker = { ...MARKER, fillColor: 'red', lineColor: 'red' };
          } else {
            marker = { ...MARKER, fillColor: '#7dbb7a', lineColor: '#7dbb7a' };
          }

          return { y, marker };
        } else {
          return +(trend.v2Score / 100).toFixed(2);
        }
      });
      this.updateChart = true;
      this.chartOptions.yAxis.plotLines[0].value = averageScore;
      this.chartOptions.series[0].data = trendScores;
      this.chartOptions.series[0].color = '#2d8ed9';
      this.showGraph = true;
    } catch (error) {
      console.log('Error while forming graph data', error);
      this.showGraph = false;
    }
  }
}
