import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export type ChannelGroupType = 'AssetName' | 'DriverName';

/**
 * Channel object used to communicate with each other portions of the
 * application.
 *
 * @example
 *  const channel: ChannelGroup = ...
 *  channel.createChannel('mydata');
 *  channel.getChannelById('mydata').subscribe(obj => console.log(obj.message));
 *  const result = channel.notify('mydata', { message: 'Hello World' })
 *  if (!result) { throw new Error('Cannot notify on channel' )}
 *
 */
export interface ChannelGroup {
  /**
   * Create a channel with a specific id if not present.
   *
   * @param channelId ID of the channel
   * @return {*} true if not present, otherwise false
   */
  createChannel(channelId: string): boolean;

  /**
   * Return a channel with a specific id.
   * This method will try to create a new channel if it's existed.
   *
   * @template T
   * @param channelId ID of the channel
   * @return {*} An `Observable` object to receive data from this channel
   */
  getChannelById<T>(channelId: string): Observable<T>;

  /**
   * Check if a channel with a specific ID is presented
   *
   * @param channelId ID of the channel
   * @return {*} true if present, otherwise false
   */
  isPresent(channelId: string): boolean;

  /**
   * Send a message/obj to a specific channel
   *
   * @param channelId ID of the channel
   * @param value mesasge/obj to be sent
   * @return {*} true if successfully, otherwise false
   */
  notify(channelId: string, value: any): boolean;
}

/**
 * ChannelGroup implement with HashMap and ReplaySubject
 *
 */
class HashMapQueryChannel implements ChannelGroup {
  private _internalMap: Record<string, ReplaySubject<any>> = {};

  /**
   * Creates an instance of HashMapQueryChannel.
   *
   * @param Set the buffer size of internal ReplaySubject
   */
  constructor(private _defaultBufferSize = 1) {}

  public createChannel(channelId: string): boolean {
    if (!this.isPresent(channelId)) {
      this._internalMap[channelId] = new ReplaySubject(this._defaultBufferSize);
      return true;
    }
    return false;
  }

  public getChannelById<T>(channelId: string): Observable<T> {
    this.createChannel(channelId);
    return this._getChannelByIdInternal(channelId);
  }

  public notify(channelId: string, value: any): boolean {
    if (!this.isPresent(channelId)) {
      return false;
    }

    this._getChannelByIdInternal(channelId).next(value);
    return true;
  }

  public isPresent(channelId: string): boolean {
    return this._internalMap[channelId] !== undefined;
  }

  private _getChannelByIdInternal<T>(channelId: string): ReplaySubject<T> {
    return this._internalMap[channelId];
  }
}

/**
 * Service to manage different communication channels for the application
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ChannelGroupService {
  private _channelData: Record<string, ChannelGroup> = {};

  /**
   * Get a query channel for a specific type
   */
  public getChannelGroup(channelGroupType: ChannelGroupType): ChannelGroup {
    if (this._channelData[channelGroupType] === undefined) {
      this._channelData[channelGroupType] = new HashMapQueryChannel();
    }

    return this._channelData[channelGroupType];
  }

  public getChannelId(channelGroupType: ChannelGroupType, value: any) {
    switch (channelGroupType) {
      case 'AssetName':
        return value.tripId;
      case 'DriverName':
        return value.driverId;
    }
  }
}
