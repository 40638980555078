import { Injectable } from '@angular/core';

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { SnackBarMsg } from '@modules/dashboard3/components/snackbar-custom-message/snackbar-custom-message.component';
import { ComponentType } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
// import { tap } from 'rxjs/operators';

const STACK_DEFAULT_TIMEOUT = 99999;

@Injectable(
  // {providedIn: 'root',}
)
export class SnackbarService {
  public horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  public verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  public durationInSeconds = 10 * 1000;
  public isErrorSnackbarOpened = false;
  public iconSuccess = new Image();
  public iconError = new Image();

  // Support for stackable snackbar
  public toastMsgNotifier = new Subject<SnackBarMsg>();
  public get snackbarMsger$(): Observable<SnackBarMsg> {
    return this.toastMsgNotifier;
  }

  public get isSnackbarOpened(): boolean {
    return !!this._snackBar._openedSnackBarRef;
  }

  public get snackbarRef() {
    return this._snackBar._openedSnackBarRef;
  }

  constructor(private _snackBar: MatSnackBar) {
    this.iconError.src = 'assets/images/exclamation-white-circle.svg';
    this.iconSuccess.src = 'assets/images/check-green-circle.svg';
  }

  public success(message: string, action: string) {
    return this.openSnackBar(message, action, 'success-snackbar');
  }

  public error(message: string, action?: string) {
    return !this.isErrorSnackbarOpened ? this.openSnackBar(message, action, 'error-snackbar') : null;
  }

  public openSnackBar(message: string, action: string, panelClass: string | string[]): Observable<void> {
    const snackBarRef = this._snackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass,
      duration: this.durationInSeconds,
    });

    return snackBarRef.onAction();
  }

  public openCustomSnackBar(component: any, panelClass?: string | string[], durationInMs?: number, data?: any) {
    const snackBarRef = this._snackBar.openFromComponent(component, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass,
      data,
      duration: durationInMs,
    });

    return snackBarRef.onAction();
  }

  public openStackableSnackbar<T>(component: ComponentType<T>, duration?: number) {
    this._snackBar.openFromComponent(component, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'custom-stack-snackbar',
      data: duration || this.durationInSeconds,
      duration: STACK_DEFAULT_TIMEOUT,
    });
  }

  /**
   * Dismiss current snackbar and provides an observable to notify when the snackbar is finished
   */
  public closeCurrentSnackbar() {
    if (this.isSnackbarOpened) {
      this._snackBar.dismiss();
      this.toastMsgNotifier = new Subject<SnackBarMsg>();
    }
  }
}
