import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnInit,
} from '@angular/core';
import { DateService } from '@app-core/services/date.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import { StorageService } from '@app-core/services/storage.service';
import { DATE_FILTER, FilterChangeEvent } from '@app-core/constants/constants';

// Options for the date filter
// eslint-disable-next-line @typescript-eslint/naming-convention
const DateRangeList = [
  { lable: 'Today', value: 0 },
  { lable: 'Yesterday', value: -1 },
  { lable: 'Last 7 Days', value: 7 },
  { lable: 'Last 14 Days', value: 14 },
  { lable: 'Last 30 Days', value: 30 },
  { lable: 'Last 3 Months', value: 90 },
  { lable: 'Last 6 Months', value: 180 },
  { lable: 'Custom', value: 2 },
];

const CUSTOM_RANGE = 'CUSTOM_RANGE';
export const DATE_FILTER_EVENT: FilterChangeEvent = {
  filterName: DATE_FILTER,
  isChanged: true,
};

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnChanges, OnInit {

  @ViewChild(DaterangepickerDirective)
  public pickerDirective: DaterangepickerDirective;
  @ViewChild('datePicker', { static: true }) public datePicker;

  @Input()
  public value;
  @Input()
  public isExportSet: boolean;
  @Input() public range;

  @Output()
  public valueChange = new EventEmitter<number>();
  @Output()
  public applyChanges = new EventEmitter<FilterChangeEvent>();
  @Output()
  public discardChanges = new EventEmitter<void>();

  public isOpen = false;
  public dateRangeList = DateRangeList;
  public rangeText = '';
  public showRange = true;
  public showCalendar = false;
  public showApply = true;
  public dateRange1;
  public dateRange2;
  public startDate;
  public endDate;
  public days;
  public onApply = false;
  public today = moment(new Date());
  public minDate = moment(new Date());
  public onRefresh = false;
  public selectedRange: number;

  constructor(
    private _dateService: DateService,
    private _storage: StorageService
  ) {
    this.minDate = this.minDate.subtract(1, 'y');
    this.onRefresh = true;
  }

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (changes.value.currentValue || changes.value.currentValue === 0) {
        this._setToInitialValue();
      }
    }
    if (changes.range) {
      this._setRange(this.value);
    }
  }

  /**
   * @description : set the value selected temporarily until filter is clicked
   */
  public selectDate(val) {
    this.selectedRange = val;
    if (val === 2) {
      this.showCalendar = true;
      this.showApply = false;
      if (this.rangeText === 'Custom') {
        this.datePicker.startDate = moment(this._dateService.customStartdate);
        this.datePicker.endDate = moment(
          this._dateService.customEndDate
        ).subtract(1, 'd');
        this.datePicker.updateMonthsInView();
      }
    } else {
      this.showCalendar = false;
    }
    this.valueChange.emit(val);
    if (val !== 2) {
      this.isOpen = false;
    }
  }

  /**
   * @description: helper class used for apply the filter values based on user change
   */
  public applyFilter() {
    this.onApply = true;
    if (this.selectedRange === 2) {
      // if ( this.dateRange2.diff(moment(new Date())) > 0) {
      // this.toastService.error('')
      // }
      this.dateRange2 = this.dateRange2.add(1, 'd');
      this._dateService.exportCustomStartDate = this._dateService.toDaysStart(
        this.dateRange1._d
      );
      this._dateService.exportCustomEndDate = this._dateService.toDaysEnd(
        this.dateRange2._d
      );
      if (!this.isExportSet) {
        this._dateService.customStartdate = this._dateService.toDaysStart(
          this.dateRange1._d
        );
        this._dateService.customEndDate = this._dateService.toDaysEnd(
          this.dateRange2._d
        );
        this.saveCustomRange();
      }
    }
    this._setRange(this.selectedRange);
    this._closeFilter();
    this.applyChanges.emit(DATE_FILTER_EVENT);
  }

  /**
   * @description: toggling the filter dropdown based on user action
   */
  public toggleFilter(e?: Event) {
    if (this.isOpen) {
      this._setToInitialValue();
      this._closeFilter();
    } else {
      this.populateSelectedDate();
      this._openFilter();
      if (this.days === 7) {
        this.setMonthsInCAlendar();
      }
    }
    if (e) {
      e.stopPropagation();
    }
  }

  /**
   * @description: closing the dropdown based on click outside the dropdown body
   */
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      if (this.isOpen) {
        this.discardChanges.emit();
      }
      this._setToInitialValue();
      this._closeFilter();
    }
  }

  public dateRangeSelected(event) {
    this.dateRange1 = event.startDate;
    this.dateRange2 = event.endDate;
    this.showApply = true;
    // on responsive screen < 1147, to save the selected range in service
    if (window.innerWidth <= 1147) {
      this.applyFilter();
    }
  }

  /**
   * @description: saving custom date range to localstorage based on selected values
   */
  public saveCustomRange() {
    const from = this.dateRange1.format();
    const to = this.dateRange2;
    const range = { from, to };
    const DATA = {
      time: Date.now(),
      data: range,
    };
    this._storage.setStorageValue(CUSTOM_RANGE, DATA);
  }

  /**
   * @description: populate the selected date and update view based on date selection
   */
  public populateSelectedDate() {
    this.datePicker.startDate = moment(this.range ? this.range.from : '');
    this.datePicker.endDate = moment(this.range ? this.range.to : '');
    this.datePicker.updateView();
  }

  public setMonthsInCAlendar() {
    this.datePicker.rightCalendar.month = moment(this.today);
    this.datePicker.leftCalendar.month = moment(this.today).subtract(
      1,
      'month'
    );
    this.datePicker.updateView();
  }

  /**
   * @description: Set the initial value on the chips, by the default value
   * provided while loading
   */
  private _setToInitialValue() {
    this.selectedRange = this.value;
    const defaultRange = this.dateRangeList.find((ele) => ele.value === this.value);
    if (defaultRange.value === 2) {
      // On refresh set the calendar open, and hide the apply button
      this.showCalendar = true;
      if (this.onApply === false && this.onRefresh) {
        this._setRange(defaultRange.value);
        this.onRefresh = false;
      }
    } else {
      this.showCalendar = false;
      this._setRange(defaultRange.value);
    }
    this.rangeText = defaultRange.lable;
    this.onApply = false;
  }

  /**
   * @description : Set range on the filter chip
   * @param days : no of past days from today
   */
  private _setRange(days) {
    this.days = days;
    // Dont show range if date range is today
    this.showRange = days <= 1 ? false : true;
    this.range = this._dateService.getDateRange(days);
    // Check if custom range is selected in expoert modal
    if (days === 2 && this.isExportSet) {
      let from; let to;
      if (this.dateRange1 !== undefined && this.dateRange2 !== undefined) {
        from = this.dateRange1;
        to = this.dateRange2.subtract(1, 'd');
        this.datePicker.startDate = moment(from);
        this.datePicker.endDate = moment(to);
        this.datePicker.updateMonthsInView();
      } else if (
        this._dateService.exportCustomStartDate &&
        this._dateService.exportCustomEndDate
      ) {
        // on refresh the data is erased from service , so to avoid errors check if its present.
        from = this._dateService.exportCustomStartDate;
        to = moment(this._dateService.exportCustomEndDate).subtract(1, 'd');
        this.datePicker.startDate = moment(from);
        this.datePicker.endDate = moment(to);
        this.datePicker.updateMonthsInView();
      }
      this.range = { from, to };
    } else if (days === 2) {
      let from; let to;
      if (this.dateRange1 !== undefined && this.dateRange2 !== undefined) {
        from = this.dateRange1;
        to = this.dateRange2.subtract(1, 'd');
      } else if (
        this._dateService.customStartdate &&
        this._dateService.customEndDate
      ) {
        // on refresh the data is erased from service , so to avoid errors check if its present.
        from = this._dateService.customStartdate;
        to = moment(this._dateService.customEndDate).subtract(1, 'd');
        this.datePicker.startDate = moment(from);
        this.datePicker.endDate = moment(to);
        this.datePicker.updateMonthsInView();
      }
      this.range = { from, to };
    }
  }

  private _closeFilter() {
    this.isOpen = false;
    this.dateRange1 = undefined;
    this.dateRange2 = undefined;
  }

  private _openFilter() {
    this.isOpen = true;
    this.showApply = false;
  }
}
