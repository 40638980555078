import { LOCAL_DRIVER_SCORECARDS_KEY, LOCAL_TRIP_RECAPS_KEY } from './../../../../core/constants/constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ZCFleetService } from './../../services/zcfleet.service';
import { DateService } from './../../../../core/services/date.service';

import { LoadingService } from '@app-core/services/loading.service';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { SnackbarService } from '@app-core/services/snackbar.service';

import { SearchFilterModel, StatsModel, EVENT_NAMES_MAP } from './../../dashboard3.models';

import { LastNameSortPipe } from '@modules/shared/pipes/last-name-sort/last-name-sort.pipe';
import { Data } from '@modules/dashboard3/services/data.service';
import { LocalService } from './../../../../core/services/local.service';
import { StorageService } from '@app-core/services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterOptions } from '@modules/dashboard3/components/search-filter-table/search-filter-table.component';
import { StackableCustomSnackbarComponent }
  from '@modules/dashboard3/components/stackable-custom-snackbar/stackable-custom-snackbar.component';
import { SnackBarMsg } from '@modules/dashboard3/components/snackbar-custom-message/snackbar-custom-message.component';
import { Subscription } from 'rxjs';
import { HomeLocationService } from '@app-core/services/home-location.service';

const SNACKBAR_TITLE = 'An error has occurred';
const SNACKBAR_MSG = (target: string) => (`We were unable to load ${target || ''} due to a connection issue. Please try again.`);

@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.scss'],
  providers: [ArraySortPipe, LastNameSortPipe],
})
export class FleetDetailsComponent implements OnInit, OnDestroy {
    public histogramData = [];
    public histogramPercentileData = [];
    public modeScoreFrequency = 1;
    public driverCount = undefined;
    public totalTrips = 0;
    public checkDriverTrips = false;
    public mediaLinkFromTableIncident = '';
    public switch = false;
    public isReloadPage = false;
    public isReloadFunction = false;
    public isFleetPerError = undefined;
    public isDriverScorecardsError = undefined;
    public isTripRecapsError = undefined;
    public isDisputedError = undefined;
    public isShowNetworkError = false;
    public isGetHomeLocation = false;

    public dropdownFilterOptions: Record<string, FilterOptions> = {};
    public refetchFilter = false;
    public searchFilterData: SearchFilterModel;
    public stackMsg = [];
    private _subscription: Subscription = new Subscription();
    private _homeLocationList = null;

    public get localStorageHomeLocation() {
      return this._storageService.getStorageValue('HOME_LOCATION');
    };

    public get localStorageHomeLocationABV() {
      return this._storageService.getStorageValue('HOME_LOCATION_ABV');
    };
    constructor(
        private _zcfleet: ZCFleetService,
        private _router: Router,
        private _dateService: DateService,
        private _loading: LoadingService,
        private _data: Data,
        private _localService: LocalService,
        private _storageService: StorageService,
        private _title: Title,
        private _networkService: TrackNetworkStatusService,
        private _snackbarService: SnackbarService,
        private _homeLocationService: HomeLocationService,
        private _activatedRoute: ActivatedRoute
    ) {}
    public ngOnDestroy() {
      if (!this.isReloadFunction) {
        this._storageService.removeStorageValue(LOCAL_DRIVER_SCORECARDS_KEY);
        this._storageService.removeStorageValue(LOCAL_TRIP_RECAPS_KEY);
      }
      this._syncLocalStorageHomeLocation(true);
      this._subscription.unsubscribe();
    }

    public ngOnInit() {
      this._title.setTitle('Dashboard - Zonar Coach');
      this._activatedRoute.data.subscribe(res => {
        if (res?.homeLocations) {
          console.log(res);
        }
      });
      this._syncLocalStorageHomeLocation(false);
    }

    /**
     * @description: function to set search filter data based on user input
     * @param: filter values
     */
    public setSearchFilterData(val) {
      this.searchFilterData = val;
      this._snackbarService.closeCurrentSnackbar();
      this.getData();
      if (!this.isReloadPage) {
        this._zcfleet.fleetFilterChange.next(val);
      } else {
        this.reload();
      }
      this.isReloadPage = true;
    }

    public reload() {
      this.isReloadFunction = true;
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.onSameUrlNavigation = 'reload';
      this._router.navigate(['/dashboard']);
    }

    /**
     * @description: function to get intial page date of fleet details
     * @param:
     */
    public getData() {
      this._localService.saveSearchFilterData(this.searchFilterData);
      const { days, dutyType, minScore, maxScore } = this.searchFilterData;
      let date = { from: null, to: null };
      if (days === 2) {
        date.from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
        date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
        if (this._dateService.customStartdate === undefined || this._dateService.customEndDate === undefined) {
          date = this._data.customRange.data;
          this._dateService.customStartdate = this._data.customRange.data.from;
          this._dateService.customEndDate = this._data.customRange.data.to;
        }
      } else {
        date = this._dateService.getDateRangeInISO(days);
      }

      this._homeLocationList = this._storageService.getStorageValue('HOME_LOCATION_ABV');

      // Stats
      const statsParams = {
        params: new StatsModel(date.from, date.to, dutyType, minScore, maxScore, 85, this._homeLocationList),
      };

      this._loading.show();

      this._zcfleet.getStats(statsParams).subscribe(
        res => {
          this._loading.hide();
          this._zcfleet.stats = res;
          this.setStats(res);
          if (res.driverCount !== 0 && res.tripCount !== 0){
            this.checkDriverTrips = true;
          }
          this._zcfleet.fleetRating = res.score;
          this._zcfleet.driverCount = res.driverCount;
          this._zcfleet.tripCount = res.tripCount;
          this._zcfleet.fleetDriverCount = res.driverCount;
        },
        err => {
          this._loading.hide();
          console.error(`Received Error: ${err}`);
        }
      );
    }

    /**
     * @description: function to set fleet stats
     * @param: data such as percentile, distribution and other fields
     */
    public setStats(data) {
      const {
        percentile,
        distribution,
        modeScoreFrequency,
        driverCount,
        tripCount,
      } = data;
      setTimeout(() => {
        this.histogramData = distribution;
        this.histogramPercentileData = percentile;
        this.modeScoreFrequency = modeScoreFrequency;
        this.driverCount = driverCount;
        this.totalTrips = tripCount;
      }, 1);
    }

    public driverChange(driver) {
      this.gotoDriverDetail(driver);
    }

    /**
     * @description: function to navigate to particular driver page
     * @param: driver details such as id and name
     */
    public gotoDriverDetail(driver) {
      if (driver && driver.driverId) {
        // Set driver name
        // this._data.driverName = driver.driverName;
        this._router.navigate(['/driver-detail'], {
          queryParams: {
            driverId: driver.driverId,
            driverName: driver.driverName,
          },
        });
      }
    }

    public getIncident(incident) {
      return EVENT_NAMES_MAP[incident] || 'NA';
    }

    public setMediaLink(mediaLink: string) {
      this.mediaLinkFromTableIncident = mediaLink;
    }
    public setSwitch() {
      this.switch = !this.switch;
    }

    public notifyFleetPerErrChange(flag: boolean) {
      this.isFleetPerError = flag;
      if (!this._checkNetworkFail() && this._checkFlagError()) {
        this.showIndividualError();
      }
    }

    public notifyDriverScorecardsErrChange(flag: boolean) {
      this.isDriverScorecardsError = flag;
      if (!this._checkNetworkFail() && this._checkFlagError()) {
        this.showIndividualError();
      }
    }

    public notifyTripRecapsErrChange(flag: boolean) {
      this.isTripRecapsError = flag;
      if (!this._checkNetworkFail() && this._checkFlagError()) {
        this.showIndividualError();
      }
    }

    public notifyDisputedErrChange(flag: boolean) {
      this.isDisputedError = flag;
      if (!this._checkNetworkFail() && this._checkFlagError()) {
        this.showIndividualError();
      }
    }

    public showIndividualError() {
      if (!this.isShowNetworkError && this.isFleetPerError) {
        const title = SNACKBAR_TITLE;
        const textMsg = SNACKBAR_MSG('metrics data');
        this.showIrcError(title, textMsg);
      }
      if (!this.isShowNetworkError && this.isDriverScorecardsError) {
        const title = SNACKBAR_TITLE;
        const textMsg = SNACKBAR_MSG('driver scorecards');
        this.showIrcError(title, textMsg);
      }
      if (!this.isShowNetworkError && this.isTripRecapsError) {
        const title = SNACKBAR_TITLE;
        const textMsg = SNACKBAR_MSG('trips');
        this.showIrcError(title, textMsg);
      }
      if (!this.isShowNetworkError && this.isDisputedError) {
        const title = SNACKBAR_TITLE;
        const textMsg = SNACKBAR_MSG('disputed incidents');
        this.showIrcError(title, textMsg);
      }
    }

    public showIrcError(title: string, textMsg: string, isNetworkError?: boolean) {
      if (this._snackbarService.isSnackbarOpened && !this._networkService.isOnline) {
        this._subscription = this._snackbarService.snackbarRef.afterDismissed().subscribe(_ => {
          this.showIrcError(title, textMsg, isNetworkError);
        });
      } else if (!this._checkDuplicateErrorMsg(textMsg)) {
        this.initSnackbarInstance();
        const msg: SnackBarMsg = {
          textMsg,
          panelClasses: ['error-msg'],
          title,
          action: 'Try again',
          isShowContact: true,
          linkAction: this.reload.bind(this),
        };
        console.log(isNetworkError);
        if (isNetworkError) {
          this.stackMsg = [];
        }
        this.stackMsg.push(msg);
        this._snackbarService.toastMsgNotifier.next(msg);
      }
    }

    public initSnackbarInstance() {
      if (!this._snackbarService.isSnackbarOpened) {
        this._snackbarService.openStackableSnackbar(StackableCustomSnackbarComponent);
      }
    }

    private _checkDuplicateErrorMsg(textMsg: string) {
      let flag = false;
      this.stackMsg.forEach((item: SnackBarMsg) => {
        if (item.textMsg === textMsg && !this._snackbarService.isSnackbarOpened) {
          flag = true;
          return;
        }
      });
      return flag;
    }

    private _checkNetworkFail(): boolean{
      let  title = '';
      let textMsg = '';
      if (!this._networkService.isOnline) {
        title = 'No network found';
        textMsg = 'We were unable to load trips, metrics, and driver scorecards.'
          + ' Please wait a few minutes and try again.';
        this.showIrcError(title, textMsg, true);
        this.isShowNetworkError = true;
      } else {
        this.isShowNetworkError = false;
      }
      return this.isShowNetworkError;
    }

    private _checkFlagError() {
      if (
        this.isFleetPerError !== undefined
        && this.isDriverScorecardsError !== undefined
        && this.isTripRecapsError !== undefined
        && this.isDisputedError !== undefined
      ) {
        return true;
      }
      return false;
    }

    private _syncLocalStorageHomeLocation(isDestroy: boolean) {
      const locationFullList = this._storageService.getStorageValue('HOME_LOCATION_FULL_LIST');
      if (!isDestroy) { // load dashboard page
        if (this.localStorageHomeLocation?.length === 0) {
          // TO-DO: optimize Old All locations logic
          if (!locationFullList) {
            this._homeLocationService.getHomeLocations().subscribe(
              () => {
                this.isGetHomeLocation = true;
                this.reload();
              }
            );
          } else {
            this._storageService.setStorageValue('HOME_LOCATION_ABV', locationFullList.map(item => item.locationId));
          }
        } else {
          this._storageService.setStorageValue('HOME_LOCATION_ABV', this.localStorageHomeLocation);
        }
      } else if (isDestroy) { // leave dashboard page
        const currentLocationListSelected = this.localStorageHomeLocationABV?.filter(item => item !== 'null');
        const isCheckAllLocation = locationFullList?.length === currentLocationListSelected?.length;
        if (isCheckAllLocation || this.isGetHomeLocation) {
          this._storageService.setStorageValue('HOME_LOCATION', []); // all locations
        } else {
          this._storageService.setStorageValue('HOME_LOCATION', this.localStorageHomeLocationABV); // no location selected, select multiple
        }
      }
    }
}
