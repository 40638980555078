import { Directive, HostListener, Input } from '@angular/core';
import { EventBusService } from '@app-core/services/event-bus.service';

const GA_EVENT_CATEGORY = 'ga';

@Directive({
  selector: '[appSendGAOnClick]',
})
export class SendGAOnClickDirective {
  @Input()
  public gaEventName: string;

  @Input()
  public gaEventData: any;

  constructor(private _eventBusService: EventBusService) {}

  /**
   * @description: check whether the document click happened or not
   * @param: the target element the user click
   */
  @HostListener('click', ['$event'])
  public onClick() {
    this._eventBusService.emit({
      eventCategory: GA_EVENT_CATEGORY,
      eventName: this.gaEventName,
      value: this.gaEventData,
    });
  }
}
