<div class="zc-body zc-app-wrap has-search-filter">
    <app-export-modal
        [histogramData]="histogramData"
        [histogramPercentile]="histogramPercentileData"
        [modeScoreFrequency]="modeScoreFrequency"
        [driverCount]="driverCount"
    ></app-export-modal>

    <app-search-filter
        page="fleetmetrics"
        (driverChange)="driverChange($event)"
        [histogramData]="histogramData"
        [histogramPercentile]="histogramPercentileData"
        [modeScoreFrequency]="modeScoreFrequency"
        [driverCount]="driverCount"
        (filterChange)="setSearchFilterData($event)"
        [fleetMetricChange]= "true"
    >
    </app-search-filter>

<div class="zc-content-wrap">
    <div class="fleet-page-wrap">
        <div class = "home" >
            <img  class ="home-icon" (click)="goToDashboard()" [src]="imgSrc"
           (mouseout)="imgSrc = 'assets/images/path@2x.png'">&nbsp;

           <!-- <img  class ="home-icon" (click)="goToDashboard()" [src]="imgSrc"
           (mouseover)="imgSrc = 'assets/images/blue-home.png'"
           (mouseout)="imgSrc = 'assets/images/path@2x.png'">&nbsp; -->

            <!-- <img src = "/assets/images/arrow.png"> -->
            &nbsp; <span class="arrow" > > </span>
            <span class="route"> &nbsp; Fleet Trends </span>
        </div>
        <div class ="side-heading">
                Fleet Trends
        </div>
        <app-fleet-score-graph></app-fleet-score-graph>
    </div>
</div>

</div>
