/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
// import Cookies from 'cookies-js';

// using LocalStorage to store certain useful data
@Injectable()
export class StorageService {
  public showNotification = false;

  private defaultStorage: string;

  constructor() {
    this.initializeStorageService();
  }

  public setStorageValue(key, value = {}) {
    this[`set${this.defaultStorage}Value`](key, JSON.stringify(value));
  }

  public getStorageValue(key) {
    return this[`get${this.defaultStorage}Value`](key);
  }

  public removeStorageValue(key) {
    this[`remove${this.defaultStorage}Value`](key);
  }

  public clearAll() {
    localStorage.clear();
  }

  private initializeStorageService() {
    try {
      this.setLocalStorageValue('checkingStorage', '1');
      if (this.getLocalStorageValue('checkingStorage')) {
        this.defaultStorage = 'LocalStorage';
        this.removeLocalStorageValue('checkingStorage');
      }
    } catch (e) {
      this.defaultStorage = 'Cookie';
    }
  }

  private setLocalStorageValue(key, value) {
    localStorage.setItem(key, value);
  }

  private getLocalStorageValue(key) {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  private removeLocalStorageValue(key) {
    localStorage.removeItem(key);
  }

  // private setCookieValue(key, value) {
  //   // document.cookie.
  //   Cookies.set(key, value);
  // }

  // private getCookieValue(key) {
  //   const value = Cookies.get(key);
  //   if (value) {
  //     return JSON.parse(value);
  //   }
  //   return null;
  // }

  // private removeCookieValue(key) {
  //   Cookies.expire(key);
  // }
}
