import { Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { EventData } from './even-data';

@Injectable({
  providedIn: 'root',
})
export class EventBusService {

  private _subject$ = new Subject();

  constructor() { }

  public emit(event: EventData) {
    this._subject$.next(event);
  }

  public on(eventCategory: string, eventName: string, action: any): Subscription {
    return this._subject$.pipe(
      filter( (e: EventData) => e.eventCategory === eventCategory && e.eventName === eventName),
      map( (e: EventData) => e.value)
    ).subscribe(action);
  }

  public onCategory(eventCategory: string, action: any): Subscription {
    return this._subject$.pipe(
      filter( (e: EventData) => e.eventCategory === eventCategory),
      map( (e: EventData) => ({ eventName: e.eventName, value: e.value }))
    ).subscribe(action);
  }
}
