/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { combineLatest, Observable, of, ReplaySubject, merge, BehaviorSubject, iif, Subscription } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  catchError,
  distinctUntilChanged,
  retry,
  share,
  delay,
  tap,
  first,
  expand,
  takeWhile,
  reduce,
  mergeMap,
  finalize,
} from 'rxjs/operators';
import moment, { Moment } from 'moment-timezone';

import { FilterList } from '@modules/dashboard3/components/search-filter-table/search-filter-table.component';
import { SearchFilterTableModel } from '@modules/dashboard3/dashboard3.models';
import { KeyMetric } from '@modules/dashboard3/components/key-metric-overview/key-metric-overview.component';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { PaginationData } from '@modules/shared/components/table/table.component';
import { SortEvent, SortInfo } from '@modules/shared/components/card/card.component';
import { createMomentObjectUtc, getTimezoneString, makeUniqueWithIndex } from '@modules/shared/utils';
import { ErrorMessage } from '@modules/shared/components/error-message/error-message.component';
import { IncidentData } from '@modules/dashboard3/components/incident-media-control/incident-media-control.component';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { BookmarkInfo } from '@modules/dashboard3/components/comment-button-group/comment-button-group.component';
import {
  ChannelGroupService,
  ChannelGroupType,
} from '@app-core/services/query-channel.service';
import {
  UNASSIGNED_DRIVER,
  UNIDENTIFIED_DRIVER_NAME,
  EVENTS_INCIDENT_TYPE,
  ERROR_MESSAGE,
  BLANK_TEXT} from '@app-core/constants/constants';
import { StorageService } from '@app-core/services/storage.service';
import { DateService } from '@app-core/services/date.service';
import { LocalService } from '@app-core/services/local.service';
import { SortOption } from '../incident-response-center/fleet-saved-incidents-table/fleet-saved-incidents-table.component';
import { GraphData } from './identification-card/identification-card.component';
import { SnackbarService } from '@app-core/services/snackbar.service';
import {
  StackableCustomSnackbarComponent,
} from '@modules/dashboard3/components/stackable-custom-snackbar/stackable-custom-snackbar.component';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';

const MILE_OF_A_KILOMETER = 0.62137;
const MILE_NUMBER = 100;
const SECONDS_OF_A_HOUR = 60 * 60;
const MILLISECOND_PER_HOUR = 60000 * 60;
const NOT_SELECT_CUSTOM_ROW = -1;
const MAX_ASSET_VIOLATIONS_PER_REQ = 500;
const ASSET_VIOLATION_PAGE_SIZE = 6;

interface Date {
  from: string;
  to: string;
}

interface PageIndex {
  pageIndex: number;
  pageSize?: number;
}

interface DateFilterValue {
  from: string;
  to: string;
  isCustomRange?: boolean;
}

interface EventCount {
  total: number;
}

interface AggregateData {
  tripDuration: number;
  tripCount: number;
  score: number;
  tripDistance: number;
  eventCount: EventCount & Record<string, number>;
}

export interface AggregateDataList {
  key: string;
  value: {
    tripDuration: number;
    tripCount: number;
    score: number;
    tripDistance: number;
    eventCount: EventCount & Record<string, number>;
  };
}

const SNACKBAR_TITLE = 'An error has occurred';
const SNACKBAR_INTERNET_TITLE = 'No network found';
const SNACKBAR_MSG = (target: string) => (`We were unable to load ${target || ''} data due to a connection issue. Please try again.`);
const SNACKBAR_NO_INTERNET = 'We were unable to load asset scorecard data. Please wait a few minutes and try again.';

export interface AssetDriverInfo {
  driverId: string;
  driverName?: string | Observable<string>;
  driverNameAsync?: Observable<string>;
  lastTrip: Moment;
  totalTrip: number;
  totalIncident: number;
  topIncident: string;
  eventCount?: any;
}

interface DriverHistoryInfo {
  dataList: AssetDriverInfo[];
  pageSize: number;
  getDataOffset: number;
  currentDate: DateFilterValue;
}

const MAX_DATE_OF_MONTH = 31;
const MAXIMUM_CONCURRENT_REQUEST_LENGTH = 25;
const MAXIMUM_ASSET_TRIP_PAGE_SIZE = 500;

@Component({
  selector: 'app-asset-snapshot',
  templateUrl: './asset-snapshot.component.html',
  styleUrls: ['./asset-snapshot.component.scss'],
  providers: [ArraySortPipe],
})
export class AssetSnapshotComponent implements OnInit, OnDestroy {
  public assetTripPrefix = 'Trips Driven by ';
  public refetchFilter = false;
  public filterList: FilterList = {
    hasDateFilter: true,
    hasAssetFilter: false,
    hasDriverFilter: false,
    hasIncidentTypeFilter: false,
    hasLocationFilter: false,
    hasCameraImeisFilter: false,
    hasDutyTypeFilter: false,
  };

  public decimalType;
  public dateFilterValue;

  private _dateFilter$ = new ReplaySubject<DateFilterValue>();
  public get dateFilter$(): Observable<DateFilterValue> {
    return this._dateFilter$;
  }

  private _tripListSortChanged$ = new ReplaySubject<SortEvent>();
  public get tripListSortChanged$(): Observable<SortEvent> {
    return this._tripListSortChanged$;
  }

  public limit = 4;
  public sort: string;
  public assetId: string;
  public startDate: string;
  public endDate: string;
  public tripCount: number;

  public assetId$: Observable<string>;
  public reload$: Observable<boolean>;
  public assetName$: Observable<string>;
  public assetTripTitle$: Observable<string>;
  public divisionName$: Observable<string>;
  public graphData$: Observable<GraphData>;
  public aggregateDataByDays$: Observable<AggregateDataList[]>;
  public aggregateData$: Observable<AggregateData>;
  public metricData$: Observable<KeyMetric[]>;
  public incidentOverTimeSource$: Observable<AggregateDataList[]>;
  public reverseBookmarkInfo$: Observable<[any, any]>;

  public assetTripList$: Observable<any>;

  public sortListRecap: SortInfo[] = [
    {
      displayName: 'Most recent',
      sortColumn: 'tripRecap',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least recent',
      sortColumn: 'tripRecap',
      sortOrder: 'asc',
    },
  ];

  public metricHeaders = [
    { values: ['Total Miles', 'Total Hours', 'Total Trips'], longestName: 'Total Hours' },
    { values: ['Incidents / 100mi', 'Incidents / Hour', 'Total Incidents'], longestName: 'Incidents / 100mi' },
  ];

  public dateFilter = 0;
  public isCustomDateRange: boolean;

  public totalIncident$: Observable<number>;
  public topIncidentData$: Observable<IncidentStat[]>;

  //Driver History Table
  public getDriverTripListError = false;
  public driverHistory$: Observable<PaginationData<any>>;
  private _driverTableSort$ = new ReplaySubject<SortEvent>();
  public get driverTableSort$(): Observable<SortEvent> {
    return this._driverTableSort$;
  }
  private _driverHistoryInfo: DriverHistoryInfo = {
    dataList: [],
    getDataOffset: 0,
    pageSize: 10,
    currentDate: {
      from: '',
      to: '',
    },
  };
  private _driverTablePage$ = new ReplaySubject<any>();
  public get driverTablePage$(): Observable<any> {
    return this._driverTablePage$;
  }
  public driverDataReload$: Observable<boolean>;


  public assetIncidentTableReload$: Observable<boolean>;
  public isDisabledAction = false;
  public incidentData: IncidentData = {};
  public switch = false;
  public selectedIncident = null;
  public storeCurrentIncidentData = {};
  public filterOptions: any;
  public assetListViolations: any[] = [];
  public assetListViolationsCurrentPage: any[] = [];
  public assetViolationsTotalPage = 0;
  public offsetIncidentTable = 0;
  public show = 'data';
  public showRetryButton = true;
  public homeLocationList = [];
  public syncSortCard = {
    displayName: 'Most recent',
    sortColumn: 'disputeTimeMoment',
    sortOrder: 'desc',
  };
  public tripDetailMap = {};

  public sortListIncidentTable: SortInfo[] = [
    {
      displayName: 'Most recent',
      sortColumn: 'disputeTimeMoment',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least recent',
      sortColumn: 'disputeTimeMoment',
      sortOrder: 'asc',
    },
  ];

  public currentPageInfo = {
    pageIndex: 0,
    pageSize: ASSET_VIOLATION_PAGE_SIZE,
    totalItems: 0,
    totalPage: 0,
  };

  public colDescriptionsIncidentTable = [
    {
      colKey: 'disputeTimeMoment',
      colDisplayName: 'Date + Time',
      sortable: true,
      type: 'date-time',
      getter: (item) => item.disputeTimeMoment,
    },
    {
      colKey: '_eventType',
      colDisplayName: 'Category',
      sortable: false,
      type: 'bold-text',
      getter: (item) => item._eventType,
    },
    {
      colKey: 'assetName',
      colDisplayName: 'Asset',
      sortable: false,
      type: 'text',
      getter: (item) => item.assetNumber,
      async: true,
    },
    {
      colKey: 'driverName',
      colDisplayName: 'Driver',
      sortable: false,
      type: 'custom',
      getter: (item) => item.driverNameAsync,
      async: true,
    },
    {
      colKey: 'action',
      colDisplayName: 'Actions',
      sortable: false,
      type: 'custom',
      async: true,
      getter: (item) => item.bookmarkInfo,
    },
  ];

  public justEnterScreen = true;

  public assetIncidentCustomTemplates = ['driverName'];

  public get incidentListTable$(): Observable<PaginationData<any>> {
    return this._incidentListTable$;
  };
  private _incidentListTable$ = new ReplaySubject<any>();

  public get notifySortChange$(): Observable<[string, 'desc' | 'asc']> {
    return this._notifyPageChange$;
  }
  private _notifyPageChange$ = new ReplaySubject<[string, 'desc' | 'asc']>();

  public get errorMessage$(): Observable<ErrorMessage> {
    return this._errorMessage$;
  }
  private _errorMessage$ = new ReplaySubject<ErrorMessage>();

  private _sortOption: SortOption = {
    sortBy: 'disputeTimeMoment',
    sort: 'desc',
  };

  public getAssetTripListBinded = this.getTripList.bind(this);

  private _assetTripSort$ = new BehaviorSubject<SortEvent>(['tripRecap', 'desc']);
  /* eslint-enable @typescript-eslint/member-ordering */
  private _tripDetailMapFetchingSubscription: Subscription;
  private _subscription: Subscription = new Subscription();

  constructor(
    private _router: Router,
    private _title: Title,
    private _data: Data,
    private _storage: StorageService,
    private _route: ActivatedRoute,
    private _zcFleet: ZCFleetService,
    private _localService: LocalService,
    private _dateService: DateService,
    private _channel: ChannelGroupService,
    private _sort: ArraySortPipe,
    private _snackbarService: SnackbarService,
    private _networkService: TrackNetworkStatusService
  ) {
    this._prepareUpstream();
  }

  public ngOnInit() {
    this._title.setTitle('Asset Snapshot - Zonar Coach');
  }

  public ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public goToDashboard() {
    this._router.navigate(['/dashboard']);
  }

  public isDisabledClick(event) {
    this.isDisabledAction = event;
  }

  public notifySortChangeIncidentTable(event) {
    this._notifyPageChange$.next(event);
  }

  public get showSortOption() {
    return this.assetListViolations.length > 0;
  }

  public initSnackbarInstance() {
    if (!this._snackbarService.isSnackbarOpened) {
      this._snackbarService.openStackableSnackbar(StackableCustomSnackbarComponent);
    }
  }

  public reloadPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._snackbarService.closeCurrentSnackbar();
    this._snackbarService.snackbarRef.afterDismissed().subscribe(_ => {
      this._router.navigateByUrl(this._router.url);
    });
  }

  public catchAPIError<T>(errMsg: string, responseData: T): Observable<T> {
    if (this._snackbarService.isSnackbarOpened && !this._networkService.isOnline) {
      this._subscription = this._snackbarService.snackbarRef.afterDismissed().subscribe(_ => {
        this.justEnterScreen = false;
        this.catchAPIError(errMsg, responseData);
      });
    } else {
      this.initSnackbarInstance();
      if (this._networkService.isOnline) {
        this._snackbarService.toastMsgNotifier.next({
          title: SNACKBAR_TITLE,
          textMsg: SNACKBAR_MSG(errMsg),
          panelClasses: ['error-msg'],
          action: 'Try again',
          linkAction: this.reloadPage.bind(this),
          isShowContact: true,
        });
      } else {
        // not push no internet message yet
        this._snackbarService.toastMsgNotifier.next({
          title: SNACKBAR_INTERNET_TITLE,
          textMsg: SNACKBAR_NO_INTERNET,
          panelClasses: ['error-msg'],
          action: 'Try again',
          linkAction: this.reloadPage.bind(this),
          isShowContact: true,
        });
      }
      return of(responseData);
    }
  }

  /**
   * @description: function to set media type of a particular incident
   * @param: incident details
   */
  public setMedia(incident) {
    if (!incident) {
      this.incidentData = {};
    } else {
      this.storeCurrentIncidentData = Object.assign(incident);
      this.switch = !this.switch;
      this.selectedIncident = incident;
      if (this.selectedIncident.index !== undefined) {
        delete this.selectedIncident.index;
      }
      this.incidentData = {
        mediaLink: incident.eventVideoFile,
        driverId: incident.driverId,
        tripId: incident.tripId,
        eventIndex: incident.eventIndex,
        eventType: incident.eventType,
        speed: incident.speed,
        speedSign: {
          eventVideoFilename: incident.speedSign
            ? incident.speedSign.eventVideoFilename
            : undefined,
          speedSignValue: incident.speedSign
            ? incident.speedSign.speedSignValue
            : undefined,
        },
        challengeRaised: incident.challengeRaised,
        challengeAccepted: incident.challengeAccepted,
        challengeResolved: incident.challengeResolved,
      };
    }
  }

  /**
   * @description: function to update filter options
   * @param:filterOptions SearchFilterTableModel
   */
  public onDateChange(filterOptions: SearchFilterTableModel) {
    this.dateFilter = filterOptions.days;
    this.isCustomDateRange = this.dateFilter === 2;

    const date: Date = this._setDate(filterOptions);

    // Update asset incidents table when date range filter changed
    this.filterOptions = {
      days: this.dateFilter,
    };
    // when date filter changed, call API to get full list asset violations
    this.getListAssetViolations();

    this._snackbarService.closeCurrentSnackbar();
    this._dateFilter$.next({
      from: date.from,
      to: date.to,
      isCustomRange: this.isCustomDateRange,
    });
  }

  public notifySortChangeRecap(event: SortEvent) {
    this._assetTripSort$.next(event);
  }

  /**
   * @description update timezone by trip first location for current page incident list
   */
  public updateTimeZoneAssetIncidentTable() {
    const apiCalls = this._createGetTripDetailApiCall(
      this.assetListViolationsCurrentPage
    );

    if (this._tripDetailMapFetchingSubscription) {
      this._tripDetailMapFetchingSubscription.unsubscribe();
    }
    this._tripDetailMapFetchingSubscription = merge(...apiCalls).subscribe(
      (data: any) => {
        this.tripDetailMap[data.tripId] = data;
        // sort incidentList desc by date
        this.assetListViolationsCurrentPage.map(this._addDisputeTimeMoment.bind(this));
      }
    );
  }

  public sortChangeInternalAssetTable(event) {
    this._sortOption = {
      sortBy: event[0],
      sort: event[1],
    };
    switch (this._sortOption.sortBy) {
      case 'disputeTimeMoment':
        if (this._sortOption.sort === 'asc') {
          this._setSyncSortCard('Least recent');
        } else {
          this._setSyncSortCard('Most recent');
        }
        break;
    }

    this._sortAssetListViolations();

    this.getAssetTableData(
      this.currentPageInfo.pageIndex,
      this.currentPageInfo.pageSize,
      true
    );
  }

  /**
   * @description update data by paginator index
   * @param event paginator object include pageIndex and PageSize
   */
  public updatePageIncidentTable(page: PageIndex) {
    this.currentPageInfo.pageIndex = page.pageIndex;
    this.getAssetTableData(page.pageIndex, page.pageSize, false);
  }

  /**
   * @description call after bug reported for selected row
   * @param incidentUpdated selected incident
   */
  public updateIncidentChallenge(incidentUpdated) {
    const selectedIncident = this.assetListViolationsCurrentPage.find(
      item => this._isIncidentEqual(item, incidentUpdated));
    this._updateIncidentChallenge(selectedIncident, incidentUpdated);
  }

  /**
   * @description: function to get all asset violation list
   */
  public getListAssetViolations() {

    this.assetTripList$.pipe(first()).subscribe(
      () => {
        this._getAssetListViolations();
      }
    );

  }

  /**
   * @description: Update page info and emit the information to table data source
   * @param: pageIndex pageIndex begin with 0
   * @param: pageSize item per page
   */
  public getAssetTableData(
    pageIndex: number,
    pageSize = ASSET_VIOLATION_PAGE_SIZE,
    selectFirstRow = false,
    selectCustomRow = NOT_SELECT_CUSTOM_ROW,
    isInitTable = false
  ) {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    this.assetListViolationsCurrentPage = this.assetListViolations.slice(startIndex, endIndex);
    this.updateTimeZoneAssetIncidentTable();
    this.assetListViolationsCurrentPage.forEach(incident => {
      const timeZoneOffSetInMin = new Date(incident.timestampUTC).getTime() - new Date(incident.timestamp).getTime();
      incident.displayTimeZone = timeZoneOffSetInMin / MILLISECOND_PER_HOUR;
      incident._eventType = EVENTS_INCIDENT_TYPE.find((x) => x[0] === incident.eventType)[1] || '';
    });

    this._reverseAssetTableCurrentPageData();
    // If reloaded assetListViolationsCurrentPage length shorter than selected row index,
    // select the assetListViolationsCurrentPage last item index instead
    if (selectCustomRow >= this.assetListViolationsCurrentPage.length) {
      selectCustomRow = this.assetListViolationsCurrentPage.length - 1;
    }
    this.currentPageInfo = {
      pageIndex,
      pageSize,
      totalItems: this.assetListViolationsCurrentPage.length,
      totalPage: this.assetViolationsTotalPage,
    };

    // Initializing the asset incident list table's data
    if (isInitTable) {
      // Set the image/video from first incident
      if (this.assetListViolationsCurrentPage.length) {
        this.setMedia(this.assetListViolationsCurrentPage[0]);
        this._storage.showNotification = true;
      } else {
        this.setMedia(null);
        this._storage.showNotification = false;
      }
    }

    this._incidentListTable$.next({
      data: this.assetListViolationsCurrentPage,
      totalItems: this.assetListViolations.length,
      totalPage: this.assetViolationsTotalPage,
      perPage: 6,
      pageIndex: this.currentPageInfo.pageIndex,
      selectFirstRow,
      selectCustomRow,
    });
    this.show = 'data';
  }

  public getRowDataIdentifier(element: any): string {
    if (element) {
      return element.tripId + '-' + element.eventIndex;
    }
    return '';
  }

  public getAssetTripList(
    [ assetId, filterValue, sortEvent ]: [ string, any, SortEvent ],
    limit: number,
    skip: number
  ): Observable<any> {
    return this._zcFleet.getAssetTripList(assetId, {
      startDate: filterValue.from,
      endDate: filterValue.to,
      limit,
      skip,
      sort: sortEvent[1],
    });
  }

  public getTripList(
    [ assetId, filterValue, sortEvent ]: [ string, any, SortEvent ],
    limit: number,
    skip: number
  ): Observable<any> {
    return this._zcFleet.getAssetTripList(assetId, {
      startDate: filterValue.from,
      endDate: filterValue.to,
      limit,
      skip,
      sort: sortEvent[1],
    }).pipe(catchError(_err => this.catchAPIError('trips', undefined)));
  }

  public driverHistoryPageChange(page: any) {
    this._driverTablePage$.next(page);
  }

  public driverHistorySortChange(sortType: SortEvent) {
    this._driverTableSort$.next(sortType);
  }

  /**
   * @description: Subscribe for asset ID sent and call the API
   */
  private _listenForQueryParams() {
    this.assetId$ = this._route.paramMap.pipe(
      map((res) => res.get('assetId')),
      shareReplay()
    );
  }

  /**
   * @description: check if two incidents are equal
   */
  private _isIncidentEqual(incident1, incident2): boolean {
    return (
      incident1.tripId + incident1.eventIndex ===
      incident2.tripId + incident2.eventIndex
    );
  }

  private _setSyncSortCard(name: string) {
    this.syncSortCard = {
      displayName: name,
      sortColumn: this._sortOption.sortBy,
      sortOrder: this._sortOption.sort,
    };
  }

  private _getAssetListViolations(offset = 0) {

    if (offset === 0) {
      this.assetListViolations = [];
    }

    this._zcFleet.getListAssetViolations(
      this.assetId,
      this._buildOptionQueryTableIncident(offset)
    ).subscribe(
      (res) => {
        this.assetListViolations = this.assetListViolations.concat(res.rows);

        if (res.rows.length < MAX_ASSET_VIOLATIONS_PER_REQ) {
          // filter violations with unsupported eventType
          this.assetListViolations = this.assetListViolations.filter(
            violation => EVENTS_INCIDENT_TYPE.find(
              item => item[0] === violation.eventType
            ));

          this.assetListViolations = this.assetListViolations.map(this._addDisputeTimeMoment.bind(this));
          this.assetListViolations.forEach(item => item.assetNumber = this.assetName$);

          this._sortAssetListViolations();

          this.assetViolationsTotalPage = Math.ceil(
            this.assetListViolations.length / ASSET_VIOLATION_PAGE_SIZE);
          this.getAssetTableData(0, 6, true, NOT_SELECT_CUSTOM_ROW, true);
          return;
        } else if (res.rows.length === MAX_ASSET_VIOLATIONS_PER_REQ) {
          offset += MAX_ASSET_VIOLATIONS_PER_REQ;
          this._getAssetListViolations(offset);
        }
      },
      (_err) => {
        // new catch error sequences
        this.catchAPIError('incident', undefined);
      }
    );
  }

  private _setDate(filterOptions: SearchFilterTableModel): Date {
    this._localService.saveSearchFilterData(filterOptions);
    const { days } = filterOptions || this._data.filterData;
    let date: Date = {
      from: '',
      to: '',
    };
    if (days === 2) {
      // custom range filter
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }

    return date;
  }

  private _buildOptionQueryTableIncident(offset: number) {
    const { days } = this.filterOptions;
    let date;
    if (days === 2) {
      const from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      const to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      date = { from, to };
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList = JSON.parse(localStorage.getItem('HOME_LOCATION'));

    return {
      params: {
        startDate: date.from,
        endDate: date.to,
        limit: MAX_ASSET_VIOLATIONS_PER_REQ,
        offset,
      },
    };
  }


  private _getCardIdentificationData(
    distinctDateFilter$: Observable<DateFilterValue>,
    distinctAssetId$: Observable<string>) {

    this.assetName$ = this.assetId$.pipe(
      switchMap(assetId => this._zcFleet.getAssetNameByAssetId(assetId).pipe(
        map(assetName => assetName ? assetName : 'N/A')
      )),
      shareReplay()
    );

    this.assetTripTitle$ = this.assetName$.pipe(
      map(value => `${this.assetTripPrefix}${value}`)
    );

    this.divisionName$ = this.assetId$.pipe(
      switchMap(assetId => this._zcFleet.getDivisionByAssetId(assetId).pipe(
        map(divisionName => divisionName ? divisionName : 'N/A')
      )),
      shareReplay()
    );

    const createObsWithGroupBy = (groupBy: 'day' | 'week') => combineLatest([
      distinctDateFilter$,
      distinctAssetId$,
    ]).pipe(
      switchMap(
        ([dateFilterValue, assetId]) => this._zcFleet.getAssetAggregateByDays(assetId, {
          startDate: dateFilterValue.from,
          endDate: dateFilterValue.to,
          groupBy,
        }).pipe(
          catchError(() => this.catchAPIError('metrics', undefined))
        )
      ),
      shareReplay()
    );
    this.aggregateDataByDays$ = createObsWithGroupBy('day');
    this.incidentOverTimeSource$ = iif(
      () => this._isRangeGreaterThan(this._data.filterData.days),
      createObsWithGroupBy('week'),
      this.aggregateDataByDays$
    );

    this.graphData$ = this.aggregateDataByDays$.pipe(
      map(res => {
        if (res && res.length) {
          const initPlotDataByDateRange = (dateRange: DateFilterValue) => {
            const from = moment(dateRange.from);
            const to = moment(dateRange.to);
            const dateArray = [];

            for (let i = 0; from <= to; from.add(1, 'days'), i++) {
              dateArray[i] = {
                key: from.format('YYYY-MM-DD'),
                value: 0,
              };
            }
            dateArray.pop(); // remove redundant last item
            return dateArray;
          };

          const plotDataList = initPlotDataByDateRange(this.dateFilterValue).map(item => {
            const data = res.find(resItem => resItem.key === item.key);
            return data ? data.value.eventCount.total : 0;
          });

          console.log('list plotData:' + plotDataList + '\n'
            + 'list plotData removed empty:' + plotDataList.filter(item => item !== 0) + '\n'
            + 'total incident: ' + plotDataList.reduce((cur, next) => cur + next, 0));

          return {
            numberOfDays: this.dateFilter,
            updateChart: true,
            plotData: plotDataList,
          };
        }
        return undefined;
      })
    );
  }

  private _prepareUpstream(): void {
    this._listenForQueryParams();

    const distinctDateFilter$ = this.dateFilter$.pipe(distinctUntilChanged()).pipe(
      tap(value => {
        this.dateFilterValue = value;
      })
    );
    const distinctAssetId$ = this.assetId$.pipe(distinctUntilChanged());
    this.reload$ = merge(distinctAssetId$, distinctDateFilter$).pipe(
      map(() => true)
    );

    this.aggregateData$ = combineLatest([
      distinctDateFilter$,
      distinctAssetId$,
    ]).pipe(
      switchMap(
        ([dateFilterValue, assetId]) => this._zcFleet.getAssetAggregate<{ value: AggregateData }>(assetId, {
          startDate: dateFilterValue.from,
          endDate: dateFilterValue.to,
        }).pipe(
          catchError(() => this.catchAPIError('metrics', undefined)),
          map(res => {
            this.tripCount = res && res.value && res.value.tripCount;
            return res ? res.value : undefined;
          }
          )
        )
      ),
      shareReplay()
    );

    this.assetTripList$ = combineLatest([
      distinctAssetId$,
      distinctDateFilter$,
      this._assetTripSort$,
    ]).pipe(
      tap(
        ([assetId, dateFilterValue]) => {
          this.assetId = assetId;
          this.startDate = dateFilterValue.from;
          this.endDate = dateFilterValue.to;
          this.sort = 'desc';
        }
      ),
      shareReplay(1)
    );

    this._getCardIdentificationData(distinctDateFilter$, distinctAssetId$);

    this.metricData$ = this.aggregateData$.pipe(
      map(this._aggregateAssetMappingKeyMetrics)
    );

    this.totalIncident$ = this.aggregateData$.pipe(
      map(this._getTotalIncident)
    );

    this.topIncidentData$ = this.aggregateData$.pipe(
      map(this._getTopIncidentAssetList)
    );

    const driverSortChange$ = this.driverTableSort$.pipe(distinctUntilChanged());
    const driverPageChange$ = this.driverTablePage$.pipe(distinctUntilChanged());
    this.driverDataReload$ = merge(this.reload$, driverPageChange$, driverSortChange$).pipe(map(() => true));
    this.driverHistory$ = combineLatest([
      distinctAssetId$,
      distinctDateFilter$,
      driverSortChange$,
      driverPageChange$,
    ]).pipe(
      switchMap(([assetId, dateFilter, sortData, pageInfo]) => this._getDriverTableData(assetId, dateFilter, sortData, pageInfo)),
      map(res => this.getDriverTripListError ? undefined : res),
      shareReplay(1));

    // asset list violations data
    this.assetIncidentTableReload$ = this.reload$.pipe(map(() => true));
  }

  private _aggregateAssetMappingKeyMetrics(data: AggregateData): KeyMetric[] {
    if (data) {
      const totalMiles = Number((data.tripDistance * MILE_OF_A_KILOMETER).toFixed(2));
      const totalHours = Number((data.tripDuration / SECONDS_OF_A_HOUR).toFixed(2));
      const totalIncident = data.eventCount.total;
      const incidentPerNMile = data.tripDistance > 0 ? (totalIncident * MILE_NUMBER) / totalMiles : 0;
      const incidentPerMin = data.tripDuration > 0 ? totalIncident / totalHours : 0;

      return [
        { name: 'Total Miles', value: totalMiles, decimalType: '1.0-2', difference: 0 },
        { name: 'Total Hours', value: totalHours, decimalType: '1.0-2', difference: 0 },
        { name: 'Total Trips', value: data.tripCount, difference: 0 },
        { name: `Incidents / ${MILE_NUMBER}mi`, value: incidentPerNMile, decimalType: '1.0-2', difference: 0 },
        { name: 'Incidents / Hour', value: incidentPerMin, decimalType: '1.0-2', difference: 0 },
        { name: 'Total Incidents', value: totalIncident, difference: 0 },
      ];
    }
    return undefined;
  }

  private _getTopIncidentAssetList(eventData: AggregateData): IncidentStat[] {
    if (eventData && eventData.eventCount) {
      return [
        {
          name: 'Accelerating',
          value: eventData.eventCount['Harsh-Acceleration'],
          incidentClass: 'incident-acceleration',
        },
        {
          name: 'Braking',
          value: eventData.eventCount['Harsh-Braking'],
          incidentClass: 'incident-braking',
        },
        {
          name: 'Lane Drift',
          value: eventData.eventCount['Lane-Drift-Found'],
          incidentClass: 'incident-lane-drift',
        },
        {
          name: 'Cornering',
          value: eventData.eventCount['Cornering'],
          incidentClass: 'incident-cornering',
        },
        {
          name: 'Speeding',
          value: eventData.eventCount['Traffic-Speed-Violated'],
          incidentClass: 'incident-speeding',
        },
        {
          name: 'Tailgating',
          value: eventData.eventCount['Tail-Gating-Detected'],
          incidentClass: 'incident-tailgating',
        },
        {
          name: 'Distraction',
          value: eventData.eventCount['Distracted-Driving'],
          incidentClass: 'incident-distraction',
        },
        {
          name: 'Stop Sign',
          value: eventData.eventCount['Traffic-STOP-Sign-Violated'],
          incidentClass: 'incident-stop-sign',
        },
      ];
    }
    return undefined;
  }

  private _getTotalIncident(data: AggregateData): number {
    return data?.eventCount?.total ?? undefined;
  }

  private _isRangeGreaterThan(days: number): boolean {
    let date: Date = {
      from: '',
      to: '',
    };
    date = this._data.getDateRangeFromDays(days);
    const fromInMSec = new Date(date.from).getTime();
    const toInMSec = new Date(date.to).getTime();
    const diff = (toInMSec - fromInMSec) / (1000 * 60 * 60 * 24);
    return diff > MAX_DATE_OF_MONTH;
  }

  private _getDriverTableData(
    assetId: string,
    date: DateFilterValue,
    sortInfo: SortEvent,
    paginationData: any): Observable<PaginationData<any>> {
    const pageSize = this._driverHistoryInfo.pageSize;
    const pageIdx = paginationData ? paginationData.pageIndex : 0;
    let driverDataPipe$: Observable<any>;
    if (this._driverHistoryInfo.currentDate.from !== date.from
      || this._driverHistoryInfo.currentDate.to !== date.to
      || this.getDriverTripListError) {
      // Get new asset trip data since date changed
      this._driverHistoryInfo.currentDate = date;
      this._driverHistoryInfo.getDataOffset = 0;
      this._driverHistoryInfo.dataList = [];
      driverDataPipe$ = this._getDriverHistoryData(assetId, date, sortInfo, MAXIMUM_ASSET_TRIP_PAGE_SIZE, 0).pipe(
        map(res => this._groupTripByDriver(res.rows))
      );
    } else {
      // Add delay to prevent infinite skeleton loading
      // TO-DO: Will find a way to remove the delay
      driverDataPipe$ = of(this._driverHistoryInfo.dataList).pipe(delay(500));
    }
    return driverDataPipe$.pipe(
      map(res => {
        res = this._sortDriverData(res, sortInfo);
        this._driverHistoryInfo.dataList = res;
        this._requestTableData();
        if (res && res.length > 0) {
          return {
            data: this._getDriverPageData(res, pageSize, pageIdx),
            totalItems: res.length,
            totalPage: Math.ceil(res.length / pageSize),
            perPage: pageSize,
            pageIndex: paginationData ? paginationData.pageIndex : 0,
          };
        } else {
          return {
            data: [],
            totalItems: 0,
            totalPage: 0,
            perPage: pageSize,
            pageIndex: paginationData ? paginationData.pageIndex : 0,
          };
        }
      })
    );
  }

  private _groupTripByDriver(assetTrips: any[]) {
    const incidentByDriverMap: Record<string, AssetDriverInfo> = (assetTrips || []).reduce((acc, curr) => {
      if (acc[curr.driverId]) {
        acc[curr.driverId].totalTrip += 1;
        Object.keys(acc[curr.driverId].eventCount).forEach(key => acc[curr.driverId].eventCount[key] += curr.eventCount[key]);
        acc[curr.driverId].totalIncident = acc[curr.driverId].eventCount.total;
        acc[curr.driverId].topIncident = this._getTopIncident(acc[curr.driverId].eventCount);
      } else {
        acc[curr.driverId] = {
          driverId: curr.driverId,
          driverName: curr.driverName !== undefined ? curr.driverName : undefined,
          lastTrip: this._getDisputedTimeMoment(
            curr.startTimeUTC, curr.firstLocation.latitude, curr.firstLocation.longitude, curr.timezoneOffset
          ),
          totalTrip: 1,
          totalIncident: curr.eventCount.total,
          eventCount: curr.eventCount,
          topIncident: this._getTopIncident(curr.eventCount),
        };
      }
      return acc;
    }, {});
    return Object.values(incidentByDriverMap);
  }

  private _getDriverHistoryData(assetId: string, dateFilter: any, sortEvent: any, limit: number, skip: number) {
    let isCompleted = false;
    this.getDriverTripListError = false;
    return this.getAssetTripList([assetId, dateFilter, sortEvent], limit, skip).pipe(
      expand(res => {
        if (res && res.rows) {
          if (res.rows.length === limit) {
            this._driverHistoryInfo.getDataOffset += limit;
          } else {
            //All trip data has been collected
            this._driverHistoryInfo.getDataOffset += res.rows.length;
            isCompleted = true;
          }
        }
        return this.getAssetTripList([assetId, dateFilter, sortEvent], limit, this._driverHistoryInfo.getDataOffset);
      }),
      takeWhile(() => isCompleted === false),
      catchError((_) => {
        this.getDriverTripListError = true;
        isCompleted = true;
        return this.catchAPIError('driver history', {rows: []});
      }),
      reduce((acc, value) => {
        acc.rows = [...acc.rows, ...value.rows];
        return acc;
      })
    );
  }

  private _getDriverPageData(driverList: any[], pageSize: number, pageIdx: number) {
    if (driverList.length > 0) {
      return driverList.slice(pageIdx * pageSize, (pageIdx * pageSize) + pageSize);
    }
    return [];
  }

  private _sortDriverData(data: AssetDriverInfo[], sortEvent: SortEvent) {
    switch (sortEvent[0]) {
      case 'lastTrip':
        data.sort((a,b) => {
          if (a.lastTrip === b.lastTrip) {
            return 0;
          } else {
            if (a.lastTrip.isAfter(b.lastTrip)) {
              return sortEvent[1] === 'asc' ? 1 : -1;
            } else {
              return sortEvent[1] === 'asc' ? -1 : 1;
            }
          }
        });
        break;
      case 'totalIncident':
        if (sortEvent[1] === 'asc') {
          data.sort((a,b) => a.totalIncident - b.totalIncident);
        } else {
          data.sort((a,b) => b.totalIncident - a.totalIncident);
        }
        break;
      case 'totalTrip':
        if (sortEvent[1] === 'asc') {
          data.sort((a,b) => a.totalTrip - b.totalTrip);
        } else {
          data.sort((a,b) => b.totalTrip - a.totalTrip);
        }
        break;
    }
    return data;
  }

  private _getTopIncident(source): string {
    let result = '';
    let highestVal = 0;
    Object.keys(source).forEach(key => {
      if (key !== 'total' && source[key] > highestVal) {
        result = key;
        highestVal = source[key];
      }
    });
    for (const item of EVENTS_INCIDENT_TYPE) {
      if (item[0] === result) {
        result = item[1];
        break;
      }
    }
    return highestVal > 0 ? result + ` (${highestVal})` : 'N/A';
  }

  private _requestTableData() {
    const driverNameChannelGroup = this._channel.getChannelGroup('DriverName');

    this._driverHistoryInfo.dataList.forEach((incident) => {

      // Set driverName to '_UNASSIGNED' for violations with driverId is '_UNASSIGNED'
      // set driverName to 'Unidentified Driver'
      if (incident.driverId === UNASSIGNED_DRIVER) {
        incident.driverName = UNIDENTIFIED_DRIVER_NAME;
      }

      if (incident.driverName && incident.driverName !== BLANK_TEXT) {
        // If driverName is ReplaySubject skip this step
        if (typeof incident.driverName !== 'object') {
          incident.driverNameAsync = of(incident.driverName) as Observable<string>;
        }
      } else {
        incident.driverName = driverNameChannelGroup.getChannelById(
          this._channel.getChannelId('DriverName', incident)
        );
      }
    });

    // get driver name API request's body payload
    // only reverse driverName for violations that not included driverName info
    const driverIds: [string, number][] = this._driverHistoryInfo.dataList.map(
      (item, index) => {
        if (!item.driverNameAsync) {
          return [item.driverId, index];
        }
        return undefined;
      }
    ).filter(item => item !== undefined) as [string, number][];

    if (driverIds.length) {
      this._initReverseDriverNameJobs(driverIds);
    }
  }

  private _getUpstreamChannel(channelType: ChannelGroupType) {
    const queryChannel = this._channel.getChannelGroup(channelType);
    const notify = (value: any, event: any) => {
      const channelId = this._channel.getChannelId(channelType, value);
      queryChannel.notify(channelId, event);
    };

    return {
      notify,
    };
  }

  private _makeUniqueWithIndex<T, R>(
    source: [T, R][],
    checkUniqueFn: (ele: T, ele2: T) => boolean
  ): [T, R[]][] {
    return source.reduce<[T, R[]][]>((acc, value) => {
      const duplicatedElement = acc.find((ele) =>
        checkUniqueFn(ele[0], value[0])
      );
      if (duplicatedElement) {
        duplicatedElement[1].push(value[1]);
        return acc;
      } else {
        return [...acc, [value[0], [value[1]]]];
      }
    }, []);
  }

  private _splitJob<T, R = any>(
    list: T[],
    jobFactory: (value: T[]) => Observable<R>,
    jobSize
  ): Observable<[R, T[]]> {
    const obs$: Observable<[R, T[]]>[] = [];
    for (let i = 0; i < list.length; i += jobSize) {
      const chunk = list.slice(i, i + jobSize);
      obs$.push(jobFactory(chunk).pipe(map((res) => [res, chunk])));
    }

    // make sure to call only 25 concurrent requests to avoid overload server
    // 25 is average safe number, the larger number the higher chances of overload server
    if (obs$.length > MAXIMUM_CONCURRENT_REQUEST_LENGTH) {
      return of(...obs$).pipe(
        mergeMap((obj) => obj, MAXIMUM_CONCURRENT_REQUEST_LENGTH),
        finalize(() => console.log('Sequence complete'))
      );
    } else {
      return merge(...obs$);
    }
  }

  private _initReverseDriverNameJobs(
    driverIdList: [string, number][],
    jobSize = 15 // should not increase the jobSize, too large number will cause server error
  ) {

    if (!driverIdList.length) {
      return;
    }

    const upstream = this._getUpstreamChannel('DriverName');
    const uniqueList = this._makeUniqueWithIndex(
      driverIdList,
      (driverId, currentVal) => driverId === currentVal
    );
    const updateAllDriverRow = (indexList: number[], driverName: string) => {
      for (const index of indexList) {
        this._driverHistoryInfo.dataList[index].driverNameAsync = of(driverName);
      }
    };

    this._splitJob(
      uniqueList,
      (partialList) =>
        this._zcFleet
          .getMultipleUserInfoFromUserProfileIds(
            partialList.map((item) => item[0])
          )
          .pipe(catchError(() => of({}))),
      jobSize
    )
      .subscribe(([res, chunk]) => {
        for (const [driverId, incidentIndexList] of chunk) {
          const user = res[driverId];
          const driverName =
            user && user.firstName
              ? user.firstName + ' ' + user.lastName
              : ERROR_MESSAGE.INVALID_DRIVER_ID;

          upstream.notify({ driverId }, driverName);
          updateAllDriverRow(incidentIndexList, driverName);
        }
      });
  }

  private _getDisputedTimeMoment(time: string, latitude: number, longitude: number, timezoneOffset: number): Moment {
    return createMomentObjectUtc(time).tz(getTimezoneString(timezoneOffset, latitude, longitude));
  }

  private _addDisputeTimeMoment(item): any {
    const tripKey = item.tripId;
    item.disputeTimeMoment = createMomentObjectUtc(item.timestampUTC);

    if (this.tripDetailMap[tripKey]) {
      const {
        firstLocation: { latitude = 0, longitude = 0 } = {},
        timezoneOffset: timezoneOffset = 0,
      } = this.tripDetailMap[tripKey];
      item.disputeTimeMoment = item.disputeTimeMoment.tz(
        getTimezoneString(timezoneOffset, latitude, longitude)
      );
    }
    return item;
  }

  private _updateIncidentChallenge(incident, incidentUpdated) {
    // this method only call when a challenge updated successfully

    // current index of incident
    const listIndex = this.assetListViolations.indexOf(incident);

    // Update challenges for incident in asset violation list
    this.assetListViolations[listIndex].challengeAccepted = incidentUpdated.challengeAccepted;
    this.assetListViolations[listIndex].challengeRaised = incidentUpdated.challengeRaised;
    this.assetListViolations[listIndex].challengeResolved = incidentUpdated.challengeResolved;

    // set current selected violation media
    this.selectedIncident = this.assetListViolations[listIndex];
    this.setMedia(this.selectedIncident);
  }

  private _reverseAssetTableCurrentPageData() {

    // Set driverName to '_UNASSIGNED' for violations with driverId is '_UNASSIGNED'
    // Or if violation already has driverName === '', set driverName to 'Unidentified Driver'
    // Set asynchronous driverName for violations that already have driverName info
    this.assetListViolationsCurrentPage.forEach(item => {
      if (item.driverId === UNASSIGNED_DRIVER || item.driverName === BLANK_TEXT) {
        item.driverName = UNIDENTIFIED_DRIVER_NAME;
      }

      if (item.driverName) {
        item.driverNameAsync = of(item.driverName);
      }
    });
    // get driver name API request's body payload
    // only reverse driverName for violations that not included driverName info
    const driverIds: [string, number][] = this.assetListViolationsCurrentPage.filter(
      item => !item.driverName).map((item, index) => [item.driverId, index]);

    if (driverIds.length) {
      this._reverseDataAssetViolationTable(driverIds);
    }

    // violationIdentifiers is used to get bookmarkInfo
    const violationIdentifiers = this.assetListViolationsCurrentPage.map((item) =>
      this.getRowDataIdentifier(item)
    );

    const queryOptions = { params: { sort: 'asc'} };

    this.reverseBookmarkInfo$ = combineLatest([
      this._zcFleet.getMultiCommentByViolationIdentifiers(violationIdentifiers, queryOptions),
      this._zcFleet.getMultiBookmarkByViolationIdentifiers(
        violationIdentifiers
      ),
    ]).pipe(
      retry(),
      share()
    );

    this.assetListViolationsCurrentPage.forEach((item) => {
      // get bookmarkInfo
      item.bookmarkInfo = this.reverseBookmarkInfo$.pipe(
        map(([commentList, bookmarkList]) => {
          const incidentId = this.getRowDataIdentifier(item);
          return {
            comment: commentList[incidentId],
            bookmark: bookmarkList[incidentId],
          };
        }),
        map((rowBookmarkInfo) =>
          this._transformRowComment(item, rowBookmarkInfo)
        )
      );
    });
  }

  private _reverseDataAssetViolationTable(driverIds) {
    this._reverseDriverNameJobs(driverIds);
  }

  private _transformRowComment(item, rowBookmarkInfoObj): BookmarkInfo {
    return {
      eventIndex: item.eventIndex,
      bookmark: rowBookmarkInfoObj['bookmark'].bookmark,
      comments: rowBookmarkInfoObj['comment'] || [],
      tripId: item.tripId,
      driverId: item.driverId,
      displayTimeZone: item.displayTimeZone,
      status: item.status,
      violationIdentifier: this.getRowDataIdentifier(item),
      disputeTimeMoment: item.disputeTimeMoment,
      timestampUTC: item.timestampUTC,
      eventType: item.eventType,
    };
  }

  private _createGetTripDetailApiCall(violation: any[]): Observable<any>[] {
    const tripList = violation.map((item) => ({
      tripId: item.tripId,
      value: {
        driverId: item.driverId, // we need driverId to request the API
        tripId: item.tripId,
      },
    }));

    /** Get the tripId item that wasn't cached */
    const notCachedKeys = Array.from(
      new Set(tripList.map((item) => item.tripId))
    )
      .map((uniqueTripId) =>
        tripList.find((trip) => trip.tripId === uniqueTripId)
      ) // only return unique trip id item
      .filter((trip) => !this.tripDetailMap[trip.tripId]);

    const apiCalls = notCachedKeys.map((trip) =>
      this._zcFleet.getTripDetails(trip.value.driverId, trip.value.tripId)
    );
    apiCalls.push(of({ tripId: 'notused' })); // To ensure that the subscribe callback will always be called

    return apiCalls;
  }

  private _reverseDriverNameJobs(
    driverIdList: [string, number][]
  ) {
    if (!driverIdList.length) {
      return;
    }
    const uniqueList = makeUniqueWithIndex(
      driverIdList,
      (driverId, currentVal) => driverId === currentVal
    );

    const uniqueDriverIds = uniqueList.map(item => item[0]);

    this._zcFleet.getMultipleUserInfoFromUserProfileIds(uniqueDriverIds)
      .subscribe((res) => {
        this.assetListViolationsCurrentPage.forEach(item => {
          const data = res[item.driverId];
          if (data && data.firstName && data.lastName) {
            item.driverName = res[item.driverId].firstName + ' ' + res[item.driverId].lastName;
          } else if (uniqueDriverIds.includes(item.driverId)) {
            item.driverName = ERROR_MESSAGE.INVALID_DRIVER_ID;
          }
          item.driverNameAsync = of(item.driverName);
        });
      }, (error) => {
        console.log(error);
        this.assetListViolationsCurrentPage.forEach(item => {
          if (uniqueDriverIds.includes(item.driverId)) {
            item.driverNameAsync = of(ERROR_MESSAGE.INVALID_DRIVER_ID);
          }
        });
      });
  }

  private _sortAssetListViolations() {
    // sort by current _sortOption value
    const isSortAsc = this._sortOption.sort === 'asc';
    this.assetListViolations = this._sort.transform(
      this.assetListViolations, this._sortOption.sortBy, isSortAsc);
  }
}
