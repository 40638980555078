import { Component, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { ErrorHandling, FleetAllTripsModel, SearchFilterModel } from '@modules/dashboard3/dashboard3.models';

import { Data } from '@modules/dashboard3/services/data.service';
import { StorageService } from '@app-core/services/storage.service';
import { createMomentObjectUtc, getTimezoneString, calcSumTotalIncidents } from '@modules/shared/utils';

@Component({
  selector: 'app-all-trips-table',
  templateUrl: './all-trips-table.component.html',
  styleUrls: ['./all-trips-table.component.scss'],
})
export class AllTripsTableComponent implements OnInit , OnDestroy , OnChanges {
    @Input() public totalTrips: any;
    @Input() public filterOptions: SearchFilterModel;
    @Input() public driverName: string;
    @Input() public allAssets: any;
    @Input() public isShown = true;
    public tripListPage = [];
    public show = 'loading';
    public limit = 20;
    public sortBy = 'startTime';
    public sort = 'desc';
    public reset = false;
    public triplist: any[] = [];
    public tripdetails: any[] = [];
    public tablelimit = false;
    public cameraConnected: boolean;
    public homeLocationList = [];
    public isShowNextPageSpinner = false;
    public errorMessage = 'Something went wrong';

    private _offset = 0;
    private _modalSubscription: any;
    private _list = new BehaviorSubject([]);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public listData = this._list.asObservable();

    constructor(
        private _zcfleet: ZCFleetService,
        private _dateService: DateService,
        private _data: Data,
        private _router: Router,
        private _storageService: StorageService
    ) {
    }

    public ngOnInit() {
      this.show = 'loading';
    }

    public ngOnChanges() {
      if (this.isShown) {
        this.tabletRefreshList();
      }
    }

    public ngOnDestroy() {
      // Unsubscribe from filter change event
      if (this._modalSubscription) {
        this._modalSubscription.unsubscribe();
      }
    }

    public toggleTable() {
      this.tabletRefreshList();
    }

    /**
     * @description: function to refresh the table list with new details
     * @param:
     */
    public tabletRefreshList() {
      this._offset = 0;
      this.reset = true;
      this.limit = 20;
      this.isShowNextPageSpinner = true;
      this.getTripList()
        .then((res: any) => {
          this.show = res.length !== 0 ? 'data' : 'empty';
          this.isShowNextPageSpinner = false;
          this._list.next(res);
        })
        .catch(err => {
          this._getErrorMessage(err);
          this.show = 'error';
          this.isShowNextPageSpinner = false;
        });
    }

    /**
     * @description: function to get more trip list based on page change
     * @param: page details with limit and object
     */
    public getMoreData(page) {
      // update limit and offset values and call the API to get the paginated
      // values
      ({ limit: this.limit, offset: this._offset } = page);
      this.show = 'data';
      this.isShowNextPageSpinner = true;
      this.getTripList()
        .then((res: any) => {
          this.isShowNextPageSpinner = false;
          this._list.next(res);
        })
        .catch(err => {
          this._getErrorMessage(err);
          this.show = 'error';
          this.isShowNextPageSpinner = false;
        });
    }

    /**
     * @description: function to get trip list
     * @param: param options
     */
    public getTripList() {
      const { days, dutyType, minScore, maxScore, ids } = this.filterOptions;
      // When minscore and maxscore filter is applied and if ids list is empty
      // then return an empty array
      // Because when the ids are empty, the API returns all trips, which is not
      // desired
      if (minScore !== 0 || maxScore !== 100) {
        if (ids.length === 0) {
          return Promise.resolve([]);
        }
      }
      let date = { from: null, to: null };
      if (days ? days === 2 : 0) {
        date.from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
        date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
        if (
          this._dateService.customStartdate === undefined ||
                this._dateService.customEndDate === undefined
        ) {
          date = this._data.customRange.data;
          this._dateService.customStartdate = this._data.customRange.data.from;
          this._dateService.customEndDate = this._data.customRange.data.to;
        }
      } else {
        date = this._dateService.getDateRangeInISO(days);
      }
      this.homeLocationList = this._storageService.getStorageValue('HOME_LOCATION_ABV');

      const params = new FleetAllTripsModel(
        date.from,
        date.to,
        dutyType,
        this.limit,
        this._offset,
        this.sort,
        this.sortBy,
        this.homeLocationList
      );

      const OPTIONS = {
        ...params,
        driverIds: ids,
      };

      return new Promise((resolve, reject) => {
        if (this.isShown) {
          this.isShowNextPageSpinner = true;
        }
        this._zcfleet.getTripList(OPTIONS).subscribe(
          res => {
            this.isShowNextPageSpinner = false;
            this.allAssets.assets.map((asset) => {
              res.map((trip) => {
                if (trip._assetNumber === asset.assetId) {
                  if (asset.metadata && asset.metadata.assetNumber) {
                    trip.assetNumber = asset.metadata.assetNumber;
                  } else {
                    trip.assetNumber = 'N/A';
                  }
                }
              });
            });
            resolve(res);
          },
          err => {
            reject(err);
            this._getErrorMessage(err);
            this.show = 'error';
            this.isShowNextPageSpinner = false;
          }
        );
      });
    }

    public tripListPageChange(e) {
      setTimeout(() => {
        this.tripListPage = this._zcfleet.setTampered(e.list.map(
          (item) => {
            const clonedItem = cloneDeep(item);
            if (clonedItem && clonedItem.eventCount) {
              clonedItem.totalIncidentsEventType = calcSumTotalIncidents(clonedItem.eventCount);
            }
            clonedItem.startTimeMoment = createMomentObjectUtc(item.startTimeUTC).tz(
              getTimezoneString(item.timezoneOffset, item.firstLocation.latitude, item.firstLocation.longitude)
            );
            return clonedItem;
          })
        );
      }, 1);
    }
    /**
     * @description: function to navigate to particular trip details page
     * @param: trip details with trip and driver ids
     */
    public gotoTripDetail(trip) {
      this._router.navigate(['/trip-detail'], {
        queryParams: {
          tripId: trip.tripId,
          driverId: trip.driverId,
        },
      });
    }

    private _getErrorMessage(err: ErrorHandling) {
      console.error(`Received Error: ${err}`);
      const errorHanding = this._zcfleet.getErrorMessage(err);
      this.errorMessage = errorHanding.message;
    }
}
