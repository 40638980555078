import { Injectable, OnDestroy } from '@angular/core';
import { EventBusService } from './event-bus.service';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventBusToGaWiringService implements OnDestroy {
  private _handlerMapper: Record<string, any> = {};
  private _subscriber: Subscription;

  constructor(
    private _eventBusService: EventBusService,
    private _googleTagService: GoogleTagManagerService
  ) {}

  public initialize() {
    this._handlerMapper = this._getHandlerMapper();
    this._subscriber = this._eventBusService.onCategory(
      'ga',
      this._mapHandler.bind(this)
    );
  }

  public ngOnDestroy(): void {
    if (this._subscriber) {
      this._subscriber.unsubscribe();
    }
  }

  private _getHandlerMapper(): Record<string, any> {
    return {
      login: this._handleLoginEvent.bind(this),
      driverCardClick: this._handleDriverCardClick.bind(this),
      PERFORMANCE_REQUEST: this._trackingPerformanceRequest.bind(this),
      SUCCESS_REQUEST: this._trackingSuccessRequest.bind(this),
      NAVIGATION: this._trackingNavigation.bind(this),
    };
  }

  private _mapHandler(eventData: { eventName: string; value: any }) {
    const voidHandler = () => {};
    const handler = this._handlerMapper[eventData.eventName] || voidHandler;
    handler(eventData.value);
  }

  private _handleLoginEvent(value: any): void {
    this._googleTagService.login(value);
  }

  private _handleDriverCardClick(value: any): void {
    this._googleTagService.driverCardClick({
      driverName: value['driverName'],
      driverId: value['driverId'],
    });
  }

  private _trackingPerformanceRequest(eventValue: any): void {
    this._googleTagService.trackingPerformanceRequestEvent(
      eventValue.label,
      eventValue.time
    );
  }

  private _trackingSuccessRequest(eventValue: any): void {
    this._googleTagService.trackingSuccessRequestEvent(
      eventValue.label,
      eventValue.times
    );
  }

  private _trackingNavigation(eventValue: any): void {
    this._googleTagService.trackingNavigationEvent(
      eventValue.label,
      eventValue.times
    );
  }
}
