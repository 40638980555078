<!-- Trip table -->
<div class="zc-table-heading">
    <div class="table-name">
        <span>Trip Details</span>
    </div>
    <div class="zc-table-toggle">
        <a class="toggle-button" (click)="toggleTable()">
            <span class="label" *ngIf="!tableHidden">Hide All Trips {{tripCount}}</span>
            <span class="label" *ngIf="tableHidden">Show All Trips {{tripCount}}</span>
            <img class="dropdown-arrow" *ngIf="!tableHidden" src="assets/images/arrow_up_white.png">
            <img class="dropdown-arrow" *ngIf="tableHidden" src="assets/images/arrow_down_white.png">
        </a>
    </div>
</div>

<div [ngSwitch]="show" class="all-trip-table" [hidden]="tableHidden">
    <div *ngSwitchCase="'loading'"></div>

    <div *ngSwitchCase="'data'">
        <div class="row no-gutters zc-table-wrap">
            <table class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm">
                <thead>
                    <tr>
                        <th scope="col">
                                    <app-table-sort-header sortOn="startTime" [(sortBy)]="sortBy" [(sort)]="sort"
                                        (sortClicked)="refreshList()">Date/Time
                                    </app-table-sort-header>
                        </th>
                        <th scope="col">
                                <!-- TODO: Uncomment below line once location sort is supported -->
                                <!-- <app-table-sort-header sortOn="location" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="refreshList()">Start Location
                                </app-table-sort-header> -->
                                Start Location
                            </th>
                        <th scope="col">
                            <app-table-sort-header sortOn="driverName" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Driver
                            </app-table-sort-header>
                        </th>
                        <th class="w-10" scope="col">
                            <app-table-sort-header sortOn="total" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Total Incidents
                            </app-table-sort-header>
                        </th>

                        <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="tripDistance" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="refreshList()">Distance (MI)
                                </app-table-sort-header>
                            </th>
                        <th class="w-10" scope="col">
                            <app-table-sort-header sortOn="duration" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Duration (HH:MM)
                            </app-table-sort-header>
                        </th>

                        <th scope="col">
                            <app-table-sort-header sortOn="assetNumber" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Asset
                            </app-table-sort-header>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let trip of tripListPage;let i = index" (click)="gotoTripDetail(trip)">
                        <td>{{trip?.startTime | dateFormat}} {{ trip?.displayTimeZone | timeZone }}</td>
                        <td>{{trip?.location}}</td>
                        <td>{{trip?.driverName || trip?.driverId}}</td>
                        <td>{{trip?.total}}</td>
                        <td>{{trip?.tripDistance | distance: 'miles' |
                                number: '1.0-0'}}</td>
                        <td>{{trip?.duration | duration2: 'seconds':
                            'HH:MM' }}</td>
                        <td>{{trip?._assetNumber}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="paginator-wrap">
                <app-paginator3
                    [(reset)]="reset"
                    [pageLimit]="20"
                    [length]="totalTrips"
                    [data]="listData"
                    (pageChangeData)="tripListPageChange($event)"
                    (pageChange)="getMoreData($event)">
                </app-paginator3>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'">
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                    <span>
                        No trips found
                    </span>
                </div>
                <div class="note sub">
                    <span>Try different filters to view trips.</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'error'">
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="retry" *ngIf="showRetryButton">
                    <button class="btn btn-retry" (click)="getTripList()">Retry</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Trip table -->
