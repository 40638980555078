import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-package-config',
  templateUrl: './package-config.component.html',
  styleUrls: ['./package-config.component.scss'],
})
export class PackageConfigComponent implements OnInit {
  public accountsList: any[] = [];

  constructor(private _title: Title) {}

  public ngOnInit() {
    this._title.setTitle('Package Config - Zonar Coach');
  }
}
