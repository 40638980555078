<app-card [isSank]="true" [sortList]="sortList" [showSortList]="true"
  [menuOptionTemplate]="menuOption"
  (changeSortBy)="notifySortChange($event)">
  <div card-title
      class="name trip-recap-title"
      #tooltip="matTooltip"
      (click)="tooltip.toggle()"
      matTooltip="{{ nameTooltip$ | async }}"
      matTooltipClass="text-name-tooltip"
      [matTooltipPosition]="'below'">
      {{ displayName$ | async }}
  </div>
  <div card-body>
    <ng-container>
      <app-async-carousel
        [pageSize]="pageSize"
        [isFullLoading]="isLoading"
        [dataSource$]="source$"
        [newPageGenerator]="generateNewSlidesBinded"
        [itemTemplate]="tripCard"
        [skeletonTemplate]="skeletonTripCard"
        [trackBy]="trackBy"
        >
      </app-async-carousel>
    </ng-container>
  </div>
</app-card>

<ng-template #tripCard let-trip let-index="index" let-isSkeleton="isSkeleton">
  <div class="trip-recap-card">

    <!-- Mini map section -->
    <div class="mini-map-container"
      *ngIf="!isSkeleton; else fakeMap"
      [ngClass]="{ 'barberpole-border': trip.ongoing }">
      <app-map mapId="eventMap_{{index}}" [ongoing]="trip.ongoing" [mapheight]="!trip.ongoing ? 200: 192"
      [markerList]="trip?.markerList" [latlonList]="trip?.pathList"></app-map>
    </div>
    <ng-template #fakeMap >
      <app-custom-skeleton-loader [theme]="{
          height: '200px',
          width: '100%',
          borderRadius: '0'
        }"></app-custom-skeleton-loader>
    </ng-template>
    <!-- End of minimap section -->

    <div class="content">
      <ng-container *ngTemplateOutlet="tripLabel; context: { $implicit: trip, isSkeleton }"></ng-container>
      <ng-container *ngTemplateOutlet="tripTextSummary; context: { $implicit: trip, isSkeleton }"></ng-container>

    </div>

    <ng-container *ngTemplateOutlet="buttonViewDetails; context: { $implicit: trip, isSkeleton }"></ng-container>
  </div>
</ng-template>

<ng-template #skeletonTripCard >
  <ng-container *ngTemplateOutlet="tripCard; context: { isSkeleton: true }"></ng-container>
</ng-template>

<ng-template #tripLabel let-trip let-isSkeleton="isSkeleton">

  <div class="trip-name">
    <ng-container *ngIf="!isSkeleton; else fakeTripName">
      {{trip?.driverName}}'s {{ getGreetingText(trip?.startTime)}} Trip
    </ng-container>
    <ng-template #fakeTripName>
      <app-custom-skeleton-loader [theme]="{ height: '16px', width: '193px' }"></app-custom-skeleton-loader>
    </ng-template>
  </div>

  <div class="from-to" *ngIf="!trip?.ongoing; else ongoingTrip">
    <div class="placeholder" *ngIf="!isSkeleton">
      {{ longestFromToText }}
    </div>
    <div class="real" *ngIf="!isSkeleton; else fakeFromTo">
      {{trip?.startPoint}} to {{trip?.endPoint}}
    </div>

    <ng-template #fakeFromTo>
      <app-custom-skeleton-loader [theme]="{ width: '260px', height: '14px' }"></app-custom-skeleton-loader>
    </ng-template>
  </div>

  <ng-container *ngIf="!isSkeleton; else incidentSummaryPlaceholder">
    <div *ngIf="trip?.cameraConnected && !trip.ongoing; else tamperedBlock" class="label-row">
      <button class="label incident-number">{{ totalIncidentsEventType(trip?.eventCount) }}
        <span *ngIf="totalIncidentsEventType(trip?.eventCount) > 1">Incidents</span>
        <span *ngIf="totalIncidentsEventType(trip?.eventCount) <= 1">Incident</span>
      </button>
      <button class="label top-incident" [style.background]="trip?.incident_type?.color"
        *ngIf="trip?.eventCount?.total > 0">{{ trip?.incident_type?.name }}</button>
    </div>

    <ng-template #tamperedBlock>
      <div *ngIf="!trip.ongoing; else tamperPlaceholder" class="tampered">
        <img src="../../../../../../../assets/images/exclamation-red-circle.svg">
        <span>Camera Not Connected</span>
      </div>
      <ng-template #tamperPlaceholder>
        <div class="tamper-placeholder"></div>
      </ng-template>
    </ng-template>

  </ng-container>

  <ng-template #ongoingTrip>
    <div class="from-to ongoing-trip">
      Vehicle in transit
    </div>
  </ng-template>

  <ng-template #incidentSummaryPlaceholder >
    <div class="incident-summary-placeholder" ></div>
  </ng-template>

</ng-template>

<ng-template #tripTextSummary let-trip let-isSkeleton="isSkeleton">
  <div class="trip-summary">
    <table style="width:100%" class="incident_table">
      <tr>
        <td>Start Date/Time</td>
        <td>
          <ng-container *ngIf="!isSkeleton; else fakeCell" >
            {{trip?.startTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z'}}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Duration</td>
        <td>
          <ng-container *ngIf="!isSkeleton; else fakeCell" >
            {{trip?.duration | duration2:'seconds':'h:m' }}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>Distance</td>
        <td>
          <ng-container *ngIf="!isSkeleton; else fakeCell" >
            {{trip?.tripDistance | distance: 'miles' |number: '1.0-0'}} mi
          </ng-container>
        </td>
      </tr>
    </table>
  </div>

  <ng-template #fakeCell>
    <app-custom-skeleton-loader [theme]="{ width: '160px', height: '16px'}"></app-custom-skeleton-loader>
  </ng-template>
</ng-template>

<ng-template #buttonViewDetails let-trip let-isSkeleton="isSkeleton">
  <div class="trip-detail-link">
    <a
      *ngIf="!isSkeleton; else fakeDetailBtn"
      [routerLink]="['/trip-detail']"
      [queryParams]="{ tripId: trip.tripId, driverId: trip.driverId}"
    >View Details</a>

    <ng-template #fakeDetailBtn>
      <app-custom-skeleton-loader [theme]="{ width: '100px', height: '26px'}"></app-custom-skeleton-loader>
    </ng-template>
  </div>
</ng-template>

<ng-template #menuOption>
  <div class="btn-group table-view" role="group" >
    <span class="label">Table View</span>
  </div>
</ng-template>
