import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';

import { finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { LoadingService } from '@app-core/services/loading.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { PermissionManagerService } from '@app-core/services/permission-manager.service';
import { IncidentChallenge, PopupCancel } from '../incident-button-control/incident-button-control.component';

// millisecond
const POPUP_TIMEOUT = 5000;
const SUCCESS_MARK_AS_SAFE = 'Incident marked as safe';
const SUCCESS_REJECT = 'Incident bug rejected';
const dvrStatusList = ['PENDING', 'UNAVAILABLE', 'FINISHED'];

export interface IncidentData {
  driverId?: string;
  tripId?: string;
  eventIndex?: number;
  eventType?: string;
  mediaLink?: string;
  challengeAccepted?: boolean;
  challengeRaised?: boolean;
  challengeResolved?: boolean;
  speedSign?: any;
  speed?: any;
  isLoadingSpeedData?: boolean; // Support for loading speed data of saved incident table
}

@Component({
  selector: 'app-incident-media-control',
  templateUrl: './incident-media-control.component.html',
  styleUrls: ['./incident-media-control.component.scss'],
})
export class IncidentMediaControlComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public incidentData: IncidentData = {};
  @Input() public switch: boolean;
  @Input() public selectedRowData;
  @Input() public hideViewTripButton = false;
  @Input() public showSmallSizeMediaPanel = false;
  @Input() public notLoadingState$: Observable<boolean>;
  @Input() public noMediaText: string;

  @Output() public challengeUpdated = new EventEmitter<any>();
  @Output() public isDisabledClick = new EventEmitter<boolean>();

  public popupCancel: PopupCancel = {
    showPopup: '',
    hideCancel: false,
  };
  public timeoutforReportBug;
  public timeoutforAccept;
  public isShowingActualSpeed = true;
  public isPostedSpeedImageAvailable = false;
  public mediaSrc = '';
  public incidentEventType = '';
  public actualSpeed: number;
  public postedSpeed: number;
  public isEmptyData = false;
  public mediaStatus = null;
  public isChallengeUpdating = false;
  public isDvrEvent = false;
  public hasSpeedSign = true;

  private _destroy$ = new Subject();

  constructor(
    private _zcfleet: ZCFleetService,
    private _snackbarService: SnackbarService,
    private _loading: LoadingService,
    private _permission: PermissionManagerService
  ) {}

  public ngOnInit() {
    this._permission.requestPermissionCheck('fleet-ridecam-plus');
  }

  public ngOnChanges(changes: SimpleChanges) {
    const incidentChanges =
      changes.incidentData && changes.incidentData.currentValue;
    if (incidentChanges) {
      this.isEmptyData = Object.keys(incidentChanges).length === 0;
      this.incidentEventType = incidentChanges.eventType;
      this.isShowingActualSpeed = true;
      this.mediaSrc = incidentChanges.mediaLink;
      this.mediaStatus = incidentChanges.eventVideoFilenameUploadStatus
        ? incidentChanges.eventVideoFilenameUploadStatus.status
        : 'UPLOADED';
      this.isDvrEvent = dvrStatusList.includes(this.mediaStatus);

      // ZCW-545: keep solution from LM, converting meter per sec to km per hour
      if (incidentChanges.eventType === 'Traffic-Speed-Violated') {
        if (incidentChanges.tripId.includes('trip')) {
          // converting meter per sec to km per hour
          this.actualSpeed = incidentChanges.speed * 3.6;
        } else {
          this.actualSpeed = incidentChanges.speed;
        }
        this.postedSpeed = incidentChanges.speedSign.speedSignValue;
      }
    }
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this.isDvrEvent = false;
  }

  /**
   * @description: function to check whether the http link is a image or not
   * @param: the src
   */
  public isImage(src) {
    return src.indexOf('.jpg') > -1;
  }

  /**
   * @description a snackbar with suitable message is displayed for 5 seconds with a cancel button
   * if the cancel button is pressed within 5 seconds, then no action should be taken
   * else a patch call is made with param body
   * @param event contains type of popup and body payload
   */
  public updateChallenge(event: IncidentChallenge): void {
    if (!this.isChallengeUpdating) {
      this.isChallengeUpdating = true;
      this.isDisabledClick.emit(this.isChallengeUpdating);

      if (event.type === 'bug') { //TO-DO: Report bug button is deprecated. Remove this case when everything is stable
        this.timeoutforReportBug = this._setTimeOutPopUp(event.body);
      } else {
        this.timeoutforAccept = this._setTimeOutPopUp(event.body);
      }

    }
  }

  /**
   *
   * @description: show pop up to confirm report bug submit
   * @param event: contains pop up type
   */
  public snackbarCancel(event: Record<string, string>) {
    if (event.status === 'bug' || event.status === 'accept') {
      clearTimeout(this.timeoutforReportBug);
      clearTimeout(this.timeoutforAccept);

      this.isChallengeUpdating = false;
      this.isDisabledClick.emit(this.isChallengeUpdating);
    }
  }

  /**
   * @description to set up correct media source Actual Speed for speeding incident
   */
  public showActualSpeed() {
    this.isShowingActualSpeed = true;
    this.mediaSrc = this.incidentData && this.incidentData.mediaLink;
  }

  /**
   * @description to set up correct media source Posted Speed for speeding incident
   */
  public showPostedSpeed() {
    this.isShowingActualSpeed = false;
    this.mediaSrc =
      this.incidentData.speedSign &&
      this.incidentData.speedSign.eventVideoFilename;
    // If no posted speed data, keep showing header and footer
    this.hasSpeedSign = !!this.mediaSrc;
  }

  private _updateFleetIncidents(incident, body) {
    const eventIndex = Number(incident.eventIndex);
    const emitChallengeUpdate = () => {
      this._loading.hide();
      this.isChallengeUpdating = false;
      this.isDisabledClick.emit(this.isChallengeUpdating);
    };
    this._zcfleet
      .updateFleetIncidents(
        incident.driverId,
        incident.tripId,
        eventIndex,
        body
      ).pipe(
        takeUntil(this._destroy$),
        finalize(() => {
          emitChallengeUpdate();
        })
      )
      .subscribe(
        () => {
          // We must modify selectedRowData here, or else the upper parent (Trip Details) will have
          // no information about the operation to be reject or mark as safe and update their state accordingly
          this.selectedRowData.challengeAccepted = body.isAccepted;
          // if challenges updated successfully (mark as safe or reject), challengeRaised and challengeResolved always true
          this.selectedRowData.challengeRaised = true;
          this.selectedRowData.challengeResolved = true;

          this.challengeUpdated.emit(this.selectedRowData);
          if (body.isAccepted) {
            this._snackbarService.success(SUCCESS_MARK_AS_SAFE, 'Close');
          } else {
            this._snackbarService.success(SUCCESS_REJECT, 'Close');
          }
        },
        (err) => {
          console.log(err);
          this._snackbarService.error(
            `Error while ${body.isAccepted ? 'marking selected incident as safe' : 'reject incident'} `,
            'Try Again'
          ).subscribe(() => {
            this._updateFleetIncidents(incident, body);
          });
        }
      );
  }

  private _setTimeOutPopUp(value: { isAccepted?: boolean; isReportBug?: boolean }) {
    return setTimeout(() => {
      this._loading.show();
      const body = value;

      this.incidentData.challengeAccepted = !!value.isAccepted;
      this.incidentData.challengeResolved = true;
      this._updateFleetIncidents(this.incidentData, body);

      this.popupCancel.hideCancel = true;
      this.popupCancel.showPopup = '';
    }, POPUP_TIMEOUT);
  }
}
