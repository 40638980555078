<div class="row leadboard-heading">
    <div class="col-md-6 p-0 heading-left">
        <span>Drivers Ranked by Score (100-Point Scale)</span>
    </div>
    <div class="col-md-6 p-0 heading-right">
        <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn" [class.selected]="leadboardAsc" (click)="toggleLeaderboardOrder()">Low-High
                Score
            </button>
            <button class="btn" [class.selected]="!leadboardAsc" (click)="toggleLeaderboardOrder()">High-Low
                Score
            </button>
        </div>
    </div>
</div>



<div [ngSwitch]="show" class="leaderboard-content">
    <div *ngSwitchCase="'loading'"></div>

    <div *ngSwitchCase="'data'">
        <div class="row leadboard">
            <div class="col-12 col-sm-6 col-lg-3  leadboard-card-wrap" *ngFor="let driver of leadboardList | slice:0:8">
                <app-leadboard-card [data]="driver" [days]="filterOptions.days" (cardClicked)="gotoDriverDetail(driver)"></app-leadboard-card>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'">
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                    <span>
                        No drivers found
                    </span>
                </div>
                <div class="note sub">
                    <span>Try different filters to view drivers.</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'error'">
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                  <span> {{ errorMessage }}</span>
                </div>
                <div class="retry" *ngIf="showRetryButton">
                    <button class="btn btn-retry" (click)="getData()">Retry</button>
                </div>
            </div>
        </div>
    </div>
</div>
