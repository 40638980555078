import {
  Component, OnChanges, OnInit, QueryList,
  SimpleChanges, ViewChild, ViewChildren,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';

import { ZCFleetService } from './../../services/zcfleet.service';
import { AccessService } from '@app-core/zcw-auth-module/services/access.service';
import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { SnackbarService } from '@app-core/services/snackbar.service';

import { ASSET_TYPES, ASSET_VOLUME, ASSET_ACTION } from './../../dashboard3.constants';

import { Paginator3Component } from '@modules/dashboard3/components/paginator3/paginator3.component';
import { SettingUploadCameraByIdComponent, AddImeiEventResponse }
  from './setting-upload-camera-by-id/setting-upload-camera-by-id.component';
import { SettingUploadCameraFromFileComponent } from './setting-upload-camera-from-file/setting-upload-camera-from-file.component';
import { PermissionManagerService } from '@app-core/services/permission-manager.service';
import { ModalParamsModel } from '@modules/dashboard3/dashboard3.models';
import {
  SnackbarCustomMessageComponent,
  SnackBarMsg,
} from '@modules/dashboard3/components/snackbar-custom-message/snackbar-custom-message.component';
import { ExcelService } from '@app-core/services/excel.service';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { FlagService } from '@app-core/services/flag.service';
import { CLEAR_FILTER_UNASSIGN_CAMERA_KEY } from '@app-core/constants/constants';
import { FilterCommand, FilterList, FilterOptions } from '@modules/dashboard3/components/search-filter-table/search-filter-table.component';
import { SortDirection } from '@angular/material/sort';

export interface UnassociationCamera {
  assetId: string;
  assetNumber?: string;
  cameraIMEI: string;
  index?: number;
  pageNumber?: number;
}

export interface UnusedCamera {
  id?: string;
  assetId: string;
  index?: number;
  pageNumber?: number;
  isSelected: boolean;
  status: string;
  cameraType?: number;
  typeInfo?: any;
  zonarSerialNum?: string;
}

export interface AssetsList {
  assetCount: number;
  assets: any;
  cameraCount: number;
  limit: number;
  offset: number;
  standardPackages?: any;
}

interface AssetResponse {
  assetList: any[];
  assetCount: number;
}

const DUTY_TYPE_FILTER_OPTIONS = [
  {id: 'heavy', name:'Heavy'},
  {id: 'medium', name:'Medium'},
  {id: 'light', name:'Light'},
  {id: 'Unknown', name:'Unknown'},
];

const SNACKBAR_DURATION = 10000;
const ASSET_PER_PAGE = 20;
const FIRST_PAGE = 1;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class Settings3Component implements OnInit, OnChanges {
    @ViewChildren(PopoverDirective) public popover: QueryList<PopoverDirective>;
    @ViewChild(Paginator3Component) public paginator: Paginator3Component;
    @ViewChild('unassignedTablePaginator') public unassignedTablePaginator: Paginator3Component;

    public assetTypes = ASSET_TYPES;
    public assetVolume = ASSET_VOLUME;
    public assetAction = ASSET_ACTION;
    public isShowDeleteCameras = false;
    public isShowCheckBoxAndActionColumn = false;
    public isSelectedAll = false;
    public noCameras = 'No Camera Associated';
    public selectedUnusedCameras: UnusedCamera[] = []; // selected item
    public unusedCameraDataList: UnusedCamera[] = []; // full list
    public isSearchNoData = false;
    public isNetworkError = false;
    public isNetworkErrorAC = false;
    public isGetCameraError = false;
    public isGetAssetError = false;
    public cameraImeiSearchFilter = '';
    public refetchAssetFilter = false;
    public dropdownFilterOptions: Record<string, FilterOptions> = {};
    public filterList: FilterList = {
      hasDateFilter: false,
      hasAssetFilter: true,
      hasDriverFilter: false,
      hasIncidentTypeFilter: false,
      hasLocationFilter: false,
      hasCameraImeisFilter: true,
      hasDutyTypeFilter: true,
    };
    public assetConfigTableNoResult = false;
    public assetPerPageLimit = ASSET_PER_PAGE;

    public editParamsModal: ModalParamsModel = {
      state: false,
      component: 'app-edit-modal',
    };

    public removeParamsModal: ModalParamsModel = {
      state: false,
      component: 'app-remove-camera-modal',
    };

    public imeiList: string[] = [];

    public configuration: any;

    public assetsPageList = [];
    public assetPageListFiltered = [];  // filtered data from assetsPageList
    public assetsPerPageList = [];
    public reset = false;
    public resetUnassignedCamerasTable = false;
    public readOnly = false;
    public zonarRegEx = new RegExp('^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(zonarsystems)\.com$');
    public limit = 500;
    public sortBy = 'assetNumber';
    public sortAssetConfig = 'asc';
    public sortUnassignedCamera = 'asc';
    public totalAssets = 0;
    public assetsList: any[] = [];
    public removeDevices: UnusedCamera[] = [];
    public listRemoveError: UnusedCamera[] = [];
    public unusedCameraListLimit = 10;
    public totalCameras: number;
    public bsModalRef: BsModalRef;
    public activePage: number;
    public sort: SortDirection = 'desc';
    public activeUnassignedCamerasPage: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    public asyncFlag: object = {}; // TODO: Conver to dimensional array
    // eslint-disable-next-line @typescript-eslint/ban-types
    public context: object;

    private _unusedCameraList = new BehaviorSubject([]);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public unusedCameraList = this._unusedCameraList.asObservable();

    // Behavior subject to pass data of each page into the paginator component
    private _list = new BehaviorSubject([]);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public listData = this._list.asObservable();

    private _offset = 0;
    private _filterCommands: FilterCommand[] = [];

    constructor(
        private _router: Router,
        private _loading: LoadingService,
        private _toast: ToasterService,
        private _zcFleet: ZCFleetService,
        private _title: Title,
        private _modalService: BsModalService,
        private _accessService: AccessService,
        private _permissionManager: PermissionManagerService,
        private _snackbarService: SnackbarService,
        private _excelService: ExcelService,
        private _networkService: TrackNetworkStatusService,
        private _flagService: FlagService
    ) {}

    public ngOnInit() {
      this.checkIfReadablePage();
      this._getSettingsConfig();
      this.refreshAssetsList();
      this.refreshCameraList();
      this._title.setTitle('Settings - Zonar Coach');
      this._permissionManager.requestPermissionCheck('fleet-ridecam-plus');
      const userInfo = JSON.parse(localStorage.getItem('userProfile'));
      if (userInfo && userInfo.email && this.zonarRegEx.test(userInfo.email)) {
        this.isShowCheckBoxAndActionColumn = true;
        console.log('Zonar Internal User recognized');
      }
    }
    public ngOnChanges(_: SimpleChanges): void {
      this.refreshAssetsList();
      this.refreshCameraList();
    }

    /**
     * @description: function to update the settings configuration based on particular fleet settings
     * @param: duty type and object
     */
    public updateSettingsConfig(dutyType, ob) {
      this._loading.show();

      this._zcFleet.updateDutyTypeConfig(dutyType, ob).subscribe(
        res => {
          this._loading.hide();
          this.configuration = res;
          this._toast.success('Duty type updated successfully');
        },
        err => {
          this._loading.hide();
          console.log(err);
          this._toast.error('Error while updating Duty type');
        }
      );
    }

    public showHeaderCheckbox() {
      return (this.isShowCheckBoxAndActionColumn && !this.isSearchNoData && !this.isNetworkError && !this.isGetCameraError);
    }

    /**
     * @description: function to refresh asset list
     * @param:
     */
    public refreshAssetsList() {
      // set the offset to 0 and set reset flag to true
      this._offset = 0;
      this.assetsList = [];
      // when no filter applied
      if (!this._filterCommands.length) {
        this._getFullAssetList();
      } else { // when applying filters
        this._getFullAssetListFiltered();
      }
    }

    public refreshUnusedCameraList() {
      // set the offset to 0 and set reset flag to true
      this._offset = 0;
      this.reset = true;
      if (this.cameraImeiSearchFilter !== '' && this.cameraImeiSearchFilter) {
        this.refreshCameraListByImei();
      } else {
        this.isSearchNoData = false;
        this.refreshCameraList();
      }
    }

    public refreshUnassignedCameras() {
      this.isSelectedAll = false;
      this.isShowDeleteCameras = false;
      this.selectedUnusedCameras = [];
      this.unusedCameraDataList = [];
    }

    /**
     * @description: function to refresh cameras list
     * @param:
     */
    public refreshCameraList() {
      this.getCameras({ activePage: 1 });
      this.resetUnassignedCamerasTable = true;
    }

    public refreshCameraListByImei() {
      this.getCamerasByImei(this.cameraImeiSearchFilter);
      this.resetUnassignedCamerasTable = true;
    }

    public refreshCameraListByActivePage(page: number) {
      this.getCameras({ activePage: page });
      this.onCurrentUnassignedCamerasPageChange(page);
      this.unassignedTablePaginator.setPage(page);
    }

    public getCameras(page) {
      this.refreshUnassignedCameras();
      this._loading.show();
      this._zcFleet.getUnassignedDevices(
        page.activePage, this.unusedCameraListLimit, 'zonarSerialNum:' + this.sortUnassignedCamera).subscribe(
        res => {
          this.updateCamerasListForUCTable(res);
        },
        (error) => {
          this.showUnassignedDevicesError(error);
        }
      );
    }

    public getCamerasByImei(cameraImei) {
      this.refreshUnassignedCameras();
      this._loading.show();
      this._zcFleet.getUnassignedDevicesByImei(cameraImei, 'zonarSerialNum:' + this.sortUnassignedCamera).subscribe(
        res => {
          this.updateCamerasListForUCTable(res);
        },
        (error) => {
          this.showUnassignedDevicesError(error);
        }
      );
    }

    public updateCamerasListForUCTable(res) {
      this.isNetworkError = false;
      this.isGetCameraError = false;
      this.totalCameras = res.totalCount;
      // Code use after call new API
      if (this.totalCameras !== 0) {
        this.isSearchNoData = false;
      } else {
        this.isSearchNoData = true;
      }
      let listItemError: string[] = [];
      if (res.results) {
        this.unusedCameraDataList = [...res.results];
        this.unusedCameraDataList.forEach(item => {
          if (item) {
            item.isSelected = false;
            item.cameraType = 1;
            this._checkZonarSerialNum(item);
            this._checkTypeInfoImei(item);
            if (this.listRemoveError?.length > 0
              && this.listRemoveError.find((deviceError) => deviceError.zonarSerialNum === item.zonarSerialNum)) {
              item.isSelected = true;
            }
          }
        });
        if (this.listRemoveError?.length > 0) {
          listItemError = [...this.listRemoveError.map((item) => item.zonarSerialNum)];
          this.selectedUnusedCameras = this.unusedCameraDataList.filter((device) => listItemError.includes(device.zonarSerialNum));
          this.updateShowDeleteCamerasFlagStatus();
          this.updateSelectAllFlagStatus();
        }
      }
      this._unusedCameraList.next(this.unusedCameraDataList);
      this._loading.hide();
    }

    public showUnassignedDevicesError(error) {
      if (!this._networkService.isOnline) {
        console.log('Error network when get unassigned Devices!');
        this.isNetworkError = true;
      } else {
        console.log('Error when get unassigned Devices - error: ' + error);
        this.isGetCameraError = true;
      }
      this.totalCameras = 0;
      this.unusedCameraDataList = [];
      this._loading.hide();
    }

    public showAssetConfigurationError(error) {
      if (!this._networkService.isOnline) {
        console.log('Error network when get Asset Configuration!');
        this.isNetworkErrorAC = true;
      } else {
        console.log('Error when get Asset Configuration - error: ' + error);
        this.isGetAssetError = true;
      }
      this.totalAssets = 0;
      this.assetsPageList = [];
      this.assetsPerPageList = [];
      this._loading.hide();
    }

    /**
     * @description: function to get more asset list based on page details
     * @param: page details such as limit and offset
     */
    public getMoreData(page) {
      this.activePage = page.activePage;
      this.updateAssetTableByActivePage();
    }

    public onCurrentPageChange(activePage: number) {
      this.activePage = activePage;
    }

    public onCurrentUnassignedCamerasPageChange(activePage: number) {
      if (this.activeUnassignedCamerasPage !== activePage) {
        this.activeUnassignedCamerasPage = activePage;
        this.listRemoveError = [];
      }
    }

    public updateAssetTableByActivePage(isFiltersUpdated = false) {
      this.assetsPerPageList = [];
      // update paginator component and table page to first page if filter value changed
      if (isFiltersUpdated) {
        this.reset = true; // paginator
        this.onCurrentPageChange(FIRST_PAGE); // table assets config
      }
      const startPage = (this.activePage - 1) * this.assetPerPageLimit;
      const endPage = startPage + this.assetPerPageLimit;
      // check if the table is filtered
      if (this._filterCommands && this._filterCommands.length) {
        this.totalAssets = this.assetPageListFiltered.length;
        for (let index = startPage; index < endPage; index++) {
          if (this.assetPageListFiltered[index]) {
            this.assetsPerPageList.push(this.assetPageListFiltered[index]);
          } else {
            break;
          }
        }
      } else {
        for (let index = startPage; index < endPage; index++) {
          if (this.assetsPageList[index]) {
            this.totalAssets = this.assetsPageList.length;  // Update the paginator page length
            this.assetsPerPageList.push(this.assetsPageList[index]);
          } else {
            break;
          }
        }
      }
    }

    /**
     * @description: function to check the page change
     * @param: event details
     */
    public pageChange(
      event,
      getFullDutyOptions = true
    ) {
      setTimeout(() => {
        this.assetsPageList = event.list.map(this.addModelName.bind(this));
        this.mapToFeedbackVolume();
        this.updateAssetTableByActivePage();
        // Before create filter duty type options, check item with no duty type
        this._checkDutyType();

        if (!this._filterCommands.length || !getFullDutyOptions) {

          // asset filter setup
          this.dropdownFilterOptions = {
            ...this.dropdownFilterOptions,
            ...this._buildDataFilterOption(
              'assetFilter',
              'assetId',
              'assetNumber'
            )};

          // camera imei filter setup
          this.dropdownFilterOptions = {
            ...this.dropdownFilterOptions,
            ...this._buildDataFilterOption(
              'cameraImeisFilter',
              'cameraIMEI',
              'cameraIMEI'
            )};

          if (getFullDutyOptions) {
          // dutyType filter setup
            this.dropdownFilterOptions = {
              ...this.dropdownFilterOptions,
              ...this._buildDutyTypeFilterOptions(),
            };
          } else {
            // camera imei filter setup
            this.dropdownFilterOptions = {
              ...this.dropdownFilterOptions,
              ...this._buildDataFilterOption(
                'dutyTypeFilter',
                'dutyType',
                'dutyType'
              )};
          }
        }
      }, 1);
    }

    /**
     * @description: function include CameraIMEI From field tags
     * @param:
     * @returns: assets list
     */
    public includeIMEIFromTag(camera) {
      const tagPrefix = 'cameraIMEI:';
      const cameraIMEIMetadata = (camera.tags || []).filter(tag => tag.indexOf(tagPrefix) !== -1)
        .map(tag => tag.substring(tagPrefix.length))[0];
      camera['cameraIMEI'] = cameraIMEIMetadata || 'No Camera Associated';
      return camera;
    }

    public addModelName(asset) {
      // check camera IMEI
      asset = this.includeIMEIFromTag(asset);
      return asset;
    }

    /**
     * @description: function show modal upload camera by id component
     */
    public openModalUploadCameraByIdComponent() {
      this.bsModalRef = this._modalService.show(SettingUploadCameraByIdComponent);
      this.bsModalRef.content.addCameraIMEIResponse.subscribe((result: AddImeiEventResponse[]) => {
        if (result) {
          this._flagService.changeFlag(CLEAR_FILTER_UNASSIGN_CAMERA_KEY, true);
          const failedList: AddImeiEventResponse[] = [];
          result.forEach(element => {
            if (!element.deviceId && element.err) {
              failedList.push(element);
            }
          });

          if (failedList.length < result.length) {
            this.refreshCameraList();
          }
        }
      });
    }

    /**
     * @description: function show modal upload camera from file component
     */
    public openModalUploadCameraFromFile() {
      const config = {
        class: 'import-from-file-modal',
      };
      this.bsModalRef = this._modalService.show(SettingUploadCameraFromFileComponent, config);
      this.bsModalRef.content.isUploadBulkReload.subscribe(result => {
        if (result) {
          this.refreshCameraList();
        }
      }
      );
    }

    /**
     * @description: function to map values based on feedback value
     * @param:
     * @returns: the mapped feedback volume string
     */
    public mapToFeedbackVolume() {
      this.assetsPageList.map(x => {
        switch (x.metadata.notificationLevel) {
          case 'loud':
            x.deviceVolume = 'Max';
            break;
          case 'silent':
            x.deviceVolume = 'Muted';
            break;
          default:
            x.deviceVolume = 'On';
            break;
        }
      });
    }

    /**
     * @description: function to update asset details
     * @param: asset details, dutytype and device volume
     */
    public updateAssets(asset, dutyType, deviceVolume?) {
      const assetId = asset.assetId;
      let param;
      if (deviceVolume) {
        param = {
          notificationLevel: dutyType,
        };
      } else {
        param = {
          dutyType,
        };
      }
      this._loading.show();
      this._zcFleet.updateAssets(assetId, param).subscribe(
        () => {
          this._loading.hide();
          if (deviceVolume) {
            asset.deviceVolume = this.assetVolume.filter(x => x.value === dutyType)[0].label;
            this._toast.success('Device Volume updated successfully');
          } else {
            asset.dutyType = dutyType;
            this._toast.success('Duty type updated successfully');
          }
          this.refreshAssetsList();
        },
        err => {
          this._loading.hide();
          console.log(err);
          if (!this._networkService.isOnline) {
            this.isNetworkErrorAC = true;
            this.totalAssets = 0;
          }
          if (deviceVolume) {
            this._toast.error('Error while updating device volume');
          } else {
            this._toast.error('Error while updating duty type');
          }
        }
      );
    }

    /**
     * @description: function to navigate to driver detail page
     * @param: driver details
     */
    public driverChange(driver) {
      if (driver && driver.driverId) {
        this._router.navigate(['/driver-detail'], {
          queryParams: {
            driverId: driver.driverId,
            driverName: driver.driverName || driver.driverId,
          },
        });
      }
    }

    public unassignCamChange(cameraImei) {
      //Call API
      this.cameraImeiSearchFilter = cameraImei;
      this.refreshUnusedCameraList();
    }

    /**
     * @description: function to navigate to dashboard page
     * @param:
     */
    public gotoFleet() {
      this._router.navigate(['/dashboard']);
    }

    /**
     * @description: function to check if the setting page is readonly to user or not
     * @param:
     */
    public checkIfReadablePage() {
      this._accessService.hasPermission('setting', 'U').then(value => this.readOnly = !value);
    }

    public getUnassociationOfIMEIInfo(infoCamera: UnassociationCamera): UnassociationCamera {
      return this.context = infoCamera;
    }

    public updateTableAsset(infoCamera: UnassociationCamera) {
      for (const asset of this.assetsPageList) {
        if (infoCamera.assetId === asset['assetId'] && infoCamera.cameraIMEI === asset['cameraIMEI']) {
          asset['cameraIMEI'] = 'No Camera Associated';
        }
      }
    }

    public disassociateCameraFromAsset(infoCamera: UnassociationCamera) {
      if (!this.asyncFlag[infoCamera.pageNumber]) {
        this.asyncFlag[infoCamera.pageNumber] = {};
      }
      this.asyncFlag[infoCamera.pageNumber][infoCamera.index] = true;
      this._zcFleet.disassociateCameraFromAsset(infoCamera.assetId, infoCamera.cameraIMEI).subscribe(
        () => {
          this.updateTableAsset(infoCamera);
          this.refreshCameraList();
          this._toast.success(`IMEI ${infoCamera.cameraIMEI} disassociated successfully`);
          this.paginator.setDirty();
        },
        (err) => {
          console.log(err);
          this._toast.error(`IMEI ${infoCamera.cameraIMEI} disassociation failed`);
          this.asyncFlag[infoCamera.pageNumber][infoCamera.index] = false;
        }, () => {
          this.asyncFlag[infoCamera.pageNumber][infoCamera.index] = false;
        }
      );
    }

    public toggleDeleteCameras() {
      setTimeout(() => {
        this.removeParamsModal.state = true;
        this._zcFleet.removeCameraModal.next(this.removeParamsModal);
      });
    }

    public removeMultipleCameras() {
      this.imeiList = this.selectedUnusedCameras.map(camera => camera.typeInfo.imei);
      this.toggleDeleteCameras();
    }

    public openModal(camera: any) {
      this.imeiList = [
        camera.typeInfo.imei,
      ];
      this.toggleDeleteCameras();
    }

    public toggleExport() {
      console.log('toggleExport!');
    }

    public toggleSelectAll(): void {
      if (!this.isSelectedAll) {
        this.updateCheckAllFlags(true);
        this.selectedUnusedCameras = [...this.unusedCameraDataList];
      } else {
        this.updateCheckAllFlags(false);
        this.selectedUnusedCameras = [];
      }
    }

    public onItemClick(_$event: any, item: UnusedCamera): void {
      const found = this.isSelected(item);
      if (!found) {
        this.addSelected(item);
      } else {
        this.removeSelected(item);
      }
      this.updateShowDeleteCamerasFlagStatus();
      this.updateSelectAllFlagStatus();
    }

    public updateShowDeleteCamerasFlagStatus() {
      if (this.selectedUnusedCameras && this.selectedUnusedCameras.length > 0) {
        this.isShowDeleteCameras = true;
      } else {
        this.isShowDeleteCameras = false;
      }
    }

    public updateSelectAllFlagStatus() {
      if (this.selectedUnusedCameras.length === this.unusedCameraDataList.length) {
        this.isSelectedAll = true;
      } else {
        this.isSelectedAll = false;
      }
    }

    public isSelected(selectedItem: UnusedCamera): boolean {
      return this.selectedUnusedCameras.some(item => selectedItem.id === item.id);
    }

    public onRemoveCamera(isRetry?: boolean) {
      if (!isRetry) {
        this.removeDevices = this.unusedCameraDataList.filter(camera => this.imeiList.includes(camera.zonarSerialNum));
        this.removeDevices.forEach(item => {
          item.status = 'INACTIVE';
        });
      }
      const requestParams = {
        cameras: this.removeDevices,
      };
      this._loading.show();
      this._zcFleet.updateCameraStatus(requestParams).subscribe(
        (res: any) => {
          console.log('Successful request',res);
          const errorCases = [];
          if (res.length) {
            for (const resp of res) {
              if (resp.status === 'rejected') {
                errorCases.push(resp.imei);
              }
            }
          }
          // Some imeis failed to remove
          if (errorCases.length > 0) {
            this.openRemoveSnackBar([...this.removeDevices], errorCases, false).subscribe(_ => {
              this.removeDevices = this.removeDevices.filter((device) => errorCases.includes(device.zonarSerialNum));
              console.log('Try again sequences', this.removeDevices);
              this.onRemoveCamera(true);
            });
            if (errorCases.length < res.length) {
              this.listRemoveError = [...this.removeDevices];
              if (!this.activeUnassignedCamerasPage) {
                this.activeUnassignedCamerasPage = 1;
              }
              this.refreshCameraListByActivePage(this.activeUnassignedCamerasPage);
            }
          } else {
            this.openRemoveSnackBar([...this.removeDevices], errorCases, false);
            // clean the remove list when success
            this.removeDevices = [];
            this.listRemoveError = [];
            console.log('delete success!');
            // Clear search IMEI input
            this._flagService.changeFlag(CLEAR_FILTER_UNASSIGN_CAMERA_KEY, true);
            this.refreshCameraList();
          }
          this._loading.hide();
        },
        () => {
          const isOffline = !this._networkService.isOnline;
          const errorList = this.removeDevices.map(device => device.zonarSerialNum);
          this.openRemoveSnackBar(this.removeDevices, errorList, isOffline).subscribe(_ => {
            this.onRemoveCamera(true);
          });
          this.refreshCameraList();
          this._loading.hide();
        }
      );
    }

    public openRemoveSnackBar(sentList: any[], errorList: string[], isOffline: boolean): Observable<void> {
      if (sentList == null || errorList == null) {
        return;
      }

      const msgInfo: SnackBarMsg = {
        textMsg: null,
      };
      const panelClasses = ['custom-snackbar'];

      // check success cases
      if (errorList.length === 0) {
        msgInfo.textMsg = sentList.length > 1
          ? `${sentList.length} cameras were successfully removed from the account.`
          : `Camera ${sentList[0].typeInfo.imei} was successfully removed from the account.`;
        panelClasses.push('success-msg');
      } else {
        // check error cases
        panelClasses.push('error-msg');
        msgInfo.action = 'Try again';

        if (isOffline) {
          msgInfo.title = 'Cameras could not be removed';
          msgInfo.textMsg = 'from this account. Please ensure you are connected to the Internet and try again.';
          msgInfo.linkAction = this.formatDownloadResultData.bind(this);
          msgInfo.linkMsg = `${sentList.length} cameras could not be removed`;
          msgInfo.customLinkData = errorList;
        } else {
          if (sentList.length === 1) {
            msgInfo.textMsg = `Camera ${errorList[0]} could not be removed from the account.`;
            msgInfo.linkMsg = null;
          } else {
            msgInfo.title = 'Cameras could not be removed';
            msgInfo.textMsg = 'from this account. If this issue persists, please escalate through Zonar Customer Care.';
            msgInfo.linkAction = this.formatDownloadResultData.bind(this);
            msgInfo.linkMsg = `${errorList.length} cameras could not be removed`;
            msgInfo.customLinkData = errorList;
          }
        }
      }

      return this._snackbarService.openCustomSnackBar(SnackbarCustomMessageComponent, panelClasses, SNACKBAR_DURATION, msgInfo);
    }

    public addSelected(itemSel: UnusedCamera): void {
      this.selectedUnusedCameras.push(itemSel);
      this.updateSelectedStatus(true, itemSel.id);
    }

    public removeSelected(itemSel: UnusedCamera): void {
      this.selectedUnusedCameras = this.selectedUnusedCameras.filter(item => itemSel.id !== item.id);
      this.updateSelectedStatus(false, itemSel.id);
    }

    public updateSelectedStatus(status: boolean, cameraId: string) {
      this.unusedCameraDataList.forEach(item => {
        if (item.id === cameraId) {
          item.isSelected = status;
        }
      });
      this._unusedCameraList.next(this.unusedCameraDataList);
    }

    public updateCheckAllFlags(flag: boolean) {
      this.isShowDeleteCameras = flag;
      this.isSelectedAll = flag;
      this.unusedCameraDataList.forEach(item => item.isSelected = flag);
      this._unusedCameraList.next(this.unusedCameraDataList);
    }

    public formatDownloadResultData(imeiList: string[]) {
      try {
        const filename = 'download_result';
        const headers = [
          {},
        ];
        const listData = imeiList.map(imei => ({
          IMEI: imei,
        }));
        const utils = this._excelService.getSheetToJson(headers, {
          header: [],
          skipHeader: false,
        });
        const sheet = this._excelService.sheetAddJson(utils, listData, {
          origin: 'A1',
        });

        this._excelService.convertToCSV(sheet, filename);

      } catch (error) {
        console.log('Error while saving report', error);
      }
    }

    public displayDutyType(dutyValue: string) {
      return dutyValue === 'Unknown' ? '' : dutyValue;
    }

    public onFilterChange(filterCommands: FilterCommand[]) {
      if (!this._networkService.isOnline) {
        this.isNetworkErrorAC = true;
        this.totalAssets = 0;
        return;
      } else {
        this.isNetworkErrorAC = false;
      }
      this._filterCommands = filterCommands;
      this.refetchAssetFilter = false;
      const isFiltersUpdated = true;
      this.loadPageData(isFiltersUpdated);
    }

    public loadPageData(isFiltersUpdated = false) {
      this.assetPageListFiltered = [...this.assetsPageList];

      for (const command of this._filterCommands) {
        this.assetPageListFiltered = this.assetPageListFiltered.filter((incident) =>
          command.filterValues.some(
            (value) => incident[command.filterKey] === value.id
          )
        );
      }

      this.assetConfigTableNoResult = !this.assetPageListFiltered.length;
      this.updateAssetTableByActivePage(isFiltersUpdated);
      return;
    }

    /**
     * @description: function to get settings configuration date based on initial page load
     * @param:
     */
    private _getSettingsConfig() {
      this._loading.show();
      this._zcFleet.getDutyTypeConfig().subscribe(
        res => {
          this._loading.hide();
          this.configuration = res;
        },
        err => {
          this._loading.hide();
          console.log(err);
        }
      );
    }

    private _refreshAssetFilteredList(res) {
      this.assetsPageList = res.assetList.map(this.addModelName.bind(this));
      this._list.next(this.assetsPageList);
      this.mapToFeedbackVolume();
      this.updateAssetTableByActivePage();
      // Before create filter duty type options, check item with no duty type
      this._checkDutyType();
      this.onFilterChange(this._filterCommands);
    }

    private _createDummyFilters() {
      // reset asset list
      const event = {
        list: [],
      };
      // reset filtered asset list
      this.assetPageListFiltered = [];
      // set correct dutyType filter options
      const getFullDutyOptions = false;
      this.pageChange(event, getFullDutyOptions);
    }


    /**
     * @description: function to get asset list
     * @param:
     * @returns: a promise with asset list or error details
     */
    private _getAssets() {
      const OPTIONS = {
        params: {
          limit: this.limit,
          offset: this._offset,
          sort: this.sortAssetConfig,
          sortBy: this.sortBy,
        },
      };

      return new Promise((resolve, reject) => {
        this._loading.show();
        this._zcFleet.getAssets(OPTIONS).subscribe(
          (res: AssetsList) => {
            this._loading.hide();
            this.assetsList = [...this.assetsList, ...res.assets];
            this.totalAssets = this.assetsList.length;
            resolve({
              assetList: res.assets,
              assetCount: res.assets.length,
            });

            if (this.isNetworkErrorAC) {
              this._filterCommands = [];
              this.dropdownFilterOptions = {};
            }

            this.isNetworkErrorAC = false;
          },
          err => {
            this.showAssetConfigurationError(err);
            reject(err);
          }
        );
      });
    }

    private _getFullAssetList(isReset = true) {
      this.reset = isReset;
      this._getAssets()
        .then((res: AssetResponse) => {
          if (res.assetCount < 500) {
            this._list.next(this.assetsList);
          } else {
            this._offset += this.limit;
            this._getFullAssetList(false);
          }
        })
        .catch(err => {
          console.error(`Received Error: ${err}`);
          // handle filters when error occurs
          this._createDummyFilters();
        });
    }

    private _getFullAssetListFiltered() {
      this._getAssets()
        .then((res: AssetResponse) => {
          if (res.assetCount < 500) {
            this._refreshAssetFilteredList(res);
          } else {
            this.reset = false;
            this._offset += this.limit;
            this._getFullAssetListFiltered();
          }
        })
        .catch(err => {
          console.error(`Received Error: ${err}`);
          // handle filters when error occurs
          this._createDummyFilters();
        });
    }

    private _checkTypeInfoImei(item: UnusedCamera) {
      if (!item.zonarSerialNum && item.typeInfo?.imei) {
        item.zonarSerialNum = item.typeInfo.imei;
      }
    }

    private _checkZonarSerialNum(item: UnusedCamera) {
      if (item.zonarSerialNum && !item.typeInfo?.imei) {
        item.typeInfo.imei = item.zonarSerialNum;
      }
    }

    // TO-DO: Create service to avoid duplication in source code
    private _buildDataFilterOption(
      filterName: string,
      idField: string,
      filterKey: string,
      ignoreName: string[] = []
    ) {
      return {
        [filterName]: {
          filterKey: idField,
          availableOptions: this._makeUnique(
            (this.assetsPageList || [])
              .map((item, i) => ({
                id: item[idField] || i,
                name: item[filterKey],
              }))
              // Because some error can be interpreted as name, we need to
              // remove them out
              .filter((item) => ignoreName.every((val) => val !== item.name)),
            (v1, v2) => v1.id === v2.id
          ),
          enable: true,
        },
      };
    }

    private _buildDutyTypeFilterOptions() {
      return {
        ['dutyTypeFilter']: {
          filterKey: 'dutyType',
          availableOptions: DUTY_TYPE_FILTER_OPTIONS,
          enable: true,
        },
      };
    }

    private _checkDutyType() {
      this.assetsPageList.forEach(item => {
        if (item && !item.dutyType) {
          // if no set duty type, add value unset to dutyType
          item['dutyType'] = 'Unknown';
        }
      });
    }

    private _makeUnique<T>(
      source: T[],
      checkUniqueFn: (ele: T, ele2: T) => boolean
    ): T[] {
      return source.reduce(
        (acc, value) =>
          acc.some((ele) => checkUniqueFn(ele, value)) ? acc : [...acc, value],
        []
      );
    }
}
