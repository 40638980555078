import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '@app-core/services/storage.service';
import { environment } from '@env/environment';


const fleetIdHeader = 'x-zonar-fleet-id';

// Service used to intercept each and every HTTP call
@Injectable()
export class FleetIdAttachInterceptorService implements HttpInterceptor {

  constructor(
    private _storage: StorageService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let postReq = req;
    // If we do not check for url, other requests such as jwks, discovery
    // will also need to carried out `x-zonar-fleet-id` which will trigger
    // CORS checking
    if (req.url.indexOf(environment.intermediate_url) > -1) {
      postReq = req.clone({
        headers: req.headers.set(fleetIdHeader, this._storage.getStorageValue('state') || ''),
      });
    }

    return next.handle(postReq);
  }
}
