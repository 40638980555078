import { Injectable } from '@angular/core';

import { Observable, combineLatest, iif } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { FeatureFlagService } from '@modules/feature-flag/services/feature-flag.service';

@Injectable() export class HomeLocationService {

  constructor(
    private _featureFlagService: FeatureFlagService,
    private _zcFleetService: ZCFleetService
  ) {}

  public getHomeLocations(): Observable<any> {
    const homeLocations$ = combineLatest([
      this._featureFlagService.isFlagEnabled('driverFilterFallbackToLM').pipe(distinctUntilChanged()),
      this._featureFlagService.isFlagEnabled('driverFilterAlwaysUseLM').pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([flagFallbackToLM, flagAlwaysUseLM]) => {
        if (flagFallbackToLM && !flagAlwaysUseLM) {
          return this._zcFleetService.isFleetContainsRideCamCamera().pipe(
            switchMap((value) => {
              const data = [
                this._zcFleetService.getHomeLocations(), // fleet 1.0
                this._zcFleetService.getHomeLocationsFromEntity(), // fleet 2.0
              ];
              return iif(() => value, data[0], data[1]);
            })
          );
        } else if (flagAlwaysUseLM) {
          return this._zcFleetService.getHomeLocations();
        } else {
          return this._zcFleetService.getHomeLocationsFromEntity();
        }
      })
    );

    return homeLocations$.pipe(distinctUntilChanged());
  }
}
