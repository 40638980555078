import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DATE_RANGE_TEXT_MAP } from './../../../dashboard3.models';
import { MARKER } from './../../../dashboard3.constants';

import * as Highcharts from 'highcharts';

const CHART_OPTION = {
  title: {
    text: '',
  },
  chart: {
    height: 60, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 10, // number represents pixels
    spacingRight: 10, // number represents pixels
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    // visible: false,
    maxPadding: 0, // padding at the end of the bar
    // gridLineDashStyle: 'dash',
    // gridLineColor: '#979797',
    gridLineWidth: 0,
    // tickInterval: 1,
    labels: {
      enabled: false,
    },
    title: { enabled: false },
    plotLines: [
      {
        value: 1,
        color: '#b8bcc0',
        dashStyle: 'shortdash',
        width: 2,
      },
    ],
  },
  plotOptions: {
    line: {
      states: {
        hover: {
          enabled: false, // Disable hover effect on line
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            fillColor: '#7dbb7a',
            lineColor: '#7dbb7a',
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      data: [],
      color: '#b8bcc0',
    },
  ],
};
@Component({
  selector: 'app-leadboard-card',
  templateUrl: './leadboard-card.component.html',
  styleUrls: ['./leadboard-card.component.scss'],
})
export class LeadboardCardComponent implements OnChanges {

    @Input()
    public data: any;
    @Input() public days: number;
    @Output() public cardClicked = new EventEmitter<void>();

    public highcharts = Highcharts;
    public chartOptions = CHART_OPTION;
    public updateChart = false;

    public minScore: number;
    public maxScore: number;
    public rangeText: string;

    public ngOnChanges(changes: SimpleChanges) {
      if (changes.data) {
        const val = changes.data.currentValue;
        if (val && val.trend) {
          if (val.trend.length) {
            this._generateGraph(val.trend);
          }
        }
      }
    }

    public clicked() {
      this.cardClicked.emit();
    }

    public get dateRangeText() {
      return DATE_RANGE_TEXT_MAP[this.days] || '';
    }

    public get noIncident() {
      return this.data && this.data.topIncident && this.data.topIncident.value === 0;
    }

    private _generateGraph(list: any[]) {
      list = list.map(e => e[1]);
      try {
        this.minScore = +list[0];
        this.maxScore = +list[list.length - 1];
        const totalScore = list.reduce((a, b) => a + b, 0);
        let averageScore = +(+totalScore / list.length).toFixed(2);
        averageScore = +(averageScore / 100).toFixed(2);

        const trendScores = list.map((trend, index) => {
          if (index === list.length - 1) {
            const y = +(trend / 100).toFixed(2);
            let marker;
            if (y < averageScore) {
              marker = { ...MARKER, fillColor: 'red', lineColor: 'red' };
            } else {
              marker = { ...MARKER, fillColor: '#7dbb7a', lineColor: '#7dbb7a' };
            }

            return { y, marker };
          } else {
            return +(trend / 100).toFixed(2);
          }
        });

        this.chartOptions.yAxis.plotLines[0].value = averageScore;
        this.chartOptions.series[0].data = trendScores;
        this.chartOptions.series[0].color = '#b8bcc0';
        this.updateChart = true;
        // this.showGraph = true;
      } catch (error) {
        // this.showGraph = false;
      }
    }
}
