<div class="zc-body zc-app-wrap has-search-filter">
   <div class="container-fluid content-wrap">
      <app-display-container [show]="show">

         <div class="row">

            <div class="col-lg-6">
               <div class="box">
                  <h5>Version Crash Count</h5>
                  <div class="table-400 table-responsive">
                     <table class="table table-text-center table-striped table-bordered table-hover">
                        <thead>
                           <tr>
                              <th>App Version</th>
                              <th>Crash Count</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let crash of crashCount | sort:'key':false">
                              <td>{{crash.key}}</td>
                              <td>{{crash.value}}</td>
                           </tr>
                           <tr *ngIf="!crashCount.length">
                              <div class="no-match-found">
                                 <span>No Crashes Found</span>
                              </div>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

         </div>

         <app-exception-list></app-exception-list>

      </app-display-container>
   </div>

</div>
