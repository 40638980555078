<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="removeCameraModal" aria-hidden="true" style="z-index:1500 !important">
  <div class="backDrop" (click)="hideModal()"></div>
  <div class="modal-dialog remove-cameras-modal" [class.modal-big]="cameraList.length > 1">
    <div class="modal-content">
      <div class="modal-header remove-title">{{titleName}}</div>
      <div class="modal-body remove-body">
        <div class="imei-area">
          <div class="camera-item" *ngFor="let item of cameraList">
            <div class="camera-icon">
              <img id="camera-ico" src="../../../../../../assets/images/videocam.svg" alt="camera icon">
            </div>
            <div class="imei-display">{{item}}</div>
          </div>
        </div>
      </div>
      <div class="modal-footer remove-footer">
        <div class="warning-msg">
          <p class="first-warning"><span class="red-mark">WARNING: </span>This is a permanent action and cannot be undone.<br/>Please double-check the camera details before deleting.</p>
        </div>
        <div class="action-btn">
          <button class="btn btn-sub" (click)="hideModal()">Cancel</button>
          <button class="btn btn-main" (click)="deleteCamera()">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>
