import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

interface CacheContent {
  expiry: number;
  value: any;
}

// Creating an HTTP cache service to cache http calls
@Injectable()
export class HttpCacheService {
  private _store: Map<string, CacheContent> = new Map<string, CacheContent>();
  private readonly _defaultMaxAge = 15; // in min

  /**
   *
   * @description: Store response into cache
   */
  public setCache(
    req: HttpRequest<any>,
    resp: HttpResponse<any>,
    expiry: number = this._defaultMaxAge
  ): void {
    expiry = expiry * 1000 * 60;
    const value = this._copy(resp);
    this._store.set(req.urlWithParams, { value, expiry: Date.now() + expiry });
  }

  /**
   * @description: Retrieve response from cache
   */
  public getCache(req: HttpRequest<any>): HttpResponse<any> | null {
    const key = req.urlWithParams;
    if (this._store.has(key)) {
      const cacheVal = this._store.get(key);
      if (!this._isExpired(cacheVal.expiry)) {
        // Not expired
        return this._copy(cacheVal.value);
      }
      // Expired
      this._store.delete(key);
      return null;
    }
    return null;
  }

  private _isExpired(val): boolean {
    return val < Date.now();
  }

  private _copy(ob: any) {
    return ob;
    // return ob ? JSON.parse(JSON.stringify(ob)) : ob;
  }
}
