import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class HttpCancelRequestService {
  private _pendingHttpRequest$ = new Subject<void>();
  private _errorSubject$ = new Subject<void>();

  public get pendingHttpRequest$(): Observable<void> {
    return this._pendingHttpRequest$;
  }

  constructor(private _loading: LoadingService, private _router: Router) {
    this._errorSubject$.subscribe((_) => {
      this._router.navigateByUrl('/error', {skipLocationChange: true});
    });
  }

  public cancelPendingRequest() {
    this._pendingHttpRequest$.next();
    this._loading.reset();
  }

  public navigateToErrorPage() {
    this._errorSubject$.next();
  }
}
