import { OnDestroy } from '@angular/core';
/*
  @Tit1le: Fleet metrics page
  @Author: Stella Maria Mendonca
  @Description: Displays fleet trends in graphs
*/

import { Component, OnInit } from '@angular/core';
import {
  StatsModel,
  SearchFilterModel,
} from '@modules/dashboard3/dashboard3.models';
import { Router } from '@angular/router';
import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { StorageService } from '@app-core/services/storage.service';
import { Title } from '@angular/platform-browser';
import { HomeLocationService } from '@app-core/services/home-location.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

@Component({
  selector: 'app-fleet-metrics',
  templateUrl: './fleet-metrics.component.html',
  styleUrls: ['./fleet-metrics.component.scss'],
})
export class FleetMetricsComponent implements OnInit, OnDestroy {
  public histogramData = [];
  public histogramPercentileData = [];
  public modeScoreFrequency = 1;
  public driverCount = undefined;
  public totalTrips = 0;
  public imgSrc = 'assets/images/path@2x.png';
  public searchFilterData: SearchFilterModel;
  public homeLocationList = [];
  public isGetHomeLocation = false;

  public get localStorageHomeLocation() {
    return this._storageService.getStorageValue('HOME_LOCATION');
  };

  public get localStorageHomeLocationABV() {
    return this._storageService.getStorageValue('HOME_LOCATION_ABV');
  };

  constructor(
    private _zcfleet: ZCFleetService,
    private _router: Router,
    private _dateService: DateService,
    private _loading: LoadingService,
    private _data: Data,
    private _storageService: StorageService,
    private _title: Title,
    private _homeLocationService: HomeLocationService
  ) {}

  public ngOnInit() {
    this._title.setTitle('Fleet Trends - Zonar Coach');
    this._syncLocalStorageHomeLocation(false);
  }

  public ngOnDestroy(): void {
    this._syncLocalStorageHomeLocation(true);
  }

  /**
   * change in filter is made available in child component i.e fleetScoreGraphComponent
   */
  public setSearchFilterData(val) {
    this.searchFilterData = val;
    this.getData();
    this._zcfleet.fleetFilterChange.next(val);
  }

  /**
   * stats api call is made and the filterStore in Zcfleet service is updated
   */
  public getData() {
    const { days, dutyType, minScore, maxScore } = this.searchFilterData;
    let date = { from: null, to: null };
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION_ABV');
    // Stats
    const statsParams = {
      params: new StatsModel(
        date.from,
        date.to,
        dutyType,
        minScore,
        maxScore,
        85,
        this.homeLocationList
      ),
    };
    this._loading.show();

    this._zcfleet.getStats(statsParams).subscribe(
      (res) => {
        this._loading.hide();
        this._zcfleet.stats = res;
        this.setStats(res);
        this._zcfleet.fleetRating = res.score;
        this._zcfleet.driverCount = res.driverCount;
        this._zcfleet.tripCount = res.tripCount;
        this._zcfleet.fleetDriverCount = res.driverCount;
      },
      (err) => {
        this._loading.hide();
        console.error(`Received Error: ${err}`);
      }
    );
  }

  /**
   * stats are set in the current component to update the filter view [passed to child components such as searchFilterComponent]
   */
  public setStats(data) {
    const {
      percentile,
      distribution,
      modeScoreFrequency,
      driverCount,
      tripCount,
    } = data;
    setTimeout(() => {
      this.histogramData = distribution;
      this.histogramPercentileData = percentile;
      this.modeScoreFrequency = modeScoreFrequency;
      this.driverCount = driverCount;
      this.totalTrips = tripCount;
    }, 1);
  }

  public driverChange(driver) {
    this.gotoDriverDetail(driver);
  }

  /**
   * @description: function to navigate to particular driver page
   * @param: driver details such as id and name
   */
  public gotoDriverDetail(driver) {
    if (driver && driver.driverId) {
      // Set driver name
      // this._data.driverName = driver.driverName;
      this._router.navigate(['/driver-detail'], {
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }

  public goToDashboard() {
    this._router.navigate(['/dashboard']);
  }
  public reloadPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/fleet-trends']);
  }

  private _syncLocalStorageHomeLocation(isDestroy: boolean) {
    const locationFullList = this._storageService.getStorageValue('HOME_LOCATION_FULL_LIST');
    if (!isDestroy) { // load Fleet Trends page
      if (this.localStorageHomeLocation?.length === 0) {
        // TO-DO: optimize Old All locations logic
        if (!locationFullList) {
          this._homeLocationService.getHomeLocations().subscribe(
            () => {
              this.isGetHomeLocation = true;
              this.reloadPage();
            }
          );
        } else {
          this._storageService.setStorageValue('HOME_LOCATION_ABV', locationFullList.map(item => item.locationId));
        }
      } else {
        this._storageService.setStorageValue('HOME_LOCATION_ABV', this.localStorageHomeLocation);
      }
    } else if (isDestroy) { // leave Fleet Trends page
      const currentLocationListSelected = this.localStorageHomeLocationABV?.filter(item => item !== 'null');
      const isCheckAllLocation = locationFullList?.length === currentLocationListSelected?.length;
      if (isCheckAllLocation || this.isGetHomeLocation) {
        this._storageService.setStorageValue('HOME_LOCATION', []); // all locations
      } else {
        this._storageService.setStorageValue('HOME_LOCATION', this.localStorageHomeLocationABV); // no location selected, select multiple
      }
    }
  }
}
