<div>
  <div class="display">
    <li class="nav-item" *ngIf="challengeResolved && challengeAccepted">
      <ng-container *ngTemplateOutlet="safeIncidentLabel"></ng-container>
    </li>

    <li class="nav-item" *ngIf="challengeResolved && !challengeAccepted">
      <ng-container *ngTemplateOutlet="rejectedIncidentLabel"></ng-container>
    </li>
  </div>

  <div class="display" *ngIf="!['Calibration', 'Video-Capture'].includes(eventType)">
    <li class="nav-item" *ngIf="challengeRaised && !challengeResolved" >
      <ng-container *ngTemplateOutlet="rejectBtn"></ng-container>
    </li>

    <li class="nav-item" *ngIf="
        (!challengeRaised && !challengeResolved)
        || (challengeRaised && !challengeResolved)
    ">
      <ng-container *ngTemplateOutlet="markAsSafeBtn"></ng-container>
    </li>
  </div>
</div>

<div class="notify-popup" [ngSwitch]="popupCancel.showPopup">
  <!-- report bug confirm pop up -->
  <div class="snackbar" *ngSwitchCase="'bug'">
    Bug will be reported. This incident will be removed from the record.
    <div class="cancel-button" [hidden]="popupCancel.hideCancel" (click)="onSnackbarCancelChange('bug')">
      Cancel
    </div>
  </div>

  <!-- reject confirm popup -->
  <div class="snackbar" *ngSwitchCase="'reject'">
    This incident is marked as rejected. It will remain on the record.
    <div class="cancel-button" [hidden]="popupCancel.hideCancel" (click)="onSnackbarCancelChange('bug')">
      Cancel
    </div>
  </div>

  <!-- accept confirm popup -->
  <div class="snackbar" *ngSwitchCase="'accept'">
    This incident is marked as safe. It no longer appears on the record.
    <div class="cancel-button" [hidden]="popupCancel.hideCancel" (click)="onSnackbarCancelChange('accept')">
      Cancel
    </div>
  </div>

</div>

<ng-template #markAsSafeBtn>
  <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else markAsSafeBtnSkeleton">
    <a class="nav-link button accept-challenge-button" (click)="onIncidentButtonChange('accept')">
      <img class="accept-challenge-icon" src="assets/images/accept_dispute.svg">
      Mark as safe
    </a>
  </ng-container>
</ng-template>

<!-- loading skeleton for mark as safe button-->
<ng-template #markAsSafeBtnSkeleton>
  <app-custom-skeleton-loader
    style="padding-right: 10px;"
    [theme]="{
      width: '152px',
      height: '32px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<ng-template #rejectBtn>
  <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else rejectBtnSkeleton">
    <a class="nav-link button reject-challenge-button" (click)="onIncidentButtonChange('reject')">
      <img class="reject-challenge-icon" src="assets/images/shape.svg">
      Reject dispute
    </a>
  </ng-container>
</ng-template>

<!-- loading skeleton for reject button-->
<ng-template #rejectBtnSkeleton>
  <app-custom-skeleton-loader
    [theme]="{
      width: '152px',
      height: '32px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<ng-template #safeIncidentLabel>
  <div class="display-text">
      This incident is marked as safe. It no longer appears on the record.
  </div>
</ng-template>

<ng-template #rejectedIncidentLabel>
  <div class="display-text">
      This incident remains on the record.
  </div>
</ng-template>

<ng-template #reportBugBtn>
  <a class="nav-link button report-bug-button" (click)="onIncidentButtonChange('bug')">
    <img class="bug-icon" src="assets/images/bug_report_24px.svg">
    Report bug
  </a>
</ng-template>
