
import { Injectable } from '@angular/core';

@Injectable()
export class TrackNetworkStatusService {

  public get isOnline(): boolean {
    return this._trackNetworkStatus();
  }

  constructor() {}

  private _trackNetworkStatus(): boolean {
    return navigator.onLine;
  }
}
