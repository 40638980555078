<div class="zc-body zc-app-wrap has-search-filter">
        <app-export-modal
            [histogramData]="histogramData"
            [histogramPercentile]="histogramPercentileData"
            [modeScoreFrequency]="modeScoreFrequency"
            [driverCount]="driverCount"
        ></app-export-modal>
    <div class="zc-content-wrap">

        <div class="page-title">
          <h1>Fleet Performance at a Glance</h1>
        </div>

        <app-search-filter
          class="fleet-dashboard"
          page="fleet"
          (driverChange)="driverChange($event)"
          [histogramData]="histogramData"
          [histogramPercentile]="histogramPercentileData"
          [modeScoreFrequency]="modeScoreFrequency"
          [driverCount]="driverCount"
          (filterChange)="setSearchFilterData($event)">
        </app-search-filter>

        <div class="fleet-page-wrap">
            <div class="leadboard-wrap">
              <app-fleet-performance
                (isShowError)="notifyFleetPerErrChange($event)"
              ></app-fleet-performance>
            </div>

            <div class="scoreboard-wrap">
              <app-driver-scorecards
                (isShowError)="notifyDriverScorecardsErrChange($event)"
              ></app-driver-scorecards>
            </div>

            <div class="triprecaps-wrap">
              <app-triprecaps
                [totalTrips]="totalTrips"
                (isShowError)="notifyTripRecapsErrChange($event)"
              ></app-triprecaps>
            </div>

            <div class="disputed-trip-table-wrap">
              <app-fleet-disputed-incidents-table
                [checkDriverTrips]="checkDriverTrips"
                (isShowError)="notifyDisputedErrChange($event)"
              ></app-fleet-disputed-incidents-table>
            </div>
        </div>
    </div>

</div>
