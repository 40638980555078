import { Injectable } from '@angular/core';

import { EVENT_NAMES_MAP } from '../dashboard3.models';

export interface IncidentStat {
  name: string;
  value: number;
  color?: string;
  incidentClass?: string;
}

export interface SourceEventList {
  eventCount: Record<string, number>;
  tripCount?: number;
  tripDistance?: number;
  tripDuration?: number;
  v2Score?: number;
  v2ScoreChange?: number;
}

export interface TransformOptions {
  ignoreZero: boolean; // Flag to know if we need to remove any not occur incidents
  sliceIndex: number; // If specify, cut the incident list instead of show all incidents
}


type PipeLineFunction<T> = (list: T[], options: TransformOptions) => T[];

/**
 * Service to help with calculate incident statistic.
 *
 * @export
 */
@Injectable({
  providedIn: 'root',
})
export class IncidentStatService {

  constructor() { }

  /**
   * Transform from event list source to incident stat list.
   *
   * @param sourceEventList Source event list
   * @param TransformOptions} [transformOptions] Transform options, with options like ignoreZero, etc
   * @return List of incident statistic object
   */
  public transformToIncidentStatList(sourceEventList: SourceEventList, transformOptions?: TransformOptions): IncidentStat[] {
    const eventCount = sourceEventList.eventCount || {};
    const eventMapper = this._buildEventMapping();
    const pipelineFunctions = this._buildTransformPipelines();
    let statList = Object.keys(eventMapper).map<IncidentStat>(key => ({
      name: eventMapper[key],
      value: eventCount[key],
      incidentClass: this._buildClassNameUsingEventType(key, eventMapper),
    }));

    for (const func of pipelineFunctions) {
      statList = func(statList, transformOptions);
    }

    return statList;
  }

  private _buildEventMapping(): Record<string, string> {
    return { ...EVENT_NAMES_MAP };
  }

  /**
   * Build pipeline functions to support for the transformation
   * from driver stats => incident stats
   *
   * For ex: if we have 2 pipeline functions: A and B, the flow looks like this:
   * [driver] ==normalize==> [basic incident stat] ==A==> [incident stat after A] ==B==> [incident stat after B]
   */
  private _buildTransformPipelines(): PipeLineFunction<IncidentStat>[] {
    return [
      this._filterOuteUndefinedValue,
      this._filterOutNegativeValue,
      this._filterOutZeroValue, // depends on this.ignoreZero flag
      this._sortDescending,
      this._sliceIncidentList, // depends on both this.sliceIndex and this.showOthers
    ].map(func => func.bind(this)); // to ensure that all the pipeline has the correct 'this' value
  }

  private _filterOuteUndefinedValue(list: IncidentStat[], _options: TransformOptions): IncidentStat[] {
    return list.filter(item => item.value !== undefined && item.name !== undefined);
  }

  private _filterOutNegativeValue(list: IncidentStat[], _options: TransformOptions): IncidentStat[] {
    return list.filter(item => item.value >= 0);
  }

  private _sortDescending(list: IncidentStat[], _options: TransformOptions): IncidentStat[] {
    return list.sort((a, b) => (b.value - a.value));
  }

  private _sliceIncidentList(list: IncidentStat[], options: TransformOptions): IncidentStat[] {
    if (options.sliceIndex > 0) {
      const [left, right] = this._splitIncidentStatsList(list, options.sliceIndex + 1);
      if (right.length > 0) {
        if (right.length === 1) {
          left.push(right[0]);
        } else {
          left.push({
            name: 'Others',
            value: right.reduce((acc, current) => (acc + current.value), 0),
            incidentClass: 'incident-others',
          });
        }
      }
      return left;
    }
    return list;
  }

  private _filterOutZeroValue(list: IncidentStat[], options: TransformOptions): IncidentStat[] {
    return list.filter(item => !(options.ignoreZero && item.value === 0));
  }

  private _buildClassNameUsingEventType(eventType: string, eventMapper: Record<string, string>): string {
    return 'incident-' + eventMapper[eventType].toLowerCase().replace(/ /g, '-');
  }

  private _splitIncidentStatsList(statList: IncidentStat[], splitIndex: number): IncidentStat[][] {
    const left = statList.slice(0, splitIndex);
    const right = statList.slice(splitIndex);
    return [left, right];
  }
}
