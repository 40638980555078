import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ProvideFlagSource } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentFlagSourceService implements ProvideFlagSource {

  private _flagMap: Record<string, boolean>;

  constructor() {
    this._flagMap = this._cloneObject(environment.feature_flags);
  }

  public normalizeFlagSource(): void {
    for (const key in this._flagMap) {
      if (this._flagMap.hasOwnProperty(key)) {
        this._flagMap[key] = !!this._flagMap[key];
      }
    }
  }

  public isFlagSet(flagName: string): boolean {
    return this._flagMap[flagName];
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private _cloneObject<T extends object>(sourceObj: T): T {
    return Object.assign(sourceObj);
  }
}
