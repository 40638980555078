/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import {
  BehaviorSubject,
  combineLatest,
  iif,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
} from 'rxjs';
import {
  filter,
  switchMap,
  take,
  distinctUntilChanged,
  map,
  catchError,
  tap,
} from 'rxjs/operators';

import { DateService } from '@app-core/services/date.service';
import {
  DRIVER_NAME_UNIDENTIFIED_LIST,
  ERROR_MESSAGE,
  UNASSIGNED_DRIVER,
  UNIDENTIFIED_DRIVER_NAME,
} from '@app-core/constants/constants';
import { StorageService } from '@app-core/services/storage.service';

import {
  Data,
  DRIVER_AVG_SCORE_GRAPH_OPTION,
  DRIVER_INCIDENTS_GRAPH_OPTION,
} from '@modules/dashboard3/services/data.service';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { DateFormatPipe } from '@modules/shared/pipes/date-format.pipe';
import { ErrorMessage } from '@modules/shared/components/error-message/error-message.component';
import { calcSumTotalIncidents } from '@modules/shared/utils';

import { ZCFleetService } from './../../services/zcfleet.service';
import {
  DATE_RANGE_TEXT_MAP,
  DriverStatsModel,
  SearchFilterModel,
} from './../../dashboard3.models';
import { LocalService } from './../../../../core/services/local.service';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { KeyMetric } from '@modules/dashboard3/components/key-metric-overview/key-metric-overview.component';
import { SnackbarService } from '@app-core/services/snackbar.service';
import {
  StackableCustomSnackbarComponent,
} from '@modules/dashboard3/components/stackable-custom-snackbar/stackable-custom-snackbar.component';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';

const MILE_OF_A_KILOMETER = 0.62137;
const MILE_NUMBER = 100;
const SECONDS_OF_A_HOUR = 60 * 60;
const SNACKBAR_TITLE = 'An error has occurred';
const SNACKBAR_MSG = (target: string) => (`We were unable to load ${target || ''} data due to a connection issue. Please try again.`);
const SNACKBAR_INTERNET_TITLE = 'No network found';
const SNACKBAR_NO_INTERNET = 'We were unable to load driver scorecard data. Please wait a few minutes and try again.';

export const MARKER = {
  enabled: false,
  radius: 5,
};

@Component({
  selector: 'app-driver-detail2',
  templateUrl: './driver-detail2.component.html',
  styleUrls: ['./driver-detail2.component.scss'],
  providers: [ArraySortPipe],
})
export class DriverDetail2Component implements OnInit, OnDestroy {
  public show = 'data';

  public driverId = '';
  public driverName = '';
  public driverNameRouteParam = '';

  public get driverName$(): Observable<string> {
    return this._driverName$;
  }
  private _driverName$ = new ReplaySubject<string>();

  public totalTrips = 0;
  public groupBy = {
    dateArray: [],
    group: 'day',
  };
  public fleetTrend;
  public slidePage = 1;

  public homeLocationList = [];

  public searchFilterData: SearchFilterModel = {
    days: 7,
    minScore: 0,
    maxScore: 100,
    dutyType: '',
    ids: [],
  };

  public driver: any;
  public sort = 'desc';
  public reset = false;
  public listData = null;
  public driverids = [];
  public startday = 0;
  public numberofdays = 0;
  public isCustomDateRange = false;
  public allAssetsList = [];
  public ranges;
  public metricHeaders = [
    {
      values: ['Total Miles', 'Total Hours', 'Total Trips'],
      longestName: 'Total Hours',
    },
    {
      values: ['Incidents / 100mi', 'Incidents / Hour', 'Total Incidents'],
      longestName: 'Incidents / 100mi',
    },
  ];
  public driverStatsModel: DriverStatsModel;
  public dataSubs: Subscription;

  public showRetryButton = true;
  public get errorMessage$(): Observable<ErrorMessage> {
    return this._errorMessage$;
  }

  private _filterChange$ = new ReplaySubject<any>();
  public get filterChange$(): Observable<any> {
    return this._filterChange$;
  }

  private _performanceTrend$ = new ReplaySubject<any>();
  public get performanceTrend$(): Observable<any> {
    return this._performanceTrend$;
  }

  private _driverOverview$ = new ReplaySubject<any>();
  public get driverOverview$(): Observable<any> {
    return this._driverOverview$;
  }

  private _tripDetails$ = new ReplaySubject<any>();
  public get tripDetails$(): Observable<any> {
    return this._tripDetails$;
  }

  private _incidentList$ = new ReplaySubject<any>();
  public get incidentList$(): Observable<any> {
    return this._incidentList$;
  }

  private _keyMetric$ = new ReplaySubject<any>();
  public get keyMetric$(): Observable<any> {
    return this._keyMetric$;
  }

  public reload$: Observable<boolean>;

  private _errorMessage$ = new ReplaySubject<ErrorMessage>();
  private _list = new BehaviorSubject([]);
  private _destroy$ = new Subject();
  private _subscription: Subscription = new Subscription();
  constructor(
    private _route: ActivatedRoute,
    private _zcfleet: ZCFleetService,
    private _router: Router,
    private _dateService: DateService,
    private _data: Data,
    private _customDateFormatPipe: DateFormatPipe,
    private _localService: LocalService,
    private _storageService: StorageService,
    private _title: Title,
    private _snackbar: SnackbarService,
    private _networkService: TrackNetworkStatusService
  ) {
    this.listData = this._list.asObservable();
    this.searchFilterData = this._localService.getSearchFilterData();
    if (this.searchFilterData.days === 2) {
      const date = { from: null, to: null };

      const getDateRangeFromLocalStorage = JSON.parse(
        localStorage.getItem('CUSTOM_RANGE')
      );
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate ||
          getDateRangeFromLocalStorage.data.from
      );
      date.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate || getDateRangeFromLocalStorage.data.to
      );
      const fromdate = date.from.toString();
      const todate = date.to.toString();
      const dateone: any = new Date(fromdate);
      const datetwo: any = new Date(todate);
      const dayDif = Math.round((datetwo - dateone) / 1000 / 60 / 60 / 24);
      this.numberofdays = dayDif - 1;
      this.isCustomDateRange = true;
    } else {
      this.numberofdays = this.searchFilterData.days;
      this.isCustomDateRange = false;
    }
    this._route.queryParams.subscribe((params) => {
      this.driverids = [];
      this.driverids.push(params.driverId);
      this.driverId = params.driverId;
      this.driverNameRouteParam = params.driverName;
    });

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    this._router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        this._router.navigated = false;
        window.scrollTo(0, 0);
      });
  }

  public ngOnInit() {
    this._title.setTitle('Driver Details - Zonar Coach');
    this._setDriverName(this.driverNameRouteParam, this.driverId);
    this.getAllAssets();
    this._preparePageData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._subscription.unsubscribe();
    if (this.dataSubs) {
      this.dataSubs.unsubscribe();
    }
  }

  public setSearchFilterData(val) {
    if (this.dataSubs) {
      this.dataSubs.unsubscribe();
    }
    this.searchFilterData = val;
    this._snackbar.closeCurrentSnackbar();
    this._localService.saveSearchFilterData(this.searchFilterData);
    this._zcfleet.driverFilterChange.next(val);
    this.startday = this.searchFilterData.days;

    if (this.searchFilterData.days === 2) {
      const date = { from: null, to: null };

      const getDateRangeFromLocalStorage = JSON.parse(
        localStorage.getItem('CUSTOM_RANGE')
      );
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate ||
          getDateRangeFromLocalStorage.data.from
      );
      date.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate || getDateRangeFromLocalStorage.data.to
      );
      const fromdate = date.from.toString();
      const todate = date.to.toString();
      const dateone: any = new Date(fromdate);
      const datetwo: any = new Date(todate);
      const dayDif = Math.round((datetwo - dateone) / 1000 / 60 / 60 / 24);
      this.numberofdays = dayDif - 1;
      this.isCustomDateRange = true;
    } else {
      if (this.searchFilterData.days === -1) {
        this.numberofdays = 1;
      } else {
        this.numberofdays = this.searchFilterData.days;
      }
      this.isCustomDateRange = false;
    }
    this.driverStatsModel = this._getParams();
    this.searchFilterData.ids = this.driverids;
    this._filterChange$.next(this.searchFilterData);
  }

  public driverChange(driver) {
    this.gotoDriverDetail(driver);
  }

  public gotoFleet() {
    this._router.navigate(['/dashboard']);
  }

  // Naviagate to Driver page
  public gotoDriverDetail(driver) {
    // Check if all the details are present to navigate successfully
    if (driver && driver.driverId) {
      // Replace the current url with the next url by setting a flag
      // in the router, so that on back, we go to the fleet page instead
      // of the previous deriver
      this._router.navigate(['/driver-detail'], {
        replaceUrl: true,
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }

  public initSnackbarInstance() {
    // Init stackable snackbar if not exist
    if (!this._snackbar.isSnackbarOpened) {
      this._snackbar.openStackableSnackbar(StackableCustomSnackbarComponent);
    }
  }

  // Handle error when calling api
  public catchAPIError(errMsg: string): Observable<any> {
    if (this._snackbar.isSnackbarOpened && !this._networkService.isOnline) {
      this._subscription = this._snackbar.snackbarRef.afterDismissed().subscribe(_ => {
        this.catchAPIError(errMsg);
      });
    } else {
      // timeout for waiting to dismiss snackbar properly
      setTimeout(() => {
        this.initSnackbarInstance();
        if (this._networkService.isOnline) {
          this._snackbar.toastMsgNotifier.next({
            title: SNACKBAR_TITLE,
            textMsg: SNACKBAR_MSG(errMsg),
            panelClasses: ['error-msg'],
            action: 'Try again',
            isShowContact: true,
            linkAction: this.reloadPage.bind(this),
          });
        } else {
          // not push no internet message yet
          this._snackbar.toastMsgNotifier.next({
            title: SNACKBAR_INTERNET_TITLE,
            textMsg: SNACKBAR_NO_INTERNET,
            panelClasses: ['error-msg'],
            action: 'Try again',
            linkAction: this.reloadPage.bind(this),
            isShowContact: true,
          });
        }
      }, 500);
    }

    return of(undefined);
  }

  public catchTripListError(hasError: boolean) {
    if (hasError) {
      this.catchAPIError('trips');
    }
  }

  public reloadPage() {
    const driver = {
      driverName: this.driverName,
      driverId: this.driverId,
    };
    if (this._snackbar.isSnackbarOpened) {
      this._snackbar.snackbarRef.afterDismissed().subscribe(_ => {
        this.gotoDriverDetail(driver);
      });
    }
  }

  public getGreetingText(date) {
    if (date) {
      // return this._dateService.getGreetingTime(date);
      let currentTime = date;
      currentTime = this._customDateFormatPipe.transform(currentTime);
      const time = new Date(currentTime).getHours();

      let greeting;
      if (time > 5 && time <= 11) {
        // 6AM to 11AM
        greeting = 'Morning';
      } else if (time > 11 && time <= 17) {
        // 12PM to 5PM
        greeting = 'Afternoon';
      } else if (time > 17 && time <= 22) {
        // 6PM to 10PM
        greeting = 'Evening';
      } else {
        greeting = 'Night'; // 8PM to 5AM
      }
      return greeting;
    }
  }

  /**
   * @description: function to particular trip details page
   * @param:
   */
  public gotoTripDetail(trip) {
    this._router.navigate(['/trip-detail'], {
      queryParams: {
        tripId: trip.tripId,
        driverId: trip.driverId,
      },
    });
  }

  public get dateRangeText() {
    return DATE_RANGE_TEXT_MAP[this.searchFilterData.days] || '';
  }

  /**
   * @description: function to check whether a number is interger or not
   * @param: number
   */
  public checkIfInteger(num?) {
    if (Number.isInteger(num)) {
      return true;
    } else {
      return false;
    }
  }

  public getAllAssets() {
    this._zcfleet.getAllAssets().subscribe(
      (res) => {
        this.allAssetsList = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public totalIncidentsEventType(eventCount: Record<string, string>): number {
    return calcSumTotalIncidents(eventCount);
  }

  private _setDriverName(driverName: any, driverId: string) {
    // Set driverName to 'Unidentified Driver' for driverId '_UNASSIGNED'
    // Or if driverName included in DRIVER_NAME_UNIDENTIFIED_LIST
    if (driverId === UNASSIGNED_DRIVER || DRIVER_NAME_UNIDENTIFIED_LIST.includes(driverName)) {
      this.driverName = UNIDENTIFIED_DRIVER_NAME;
    } else if (driverName !== '[object Object]') {
      this.driverName = driverName;
    }

    if (this.driverName) {
      this._driverName$.next(this.driverName);
    } else {
      this._zcfleet.getUserInfoFromUserProfileId(driverId).subscribe(
        (res) => {
          this.driverName = res.driverName;
          this._driverName$.next(this.driverName);
        },
        (error) => {
          console.log('Can\'t get driver name.', error);
          this.driverName = ERROR_MESSAGE.INVALID_DRIVER_ID;
          this._driverName$.next(this.driverName);
        }
      );
    }
  }

  private _getParams() {
    const { days } = this.searchFilterData;
    let range;
    if (days === 2) {
      const getDateRangeFromLocalStorage = JSON.parse(
        localStorage.getItem('CUSTOM_RANGE')
      );
      const from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate ||
          getDateRangeFromLocalStorage.data.from
      );
      const to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate || getDateRangeFromLocalStorage.data.to
      );
      range = { from, to };
    } else {
      range = this._dateService.getDateRangeInISO(days);
    }

    const groupBy = this._data.decideGroup(
      days,
      range,
      DRIVER_AVG_SCORE_GRAPH_OPTION,
      DRIVER_INCIDENTS_GRAPH_OPTION
    );
    this.groupBy = groupBy;
    const homeLocations = this._storageService.getStorageValue('HOME_LOCATION_ABV');
    this.ranges = range;
    return new DriverStatsModel(
      range.from,
      range.to,
      30,
      groupBy.group,
      homeLocations
    );
  }

  private _preparePageData() {
    const distinctFilterChanged$ = this.filterChange$.pipe(
      distinctUntilChanged()
    );

    // Whenever the filter changed to a new value, reload mechanism will be fired
    this.reload$ = distinctFilterChanged$.pipe(map(() => true));
    // The root of all data push
    distinctFilterChanged$.subscribe(_ => {
      const driverStatsParams = {...this.driverStatsModel};
      driverStatsParams.activeDivisions = this._storageService.getStorageValue('HOME_LOCATION_FULL_LIST').map(item => item.locationId);
      const obs1$ = this._zcfleet.getDriversStats(this.driverId, { params: driverStatsParams }).pipe(
        catchError((_err) => this.catchAPIError('metrics')),
        tap((res) => {
          // Update trip count for the trip driven comp
          if (res) {
            const { tripCount } = res;
            if (tripCount) {
              this.totalTrips = tripCount;
            }
          }
        })
      );
      const obs2$ = this._zcfleet
        .getTrendsList({ params: this.driverStatsModel })
        .pipe(catchError((_err) => this.catchAPIError('driver performance')));
      const obs3$ = this._zcfleet.isFleetContainsRideCamCamera().pipe(
        switchMap((hasRideCam) => {
          const getDriverLocationsApi = [
            this._zcfleet.getDriverLocation(this.driverId).pipe(),
            this._zcfleet.getDriverLocationFromEntity(this.driverId).pipe(),
          ];
          return iif(
            () => hasRideCam,
            getDriverLocationsApi[0],
            getDriverLocationsApi[1]
          );
        }),
        catchError((_err) => of({ homeLocation: 'N/A' }))
      );
      const requestObs = [obs1$, obs2$, obs3$, this.driverName$];
      this.dataSubs = combineLatest(requestObs).pipe(
        map(([driverState, fleetTrends, driverLocation, driverName]) => ({
          driverState,
          fleetTrends,
          driverLocation: driverLocation.homeLocation,
          driverName,
        })),
        catchError((_err) => {
          console.log('catch error', _err);
          return of(undefined);
        })
      ).subscribe(res => {
        //Process all response data
        if (res) {

          if (res.driverState) {
            // Prepare for driver overview
            const overviewData = {
              driverState: res.driverState || {},
              driverName: res.driverName,
              driverLocation: res.driverLocation,
            };
            // Prepare for driver stats
            const tripDetails = this._correctEventCount(res.driverState.tripdetails);
            const incidentList = this._getTopIncidentAssetList(tripDetails);
            const keyMetric = this._tripDetailsMapToKeyMetrics(tripDetails);
            if (this._networkService.isOnline) {
              this._tripDetails$.next(tripDetails);
              this._incidentList$.next(incidentList);
              this._keyMetric$.next(keyMetric);
              this._driverOverview$.next(overviewData);
            }
          }


          if (res.fleetTrends) {
            // Prepare for driver performance trend
            const performanceData = {
              fleetTrend: res.fleetTrends ? ([...res.fleetTrends.data] || []) : [],
              driverTrend: res.driverState ? ([...res.driverState.trend] || 0) : 0,
              dateArray: this.groupBy.dateArray,
              updateChart: true,
            };
            if (res.driverState && this._networkService.isOnline) {
              this._performanceTrend$.next(performanceData);
            }
          }
        }
      });
    });
  }

  private _getTopIncidentAssetList(eventData: any): IncidentStat[] {
    if (eventData && eventData.eventCount) {
      return [
        {
          name: 'Accelerating',
          value: eventData.eventCount['Harsh-Acceleration'],
          incidentClass: 'incident-acceleration',
        },
        {
          name: 'Braking',
          value: eventData.eventCount['Harsh-Braking'],
          incidentClass: 'incident-braking',
        },
        {
          name: 'Lane Drift',
          value: eventData.eventCount['Lane-Drift-Found'],
          incidentClass: 'incident-lane-drift',
        },
        {
          name: 'Cornering',
          value: eventData.eventCount['Cornering'],
          incidentClass: 'incident-cornering',
        },
        {
          name: 'Speeding',
          value: eventData.eventCount['Traffic-Speed-Violated'],
          incidentClass: 'incident-speeding',
        },
        {
          name: 'Tailgating',
          value: eventData.eventCount['Tail-Gating-Detected'],
          incidentClass: 'incident-tailgating',
        },
        {
          name: 'Distraction',
          value: eventData.eventCount['Distracted-Driving'],
          incidentClass: 'incident-distraction',
        },
        {
          name: 'Stop Sign',
          value: eventData.eventCount['Traffic-STOP-Sign-Violated'],
          incidentClass: 'incident-stop-sign',
        },
      ];
    }
  }

  private _tripDetailsMapToKeyMetrics(data: any): KeyMetric[] {
    if (data && data.eventCount) {
      const totalMiles = Number(
        (data.tripDistance * MILE_OF_A_KILOMETER).toFixed(2)
      );
      const totalHours = Number(
        (data.tripDuration / SECONDS_OF_A_HOUR).toFixed(2)
      );
      const totalIncident = data.eventCount.total;
      const incidentPerNMile =
        data.tripDistance > 0 ? (totalIncident * MILE_NUMBER) / totalMiles : 0;
      const incidentPerMin =
        data.tripDuration > 0 ? totalIncident / totalHours : 0;

      return [
        {
          name: 'Total Miles',
          value: totalMiles,
          decimalType: '1.0-2',
          difference: 0,
        },
        {
          name: 'Total Hours',
          value: totalHours,
          decimalType: '1.0-2',
          difference: 0,
        },
        { name: 'Total Trips', value: data.tripCount, difference: 0 },
        {
          name: `Incidents / ${MILE_NUMBER}mi`,
          value: incidentPerNMile,
          decimalType: '1.0-2',
          difference: 0,
        },
        {
          name: 'Incidents / Hour',
          value: incidentPerMin,
          decimalType: '1.0-2',
          difference: 0,
        },
        { name: 'Total Incidents', value: totalIncident, difference: 0 },
      ];
    }
    return [];
  }

  private _correctEventCount(tripDetails: any) {
    if (tripDetails && tripDetails.eventCount) {
      tripDetails.eventCount['total'] = calcSumTotalIncidents(
        tripDetails.eventCount
      );
    }
    return tripDetails;
  }
}
