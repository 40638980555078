import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  catchError,
  takeUntil,
  map,
  finalize,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';

import { PackageConfigService } from '@modules/package-config/services/package-config.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { LoadingService } from '@app-core/services/loading.service';
import { debounceTime } from 'rxjs/operators';

const addDisplayPackageName = (asset, standardPackages = []) => {
  const { packages = [] } = asset;
  const allPackages = [...standardPackages, ...packages];
  let displayPackageName;

  if (allPackages.includes('EDVR')) {
    displayPackageName = ['Base', 'Video', 'DVR', 'EDVR'];
  } else if (allPackages.includes('DVR')) {
    displayPackageName = ['Base', 'Video', 'DVR'];
  } else if (allPackages.includes('Video')) {
    displayPackageName = ['Base', 'Video', 'DVR'];
  } else if (allPackages.includes('Base')) {
    displayPackageName = ['Base'];
  }
  return {
    ...asset,
    displayPackageName,
  };
};

const compareStrings = (a, b) => {
  const A = a.toUpperCase();
  const B = b.toUpperCase();
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  return 0;
};

const sortFuncMap = {
  assetId: ({ assetId: assetA = '' }, { assetId: assetB = '' }) =>
    compareStrings(assetA, assetB),
  gpsId: (
    { metadata: { gpsId: gpsIdA = '' } = {} },
    { metadata: { gpsId: gpsIdB = '' } = {} }
  ) => compareStrings(gpsIdA, gpsIdB),
  assetNumber: (
    { metadata: { assetNumber: assetNumberA = '' } = {} },
    { metadata: { assetNumber: assetNumberB = '' } = {} }
  ) => compareStrings(assetNumberA, assetNumberB),
};

@Component({
  selector: 'app-asset-config',
  templateUrl: './asset-config.component.html',
  styleUrls: ['./asset-config.component.scss'],
})
export class AssetConfigComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput')
  public fileInput: ElementRef;

  @Input()
  public accountsList: any = [];

  public assetList: any = [];
  public getAccountParams = null;
  public file: File = null;
  public showFleetList = true;
  public packageOptions = [
    {
      name: 'Coach Basic',
      value: ['Base'],
    },
    {
      name: 'Coach',
      value: ['Base', 'Video', 'DVR'],
    },
    {
      name: 'EDVR',
      value: ['Base', 'Video', 'DVR', 'EDVR'],
    },
  ];
  public fleetIdSearch = '';
  public fleetId = '';

  public sortByKey = '';
  public isReversed = true;
  public searchAccountControl = new FormControl('');
  public totalAssets = 0;
  public pageSize = 10;
  public resetPaginator = false;

  private _ngUnSubscribe: Subject<void> = new Subject();
  private _assetListLimit = this.pageSize;
  private _assetListOffset = 0;

  constructor(
    private _packageService: PackageConfigService,
    private _toasterService: ToasterService,
    private _loading: LoadingService
  ) {}

  public ngOnInit() {
    this.searchAccountControl.valueChanges
      .pipe(
        takeUntil(this._ngUnSubscribe),
        debounceTime(500),
        map((val) => val.trim().toLowerCase()),
        distinctUntilChanged(),
        switchMap((searchString) => this._autoCompleteFleets(searchString)),
        catchError(() => of([]))
      )
      .subscribe((fleets: string[]) => {
        this.accountsList = fleets;
      });
  }

  public ngOnDestroy() {
    this._ngUnSubscribe.next();
    this._ngUnSubscribe.complete();
  }

  public async changeFleet(x: any) {
    const { value } = x;
    this.fleetId = value;
    await this.getAssetList();
  }

  /**
   * @description: function to update asset Info
   * @param:
   */
  public updateAssetInfo(asset) {
    console.log(asset);
  }

  /**
   * @description: function to get account list
   * @param: searchstring
   */
  public getAccountsList(searchString) {
    this.showFleetList = true;
    this.getAccountParams = {
      fleetId: searchString,
    };
    this._loading.show();
    this._packageService
      .getListOfAccounts(this.getAccountParams)
      .pipe(debounceTime(500))
      .subscribe(
        (res) => {
          this._loading.hide();
          this.accountsList = res.data.fleets;
        },
        (err) => {
          this._loading.hide();
          console.log(err);
        }
      );
  }

  /**
   * @description: function to get asset list based on particular fleetid
   * @param: fleetId
   */
  public getAssetListBasedOnFleetId(fleetId) {
    this.fleetId = fleetId;
    this.showFleetList = false;
    this.getAssetList();
  }

  public async disableAsset(assetId) {
    await this.updateAsset(
      assetId,
      { active: false },
      'Asset Disabled Successfully',
      'Asset disable failed'
    );
  }

  public async enableAsset(assetId) {
    await this.updateAsset(
      assetId,
      { active: true },
      'Asset Enabled Successfully',
      'Asset enable failed'
    );
  }

  public async updatePackage(asset) {
    const { assetId, displayPackageName } = asset;
    const packages = displayPackageName
      .split(',')
      .map((x) => x.charAt(0).toUpperCase() + x.slice(1));
    await this.updateAsset(
      assetId,
      { packages },
      'Asset Package updated',
      'Asset package update failed'
    );
  }

  public sortBy(x) {
    const shouldReverse = x === this.sortByKey && !this.isReversed;
    this.sortByKey = x;
    const sortFunc = sortFuncMap[x];
    this.assetList.sort(sortFunc);
    this.isReversed = shouldReverse;
    if (shouldReverse) {
      this.assetList.reverse();
    }
  }

  /**
   * @description: function to get asset list
   * @param: fleetid
   */
  public getAssetList() {
    this._loading.show();
    return new Promise<void>((resolve, reject) => {
      const fleetIdInLowerCase = this.fleetId && this.fleetId.toLowerCase();
      const limit = this._assetListLimit;
      const offset = this._assetListOffset;
      this._packageService
        .getAssetListOf(fleetIdInLowerCase, limit, offset)
        .subscribe(
          (res) => {
            this._loading.hide();
            const { assets = [], assetCount = 0, standardPackages } = res;
            this.assetList = assets.map((asset) =>
              addDisplayPackageName(asset, standardPackages)
            );
            this.totalAssets = assetCount;
            resolve();
          },
          (err) => {
            this._loading.hide();
            this.assetList = [];
            console.log(err);
            reject();
          }
        );
    });
  }

  public getMoreData(page) {
    this._assetListLimit = page.limit;
    this._assetListOffset = page.offset;
    this.getAssetList();
  }

  /**
   * @description: function to update asset details
   * @param: fleetid, assetId
   */
  public updateAsset(
    assetId: string,
    update: any,
    successMessage = 'Successfully',
    failureMessage = 'Failure'
  ) {
    this._loading.show();
    this._packageService.updateAsset(this.fleetId, assetId, update).subscribe(
      async () => {
        this._loading.hide();
        try {
          this._toasterService.success(successMessage);
          await this.getAssetList();
        } catch (err) {
          this._loading.hide();
          this._toasterService.error('Error fetching updated Assets');
          console.log(err);
        }
      },
      (err) => {
        console.log(err);
        this._loading.hide();
        this._toasterService.error(failureMessage);
      }
    );
  }

  /**
   * @description: function to upload asset details
   * @param:
   */
  public uploadAssetList() {
    const file = this.file;

    if (file) {
      this._loading.show();
      const formData: FormData = new FormData();
      formData.append('assets', file, file.name);
      this._packageService.uploadAssetList(this.fleetId, formData).subscribe(
        async (res) => {
          this._loading.hide();
          const errorMessages = [];
          const {
            uploadCount = 0,
            validAssets = 0,
            assetsFailed = [],
            assetsUpdated = [],
          } = res;
          if (uploadCount && uploadCount === assetsUpdated.length) {
            this._toasterService.success('Assets uploaded successfully');
          }
          try {
            await this.getAssetList();
          } catch (err) {
            errorMessages.push('Refresh the page to get updated asset list');
          }
          if (!uploadCount) {
            errorMessages.push('No assets were uploaded');
          }
          if (!validAssets) {
            errorMessages.push('No valid assets found');
          }
          if (uploadCount !== validAssets) {
            errorMessages.push(
              `Found ${uploadCount - validAssets} invalid assets`
            );
          }
          if (assetsFailed.length) {
            errorMessages.push(
              assetsFailed
                .map(
                  ({ assetId, message }) =>
                    `${assetId}: Failed to update because ${message}`
                )
                .reduce((a, b) => `${a}. ${b}`, '')
            );
          }
          if (errorMessages.length) {
            this._toasterService.error(errorMessages.join('. '));
          }
          this.fileInput.nativeElement.value = null;
        },
        (err) => {
          this._toasterService.error(
            (err.error && err.error.message) || 'Bad request'
          );
          console.log(err);
          this._loading.hide();
        }
      );
    }
  }

  /**
   * @description: function to check file input changed
   * @param:
   */
  public fileInputChanged() {
    const files = this.fileInput.nativeElement.files;
    this.file = files[0];
  }

  private _autoCompleteFleets(fleetId = ''): Observable<string[]> {
    if (!fleetId) {
      return of([]);
    }
    const params = {
      fleetId,
    };
    this.showFleetList = true;
    this._loading.show();
    return this._packageService.getListOfAccounts(params).pipe(
      finalize(() => this._loading.hide()),
      map((res: any) => {
        const { fleets = [] } = res.data || {};
        return fleets;
      }),
      catchError(() => of([]))
    );
  }
}
