<div class="table-data-container">
  <div class="two-column-flex">
    <!-- first -->
    <div class="first-column">
      <ng-content select="[first-column]"></ng-content>
    </div>
    <!-- second -->
    <div class="second-column">
      <ng-content select="[second-column]"></ng-content>
    </div>
  </div>
</div>
