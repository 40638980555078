import { Component, Input, OnInit } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'app-footer2',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() public hideFooter: boolean;

  public date = new Date();

  public appVersion = '';

  public ngOnInit() {
    this.appVersion = environment.version;
  }
}
