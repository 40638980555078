import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SortDirection } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import {
  combineLatest,
  from,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  finalize,
  map,
  mergeMap,
  retry,
  share,
} from 'rxjs/operators';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Data } from '@modules/dashboard3/services/data.service';
import {
  ColDescription,
  PaginationData,
} from '@modules/shared/components/table/table.component';
import {
  ChallengesModel,
  SearchFilterModel,
  SearchFilterTableModel,
} from '@modules/dashboard3/dashboard3.models';
import { ErrorMessage } from '@modules/shared/components/error-message/error-message.component';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import {
  createMomentObjectUtc,
  getTimezoneString,
  makeUniqueWithIndex,
} from '@modules/shared/utils';
import {
  GeoCodeResult,
  Point,
  ReverseGeoCodeService,
} from '@modules/dashboard3/services/reverseGeocode.service';
import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import { StorageService } from '@app-core/services/storage.service';
import {
  BLANK_TEXT,
  DEFAULT_BUFFER_SIZE,
  ERROR_MESSAGE,
  EVENTS_INCIDENT_TYPE,
  UNASSIGNED_DRIVER,
  UNIDENTIFIED_DRIVER_NAME,
} from '@app-core/constants/constants';
import {
  ChannelGroupService,
  ChannelGroupType,
} from '@app-core/services/query-channel.service';
import { BookmarkInfo } from '@modules/dashboard3/components/comment-button-group/comment-button-group.component';
import {
  FilterCommand,
  FilterOptions,
} from '@modules/dashboard3/components/search-filter-table/search-filter-table.component';
import { LocalService } from '@app-core/services/local.service';
import {
  StackableCustomSnackbarComponent,
} from '@modules/dashboard3/components/stackable-custom-snackbar/stackable-custom-snackbar.component';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { HomeLocationService } from '@app-core/services/home-location.service';

export interface AssetViewIncidentList {
  rows: any[];
  limit?: number;
  rawLength: number;
  skip?: number;
}

const ASSET_VIEW_TABLE_PAGE_SIZE = 'asset-view-table-page-size';
const CUSTOM_PAGINATION_LABEL = 'Rows per page:';
const MAXIMUM_VIOLATION_PAGE_SIZE = 500;
const MAXIMUM_CONCURRENT_REQUEST_LENGTH = 25;
const MILLISECOND_PER_HOUR = 60000 * 60;

const SNACKBAR_TITLE = 'An error has occurred';
const SNACKBAR_INTERNET_TITLE = 'No network found';
const SNACKBAR_MSG = (target: string) =>
  `We were unable to load ${
    target || ''
  } data due to a connection issue. \nPlease wait a few minutes or try again.`;
const SNACKBAR_NO_INTERNET = 'We were unable to load incident data. Please wait a few minutes and try again.';

@Component({
  selector: 'app-asset-view',
  templateUrl: './asset-view.component.html',
  styleUrls: ['./asset-view.component.scss'],
})
export class AssetViewComponent implements OnInit, OnDestroy {
  @Output() public loaded = new EventEmitter<string>();
  public displayedColumns = [
    'displayEventType',
    'assetName',
    'driverName',
    'disputeTimeMoment',
    'address',
    'action',
    'ripple-toggler',
  ];
  public colDescriptions: ColDescription[] = [
    // incident column
    {
      colKey: 'displayEventType',
      colDisplayName: 'Incident',
      sortable: true,
      type: 'text',
      getter: (item) => item.displayEventType,
    },
    {
      colKey: 'assetName',
      colDisplayName: 'asset number',
      sortable: true,
      type: 'custom',
      getter: (item) => item.assetName,
      async: true,
      sortKey: 'assetNameSync',
    },
    {
      colKey: 'driverName',
      colDisplayName: 'Driver',
      sortable: true,
      type: 'custom',
      getter: (item) => item.driverNameAsync,
      async: true,
      sortKey: 'driverNameSyncSortKey',
    },
    {
      colKey: 'disputeTimeMoment',
      colDisplayName: 'Date / Time',
      sortable: true,
      type: 'date-time',
      getter: (item) => item.disputeTimeMoment,
      sortKey: 'timestampUTC',
    },
    {
      colKey: 'address',
      colDisplayName: 'Address',
      sortable: false,
      type: 'text',
      getter: (item) => item.address,
      async: true,
    },
    {
      colKey: 'action',
      colDisplayName: 'Actions',
      sortable: false,
      type: 'custom',
      getter: (item) => item.bookmarkInfo,
      async: true,
    },
  ];

  public errorDisplayedColumns = [
    'displayEventType',
    'assetName',
    'driverName',
    'disputeTimeMoment',
    'address',
    'action',
  ];

  public errorColDescriptions: ColDescription[] = [
    // incident column
    {
      colKey: 'displayEventType',
      colDisplayName: 'Incident',
      sortable: false,
      type: 'text',
      async: true,
      getter: (item) => item,
    },
    {
      colKey: 'assetName',
      colDisplayName: 'asset number',
      sortable: false,
      type: 'custom',
      getter: (item) => item,
      async: true,
      sortKey: 'assetNameSync',
    },
    {
      colKey: 'driverName',
      colDisplayName: 'Driver',
      sortable: false,
      type: 'custom',
      getter: (item) => item,
      async: true,
      sortKey: 'driverNameSyncSortKey',
    },
    {
      colKey: 'disputeTimeMoment',
      colDisplayName: 'Date / Time',
      sortable: false,
      type: 'date-time',
      getter: (item) => item,
      sortKey: 'timestampUTC',
      async: true,
    },
    {
      colKey: 'address',
      colDisplayName: 'Address',
      sortable: false,
      type: 'text',
      getter: (item) => item,
      async: true,
    },
    {
      colKey: 'action',
      colDisplayName: 'Actions',
      sortable: false,
      type: 'custom',
      getter: (item) => item,
      async: true,
    },
  ];

  public filterOptions: any;
  public sortHeader = [
    'displayEventType',
    'assetName',
    'driverName',
    'disputeTimeMoment',
  ];
  public sort: SortDirection = 'desc';
  public sortBy = 'disputeTimeMoment';

  public driverCount = 0;
  public searchFilterData: SearchFilterModel;
  public reverseBookmarkInfo$: Observable<[any, any]>;
  public reverseAddressInfo$: Observable<GeoCodeResult[]>;
  public violationIdentifier: string;
  public currentPageInfo = {
    pageIndex: 0,
    pageSize: 0,
  };

  public isErrorLoadingData = false;
  public homeLocationList = [];
  public incidentsList: any[] = [];
  public incidentListOffset = 0;
  public tableCurrentPageDetail = [];
  public customItemsPerPageLabel = CUSTOM_PAGINATION_LABEL;

  public isRetry = false;
  public tripDetailMap = {};
  public refetchFilter = false;
  public locationListData: any[] = [];
  public dropdownFilterOptions: Record<string, FilterOptions> = {};
  public isReload = false;

  public positionOptions: TooltipPosition[] = [
    'after',
    'before',
    'above',
    'below',
    'left',
    'right',
  ];
  public toolTipPosition = new FormControl(this.positionOptions[3]);

  public get incidentListTable$(): Observable<PaginationData<any>> {
    return this._incidentListTable$;
  }

  public get notifySortChange$(): Observable<[string, SortDirection]> {
    return this._notifyPageChange$;
  }

  public get localStorageHomeLocation() {
    return this._storage.getStorageValue('HOME_LOCATION');
  };

  public get localStorageHomeLocationABV() {
    return this._storage.getStorageValue('HOME_LOCATION_ABV');
  };

  private _filterChangeSubscription: any;
  private _tripDetailMapFetchingSubscription: Subscription;
  private _incidentListTable$ = new ReplaySubject<PaginationData<any>>(
    DEFAULT_BUFFER_SIZE
  );
  private _notifyPageChange$ = new ReplaySubject<[string, SortDirection]>(
    DEFAULT_BUFFER_SIZE
  );
  public get errorMessage$(): Observable<ErrorMessage> {
    return this._errorMessage$;
  }
  private _errorMessage$ = new ReplaySubject<ErrorMessage>(DEFAULT_BUFFER_SIZE);
  private _filterCommands: FilterCommand[] = [];
  private _subscription: Subscription = new Subscription();

  constructor(
    private _dateService: DateService,
    private _sort: ArraySortPipe,
    private _router: Router,
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _data: Data,
    private _reverseGeoCodeService: ReverseGeoCodeService,
    private _storage: StorageService,
    private _title: Title,
    private _channel: ChannelGroupService,
    private _localService: LocalService,
    private _snackbarService: SnackbarService,
    private _networkService: TrackNetworkStatusService,
    private _homeLocationService: HomeLocationService
  ) {
    // get current pagesize, if not set default value 10
    this.currentPageInfo.pageSize =
      this._storage.getStorageValue(ASSET_VIEW_TABLE_PAGE_SIZE) || 10;
  }

  public ngOnInit() {
    this._title.setTitle('Asset View - Zonar Coach');
    this._syncLocalStorageHomeLocation(false);
    this._getHomeLocations();
  }

  public ngOnDestroy() {
    this._loading.hide();
    this._syncLocalStorageHomeLocation(true);
    this._subscription.unsubscribe();
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  /**
   * @description: function to update filter options
   * @param:filterOptions SearchFilterTableModel
   */
  public onDateChange(filterOptions: SearchFilterTableModel) {
    // clear incidentList everytime filter changed
    this.incidentsList = [];
    this._snackbarService.closeCurrentSnackbar();
    this.getData(this.incidentListOffset, filterOptions);
  }

  public onFilterChange(filterCommands: FilterCommand[]) {
    this._filterCommands = filterCommands;
    this.refetchFilter = false;
    // When changing the filter, the data in the table defaults to the first page with pageIndex = 0
    const firstPageIndex = 0;
    this.loadPageData(firstPageIndex, this.currentPageInfo.pageSize);
  }

  public initSnackbarInstance() {
    if (!this._snackbarService.isSnackbarOpened) {
      this._snackbarService.openStackableSnackbar(StackableCustomSnackbarComponent);
    }
  }

  public reloadPage() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigateByUrl(this._router.url);
  }

  public catchAPIError<T>(errMsg: string, responseData: T): Observable<T> {
    if (this._snackbarService.isSnackbarOpened && !this._networkService.isOnline) {
      this._loading.hide();
      this._subscription = this._snackbarService.snackbarRef.afterDismissed().subscribe(_ => {
        this.catchAPIError(errMsg, responseData);
      });
    } else {
      this.initSnackbarInstance();
      if (this._networkService.isOnline) {
        this._snackbarService.toastMsgNotifier.next({
          title: SNACKBAR_TITLE,
          textMsg: SNACKBAR_MSG(errMsg),
          panelClasses: ['error-msg'],
          action: 'Try again',
          linkAction: this.reloadPage.bind(this),
          isShowContact: true,
        });
      } else {
        // not push no internet message yet
        this._snackbarService.toastMsgNotifier.next({
          title: SNACKBAR_INTERNET_TITLE,
          textMsg: SNACKBAR_NO_INTERNET,
          panelClasses: ['error-msg'],
          action: 'Try again',
          linkAction: this.reloadPage.bind(this),
          isShowContact: true,
        });
      }
      this._loading.hide();
      return of(responseData);
    }
  }

  /**
   * @description: function to get challenged incident data list
   * @param:isRetry
   */
  public getData(
    offset: number,
    filterOptions?: SearchFilterTableModel,
    isRetry: boolean = false
  ): void {
    this._loading.show();
    if (isRetry) {
      // TO-DO: Do something when retry
      console.log(isRetry);
    }
    if (filterOptions) {
      this._localService.saveSearchFilterData(filterOptions);
    }
    const { days } = filterOptions || this._data.filterData;
    let date;
    if (days === 2) {
      const fromDate = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      const to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      date = { from: fromDate, to };
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList = this._storage.getStorageValue('HOME_LOCATION_ABV');
    const params = new ChallengesModel(
      date.from,
      date.to,
      MAXIMUM_VIOLATION_PAGE_SIZE,
      offset,
      this.homeLocationList,
      EVENTS_INCIDENT_TYPE.map(item => item[0])
    );
    const OPTIONS = {
      params: { ...params, sort: 'desc' },
    };

    this._zcfleet.getAssetViewFleetIncidents(OPTIONS).subscribe(
      (res: AssetViewIncidentList) => {
        this.isErrorLoadingData = false;
        res.rows.forEach((incident) => {
          incident.displayTimeZone =
            (new Date(incident.timestampUTC).getTime() -
              new Date(incident.timestamp).getTime()) /
            MILLISECOND_PER_HOUR;
          incident.displayEventType =
            EVENTS_INCIDENT_TYPE.find((x) => x[0] === incident.eventType)[1] ||
            '';
        });

        this.incidentsList = this.incidentsList.concat(res.rows);
        // Get full list
        if (res.rawLength === MAXIMUM_VIOLATION_PAGE_SIZE) {
          this.incidentListOffset += MAXIMUM_VIOLATION_PAGE_SIZE;
          this.getData(this.incidentListOffset);
          this._loading.hide();
          return;
        } else {
          this.incidentListOffset = 0;
        }

        this._filterCommands = [];
        this.refetchFilter = true;

        // update incident type filter data
        this.dropdownFilterOptions = {
          ...this.dropdownFilterOptions,
          ...this._buildDataFilterOption(
            'incidentType',
            'displayEventType',
            'displayEventType'
          )};
        // this._requestLocationFilter();
        this._requestTableData();

        this._loading.hide();
        // init mat table disputed incident page data
        const pageIndex = 0;
        const pageSize = this.currentPageInfo.pageSize; // default page size or localstorage value
        // set default sort by time
        const defaultSortOptions: [string, SortDirection] = [
          'disputeTimeMoment',
          'desc',
        ];
        this.notifySortChange(defaultSortOptions);
        // load table page data
        this.loadPageData(pageIndex, pageSize, true);
      },
      (err) => {
        this.catchAPIError('incident', undefined);
        this.isErrorLoadingData = true;
        this._incidentListTable$.next({
          data: this.dummiesData,
          totalItems: this.currentPageInfo.pageSize || 10,
          totalPage: 1,
          perPage: this.currentPageInfo.pageSize || 10,
          pageIndex: 0,
          selectFirstRow: false,
          selectCustomRow: -1,
        });
        console.log('error while fetching fleet data', err);
      }
    );
  }

  public get dummiesData(): any[] {
    const result = [];
    const pageSize = this.currentPageInfo.pageSize;
    const item = new ReplaySubject().asObservable();
    for (let i = 0; i <= pageSize; i++) {
      result.push(item);
    }
    return result;
  }

  public goToDashboard() {
    this._router.navigate(['/dashboard']);
  }

  public notifySortChange(event: [string, SortDirection]) {
    this._notifyPageChange$.next(event);
  }

  /**
   * @description update tableCurrentPageDetail by paginator index
   * @param event paginator object include pageIndex and PageSize
   */
  public updatePage(event: any) {
    // store selected pageSize to localstorage, this value removed when user logout
    if (event.pageSize) {
      this._storage.setStorageValue(ASSET_VIEW_TABLE_PAGE_SIZE, event.pageSize);
    }
    this.loadPageData(event.pageIndex, event.pageSize);
  }

  public sortChangeInternal(event: [string, SortDirection]) {
    this.sortBy = event[0];
    this.sort = event[1];

    this.loadPageData(
      this.currentPageInfo.pageIndex,
      this.currentPageInfo.pageSize
    );
  }

  /**
   * @description: Update page info and emit the information to table data source
   * @param: pageIndex pageIndex begin with 0
   * @param: pageSize item per page
   */
  public loadPageData(
    pageIndex: number,
    pageSize: number,
    selectFirstRow = false,
    selectCustomRow = -1
  ) {
    let filteredIncidents = [...this.incidentsList];

    for (const command of this._filterCommands) {
      filteredIncidents = filteredIncidents.filter((incident) =>
        command.filterValues.some(
          (value) => incident[command.filterKey] === value.id
        )
      );
    }
    const sortedIncidents = this._sortList(filteredIncidents);
    const { currentPage, previousPage } = this.patchPageInfo(
      sortedIncidents,
      pageIndex,
      pageSize
    );
    this.tableCurrentPageDetail = currentPage;
    // update time zone for only current page incidents
    this.pageChangeUpdateTimeZone();

    const latlongList: Point[] = this.tableCurrentPageDetail.map((item) => ({
      latitude: item.latitude,
      longitude: item.longitude,
      additional_payload: {
        tripId: item.tripId,
        eventIndex: item.eventIndex,
      },
    }));

    // Handle the case when we are at the last page and
    // delete the last row, if there are previous pages, we
    // should move back.
    if (currentPage.length === 0 && pageIndex > 0) {
      this.tableCurrentPageDetail = previousPage;
      this.currentPageInfo.pageIndex -= 1;
    }

    // violationIdentifiers is used to get bookmarkInfo
    const violationIdentifiers = this.tableCurrentPageDetail.map((item) =>
      this.getRowDataIdentifier(item)
    );

    const queryOptions = { params: { sort: 'asc'} };

    this.reverseAddressInfo$ = from(
      this._reverseGeoCodeService.reverseMultiplePoint(latlongList)
    ).pipe(retry(), share());

    this.reverseBookmarkInfo$ = combineLatest([
      this._zcfleet.getMultiCommentByViolationIdentifiers(violationIdentifiers, queryOptions),
      this._zcfleet.getMultiBookmarkByViolationIdentifiers(
        violationIdentifiers
      ),
    ]).pipe(retry(), share());

    this.tableCurrentPageDetail.forEach((item) => {
      // get bookmarkInfo
      item.bookmarkInfo = this.reverseBookmarkInfo$.pipe(
        map(([commentList, bookmarkList]) => {
          const incidentId = this.getRowDataIdentifier(item);
          return {
            comment: commentList[incidentId],
            bookmark: bookmarkList[incidentId],
          };
        }),
        map((rowBookmarkInfo) =>
          this._transformRowComment(item, rowBookmarkInfo)
        )
      );

      // get address
      item.address = this.reverseAddressInfo$.pipe(
        map((resGeoCodeList) => {
          const incidentId = this.getRowDataIdentifier(item);
          const address = resGeoCodeList.find(
            (geo) => this.getRowDataIdentifier(geo) === incidentId
          );
          return (address && address.full_address) ? address.full_address : 'Unknown';
        })
      );
    });

    this._incidentListTable$.next({
      data: this.tableCurrentPageDetail,
      totalItems: sortedIncidents.length,
      totalPage: Math.ceil(sortedIncidents.length / pageSize),
      perPage: pageSize,
      pageIndex: this.currentPageInfo.pageIndex,
      selectFirstRow,
      selectCustomRow,
    });
  }

  public patchPageInfo<T>(source: T[], pageIndex: number, pageSize: number) {
    this._patchPageInfo(pageIndex, pageSize);
    return this._paginatedContent(source);
  }

  /**
   * @description update timezone by trip first location for current page incident list
   */
  public pageChangeUpdateTimeZone() {
    const apiCalls = this._createGetTripDetailApiCall(
      this.tableCurrentPageDetail
    );

    if (this._tripDetailMapFetchingSubscription) {
      this._tripDetailMapFetchingSubscription.unsubscribe();
    }
    this._tripDetailMapFetchingSubscription = merge(...apiCalls).subscribe(
      (data: any) => {
        this.tripDetailMap[data.tripId] = data;
        // sort incidentList desc by date
        this.tableCurrentPageDetail.map(this._addDisputeTimeMoment.bind(this));
      }
    );
  }

  public getRowDataIdentifier(element: any): string {
    if (element) {
      return element.tripId + '-' + element.eventIndex;
    }
    return '';
  }

  public setMedia(element: any) {
    if (!element) {
      element = {};
    } else {
      element.mediaLink = element.eventVideoFile;
      if (element.eventType === 'Traffic-Speed-Violated' && !element.speedSign) {
        element.speedSign = {
          eventVideoFilename: undefined,
          speedSignValue: undefined,
        };
      }
    }
    return element;
  }

  private _createGetTripDetailApiCall(violation: any[]): Observable<any>[] {
    const tripList = violation.map((item) => ({
      tripId: item.tripId,
      value: {
        driverId: item.driverId, // we need driverId to request the API
        tripId: item.tripId,
      },
    }));

    /** Get the tripId item that wasn't cached */
    const notCachedKeys = Array.from(
      new Set(tripList.map((item) => item.tripId))
    )
      .map((uniqueTripId) =>
        tripList.find((trip) => trip.tripId === uniqueTripId)
      ) // only return unique trip id item
      .filter((trip) => !this.tripDetailMap[trip.tripId]);

    const apiCalls = notCachedKeys.map((trip) =>
      this._zcfleet.getTripDetails(trip.value.driverId, trip.value.tripId)
    );
    apiCalls.push(of({ tripId: 'notused' })); // To ensure that the subscribe callback will always be called

    return apiCalls;
  }

  private _patchPageInfo(pageIndex: number, pageSize: number) {
    this.currentPageInfo.pageIndex = pageIndex;
    this.currentPageInfo.pageSize = pageSize;
  }

  /**
   * @description: function to sort the list
   * @param: incident list
   */
  private _sortList<T>(list: T[]): T[] {
    return this._sortListInternal(list, this.sort === 'asc');
  }

  private _sortListInternal<T>(list: T[], isAsc): T[] {
    const clonedList = [...list];
    return this._sort.transform(clonedList, this.sortBy, isAsc);
  }

  private _paginatedContent<T>(source: T[]): {
    currentPage: T[];
    previousPage: T[];
  } {
    const pageIndex = this.currentPageInfo.pageIndex;
    const pageSize = this.currentPageInfo.pageSize;

    const paginate = (page, size) =>
      source.slice(page * size, (page + 1) * size);

    return {
      currentPage: paginate(pageIndex, pageSize),
      previousPage: paginate(pageIndex - 1, pageSize),
    };
  }

  private _addDisputeTimeMoment(item): any {
    const tripKey = item.tripId;
    item.disputeTimeMoment = createMomentObjectUtc(item.timestampUTC);

    if (this.tripDetailMap[tripKey]) {
      const {
        firstLocation: { latitude = 0, longitude = 0 } = {},
        timezoneOffset: timezoneOffset = 0,
      } = this.tripDetailMap[tripKey];
      item.disputeTimeMoment = item.disputeTimeMoment.tz(
        getTimezoneString(timezoneOffset, latitude, longitude)
      );
    }
    return item;
  }

  // private _requestLocationFilter() {
  //   this.incidentsList.forEach((incident) => {
  //     // TO-DO
  //     incident.locationName = '';
  //   });
  // }

  /**
   * TO-DO: Remove driverFilterAlwaysUseLM since we do not get data from it anymore
   */
  private _getHomeLocations() {
    this._homeLocationService.getHomeLocations().subscribe(
      (response) => {
        // sort home locations in ascending order
        const responseHomeLocations = Object.keys(response.homeLocations
          .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))).map(key => (response.homeLocations
          .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))[key]));
        this.locationListData = responseHomeLocations.map(({ locationId, locationName }) => ({ id: locationId, name: locationName }));
      }, () => {}
    );
  }
  private _requestTableData() {
    const driverNameChannelGroup = this._channel.getChannelGroup('DriverName');
    this.incidentsList.forEach((incident) => {
      incident.assetName = incident?.asset?.metadata?.assetNumber || 'N/A';
      // Set driverName to '_UNASSIGNED' for violations with driverId is '_UNASSIGNED'
      // set driverName to 'Unidentified Driver'
      if (incident.driverId === UNASSIGNED_DRIVER) {
        incident.driverName = UNIDENTIFIED_DRIVER_NAME;
      }

      if (incident.driverName && incident.driverName !== BLANK_TEXT) {
        incident.driverNameSyncSortKey = incident.driverName;
        incident.driverNameAsync = of(incident.driverName);
      } else {
        incident.driverName = driverNameChannelGroup.getChannelById(
          this._channel.getChannelId('DriverName', incident)
        );
      }

      if (incident.locationName) {
        incident.locationNameSyncSortKey = incident.locationName;
        incident.locationNameAsync = of(incident.locationName);
      } else {
        // TO-DO
        // incident.locationName = locationNameChannelGroup.getChannelById(
        //   this._channel.getChannelId('LocationName', incident)
        // );
      }
    });

    // get driver name API request's body payload
    // only reverse driverName for violations that not included driverName info
    const driverIds: [string, number][] = this.incidentsList.map(
      (item, index) => {
        if (!item.driverNameAsync) {
          return [item.driverId, index];
        }
        return undefined;
      }
    ).filter(item => item !== undefined) as [string, number][];

    this._reverseData(driverIds);
  }

  private _reverseData(driverIds) {
    this._initReverseAssetNameJobs();
    if (driverIds.length) {
      this._initReverseDriverNameJobs(driverIds);
    } else {
      // update empty driver name filter data
      this.dropdownFilterOptions = {
        ...this.dropdownFilterOptions,
        ...this._buildDataFilterOption(
          'driverFilter',
          'driverId',
          'driverNameSyncSortKey'
        )};
    }
  }

  private _initReverseAssetNameJobs() {
    const upstream = this._getUpstreamChannel('AssetName');
    // Show loading while converting data
    if (
      this.dropdownFilterOptions.assetFilter &&
      this.dropdownFilterOptions.assetFilter.enable
    ) {
      this.dropdownFilterOptions.assetFilter.enable = false;
    }

    this.incidentsList.map(item => {
      const assetName = item.assetName;
      item.assetNameSync = assetName;
      item.assetId = item.asset.assetId;
      // if assetName too long then truncate and show tooltip
      const isDisabledToolTip = assetName.length <= 20;
      const rowAssetName = !isDisabledToolTip
        ? assetName.slice(0, 20).concat('...')
        : assetName;
      upstream.notify(item.tripId, [
        assetName,
        isDisabledToolTip,
        rowAssetName,
      ]);
    });

    // update asset number filter data
    this.dropdownFilterOptions = {
      ...this.dropdownFilterOptions,
      ...this._buildDataFilterOption(
        'assetFilter',
        'assetId',
        'assetNameSync'
      )};
  }

  private _initReverseDriverNameJobs(
    driverIdList: [string, number][],
    jobSize = 15 // should not increase the jobSize, too large number will cause server error
  ) {

    if (!driverIdList.length) {
      return;
    }

    const upstream = this._getUpstreamChannel('DriverName');
    const uniqueList = makeUniqueWithIndex(
      driverIdList,
      (driverId, currentVal) => driverId === currentVal
    );
    const updateAllDriverRow = (indexList: number[], driverName: string) => {
      for (const index of indexList) {
        this.incidentsList[index].driverNameSyncSortKey = driverName;
        this.incidentsList[index].driverNameAsync = of(driverName);
      }
    };


    // Show loading while converting data
    if (
      this.dropdownFilterOptions.driverFilter &&
      this.dropdownFilterOptions.driverFilter.enable
    ) {
      this.dropdownFilterOptions.driverFilter.enable = false;
    }
    this._splitJob(
      uniqueList,
      (partialList) =>
        this._zcfleet
          .getMultipleUserInfoFromUserProfileIds(
            partialList.map((item) => item[0])
          )
          .pipe(catchError(() => of({}))),
      jobSize
    )
      .pipe(
        finalize(() =>
          // update driver name filter data
          this.dropdownFilterOptions = {
            ...this.dropdownFilterOptions,
            ...this._buildDataFilterOption(
              'driverFilter',
              'driverId',
              'driverNameSyncSortKey'
              // [ERROR_MESSAGE.INVALID_DRIVER_ID]
            )}
        )
      )
      .subscribe(([res, chunk]) => {
        for (const [driverId, incidentIndexList] of chunk) {
          const user = res[driverId];
          const driverName = user && user.firstName && user.lastName
            ? user.firstName + ' ' + user.lastName
            : ERROR_MESSAGE.INVALID_DRIVER_ID;

          upstream.notify({ driverId }, driverName);
          updateAllDriverRow(incidentIndexList, driverName);
        }
      });
  }

  private _splitJob<T, R = any>(
    list: T[],
    jobFactory: (value: T[]) => Observable<R>,
    jobSize
  ): Observable<[R, T[]]> {
    const obs$: Observable<[R, T[]]>[] = [];
    for (let i = 0; i < list.length; i += jobSize) {
      const chunk = list.slice(i, i + jobSize);
      obs$.push(jobFactory(chunk).pipe(map((res) => [res, chunk])));
    }

    // make sure to call only 25 concurrent requests to avoid overload server
    // 25 is average safe number, the larger number the higher chances of overload server
    if (obs$.length > MAXIMUM_CONCURRENT_REQUEST_LENGTH) {
      return of(...obs$).pipe(
        mergeMap((obj) => obj, MAXIMUM_CONCURRENT_REQUEST_LENGTH),
        finalize(() => console.log('Sequence complete'))
      );
    } else {
      return merge(...obs$);
    }
  }

  /**
   * Return a unique list
   */
  private _makeUnique<T>(
    source: T[],
    checkUniqueFn: (ele: T, ele2: T) => boolean
  ): T[] {
    return source.reduce(
      (acc, value) =>
        acc.some((ele) => checkUniqueFn(ele, value)) ? acc : [...acc, value],
      []
    );
  }

  private _getUpstreamChannel(channelType: ChannelGroupType) {
    const queryChannel = this._channel.getChannelGroup(channelType);
    const notify = (value: any, event: any) => {
      const channelId = this._channel.getChannelId(channelType, value);
      queryChannel.notify(channelId, event);
    };

    return {
      notify,
    };
  }

  private _transformRowComment(item, rowBookmarkInfoObj): BookmarkInfo {
    return {
      eventIndex: item.eventIndex,
      bookmark: rowBookmarkInfoObj['bookmark'].bookmark,
      comments: rowBookmarkInfoObj['comment'] || [],
      tripId: item.tripId,
      driverId: item.driverId,
      displayTimeZone: item.displayTimeZone,
      status: item.status,
      violationIdentifier: this.getRowDataIdentifier(item),
      disputeTimeMoment: item.disputeTimeMoment,
      timestampUTC: item.timestampUTC,
      eventType: item.eventType,
    };
  }

  private _buildDataFilterOption(
    filterName: string,
    idField: string,
    filterKey: string,
    ignoreName: string[] = []
  ) {
    return {
      [filterName]: {
        filterKey: idField,
        availableOptions: this._makeUnique(
          (this.incidentsList || [])
            .map((item, i) => ({
              id: item[idField] || i,
              name: item[filterKey],
            }))
            // Because some error can be interpreted as name, we need to
            // remove them out
            .filter((item) => ignoreName.every((val) => val !== item.name)),
          (v1, v2) => v1.id === v2.id
        ),
        enable: true,
      },
    };
  }

  private _syncLocalStorageHomeLocation(isDestroy: boolean) {
    const locationFullList = this._storage.getStorageValue('HOME_LOCATION_FULL_LIST');
    if (!isDestroy) { // load page asset-view
      if (this.localStorageHomeLocation?.length === 0) {
        // TO-DO: optimize Old All locations logic
        if (!locationFullList) {
          this._homeLocationService.getHomeLocations().subscribe(
            () => {
              this.isReload = true;
              this.reloadPage();
            }
          );
        } else {
          this._storage.setStorageValue('HOME_LOCATION_ABV', locationFullList.map(item => item.locationId));
        }
      } else {
        this._storage.setStorageValue('HOME_LOCATION_ABV', this.localStorageHomeLocation);
      }
    } else if (isDestroy) { // leave page asset-view
      const currentLocationListSelected = this.localStorageHomeLocationABV?.filter(item => item !== 'null');
      const isCheckAllLocation = locationFullList?.length === currentLocationListSelected?.length;
      if (isCheckAllLocation || this.isReload) {
        this._storage.setStorageValue('HOME_LOCATION', []); // all locations
      } else {
        this._storage.setStorageValue('HOME_LOCATION', this.localStorageHomeLocationABV); // no location selected, select multiple
      }
    }
  }
}
