import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { StorageService } from './services/storage.service';
import { LocalService } from './services/local.service';
import { DateService } from './services/date.service';
import { ToasterService } from './services/toaster.service';
import { LoadingService } from './services/loading.service';
import { ExcelService } from './services/excel.service';
import { HttpCacheService } from './services/cache.service';
import { CacheInterceptorService } from './services/cacheInterceptor.service';
import { PerformanceTrackingInterceptorService } from './services/performance-tracking-interceptor.service';
import { INTERCEPTOR_REGEX_PATTERNS, PENDO_OBJECT, WINDOW_OBJECT } from './services/token.provider';
import { SuccessRequestTrackingInterceptorService } from './services/success-request-tracking-interceptor.service';
import { TitleTrackingService } from './services/title-tracking.service';
import { NavigationTracking } from './services/navigation-tracking.service';
import { AccessService } from './zcw-auth-module/services/access.service';
import { ZcwAuthCommonModule } from './zcw-auth-module/zcw-auth-common.module';
import { ZcwAuthModule } from './zcw-auth-module/zcw-auth.module';
import { SnackbarService } from './services/snackbar.service';
import { ConfigurationService } from './services/configuration.service';
import { TrackNetworkStatusService } from './services/track-network-status.service';
import { ManageHttpInterceptor } from './services/manage-http-interceptor.service';
import { HttpCancelRequestService } from './services/http-cancel-request.service';
import { FlagService } from './services/flag.service';
import { DevCycleService } from '@modules/feature-flag/devcycle/devcycle-client.service';
import { HomeLocationService } from './services/home-location.service';

@NgModule({
  imports: [
    ZcwAuthCommonModule.forRoot(),
    ZcwAuthModule.forRoot(),
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PerformanceTrackingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SuccessRequestTrackingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true,
    },
    StorageService,
    DateService,
    ToasterService,
    SnackbarService,
    LoadingService,
    HttpCacheService,
    ExcelService,
    AccessService,
    LocalService,
    ConfigurationService,
    TrackNetworkStatusService,
    FlagService,
    DevCycleService,
    HomeLocationService,
    {
      provide: PENDO_OBJECT,
      useValue: window['pendo'],
    },
    TitleTrackingService,
    NavigationTracking,
    {
      provide: INTERCEPTOR_REGEX_PATTERNS,
      useValue: {
        PERFORMANCE_REQUEST: [
          // new RegExp('edvr-requests')
        ],
        SUCCESS_REQUEST: [
          // new RegExp('home-locations'),
          // new RegExp('callback')
        ],
        NAVIGATION: [
          // new RegExp('dashboard'),
          // new RegExp('fleet-trends'),
          // new RegExp('driver-detail'),
          // new RegExp('trip-detail'),
        ],
      },
    },
    {
      provide: WINDOW_OBJECT,
      useValue: window,
    },
    HttpCancelRequestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
