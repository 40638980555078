<div class="zc-body zc-app-wrap has-search-filter">
  <app-search-filter
    page="driver"
    (backClicked)="gotoFleet()"
    (driverChange)="driverChange($event)"
    (filterChange)="setSearchFilterData($event)"
  >
  </app-search-filter>

  <div class="zc-content-wrap">
    <div class="driver-page-wrap">
      <div [ngSwitch]="show">
        <div *ngSwitchCase="'data'">
          <div class="breadcrum" data-test="breadcrum-driver-detail-page">
            <img
              src="assets/images/home.png"
              class="home_icons"
              (click)="gotoFleet()"
            /><img src="assets/images/arrow.png" class="arrowlink" />
            <span *ngIf="driverName !== ''; else loadingDriverName">{{driverName}}</span>
          </div>

          <div class="tripeboard-heading mt20">
            <div class="col-md-12 p-0">
              <span class="heading-left" data-test="driver-snapshot-header"
                >Driver Snapshot</span
              >
            </div>
          </div>

          <app-division-graph
          [driverStat$]="driverOverview$"
          [reload$]="reload$"
          [numberofdays]="numberofdays"
          [isCustomDateRange]="isCustomDateRange"
          ></app-division-graph>

          <div class="driver-overview">
            <!--Driver Overview-->
            <div class="row scoreboard">
              <app-driver-total-incident class="col-12 col-sm-12 col-lg-7 driver-total-incident-card"
              [tripDetails$]="tripDetails$"
              [incidentStat$]="incidentList$"
              [ranges]="ranges"
              [reload$]="reload$"></app-driver-total-incident>
              <app-key-metric-overview class="col-12 col-sm-12 col-lg-5 scoreboard-card-wrap"
                [metricData$]="keyMetric$"
                [metricHeaders]="metricHeaders"
                [reload$]="reload$"
              ></app-key-metric-overview>
            </div>
          </div>
          <!--driver trip caps start-->
          <div class="triprecaps-wrap">
            <app-driver-trip-driven
            [totalTrips]="totalTrips"
            [driverIds]="driverids"
            [filterChangeObs$]="filterChange$"
            [driverName]="driverName"
            (isMainApiFailed)="catchTripListError($event)">
          </app-driver-trip-driven>
          </div>
          <!-- driver score graph -->
          <div>
            <app-driver-score-graph
              [trendGraphChange$]="performanceTrend$"
              [reload$]="reload$"
              [driverName$]="driverName$"
            ></app-driver-score-graph>
          </div>
        </div>
        <div *ngSwitchCase="'error'">
          <app-card>
            <div card-error class="error-content">
              <app-error-message
                [errorMessageChange$]="errorMessage$"
                [showRetryButton]="showRetryButton"
                (tryAgainAction)="getDriverSummaryDetails()"
              ></app-error-message>
            </div>
          </app-card>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #ongoingTrip>
  <div class="tripedriver_name ongoing-trip pt0">Vehicle in transit</div>
  <div class="tampered">
    <br />
  </div>
</ng-template>

<ng-template #loadingDriverName>
  <app-custom-skeleton-loader [theme]="{ width: '260px', height: '14px' }"></app-custom-skeleton-loader>
</ng-template>
