import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { TARGET_VIDEO_REQUEST_CONFIGURATION } from '@app-core/constants/constants';

@Component({
  selector: 'app-enhance-video-popup',
  templateUrl: './enhance-video-popup.component.html',
  styleUrls: ['./enhance-video-popup.component.scss'],
})
export class EnhanceVideoPopupComponent implements OnInit {
  @Input() public incidentDetails = null;
  @Output() public closePopup = new EventEmitter<void>();
  constructor(
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _toast: ToasterService ) { }

  public ngOnInit() {
  }


  public hideModal() {
    this.closePopup.emit();
  }

  public enhanceVideo(incidentDetails) {
    this._loading.show();
    const eDVRParams = {
      tripId: incidentDetails.tripId,
      driverId: incidentDetails.driverId,
      eventIndex: incidentDetails.eventIndex,
      ...TARGET_VIDEO_REQUEST_CONFIGURATION,
    };

    this.hideModal();
    this._zcfleet.eDvrRequest(eDVRParams).subscribe(
      (res) => {
        console.log(res);
        this._loading.hide();
        this._toast.success('Enhance DVR request created successfully');
      },
      (err) => {
        console.log(err);
        this._loading.hide();
        this._toast.error('Error while creating enhance DVR request');
      });
  }
}
