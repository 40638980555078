<div class="dropdown-wrap">
    <div class="driver-search">
        <img class="search-icon" src="assets/images/search.png">
        <!-- <input type="text"
            class="form-control"
            placeholder="Driver Name"
            ngui-auto-complete
            [open-on-focus]="false"
            autocomplete="off"
            [source]="list"
            [list-formatter]="displayProp"
            [match-formatted]="true"
            [ignore-accents]="false"
            (ngModelChange)="selectionChange($event)" /> -->
        <div class="search-complete-wrapper" appDocumentClick (clickedInside)="clickedInside($event)">
            <input type="text"
                class="form-control"
                placeholder="Go to Driver"
                data-test="driver-name-input-wrapper"
                [ngModel]="selectedName"
                (focusin)="onFocus()"
                (keydown.arrowdown)="onKeyDown()"
                (keydown.arrowup)="onKeyUp()"
                (keydown.enter)="onEnter()"
                (ngModelChange)="searchChange($event)" />

            <div class="zc-search-complete">
                <div class="search-complete-list" *ngIf="listEnabled">
                    <ul *ngIf="!isLoading">
                        <li class="item"
                            [class.selected]="focusedIndex === i"
                            *ngFor="let item of displayList;let i = index"
                            (click)="onSelect(item)" [innerHTML]="getText(item) | highlight: key">
                            {{getText(item)}}
                        </li>
                        <li class="no-match-found" *ngIf="!displayList.length">No Results Found</li>
                    </ul>
                    <ul *ngIf="isLoading">
                        <app-custom-skeleton-loader
                        *ngFor="let item of mock"
                        [theme]="{
                            margin: '14px 9px',
                            height: '20px',
                            width: '100%',
                            'max-width': '250px',
                            borderRadius: '8px'
                        }"></app-custom-skeleton-loader>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</div>
