import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ExceptionInfoService } from '@modules/exception-info/services/exception-info.service';
import { DateService } from '@app-core/services/date.service';
import { ExceptionsParams, Exception } from '@modules/exception-info/common/exception-info.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-exception-list',
  templateUrl: './exception-list.component.html',
  styleUrls: ['./exception-list.component.scss'],
})
export class ExceptionListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('f', { static: true }) public form: NgForm;

  public show = '';

  public from: Date = null;
  public to: Date = null;
  public todaysDate: Date = new Date();

  public bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-blue',
    dateInputFormat: 'MM/DD/YYYY',
  };

  public exceptionInfo: Exception[] = [];
  public totalExceptions = 0;
  public pageSize = 10;
  public resetPaginator = false;

  private _changesSubscriptions;

  constructor(
    private _exceptionService: ExceptionInfoService,
    private _dateService: DateService,
    private _title: Title
  ) {}

  public ngOnInit() {
    const { from, to } = this._dateService.getDateRangeInISO(30);
    this.from = new Date(from);
    this.to = new Date(to);
    this.getExceptionInfo();
    this._title.setTitle('Exception Info - Zonar Coach');
  }

  public ngAfterViewInit() {
    this._initSubscription();
  }

  public onSearch() {
    this.resetPaginator = true;
    this.totalExceptions = 0;
    this.getExceptionInfo();
  }

  public getExceptionInfo({ limit = this.pageSize, offset = 0} = {}) {
    this.show = 'loading';
    const from = this._dateService.toDaysStartISO(this.from);
    const to = this._dateService.toDaysStartISO(this.to);
    const param = new ExceptionsParams({
      startDate: from,
      endDate: to,
      limit,
      offset,
    });
    this._exceptionService.getExceptionInfo(param).subscribe(
      res => {
        this.show = 'content';
        this.exceptionInfo = res.rows || [];
        this.totalExceptions = res.count || 0;
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * Clear subscriptions on destroy
   */
  public ngOnDestroy() {
    if (this._changesSubscriptions) {
      this._changesSubscriptions.unsubscribe();
    }
  }

  /**
   * @description: Subscribr for changes events
   */
  private _initSubscription() {
    setTimeout(() => {
      this._changesSubscriptions = this.form.controls.from.valueChanges.subscribe(() => {
        this.to = null;
      });
    }, 100);
  }
}
