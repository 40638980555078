<div
    class="modal fade"
    bsModal
    #modal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
    (onShow)="show()"
>
    <div class="backDrop" (click)="hideModal()"></div>
    <div class="modal-dialog modal-lg" data-test="table-modal">
        <div class="modal-content">
            <div class="modal-header">
                <span class="pull-right ml-auto" style="cursor: pointer;" (click)="hideModal()">
                    <img src="assets/images/x.svg" />
                </span>
            </div>
            <div class="modal-body">
                <div *ngIf="displayComponent === 'trips'">
                    <app-all-trips-table 
                      [allAssets]="allAssets" 
                      [totalTrips]="totalTrips" 
                      [filterOptions]="filterOptions"
                      [isShown]="isShown">
                    </app-all-trips-table>
                </div>

                <div *ngIf="displayComponent === 'driverTrips'">
                    <app-driver-trips-table [allAssets]="allAssets" [driverId]="driverId" [driverName]="driverName"
                        [totalTrips]="totalTrips" [filterOptions]="filterOptions" [isShown]="isShown">
                    </app-driver-trips-table>
                    </div>

                <div *ngIf="displayComponent === 'drivers'">
                    <app-fleet-all-driver-table [filterOptions]= "filterOptions"></app-fleet-all-driver-table>
                </div>
                <!-- empty div to maintain height and width of table till data loads -->
            </div>
        </div>
    </div>
</div>
