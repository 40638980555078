<!-- Driver table -->
<div class="zc-table-heading">
    <div class="table-name">
        <span>Performance Trends</span>
    </div>
</div>
<div *ngIf="(notLoadingState$ | async), else driverScoreSkeleton" class="driver-score-card-margin">
    <ng-container *ngIf="(internalTrendGraphData$ | async) as trendGraphData">
        <div class="disputed-table-card-wrap driver-score-over-time" *ngIf="trendGraphData.driverTrend && trendGraphData.driverTrend.length, else noDataTemplate">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                <table
                    class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
                >
                <highcharts-chart
                    class="driverScoreChart"
                    [Highcharts]="highcharts"
                    [options]="(driverGraphOptions$ | async)"
                    [(update)]="trendGraphData.updateChart"
                    [oneToOne]="true">
                </highcharts-chart>
                </table>
            </div>
        </div>
    </ng-container>
</div>

<div *ngIf="(notLoadingState$ | async), else incidentSkeleton" class="incident-card-margin">
    <ng-container *ngIf="(internalTrendGraphData$ | async) as trendGraphData">
        <div class="disputed-table-card-wrap driver-incident-over-time" *ngIf="trendGraphData.driverTrend && trendGraphData.driverTrend.length">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                <table
                    class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
                >
                <highcharts-chart
                    [Highcharts]="highcharts"
                    [options]="(trendGraphOptions$ | async)"
                    [(update)]="trendGraphData.updateChart"
                    [oneToOne]="true">
                </highcharts-chart>
                </table>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #noDataTemplate>
    <div class="performance-trends-no-data-block">
        <div class="performance-trends-no-data-content">
            <span>
                No data available
            </span>
            <p>
                <br>There are no driver trips for this time period.
                <br>Please modify your search and try again.
            </p>
        </div>
    </div>
</ng-template>

<ng-template #driverScoreSkeleton>
    <div class="driver-score-card-margin">
        <div class="disputed-table-card-wrap driver-score-over-time wrap-skeleton">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                <highcharts-chart
                    class="driverScoreChartSkeleton"
                    [Highcharts]="highcharts"
                    [options]="chartOptionsSkeleton"
                    [(update)]="updateScoreChart"
                    [oneToOne]="true">
                </highcharts-chart>
            </div>
            <div class="driver-score-chart-skeleton">
                <app-custom-skeleton-loader
                    [theme]="{
                        'width': '100%',
                        'height': '164px'
                    }">
                </app-custom-skeleton-loader>
            </div>
            <div class="title-driver-score-skeleton">
                <app-custom-skeleton-loader
                    [theme]="{
                        'width': '100%',
                        'height': '20px'
                    }">
                </app-custom-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #incidentSkeleton>
    <div class="incident-card-margin">
        <div class="disputed-table-card-wrap driver-incident-over-time wrap-skeleton">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                <highcharts-chart
                    [Highcharts]="highcharts"
                    [options]="incidentsChartOptionsSkeleton"
                    [(update)]="updateIncidentChart"
                    [oneToOne]="true"
                >
                </highcharts-chart>
            </div>
            <div class="number-incident-skeleton">
                <app-custom-skeleton-loader
                    [theme]="{
                        'width': '20px',
                        'height': '370px'
                    }">
                </app-custom-skeleton-loader>
            </div>
            <div class="incident-chart-skeleton">
                <app-custom-skeleton-loader
                    [theme]="{
                        'width': '100%',
                        'height': '370px'
                    }">
                </app-custom-skeleton-loader>
            </div>
            <div class="title-incident-skeleton">
                <app-custom-skeleton-loader
                    [theme]="{
                        'width': '100%',
                        'height': '20px'
                    }">
                </app-custom-skeleton-loader>
            </div>
        </div>
    </div>
</ng-template>