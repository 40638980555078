import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {

  @Input() public set appFeatureFlag(val) {
    if (val) {
      this._requireFlag = val;
      this._updateView();
    }
  }

  private _requireFlag = '';
  private _isHidden = true;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _featureFlags: FeatureFlagService
  ) { }

  public ngOnInit(): void {
    this._updateView();
  }

  public checkValidity() {
    return this._checkValidity();
  }

  private _updateView() {
    if (this._checkValidity()) {
      if (this._isHidden) {
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._isHidden = false;
      }
    }
  }

  private _checkValidity() {
    return (
      this._requireFlag &&
      this._featureFlags.isFlagSet(this._requireFlag)
    );
  }

}
