<div class="duration-field">
  <mat-form-field class="duration-dropdown" appearance="outline">
    <mat-label>Duration*</mat-label>
    <mat-select [disableOptionCentering]="true" [disabled]="disableDropDown" [(ngModel)]="durationValue" (selectionChange)="notifySelectionChanged($event)">
      <mat-option *ngFor="let option of selectOptions" [value]="option.value" [disabled]="option.disabled">
        {{option.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
