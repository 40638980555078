import { Component, Inject, OnInit } from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

export interface SnackBarMsg {
  title?: string;
  textMsg: string;
  action?: string;
  linkMsg?: string;
  linkAction?: any;
  customLinkData?: any;
  // For custom stack
  panelClasses?: string | string[];
  isShowContact?: boolean;
}

@Component({
  selector: 'app-snackbar-custom-message',
  templateUrl: './snackbar-custom-message.component.html',
  styleUrls: ['./snackbar-custom-message.component.scss'],
})
export class SnackbarCustomMessageComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMsg, private _snackRef: MatSnackBarRef<SnackbarCustomMessageComponent>) { }

  public ngOnInit(): void {
  }

  public closeSnackbar() {
    this._snackRef.dismissWithAction();
  }

  public execLinkAction() {
    if (this.data.linkAction && typeof this.data.linkAction === 'function') {
      if (this.data.customLinkData) {
        this.data.linkAction(this.data.customLinkData);
      } else {
        this.data.linkAction();
      }
    }
  }

}
