<div class="map-tooltip">
    <div class="event-info">
        <span id="eventType">
            {{getEventType(event?.eventType)}}
        </span>
        <!--<span id="eventTime">{{event.timestamp | date:'hh:mm a'}}</span>-->
        <span id="eventTime">{{event?.timestamp | dateFormat | date:'hh:mm a'}}</span>
    </div>

    <div class="thumbnail">
        <span class="helper"></span>
        <img src="assets/images/sample-thumbnail.jpg" alt="No image or video" srcset="">
    </div>
</div>
