import {
  Directive,
  HostListener,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appDocumentClick]',
})
export class DocumentClickDirective implements OnInit {
  @Input()
  public width: number;
  @Output()
  public clickedInside = new EventEmitter<boolean>();

  constructor(private _elementRef: ElementRef) {}

  /**
   * @description: check whether the document click happened or not
   * @param: the target element the user click
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement) {
    if (!targetElement) {
      return;
    }

    if (window.innerWidth > this.width) {
      const clickedInside =
        this._elementRef.nativeElement.contains(targetElement);
      this.clickedInside.emit(clickedInside);
    }
  }

  public ngOnInit() {
    if (!this.width || this.width <= 0) {
      this.width = 0;
    }
  }

}
