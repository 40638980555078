<div class="speed-tabs" *ngIf="event?.eventType === 'Traffic-Speed-Violated'">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <a
                class="nav-link"
                [ngClass]="{ active: isShowingActualSpeed }"
                data-toggle="tab"
                href="#actualSpeed"
                role="tab"
                (click)="showActualSpeed()"
            >
                Actual Speed
                <span class="speedingValue">
                    {{ actualSpeed | distance: "miles" | number: "1.0-0" }} mph
                </span>
            </a>
        </li>
        <li class="nav-item">
            <a
                class="nav-link"
                [ngClass]="{ active: !isShowingActualSpeed }"
                data-toggle="tab"
                href="#postedSpeed"
                role="tab"
                (click)="showPostedSpeed()"
            >
                Posted Speed
                <span class="speedingValue">
                    {{ postedSpeed | distance: "miles" | number: "1.0-0" }} mph
                </span>
            </a>
        </li>
    </ul>
</div>

<ng-container *ngIf="!isShowingActualSpeed">
    <div class="image-unavailable-container" *ngIf="!mediaSrc">
        <div class="no-image-available text-center">
            <div class="notification-container text-normal">
                <span class="notification-icon custom-warning-icon">!</span>
                <div class="notification-text">Image is not available</div>
                <div class="notification-sub-text">
                    Make sure that camera and wi-fi are both connected.
                </div>
            </div>
        </div>
    </div>

    <img class="incident-image" [src]="mediaSrc" *ngIf="mediaSrc" />
</ng-container>

<!-- speedsign event -->
<ng-container *ngIf="event?.eventType === 'Traffic-Speed-Violated' && isShowingActualSpeed">
    <ng-container *ngIf="media === 'video'">
        <div
            class="video-unavailable-container"
            *ngIf="mediaStatus === 'CANCELLED' || mediaStatus === 'NOT_MARKED_FOR_UPLOAD'"
        >
            <div class="no-video-available text-center">
                <div class="notification-container text-normal">
                    <span class="notification-icon custom-warning-icon">!</span>
                    <div class="notification-text">Video is not available</div>
                    <div class="notification-sub-text">
                        Make sure that camera and wi-fi are both connected.
                    </div>
                </div>
            </div>
        </div>

        <div
            class="video-unavailable-container"
            *ngIf="mediaStatus === 'UPLOADING' || mediaStatus === 'MARKED_FOR_UPLOAD'"
        >
            <div class="no-video-available text-center">
                <div class="notification-container text-normal">
                    <span class="notification-icon custom-warning-icon">!</span>
                    <div class="notification-text">Video upload pending</div>
                    <div class="notification-sub-text">
                        If video isn’t available in a minute or two, make sure that the camera and
                        wi-fi are both connected.
                    </div>
                </div>
            </div>
        </div>

        <video class="incident-video" controls *ngIf="mediaStatus === 'UPLOADED'">
            <source [src]="mediaSrc" (error)="this.media = 'image'" type="video/mp4" />
            Browser not supported
        </video>
    </ng-container>
</ng-container>

<!-- all other events -->
<ng-container *ngIf="event?.eventType !== 'Traffic-Speed-Violated'">
    <ng-container *ngIf="media === 'video'">
        <div
            class="video-unavailable-container"
            *ngIf="mediaStatus === 'CANCELLED' || mediaStatus === 'NOT_MARKED_FOR_UPLOAD'"
        >
            <div class="no-video-available text-center">
                <div class="notification-container text-normal">
                    <span class="notification-icon custom-warning-icon">!</span>
                    <div class="notification-text">Video is not available</div>
                    <div class="notification-sub-text">
                        Make sure that camera and wi-fi are both connected.
                    </div>
                </div>
            </div>
        </div>

        <div
            class="video-unavailable-container"
            *ngIf="mediaStatus === 'UPLOADING' || mediaStatus === 'MARKED_FOR_UPLOAD'"
        >
            <div class="no-video-available text-center">
                <div class="notification-container text-normal">
                    <span class="notification-icon custom-warning-icon">!</span>
                    <div class="notification-text">Video upload pending</div>
                    <div class="notification-sub-text">
                        If video isn’t available in a minute or two, make sure that the camera and
                        wi-fi are both connected.
                    </div>
                </div>
            </div>
        </div>

        <video class="incident-video" controls *ngIf="mediaStatus === 'UPLOADED'">
            <source [src]="mediaSrc" (error)="this.media = 'image'" type="video/mp4" />
            Browser not supported
        </video>
    </ng-container>
</ng-container>
