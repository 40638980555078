/* eslint-disable @typescript-eslint/naming-convention */
import { API } from '@app-core/constants/api.constants';
import { API as DASHBOARD3, distractionFlag } from '@modules/dashboard3/dashboard3.models';
import { USERS_DEFAULT_HOME_LOCATION_CONFIG } from '@modules/dashboard3/dashboard3.constants';
import { Locations } from '@modules/dashboard3/components/location-filter/location-filter.component';

export const TRAFFIC_SPEED_SIGN = 'Traffic-Speed-Sign_0';

export const TRAFFIC_SPEED_VIOLATED = 'Traffic-Speed-Violated';

export const EVENTS_MAPPING = {
  'Traffic-Speed-Violated': 'Speed Limit Violated',
  Cornering: 'Cornering Found',
  'Traffic-STOP-Sign-Violated': 'Stop Sign Violated',
  'Harsh-Braking': 'Harsh Braking Found',
  'Tail-Gating-Detected': 'Tail Gating Incident Found',
  'Harsh-Acceleration': 'Harsh Acceleration Found',
  'Lane-Drift-Found': 'Lane Drift Incident Found',
  'Traffic-Speed-Sign_0': 'Posted Speed Limit',
  'Video Capture': 'Video Capture',
  Calibration: 'Dummy Video',
};

export const LOCATION_QUERY_FAIL = 'Location query failed';

/**
 * @description: list of API's to cache
 *    url    : API endpoint
 *    expiry : Expiry duration of the cache (in mins), if expiry is not specified,
 *             15 min is taken as expiry
 */
export const APIS_TO_CACHE = [
  { url: API.GET_TRIP_LIST },
  { url: API.GET_DRIVER_LIST },
  { url: API.GET_FLEET_LEVEL_SNAPSHOT },
  {
    url: API.GET_UNIQUE_DRIVER_LIST,
    expiry: 30,
  },
  {
    url: API.GET_DRIVER_SNAPSHOT,
  },
  {
    url: API.GET_DRIVER_TRIPLIST,
  },
  {
    url: API.GET_DRIVING_INCIDENTS_PER_TRIP,
  },
  {
    url: API.GET_VIOLATION_DISTRIBUTION,
  },
  {
    url: API.GET_UNIQUE_DRIVERS_FOR_USER,
    expiry: 30,
  },
  { url: API.GET_VERSION_CRASH_COUNT },
  { url: API.GET_EXCEPTIONS },
  { url: API.GET_ANOMALOUS_TRIPS },
  { url: DASHBOARD3.DRIVERS, expiry: 360 },
  { url: DASHBOARD3.TRIPS },
  { url: DASHBOARD3.HOME_LOCATION },
  { url: DASHBOARD3.HOME_LOCATION_ENTITY },
  { url: DASHBOARD3.FLEET_RIDECAM_STATUS },
  { url: /http[s]?:\/\/\S+\/assets\/[^\/]+\/?$/ }, // api to get assetName by assetId

  // { url: DASHBOARD3.ASSETS }
  // { url: DASHBOARD3.CHALLENGES }
];

/**
 * @description Package config and exception info and unconnected trips
 * tabs will only be enabled for the following list of domains
 */
export const ACCESS_DOMAINS_WHITELIST = [
  '@lightmetrics.co',
  '@zonarsystems.com',
];
export const V3_DASHBORD_ACCESS_LIST = [
  'zon6769',
  'ore3631',
  'den0597',
  'dee7867',
  'mig7757',
  'zon2423',
];

export const OZA8331_EMAIL_WHITELIST = [
  'broesler@oreillyauto.com',
  'ekrug@oreillyauto.com',
];
export const OZA8331_ACCOUNT_CODE = ['OZA8331', 'oza8331'];

export const EDVR_SWITCH = false;

// ADDED for testing
export const TEST_VARIABLE = {
  FIRST_OF_JAN_2018: '2017-12-31T17:00:00.000Z',
  homeLocation: {
    key: 'HOME_LOCATION',
    value: ['All Locations'],
  },
  userInfo: {
    key: 'USER_INFO',
    value: USERS_DEFAULT_HOME_LOCATION_CONFIG,
  },
  config: 'fleet',
  searchFilterData: {
    key: 'data',
    value: { days: 7, minScore: 0, maxScore: 100, dutyType: '', ids: [] },
  },
  location: [{ lat: 0, lng: 0 }],
  tripDetails: {
    duration: 5,
    startTime: new Date().toISOString(),
    startTimeUTC: new Date().toISOString(),
    driverId: '',
    tripId: '',
  },
  fullList: [
    { timestampUTC: new Date().getTime(), latitude: 0, longitude: 0 },
    { timestampUTC: new Date().getTime() + 3000, latitude: 0, longitude: 0 },
    { timestampUTC: new Date().getTime() + 6000, latitude: 0, longitude: 0 },
  ],
  unconnectedParam: {
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    offset: 0,
    limit: 4,
  },
  homeLocationFullList: [
    {locationId:'7eed4a58-aaf4-4ae1-b232-aaaaaaaaaaaa',locationName:'Test 1'},
    {locationId:'38d96924-7384-4d3a-92ff-bbbbbbbbbbbb',locationName:'Test 2'},
    {locationId:'39d96924-7384-4d3a-92ff-cccccccccccc',locationName:'Test 3'},
  ],
  statsResponse: {
    safety: 0,
    topIncident: 'MaxSpeedExceeded',
    score: 61,
    tripCount: 10,
    driverCount: 1,
    modeScoreFrequency: 1,
    driversPassed: 0,
    driversPassedPercent: 0,
    passedDriversChangePercent: -33.4,
    goal: 85,
    distribution: [
      {
        count: 0,
        driverIds: [],
      },
    ],
    percentile: [
      0,
    ],
  },
};

export interface EventDisplayData {
  name: string;
  value: number;
  color: string;
}

export interface FilterChangeEvent {
  filterName: string;
  isChanged: boolean;
}

export const EVENTS_INCIDENT = (eventCount): EventDisplayData[] => [
  {
    name: 'Acceleration',
    value: eventCount['Harsh-Acceleration'],
    color: '#8ba600',
  },
  {
    name: 'Braking',
    value: eventCount['Harsh-Braking'],
    color: '#e67300',
  },
  {
    name: 'Cornering',
    value: eventCount['Cornering'],
    color: '#6134b1',
  },
  {
    name: 'Lane Drift',
    value: eventCount['Lane-Drift-Found'],
    color: '#e5389d',
  },
  {
    name: 'Tailgating',
    value: eventCount['Tail-Gating-Detected'],
    color: '#814911',
  },
  {
    name: 'Rolling Stop',
    value: eventCount['Traffic-STOP-Sign-Violated'],
    color: '#a82a89',
  },
  {
    name: 'Speeding',
    value: eventCount['Traffic-Speed-Violated'],
    color: '#2a8596',
  },
  {
    name: 'Distraction',
    value: eventCount['Distracted-Driving'],
    color: '#731042',
  },
].filter((item) => {
  if (!distractionFlag) {
    return item.name !== 'Distraction';
  }
  return item;
});

export const TARGET_VIDEO_REQUEST_CONFIGURATION = {
  videoResolution: '1920x1080',
  dvrVideoType: 'pictureInPicture',
  dvrVideoTypeMainFrame: 'road',
};

export const EVENTS_INCIDENT_TYPE = [
  ['Traffic-Speed-Violated', 'Speeding'],
  ['Cornering', 'Cornering'],
  ['Traffic-STOP-Sign-Violated', 'Rolling Stop'],
  ['Harsh-Braking', 'Braking'],
  ['Tail-Gating-Detected', 'Tailgating'],
  ['Harsh-Acceleration', 'Acceleration'],
  ['Lane-Drift-Found', 'Lane Drift'],
  ['Distracted-Driving', 'Distraction'],
];

export const DEFAULT_BUFFER_SIZE = 1;

export const NAVIGATION_ITEMS = {
  dashboardItem: [
    { name: 'Home', routerLink: 'dashboard' },
    { name: 'Incident Response Center', routerLink:'incident-response-center' },
    { name: 'Asset View', routerLink:'asset-view' },
  ],
  configurationItem: [
    { name: 'Home', routerLink: 'dashboard' },
    { name: 'Package Configuration', routerLink: 'package-configuration'},
    { name: 'Exception Info', routerLink: 'exception-info'},
    { name: 'Unconnected Trips', routerLink: 'unconnected-trips'},
  ],
};

export const CHART_OPTION = {
  title: {
    text: '',
  },
  chart: {
    height: 60, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 0, // number represents pixels
    spacingRight: 0, // number represents pixels
    style: { fontFamily: '\'Open Sans\', sans-serif' },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    visible: false,
  },
  yAxis: {
    // visible: false,
    maxPadding: 0, // padding at the end of the bar
    // gridLineDashStyle: 'dash',
    // gridLineColor: '#979797',
    gridLineWidth: 0,
    // tickInterval: 1,
    labels: {
      enabled: false,
    },
    title: { enabled: false },
    plotLines: [
      {
        value: 20,
        color: '#b8bcc0',
        dashStyle: 'shortdash',
        width: 2,
      },
    ],
  },
  plotOptions: {
    line: {
      states: {
        hover: {
          enabled: false, // Disable hover effect on line
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            fillColor: '#7dbb7a',
            lineColor: '#7dbb7a',
          },
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [
    {
      data: [],
      color: '#b8bcc0',
    },
  ],
  responsive: {
    rules: [{
      condition: {
        maxWidth: 500,
      },
      chartOptions: {
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'bottom',
        },
      },
    }],
  },
};

export const UNASSIGNED_DRIVER = '_UNASSIGNED';
export const BLANK_TEXT = '';
export const UNIDENTIFIED_DRIVER_NAME = 'Unidentified Driver';
export const ERROR_MESSAGE = {
  INVALID_DRIVER_ID: 'Error getting driver name',
};
export const DRIVER_NAME_UNIDENTIFIED_LIST = [
  '',
  undefined,
  UNASSIGNED_DRIVER,
];
export const UNIDENTIFIED_LOCATION_ID = '_UNIDENTIFIED';
export const UNIDENTIFIED_LOCATION_NAME = 'Unidentified';
export const UNIDENTIFIED_LOCATION: Locations = {
  locationId: UNIDENTIFIED_LOCATION_ID,
  locationName: UNIDENTIFIED_LOCATION_NAME,
};
export const LOCAL_DRIVER_SCORECARDS_KEY = 'local_driver_scorecards_key';
export const LOCAL_TRIP_RECAPS_KEY = 'local_trip_recaps_key';
export const CLEAR_FILTER_UNASSIGN_CAMERA_KEY = 'clear_filter_unassign_camera_key';
export const LOCATION_FILTER = 'location-filter';
export const DATE_FILTER = 'date-filter';

export const EMPTY_AGGREGATE_DATA = {
  tripDuration: 0,
  tripCount: 0,
  score: 0,
  tripDistance: 0,
  eventCount: {
    total: 0,
  },
};

export const EMPTY_AGGREGATE_DATA_LIST = [
  {
    key: '',
    value: {
      tripDuration: 0,
      tripCount: 0,
      score: 0,
      tripDistance: 0,
      eventCount: {
        total: 0,
      },
    },
  },
];

export interface TableTripInfo {
  _assetNumber: string;
  cameraConnected: boolean;
  startTime: string;
  startTimeUTC: string;
  timezoneOffset: number;
  displayTimeZone: number;
  duration: number;
  tripDistance: number;
  tripId: string;
  total: number;
  topIncident: string;
  eventCount: any;
  location: any;
  firstLocation: {
    latitude: number;
    longitude: number;
  };
}
