import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[appThrottleClick]',
})
export class ThrottleClickDirective implements OnInit, OnDestroy {

  @Input() public throttleTimeInMs = 1000;
  @Output() public throttleClick = new EventEmitter();

  private _click$ = new Subject();
  private _destroy$ = new Subject<boolean>();
  constructor() {}

  @HostListener('click', ['$event'])
  public clickListener(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    this._click$.next(evt);
  }

  public ngOnInit(): void {
    this._click$.pipe(
      throttleTime(this.throttleTimeInMs),
      takeUntil(this._destroy$)
    ).subscribe((evt) => {
      this.throttleClick.emit(evt);
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
