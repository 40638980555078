import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

import { AuthenticateInterface, UserProfile } from './zcw-authenticate.interface';

@Injectable()
export class ZcwAuthenticateService implements AuthenticateInterface {

  public tokenReceived$: Observable<boolean>;
  public userProfile$: Observable<UserProfile>;

  constructor(
    _oauthService: OAuthService
  ) {}

  public initialize(): Promise<boolean> {
    throw (new Error('Do not use this class directly, but use the adapter instance instead'));
  }

  public logout() {
    throw (new Error('Do not use this class directly, but use the adapter instance instead'));
  }
}
