export const INCIDENT_PIECHART_OPTION = {
  chart: {
    height: 200, // Height of the chart in pixels
    type: 'pie', // type of chart 'line', 'bar'...
    width: 200, // number represents pixels
    // eslint-disable-next-line quotes, @typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    x: -7,
    y: 7,
    style: {
      color: '#49555e',
      fontWeight: '600',
      fontSize: '60px',
    },
  },
  subtitle: {
    text: 'Total Incidents',
    align: 'center',
    verticalAlign: 'middle',
    x: -4,
    y: 25,
    style: {
      color: '#5b6770',
      fontWeight: 'normal',
      fontSize: '12px',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    enabled: true,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      center: ['45%', '45%'],
      size: '120%',
      states: {
        hover: {
          enabled: false, // Disable hover effect on line
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            fillColor: '#7dbb7a',
            lineColor: '#7dbb7a',
          },
        },
      },
    },
  },
  series: [
    {
      name: 'Brands',
      innerSize: '80%',
      colors: [],
      data: [],
    },
  ],
};

export const SCORE_PIECHART_OPTION = {
  chart: {
    height: 200, // Height of the chart in pixels
    type: 'pie', // type of chart 'line', 'bar'...
    width: 200, // number represents pixels
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    style: {
      color: '#49555e',
      fontWeight: 'bold',
      fontSize: '64px',
    },
    x: -20,
    y: -5,
  },
  subtitle: {
    text: 'Current Score',
    align: 'center',
    verticalAlign: 'middle',
    x: -20,
    y: 10,
    style: {
      color: '#5b6770',
      fontWeight: 'normal',
      fontSize: '11px',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    enabled: false,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      center: ['35%', '35%'],
      size: '100%',
      states: {
        hover: {
          enabled: false, // Disable hover effect on line
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            fillColor: '#7dbb7a',
            lineColor: '#7dbb7a',
          },
        },
      },
    },
  },
  series: [
    {
      name: 'Brands   ',
      innerSize: '80%',
      colors: [],
      data: [],
    },
  ],
};

export const DRIVER_INCIDENT_PIECHART_OPTION = {
  chart: {
    height: 252, // Height of the chart in pixels
    type: 'pie', // type of chart 'line', 'bar'...
    width: 252, // number represents pixels
    // eslint-disable-next-line quotes, @typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
    useStyle: true,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    x: 0,
    y: 25,
    style: {
      color: '#49555e',
      fontWeight: '700',
      fontSize: '64px',
      'text-align': 'center',
    },
    useHTML: true,
  },
  subtitle: {
    text: '',
    align: 'center',
    verticalAlign: 'middle',
    x: -4,
    y: 25,
    style: {
      color: '#5b6770',
      fontWeight: 'normal',
      fontSize: '16px',
      'text-align': 'center',
    },
  },
  tooltip: {
    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    enabled: true,
    borderWidth: 0,
    backgroundColor: 'none',
    shadow: false,
    useHTML: true,
  },
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: false,
      },
      center: ['50%', '50%'],
      size: '120%',
      states: {
        hover: {
          enabled: false, // Disable hover effect on line
        },
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            fillColor: '#7dbb7a',
            lineColor: '#7dbb7a',
          },
        },
      },
    },
  },
  series: [
    {
      name: 'Brands',
      innerSize: '85%',
      colors: [],
      data: [],
    },
  ],
};
