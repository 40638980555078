import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { TARGET_VIDEO_REQUEST_CONFIGURATION } from '@app-core/constants/constants';

@Component({
  selector: 'app-enhance-video-modal',
  templateUrl: './enhance-video-modal.component.html',
  styleUrls: ['./enhance-video-modal.component.scss'],
})
export class EnhanceVideoModalComponent implements OnInit, OnDestroy {

  @Input() public incidentDetails = null;
  @Input() public getDriverId = null;
  @Input() public getTripId = null;
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  public modalSubscription;
  public displayComponent;
  // eslint-disable-next-line max-len
  public emailValidationRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _toast: ToasterService) {
    this.modalSubscription = this._zcfleet.enhanceVideoModal.subscribe(params => {
      if (params.state) {
        this.displayComponent = params.component;
        this.modal.show();
      }
    });
  }

  public ngOnInit() {
  }

  public hideModal() {
    this.modal.hide();
  }

  public enhanceDVR(incidentDetails, tripId, driverId) {
    const eDVRParams = {
      tripId,
      driverId,
      eventIndex: incidentDetails.eventIndex,
      ...TARGET_VIDEO_REQUEST_CONFIGURATION,
    };
    this._loading.show();
    this.hideModal();
    this._zcfleet.eDvrRequest(eDVRParams).subscribe(
      (res) => {
        console.log(res);
        this._loading.hide();
        this._toast.success('Enhance DVR request created successfully');
      },
      (err) => {
        console.log(err);
        this._loading.hide();
        this._toast.error('Error while creating enhance DVR request');
      });
  }

  public ngOnDestroy() {
    this.modalSubscription.unsubscribe();
  }

}
