import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpCancelRequestService } from '@app-core/services/http-cancel-request.service';


// Service used to intercept each and every HTTP call
@Injectable()
export class HandleAuthErrorInterceptorService implements HttpInterceptor {

  constructor(
    private _httpCancelService: HttpCancelRequestService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const postReq = req.clone({});
    return next.handle(postReq).pipe(
      catchError((err: HttpErrorResponse): Observable<any> => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 0) {
            // show no internet when status is 0
            return throwError(err);
          } else if (err.status === 401) {
            // Go to the error page
            localStorage.setItem('http-error', JSON.stringify({
              status: '401',
              url: err.url,
            }));
            this._httpCancelService.navigateToErrorPage();
            // Delaying log out to give user time to read the above message
            return throwError(err);
          }
        }
        return throwError(err);
      }
      )
    );
  }
}
