<div style="padding: 10px 0 ;">
  <table class="incident_table" data-test="top-incident-card-table-body">
    <tr class="incident-row" data-test="top-incident-card-table-row" *ngFor="let incident of filteredStatList"
      [ngClass]="{ 'zero-incident': incident?.value === 0 }">
      <td class="incident_text incident-item" [ngClass]="incident?.incidentClass">
        {{ incident?.name }}
      </td>
      <td class="numbercount">
        {{ incident?.value }}
      </td>
    </tr>
    <tr>
      <span *ngIf="isDistractedDisclaimerFlag" class="distraction-disclaimer"><img src="assets/images/info_24px.svg">
        {{distractionDisclaimer}}</span>
    </tr>
  </table>
</div>
