import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AccessService } from './access.service';

// using canActivate guard to protect unauthorized access to internal routes
@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private _accessService: AccessService, private _router: Router) { }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._accessService.canAccess.pipe(
      tap(res => {
        if (!res) {
          this._router.navigate(['/dashboard']);
        }
      })
    );
  }
}
