<div *ngIf="!readOnly" class="duty-type-settings">
    <div class="settings-options">
        <span class="lable">Acceleration </span>
        <span (mouseover)="hover[0] = false" (mouseleave)="hover[0] = true" >
            <img src="/assets/images/info_2019-04-30/info.png">
        </span>
        <div [hidden]="hover[0]" class="tool-tip">
            <div style=" margin: 12px">
                <div style="padding-bottom: 20px">
                    <b>ACCELERATING</b> is the increase in speed (mph/second) based on the camera
                    accelerometer and vehicle speed.
                    <br />
                </div>
                <div>
                    <div style="padding-bottom: 6px"><b>SENSITIVITY SETTINGS</b><br /></div>
                    <b class="text-tab">LOW</b> 8 mph/sec over a period of several seconds<br />
                    <b class="text-tab1">MEDIUM</b> 6 mph/sec over a period of several seconds<br />
                    <b class="text-tab">HIGH</b>4.5 mph/sec over a period of several seconds<br />
                </div>
            </div>
        </div>
        <p class="note">
            Increase in speed (mph/second) based on the camera accelerometer and vehicle speed.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.harshAccelerationEnabled"
                [class.active]="options.value === config.harshAccelerationThreshold"
                [class.off]="!options.body.harshAccelerationEnabled"
                *ngFor="let options of hardAccelerationOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Braking </span>
        <span (mouseover)="hover[1] = false" (mouseleave)="hover[1] = true" >
            <img src="/assets/images/info_2019-04-30/info.png">
        </span>
        <div [hidden]="hover[1]" class="tool-tip">
            <div style=" margin: 12px">
                <div style="padding-bottom: 20px">
                    <b>BRAKING</b> is the decrease in speed (mph/second) based on the camera
                    accelerometer and vehicle speed.
                    <br />
                </div>
                <div>
                    <div style="padding-bottom: 6px"><b>SENSITIVITY SETTINGS</b><br /></div>
                    <b class="text-tab">LOW</b> 8 mph/sec over a period of several seconds <br />
                    <b class="text-tab1">MEDIUM</b> 6 mph/sec over a period of several seconds<br />
                    <b class="text-tab">HIGH</b>4.5 mph/sec over a period of several seconds<br />
                </div>
            </div>
        </div>
        <p class="note">
            Decrease in speed (mph/second) based on the camera accelerometer and vehicle speed.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.hardBrakingEnabled"
                [class.active]="options.value === config.hardBrakingThreshold"
                [class.off]="!options.body.hardBrakingEnabled"
                *ngFor="let options of hardBrakingOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Cornering </span>
        <span (mouseover)="hover[2] = false" (mouseleave)="hover[2] = true" >
            <img src="/assets/images/info_2019-04-30/info.png">
        </span>
        <div [hidden]="hover[2]" class="tool-tip">
            <div style=" margin: 12px">
                <div style="padding-bottom: 20px">
                    <b>CORNERING</b> is lateral force exerted on the vehicle (g-force) based on the
                    camera accelerometer.
                    <br />
                </div>
                <div>
                    <div style="padding-bottom: 6px"><b>SENSITIVITY SETTINGS</b><br /></div>
                    <b class="text-tab">LOW </b>0.28 g<br />
                    <b class="text-tab1">MEDIUM </b>0.26 g<br />
                    <b class="text-tab">HIGH</b>0.24 g<br />
                </div>
            </div>
        </div>
        <p class="note">
            Lateral force exerted on the vehicle (g-force) based on the camera accelerometer.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.corneringEnabled"
                [class.active]="options.value === config.corneringThreshold"
                [class.off]="!options.body.corneringEnabled"
                *ngFor="let options of hardCorneringOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Lane Drift</span>
        <p class="note">
            Vehicle has slowly drifted out of it’s lane, based on the camera’s detection of lane
            markers.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.onOff]="options.value === config.laneDriftEnabled"
                *ngFor="let options of laneDriftingOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <div class="settings-options speeding-settings-options">
        <span class="lable">Speeding </span>
        <span (mouseover)="hover[3] = false" (mouseleave)="hover[3] = true" >
            <img src="/assets/images/info_2019-04-30/info.png">
        </span>
        <div [hidden]="hover[3]" class="tool-tip">
            <div style=" margin: 12px">
                <div style="padding-bottom: 20px">
                    <b>SPEEDING</b> is the amount of miles per hour by which the posted may be
                    exceeded.
                    <br />
                </div>
                <div>
                    <div style="padding-bottom: 6px"><b>SENSITIVITY SETTINGS</b><br /></div>
                    <b class="text-tab">LOW </b>10 mph over posted speed limit<br />
                    <b class="text-tab1">MEDIUM </b>5 mph over posted speed limit <br />
                    <b class="text-tab">HIGH</b>0 mph over posted speed limit<br />
                </div>
            </div>
        </div>
        <p class="note">
            Vehicle exceeds to some extent the speed limit sign detected by the camera.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.speedingEnabled"
                [class.active]="options.value === config.speedingAllowance"
                [class.off]="!options.body.speedingEnabled"
                *ngFor="let options of speedingOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Maximum speed</span>
        <p class="note">
            Vehicle has exceeded the allowable absolute speed, regardless of speed limit signs.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.speedCapEnabled"
                [class.active]="item === config.speedUpperLimit"
                *ngFor="let item of maxSpeedOptions"
                (click)="changeSpeedCap(item)"
            >
                {{ item }} mph
            </button>
            <button
                class="btn btn-option off"
                [class.enabled]="config.speedCapEnabled"
                (click)="changeSpeedCap(-1)"
            >
                Off
            </button>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Tailgating</span>
        <p class="note">
            Following too closely to the vehicle in front, as detected by the camera. Measured in
            seconds and based on duty type.
        </p>
        <div class="options-group">
            <button
                class="btn btn-option"
                [class.enabled]="config.tailgatingEnabled"
                [class.active]="options.value === config.tailgatingSensitivity"
                [class.off]="!options.body.tailgatingEnabled"
                *ngFor="let options of tailgatingOptions"
                (click)="changeSettings(options.body)"
            >
                {{ options.label }}
            </button>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="stopSignSettings"></ng-template>

    <div *ngIf="distractionSetting" class="settings-options">
      <span class="lable">Driver Distraction</span>
      <p class="note">
        Averting eyes from the road, as detected by a driver-facing camera.  Measured in seconds
        and based on vehicle speed.
      </p>
      <div class="options-group">
          <button
              class="btn btn-option"
              [class.onOff]="options.value === config.distractedDrivingEnabled"
              *ngFor="let options of driverDistractionOptions"
              (click)="changeSettings(options.body)">
              {{ options.label }}
          </button>
      </div>
  </div>
</div>

<div *ngIf="readOnly" class="duty-type-settings">
    <div class="settings-options">
        <span class="lable">Acceleration </span>
        <p class="note">
            Increase in speed (mph/second) based on the camera accelerometer and vehicle speed.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of hardAccelerationOptions">
                    <p *ngIf="options.value === config.harshAccelerationThreshold"> <b> {{ options.label }} </b></p>
            </span>
        </div>
    </div>

    <div class="settings-options">
        <span class="lable">Braking </span>
        <p class="note">
            Decrease in speed (mph/second) based on the camera accelerometer and vehicle speed.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of hardBrakingOptions">
                    <p *ngIf="options.value === config.hardBrakingThreshold"> <b> {{ options.label }} </b></p>
            </span>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Cornering </span>
        <p class="note">
            Lateral force exerted on the vehicle (g-force) based on the camera accelerometer.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of hardCorneringOptions">
                    <p *ngIf="options.value === config.corneringThreshold"> <b> {{ options.label }} </b></p>
                </span>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Lane Drift</span>
        <p class="note">
            Vehicle has slowly drifted out of it’s lane, based on the camera’s detection of lane
            markers.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of laneDriftingOptions">
                    <p *ngIf="options.value === config.laneDriftEnabled"> <b> {{ options.label }} </b></p>
            </span>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Speeding </span>
        <p class="note">
            Vehicle exceeds to some extent the speed limit sign detected by the camera.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of speedingOptions">
                    <p *ngIf="options.value === config.speedingAllowance"> <b> {{ options.label }} </b></p>
                </span>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Maximum speed</span>
        <p class="note">
            Vehicle has exceeded the allowable absolute speed, regardless of speed limit signs.
        </p>
        <div class="options-group"  >
            <span *ngFor="let options of maxSpeedOptions">
                    <p *ngIf="options === config.speedUpperLimit"> <b> {{ options }} mph</b></p>
                </span>
        </div>
    </div>
    <div class="settings-options">
        <span class="lable">Tailgating</span>
        <p class="note">
            Following too closely to the vehicle in front, as detected by the camera. Measured in
            seconds and based on duty type.
        </p>
        <div class="options-group" >
            <span *ngFor="let options of tailgatingOptions">
                    <p *ngIf="options.value === config.tailgatingSensitivity"> <b> {{ options.label }} </b></p>
                </span>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="stopSignSettings"></ng-template>

    <div *ngIf="distractionSetting" class="settings-options">
      <span class="lable">Driver Distraction</span>
      <p class="note">
        Averting eyes from the road, as detected by a driver-facing camera.  Measured in seconds
        and based on vehicle speed.
      </p>
      <div class="options-group" >
          <span *ngFor="let options of driverDistractionOptions">
                  <p *ngIf="options.value === config.distractedDrivingEnabled"> <b> {{ options.label }} </b></p>
              </span>
      </div>
  </div>
</div>

<ng-template #stopSignSettings>
  <div class="settings-options">
    <span class="lable">Stop sign</span>
    <p class="note">
      Vehicle speed does not decrease below 5 mph after a stop sign has been detected by the camera.<br /><br />
      <strong>Please Note:</strong> This incident type is not configurable and cannot be disabled.
    </p>
  </div>
</ng-template>
