import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
// import { ToasterService } from '@app-core/services/toaster.service';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';



export interface EventTripMetadata {
  driverId: string;
  bookmark: boolean;
}

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
  styleUrls: ['./remove-modal.component.scss'],
})
export class RemoveModalComponent implements OnInit, OnDestroy {
  public bodyTripMetadata: EventTripMetadata;
  public violationIdentifier: string;
  public isValidating: Subject<boolean> = new Subject<boolean>();
  public updateStatusBookmark: Subject<boolean> = new Subject<boolean>();
  private _isRemovingBookmark = false;

  constructor(
    public bsModalRef: BsModalRef,
    private _zcFleet: ZCFleetService
    // private _toast: ToasterService
  ) { }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.closeRemoveModal();
  }

  public closeRemoveModal(): void {
    if (!this._isRemovingBookmark) {
      this.updateStatusBookmark.next(undefined);
    }
  }

  public cancelRemoveBookmark(): void {
    this.bsModalRef.hide();
    this.closeRemoveModal();
  }

  public removeBookmarkFromSaveIncident(): void {
    this._isRemovingBookmark = true;
    this.bsModalRef.hide();
    this.isValidating.next(true);
    this._zcFleet.updateEventTripMetadata(this.violationIdentifier, this.bodyTripMetadata).subscribe(
      () => {
        this.updateStatusBookmark.next(this.bodyTripMetadata.bookmark);

        /** Information for not display toaster
         * https://jira.zonarsystems.net/browse
         * /ZCW-2382?focusedCommentId=475520&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-475520
         */
        // this._toast.success('Remove bookmark successfully');
      },
      _err => {
        this.updateStatusBookmark.next(!this.bodyTripMetadata.bookmark);
        // this._toast.error('Remove bookmark failed');
      }
    ).add(() => this.isValidating.next(false));
  }
}
