import { ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { AccessGuard } from './services/access.guard';
import { AssetvalidationAccessGuard } from './services/asset-validation.access.guard';
import { DashboardAccessGuard } from './services/dashboard.access.guard';
import { OidcAuthenticateAdapterService } from './services/oidc-authenticate-adapter.service';
import { SmartFleetAccessGuard } from './services/smartfleet.access.guard';
import { ZcwAuthenticateService } from './services/zcw-authenticate.service';


export const oauthProviders: Provider[] = [
  AccessGuard,
  DashboardAccessGuard,
  SmartFleetAccessGuard,
  AssetvalidationAccessGuard,
  {
    provide: ZcwAuthenticateService,
    useClass: OidcAuthenticateAdapterService,
  },
];

@NgModule({
  declarations: [],
  imports: [],
})
export class ZcwAuthModule {

  public static forRoot(): ModuleWithProviders<ZcwAuthModule> {
    return {
      ngModule: ZcwAuthModule,
      providers: oauthProviders,
    };
  }
}
