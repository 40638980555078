/* eslint-disable quote-props */
import { Component, OnInit } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { WorkSheet } from 'xlsx/types';
import { delay } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

import { UnconnectedTripsService } from '@modules/unconnected-trips/services/unconnected-trips.service';
import {
  UnconnectedTripsParamIS,
} from '@modules/unconnected-trips/common/unconnected-trip.model';
import { DistancePipe } from '@modules/shared/pipes/distance.pipe';
import { DurationPipe } from '@modules/shared/pipes/duration.pipe';
import { StorageService } from '@app-core/services/storage.service';
import { ExcelService } from '@app-core/services/excel.service';
import { DateService } from '@app-core/services/date.service';
import { TimeZonePipe } from '@modules/shared/pipes/time-zone.pipe';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { UNIDENTIFIED_DRIVER_NAME } from '@app-core/constants/constants';

const PAGE_SIZE = 500;
const MAX_TRIP_LIMITATION = 20000;
const MAX_PAGE_REQUEST_FOR_EACH_TIME = 5;  // TO-DO: Reduce this value doesn't help fix server fetch issue
const NOT_APPLICABLE = 'N/A';
const MIN_PER_HOUR = 60;
const OTHER_TIMEZONES = 'others';

interface AnomalousTrip {
  asset?: {
    metadata?: {
      assetNumber?: string;
    };
  };
  firstLocation?: {
    locationInfo?: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LongLabel?: string;
    };
  };
  lastLocation?: {
    locationInfo?: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LongLabel?: string;
    };
  };
  cameraConnected?: boolean;
  cameraSerialId?: string;
  customEventCount?: Record<string, number>;
  driverId?: string;
  driverName?: string;
  duration?: number;
  startTime?: string;
  endTime?: string;
  fleetId?: string;
  tripDistance?: number;
}

@Component({
  selector: 'app-unconnected-trips',
  templateUrl: './unconnected-trips.component.html',
  styleUrls: ['./unconnected-trips.component.scss'],
  providers: [
    DatePipe,
    DistancePipe,
    DecimalPipe,
    DurationPipe,
    TimeZonePipe,
    ArraySortPipe,
  ],
})
export class UnconnectedTripsComponent implements OnInit {
  public show = 'loading';
  public sortBy = '';
  public sortByExportExcel = 'startTimeUTC';
  public ascending = true;
  public fromDate = undefined;
  public toDate = undefined;
  public currentDate = undefined;
  public dateRange = 7;
  public localTimezoneOffsetByHour = 0;
  public timeZone: string;
  public timeZoneExportRange: string;
  public isDaylightSaving: boolean;

  public anomalousTripList: AnomalousTrip[] = [];
  public anomalousTripListExport: AnomalousTrip[] = [];
  public anomalousTripListExportBackUp: AnomalousTrip[] = [];
  public totalUnconnectedTrips = 0;
  public anomalousTripListExportLength = 0;
  public isLoadingExportData = false;
  public pageSize = 10;
  public requestListForExportData: number;
  public loadPercentageExport = 0;

  public unconnectedTripApiList$: any;

  constructor(
    private _unconnected: UnconnectedTripsService,
    private _storage: StorageService,
    private _excelService: ExcelService,
    private _dateService: DateService,
    private _title: Title,
    private _datePipe: DatePipe,
    private _distancePipe: DistancePipe,
    private _decimalPipe: DecimalPipe,
    private _durationPipe: DurationPipe,
    private _timeZonePipe: TimeZonePipe,
    private _sort: ArraySortPipe
  ) {}

  public ngOnInit() {
    this.getUnconnectedTrips();
    this._title.setTitle('Unconnected Trips - Zonar Coach');
  }

  /**
   * @description: converting the date format using the custom date service
   * @param date: date time need to convert format
   */
  public convertDate(date: Date, ignoredTimeOfDay = false) {
    if (ignoredTimeOfDay) {
      this.timeZoneExportRange = this.timeZone === OTHER_TIMEZONES ?
        this._getOtherRegionsTimeZone(date) : this.timeZone;
      return this._datePipe.transform(date, 'MM/dd/yy');
    }
    return this.timeZone === OTHER_TIMEZONES ?
      this._getOtherRegionsDate(date) :
      this._datePipe.transform(date, 'MM/dd/yy HH:mm ') + this.timeZone;
  }

  /**
   * @description: function to sort based on given key
   * @param: key
   */
  public sortOn(key) {
    if (key === this.sortBy) {
      this.ascending = !this.ascending;
    } else {
      this.sortBy = key;
      this.ascending = true;
    }
  }

  /**
   * @description: function to get unconnected trips by offset
   * @param: offset
   */
  public getUnconnectedTrips(offset = 0) {
    const param = this.getUnconnectedTripRequestParams(offset);
    this._unconnectedTrips(param);
  }

  /**
   * @description: function to get unconnected trips request's params
   * @param: offset
   */
  public getUnconnectedTripRequestParams(offset: number): UnconnectedTripsParamIS {
    this._getUnconnectedTripsRequestDateRange();
    const param = new UnconnectedTripsParamIS(
      this.fromDate, this.toDate, PAGE_SIZE, offset
    );
    return param;
  }

  /**
   *  @description: function to execute export unconnected trips data to excel file
   */
  public exportClicked() {
    this.isLoadingExportData = true;
    this.isDaylightSaving = this._dateService.isDayLightSavingTime();
    this.currentDate = (new Date()).toISOString();
    this.localTimezoneOffsetByHour = ((new Date()).getTimezoneOffset()) / MIN_PER_HOUR;
    this.timeZone = this._timeZonePipe.transform(this.localTimezoneOffsetByHour, this.isDaylightSaving);
    const totalPage = Math.ceil(this.anomalousTripListExportLength / PAGE_SIZE);
    this.requestListForExportData = Math.floor(totalPage / MAX_PAGE_REQUEST_FOR_EACH_TIME);

    if (totalPage > 1 && (this.anomalousTripListExport.length < this.anomalousTripListExportLength)) {
      const paramsList = [];
      this.loadPercentageExport = 0;
      Object.assign(this.anomalousTripListExport, this.anomalousTripListExportBackUp);
      for (let i = 1; i < totalPage; i++) {
        const offset = PAGE_SIZE * i;
        paramsList.push(this.getUnconnectedTripRequestParams(offset));
      }
      this.unconnectedTripApiList$ = paramsList.map(param =>
        this._unconnected.getAnomalousTrips(param)
      );

      const times = 0;
      this._callUnconnectedTripApiList(times);
    } else {
      this._exportExcelFile();
    }
  }

  private _callUnconnectedTripApiList(times: number) {
    const sliceStartPos = times * MAX_PAGE_REQUEST_FOR_EACH_TIME;
    const sliceEndPos = sliceStartPos + MAX_PAGE_REQUEST_FOR_EACH_TIME;
    const arrayList = this.unconnectedTripApiList$.slice( sliceStartPos, sliceEndPos);
    if (!arrayList.length) {
      this._exportExcelFile();
    }
    forkJoin(arrayList).pipe(
      delay(3000) // TO-DO: increase this value does not help fix server fetch requests issue
    ).subscribe(
      (res) => {
        if (res.length) {
          res.forEach(pageData => {
            this.anomalousTripListExport = this.anomalousTripListExport.concat(pageData['data'].rows);
          });
        }
        if (times < this.requestListForExportData) {
          this.loadPercentageExport = Math.ceil(
            (this._getIndex(times) / this._getIndex(this.requestListForExportData)) * 100);
          this._callUnconnectedTripApiList(++times);
        } else {
          this._exportExcelFile();
        }
      },
      (err) => {
        console.error(`Received Error: ${err.message}`);
        this.isLoadingExportData = false;
      }
    );
  }

  private _exportExcelFile() {
    this.loadPercentageExport = 100;
    this.anomalousTripListExportLength  = this.anomalousTripListExport.length;
    this.anomalousTripListExport = this._sort.transform(
      this.anomalousTripListExport, this.sortByExportExcel, true);
    this._exportUnconnectedTripData(this.anomalousTripListExport);
    this.isLoadingExportData = false;
  }

  private _getUnconnectedTripsRequestDateRange() {
    // TO-DO: ZCW-3816 Should use UTC-7 to request, currently using UTC+0
    if (!this.fromDate && !this.toDate) {
      const day = 60 * 60 * 24 * 1000;
      const startDate = new Date();
      this.fromDate = new Date(startDate.getTime() - 7 * day).toISOString();

      // seconds * minutes * hours * milliseconds = 1 day
      const endDate = new Date(startDate.getTime() + day);
      this.toDate = endDate.toISOString();
    }
  }

  private _unconnectedTrips(param: UnconnectedTripsParamIS) {
    this.show = 'loading';
    this._unconnected.getAnomalousTrips(param).subscribe(
      (res) => {
        Object.assign(this.anomalousTripListExportBackUp, res.data.rows);
        Object.assign(this.anomalousTripListExport, res.data.rows);
        this._filterTripResponse(res.data.rows);
        this.show = 'content';
        this.anomalousTripListExportLength = res.data.count > MAX_TRIP_LIMITATION ?
          MAX_TRIP_LIMITATION : res.data.count;
      },
      (err) => {
        console.log(err);
        this.show = err;
      }
    );
  }

  private _exportUnconnectedTripData(data: any[]) {
    const filename = `ZCoach_UnconnectedTrips_${this._storage.getStorageValue(
      'state'
    )}`;

    const headerReport = [
      { A: 'Report Title', B: 'Unconnected Trips' },
      { A: 'Account Code', B: this._storage.getStorageValue('state') },
      {
        A: 'User email',
        B: this._storage.getStorageValue('userProfile').email,
      },
      { A: 'Daylight Saving Time', B: String(this.isDaylightSaving).toLowerCase()},
      { A: 'Date of report', B: this.convertDate(this.currentDate) },
      {
        A: 'Date range of reported data',
        B: 'Last 7 days' + ' ('
         + this.convertDate(this.fromDate, true)
         + '-' + this.convertDate(this.currentDate, true)
         + ') ' + this.timeZoneExportRange,
      },
      {
        A: 'Total date range count',
        B: this.dateRange,
      },
      {
        A: 'Total trips count',
        B: this.anomalousTripListExportLength,
      },
    ];

    const exportTripList = data.map((item, index) => ({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'No.': this._getIndex(index),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Start Time': this.convertDate(item.startTimeUTC) || NOT_APPLICABLE, // TO-DO: table show start time, not startTimeUTC
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'End Time': this.convertDate(item.endTimeUTC) || NOT_APPLICABLE, // TO-DO: table show end time, not endTimeUTC
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Asset Number': item.asset && item.asset.metadata && item.asset.metadata.assetNumber || NOT_APPLICABLE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Driver Name': item.driverName || UNIDENTIFIED_DRIVER_NAME,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Driver ID': item.driverId || NOT_APPLICABLE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Start Location':
        item.firstLocation &&
        item.firstLocation.locationInfo &&
        item.firstLocation.locationInfo.LongLabel || NOT_APPLICABLE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'End Location':
        item.lastLocation &&
        item.lastLocation.locationInfo &&
        item.lastLocation.locationInfo.LongLabel || NOT_APPLICABLE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Trip Distance(miles)': this._decimalPipe.transform(
        this._distancePipe.transform(item.tripDistance, 'miles'),
        '2.0-2'
      ) || NOT_APPLICABLE,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Trip Duration': this._durationPipe.transform(item.duration) || NOT_APPLICABLE,
    }));

    const headerUtils = this._excelService.getSheetToJson(headerReport, {
      header: ['A', 'B'],
      skipHeader: true,
    });
    const sheet = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Unconnected Trips Info': this._createSheet(headerUtils, exportTripList),
    } ;
    this._excelService.convertToExcel(sheet, filename, true);
  }

  private _getIndex(index: number): number {
    return index + 1;
  }

  /**
   * @description: function to filter trips response
   * @param: trip details
   */
  private _filterTripResponse(trips: any[]) {
    if (trips.length) {
      this.anomalousTripList = trips.map((ele) =>
        ({
          tripID: ele.tripId,
          accountCode: ele.fleetId,
          assetID: ele.asset.assetId,
          driverID: ele.driverId,
          driverName: ele.driverName,
          startTime1: ele.startTime,
          tripDistance: ele.tripDistance,
          tripDuration: ele.duration,
        })
      );
    }
  }

  private _createSheet(headerUtils: WorkSheet, arr: any[]) {
    return this._excelService.sheetAddJson(headerUtils, arr, {
      origin: 'B10',
    });
  }

  private _getOtherRegionsDate(date: Date) {
    return this.localTimezoneOffsetByHour !== 0 ?
      this._datePipe.transform(date, 'MM/dd/yy HH:mm UTCZZZZZ') :
      this._datePipe.transform(date, 'MM/dd/yy HH:mm UTC+00:00');
  }

  private _getOtherRegionsTimeZone(date: Date) {
    return this.localTimezoneOffsetByHour !== 0 ?
      this._datePipe.transform(date, 'UTCZZZZZ') :
      this._datePipe.transform(date, 'UTC+00:00');
  }
}
