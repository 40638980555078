<app-settings-modal>
  <ng-container header>Add or Edit Cameras (Import From File)</ng-container>
  <ng-container body>
    <div class="body-container">
      <div class="file-upload-container" [ngClass]="{'success-upload': successResponse}">
        <div dropzone (fileDrop)="onFilesDrop($event)" class="well my-drop-zone">
          <div style="min-height: 124px;">
            <img src="assets/images/cloud_upload_24px.svg" />
          </div>
          <div class="div-upload-message" *ngIf="uploader.items.length <= 0 && errorFiles.length === 0">
            <label class="upload-message">
              <span>To upload new cameras or edit existing ones,</span>
              <br />
              <span>drag files here or </span>
              <input
                id="file-upload-input"
                name="cameraFile"
                type="file"
                #fileInput
                (change)="onFilesChange($event.target.files)"
              />
              <a class="upload-link">Browse</a>
            </label>
          </div>

          <div
            class="upload-process"
            *ngIf="
              uploader.notUploadedItemsCount > 0 ||
              uploader.isUploading ||
              successResponse ||
              errorResponse ||
              errorFiles.length !== 0">
            <progressbar
              class="setting-file-upload-progress-bar"
              [value]="uploader.totalProgress"
            ></progressbar>
            <div class="upload-detail">
              <span class="upload-size">
                {{calculateUploadedSize(uploader?.items[0]) | filesize}}
              </span>
              <span>/</span>
              <span class="file-size" *ngIf="!fileErrorDetected">
                {{uploader?.items[0]?.file?.size | filesize}}
              </span>
              <span class="file-size" *ngIf="fileErrorDetected">
                {{errorFiles[0]?.size | filesize}}
              </span>
            </div>
          </div>

          <div class="file-description" *ngFor="let item of uploader.items">
            <img src="assets/images/insert_drive_file_24px.svg" />
            <div
              class="file-name"
              data-toggle="tooltip"
              title="{{ item?.fileName }}">
              {{ truncateFileName(item?.fileName, 30) }}
            </div>
            <app-inline-spinner *ngIf="isUploading" class="inline-spinner"
            ></app-inline-spinner>
            <span *ngIf="!isUploading" 
              class="file-clear"
              (click)="item.remove(); clearMessage()"
            ></span>
          </div>
          <!-- display file that cannot add to uploader because errors -->
          <div class="file-description" *ngFor="let item of errorFiles">
            <img src="assets/images/insert_drive_file_24px.svg" />
            <div
              class="file-name"
              data-toggle="tooltip"
              title="{{ item?.name }}"
            >
              {{ truncateFileName(item?.name, 30) }}
            </div>
            <span
              class="file-clear"
              (click)="clearMessage()"
            ></span>
          </div>

          <div class="message-line" *ngIf="isUploadAvailable()"></div>

          <div class="messages">
            <div class="upload-msg success-msg" *ngIf="successResponse">
              <!-- success icon -->
              <div class="message-success-icon"></div>
              <!-- success message -->
              <div class="message-wrap">
                <div>
                  <b>Success</b>
                </div>
              </div>
            </div>
            <div class="upload-msg" *ngIf="errorResponse || fileErrorDetected">
              <!-- error icon -->
              <div class="message-error-icon"></div>
              <!-- error message -->
              <div class="message-wrap">
                <div *ngIf="!isSomeImeiValid">
                  <b>File could not be uploaded.</b>
                </div>
                <div *ngIf="isSomeImeiValid">
                  <b>File imported but failed to upload some IMEIs</b>
                </div>
                <!-- Check error message and display correct message template -->
                <!-- <div>{{ errorResponse.message }}</div> -->
                <div [ngSwitch]="uploadFileErrorState">
                  <div *ngSwitchCase="'invalidIMEI'" style="padding: 0 22px">
                    <div>
                      <a (click)="downloadTheResult()"
                        ><u>Download the result</u></a
                      >
                      to see which IMEIs encountered an error.
                    </div>
                  </div>
                  <div *ngSwitchCase="'fileTypeError'">
                    <div style="padding-bottom: 5px">File type not supported.</div>
                  </div>
                  <div *ngSwitchCase="'noCompanyFoundError'">
                    <div style="padding-bottom: 5px">No company found with the provided parameters.</div>
                  </div>
                  <div *ngSwitchCase="'fileSizeExceeded'">
                    <div style="padding-bottom: 5px">file size limit exceeded.</div>
                  </div>
                  <div *ngSwitchCase="'noNetworkError'">
                    <div style="padding-bottom: 5px">Network is unavailable, please try again.</div>
                  </div>
                  <div *ngSwitchCase="'exceedLimit'">
                    <div style="padding-bottom: 5px">File exceeds the limit of 200 cameras per upload.</div>
                  </div>
                  <div *ngSwitchCase="'unableReadFileError'">
                    <div>
                      Unable to read the file.<br />
                      Please
                      <a (click)="downloadTemplateFile()"
                        ><u>Download the template</u></a
                      >
                      and re-enter data, taking care not to add or delete any
                      columns.
                    </div>
                  </div>
                  <div *ngSwitchDefault></div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="result" *ngIf="successResponse">
            <a [href]="getDataUri(successResponse.res)" download="result.json"
              >Download upload result</a
            >
          </div> -->
        </div>
      </div>
      <div style="padding-top: 10px" class="bottom-line-break">
        <span class="accept-mime-msg"
          >Accepted File Formats: .xls, .xlsx, .csv
        </span>
      </div>
      <div class="warning-limit">
        <span>
          <b>WARNING:</b> Uploads are limited to a maximum of 200 cameras per file.
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <div
      class="footer-btn download-template-btn"
      *ngIf="isUploadCompleted()"
      (click)="downloadTemplateFile()"
      style="width: 208px; height: 32px; gap: 7.75px"
    >
      <img style="max-width: 10.5px; max-height: 12.75px;" src="/assets/images/download-white-arrow.svg" />
      <span>Download template</span>
    </div>

    <div
      class="footer-btn"
      *ngIf="isUploadCompleted()"
      (click)="bsModalRef.hide()"
      style="width: 85px; height: 32px"
    >
      Cancel
    </div>

    <div
      class="footer-btn"
      style="width: 70px; height: 32px"
      (click)="uploadFile()"
      [ngClass]="{
        'disabled-save-btn': isSaveBtnDisabled,
        'save-button': isSaveBtnEnabled
      }">
      Save
    </div>
  </ng-container>
</app-settings-modal>
