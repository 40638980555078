<div [ngSwitch]="show">
    <div *ngSwitchCase="'loading'"></div>
    <div *ngSwitchCase="'data'">
        <div class="row tripboard-heading">
            <div class="col-md-12 p-0">
                    <span class="heading-left">Trips Driven By {{ driverName }}  </span>
                </div>
        </div>

        <div class=" no-gutters zc-table-wrap drivers-trip-table">
            <table class="table zc-table table-striped table-hower table-text-wrap header-sort table-sm">
                <thead>
                    <tr>
                        <th scope="col">
                            <app-table-sort-header sortOn="startTime" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="getData()">Date/Time
                            </app-table-sort-header>
                        </th>
                        <th class="w-15" scope="col">
                            <!-- Uncomment below line once location sort is supported -->
                            <!-- <app-table-sort-header sortOn="location" [(sortBy)]="sortBy" [(sort)]="sort"
                            (sortClicked)="getData()">Start Location
                            </app-table-sort-header> -->
                            Start Location
                        </th>
                        <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="total" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="getData()">Total Incidents
                                </app-table-sort-header>
                        </th>
                        <th class="w-10" scope="col">
                            <app-table-sort-header sortOn="tripDistance" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="getData()">Distance (MI)
                            </app-table-sort-header>
                        </th>
                        <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="duration" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="getData()">Duration (HH:MM)
                                </app-table-sort-header>
                        </th>
                        <th scope="col">
                            <!-- ZCW-515 : disable sort by asset in trip table. -->
                            <!-- <app-table-sort-header sortOn="assetNumber" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="getData()">Asset
                            </app-table-sort-header> -->
                            Asset
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let trip of driverTripListPage;let i = index" (click)="gotoTripDetail(trip)">
                        <td>
                            {{trip?.startTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z'}}
                        </td>
                        <td>
                            {{trip?.location}}
                        </td>
                        <td *ngIf="trip.cameraConnected && !trip.tampered; else tamperedBlockRow">{{trip?.totalIncidentsEventType}}</td>
                        <ng-template #tamperedBlockRow>
                            <td>
                                <img class="dangerSign" src="../../../../../../../assets/images/exclamation-red-circle.svg">
                            </td>
                        </ng-template>
                        <td>{{trip?.tripDistance | distance: 'miles' | number: '1.0-0'}}
                        </td>
                        <td>{{trip?.duration | duration2: 'seconds':'HH:MM' }}
                            </td>
                        <td>{{trip?.assetNumber || 'N/A'}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="paginator-wrap" style="float: right;">
                <app-paginator3
                    [(reset)]="reset"
                    [pageLimit]="limit"
                    [length]="totalTrips"
                    [data]="listData"
                    (pageChangeData)="driverTripListPageChange($event)"
                    (pageChange)="getMoreData($event)">
                </app-paginator3>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'empty'">
        <div class="no-records-card">
            <div class="no-records">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                    <span>
                        <span class="text-bold">{{driverName}}</span>
                        hasn't driven any trips in the
                        <span class="text-lowercase">{{dateRangeText}}</span>
                    </span>
                </div>
                <div class="note sub">
                    <span>change the date filter to view the driver's trips on this page.</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngSwitchCase="'error'">
        <div class="no-records-card">
            <div class="no-records">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="retry" *ngIf="showRetryButton">
                    <button class="btn btn-retry" (click)="getData()">Retry</button>
                </div>
            </div>
        </div>
    </div>
</div>
