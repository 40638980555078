<div class="mat-elevation-z8">
  <div class="table-container" [ngClass]="{ 'notify-empty-data': isEmptyData && (!notLoadingState$ || (notLoadingState$ | async))}">
    <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else tableSkeleton">
      <table mat-table [dataSource]="(dataSource$ | async)?.data" matSort multiTemplateDataRows>
  
        <ng-container *ngFor="let colDescription of colDescriptions"
          [matColumnDef]="colDescription.colKey" >
          <th mat-header-cell *matHeaderCellDef
            mat-sort-header disableClear="true"
            [ngClass]="{'disabled-header': isEmptyData}"
            [disabled]="!sortHeader.includes(colDescription.colKey)"
            (click)="onSortChange(colDescription.colKey, colDescription.sortKey)">
            {{ colDescription.colDisplayName }}
          </th>
          <td mat-cell *matCellDef="let element; let i = dataIndex" (click)="getRecordRowSelect(element, i)" [ngClass]="{'hidden-button': checkEmpty(element)}">
            <ng-container *ngIf="!checkEmpty(element)">
              <!-- async case -->
              <ng-container *ngIf="colDescription.async && colDescription.type !== 'custom'">
                <ng-container *ngIf="(colDescription.getter(element) | async) as data; else loading">
                  <ng-container *ngTemplateOutlet="renderCol; context: { $implicit: colDescription, data: data }">
                  </ng-container>
                </ng-container>
                <ng-template #loading>
                  <div style="padding-right: 10px;">
                    <app-custom-skeleton-loader></app-custom-skeleton-loader>
                  </div>
                </ng-template>
              </ng-container>
              <!-- none async case -->
              <ng-container *ngIf="!colDescription.async && colDescription.type !== 'custom'">
                <ng-container
                  *ngTemplateOutlet="renderCol; context: { $implicit: colDescription, data: colDescription.getter(element) }">
                </ng-container>
              </ng-container>
              <!-- custom type case -->
              <ng-container *ngIf="colDescription.type === 'custom'">
                <ng-container
                  *ngTemplateOutlet="customTemplates[colDescription.colKey] || noCustom; context: { $implicit: colDescription.getter(element), index: index, element: element , key: colDescription.colKey, isAsync: colDescription.async ? true : false }">
                </ng-container>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
  
        <!-- Ripple column -->
        <ng-container matColumnDef="ripple-toggler" *ngIf="isUseRipple">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = dataIndex"  [ngClass]="{'hidden-button': checkEmpty(element)}">
            <ng-container *ngTemplateOutlet="rippleToggler; context: { element: element, index: i, expanded: isExpandedRow(i)}"></ng-container>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let index = dataIndex"
          [ngClass]="{selected: selection.isSelected(row), 'disabled-click': isDisabledClick, 'expanded': isExpandedRow(index)}" (click)="onClickExpand(row, index)"></tr>
  
          <!-- Expandable area -->
        <ng-container matColumnDef="expandedDetail" *ngIf="isUseRipple">
          <td mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="displayedColumns.length">
            <div [@detailExpand]="isExpandedRow(i) ? 'expanded' : 'collapsed'" *ngIf="isExpandedRow(i)" class="element-detail">
              <ng-container *ngTemplateOutlet="rippleTemplate; context: {element: element, index: i}"></ng-container>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="isUseRipple">
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="detail-row"></tr>
        </ng-container>
      </table>
    </ng-container>
  </div>

  <mat-paginator
    class="paginator-container"
    [length]="(dataSource$ | async)?.totalItems"
    [pageSize]="(dataSource$ | async)?.perPage || this.initialPageSize"
    [pageIndex]="(dataSource$ | async)?.pageIndex"
    [hidePageSize]="isHidePageSize"
    [pageSizeOptions]="customPageSizeOptions"
    [disabled]="disablePaginator"
    [showFirstLastButtons]="isShowFirstLastButtons"
    (page)="onPageChange($event)"
    *ngIf="!notLoadingState$ || (notLoadingState$ | async)">
  </mat-paginator>

</div>

<ng-template #noCustom let-key="key">
  No custom template provided for {{ key }}
</ng-template>

<ng-template #text let-text>
  {{ text }}
</ng-template>

<ng-template #boldText let-text>
  <b> {{ text }} </b>
</ng-template>

<ng-template #dateTime let-date>
  {{ date | momentFormat: 'MM/DD/YYYY HH:mm z'}}
</ng-template>

<ng-template #rippleToggler let-element="element" let-index="index" let-expanded="expanded">
  <div (click)="onClickExpand(element, index, $event)">
    <ng-container *ngTemplateOutlet="rippleTogglerTemplate || defaultRipplebutton; context: { expanded: expanded}"></ng-container>
  </div>
</ng-template>

<!-- Default ripple button -->
<ng-template #defaultRipplebutton let-expanded="expanded">
  <a class="dropdown-arrow desktop-version" [class.hide]="!expanded">
    <img src="assets/images/assets_view_arrow/expanded_arrow_up.svg" alt="arrow-up">
  </a>
  <a class="dropdown-arrow desktop-version" [class.hide]="expanded">
    <img src="assets/images/assets_view_arrow/expanded_arrow_down.svg" alt="arrow-down">
  </a>
</ng-template>

<ng-template #renderCol let-colDescr let-data="data">
  <ng-container [ngSwitch]="colDescr.type">
    <ng-container *ngSwitchCase="'text'">
      <ng-container *ngTemplateOutlet="text; context: { $implicit: data }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'bold-text'">
      <ng-container *ngTemplateOutlet="boldText; context: { $implicit: data }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'date-time'">
      <ng-container *ngTemplateOutlet="dateTime; context: { $implicit: data }"></ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="text; context: { $implicit: data }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #tableSkeleton>
  <table mat-table [dataSource]="loadingDummyData" matSort>
    <ng-container *ngFor="let colDescription of colDescriptions"
      [matColumnDef]="colDescription.colKey">
      <th mat-header-cell *matHeaderCellDef
        mat-sort-header disableClear="true"
        class="header-skeleton"
        [ngClass]="{'disabled-header': isEmptyData}"
        [disabled]="!sortHeader.includes(colDescription.colKey)"
        (click)="onSortChange(colDescription.colKey, colDescription.sortKey)">
        {{ colDescription.colDisplayName }}
      </th>
      <td mat-cell *matCellDef="let element; let i = dataIndex">
        <div style="padding-right: 10px;">
          <app-custom-skeleton-loader></app-custom-skeleton-loader>
        </div>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let index = dataIndex"
      [ngClass]="{selected: selection.isSelected(row), 'disabled-click': isDisabledClick, 'expanded': isExpandedRow(index)}"></tr>
  </table>
  <!-- skeleton paging navigator -->
  <div class="skeleton-paging-navigator" style="float: right; padding-top: 10px;">
    <app-custom-skeleton-loader [theme]="{
      'width': '289px',
      'height': '20px'
    }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>
