import { Component, Input } from '@angular/core';

import { EVENT_NAMES_MAP } from '@modules/dashboard3/dashboard3.models';
import { MapService } from '@modules/dashboard3/services/map.service';

@Component({
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss'],
})
export class MapPopupComponent {
  @Input() public event: any;

  constructor(private _mapService: MapService) {}

  // Helper
  public getEventType(name) {
    return EVENT_NAMES_MAP[name];
  }

  // Tigger the modal show by passing the event to map component through service
  public showMedia() {
    this._mapService.showMedia(this.event);
  }
}
