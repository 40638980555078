import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PermissionManagerService } from '@app-core/services/permission-manager.service';

@Injectable()
export class SmartFleetAccessGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _premission: PermissionManagerService
  ) {}

  /**
   * @description: using canactivate guard to check whenever user acces to smart fleet's panels
   */
  public canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this._premission.requestPermissionCheck('fleet-ridecam-plus');
    return this._premission.getPermissionObs('fleet-ridecam-plus').pipe(
      map(res => res || this._router.createUrlTree(['/dashboard']))
    );
  }
}
