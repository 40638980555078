import { Component, OnDestroy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { merge, of, Subscription, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged,map, shareReplay } from 'rxjs/operators';

import { DateService } from '@app-core/services/date.service';
import { StorageService } from '@app-core/services/storage.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { DEFAULT_BUFFER_SIZE, ERROR_MESSAGE } from '@app-core/constants/constants';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { Data } from '@modules/dashboard3/services/data.service';
import { createMomentObjectUtc, getTimezoneString } from '@modules/shared/utils';
import { PaginationData } from '@modules/shared/components/table/table.component';
import { SortInfo } from '@modules/shared/components/card/card.component';
import { IncidentData } from '@modules/dashboard3/components/incident-media-control/incident-media-control.component';
import { API, ChallengesModel } from '@modules/dashboard3/dashboard3.models';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

const CHALLENGE_ACCEPTED = {
  isAccepted: true,
};

const CHALLENGE_REJECTED = {
  isAccepted: false,
};

const SUCCESS_RULING_SAVED = 'Disputed ruling saved';

@Component({
  selector: 'app-fleet-disputed-incidents-table',
  templateUrl: './fleet-disputed-incidents-table.component.html',
  styleUrls: ['./fleet-disputed-incidents-table.component.scss'],
})
export class FleetDisputedIncidentsTableComponent implements OnInit ,OnDestroy {
    @Input() public checkDriverTrips: any;
    @Input() public isDashboard = true;
    @Output() public isShowError = new EventEmitter<boolean>();
    @Output() public loaded = new EventEmitter<string>();

    public incidentsPageList: any[] = [];
    public incidentsList: any[] = [];
    public reset = false;
    public update = false;
    public sortBy = 'disputeTimeMoment';
    public sort: 'asc' | 'desc' = 'desc';
    public syncSortCard = {
      displayName: 'Most recent',
      sortColumn: 'disputeTimeMoment',
      sortOrder: 'desc',
    };

    public filterOptions: any;
    public homeLocationList = [];
    public isDisabledChallenge = false;
    public isRetry = false;
    public screenWidth = window.screen.width;

    public currentPageInfo = {
      pageIndex: 0,
      pageSize: 0,
    };

    public incidentData: IncidentData = {};
    public deleteData = {};
    public switch = false;
    public selectedIncident = null;
    public tableCurrentPageDetail = [];
    public noMediaText = 'Please select a disputed incident to display media';

    public show = 'data';
    public showRetryButton = true;
    public storeCurrentIncidentData = {};

    public tripDetailMap = {};
    public paramOption = {};

    public sortList: SortInfo[] = [
      {
        displayName: 'Most recent',
        sortColumn: 'disputeTimeMoment',
        sortOrder: 'desc',
      },
      {
        displayName: 'Least recent',
        sortColumn: 'disputeTimeMoment',
        sortOrder: 'asc',
      },
      {
        displayName: 'Category (a' + '\u2192' + 'z)',
        sortColumn: '_eventType',
        sortOrder: 'asc',
      },
      {
        displayName: 'Category (z' + '\u2192' + 'a)',
        sortColumn: '_eventType',
        sortOrder: 'desc',
      },
      {
        displayName: 'Disputed by (a' + '\u2192' + 'z)',
        sortColumn: 'driverName',
        sortOrder: 'asc',
      },
      {
        displayName: 'Disputed by (z' + '\u2192' + 'a)',
        sortColumn: 'driverName',
        sortOrder: 'desc',
      },
    ];

    public sortHeader = ['disputeTimeMoment', '_eventType', 'driverName'];

    public displayedColumns = ['disputeTimeMoment', '_eventType', 'driverName', 'ruling'];
    public colDescriptions = [
      {
        colKey: 'disputeTimeMoment',
        colDisplayName: 'Date + Time',
        sortable: true,
        type: 'date-time',
        getter: (item) => item.disputeTimeMoment,
      },
      {
        colKey: '_eventType',
        colDisplayName: 'Category',
        sortable: true,
        type: 'bold-text',
        getter: (item) => item._eventType,
      },
      {
        colKey: 'driverName',
        colDisplayName: 'Disputed By',
        sortable: true,
        type: 'text',
        getter: (item) => item.driverName,
      },
      {
        colKey: 'ruling',
        colDisplayName: 'Ruling',
        sortable: false,
        type: 'custom',
        getter: () => ({}),
      },
    ];

    public skeletonStyle = {
      'border-radius': '8px',
      width: '120px',
      height: '20px',
    };

    public skeletonStyleBtn = {
      'border-radius': '8px',
      width: '70px',
      height: '20px',
    };

    public videoSkeletonStyle = {
      'border-radius': '8px',
      width: '640px',
      height: '360px',
    };

    public videoSkeletonStyleSmall = {
      'border-radius': '8px',
      width: '472px',
      height: '360px',
    };

    public paginationSkeleton = {
      'border-radius': '8px',
      width: '243px',
      height: '36px',
    };

    public dummyData = [];

    public reload$: Observable<boolean>;
    public notLoadingState$;

    public get incidentListTable$(): Observable<PaginationData<any>> {
      return this._incidentListTable$;
    };
    private _incidentListTable$ = new ReplaySubject<PaginationData<any>>(
      DEFAULT_BUFFER_SIZE
    );

    private _dateFilter$ = new ReplaySubject<any>();
    public get dateFilter$(): Observable<any> {
      return this._dateFilter$;
    }

    public get notifySortChange$(): Observable<[string, 'desc' | 'asc']> {
      return this._notifyPageChange$;
    }
    private _notifyPageChange$ = new ReplaySubject<[string, 'desc' | 'asc']>();

    public get showOption() {
      return this.incidentsList.length > 0;
    }

    private _filterChangeSubscription: any;
    private _tripDetailMapFetchingSubscription: Subscription;

    constructor(
        private _dateService: DateService,
        private _zcfleet: ZCFleetService,
        private _sort: ArraySortPipe,
        private _data: Data,
        private _storage: StorageService,
        private _snackbarService: SnackbarService
    ) {
      this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(filterOptions => {
        this.filterOptions = filterOptions;
        // this.resetModels();
        this._dateFilter$.next(this.filterOptions);
      });
      const distinctDateFilter$ = this.dateFilter$.pipe(distinctUntilChanged());
      this.reload$ = distinctDateFilter$.pipe(map(() => true));
      this.dummyData = this._createDummyData();
      this.notLoadingState$ = merge(this.reload$, this.incidentListTable$.pipe(shareReplay())).pipe(map(mergeValue => mergeValue !== true));
    }
    public ngOnInit(): void {
      this.getData();
    }

    public ngOnDestroy() {
      if (this._filterChangeSubscription) {
        this._filterChangeSubscription.unsubscribe();
      }
    }

    /**
     * @description: function to get challenged incident data list
     * @param:isRetry
     */
    public getData(isRetry?: boolean) {
      if (isRetry) {
        this.isRetry = true;
      }
      const { days } = this.filterOptions;
      let date;
      if (days === 2) {
        const from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
        const to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
        date = { from, to };
        if (this._dateService.customStartdate === undefined || this._dateService.customEndDate === undefined) {
          date = this._data.customRange.data;
          this._dateService.customStartdate = this._data.customRange.data.from;
          this._dateService.customEndDate = this._data.customRange.data.to;
        }
      } else {
        date = this._dateService.getDateRangeInISO(days);
      }
      this.homeLocationList = JSON.parse(localStorage.getItem('HOME_LOCATION_ABV'));
      const params = new ChallengesModel(
        date.from,
        date.to,
        100,
        0,
        this.homeLocationList);
      const OPTIONS = {
        params: { ...params, raised: true, resolved: false, sort: 'desc' },
      };
      this.paramOption = new ChallengesModel( date.from, date.to, 100, 0);
      this._zcfleet.getFleetIncidents(OPTIONS).subscribe(
        (res: any[]) => {
          res.forEach((incident) => {
            if (!incident.driverName) {
              incident.driverName = ERROR_MESSAGE.INVALID_DRIVER_ID;
            }
            incident.displayTimeZone =
                    ((new Date(incident.timestampUTC).getTime() - new Date(incident.timestamp).getTime()) / 60000) / 60;
          });
          this.incidentsList = res;
          // init mat table disputed incident page data
          this.pageChange({list: this.incidentsList}, true);
          const pageIndex = 0;
          const pageSize = 6;
          this.loadPageData(pageIndex, pageSize, true);
          this.reset = true;
          // Set the image/video from first incident
          if (this.incidentsList.length) {
            this.setMedia(this.incidentsList[0]);
            this._storage.showNotification = true;
          } else {
            this.setMedia(null);
            this._storage.showNotification = false;
          }

          const isIncidentListEmpty =
            this.incidentsList.filter(
              (incident) => Object.keys(incident).length > 0
            ).length <= 0;
          this.show =
            (this.incidentsList.length === 0 ||
            isIncidentListEmpty ) &&
            this.isDashboard
              ? 'empty'
              : 'data';
          this.isShowError.emit(false);
        },
        err => {
          console.log('error while fetching fleet data', err);
          this.isShowError.emit(true);
        }
      );
    }

    public pageChange(event, isIncidentList?) {
      const apiCalls = this._createGetTripDetailApiCall(event.list);

      if (this._tripDetailMapFetchingSubscription) {
        this._tripDetailMapFetchingSubscription.unsubscribe();
      }
      this._tripDetailMapFetchingSubscription = merge(...apiCalls).subscribe(
        (data: any) => {
          this.tripDetailMap[data.tripId] = data;
          if (isIncidentList === true) {
            // incident response center
            // sort incidentList desc by date
            this.incidentsList = this._sortListWithSortOrder(
              event.list.map(this._addDisputeTimeMoment.bind(this)),
              true
            );
          } else { // Remove this branch in the future when the old table is deprecated
            this.incidentsPageList = event.list.map(this._addDisputeTimeMoment.bind(this));
          }
        }
      );
    }

    /**
     * @description: sort the incident based on select sort type (deprecated)
     * @param:
     */
    public sortChange() {
      const list = this.incidentsList;
      this.incidentsList = this._sortList(list);
      this.reset = true;
    }

    public isDisabledClick(event) {
      this.isDisabledChallenge = event;
    }

    public sortChangeInternal(event) {
      this.sortBy = event[0];
      this.sort = event[1];
      this.incidentsList = this._sortList(this.incidentsList);
      this.loadPageData(this.currentPageInfo.pageIndex, this.currentPageInfo.pageSize);
      this.reset = true;
      switch (this.sortBy) {
        case 'disputeTimeMoment':
          if (this.sort === 'asc') {
            this._setSyncSortCard('Least recent');
          } else {
            this._setSyncSortCard('Most recent');
          }
          break;
        case '_eventType':
          if (this.sort === 'asc') {
            this._setSyncSortCard('Category (a' + '\u2192' + 'z)');
          } else {
            this._setSyncSortCard('Category (z' + '\u2192' + 'a)');
          }
          break;
        case 'driverName':
          if (this.sort === 'asc') {
            this._setSyncSortCard('Disputed by (a' + '\u2192' + 'z)');
          } else {
            this._setSyncSortCard('Disputed by (z' + '\u2192' + 'a)');
          }
          break;
      }
    }

    public notifySortChange(event) {
      this._notifyPageChange$.next(event);
    }

    /**
     * @description: function to reject or accept a challenged incident
     * @param: incident details, status (accept/reject)
     */
    public updateChallenge(incident, status, event: MouseEvent) {
      this._updateFleetIncidents(
        incident,
        status === 'accept' ? CHALLENGE_ACCEPTED : CHALLENGE_REJECTED
      );
      event.stopPropagation();
    }

    /**
     * @description: function to set media type of a particular incident
     * @param: incident details
     */
    public setMedia(incident) {
      if (!incident) {
        this.incidentData = {};
      } else {
        this.storeCurrentIncidentData = Object.assign(incident);
        this.switch = !this.switch;
        this.selectedIncident = incident;
        if (this.selectedIncident.index !== undefined) {
          delete this.selectedIncident.index;
        }
        this.incidentData = {
          mediaLink: incident._mediaLink,
          driverId: incident.driverId,
          tripId: incident.tripId,
          eventIndex: incident.eventIndex,
          eventType: incident.eventType,
          speed: incident.speed,
          speedSign: {
            eventVideoFilename: incident.speedSign ? incident.speedSign.eventVideoFilename : undefined,
            speedSignValue: incident.speedSign ? incident.speedSign.speedSignValue : undefined,
          },
        };
      }
    }


    /**
     * @description: to check whether the http link is a image or not
     * @param: the src
     */
    public isImage(src) {
      return src.indexOf('.jpg') > -1;
    }

    /**
     * @description: Update page info and emit the information to table data source
     * @param: pageIndex pageIndex begin with 0
     * @param: pageSize item per page
     */
    public loadPageData(
      pageIndex: number,
      pageSize: number,
      selectFirstRow = false,
      selectCustomRow = -1
    ) {
      const {currentPage, previousPage} = this.patchPageInfo(pageIndex, pageSize);
      this.tableCurrentPageDetail = currentPage;

      // Handle the case when we are at the last page and
      // delete the last row, if there are previous pages, we
      // should move back.
      if (currentPage.length === 0 && pageIndex > 0) {
        this.tableCurrentPageDetail = previousPage;
        this.currentPageInfo.pageIndex -= 1;
      }

      const pageContent = {
        data: this.tableCurrentPageDetail,
        totalItems: this.incidentsList.length,
        totalPage: Math.ceil( this.incidentsList.length / pageSize ),
        perPage: pageSize,
        pageIndex: this.currentPageInfo.pageIndex,
        selectFirstRow,
        selectCustomRow,
      };
      if (!(pageContent.perPage === 0 && isNaN(pageContent.totalPage))) {
        this._incidentListTable$.next(pageContent);
      }

    }

    /**
     * @description update tableCurrentPageDetail by paginator index
     * @param event paginator object include pageIndex and PageSize
     */
    public updatePage(event) {
      this.loadPageData(event.pageIndex, event.pageSize);
    }

    /**
     * @description call after bug reported for selected row
     * @param incident selected incident
     */
    public updateIncidentList(incident) {
      const selectedIncident = this.incidentsList.find(item => this._isIncidentEqual(item, incident));
      this._updateIncidentList(selectedIncident);
    }

    public patchPageInfo(pageIndex: number, pageSize: number) {
      this._patchPageInfo(pageIndex, pageSize);
      return this._paginatedContent;
    }

    public generateUrl(oneTimeToken, format): string{
      const url = API.EXPORT_DISPUTED_INCIDENT(format);
      return url + '?' + Object.entries({
        oneTimeToken,
      }).map(([x, y]) => `${x}=${y}`);
    }

    public downloadFile(event) {
      this._zcfleet.getOneTimeToken().subscribe(otp => {
        const downloadUrl = this.generateUrl(otp.oneTimeToken, event);
        const divisions = this._storage.getStorageValue('HOME_LOCATION_ABV') || [];
        const mine = event === 'pdf' ? 'application/pdf' : 'text/csv';
        const params = {
          ...this.paramOption,
          divisions,
          raised: true,
          resolved: false,
          isAllLocationsQuery: divisions.length === this._storage.getStorageValue('HOME_LOCATION_FULL_LIST')?.length,
          minScore: this.filterOptions.minScore || 0,
          maxScore: this.filterOptions.maxScore || 100,
          dutyType:  this.filterOptions.dutyType === ''
            ? 'All'
            : this.filterOptions.dutyType,
        };
        this._zcfleet.makeExportRequest(downloadUrl, {params}, mine).subscribe(
          data => {
            const downloadURL = URL.createObjectURL(data);
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = downloadURL;
            link.download = this._getfileName(event);
            link.click();
            link.remove();
          },
          _err => {
            this._snackbarService.error(
              'Something went wrong',
              'Try again'
            ).subscribe(() => this.downloadFile(event));
          }
        );
      },
      (_err) => {
        this._snackbarService.error(
          'Something went wrong',
          'Try again'
        ).subscribe(() => this.downloadFile(event));
      });
    }

    private _getfileName(extension: string) {
      return `exportDisputedIncidentsFile.${extension}`;
    }

    private _patchPageInfo(pageIndex: number, pageSize: number) {
      this.currentPageInfo.pageIndex = pageIndex;
      this.currentPageInfo.pageSize = pageSize;
    }

    private get _paginatedContent() {
      const pageIndex = this.currentPageInfo.pageIndex;
      const pageSize = this.currentPageInfo.pageSize;

      const paginate = (page, size) => this.incidentsList.slice(page * size, (page + 1) * size);

      return {
        currentPage: paginate(pageIndex, pageSize),
        previousPage: paginate(pageIndex - 1, pageSize),
      };
    }

    private _createGetTripDetailApiCall(violation: any[]): Observable<any>[] {
      const tripList = violation.map((item) => ({
        tripId: item.tripId,
        value: {
          driverId: item.driverId, // we need driverId to request the API
          tripId: item.tripId,
        },
      }));

      /** Get the tripId item that wasn't cached */
      const notCachedKeys = Array.from(new Set(tripList.map(item => item.tripId)))
        .map(uniqueTripId => tripList.find(trip => trip.tripId === uniqueTripId)) // only return unique trip id item
        .filter((trip) => !this.tripDetailMap[trip.tripId]);

      const apiCalls = notCachedKeys.map((trip) => this._zcfleet.getTripDetail(trip.value.driverId, trip.value.tripId));
      apiCalls.push(of({ tripId: 'notused' })); // To ensure that the subscribe callback will always be called

      return apiCalls;
    }

    /**
     * @description: function to sort the list
     * @param: incident list
     */
    private _sortList(list) {
      return this._sortListInternal(list, this.sort === 'asc');
    }


    /**
     * @description: function to sort the list
     * @param: incident list
     */
    private _sortListWithSortOrder(list, sortDesc: boolean) {
      return this._sortListInternal(list, !sortDesc);
    }

    private _sortListInternal(list, isAsc) {
      return this._sort.transform(list, this.sortBy, isAsc);
    }

    private _updateIncidentList(incident) {
      let listIndex = this.incidentsList.indexOf(incident);
      // Remove the incident from current incident list - list line should always remove one item successfully
      // if not, please consider making some check
      this.incidentsList = this.incidentsList.filter(item => !this._isIncidentEqual(item, incident));
      // Clear selected row when the user delete that row
      let selectedPageRowIndex = -1;
      let newPageIndex = this.currentPageInfo.pageIndex;
      if (this.selectedIncident && this._isIncidentEqual(incident, this.selectedIncident)) {
        if (this.incidentsList.length > 0) {
          listIndex = listIndex < this.incidentsList.length ? listIndex : listIndex - 1; // listIndex will never be negative
          this.selectedIncident = this.incidentsList[listIndex];
          newPageIndex = Math.floor(listIndex / this.currentPageInfo.pageSize);
          selectedPageRowIndex = listIndex % this.currentPageInfo.pageSize;
        } else {
          newPageIndex = 0;
          this.selectedIncident = null;
        }
        // remove media when selected row removed
        this.setMedia(this.selectedIncident);
      }
      // Reload table page data after remove incident
      this.loadPageData(
        newPageIndex,
        this.currentPageInfo.pageSize,
        false,
        selectedPageRowIndex
      );
    }

    /**
     * @description: check if two incidents are equal
     */
    private _isIncidentEqual(incident1, incident2): boolean {
      return (
        incident1.tripId + incident1.eventIndex ===
        incident2.tripId + incident2.eventIndex
      );
    }

    /**
     * @description: function to updte the status of particular incident
     * @param: incident details and body
     * @returns: a observable with status of call
     */
    private _updateFleetIncidents(incident, body) {
      const { driverId, tripId, eventIndex } = incident;
      const timeMoment = incident.disputeTimeMoment.format('MM/DD/YYYY HH:mm z') || '';
      this._zcfleet
        .updateFleetIncidents(driverId, tripId, eventIndex, body).subscribe(
          () => {
            this._updateIncidentList(incident);

            // Deprecated
            this.update = true;

            this._snackbarService.success(
              SUCCESS_RULING_SAVED,
              'Close'
            );
          },
          () => {
            this._snackbarService.error(
              `Error ${body.isAccepted ? 'accept' : 'reject'} dispute ruling for ${incident._eventType || ''} at ${timeMoment}`,
              'Try Again'
            ).subscribe(() => {
              this._updateFleetIncidents(incident, body);
            });
          }
        );
    }

    private _addDisputeTimeMoment(item): any {
      const tripKey = item.tripId;
      item.disputeTimeMoment = createMomentObjectUtc(item.timestampUTC);

      if (this.tripDetailMap[tripKey]) {
        const {
          firstLocation: {
            latitude = 0,
            longitude = 0,
          } = {},
          timezoneOffset: timezoneOffset = 0,
        } = this.tripDetailMap[tripKey];
        item.disputeTimeMoment = item.disputeTimeMoment.tz(getTimezoneString(timezoneOffset, latitude, longitude));
      }
      return item;
    }

    private _setSyncSortCard(name: string) {
      this.syncSortCard = {
        displayName: name,
        sortColumn: this.sortBy,
        sortOrder: this.sort,
      };
    }

    private _createDummyData() {
      const result = [];
      for (let i = 0; i <= 10; i++) {
        result.push({
          disputeTimeMoment: i,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          _eventType: `${i}`,
          driverName: `${i}`,
        });
      }
      return result;
    }
}
