<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onShow)="onShow()">
    <div class="backDrop export-modal-backdrop" (click)="hideModal()"></div>
    <div class="modal-dialog modal-lg export-modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title pull-left">Export Options </span>
                <span class="pull-right ml-auto" style="cursor: pointer;" (click)="hideModal()">
                    <img src="assets/images/x.svg" />
                </span>
            </div>
            <div class="modal-body">
                <div class="filter-choice">
                    <div class="title row">
                        Filters

                        <div class="row">
                            <app-date-filter [value]="days" (discardChanges)="discardChanges()"
                                (valueChange)="setDateRange($event)"
                                (applyChanges)="applyFilter('date')" [isExportSet]="isExportSet"
                                [range]="range">
                            </app-date-filter>

                            <app-driver-filter [reset]="resetforExport"
                                [histogramData]="histogramData"
                                [histogramPercentile]="histogramPercentile"
                                [modeScoreFrequency]="modeScoreFrequency"
                                [driverCount]="driverCount"
                                [minScore]="minScore"
                                [maxScore]="maxScore" [rangeValue]="rangeValue"
                                (applyChanges)="applyFilter()"
                                (minScoreChange)="minScoreTemp = $event"
                                (maxScoreChange)="maxScoreTemp = $event"
                                (rangeValueChange)="rangeValueTemp = $event"
                                (discardChanges)="discardChanges()"
                                [isExportSet]="isExportSet" #driverFilter>
                            </app-driver-filter>

                            <app-location-filter
                              [reset]="resetforExport"
                              (applyChanges)="applyFilter()"
                              (discardChanges)="discardChanges()"
                              [isExportSet]="isExportSet"
                              (homeLocationChange)="getHomeLocationList($event)" #locationFilter>
                            </app-location-filter>

                            <app-duty-type-filter [dutyType]="dutyType"
                                (applyChanges)="applyFilter()" (discardChanges)="discardChanges()"
                                (dutyTypeChange)="setDutyType($event)" [isExportSet]="isExportSet">
                            </app-duty-type-filter>
                        </div>
                    </div>
                </div>

                <div class="export-choice">
                    <span class="title">Export Content</span>
                    <span class="note">Select the types of data you would like to include in this
                        export package.</span>

                    <div class="btn-group-toggle">
                        <label class="btn custom-check" [class.active]="fleetCheckbox"
                            (change)="setFleetCheckbox()">
                            <input type="checkbox" autocomplete="off" />
                            <span class="fa fa-check"></span>
                        </label>
                        <span>Fleets</span>
                    </div>
                    <div class="btn-group-toggle">
                        <label class="btn custom-check" [class.active]="driverCheckbox"
                            (change)="setDriverCheckbox()">
                            <input type="checkbox" autocomplete="off" />
                            <span class="fa fa-check"></span>
                        </label>
                        <span>Drivers</span>
                    </div>
                </div>

                <div class="format-choice">
                    <span class="title">File Format</span>
                    <div class="custom-radio">
                        <input type="radio" id="radioXLS" name="fileFormat" [value]="1"
                            [(ngModel)]="radioXLS" />
                        <label for="radioXLS"></label>
                        <span>XLS</span>
                    </div>
                    <div class="custom-radio">
                        <input type="radio" id="radioCSV" name="fileFormat" [value]="2"
                            [(ngModel)]="radioXLS" />
                        <label for="radioCSV"></label>
                        <span>CSV</span>
                    </div>
                </div>

                <div class="export-buttons">
                    <button class="btn btn-cancel" (click)="hideModal()">Cancel</button>
                    <button class="btn btn-export" (click)="exportReport()">Export</button>
                </div>
            </div>
        </div>
    </div>
</div>
