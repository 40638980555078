<div class="video-container">
  <div class="header" [hidden]="!mediaSrc && hasSpeedSign && (!notLoadingState$ || (notLoadingState$ | async))">
    <!-- tab list -->
    <div class="speed-tabs" [style.visibility]="(incidentEventType === 'Traffic-Speed-Violated') &&
      (!notLoadingState$ || (notLoadingState$ | async)) ? 'visible' : 'hidden'" >
      <ul class="nav nav-tabs">
        <li class="nav-item" [ngClass]="{'video-button-selected': isShowingActualSpeed}">
          <a class="nav-link video-button"
            (click)="showActualSpeed()">Actual Speed
            <ng-container *ngIf="incidentData.isLoadingSpeedData;else actualSpeedData">
              <ng-container *ngTemplateOutlet="speedLoadingSkeleton"></ng-container>
            </ng-container>
            <ng-template #actualSpeedData>
              <span *ngIf="actualSpeed; else noSpeedData" class="speedingValue">
                {{ actualSpeed | distance: "miles" | number: "1.0-0" }} mph
              </span>
            </ng-template>
          </a>
        </li>
        <li class="nav-item" [ngClass]="{'video-button-selected': !isShowingActualSpeed}">
          <a class="nav-link video-button"
            (click)="showPostedSpeed()">Posted Speed
            <ng-container *ngIf="incidentData.isLoadingSpeedData;else postedSpeedData">
              <ng-container *ngTemplateOutlet="speedLoadingSkeleton"></ng-container>
            </ng-container>
            <ng-template #postedSpeedData>
              <span *ngIf="postedSpeed; else noSpeedData" class="speedingValue">
                {{ postedSpeed | distance: "miles" | number: "1.0-0" }} mph
              </span>
            </ng-template>
          </a>
        </li>
      </ul>
    </div>

    <!-- Other buttons -->
    <div class="common-buttons" *ngIf="!hideViewTripButton">
      <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else viewTripSkeleton">
        <ul class="nav nav-buttons" [ngClass]="{disabled:isChallengeUpdating}">
          <li class="nav-item">
            <a class="nav-link button view-trip-button"
              [routerLink]="['/trip-detail']"
              [queryParams]="{ tripId: incidentData.tripId, driverId: incidentData.driverId}">
              View trip
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>

  <div class="body">
    <div class="incident-media">

      <!-- Show video source -->
      <div *ngIf="!isDvrEvent">
        <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else viewVideoSkeleton">
          <div *ngIf="mediaSrc && !isImage(mediaSrc) && mediaStatus === 'UPLOADED'">
            <video controls [src]="mediaSrc" type="video/mp4">
              Browser not supported
            </video>
          </div>

          <!-- Show image source -->
          <div *ngIf="mediaSrc && isImage(mediaSrc)">
            <img [src]="mediaSrc" alt="Event Image">
          </div>

          <!-- Speeding incident/postedspeed selected, but no image source or error while loading image url -->
          <ng-container *ngIf="incidentData.isLoadingSpeedData && !isShowingActualSpeed;else noMediaImage">
            <ng-container *ngTemplateOutlet="viewVideoSkeleton"></ng-container>
          </ng-container>
          <ng-template #noMediaImage>
            <div class="image-unavailable-container" *ngIf="!mediaSrc && !isEmptyData && !isShowingActualSpeed">
              <div class="no-image-available text-center">
                <div class="notification-container text-normal">
                  <span class="notification-icon custom-warning-icon">!</span>
                  <div class="notification-text">Image is not available</div>
                  <div class="notification-sub-text">
                    Make sure that camera and wi-fi are both connected.
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- Incident selected, but failed to load media content -->
          <div *ngIf="!isEmptyData && isShowingActualSpeed && mediaStatus !== 'UPLOADED'">
            <div class="video-unavailable-container">
              <div class="no-video-available text-center">
                <div class="notification-container text-normal">
                  <span class="notification-icon custom-warning-icon">!</span>
                  <div *ngIf="mediaStatus === 'CANCELLED' || mediaStatus === 'NOT_MARKED_FOR_UPLOAD'">
                    <div class="notification-text">Video is not available</div>
                    <div class="notification-sub-text">
                      Make sure that camera and wi-fi are both connected.
                    </div>
                  </div>
                  <div *ngIf="mediaStatus === 'UPLOADING' || mediaStatus === 'MARKED_FOR_UPLOAD'">
                    <div class="notification-text">Video upload pending</div>
                    <div class="notification-sub-text">
                      If video isn’t available in a minute or two, make sure that the camera and
                      wi-fi are both connected.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- No incident selected, no media source to show -->
          <div class="no-media-container" *ngIf="!mediaSrc && isEmptyData">
            <span>{{noMediaText}}</span>
          </div>

        </ng-container>

      </div>

      <!-- DVR-->
      <!-- DVR Incident selected, but media status is PENDING -->
      <div *ngIf="isDvrEvent">
        <div *ngIf="!isEmptyData && mediaStatus === 'PENDING'">
          <div class="video-unavailable-container">
            <div class="no-video-available text-center">
              <div class="notification-container text-normal" style="width: 86%; padding-bottom: 20px;">
                <div> <img src="assets/images/dvr_sync.svg" class="map_icons"></div>
                <div *isAuthorized="!'fleet-ridecam-plus'">
                  <ng-container *ngTemplateOutlet="ridecamMessage"></ng-container>
                </div>
                <div *isAuthorized="'fleet-ridecam-plus'">
                  <ng-container *ngTemplateOutlet="dashcamMessage"></ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DVR Incident selected, but media status is UNAVAILABLE -->
        <div *ngIf="!isEmptyData && mediaStatus === 'UNAVAILABLE'">
          <div class="video-unavailable-container">
            <div class="no-video-available text-center">
              <div class="notification-container text-normal">
                <span class="notification-icon custom-warning-icon">!</span>
                <div class="notification-text">Video is not available</div>
                <div class="notification-sub-text">
                  Make sure that camera and wi-fi are both connected.
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DVR Incident selected, show media content when media status is FINISHED -->
        <div *ngIf="!isEmptyData && mediaStatus === 'FINISHED'">
          <video controls [src]="mediaSrc" type="video/mp4">
            Browser not supported
          </video>
        </div>
      </div>

    </div>
  </div>

  <div class="footer" [hidden]="((!mediaSrc && hasSpeedSign) || isDvrEvent) && (!notLoadingState$ || (notLoadingState$ | async))" >
    <div class="common-buttons" *ngIf="showSmallSizeMediaPanel">
      <ul class="nav nav-buttons" [ngClass]="{disabled:isChallengeUpdating}">
        <ng-template [ngTemplateOutlet]="challengeButton"></ng-template>
      </ul>
    </div>
  </div>
</div>

<ng-template #noSpeedData>
  <span class="speedingValue no-data">
    N/A
  </span>
</ng-template>

<ng-template #challengeButton>
  <app-incident-button-control
    [challengeAccepted]="incidentData.challengeAccepted"
    [challengeRaised]="incidentData.challengeRaised"
    [challengeResolved]="incidentData.challengeResolved"
    [eventType]="incidentData.eventType"
    [popupCancel]="popupCancel"
    [notLoadingState$]="notLoadingState$"
    (incidentButtonChange)="updateChallenge($event)"
    (snackbarCancelChange)="snackbarCancel($event)"
  ></app-incident-button-control>
</ng-template>

<ng-template #ridecamMessage>
  <div class="dvr-media-container">
    <div class="notification-text">
      Video upload pending
    </div>
    <div class="notification-sub-text" style="padding-left: 68px;">
      If video is not available 1-2 minutes after request, please make sure that your Ridecam is connected
      to Wi-Fi.
    </div>
  </div>
</ng-template>

<ng-template #dashcamMessage>
  <div class="dvr-media-container">
    <div class="notification-text">
      Video upload pending
    </div>
    <div class="notification-sub-text">
      If video is not available 1-2 minutes after request, please make sure that:
      <ul>
        <li>The vehicle is running</li>
        <li>Your SmartCam is receiving power</li>
        <li>Your SmartCam is in an area with cellular coverage</li>
      </ul>
    </div>

  </div>
</ng-template>

<!-- loading skeleton for view trip button -->
<ng-template #viewTripSkeleton>
  <app-custom-skeleton-loader
    style="padding-right: 10px;"
    [theme]="{
      width: '104px',
      height: '32px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<!-- loading skeleton for video -->
<!-- TO-DO: Should be updated to support many sizes (width & height) of videos -->
<ng-template #viewVideoSkeleton>
  <app-custom-skeleton-loader *ngIf="!showSmallSizeMediaPanel; else viewSmallVideoSkeleton"
    [theme]="{
      width: '626px',
      height: '358px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<ng-template #viewSmallVideoSkeleton>
  <app-custom-skeleton-loader
    [theme]="{
      width: '457px',
      height: '258px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<ng-template #speedLoadingSkeleton>
  <app-custom-skeleton-loader
    style="padding: 0;"
    [theme]="{
      width: '40px',
      height: '11px',
      padding: '2px 0 2px 0'
    }">
  </app-custom-skeleton-loader>
</ng-template>