import {
  APP_INITIALIZER,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { StorageService } from '@app-core/services/storage.service';
import { environment } from '@env/environment';

import { FleetIdAttachInterceptorService } from './interceptors/fleet-id-attach-interceptor.service';
import { HandleAuthErrorInterceptorService } from './interceptors/handle-auth-error-interceptor.service';
import { ZcwAuthenticateService } from './services/zcw-authenticate.service';
import { OidcAuthenticateAdapterService } from './services/oidc-authenticate-adapter.service';

/**
 * Init function to try checking if the current URL contains fleetID or not, if it
 * is, parse only fleet ID information and store inside StorageService for later use.
 * Also clean the URL back to origin after checking
 *
 * If it is not contains fleetId on current URL and localstorage not stored fleetId value yet,
 * clean the URL to logout_url
 *
 * storage: injected storage service
 *
 * @return function that will run logic on invoking
 */
export const parseFleetIdAndStore = (storage: StorageService) => () => {
  const queryParams = new URLSearchParams(window.location.search);
  const stateQuery = queryParams.get('state');
  const rawNonceLength = 45;
  const b64NonceLength = 4 * (rawNonceLength / 3); // https://en.wikipedia.org/wiki/Base64#Output_padding

  if (stateQuery && stateQuery.length < b64NonceLength) {
    // this is fleetId, not angular-oauth2-oidc generated nonce
    console.log('Detect redirect request from GTC, with fleetId=', stateQuery);

    storage.setStorageValue('state', stateQuery);
    // Update local storage home divisions to default when fleet id changed
    storage.setStorageValue('FLAG_RESET_DIVISION', true);
    storage.setStorageValue('HOME_LOCATION', []);
    history.replaceState(null, window.name, window.origin);
  } else {
    if (!storage.getStorageValue('state')) {
      console.log('fleetId undefined, redirect back to GTC');
      window.location.href = environment.logout_url;
    }
  }
};

/**
 * Try to login using the provided auth service
 *
 * oauthService: injected auth service
 *
 * @return function that will run logic on invoking
 */
export const tryLoginWithAuthService =
  (zcwAuthService: ZcwAuthenticateService) => () =>
    zcwAuthService.initialize();

export const commonProviders: Provider[] = [
  // Initializer list
  {
    provide: APP_INITIALIZER,
    useFactory: parseFleetIdAndStore,
    deps: [StorageService],
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: tryLoginWithAuthService,
    deps: [ZcwAuthenticateService],
    multi: true,
  },

  // Interceptor list
  {
    provide: HTTP_INTERCEPTORS,
    useClass: FleetIdAttachInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HandleAuthErrorInterceptorService,
    multi: true,
  },

  {
    provide: ZcwAuthenticateService,
    useClass: OidcAuthenticateAdapterService,
  },
];

@NgModule({
  declarations: [],
  imports: [],
})
export class ZcwAuthCommonModule {
  public static forRoot(): ModuleWithProviders<ZcwAuthCommonModule> {
    return {
      ngModule: ZcwAuthCommonModule,
      providers: commonProviders,
    };
  }
}
