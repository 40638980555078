<div class="incident-summary-wrapper">

  <div class="graph-data-wrapper">
    <!-- Total incident information -->
    <app-card [elementClasses]="'total-incident-wrapper'" class="total-incident-card">
      <ng-container *ngTemplateOutlet="incidentInformation; context: { $implicit: totalIncident$, range: range}" card-body></ng-container>
    </app-card>

    <!-- Top incident chart -->
    <app-card [elementClasses]="'graph-incident-wrapper'" class="top-incident-card" [ngClass]="{
      'no-incident-background': ((internalTopIncidentData$ | async) <= 0)
        && (notLoadingState?.topIncidentData | async)
    }">
      <div card-title class="title">Top Incident Count</div>

      <ng-container *ngIf="(notLoadingState?.topIncidentData | async); else graphLoading" card-body class="class-graph-display">
        <ng-container *ngIf="(internalTopIncidentData$ | async) as topIncidentData">
          <ng-container *ngIf="topIncidentData.length > 0; else congratulationNoIncident">
            <div class="graph-row" *ngFor="let incident of topIncidentData">
              <ng-container *ngTemplateOutlet="chartBarTemplate; context: { $implicit: incident, assetId: assetId, maxValue: getMaxValue(topIncidentData)}"></ng-container>
            </div>
            <div class="gradient-graph-color"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-card>

  </div>
</div>

<ng-template #congratulationNoIncident>
  <div class="col-6 no-incident-message">
    <div style="width:100%" class="incident_table_zero">
        <p>No incidents. Well done!</p>
    </div>
  </div>
</ng-template>

<!-- Template for display incident total -->
<ng-template #incidentInformation let-totalIncidentNumber let-range="range">
  <p class="info-title">Total Incidents</p>

  <ng-container *ngIf="(notLoadingState?.totalIncident | async); else incidentTotalLoading">
    <ng-container *ngIf="(internalTotalIncident$ | async) as totalIncident; else noIncident">
      <p class="info-number">{{internationalNumberFormat.format(totalIncident)}}</p>
    </ng-container>
    <ng-template #noIncident>
      <p class="info-number">0</p>
    </ng-template>
  </ng-container>

  <p class="info-date">since {{range?.from | date: 'MM/dd'}}</p>
</ng-template>

<!-- Tempalte for chart bar -->
<ng-template #chartBarTemplate let-incident let-assetId="assetId" let-maxValue="maxValue">
  <div class="graph-legend">
    <div class="incident-item" [ngClass]="incident?.incidentClass">
      <a [routerLink]="['/asset-view']" [queryParams]="{assetId: assetId, category: incident.name}">{{ incident?.name }}</a>
    </div>
  </div>
  <div class="graph-data">
    <div class="graph-value">{{incident?.value}}</div>
    <div class="graph-bar">
      <div [ngStyle]="{ 'width': (incident?.value / maxValue * 100).toString() + '%' }"></div>
    </div>
  </div>
</ng-template>

<!-- Skeleton loading template for total incident number -->
<ng-template #incidentTotalLoading>
  <div>
    <app-custom-skeleton-loader
      [theme]="{
        'width': '164px',
        'height': '66px'
      }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>

<!-- Skeleton loading for top incident chart -->
<ng-template #graphLoading>
  <div *ngFor="let i of getGraphSkeletonArray()" class="graph-row">
    <div class="graph-legend">
      <app-custom-skeleton-loader
        [theme]="{
          'margin-bottom': '10px',
          'width': '100%',
          'height': '20px'
        }">
      </app-custom-skeleton-loader>
    </div>
    <div class="graph-data">
      <div class="skeleton-graph-display">
        <app-custom-skeleton-loader
          [theme]="{
            'margin-bottom': '10px',
            'width': '100%',
            'height': '20px'
          }">
        </app-custom-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
