<app-default-modal>
  <ng-container header>
    <span>Remove incident from Saved Incidents?</span>
  </ng-container>
  <ng-container body>
    <div>
      <span>
        All comments and dispute rulings will still be saved, and you will be able to find
        this incident under the Trip Summary.
      </span>
    </div>
  </ng-container>
  <ng-container footer *ngTemplateOutlet="actionButton"></ng-container>
</app-default-modal>

<ng-template #actionButton>
  <div class="btn-cancel-section">
    <button aria-label="Close" (click)="cancelRemoveBookmark()"
      class="btn btn-format btn-primary btn-cancel">Cancel
    </button>
  </div>
  <div class="btn-confirm-section">
    <button class="btn btn-primary btn-confirm btn-format"
      (click)="removeBookmarkFromSaveIncident()">Confirm
    </button>
  </div>
</ng-template>
