import { jeoJSON } from '@modules/shared/geoJSON';

import { Injectable } from '@angular/core';

import { tileLayer, geoJSON, Marker } from 'leaflet';
import * as L from 'leaflet';
import * as esri from 'esri-leaflet-geocoder';
import { Subject } from 'rxjs';
import {
  ReverseGeoCodeService,
  GeoCodeResult,
  Point,
} from './reverseGeocode.service';

export interface GeoLocation {
  latitude: number;
  longitude: number;
}
export interface LocationInfo extends GeoLocation {
  bearing?: number;
  speed?: number;
  accuracy?: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  locationInfo?: { LongLabel: string };
  addressInfo?: string;
}
export interface TripDetail {
  firstLocation: LocationInfo;
  lastLocation: LocationInfo;
  startTime: string;
  endTime: string;
  tripDistance: number;
  previousTripId: string;
  nextTripId: string;
  pathInfo?: any;
}

const MAP_OPTIONS = {
  layers: [
    tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      minZoom: 10,
      maxZoom: 18,
      attribution: 'Open Street Map',
    }),
    geoJSON(jeoJSON as any, {
      style: () => ({
        weight: 1,
        color: '#d487c9',
      }),
    }),
  ],
  zoom: 13,
  coordinates: { lat: 47.4376951, lng: -122.2480235 },
  zoomControl: true,
  attributionControl: true,
};

const ICON_OPTION = {
  iconUrl: 'assets/leaflet/marker-icon.png',
  iconSize: [37, 37],
  iconAnchor: [18, 33],
  className: 'map-pin-icon',
};

const CIRCLE_ICON_OPTION = {
  iconUrl: 'assets/leaflet/marker-icon.png',
  iconSize: [30, 30],
  iconAnchor: null,
  className: 'map-circle-icon',
};

const FLAGGED_ICON_OPTION = {
  iconUrl: 'assets/leaflet/marker-icon.png',
  iconSize: [30, 30],
  iconAnchor: null,
};

const FLAGGED_SMALLER_ICON_OPTION = {
  iconUrl: 'assets/leaflet/marker-icon.png',
  iconSize: [30, 30],
  iconAnchor: null,
};

const CIRCLE_OPTION = {
  color: '#00ff00',
  fill: true,
  fillOpacity: 1,
  radius: 5,
};

const MAP_TRIP_TEXT = {
  className: 'trip-text',
  iconSize: [150, 10],
  iconAnchor: [-15, 15],
};

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private _modal = new Subject<any>();
  constructor(private _reverseGeocodeService: ReverseGeoCodeService) {}

  /**
   * @description Returns a leaflet map instance for mapId
   * @param mapId Id of the DIV where the map renders
   * @param mapOption optional map options to be passed
   */
  public getMapInstance(mapId: string, mapOption: any = MAP_OPTIONS) {
    // Add missing options from the default options
    mapOption = { ...MAP_OPTIONS, ...mapOption };

    const { coordinates, ...options } = mapOption;

    return L.map(mapId, {
      center: [coordinates.lat, coordinates.lng],
      ...options,
    });
  }

  /**
   * @description adds an openstreet map tile on a leaflet map
   */
  public addOpenstreetTile(map: any) {
    return L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);
  }

  /**
   * @description: function to generate the icon based on eventype
   * @param: event type, icon options and custom icon options
   * @returns: the leaflet icon with selected icon options
   */
  public getIcon(eventType: string, iconOption: any = ICON_OPTION) {
    if (eventType) {
      iconOption = {
        ...ICON_OPTION,
        ...iconOption,
        iconUrl: `assets/images/markericons_v3/pip_incidents_icon/${eventType}.svg`,
      };
    }
    return L.icon(iconOption);
  }

  /**
   * @description: function to generate text icon based on provided input
   *
   * @param text Text to be shown
   * @param [iconOption=MAP_TRIP_TEXT] optional override icon options
   * @return The leaflet icon with selected icon options
   */
  public getTextIcon(text: string, iconOption: any = MAP_TRIP_TEXT) {
    return L.divIcon({
      ...MAP_TRIP_TEXT,
      ...iconOption,
      html: text,
    });
  }

  /**
   * @description: function to generate the circle icon based on eventype
   * @param: event type, circle options and custom circle icon options
   * @returns: the leaflet circle icon with selected icon options
   */
  public getCircleicon(
    eventType: string,
    iconOption: any = CIRCLE_ICON_OPTION
  ) {
    if (eventType) {
      iconOption = {
        ...CIRCLE_ICON_OPTION,
        ...iconOption,
        iconUrl: `assets/images/markericons_v3/incidents_icon_shadow_circle/${eventType}.svg`,
      };
    }
    return L.icon(iconOption);
  }

  /**
   * @description: function to generate the flagged icon based on eventype
   * @param: event type, flagged icon options and custom flagged icon options
   * @returns: the flagged icon with selected icon options
   */
  public getIconforFlaggedIncidents(
    eventType: string,
    iconOption: any = FLAGGED_ICON_OPTION
  ) {
    if (eventType) {
      iconOption = {
        ...FLAGGED_ICON_OPTION,
        ...iconOption,
        iconUrl: `assets/images/markericons_v3/incidents_icon_shadow_circle/${eventType}.svg`,
      };
    }
    return L.icon(iconOption);
  }

  /**
   * @description: function to generate the smaller flagges icon based on eventype
   * @param: event type, smaller flagged icon options and custom icon options
   * @returns: the smaller flagged icon with selected icon options
   */
  public getSmallerIconforFlaggedIncidents(
    eventType: string,
    iconOption: any = FLAGGED_SMALLER_ICON_OPTION
  ) {
    if (eventType) {
      iconOption = {
        ...FLAGGED_SMALLER_ICON_OPTION,
        ...iconOption,
        iconUrl: `assets/images/markericons_v3/incidents_icon_shadow_circle/${eventType}.svg`,
      };
    }
    return L.icon(iconOption);
  }

  /**
   * @description: function to generate the circle icon based on eventype
   * @param: event type, circle icon options and custom icon options
   * @returns: the cirlce icon with selected icon options
   */
  public getCircleIcon(lat: number, lng: number, options: any = CIRCLE_OPTION) {
    const coords = L.latLng(lat, lng);
    options = { ...CIRCLE_OPTION, ...options };
    return L.circleMarker(coords, options);
  }

  /**
   * @description: function to generate the map marker based on eventype
   * @param: lat, lon, icon type and boolean isDraggable
   * @returns: the leaflet marker with event type icon
   */
  public getMarker(
    lat: number,
    lng: number,
    icon?: any,
    isDraggable?: boolean,
    isClickable?: boolean
  ) {
    if (lat && lng) {
      return L.marker([lat, lng], {
        icon,
        draggable: isDraggable,
        interactive: isClickable,
      });
    }
  }

  /**
   * @description: function to generate the map layer group
   * @param: list of map layers
   * @returns: the leaflet map layer
   */
  public getLayerGroup(list: any[]) {
    return L.layerGroup(list);
  }

  /**
   * @description: function to generate the map feature group
   * @param: list of map features
   * @returns: the leaflet map feature
   */
  public getFeatureGroup(list: any[]) {
    const filterList = list.filter((item) => item).slice();
    return L.featureGroup(filterList);
  }

  /**
   * @description: function to generate the map bounds
   * @param: list of map bounds
   * @returns: the leaflet map bounds
   */
  public getBounds(list: any[]) {
    return L.latLngBounds(list);
  }

  /**
   * @description: function to generate the map marker popup
   * @param: html template
   * @returns: the popup with values
   */
  public createPopup(template) {
    const pop = L.popup();
    pop.setContent(template);
    return pop;
  }

  /**
   * @description: function to generate the map marker tooltip
   * @param: html template
   * @returns: the tooltip with values
   */
  public createTooltip(template) {
    const toolTip = L.tooltip({
      direction: 'left',
      offset: L.point(-10, -100),
    });
    toolTip.setContent(template);
    return toolTip;
  }

  /**
   * @description returns a latlng object
   */
  public getLatLong(lat, lng) {
    return L.latLng(lat, lng);
  }

  public showMedia(event: any) {
    this._modal.next(event);
  }

  /**
   * @description: function to generate event modal
   * @param: html template
   * @returns: the current modal
   */
  public eventModal() {
    return this._modal;
  }

  /**
   * @description: function to generate the map polyline
   * @param: path with lat long list
   * @returns: the leaflet polyline
   */
  public getPolyline(path: any[], isInteractive?: boolean) {
    return L.polyline(path, {
      weight: 5,
      color: '#0074cd',
      interactive: isInteractive,
    });
  }

  /**
   * @description: function to generate the highlighted map polyline
   * @param: highlightedpath with lat long list
   * @returns: the leaflet polyline
   */
  public getHighLightedPolyline(path: any[]) {
    return L.polyline(path, { weight: 5, color: 'darkblue' });
  }

  /**
   * @description: function to generate the reverse geocode based on lat lon
   * @param: lat and long
   * @returns:
   */
  public getReverseGeoCode(lat, lng) {
    return new Promise((resolve, reject) => {
      const latLng = this.getLatLong(lat, lng);
      // @ts-ignore
      const geocodeService = esri.geocodeService();

      geocodeService
        .reverse()
        .latlng(latLng)
        .run((error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
    });
  }

  /**
   * @description: function to generate the reverse geocode based on lat lon
   * @param: lat and long
   * @returns: promise of a geocode result
   */
  public getReverseGeoCodeSinglePoint(
    lat: number,
    long: number
  ): Promise<GeoCodeResult> {
    return this._reverseGeocodeService.reverseSinglePoint(lat, long);
  }
  /**
   * @description: async function to call getReverseGeoCodeSinglePoint to get reverse geocode
   * @param GeoLocation include lat long
   * @returns reverse geocode of lat long
   */
  public async callReverseGeoCode(
    location: GeoLocation
  ): Promise<GeoCodeResult> {
    if (location && location.latitude && location.longitude) {
      const result: GeoCodeResult = await this.getReverseGeoCodeSinglePoint(
        location.latitude,
        location.longitude
      );
      return result;
    }
  }

  /**
   * @description: function to generate the reverse geocode based on multiple lat long values
   * @param: lat and long
   * @returns: promise list of geo code result
   */
  public getReverseGeoCodeMultiplePoint(
    points: Point[]
  ): Promise<GeoCodeResult[]> {
    return this._reverseGeocodeService.reverseMultiplePoint(points);
  }

  /** Combine violations, calibrations and video capture events */
  public combineEvents(res: any): any[] {
    let events = [];
    try {
      events = (res.violations || []);
      // if there are violations , then do not show chalibration icon on map
      if (events.length === 0) {
        // Check to see if calibration and custom events are present
        // If so, then combine these with the violations
        if (res.debug && res.debug.length) {
          events = [...events, ...res.debug];
        }
      }
      if (res.customEvents && res.customEvents.length) {
        events = [...events, ...res.customEvents];
      }
    } catch (error) {
      console.error(error);
      return [];
    }
    return events;
  }

  /**
   * @description: function to generate the map polyline pathlist
   * @param: path with lat long list
   * @returns: the leaflet polyline
   */
  public preparePathList(pathInfoList: any[]): L.LatLng[] {
    return pathInfoList
      .filter((event) => +event.latitude || +event.longitude)
      .map((event) => this.getLatLong(event.latitude, event.longitude));
  }

  public loadEventsLocation(list: any[]) {
    /**
     * Create a marker list
     */
    const markerList = [];
    list.map((ele) => {
      const { latitude, longitude } = ele;
      // Exclude markers with lat lon 0
      if (+latitude || +longitude) {
        // generate an Icon
        const icon = this.getIcon(ele.eventType);
        // Generate a marker with the icon
        const marker = this.getMarker(+latitude, +longitude, icon);
        // Push the markers in the markers list
        markerList.push(marker);
      }
    });
    return markerList.slice();
  }

  public async buildTripEndPointMarkers(
    tripDetail: TripDetail
  ): Promise<Marker<any>[]> {
    if (!tripDetail) {
      return [];
    }

    return [
      ...this.buildTripEndPointIconMarkers(tripDetail),
      ...(await this.buildTripEndPointAddressMarkers(tripDetail)),
    ];
  }

  public buildTripEndPointIconMarkers(tripDetail: TripDetail): Marker<any>[] {
    if (!tripDetail) {
      return [];
    }

    const startOfTripIcon = this.getIcon('StartOfTrip', { iconAnchor: null });
    const endOfTripIcon = this.getIcon('EndOfTrip', { iconAnchor: null });
    const endOfTripIconOngoing = this.getIcon('OngoingTrip', {
      iconAnchor: null,
    });
    let endOfTripMarker = null;

    const startOfTripMarker = this._getMarkerOfTrip(
      tripDetail.firstLocation,
      startOfTripIcon,
      false,
      true
    );
    if (tripDetail.lastLocation) {
      endOfTripMarker = this._getMarkerOfTrip(
        tripDetail.lastLocation,
        endOfTripIcon,
        false,
        true
      );
    } else {
      endOfTripMarker = this._getMarkerOfTrip(
        this._getLastPathItemWithLongLat(tripDetail),
        endOfTripIconOngoing,
        false,
        true
      );
    }
    return [startOfTripMarker, endOfTripMarker];
  }

  public async buildTripEndPointAddressMarkers(
    tripDetail: TripDetail
  ): Promise<Marker<any>[]> {
    if (!tripDetail) {
      return [];
    }

    const firstLocation = tripDetail.firstLocation;
    const lastLocation = tripDetail['ongoing']
      ? this._getLastPathItemWithLongLat(tripDetail)
      : tripDetail.lastLocation;

    const startOfTripAddressIcon = this.getTextIcon(
      this._getLocationLongLabel(firstLocation)
    );
    if (tripDetail['ongoing'] && lastLocation) {
      const locationInfo = await this.callReverseGeoCode(lastLocation);
      lastLocation['addressInfo'] = locationInfo.full_address;
    }
    const endOfTripAddressIcon = this.getTextIcon(
      this._getLocationLongLabel(lastLocation)
    );

    const startOfTripText = this._getMarkerOfTrip(
      firstLocation,
      startOfTripAddressIcon,
      false,
      false
    );
    const endOfTripText = this._getMarkerOfTrip(
      lastLocation,
      endOfTripAddressIcon,
      false,
      false
    );

    return [startOfTripText, endOfTripText];
  }

  private _getLocationLongLabel(locationObj: LocationInfo): string {
    if (locationObj && locationObj.addressInfo) {
      return locationObj.addressInfo;
    } else if ( locationObj && locationObj.locationInfo && locationObj.locationInfo.LongLabel) {
      return locationObj.locationInfo.LongLabel;
    } else {
      return 'Unknown, NA';
    }
  }

  private _getMarkerOfTrip(
    location: GeoLocation,
    icon?: any,
    isDraggable?: boolean,
    isClickable?: boolean
  ) {
    if (location && location.latitude && location.longitude) {
      return this.getMarker(
        location.latitude,
        location.longitude,
        icon,
        isDraggable,
        isClickable
      );
    }
  }

  /**
   * Return the last index of the path information that has both
   * of latitude and longitude value.
   *
   * @param tripDetail selected trip
   * @returns the index that sastify the condition or -1 if not found
   */
  private _getLastPathItemWithLongLat(tripDetail: TripDetail): GeoLocation {
    const reversePath: GeoLocation[] = tripDetail['pathInfo'].slice().reverse();
    return reversePath.find(
      (pathItem) => pathItem.latitude && pathItem.longitude
    );
  }
}
