<span class="heading" [class.selected]="selected"
  isDisableOnPermission
  [isDisableOnPermission]="'fleet-ridecam-plus'"
  [isDisableOnPermissionPassthrough]="!checkForFleetPermission"
  (clickWithPermission)="clicked($event)"
  >
    <ng-content></ng-content>
    <ng-container *ngIf="checkForFleetPermission; then partWithPermissionCheck else partWithoutPermissionCheck"></ng-container>

    <ng-template #partWithPermissionCheck>
      <span class="sort-arrow" *isAuthorized="'fleet-ridecam-plus' reverse true" (click)="clicked($event)">
        <ng-template *ngTemplateOutlet="sortIndicator; context: { $implicit: desc }"></ng-template>
      </span>
    </ng-template>

    <ng-template #partWithoutPermissionCheck>
      <span class="sort-arrow" (click)="clicked($event)">
        <ng-template *ngTemplateOutlet="sortIndicator; context: { $implicit: desc }"></ng-template>
      </span>
    </ng-template>
</span>

<ng-template #sortIndicator let-desc>
  <img *ngIf="!desc" src={{imgArrowUp.src}}>
  <img *ngIf="desc" src="{{imgArrowDown.src}}">
</ng-template>
