import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent {
    @Input() public value = '';
    @Output() public valueChange = new EventEmitter<string>();

    @Input() public itemList = [];
    @Input() public displayProp = '';
    @Input() public valueProp = '';
    @Input() public isShowAction = false;

    public showList = false;

    /**
     * @description: function to select an item from a dropdown list
     * @param: item details
     */
    public onSelect(item) {
      this.valueChange.emit(this._getEmitValue(item));
      this._hideDropdown();
    }

    /**
     * @description: function to display text based on object
     * @param: object
     */
    public displayText(ob) {
      return this.getValue(ob, this.displayProp);
    }

    /**
     * @description: function to get the type of value
     * @param: object and key
     */
    public getValue(ob, key) {
      if (typeof ob === 'string') {
        return ob;
      }
      if (typeof ob === 'object') {
        return ob[key];
      }
    }

    /**
     * @description: function to toggle dropdown
     * @param:
     */
    public toggleDropdown() {
      this.showList = !this.showList;
    }

    /**
     * @description: function to check if clicked inside of a dropdown body
     * @param: click inside boolean flag
     */
    public clickedInside(clickedInside) {
      if (!clickedInside) {
        this._hideDropdown();
      }
    }

    /**
     * @description: function to hide dropdown
     * @param:
     */
    private _hideDropdown() {
      this.showList = false;
    }

    /**
     * @description: function to emit object value
     * @param: object
     */
    private _getEmitValue(ob) {
      return this.getValue(ob, this.valueProp);
    }
}
