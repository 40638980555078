import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EVENTS_INCIDENT_TYPE,
  UNASSIGNED_DRIVER,
  EMPTY_AGGREGATE_DATA,
  EMPTY_AGGREGATE_DATA_LIST,
  UNIDENTIFIED_LOCATION,
  LOCATION_QUERY_FAIL,
  TableTripInfo,
  ERROR_MESSAGE,
  UNIDENTIFIED_DRIVER_NAME,
} from '@app-core/constants/constants';
import { Configuration } from '@app-core/services/configuration.service';
import { INTERCEPTOR_SKIP_HEADER } from '@app-core/services/manage-http-interceptor.service';
import { filterAssetEventCount } from '@modules/shared/utils';
import {
  Observable,
  Subject,
  forkJoin,
  of,
  iif,
  from,
  OperatorFunction,
  throwError,
} from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { CommentToDriver } from '../components/custom-modal/comment-modal/comment-modal.component';
import { EventTripMetadata } from '../components/custom-modal/remove-modal/remove-modal.component';
import { AggregateDataList } from '../pages/asset-snapshot/asset-snapshot.component';
import { AssetViewIncidentList } from '../pages/asset-view/asset-view.component';

import {
  API,
  EVENT_NAMES_MAP,
  SearchFilterModel,
  ModalParamsModel,
  INCIDENTS_NAMES_MAP,
  ErrorHandling,
  UserModel,
  GetDriverListOptions,
  AssetFromTripRequest,
  AssetModel,
} from './../dashboard3.models';
import { ReverseGeoCodeService, GeoCodeResult } from './reverseGeocode.service';

const API_FAIL_SECTION = [
  'MT_ASSET_SNAPSHOT_METRIC', //0
  'MT_ASSET_SNAPSHOT_GRAPH', //1
  'MT_ASSET_SNAPSHOT_INCIDENT', //2
  'MT_ASSET_SNAPSHOT_DRIVER_HISTORY', //3
  'MT_DRIVER_SNAPSHOT_METRICS', //4
  'MT_DRIVER_SNAPSHOT_FLEETTREND', //5
  'MT_TRIPS_RECAP', //6
  'MT_DASHBOARD_FLEET_PERFORMANCE', //7
  'MT_DASHBOARD_DRIVER_SCORECARD', //8
  'MT_DISPUTED_INCIDENT', //9
  'MT_SAVE_INCIDENT', //10
  'MT_ASSET_VIEW', //11
];

const shouldFail = (key) => localStorage.getItem(key) === 'true';

const mapFailError = (key, res) => iif(() => shouldFail(key), throwError(undefined), of(res));

@Injectable()
export class ZCFleetService {
  public fleetFilterChange = new Subject<SearchFilterModel>();
  public commonTableModal = new Subject<ModalParamsModel>();
  public driverFilterChange = new Subject<SearchFilterModel>();
  public captureIncidentModal = new Subject<ModalParamsModel>();
  public editCameraSettingsModal = new Subject<ModalParamsModel>();
  public enhanceVideoModal = new Subject<ModalParamsModel>();
  public removeCameraModal = new Subject<ModalParamsModel>();

  public get isHardcodeSystemIssue() {
    return localStorage.getItem('hardcode-asset-config-system-issue');
  }


  public stats;
  public driverCount;
  public fleetDriverCount;
  public fleetRating;
  public tripCount;
  public sort;
  public sortBy;
  public homeLocationList = [];
  private _refreshAPI = new Subject<void>();
  private _isRefreshDvr = new Subject<boolean>();

  constructor(
    private _http: HttpClient,
    private _reverseGeocodeService: ReverseGeoCodeService
  ) {
    if (!localStorage.getItem('hardcode-asset-config-system-issue')) {
      localStorage.setItem('hardcode-asset-config-system-issue', 'false');
    }
    API_FAIL_SECTION.forEach(key => {
      localStorage.setItem(key, 'false');
    });
  }

  /**
   * @description: function to get fleet stats
   * @param: params options
   * @returns: the stats object
   * @method: GET
   */
  public getStats(options?: any): Observable<any> {
    const URL = API.FLEET_STATS_V2;
    const params = this._setHomeLocationDivisions(options);
    this.stats = this._http.post(URL, params);
    return this.stats;
  }

  /**
   * @description: function to get fleet performance
   * @param: params options
   * @returns: the fleet performance object
   * @method: GET
   */
  public getFleetPerformance(options: any): Observable<any> {
    const URL = API.FLEET_STATS_PERFORMANCE;
    const params = this._setHomeLocationDivisions(options);
    return this._http.post(URL, params).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[7], res)));
  }

  /**
   * @description: function to get driver stats
   * @method: GET
   * @param: driverId and params options
   * @returns: the driver stats object
   */
  public getDriversStats(driverID: string, options: any): Observable<any> {
    const URL = API.DRIVERS_STATS_V2(driverID);
    const params = this._setHomeLocationDivisions(options);
    return this._http
      .post(URL, params )
      .pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[4], res)))
      .pipe(this._createDriverNameReversePipeline());
  }

  // get asset home locations from LighMetrics api
  // TO-DO: Remove
  public getHomeLocations(): Observable<any> {
    const URL = API.HOME_LOCATION;
    return this._http.get(URL).pipe(
      catchError((error) => {
        const errorMsg = error.error.message;
        if (errorMsg === LOCATION_QUERY_FAIL) {
          return of({
            homeLocations: [],
          });
        }
        throw (error);
      }),
      tap(res => this._addUnassignLocation(res))
    );
  }

  // get home locations from entity api
  public getHomeLocationsFromEntity(): Observable<any> {
    const URL = API.HOME_LOCATION_ENTITY;
    return this._http.get(URL).pipe(tap(res => this._addUnassignLocation(res)));
  }

  /**
   * @description: function to get fleet trends based on start and end date
   * @method: GET
   * @param: params options
   * @returns: the fleet trends object with details
   */
  public getTrendsList(options?: any): Observable<any> {
    const URL = API.TREND_V2;
    const params = this._setHomeLocationDivisions(options);

    return this._http.post(URL, params).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[5], res))).pipe(
      map((res: any) => res)
    );
  }

  /**
   * @description: function to get driver trends based on start and end date
   * @method: GET
   * @param: params options
   * @returns: the driver trends object with details
   */
  public getFleetDriverCards(options?: any): Observable<any> {
    const URL = API.FLEET_DRIVERS_TREND_CARD;
    const params = this._setHomeLocationDivisions(options);
    return this._http.post(URL, params).pipe(
      map((res: any) => {
        const list = res.data.map((ele) => {
          ele.topIncidentType =
            INCIDENTS_NAMES_MAP[ele.topIncident.type] || 'Perfect';
          return ele;
        });
        return list;
      })
    );
  }

  /**
   * @description: function to get driver list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the driver list object with details
   */
  public getDriverListV2(options?: GetDriverListOptions): Observable<any> {
    const URL = API.DRIVERS_V2;
    const params = this._setHomeLocationDivisions(options);
    return this._http.post(URL, params).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[8], res))).pipe(
      map((res: any) => {
        const totalCount = res.totalCount;
        const list = res.drivers.map((ele) => {
          ele.topIncidentType = EVENT_NAMES_MAP[ele.topIncident.type] || 'NA';
          return ele;
        });
        return { list, totalCount };
      }),
      switchMap((res) => {
        const drivers = res['list'];
        return of(drivers).pipe(
          this.createDriverNameMultipleReversePipeline(),
          map((result) => ({
            ...res,
            list: result,
          }))
        );
      })
    );
  }

  /**
   * @description: function to get driver list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the driver list object with details
   */
  public getDriverList(options?: any): Observable<any> {
    const URL = API.DRIVERS;
    return this._http.get(URL, options).pipe(
      map((res: any) => {
        const list = res.rows.filter((ele) => ele.driverName ? true : false);
        list.sort((a, b) => a.driverName > b.driverName
          ? 1
          : a.driverName < b.driverName
            ? -1
            : 0);
        return list;
      })
    );
  }

  /**
   * @description: function to get driver list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the driver list object with details
   */
  public getDriverProfileList(options?: any): Observable<any> {
    const URL = API.DRIVERS_PROFILE_ENTITY;
    const params = this._setDivisionNamesParam(options);
    return this._http.post(URL, params).pipe(
      map((res: any) => {
        const list = res.filter((ele) => ele.driverId);
        list.map(
          (item) =>
            (item['driverName'] =
              item.legacyFirstName + ' ' + item.legacyLastName)
        );
        list.sort((a, b) => a.driverName > b.driverName
          ? 1
          : a.driverName < b.driverName
            ? -1
            : 0);
        return list;
      })
    );
  }

  /**
   * @description: function to get trip list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the trip list object with details
   */
  public getTripList(options?: any): Observable<any> {
    const URL = API.TRIPS;

    return this._http.post(URL, options).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[6], res))).pipe(
      map((res: any) => {
        const list = res.rows.map((ele) => {
          // Check for 'firstLocation' and 'locationInfo' objects in
          // response and if not present, assign empty object
          let location = ele.firstLocation
            ? ele.firstLocation.locationInfo
              ? ele.firstLocation.locationInfo
              : {}
            : {};
          // if location is persent, then show city or subreigon or region
          // else show 'NA'(not available)
          // eslint-disable-next-line @typescript-eslint/naming-convention
          const { City = null, Subregion = null, Region = null } = location;
          location = City || Subregion || Region || 'NA';
          return {
            driverId: ele.driverId,
            tripId: ele.tripId,
            driverName: ele.driverName,
            startTime: ele.startTime,
            startTimeUTC: ele.startTimeUTC,
            duration: ele.duration,
            tripDistance: ele.tripDistance,
            total: ele.eventCount.total,
            cameraConnected: ele.cameraConnected,
            eventCount: ele.eventCount,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            _assetNumber: ele.asset.assetId,
            firstLocation: {
              latitude: ele.firstLocation.latitude,
              longitude: ele.firstLocation.longitude,
            },
            timezoneOffset: ele.timezoneOffset,
            location,
          };
        });
        return list;
      }),
      this.createDriverNameMultipleReversePipeline()
    );
  }

  /**
   * @description: function to get fleet incidents list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the fleet incidents list object with details
   */
  public getFleetIncidents(options?: any): Observable<any> {
    const URL = API.CHALLENGES;
    const params = this._setHomeLocationDivisions(options);
    const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);
    return this._http.post(URL, params).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[9], res))).pipe(
      switchMap((res) => {
        const drivers = res['rows'];
        return of(drivers).pipe(
          this.createDriverNameMultipleReversePipeline(),
          map((result) => ({
            ...res,
            rows: result,
          }))
        );
      }),
      map((res: any) => {
        res.rows.forEach((val) => {
          val['_eventType'] = EVENT_NAMES_MAP[val.eventType];
          val['_mediaLink'] = val.eventVideoFile;
        });
        return res.rows.filter((evt) => eventTypeAllowedList.includes(evt.eventType));
      })
    );
  }

  /**
   * @description: function to get fleet's asset view incidents list
   * @method: GET
   * @param: params options such as start, end and score
   * @returns: the asset view incidents list object with details
   */
  public getAssetViewFleetIncidents(options?: any): Observable<AssetViewIncidentList> {
    const URL = API.CHALLENGES;
    const params = this._setHomeLocationDivisions(options);
    const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);
    return this._http.post(URL, params).pipe(
      tap((res: any) => {
        res.rawLength = res.rows.length; // check asset view table
        res.rows = res.rows.filter((evt) => eventTypeAllowedList.includes(evt.eventType));
      })
    ).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[11], res)));
  }

  /**
   * @description: function to update fleet incidents
   * @method: PATCH
   * @param: driverId, tripId, eventIndex and body
   * @returns: the status of http call
   */
  public updateFleetIncidents(driverId, tripId, eventIndex, body) {
    eventIndex = Number(eventIndex);
    const URL = API.UPDATE_CHALLENGES(driverId, tripId, eventIndex);
    return this._http.patch(URL, body);
  }

  /**
   * @description: function to report and event as bug
   * @method: PATCH
   * @param: driverId, tripId, eventIndex and body
   * @returns: return the status of http call
   */
  public reportBug(driverId, tripId, eventIndex, body) {
    const URL = API.REPORT_BUG(driverId, tripId, eventIndex);
    return this._http.patch(URL, body);
  }

  /**
   * @description: function to update particular driver trip list
   * @method: GET
   * @param: driverId and param options
   * @returns: the driver trip list object with details
   */
  public getDriverTripList(driverId: string, options?: any): Observable<any> {
    if (!driverId) {
      throw new Error('Missing driver id');
    }
    const URL = API.DRIVER_TRIP_LIST(driverId);
    const params = this._setHomeLocationDivisions(options);

    return this._http.post(URL, params).pipe(
      map((res: any) => this._extractTripDetails(res.rows))
    );
  }
  /**
   * @description: function to get a specific trip data without any additional data
   * @method: GET
   * @param: driverId, tripId
   * @returns: trip detail if success
   */
  public getTripDetail(driverId: string, tripId: string, params?: any): Observable<any> {
    if (!driverId || !tripId) {
      throw new Error('Missing driver or trip Id');
    }
    const URL = API.TRIP_DETAILS(driverId, tripId);
    return this._http.get(URL, { params });
  }

  /**
   * @description: function to get particular trip details
   * @method: GET
   * @param: driverId, tripId
   * @returns: the trip details object with details
   */
  public getTripDetails(
    driverId: string,
    tripId: string,
    params?: any,
    prefetch?: boolean
  ): Observable<any> {
    if (!driverId || !tripId) {
      throw new Error('Missing driver or trip Id');
    }
    const URL = API.TRIP_DETAILS(driverId, tripId);
    return this._http.get(URL, { params }).pipe(
      this._createDriverNameReversePipeline(),
      switchMap((res: any) => {
        // Filter out paths with 0 latitude and longitude
        const pathInfo = res.pathInfo.filter(
          (path) => path.latitude && path.longitude
        );
        const violations = (res.violations || []).filter(
          ({ eventType = '' } = {}) => eventType !== 'Anomaly'
        );
        const endPointLocation = [res.firstLocation, res.lastLocation];
        const tripDetailData = { ...res, pathInfo, violations };

        const tripDetailWithLocation$ = from(
          this._reverseGeocodeService.reverseMultiplePoint(endPointLocation)
            .catch(_ => null) // normal catching in pipe will not work. TODO: find root cause
        ).pipe(
          map((addressInfo) => {
            if (!addressInfo) {
              throw new Error('Cannot get reverse location from API');
            }

            const [firstLocationAddress, lastLocationAddress] =
              this._getTripEndPointAddressInfo(
                this._zipArrays(addressInfo, endPointLocation)
              );
            return {
              ...tripDetailData,
              firstLocation: {
                ...tripDetailData.firstLocation,
                addressInfo: firstLocationAddress,
              },
              lastLocation: {
                ...tripDetailData.lastLocation,
                addressInfo: lastLocationAddress,
              },
            };
          }),
          catchError((_err) => of(tripDetailData))
        );
        return iif(() => prefetch, tripDetailWithLocation$, of(tripDetailData));
      })
    );
  }

  /**
   * @description: function to get particular driver trend details
   * @method: GET
   * @param: driverId, startDate and endDate
   * @returns: the driver trend details object with details
   */
  public getDriverTrandDetails(
    driverId: string,
    startDate: string,
    endDate: string,
    divisions: string[] = []
  ): Observable<any> {
    const OPTIONS = {
      params: {
        driverId,
        startDate,
        endDate,
        divisions,
      },
    };
    const params = this._setHomeLocationDivisions(OPTIONS);
    const URL = API.DRIVERTRAND_DETAILS;
    return this._http.post(URL, params );
  }

  /**
   * @description: function to get duty type configuration details
   * @method: GET
   * @param:
   * @returns: the duty type configuations details of assetss
   */
  public getDutyTypeConfig(): Observable<any> {
    const URL = API.DUTY_TYPE_CONFIG;
    return this._http.get(URL);
  }

  /**
   * @description: function to update duty type of particular asset
   * @method: PATCH
   * @param: duty type
   * @returns: the status of the http call
   */
  public updateDutyTypeConfig(dutyType, body): Observable<any> {
    const URL = API.DUTY_TYPE_CONFIG + `/${dutyType}`;
    return this._http.patch(URL, body);
  }

  /**
   * @description: function to get get Asset list
   * @method: GET
   * @param: param options
   * @returns: the asset list object with details
   */
  public getAssets(body: any): Observable<any> {
    const URL = API.ASSETS;
    return this._http.get(URL, body).pipe(
      map((res: any) => {
        // Handling the map opration inside a try catch to prevent the
        // application from crashing because of inconsistent asset response
        try {
          if (this.isHardcodeSystemIssue === 'true') {
            throw (new Error('hardcode asset configuration systems issue'));
          }
          res.assets = res.assets.map((ele) => {
            ele['assetNumber'] = ele.metadata.assetNumber;
            return ele;
          });
          return res;
        } catch (error) {
          if (error.message === 'hardcode asset configuration systems issue') {
            throw throwError(error.message);
          }
          console.error(`Error: ${error}`);
          // Filter the inconsistent asset responses
          res.assets = res.assets.filter((ele) => ele.metadata && ele.metadata.assetNumber);
          res.assets = res.assets.map((ele) => {
            ele['assetNumber'] = ele.metadata.assetNumber;
            return ele;
          });
          return res;
        }
      })
    );
  }

  /**
   * @description: function to update particular asset details
   * @method: PATCH
   * @param: assetId and body
   * @returns: the status of the http call
   */
  public updateAssets(assetId, body): Observable<any> {
    const URL = API.ASSETS + `/${assetId}`;
    return this._http.patch(URL, body);
  }

  /**
   * @description: function to get particular camera tampering status
   * @param: event data
   * @returns: type of event as tampered or not
   */
  public getTamepringStatus(data) {
    let result;
    const visionEvents =
      data.eventCount['Traffic-Speed-Violated'] +
      data.eventCount['Lane-Drift-Found'] +
      data.eventCount['Traffic-STOP-Sign-Violated'] +
      data.eventCount['Tail-Gating-Detected'];

    const inertialEvents =
      data.eventCount['Harsh-Braking'] +
      data.eventCount['Harsh-Acceleration'] +
      data.eventCount.Cornering;

    if (inertialEvents) {
      result = visionEvents / inertialEvents === 0 ? true : false;
    } else {
      result = false;
    }
    return result;
  }

  /**
   * @description: function to set tampering status for a particular camera
   * @param: response
   * @returns: the trip details object with details
   */
  public setTampered(res) {
    const result = res.map((trip) => {
      if (trip.cameraConnected && trip.eventCount.total > 0) {
        trip.tampered = this.getTamepringStatus(trip);
      } else {
        trip.tampered = false;
      }
      return trip;
    });
    return result;
  }
  public getErrorMessage(err = {} as any) {
    const { status = 500, error: { message = 'Something went wrong' } = {} } =
      err || {};
    return new ErrorHandling({
      status,
      message: !status
        ? 'No internet or Not able to reach the server'
        : message,
      showRetryButton: status !== 401,
    });
  }

  /**
   * @description: function to get particular event capture DVR
   * @method: POST
   * @param: body
   * @returns: the status of DVR capture details
   */
  public captureDVRClip(body): Observable<any> {
    this._isRefreshDvr.next(true);
    const URL = API.CAPTURE_DVR_CLIP;
    return this._http.post(URL, body)
      .pipe(
        catchError(_err => {
          const fallBackBody = { ...body };
          Object.assign(fallBackBody, { videoResolution: '1280x720'});
          return this._http.post(URL, fallBackBody);
        }),
        finalize(() => this._isRefreshDvr.next(false))
      );
  }

  public get refreshAPI$() {
    return this._refreshAPI;
  }

  // of(true) if is refreshing, otherwise of(false)
  public get isRefreshDvr$(): Observable<boolean> {
    return this._isRefreshDvr;
  }

  public eDvrRequest(body): Observable<any> {
    const URL = API.E_DVR_REQUEST;
    return this._http.post(URL, body)
      .pipe(catchError(_err => {
        const fallBackBody = { ...body };
        Object.assign(fallBackBody, { videoResolution: '1280x720'});
        return this._http.post(URL, fallBackBody);
      }));
  }

  public getDriverLocation(driverId): Observable<any> {
    const URL = API.DRIVER_LOCATION(driverId);
    return this._http.get(URL).pipe(
      map((res) => res),
      (err) => err
    );
  }

  public getDriverLocationFromEntity(driverId): Observable<any> {
    const URL = API.DRIVER_LOCATION_ENTITY(driverId);
    return this._http.get(URL).pipe(
      map((res: any) => ({ homeLocation: res.name })),
      (err) => err
    );
  }

  public eDvrList(options: any): Observable<any> {
    const URL = API.E_DVR_LIST;
    return this._http.get(URL, options).pipe(
      map((res) => res),
      (err) => err
    );
  }

  public violationsAggregate(options: any): Observable<any> {
    const URL = API.VIOLATIONS_AGGREGATE;
    return this._http.get(URL, options).pipe(
      map((res) => res),
      (err) => err
    );
  }

  public getAllAssets(): Observable<any> {
    const URL = API.GET_ALL_ASSETS;
    return this._http.get(URL).pipe(
      map((res) => res),
      (err) => err
    );
  }

  public getUnassignedDevices(
    page: number,
    pageLimit: number,
    sortField?: string
  ): Observable<any> {
    const URL = API.DEVICES;
    const options = {
      params: {
        page: String(page),
        per_page: String(pageLimit || 10),
        assigned: 'false',
        deviceType: 'Camera',
        sort: sortField ? sortField : 'zonarSerialNum:asc',
      },
    };

    return this._http.get(URL, options);
  }

  public getUnassignedDevicesByImei(
    imei: string,
    sortField?: string
  ): Observable<any> {
    const URL = API.DEVICES;
    const options = {
      params: {
        zonarSerialNum: imei,
        assigned: 'false',
        deviceType: 'Camera',
        sort: sortField ? sortField : 'zonarSerialNum:asc',
      },
    };

    return this._http.get(URL, options);
  }

  /**
   * @description: Function upload camera Imei
   * @method: POST
   * @param: cameraIMEIParam
   */
  public uploadCameraIMEI(cameraIMEIParam: {
    listCameraIMEI: string[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  }): Observable<object> {
    const URL = API.LIST_CAMERA_IMEI;
    return this._http.post(URL, cameraIMEIParam);
  }

  public checkCameraIMEIExist(imei: string): Observable<boolean> {
    const URL = API.DEVICES;
    const requestStatuses = ['ACTIVE'];
    const requestObs = requestStatuses.map((status) =>
      this._http.get(URL, { params: { status, zonarSerialNum: imei } })
    );

    return forkJoin(requestObs).pipe(
      map((combinedResult) =>
        combinedResult.some(
          (item: { results: any[] }) => item.results.length > 0
        )
      )
    );
  }

  public updateCameraStatus(camerasListParam: {
    cameras: any[];
  }) {
    const URL = API.LIST_CAMERA_IMEI;
    return this._http.patch(URL, camerasListParam);
  }

  public isFleetContainsRideCamCamera(): Observable<boolean> {
    const URL = API.FLEET_RIDECAM_STATUS;
    return this._http
      .get<{ hasRideCam: boolean }>(URL, {headers: INTERCEPTOR_SKIP_HEADER})
      .pipe(map((value) => value.hasRideCam));
  }

  public disassociateCameraFromAsset(
    entityAssetId: string,
    cameraIMEI
  // eslint-disable-next-line @typescript-eslint/ban-types
  ): Observable<object> {
    const URL = API.ASSET_DEVICES;
    const option = {
      assetId: entityAssetId,
      imei: cameraIMEI,
      status: 'INACTIVE',
    };
    return this._http.post(URL, option);
  }

  public getUserInfoFromUserProfileId(userId: string): Observable<UserModel> {
    // Do not get user profile from unassigned driver
    if (userId === UNASSIGNED_DRIVER) {
      return throwError(new Error('Cannot find user profile with unassigned id'));
    }

    const URL = API.USER_INFO_FROM_USER_PROFILE_ID(userId);
    return this._http.get<UserModel>(URL).pipe(
      map(res => {
        // add full driver name tp user profile
        res.driverName =
        res && res.firstName && res.lastName
          ? res.firstName + ' ' + res.lastName
          : ERROR_MESSAGE.INVALID_DRIVER_ID;
        return res;
      })
    );
  }

  public getMultipleUserInfoFromUserProfileIds(
    userIds: string[]
  ): Observable<UserModel[]> {
    const URL = API.USER_INFO_MAP_FROM_MULTIPLE_USER_PROFILE_IDS;
    // Do not get user profile from unassigned driver
    const userIdsFiltered = Array.from(new Set(userIds.filter(userId => userId !== UNASSIGNED_DRIVER)));
    const option = {
      ids: userIdsFiltered,
    };

    return option.ids.length ? this._http.post<UserModel[]>(URL, option) :
      throwError(new Error('Cannot find user profiles with empty id list'));
  }

  public createDriverNameMultipleReversePipeline(isReverseData = true): OperatorFunction<any, any> {
    return switchMap((driverIdSourceList) => {
      // Ignore empty list case
      if (
        !driverIdSourceList ||
        !Array.isArray(driverIdSourceList) ||
        driverIdSourceList.length <= 0 ||
        !isReverseData
      ) {
        return of(driverIdSourceList);
      }

      const userIds: string[] = driverIdSourceList
        .filter((item) => item && item.driverId && !item.driverName)
        .map((item) => item.driverId);

      if (userIds.length <= 0) {
        return of(driverIdSourceList);
      }

      return this.getMultipleUserInfoFromUserProfileIds(userIds).pipe(
        catchError(() => of({})),
        // This pipe will try to compare and match the user info with
        // corresponding driver and calculate the driver name, because of that
        // the server API must return a mapping format instead of normal listing
        // format, so the normal /users API should not be used.
        map((driverInfoMap) => driverIdSourceList.map((sourceItem) => {
          const mappedUserInfo = driverInfoMap[sourceItem.driverId];
          const overwriteData = {
            driverName: this._createDriverNameFromUserInfo(mappedUserInfo, sourceItem.driverId),
          };

          return {
            ...sourceItem,
            ...overwriteData,
          };
        }))
      );
    });
  }

  public updateEventTripMetadata(violationIdentifier: string, tripMetadata: EventTripMetadata): Observable<any>{
    const URL = API.UPDATE_TRIP_METADATA(violationIdentifier);
    return this._http.post(URL, tripMetadata).pipe(tap(() => this._refreshAPI.next()));
  }

  public updateCommentStatus(violationIdentifier: string, readComment: Record<string,boolean>): Observable<any>{
    const URL = API.COMMENT_VIOLATION_STATUS(violationIdentifier);
    return this._http.post(URL, readComment).pipe(tap(() => this._refreshAPI.next()));
  }

  public getMultiCommentByViolationIdentifiers(
    violationIdentifiers: string[],
    queryOptions: Record<string, Record<string, string>>
  ): Observable<any>{
    const URL = API.COMMENTS_BY_VIOLATION_IDENTIFIER;
    return this._http.post(URL, violationIdentifiers, queryOptions);
  }

  public getMultiBookmarkByViolationIdentifiers(violationIdentifiers: string[]): Observable<any>{
    const URL = API.BOOKMARKS_BY_VIOLATION_IDENTIFIER;
    return this._http.post(URL, violationIdentifiers);
  }

  public createCommentToDriver(violationIdentifier: string, commentToDriver: CommentToDriver): Observable<any>{
    const URL = API.COMMENT_VIOLATION(violationIdentifier);
    return this._http.post(URL, commentToDriver).pipe(finalize(() => this._refreshAPI.next()));
  }

  public getSavedIncident(options?: any, isReverseData = true): Observable<any> {
    const URL = API.SAVED_INCIDENT;
    let pageInfo = {};
    return this._http.post(URL, {...options}, { observe: 'response'})
      .pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[10], res))).pipe(
        map((res: any) => {
          pageInfo = {
            limit: res.body.limit,
            skip: res.body.skip,
            totalPage: res.headers.get('x-page-count'),
            totalItems: res.headers.get('x-total-count'),
          };
          return res.body.rows;
        }),
        this.createDriverNameMultipleReversePipeline(isReverseData),
        this._createAssetNameMultipleReversePipeline(isReverseData),
        map(result => ({
          rows: result || [],
          ...pageInfo,
        }))
      );
  }

  public getListAssetViolations(assetId: string, options: any): Observable<any> {
    const URL = API.LIST_ASSET_VIOLATIONS(assetId);
    return this._http.get(URL, options).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[2], res)));
  }

  public getSingleCommentByViolationIdentifier(violationIdentifier: string): Observable<any>{
    const URL = API.COMMENT_VIOLATION(violationIdentifier);
    return this._http.get(URL);
  }

  public getStatusBookmarkByViolationIdentifier(violationIdentifier: string): Observable<any>{
    const URL = API.STATUS_BOOKMARK_VIOLATION(violationIdentifier);
    return this._http.get(URL);
  }

  public getViolationConfigurationSettings(): Observable<Configuration>{
    const URL = API.CONFIGURATION_VIOLATION;
    return this._http.get<Configuration>(URL);
  }

  public getOneTimeToken(): Observable<any>{
    const URL = API.ONE_TIME_TOKEN;
    return this._http.get(URL);
  }

  public getMultipleAssetFromTrips(tripList: AssetFromTripRequest[]): Observable<Record<string, AssetModel>> {
    const URL = API.ASSETS_BY_TRIPS;
    const option = {
      tripList,
    };

    return this._http.post<Record<string, AssetModel>>(URL, option);
  }

  public getAssetNameByAssetId(assetId: string): Observable<string> {
    const URL = API.ASSET_DETAIL_BY_ASSET_ID(assetId);
    return this._http.get(URL).pipe(
      map(res => res['metadata'] && res['metadata']['assetNumber'])
    );
  }

  public getDivisionByAssetId(_assetId: string): Observable<string> {
    // TO-DO: Asset snapshot api to get division is missing
    return of('N/A');
  }

  public getAssetAggregate<T>(assetId: string, paramOptions: any): Observable<T> {
    const URL = API.ASSET_AGGREGATE(assetId);
    return this._http.get(URL, { params: paramOptions }).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[0], res))).pipe(
      map((res) => {
        if (res?.rows && res.rows[0]) {
          res['rows'][0].value.eventCount = filterAssetEventCount(res['rows'][0].value.eventCount);
          return res['rows'][0];
        }
        return {
          value: EMPTY_AGGREGATE_DATA,
        };
      }),
      (err) => err
    );
  }

  public getAssetTripList(assetId: string, paramOptions: any): Observable<any> {
    const URL = API.ASSET_TRIPS_LIST(assetId);
    return this._http.get(URL, { params: paramOptions }).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[3], res))).pipe(
      map((res: any) => {
        res.rows.forEach((val) => {
          val.eventCount = filterAssetEventCount(val.eventCount);
        });
        return res;
      })
    );
  }

  public getAssetAggregateByDays(assetId: string, paramOptions: any): Observable<AggregateDataList[]> {
    const URL = API.ASSET_AGGREGATE(assetId);
    return this._http.get(URL, { params: paramOptions }).pipe(switchMap((res) => mapFailError(API_FAIL_SECTION[1], res))).pipe(
      map((res) => {
        if (res?.rows.length > 0) {
          const aggregateList = res['rows'].map(
            row => {
              row.value.eventCount = filterAssetEventCount(row.value.eventCount);
              return row;
            }
          );
          return aggregateList;
        } else {
          return EMPTY_AGGREGATE_DATA_LIST;
        }
      }),
      (err) => err
    );
  }

  public makeExportRequest(url: string, options: any, type: 'application/pdf' | 'text/csv') {
    const body = this._setHomeLocationDivisions(options);
    return this._http.post(url, body, {responseType: 'blob', observe: 'response'}).pipe(
      map(data => new Blob([data.body], {type})),
      catchError((err) => {
        throw err;
      })
    );
  }

  private _getTripEndPointAddressInfo(addressInfo: GeoCodeResult[]): string[] {
    // Address info in the following format: <firstPart, secondPart>
    // Possible value:
    // - <address>, <region/locality>. For ex: 100 Diamond Street, Seattle
    // - <address>, NA. For ex: 100 Diamond Street, NA
    // - <region/locality>. For ex: Seattle
    // - '' in case there's no address and other values at all
    return addressInfo.map((item) => {
      const firstPart = item.address;
      const secondPart =
        item.locality || item.City || item.Subregion || item.Region;

      const fallbackValue = firstPart ? 'NA' : undefined;
      return [
        firstPart,
        // We want to display second part as 'NA' when the first part is present and second part is falsy
        secondPart || fallbackValue,
      ]
        .filter((value) => !!value)
        .join(', ');
    });
  }

  private _zipArrays<
    A extends Record<string, any>,
    B extends Record<string, any>
  >(source: A[], target: B[]): (A & B)[] {
    return source.map((item, index) => {
      const mergeValue = (target[index] && target[index].locationInfo) || {};
      return Object.assign({}, item, mergeValue);
    });
  }

  private _createDriverNameReversePipeline(): OperatorFunction<any, any> {
    return switchMap((driverIdSource) => {
      // Ignore if input is undefined or driverName is already exists
      if (
        !driverIdSource ||
        !driverIdSource['driverId'] ||
        driverIdSource['driverName']
      ) {
        return of(driverIdSource);
      }

      if (driverIdSource['driverId'] === UNASSIGNED_DRIVER) {
        driverIdSource['driverName'] = UNIDENTIFIED_DRIVER_NAME;
        return of(driverIdSource);
      }

      return this.getUserInfoFromUserProfileId(driverIdSource['driverId']).pipe(
        catchError(() => of(null)),
        map((userInfo) => ({
          ...driverIdSource,
          driverName: driverIdSource['driverName']
            ? driverIdSource['driverName']
            : this._createDriverNameFromUserInfo(userInfo, driverIdSource['driverId']),
        }))
      );
    });
  }

  private _setHomeLocationDivisions(options: any) {
    const { divisions = [], ...otherParams } = options.params || {};
    const params = otherParams;
    if (options && divisions) {
      params.divisions = divisions;
    }
    return params;
  }

  private _setDivisionNamesParam(options: any) {
    const { divisionNames = [], ...otherParams } = options.params || {};
    const params = otherParams;
    if (options && divisionNames) {
      params.divisionNames = divisionNames;
    }
    return params;
  }

  private _createDriverNameFromUserInfo(userInfo: UserModel, driverId: string) {
    if ((userInfo === undefined || userInfo === null) && driverId === UNASSIGNED_DRIVER) {
      return UNIDENTIFIED_DRIVER_NAME;
    } else if ((userInfo === undefined || userInfo === null) && driverId !== UNASSIGNED_DRIVER
    || (!userInfo.firstName || !userInfo.lastName)) {
      return ERROR_MESSAGE.INVALID_DRIVER_ID;
    }

    return userInfo.firstName + ' ' + userInfo.lastName;
  }

  private _createAssetNameMultipleReversePipeline(isReverseData = true): OperatorFunction<any, any> {
    return switchMap((assetIdSourceList) => {
      // Ignore empty list case
      if (
        !assetIdSourceList ||
        !Array.isArray(assetIdSourceList) ||
        assetIdSourceList.length <= 0 ||
        !isReverseData
      ) {
        return of(assetIdSourceList);
      }

      return this.getAllAssets().pipe(
        catchError(() => of({})),
        map((assetInfoMap) => {
          assetIdSourceList.map((sourceItem) => {
            const asset = assetInfoMap.assets.find(
              (assetItem) => assetItem.assetId === sourceItem.assetId
            );
            if (asset && asset.metadata && asset.metadata.assetNumber) {
              sourceItem.assetNumber = asset.metadata.assetNumber;
            } else {
              sourceItem.assetNumber = 'N/A';
            }
          });
          return assetIdSourceList;
        })
      );
    });
  }

  private _addUnassignLocation(res: any) {
    if (res && res.homeLocations && !res.homeLocations.find(location => location.locationId === UNIDENTIFIED_LOCATION.locationId)) {
      res.homeLocations.push(UNIDENTIFIED_LOCATION);
    }
    localStorage.setItem('HOME_LOCATION_FULL_LIST', JSON.stringify(res?.homeLocations));
  }

  private _extractTripDetails(list: any[]): TableTripInfo[] {
    return list.filter(trip => trip.tripId).map(trip => {
      const {
        total = 1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Anomaly = 0,
        ...tripEvents
      } = trip.eventCount || {};

      // Calculate top incident
      let topIncident = Object.keys(tripEvents).reduce((a, b) =>
        tripEvents[a] > tripEvents[b] ? a : b
      );
      topIncident = EVENT_NAMES_MAP[topIncident] || 'NA';

      // Get location value
      let location = trip.firstLocation?.locationInfo ? trip.firstLocation.locationInfo : {};
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { City = null, Subregion = null, Region = null } = location;
      location = City || Subregion || Region || 'NA';
      return {
        startTime: trip.startTime,
        startTimeUTC: trip.startTimeUTC,
        duration: trip.duration,
        tripDistance: trip.tripDistance,
        tripId: trip.tripId,
        total: trip.eventCount.total,
        topIncident,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        _assetNumber: trip.asset.assetId,
        location,
        firstLocation: {
          latitude: trip.firstLocation.latitude,
          longitude: trip.firstLocation.longitude,
        },
        timezoneOffset: trip.timezoneOffset,
        cameraConnected: trip.cameraConnected,
        eventCount: trip.eventCount,
        displayTimeZone: Number(trip.timezoneOffset / 60),
      } as TableTripInfo;
    });
  }
}
