<div class="zc-body zc-app-wrap has-search-filter">
    <app-search-filter [hidden]=true page="settings" (backClicked)="gotoFleet()" (driverChange)="driverChange($event)">
    </app-search-filter>

    <div class="zc-content-wrap">
        <div class="settings-page-wrap">
            <div class="row no-gutters settings-wrap">
                <div class="col-lg-12 settings-left">
                    <div class="row no-gutters">
                        <div class="col-12 p-0 zc-heading" style="top:-20px">
                            <span>Incident Category Sensitivity </span>
                        </div>
                    </div>

                    <div class="asset-settings">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#home" role="tab">
                                    Heavy Duty
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
                                    Medium Duty
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#contact" role="tab">
                                    Light Duty
                                </a>
                            </li>
                        </ul>

                        <div class="tab-content p-2">
                            <div class="tab-pane fade show active" id="home" role="tabpanel">
                                <div class="duty-settings-wrap">
                                    <app-duty-settings-options [data]="configuration" dutyType="heavy"
                                        [readOnly]="readOnly" (change)="updateSettingsConfig('heavy', $event)">
                                    </app-duty-settings-options>
                                </div>
                                <ng-template [ngTemplateOutlet]="noteEffect"></ng-template>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel">
                                <div class="duty-settings-wrap">
                                    <app-duty-settings-options [data]="configuration" dutyType="medium"
                                        [readOnly]="readOnly" (change)="updateSettingsConfig('medium', $event)">
                                    </app-duty-settings-options>
                                </div>
                                <ng-template [ngTemplateOutlet]="noteEffect"></ng-template>
                            </div>
                            <div class="tab-pane fade" id="contact" role="tabpanel">
                                <div class="duty-settings-wrap">
                                    <app-duty-settings-options [data]="configuration" dutyType="light"
                                        [readOnly]="readOnly" (change)="updateSettingsConfig('light', $event)">
                                    </app-duty-settings-options>
                                </div>
                                <ng-template [ngTemplateOutlet]="noteEffect"></ng-template>
                            </div>
                            <ng-template #noteEffect>
                              <div class="note-effect">
                                <span>
                                  <strong>
                                    For all incident categories, please allow up to 24 hours for changes to take effect.
                                  </strong>
                                </span>
                              </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div class="settings-right">
                <div class="row heading-wrap">
                    <div class="settings-table-heading zc-heading">
                        <span>Asset Configuration</span>
                    </div>
                    <!-- <div class="heading-btn-group">
                        <div class="btn-group export-btn" role="group" aria-label="Basic example" (click)="toggleExport()">
                            <span class="label" >Export to XLSX</span>
                        </div>
                    </div> -->
                </div>
                <div class="zc-asset-filter-wrap">
                    <app-search-filter-table
                        (filterChange)="onFilterChange($event)"
                        [filterOptions]="dropdownFilterOptions"
                        [refetchFilter]="refetchAssetFilter"
                        [filterList]="filterList"
                    ></app-search-filter-table>
                </div>
                <!-- Table -->
                <div class="row zc-table-wrap">
                    <table class="table zc-table asset-list-table table-striped table-hower header-sort table-sm">
                        <thead>
                            <tr>
                                <th class="col-3" scope="col">
                                    <app-table-sort-header sortOn="assetNumber" [(sortBy)]="sortBy" [(sort)]="sortAssetConfig"
                                        (sortClicked)="refreshAssetsList()">
                                        Asset
                                    </app-table-sort-header>
                                </th>
                                <th class="col-3" scope="col" *isAuthorized="'fleet-ridecam-plus'" >
                                    CAMERA IMEI
                                </th>
                                <th class="col-3" scope="col">
                                    Duty Type
                                </th>
                                <th class="col-3" *ngIf="!readOnly" >
                                    Feedback Volume
                                    <span class="info-img">
                                        <img src="/assets/images/info_2019-04-30/info.png" />
                                        <div class="tool-tip">
                                            <div style="margin: 12px">
                                                <div style="padding-bottom: 20px">
                                                    <b>FEEDBACK VOLUME</b> controls the loudness
                                                    of in-vehicle coaching and a drivers’
                                                    ability to adjust it.
                                                    <br />
                                                </div>
                                                <div>
                                                    <b>ON </b>Driver has full control of the
                                                    feedback volume.<br />
                                                    <b>MUTED </b>Driver cannot adjust the
                                                    feedback volume and it is locked to mute.<br />
                                                    <b>MAX </b>Driver cannot adjust the feedback
                                                    volume and it is locked to maximum.
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </th>

                                <th class="col-3" *ngIf="readOnly">
                                    Feedback Volume
                                    <span class="info-img">
                                        <img src="/assets/images/info_2019-04-30/info.png" />
                                        <div class="tool-tip">
                                            <div style="margin: 12px">
                                                <div style="padding-bottom: 20px">
                                                    <b>FEEDBACK VOLUME</b> controls the loudness
                                                    of in-vehicle coaching and a drivers’
                                                    ability to adjust it.
                                                    <br />
                                                </div>
                                                <div>
                                                    <b>ON </b>Driver has full control of the
                                                    feedback volume.<br />
                                                    <b>MUTED </b>Driver cannot adjust the
                                                    feedback volume and it is locked to mute.<br />
                                                    <b>MAX </b>Driver cannot adjust the feedback
                                                    volume and it is locked to maximum.
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="!readOnly && !isNetworkErrorAC && !isGetAssetError">
                            <tr *ngFor="let asset of assetsPerPageList; let index=index;" >
                                <td class="col-3 td-asset-text">{{ asset?.assetNumber }}</td>
                                <td class="col-3"
                                    *isAuthorized="'fleet-ridecam-plus'"
                                    [ngClass]="{'blurring-of-text': asset.cameraIMEI === 'No Camera Associated'}">
                                    {{ asset?.cameraIMEI }}

                                    <img *ngIf="(asset.cameraIMEI !== 'No Camera Associated') && !(asyncFlag[activePage] && asyncFlag[activePage][index])"
                                        class="chain-link-icon"
                                        src="assets/images/link_icon.svg"
                                        #popOverDirective="bs-popover"
                                        [popover]="popTemplate"
                                        [popoverContext]="context"
                                        placement="bottom"
                                        [outsideClick]="true"
                                        (click) ="getUnassociationOfIMEIInfo({
                                            assetId: asset.assetId,
                                            assetNumber: asset.assetNumber,
                                            cameraIMEI: asset.cameraIMEI,
                                            directive: popOverDirective,
                                            index: index
                                        })"
                                    >

                                    <app-inline-spinner
                                        class="inline-spinner"
                                        *ngIf="asyncFlag[activePage] && asyncFlag[activePage][index]"
                                        #popOverDirective="bs-popover"
                                        [popover]="popTemplate"
                                        [popoverContext]="context"
                                        placement="bottom"
                                        [outsideClick]="true"
                                        (click) ="getUnassociationOfIMEIInfo({
                                            assetId: asset.assetId,
                                            assetNumber: asset.assetNumber,
                                            cameraIMEI: asset.cameraIMEI,
                                            directive: popOverDirective,
                                            index: index
                                        })"
                                        >
                                    </app-inline-spinner>
                                </td>
                                <td class="col-3">
                                    <span class="duty-type-select">
                                        <app-custom-select displayProp="label" valueProp="value"
                                            [value]="displayDutyType(asset?.dutyType)" (valueChange)="updateAssets(asset, $event)"
                                            [itemList]="assetTypes">
                                        </app-custom-select>
                                    </span>
                                </td>
                                <td class="padding-25">
                                    <app-custom-select displayProp="label" valueProp="value"
                                        [value]="asset?.deviceVolume"
                                        (valueChange)="updateAssets(asset, $event, 'deviceVolume')"
                                        [itemList]="assetVolume">
                                    </app-custom-select>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="readOnly && !isNetworkErrorAC && !isGetAssetError">
                            <tr *ngFor="let asset of assetsPerPageList">
                                <td class="width-20">{{ asset?.assetNumber }}</td>
                                <td class="width-20"
                                    [ngClass]="{'blurring-of-text': asset.cameraIMEI === 'No Camera Associated'}">
                                    {{ asset?.cameraIMEI }}
                                    <img alt="ABC" src="assets/images/link_icon.svg" width="50" height="50">
                                </td>
                                <td class="width-20">
                                    <div class="readonly-duty-type">
                                        {{ asset?.dutyType }}
                                    </div>
                                </td>
                                <td class="width-20">
                                    <span class="duty-type-select">
                                        {{ asset?.deviceVolume }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="isNetworkErrorAC || isGetAssetError">
                            <tr class="no-hover">
                                <td [colSpan]=4>
                                    <div class="setting-page-no-data-block">
                                        <div class="setting-page-no-data-content">
                                            <span *ngIf="isNetworkErrorAC, else systemIssueTemplate">
                                                No network found
                                            </span>
                                            <ng-template #systemIssueTemplate>
                                                <span>
                                                    System issue
                                                </span>
                                            </ng-template>
                                            <p>
                                                <br>Unable to retrieve Coach data.
                                                <br>Please <a class="uc-refresh-link" href="../settings">refresh</a> to try again.
                                                <br><br>If the issue persists, please wait a few
                                                <br>minutes before reloading the data.
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="assetConfigTableNoResult && !isNetworkErrorAC && !isGetAssetError">
                            <tr class="no-hover">
                                <td [colSpan]=4>
                                    <div class="setting-page-no-data-block">
                                        <div class="setting-page-no-data-content">
                                            <span>
                                                No results found
                                            </span>
                                            <p>
                                                <br>Please modify your search and try again.
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="paginator-wrap">
                        <app-paginator3 [(reset)]="reset" [pageLimit]="assetPerPageLimit" [length]="totalAssets" [data]="listData"
                            (pageChangeData)="pageChange($event)" (pageChange)="getMoreData($event)" (currentPageChange)="onCurrentPageChange($event)">
                        </app-paginator3>
                    </div>
                </div>
                <!-- Table -->
            </div>

            <div class="settings-right" *isAuthorized="'fleet-ridecam-plus'">
                <div class="row heading-wrap">
                    <div class="settings-table-heading zc-heading">
                        <span>Unassigned Cameras</span>
                    </div>
                    <div class="heading-btn-group">
                        <div *ngIf="isShowDeleteCameras" class="btn-group delete-btn" role="group" aria-label="Basic example" (click)="removeMultipleCameras()">
                            <span class="label" >Delete cameras</span>
                        </div>
                        <div class="btn-group edit-btn" style="display: none;" role="group" aria-label="Basic example" (click)="editMultipleCameras()">
                            <span class="label edit-label" >Edit cameras</span>
                        </div>
                        <div *ngIf="isShowDeleteCameras" class="heading-line"></div>
                        <div class="btn-group add-btn" role="group" aria-label="Basic example" (click)="openModalUploadCameraByIdComponent()">
                            <span class="label add-icon" >
                                <img src="assets/images/plus-sign.svg" alt="">
                                Add by ID
                            </span>
                        </div>
                        <div class="btn-group bulk-btn" role="group" aria-label="Basic example" (click)="openModalUploadCameraFromFile()">
                            <span class="label bulk-icon" >Bulk upload</span>
                        </div>
                        <!-- <div class="btn-group export-btn" role="group" aria-label="Basic example" (click)="toggleExport()">
                            <span class="label" >Export to XLSX</span>
                        </div> -->
                    </div>
                </div>
                <div class="row filter-wrap">
                    <app-search-filter page="unassignedCameras" (backClicked)="gotoFleet()" (unassignCamChange)="unassignCamChange($event)">
                    </app-search-filter>
                </div>
                <!-- Table -->
                <div class="row zc-table-wrap">
                    <table class="table zc-table asset-list-table table-striped table-hower header-sort table-sm">
                        <thead>
                            <tr>
                                <ng-container *ngIf="showHeaderCheckbox(); else noDelete">
                                    <th *ngIf="isShowDeleteCameras; else emptyTemplate"
                                    class="multiselect-item-checkbox checkbox-all col-1" scope="col">
                                        <input type="checkbox" aria-label="multiselect-select-all" [checked]="isSelectedAll"/>
                                        <div *ngIf="totalCameras > 0; else disabledCheckAll" (click)="toggleSelectAll()"></div>
                                    </th>
                                    <ng-template #emptyTemplate >
                                        <th class="multiselect-item-checkbox col-1" scope="col">
                                            <input type="checkbox" aria-label="multiselect-select-all" [checked]="isSelectedAll"/>
                                            <div *ngIf="totalCameras > 0; else disabledCheckAll" (click)="toggleSelectAll()"></div>
                                        </th>
                                    </ng-template>
                                    <ng-template #disabledCheckAll >
                                        <div></div>
                                    </ng-template>
                                </ng-container>
                                <th class="col-10" scope="col">
                                    <app-table-sort-header [(sort)]="sortUnassignedCamera" (sortClicked)="refreshUnusedCameraList()">
                                        Unassigned Camera IMEI
                                    </app-table-sort-header>
                                </th>
                                <th *ngIf="isShowCheckBoxAndActionColumn && !isSearchNoData" class="col-1" scope="col"></th>
                            </tr>
                            <ng-template #noDelete >
                                <th class="col-1" *ngIf="isShowCheckBoxAndActionColumn" scope="col"></th>
                            </ng-template>
                        </thead>
                        <tbody *ngIf="totalCameras > 0 && isShowCheckBoxAndActionColumn && !isNetworkError && !isGetCameraError">
                            <tr *ngFor="let camera of unusedCameraList | async">
                                <td class="multiselect-item-checkbox">
                                    <input type="checkbox" [attr.aria-label]="camera.id" [checked]="camera.isSelected" />
                                    <div (click)="onItemClick($event,camera)"></div>
                                </td>
                                <td>{{ camera.typeInfo.imei }}</td>
                                <td>
                                    <app-custom-select displayProp="label" valueProp="value"
                                        (valueChange)="openModal(camera)"
                                        [itemList]="assetAction"
                                        [isShowAction]="true">
                                    </app-custom-select>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="totalCameras > 0 && !isShowCheckBoxAndActionColumn && !isNetworkError && !isGetCameraError">
                            <tr *ngFor="let camera of unusedCameraList | async">
                                <td>{{ camera.typeInfo.imei }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="totalCameras === 0 && isSearchNoData && !isNetworkError && !isGetCameraError">
                            <tr class="no-hover">
                                <td [colSpan]=3>
                                    <div class="setting-page-no-data-block">
                                        <div class="setting-page-no-data-content">
                                            <span>
                                                No results found
                                            </span>
                                            <p>
                                                <br>Please modify your search and try again.
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="totalCameras === 0 && !isSearchNoData && !isNetworkError && !isGetCameraError">
                            <tr>
                                <td class="no-data-data-style" [colSpan]="3">
                                    No data available.
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="isNetworkError || isGetCameraError">
                            <tr class="no-hover">
                                <td [colSpan]=3>
                                    <div class="setting-page-no-data-block">
                                        <div class="setting-page-no-data-content">
                                            <span *ngIf="isNetworkError, else systemIssueTemplate">
                                                No network found
                                            </span>
                                            <ng-template #systemIssueTemplate>
                                                <span>
                                                    System issue
                                                </span>
                                            </ng-template>
                                            <p>
                                                <br>Unable to retrieve Coach data.
                                                <br>Please <a class="uc-refresh-link" href="../settings">refresh</a> to try again.
                                                <br><br>If the issue persists, please wait a few
                                                <br>minutes before reloading the data.
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="paginator-wrap">
                        <app-paginator3 #unassignedTablePaginator [pageLimit]="unusedCameraListLimit" [length]="totalCameras"
                            (pageChange)="getCameras($event)" [(reset)]="resetUnassignedCamerasTable"
                            (currentPageChange)="onCurrentUnassignedCamerasPageChange($event)">
                        </app-paginator3>
                    </div>
                </div>
                <!-- Table -->
            </div>
        </div>
    </div>
</div>

<ng-template #popTemplate let-cameraIMEI="cameraIMEI" let-assetId="assetId" let-assetNumber="assetNumber" let-index="index" let-directive="directive">
    <div class="pop-header">
        <button (click)="directive.hide()" class="close pull-right">
            <img src="assets/images/Close_X.svg" width="25px" height="25px"/>
        </button>

        <h5 class="title-header">
            Disconnect camera from asset?
        </h5>
    </div>
    <div class="pop-body">
        <app-spinner [showPartScreen]="true" *ngIf="(asyncFlag[activePage] && asyncFlag[activePage][index])">
            Processing Camera Unassociation...
        </app-spinner>
        <div class="popup-container">
            <div class="camera-section">
                <img src="assets/images/camera_circle.svg" class="size-img" />
                <span >{{cameraIMEI}}</span>
            </div>
            <div class="truck-section">
                <img src="assets/images/truck_circle.svg" class="size-img" />
                <span>{{assetNumber}}</span>
            </div>
        </div>
        <div class="popup-container-btn">
            <div class="btn-cancel-section">
                <button (click)="directive.hide()" class="btn btn-format btn-primary btn-cancel">Cancel</button>
            </div>
            <div class="btn-discard-section">
                <button class="btn btn-primary btn-discard btn-format"
                (click)="disassociateCameraFromAsset({assetId: assetId, cameraIMEI: cameraIMEI, index: index, pageNumber: activePage })">Disconnect</button>
            </div>
        </div>
    </div>
</ng-template>

<app-remove-camera-modal [componentName]="'remove-camera-modal'" [cameraList]="imeiList" (onCameraDelete)="onRemoveCamera()"></app-remove-camera-modal>
