import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API } from '../dashboard3.models';
import { map } from 'rxjs/operators';

export interface GeoCodeHttpResult {
  data: GeoCodeResult[];
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface LocationInfo {
  City?: string;
  CountryCode?: string;
  LongLabel?: string;
  MetroArea?: string;
  Postal?: string;
  Region?: string;
  Subregion?: string;
}

export interface GeoCodeResult extends LocationInfo {
  address: string;
  address2: string;
  altitude: number;
  country: string;
  district: string;
  external_id: number;
  full_address: string;
  locality: string;
  zipcode: string;
  error?: string;
  id?: string;
  tripId?: string;
  eventIndex?: string;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface Point {
  latitude: number;
  longitude: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  additional_payload?: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class ReverseGeoCodeService {
  public endPoint: string = API.REVERSE_GEOCODE;

  // This point is located on the sea so this is considered an unknown address
  public unknownAddress: Point = {
    latitude: 0,
    longitude: 0,
  };

  constructor(private _http: HttpClient) {}

  /**
   * @description: method to reverse geocode for single point
   * @param: lat and long
   * @returns: promise of a geocode result
   */
  public reverseSinglePoint(lat: number, long: number): Promise<GeoCodeResult> {
    const options = {
      params: this._buildReverseSinglePointParams(lat, long),
    };

    return this._http
      .get<GeoCodeHttpResult>(this.endPoint, options)
      .pipe(map((result) => result.data[0]))
      .toPromise();
  }

  /**
   * @description: method to reverse geocode for multiple points
   * @param: list of lat long values
   * @returns: promise of a list of geocode result
   */
  public reverseMultiplePoint(points: Point[]): Promise<GeoCodeResult[]> {
    if (points && points.length > 0){
      const checkedPoints = points.map(point => (!point || !point.latitude || !point.longitude) ? { ...this.unknownAddress } : point);
      const body = this._buildReverseMultiplePointParams(checkedPoints);
      return this._http
        .post<GeoCodeHttpResult>(this.endPoint, body)
        .pipe(map((result) => result.data))
        .toPromise();
    } else {
      return Promise.resolve([]);
    }
  }

  /**
   * @description: method to build http params for single reverse request
   * @param: lat and long
   * @returns: http param object
   */
  private _buildReverseSinglePointParams(lat: number, long: number): HttpParams {
    return new HttpParams()
      .set('latitude', lat.toString())
      .set('longitude', long.toString());
  }

  /**
   * @description: method to build http params for multiple reverse request
   * @param: list of lat long values
   * @returns: http param object
   */
  private _buildReverseMultiplePointParams(points: Point[]): HttpParams {
    return new HttpParams().set('points', JSON.stringify(points));
  }
}
