import {
  Component,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention
const DutyTypeRangeList = [
  { title: 'All Duty Types', value: 'all' },
  { title: 'Light Duty', value: 'light' },
  { title: 'Medium Duty', value: 'medium' },
  { title: 'Heavy Duty', value: 'heavy' },
];

@Component({
  selector: 'app-duty-type-filter',
  templateUrl: './duty-type-filter.component.html',
  styleUrls: ['./duty-type-filter.component.scss'],
})
export class DutyTypeFilterComponent implements OnInit, OnChanges {
  @Input()
  public dutyType;
  @Input()
  public isExportSet: boolean;

  @Output()
  public dutyTypeChange = new EventEmitter<number>();
  @Output()
  public applyChanges = new EventEmitter<void>();
  @Output()
  public discardChanges = new EventEmitter<void>();

  public isOpen = false;
  public dutyTypeRangeList = DutyTypeRangeList;
  public displayText = '';
  public selectedDutyType = '';
  public zuiFormGroup: FormGroup = this.formBuilder.group({});

  constructor(
    public formBuilder: FormBuilder
  ) {}

  public ngOnInit() {
    this.zuiFormGroup.addControl('currentDutyType', new FormControl());
    this._convertDutyType();
    this.zuiFormGroup.controls.currentDutyType.patchValue(this.dutyType);
    this.zuiFormGroup.controls.currentDutyType.valueChanges.pipe(
      tap(res => this.selectDutyType(res))
    ).subscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.dutyType) {
      if (
        changes.dutyType.currentValue ||
        changes.dutyType.currentValue === ''
      ) {
        this._setToInitialValue();
      }
    }
  }

  /**
   * @description: getting the selected duty type value from the user input duty type
   * @param: selected duty type
   *
   */
  public selectDutyType(val) {
    const dutyValue = (val === 'all') ? '' : val;
    this.selectedDutyType = val;
    this.dutyTypeChange.emit(dutyValue);
    this._closeFilter();
  }

  // Helpers
  public applyFilter() {
    this._closeFilter();
    this.applyChanges.emit();
  }

  /**
   * @description: toggle the duty type dropdown based on user click
   * @param: the user click event
   */
  public toggleFilter(e?: Event) {
    if (this.isOpen) {
      this._setToInitialValue();
      this._closeFilter();
    } else {
      this._openFilter();
    }
    if (e) {
      e.stopPropagation();
    }
  }

  // Close the filter when clicked outside the filter and set all the values to
  // initial values before modifying
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      if (this.isOpen) {
        this.discardChanges.emit();
      }
      this._setToInitialValue();
      this._closeFilter();
    }
  }

  // Set the values on the filters according to the duty type selected
  private _setToInitialValue() {
    this._convertDutyType();
    this.selectedDutyType = this.dutyType;
    const dutyType = this.dutyTypeRangeList.find((ele) => ele.value === this.dutyType);
    this.displayText = dutyType.title || '';
  }

  private _closeFilter() {
    this.isOpen = false;
  }

  private _openFilter() {
    this.isOpen = true;
  }

  private _convertDutyType() {
    this.dutyType = (this.dutyType === '') ? 'all' : this.dutyType;
  }
}
