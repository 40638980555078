export const environment = {
  production: false,
  auth: {
    clientID: 'VlfbPVHEZzp6RhBRBA9XGFKAdbDWcMsi',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    redirect: 'https://coach-web.dev.zonarsystems.net/callback',
    scope: 'openid profile email offline_access',
  },
  base_url: 'https://coach-web.dev.zonarsystems.net',
  intermediate_url: 'https://coach-server.dev.zonarsystems.net',
  logout_url: 'https://gtc4dev-cloud.zonarsystems.net/legacy?url=%2Fdashboard',
  version: 'v4.11.0-alpha-429f5fd3',
  feature_flags: {
    driverFilterFallbackToLM: true,
    driverFilterAlwaysUseLM: false,
    distractionEvent: true,
    'incident-response-center': false,
    'asset-snapshot': true,
    showAssetSnapshotTables: true,
    'smart-fleet-dvr-capture': true,
    'show-disputed-smart-fleet': true,
  },
  dvc_sdk_key_client: 'dvc_client_f5de61e3_45cf_43a9_b245_9e09b0a80da0_cedef86',
};
