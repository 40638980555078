import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { NAVIGATION_ITEMS } from '@app-core/constants/constants';

@Component({
  selector: 'app-coach-layout',
  templateUrl: './coach-layout.component.html',
  styleUrls: ['./coach-layout.component.scss'],
})
export class CoachLayoutComponent implements OnInit {
  public hideFooter = false;

  public navigationItems = [];

  public navUseFullWidth = false;

  public navHidden = false;

  constructor(private _router: Router) {}

  public ngOnInit(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof RoutesRecognized) {
        this.hideFooter = event.state.root.firstChild.data['hideFooter'];

        this.navigationItems = event.state.root.firstChild.data['navDashboard']
          ? NAVIGATION_ITEMS.dashboardItem : NAVIGATION_ITEMS.configurationItem;

        this.navUseFullWidth = event.state.root.firstChild.data['navUseFullWidth'];

        this.navHidden = event.state.root.firstChild.data['navHidden'];
      }
    });
  }
}
