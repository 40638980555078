import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[appTooltip]',
  providers: [MatTooltip],
})
export class TooltipDirective {
  @Input() public toolTipClass: string;
  @Input() public toolTipPosition: TooltipPosition;

  constructor(private _tooltip: MatTooltip, private _el: ElementRef) { }

  @HostListener('mouseover') public mouseover(): void {
    const element = this._el.nativeElement;
    if (element.clientWidth < element.scrollWidth) {
      this._tooltip.tooltipClass = this.toolTipClass;
      this._tooltip.position = this.toolTipPosition;
      this._tooltip.message = element.innerHTML;
      this._tooltip.show();
    }
  }

  @HostListener('mouseleave') public mouseleave(): void {
    this._tooltip.hide();
  }
}
