import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { UnconnectedTripsParamIS } from '@modules/unconnected-trips/common/unconnected-trip.model';

import { API } from '@app-core/constants/api.constants';

import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class UnconnectedTripsService {
  constructor(private _http: HttpClient) {}

  /**
   * @description: Creating request object for GET requests
   * @param: params
   * @method: GET
   * @returns anomalous trip data
   */
  public getAnomalousTrips(param: UnconnectedTripsParamIS): Observable<any> {
    return this._getData(API.GET_ANOMALOUS_TRIPS, param);
  }

  /**
   * @description: Creating request object for GET requests
   * @param params
   */
  private _setParams(params) {
    return new HttpParams({
      fromObject: params,
    });
  }

  /**
   * @description: Creating request object for GET requests
   * @param: params
   * @method: GET
   * @returns: data object
   */
  private _getData(url: string, params: any, options: any = {}) {
    const op = {
      params: this._setParams(params),
      ...options,
    };
    return this._http.get(url, op).pipe(retry(3));
  }
}
