<div>
  <div class="leaderboard-content">
    <div class="row leadboard">
      <div class="col-12 col-sm-6 col-lg-3 leadboard-card-wrap">
        <ng-container *ngTemplateOutlet="displayCurrentScoreAsync"></ng-container>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 leadboard-card-wrap">
        <app-top-incident
          [totalIncident$]="totalIncident$"
          [topIncidentData$]="topIncidentData$"
          [range]="dateFilter$ | async"
          [reload$]="reload$"
        ></app-top-incident>
      </div>
      <div class="col-12 col-sm-12 col-lg-6 leadboard-card-wrap">
        <div
          class="leadboard-card leadboard-card-fleet"
          data-test="fleet-overview-card"
        >
          <div class="leadboard-card-head">
            <div class="driver-name">Key Metrics</div>
          </div>
          <div class="leadboard-card-body">
            <div class="first_col mediumscreen fleet-overview-first-column">
              <div class="fleet_overclo">
                <ng-container *ngTemplateOutlet="displayFleetAsync; context: {index: 0, value: 'Total Miles Driven'}"></ng-container>
              </div>
              <div class="fleet_overclo second_overclo">
                <ng-container *ngTemplateOutlet="displayFleetAsync; context: {index: 1, value: 'Incidents/100mi'}"></ng-container>
              </div>
            </div>
            <div class="first_col second_mediumscreen fleet-overview-second-column">
              <div class="fleet_overclo">
                <ng-container *ngTemplateOutlet="displayFleetAsync; context: {index: 2, value: 'Total Duration (hrs)'}"></ng-container>
              </div>
              <div class="fleet_overclo second_overclo">
                <ng-container *ngTemplateOutlet="displayFleetAsync; context: {index: 3, value: 'Incidents/Hour'}"></ng-container>
              </div>
            </div>
            <div class="second_mediumscreen fleet-overview-third-column">
              <div class="fleet_overclo">
                <ng-container *ngTemplateOutlet=" displayFleetAsync; context: {index: 4, value: 'Total Incidents'}"></ng-container>
              </div>
              <div class="fleet_overclo second_overclo">
                <ng-container *ngTemplateOutlet=" displayFleetAsync; context: {index: 5, value: 'Total Trips'}"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="leaderboard-content">
    <div class="row leadboard">
      <div class="col-12 leadboard-card-wrap">
        <div
          class="leadboard-card leadboard-card-graph"
          data-test="driver-score-distribution-card"
        >
          <ng-container *ngTemplateOutlet="displayDriverScoreDistribution"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Template for driver score distribution -->
<ng-template #displayDriverScoreDistribution>
  <div class="scoreheading">Driver Score Distribution</div>
  <ng-container *ngIf="chartOptions$ | async as chartOptions; else skeletonDriverScoreDistribution">
    <highcharts-chart
      [Highcharts]="highcharts"
      [options]="chartOptions"
      [(update)]="updateChart"
      [oneToOne]="true"
    ></highcharts-chart>
    <div *ngIf="drivercount !== 0 && tripcount !== 0"
      class="view-link card_viewlink graph-view"
      (click)="goToFleetMetrics()">
      <span>View Details</span>
    </div>
  </ng-container>
</ng-template>
<!-- Template for fleet overview -->
<ng-template #displayFleetAsync let-index="index" let-value="value">
  <div class="fleet_total_text">{{value}}</div>
  <ng-container *ngIf="internalValues$ | async as values , else skeletonLoadingFleetOverview">
    <ng-container *ngIf="!isLongText(values[index] | number: '1.0-2'), else showValueByTooltip">
      <div class='fleet_total_number'>{{values[index] | number: '1.0-2'}}</div>
    </ng-container>
    <ng-template #showValueByTooltip>
        <div class="fleet_total_number fleet-overview-row-ellipsis"
        #tooltip="matTooltip"
        matTooltip="{{values[index] | number: '1.0-2'}}"
        matTooltipClass="fleet-performance-tooltip"
        [matTooltipPosition]="divisionTooltipPosition"
        >
          {{values[index] | number: '1.0-2'}}
        </div>
    </ng-template>
  </ng-container>
</ng-template>

<!-- Template for Current Score-->
<ng-template #displayCurrentScoreAsync>
  <div class="leadboard-card">
    <div class="leadboard-card-head">
      <div class="driver-name" data-test="current-score-card-header">
        Current Score
      </div>
    </div>
    <div class="leadboard-card-body">
      <div class="center">
        <ng-container *ngIf="internalPerformanceScore$ | async as value , else skeletonLoadingCurrentScore">
          <div *ngIf="drivercount !== 0 && tripcount !== 0, else displayCurrentScoreNoData">
            <ng-container *ngTemplateOutlet="displayPerformanceScore; context: {data: value}"></ng-container>
            <ng-container *ngTemplateOutlet="displayPerformanceDifference;context: {data: value}"></ng-container>
            <div
            (click)="goToFleetMetrics()"
            class="view-link card_viewlink"
            data-test="current-score-card-view-detail-button"
            >
              <span>View Details</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #displayCurrentScoreNoData>
  <div class="current-score-no-data">-</div>
  <p class="current-score-no-data-message">No trips in this time period</p>
  <div
    (click)="goToFleetMetrics()"
    class="view-link current-score-card-viewlink"
    data-test="current-score-card-view-detail-button"
    >
      <span>View Details</span>
  </div>
</ng-template>

<ng-template #displayPerformanceScore let-value="data">
  <div
    class="average-score"
    data-test="current-score-card-score-label"
    [class.text-green]="value.score >= 85"
    [class.text-orange]="
      value.score > 69 && value.score <= 84
    "
    [class.text-red]="value.score <= 69"
  >
      {{ value.score }}
  </div>
</ng-template>

<ng-template #displayPerformanceDifference let-value="data">
  <ng-container *ngIf="showRange ,else notShowRange">
    <div class="score-label">
      {{ value.difference > 0 ? '+' : ''
      }}{{
        value.difference === 0
          ? 'No Change in'
          : value.difference
      }}
      points from {{ displayStartDate | date: 'M/dd':'UTC' }} -
      {{ displayEndDate | date: 'M/dd':'UTC' }}
    </div>
  </ng-container>
  <ng-template #notShowRange>
    <div class="score-label">
      {{ value.difference > 0 ? '+' : ''
      }}{{
        value.difference === 0
          ? 'No Change in'
          : value.difference
      }}
      points since {{ range?.from | date: 'M/dd' }}
    </div>
  </ng-template>
</ng-template>

<!-- loading skeleton for fleet overview-->
<ng-template #skeletonLoadingFleetOverview>
  <div class="fleet-overview-loading">
    <div class="fleet-overview-skeleton">
      <app-custom-skeleton-loader
          [theme]="{
            width: '134px',
            height: '40px'
          }">
      </app-custom-skeleton-loader>
    </div>
  </div>
</ng-template>

<!-- loading skeleton for current score -->
<ng-template #skeletonLoadingCurrentScore>
  <div class="loading-current-score">
    <div class="loading-performance-score">
      <app-custom-skeleton-loader
        [theme]="{
          width: '211px',
          height: '92px'
        }">
      </app-custom-skeleton-loader>
    </div>
    <div class="loading-performance-difference">
      <app-custom-skeleton-loader
      [theme]="{
        width: '211px',
        height: '36px'
      }">
      </app-custom-skeleton-loader>
    </div>
  </div>
  <div
    class="no-view-link card_viewlink"
    data-test="current-score-card-view-detail-button"
  >
    <span>View Details</span>
  </div>
</ng-template>

<!-- loading skeleton for drive score distribution-->
<ng-template #skeletonDriverScoreDistribution>
  <div class="drive-score-distribution-loading">
    <app-custom-skeleton-loader
      [theme]="{
        width: '1312px',
        height: '180px'
      }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>
