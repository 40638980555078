import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-menu-option',
  templateUrl: './menu-option.component.html',
  styleUrls: ['./menu-option.component.scss'],
})
export class MenuOptionComponent {
  @Input() public options = [];
  @Input() public showMeuOption = false;

  @Output() public clickOption: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public onClickOption(option) {
    const formatOption = option.toLowerCase();
    this.clickOption.emit(formatOption);
  }

}
