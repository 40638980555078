import { Component } from '@angular/core';

@Component({
  selector: 'app-two-columns-layout',
  templateUrl: './two-columns-layout.component.html',
  styleUrls: ['./two-columns-layout.component.scss'],
})
export class TwoColumnsLayoutComponent {
  // Query for marked templates as a list of TemplateRefs.
}
