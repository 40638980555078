import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { ListItem } from '@modules/dashboard3/dashboard3.models';
import { StorageService } from '@app-core/services/storage.service';
import { FilterChangeEvent, LOCATION_FILTER } from '@app-core/constants/constants';
import { HomeLocationService } from '@app-core/services/home-location.service';

export interface Locations {
  locationId: string;
  locationName: string;
}

export interface SelectedLocation {
  id: string;
  name: string;
}

const DEFAULT_LOCATION_SETTINGS = {
  dropdownPlaceholder: 'Locations',
  selectAllText: 'All locations',
  selectedItemText: 'locations',
  isSelectAll: undefined,
  isLocationFilter: true,
};

const FULL_DIVISIONS_PATH_NAME = [
  '/asset-view',
  '/dashboard',
  '/incident-response-center',
  '/fleet-trends',
  '/driver-detail',
];

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit {
  @Input()
  public reset: Observable<void>;
  @Input()
  public homeLocation = '';
  @Input()
  public isExportSet: boolean;
  @Input()
  public isHomeLocationEditable = true;
  @Input() public locationSettings = DEFAULT_LOCATION_SETTINGS;
  @Input() public showHeader = true;
  @Output()
  public applyChanges = new EventEmitter<FilterChangeEvent>();
  @Output()
  public discardChanges = new EventEmitter<void>();
  @Output()
  public homeLocationChange = new EventEmitter<string[]>();

  public get isGetFullDivisions(): boolean {
    return FULL_DIVISIONS_PATH_NAME.includes(window.location.pathname);
  }

  public get locationStorage() {
    return this.isGetFullDivisions ? 'HOME_LOCATION_ABV' : 'HOME_LOCATION';
  }

  public get prevLocationStorage() {
    return this.isGetFullDivisions ? 'PREVIOUS_HOME_LOCATION_ABV' : 'PREVIOUS_HOME_LOCATION';
  }

  public isOpen = false;
  public isLoading = true;  // skeleton
  public showApply = false;
  public isDropdownOpen = false;
  public homeLocationList = [];
  public selectedHomeLocation = '';
  public displayHomeLocation = 'All Locations';
  public showHomeLocationDropdown = false;
  public uniqueHomeLocationList: Set<any>;  // The Set object lets you store unique values of any type
  public locationSelectedList: SelectedLocation[] = [];
  public divisionList: ListItem[] = [];
  public forceReload = false;

  private _isRemoveHomeLocationClicked = false;

  constructor(
    private _homeLocationService: HomeLocationService,
    private _storageService: StorageService,
    private _router: Router
  ) {}

  public ngOnInit() {
    // Subscribe for the reset subscription
    if (this.reset) {
      this.reset.subscribe((event: void | boolean) => {
        this._setAllToInitial();
        if (event) {
          this._getHomeLocations();
        }
      });
    }
    this._getHomeLocations();
    this.showHomeLocationDropdown = true;
  }

  public getSelectedHomeLocation(homeLocation: Locations[]) {
    if (this.isGetFullDivisions) {
      this.selectedHomeLocation = homeLocation && homeLocation.length ?
        JSON.stringify(homeLocation.map(item => item.locationId)) : null;
    } else {
      // TO-DO: To be removed
      if (homeLocation && homeLocation.length) {
        this.selectedHomeLocation = homeLocation.length === this.uniqueHomeLocationList.size ?
          '' : JSON.stringify(homeLocation.map(item => item.locationId));
      } else {
        this.selectedHomeLocation = null;
      }
    }

  }

  /**
   * create an object of FilterChangeEvent to indicate the status of location filter
   */
  public onFilterChange(isOpen = true) {

    // Only backup the dropdown selected list
    if (!isOpen) {
      this.backupCurrentLocations();
      return;
    }

    const event: FilterChangeEvent = {
      filterName: LOCATION_FILTER,
      isChanged: this._isLocationFilterChanged(),
    };
    this.applyChanges.emit(event);
  }

  public submitHomeLocation() {
    const selectedHomeLocation: string[] = [];
    let selectedLocations: string[];
    if (!this.isExportSet) {
      if (this.isGetFullDivisions) {
        selectedLocations = JSON.parse(this.selectedHomeLocation);
      } else {
        selectedLocations = this.selectedHomeLocation === '' ? [] : JSON.parse(this.selectedHomeLocation);
      }

      if (selectedLocations?.length && this.selectedHomeLocation !== null) {
        selectedLocations.forEach(location => {
          selectedHomeLocation.push(location);
        });
      } else if (this.selectedHomeLocation === null) {
        selectedHomeLocation.push('null');
      }
    }
    if (this.isExportSet) {
      selectedHomeLocation.push(this.selectedHomeLocation);
      this.homeLocationChange.emit(selectedHomeLocation);
    } else {
      localStorage.setItem(
        this.locationStorage,
        JSON.stringify(selectedHomeLocation)
      );
    }
  }


  /**
   * @description: function to toggle filter based on user click
   * @param: type of event
   */
  public toggleFilter(e?: Event) {
    if (this.isOpen) {
      this._setAllToInitial();
      this._closeFilter();
    } else {
      this._openFilter();
    }
    if (e) {
      e.stopPropagation();
    }
  }

  // Backup current selected locations
  public backupCurrentLocations() {
    const currentHomeLocations = this._storageService.getStorageValue(this.locationStorage);
    const filterSelectedList = this.locationSelectedList.map(item => item.id);
    if (!filterSelectedList.length) { // set null if no value selected
      filterSelectedList.push('null');
    }
    this.forceReload = this._isLocationsChanged(filterSelectedList, currentHomeLocations);
    this._storageService.setStorageValue(this.prevLocationStorage, currentHomeLocations);
  }

  public onSelectLocation(event: SelectedLocation[]) {
    const selectedLocationList: Locations[] = [];

    if (event && event.length) {
      event.forEach(item => {
        const selectedLocation = this.homeLocationList.find(
          location => location.locationId === item.id
        );
        if (selectedLocation) {
          selectedLocationList.push(selectedLocation);
        }
      });
    }

    this.getSelectedHomeLocation(selectedLocationList);
    // apply filter
    this.submitHomeLocation();
  }

  /**
   * @description: check whether the click has been inside the dropdown body
   * @param: click inside boolean value
   */
  public clickedInside(isClickedInside) {
    if (!isClickedInside && !this._isRemoveHomeLocationClicked) {
      if (this.isOpen) {
        this.discardChanges.emit();
      }
      this._setAllToInitial();
      this._closeFilter();

      this._isRemoveHomeLocationClicked = false;
    }
  }

  public toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private _isLocationFilterChanged() {
    const previousList = this._storageService.getStorageValue(this.prevLocationStorage);
    const currentList = this._storageService.getStorageValue(this.locationStorage);
    return this._isLocationsChanged(previousList, currentList)
    || (!this._isLocationsChanged(previousList, currentList) && this.forceReload);
  }

  private _getHomeLocations() {
    this._homeLocationService.getHomeLocations().subscribe(
      (res) => {
        this._filterSelectedHomeLocationList(res && res.homeLocations);
        // sort home locations in ascending order
        this.homeLocationList = res.homeLocations
          .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this._getHomeLocationsSelected();
        this.divisionList = this.homeLocationList.map(({ locationId, locationName }) => ({ id: locationId, name: locationName }));

        if (localStorage.getItem(this.locationStorage) !== null) {
          const homeLocation = this._filterLocationInfoByLocationId(
            JSON.parse(localStorage.getItem(this.locationStorage))[0],
            this.homeLocationList
          );
          this.displayHomeLocation = (homeLocation[0] && homeLocation[0].locationName) || 'All Locations';
        }
        this.isLoading = false;
      },
      () => {
        console.log('[Location filter]: Error get Home Locations');
      }
    );
  }

  private _getHomeLocationsSelected() {
    this.uniqueHomeLocationList = new Set(this.homeLocationList);
    const storageLocations = this._storageService.getStorageValue(this.locationStorage);
    const isNull = JSON.stringify(storageLocations) === JSON.stringify(['null']);
    this._setLocationFilterPlaceHolder(storageLocations);
    if (storageLocations?.length && !isNull) {
      this.locationSelectedList = storageLocations.map(item => Object.assign({}, {id: item})).map(
        item => {
          const location = this.homeLocationList.find(locationItem => locationItem.locationId === item.id);
          return Object.assign(item, {name: location && location.locationName});
        }
      );
    } else if (isNull) {
      this.locationSelectedList = [];
    } else {
      this.locationSelectedList = this.homeLocationList.map(item => Object.assign({
        id: item.locationId,
        name: item.locationName,
      }));
    }
  }

  private _filterSelectedHomeLocationList(locationList: Locations[]) {
    const filteredHomeLocations = [];
    const locationIdList: string[] = locationList.map(item => item.locationId);
    const homeLocations: string[] = JSON.parse(localStorage.getItem(this.locationStorage));

    // Convert value from '[]' to full active divisions list and vice versa
    if (this.isGetFullDivisions && JSON.stringify(homeLocations) === '[]') {
      locationIdList.forEach(item => homeLocations.push(item));
      this._storageService.setStorageValue(this.locationStorage, homeLocations);
      this._reloadCurrentPage();
    } else if (!this.isGetFullDivisions && locationList.length === homeLocations.length) {
      this._storageService.setStorageValue(this.locationStorage, []);
      this._reloadCurrentPage();
    }

    // In case location filter can select multiple, and no location is selected
    // Or in case local storage home location empty
    if ((homeLocations && homeLocations[0] === 'null') || !homeLocations?.length) {
      return;
    }

    // Check mismatch data between list of location id we got from server and
    // list of location id stored in local storage
    homeLocations.forEach(item => {
      if (locationIdList.includes(item)) {
        filteredHomeLocations.push(item);
      }
    });

    // case when some selected divisions not available
    if (filteredHomeLocations.length !== homeLocations.length) {
      // set new local storage value for HOME_LOCATION
      localStorage.setItem(this.locationStorage, JSON.stringify(filteredHomeLocations));
      this._reloadCurrentPage();
    }
  }

  private _setLocationFilterPlaceHolder(locationList: any) {
    if (locationList) {
      const isSelectEmpty = locationList.length === 1 && locationList[0] === 'null';
      const isSelectAll = locationList.length === this.uniqueHomeLocationList.size || JSON.stringify(locationList) === '[]';
      if (isSelectEmpty) {
        this.locationSettings = Object.assign({
          dropdownPlaceholder: 'No Location Selected',
          selectAllText: 'All locations',
          selectedItemText: 'locations',
          isLocationFilter: true,
          isSelectAll,
        });
      } else if (isSelectAll) {
        this.locationSettings = Object.assign({
          dropdownPlaceholder: 'All Locations',
          selectAllText: 'All locations',
          selectedItemText: 'locations',
          isLocationFilter: true,
          isSelectAll,
        });
      } else {
        this.locationSettings = Object.assign(DEFAULT_LOCATION_SETTINGS);
      }
    }
  }


  private _filterLocationInfoByLocationId(id: string, locations: Locations[]): Locations[] {
    return locations.filter((location) => location.locationId === id);
  }

  private _setAllToInitial() {
    this.showApply = false;
    this.isDropdownOpen = false;
  }

  private _closeFilter() {
    this.isOpen = false;
  }

  private _openFilter() {
    this.isOpen = true;
    this.showApply = false;
  }

  private _reloadCurrentPage() {
    // reload the page using current url
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([this._router.url]);
  }

  private _isLocationsChanged(listPrevious: string[], listCurrent: string[]): boolean {
    return listPrevious.length !== listCurrent.length ?
      true : !!(listPrevious.find(item => !listCurrent.includes(item)));
  }
}
