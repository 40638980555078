import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';

import { IncidentStat } from './incident-stat.service';
import { DRIVER_INCIDENT_PIECHART_OPTION, INCIDENT_PIECHART_OPTION, SCORE_PIECHART_OPTION } from './pie-chart-options';

interface PieChartSeriesItem {
  data: { name: string; y: number}[];
  colors: string[];
}

type PieChartType = 'score' | 'total-incident';

/* eslint-disable @typescript-eslint/naming-convention */
const INCIDENT_COLOR_MAPPING = {
  Acceleration: '#8ba600',
  Braking: '#e67300',
  'Lane Drift': '#e5389d',
  Cornering: '#6134b1',
  Speeding: '#2a8596',
  Tailgating: '#814911',
  'Stop Sign': '#a82a89',
  Others: '#5b6770',
  'No Incidents': '#2d8ed9',
  Distraction: '#731042',
};
/* eslint-enable @typescript-eslint/naming-convention */

const INCIDENT_TYPE_ORDER = ['Acceleration', 'Braking', 'Lane Drift', 'Cornering'];
const DEFAULT_NO_INCIDENT_SLICE_INDEX = 4;

@Injectable({
  providedIn: 'root',
})
export class IncidentPieChartService {

  constructor() { }

  /**
   * Calculate pie options from provided arguments. Pie chart options has
   * 'Score' and title text
   *
   * @param incidentStatList List of incident statistic object
   * @param scoreValue Score value
   * @param totalIncident Total number of all incidents
   * @param defaultColor Color to be used when there's no color mapping for an incident type
   * @param [optionalColorMapper={}] Optional color mapper, overwrite default one
   * @return Pie chart options object
   */
  public buildScorePieChartOptions(incidentStatList: IncidentStat[],
    scoreValue: number, totalIncident: number,
    defaultColor: string, optionalColorMapper: Record<string, string> = {}) {
    const chartData = this._calculateChartData('score', incidentStatList, totalIncident, optionalColorMapper, defaultColor);
    return this._buildScorePieChartOptions(SCORE_PIECHART_OPTION, chartData, scoreValue, totalIncident);
  }

  /**
   * Calculate pie options from provided arguments. Pie chart options has
   * 'Total Incidents' and title text
   *
   * @param incidentStatList List of incident statistic object
   * @param totalIncident Total number of all incidents
   * @param defaultColor Color to be used when there's no color mapping for an incident type
   * @param [optionalColorMapper={}] Optional color mapper, overwrite default one
   * @return Pie chart options object
   */
  public buildIncidentPieChartOptions(incidentStatList: IncidentStat[],
    totalIncident: number, defaultColor: string, useCustom: boolean = false,
    optionalColorMapper: Record<string, string> = {}) {
    const chartData = this._calculateChartData('total-incident', incidentStatList, totalIncident, optionalColorMapper, defaultColor);
    return this._buildScorePieChartOptions(
      useCustom ? DRIVER_INCIDENT_PIECHART_OPTION : INCIDENT_PIECHART_OPTION,
      chartData,
      totalIncident,
      totalIncident
    );
  }

  private _calculateChartData(pieChartType: PieChartType, incidentStatList: IncidentStat[], totalIncident: number,
    optionalColorMapper: Record<string, string>, defaultColor: string) {
    const colorMapper = { ...INCIDENT_COLOR_MAPPING, ...optionalColorMapper };
    const chartList = totalIncident === 0
      ? this._buildNoIncidentPieChartList(pieChartType, INCIDENT_TYPE_ORDER, DEFAULT_NO_INCIDENT_SLICE_INDEX)
      : incidentStatList;
    return this._buildChartDataFromIncidentList(chartList, colorMapper, defaultColor);
  }

  private _buildNoIncidentPieChartList(pieChartType: PieChartType,
    incidentTypeList: string[],
    sliceIndex: number): { name: string; value: number}[] {
    // Display pie chart with 4 color, each color take the same size
    if (pieChartType === 'total-incident') {
      return incidentTypeList.slice(0, sliceIndex).map(item => ({
        name: item,
        value: 1,
      }));
    }

    // Display one color pie chart
    return [{name: 'No Incidents', value: 1}];
  }

  private _buildChartDataFromIncidentList(
    incidentList: IncidentStat[],
    colorMapper: Record<string, string>,
    defaultColorCode: string): PieChartSeriesItem {
    const totalIncidentText = 'Total Incidents';

    return (incidentList || []).reduce((acc, curr) => {
      if (curr.name === totalIncidentText) {
        return acc;
      }
      return {
        data: [...acc.data, { name: curr.name, y: curr.value }],
        colors: [...acc.colors, colorMapper[curr.name] || defaultColorCode],
      };
    }, { data: [], colors: [] });
  }

  /**
   * Build pie chart options based on provided arguments.
   *
   * Depends on pieChartType, the pie chart options can be either for
   * 'Score' pie chart type of 'Total Incidents' pie chart, the different
   * is mostly in the pie chart main title value and subtitle value.
   *
   * @param pieChartType Either 'score' or 'total-incidents'
   * @param defaultPieChartOptions Default pie chart options, the method doesn't mutate this
   * @param chartData Pie chart series data
   * @param titleValue Value (in number) of the main title
   * @param totalIncident Value of total incidents, if <= 0 will disable to tooltip
   * @return Pie chart options object
   */
  private _buildScorePieChartOptions(
    defaultPieChartOptions,
    chartData: PieChartSeriesItem,
    titleValue: number,
    totalIncident: number) {
    const clonedChartOptions = cloneDeep(defaultPieChartOptions);

    clonedChartOptions.tooltip.enabled = totalIncident > 0;
    clonedChartOptions.title.text = String(titleValue);
    clonedChartOptions.series[0].data = chartData.data;
    clonedChartOptions.series[0].colors = chartData.colors;

    return clonedChartOptions;
  }
}
