import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

import { DefaultInputModelV2, SearchFilterModel } from './../../../dashboard3.models';
import { Data } from '@modules/dashboard3/services/data.service';
import { GoogleTagManagerService } from '@app-core/services/google-tag-manager.service';

@Component({
  selector: 'app-leadboard',
  templateUrl: './leadboard.component.html',
  styleUrls: ['./leadboard.component.scss'],
})
export class LeadboardComponent implements OnDestroy {
    public leadboardList = [];

    public leadboardAsc = false;

    public show = 'loading';

    public filterOptions: SearchFilterModel;

    public errorMessage = 'Something went wrong';
    public showRetryButton = true;
    public homeLocationList = [];

    private _filterChangeSubscription: any;

    constructor(
        private _zcfleet: ZCFleetService,
        private _dateService: DateService,
        private _loading: LoadingService,
        private _router: Router,
        private _data: Data,
        private _gtmService: GoogleTagManagerService
    ) {
      this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(filterOptions => {
        this.filterOptions = filterOptions;
        this.getData();
      });
    }

    public ngOnDestroy() {
      // Unsubscribe from filter change event
      if (this._filterChangeSubscription) {
        this._filterChangeSubscription.unsubscribe();
      }
    }

    public getData() {
      const { days, dutyType, minScore, maxScore } = this.filterOptions;
      let date = { from: null, to: null };
      if (days === 2) {
        date.from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
        date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
        if (this._dateService.customStartdate === undefined || this._dateService.customEndDate === undefined) {
          date = this._data.customRange.data;
          this._dateService.customStartdate = this._data.customRange.data.from;
          this._dateService.customEndDate = this._data.customRange.data.to;
        }
      } else {
        date = this._dateService.getDateRangeInISO(days);
      }
      this.homeLocationList = JSON.parse(localStorage.getItem('HOME_LOCATION'));
      const params = new DefaultInputModelV2(date.from, date.to, dutyType, minScore, maxScore, 0, 0, '', '', this.homeLocationList);

      // Trend
      const trendsParams = {
        params: { ...params, count: 8 },
      };

      this.leadboardAsc = false;

      this._loading.show();
      this.show = 'loading';
      this._zcfleet.getFleetDriverCards(trendsParams).subscribe(
        res => {
          this._loading.hide();
          this.leadboardList = res;
          this.toggleLeaderboardOrder();
          this.show = this.leadboardList.length !== 0 ? 'data' : 'empty';
        },
        err => {
          console.error(`Received Error: ${err}`);
          const errorHanding = this._zcfleet.getErrorMessage(err);
          this.errorMessage = errorHanding.message;
          this.showRetryButton = errorHanding.showRetryButton;
          this._loading.hide();
          this.show = 'error';
        }
      );
    }

    public gotoDriverDetail(driver) {
      if (driver && driver.driverId) {
        const {
          driverId = '',
          driverName = '',
        } = driver;
        this._gtmService.driverCardClick({ driverName, driverId });
        // Set driver name
        // this._data.driverName = driver.driverName;
        this._router.navigate(['/driver-detail'], {
          queryParams: {
            driverId,
            driverName,
          },
        });
      }
    }

    // Toggle leaderboard order to Asc or Desc and reverse the array
    public toggleLeaderboardOrder() {
      this.leadboardAsc = !this.leadboardAsc;
      this.leadboardList.reverse();
    }
}
