/* eslint-disable @typescript-eslint/explicit-member-accessibility */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { environment } from '@env/environment';
import { EnvironmentFlagSourceService } from '@modules/feature-flag/services/environment-flag-source.service';
import { FeatureFlagService } from '@modules/feature-flag/services/feature-flag.service';

export class API {
  public static BASE_URL = environment.intermediate_url;
  public static VERSION = '/v5';
  public static PATH = `/api${API.VERSION}`;

  public static DRIVERS = `${API.BASE_URL}/drivers`;
  public static DRIVERS_V2 = `${API.BASE_URL}/v2/drivers`;
  public static DRIVERS_PROFILE_ENTITY = `${API.BASE_URL}/driveruserprofiles`;
  public static CHALLENGES = `${API.BASE_URL}/challenges`;
  public static REPORTBUG = `${API.BASE_URL}/report-bug`;
  public static FLEET_RIDECAM_STATUS = `${API.BASE_URL}/fleet-ridecam-status`;
  public static FLEET_STATS = `${API.BASE_URL}/stats`;
  public static FLEET_STATS_V2 = `${API.BASE_URL}/v2/stats`;
  public static FLEET_STATS_PERFORMANCE = `${API.BASE_URL}/fleetperformance`;
  public static TREND_V2 = `${API.BASE_URL}/v2/trend`;
  public static FLEET_DRIVERS_TREND_CARD = `${API.BASE_URL}/v2/drivers/trend`;
  public static DRIVERS_SCORE = `${API.BASE_URL}/drivers/score`;
  public static DRIVERS_INCIDENTS = `${API.BASE_URL}/drivers/incidents`;
  public static TRIPS = `${API.BASE_URL}/trips`;
  public static CAPTURE_DVR_CLIP = `${API.BASE_URL}/create-dvr-request`;
  public static E_DVR_REQUEST = `${API.BASE_URL}/create-e-dvr-request`;
  public static E_DVR_LIST = `${API.BASE_URL}/edvr-requests`;
  public static VIOLATIONS_AGGREGATE = `${API.BASE_URL}/violations-aggregate`;
  public static DUTY_TYPE_CONFIG = `${API.BASE_URL}/configuration`;
  public static ASSETS = `${API.BASE_URL}/assets`;
  public static ASSETS_BY_TRIPS = `${API.BASE_URL}/assets/by-trips`;

  public static DEVICES = `${API.BASE_URL}/devices`;
  public static REVERSE_GEOCODE = `${API.BASE_URL}/reverse-geocode`;
  public static LIST_CAMERA_IMEI = `${API.BASE_URL}/cameras`;
  public static ASSET_DEVICES = `${API.BASE_URL}/assetdevices`;

  public static DRIVER_LOCATION = (driverId) =>
    `${API.BASE_URL}/home-location/${driverId}`;
  public static DRIVER_LOCATION_ENTITY = (userProfileId) =>
    `${API.BASE_URL}/locations/by-user-profile-id/${userProfileId}`;
  public static HOME_LOCATION = `${API.BASE_URL}/home-locations`;
  public static HOME_LOCATION_ENTITY = `${API.BASE_URL}/divisions-by-fleet-id-entity`;
  public static GET_ALL_ASSETS = `${API.BASE_URL}/all-assets`;

  public static SAVED_INCIDENT = `${API.BASE_URL}/saved-incidents`;
  public static CONFIGURATION_VIOLATION = `${API.BASE_URL}/violations/configurations`;

  public static UPDATE_CHALLENGES = (driverId, tripId, eventIndex) =>
    `${API.CHALLENGES}/${driverId}/${tripId}/${eventIndex}`;
  public static REPORT_BUG = (driverId, tripId, eventIndex) =>
    `${API.REPORTBUG}/${driverId}/${tripId}/${eventIndex}`;
  public static DRIVERS_INCIDENTS_CHANGE = (driverId) =>
    `${API.DRIVERS}/${driverId}/incidents/change`;
  public static DRIVERS_STATS = (driverId) =>
    `${API.DRIVERS}/${driverId}/stats`;
  public static DRIVERS_STATS_V2 = (driverId) =>
    `${API.DRIVERS_V2}/${driverId}/stats`;
  public static DRIVER_TRIP_LIST = (driverId) =>
    `${API.DRIVERS}/${driverId}/trips`;
  public static TRIP_DETAILS = (driverId, tripId) =>
    `${API.DRIVERS}/${driverId}/trips/${tripId}`;
  public static DRIVERTRAND_DETAILS = `${API.BASE_URL}/v2/driverscoretrend`;

  public static UPLOAD_CAMERA_FROM_FILE = `${API.BASE_URL}/cameras/file`;

  public static COMMENTS_BY_VIOLATION_IDENTIFIER = `${API.BASE_URL}/violations/query-comments`;
  public static BOOKMARKS_BY_VIOLATION_IDENTIFIER = `${API.BASE_URL}/violations/metadata/status`;
  public static ONE_TIME_TOKEN = `${API.BASE_URL}/one-time-token`;

  public static ASSET_ID_INFO = (assetId) =>
    `${API.BASE_URL}/assets/${assetId}/entity`;

  public static ASSET_AGGREGATE = (assetId) =>
    `${API.BASE_URL}/assets/${assetId}/aggregate`;

  public static ASSET_TRIPS_LIST = (assetId) =>
    `${API.BASE_URL}/assets/${assetId}/trips`;

  public static ASSET_DETAIL_BY_ASSET_ID = (assetId) =>
    `${API.BASE_URL}/assets/${assetId}`;

  public static LIST_ASSET_VIOLATIONS = (assetId) =>
    `${API.BASE_URL}/assets/${assetId}/violations`;

  public static USER_INFO_FROM_USER_PROFILE_ID = (userProfileId) =>
    `${API.BASE_URL}/users/by-user-profile-id/${userProfileId}`;
  public static USER_INFO_MAP_FROM_MULTIPLE_USER_PROFILE_IDS = `${API.BASE_URL}/users/by-user-profile-ids`;

  public static UPDATE_TRIP_METADATA = (violationIdentifier) =>
    `${API.BASE_URL}/violations/${violationIdentifier}/metadata`;

  public static STATUS_BOOKMARK_VIOLATION = (violationIdentifier) =>
    `${API.BASE_URL}/violations/${violationIdentifier}/metadata/status`;

  public static COMMENT_VIOLATION = (violationIdentifier) =>
    `${API.BASE_URL}/violations/${violationIdentifier}/comments`;

  public static COMMENT_VIOLATION_STATUS = (violationIdentifier) =>
    `${API.BASE_URL}/violations/${violationIdentifier}/comments/status`;

  public static EXPORT_SAVED_INCIDENT = (format) =>
    `${API.BASE_URL}/saved-incidents/download/${format}`;

  public static EXPORT_DISPUTED_INCIDENT = (format) =>
    `${API.BASE_URL}/disputed-incidents/download/${format}`;
}

export const EVENT_NAMES_MAP = {
  total: 'Total Incidents',
  Cornering: 'Cornering',
  'Harsh-Acceleration': 'Acceleration',
  'Harsh-Braking': 'Braking',
  'Lane-Drift-Found': 'Lane Drift',
  'Tail-Gating-Detected': 'Tailgating',
  'Traffic-STOP-Sign-Violated': 'Stop Sign',
  'Traffic-Speed-Violated': 'Speeding',
  Anomaly: 'Anomaly',
  Calibration: 'Camera Install Test',
  'Video-Capture': 'Driver Captured Incident',
  'Distracted-Driving': 'Distraction',
  'Other-Event': 'Other Event',
};

export const distractionFlag = new FeatureFlagService(
  new EnvironmentFlagSourceService()
).isFlagSet('distractionEvent');
if (!distractionFlag) {
  delete EVENT_NAMES_MAP['Distracted-Driving'];
}

export const EVENTS_COLOR_MAP = {
  Cornering: '#ff4078',
  'Harsh-Acceleration': '#c8c9ce',
  'Harsh-Braking': '#ffa41a',
  'Lane-Drift-Found': '#fbdb43',
  'Tail-Gating-Detected': '#3fc3c2',
  'Traffic-STOP-Sign-Violated': '#4c8cef',
  'Traffic-Speed-Violated': '#ac00ff',
};

export const INCIDENTS_NAMES_MAP = {
  /*
        Cornering: 'The Cornerer',
        'Harsh-Acceleration': 'The Accelerator',
        'Harsh-Braking': 'The Braker',
        'Lane-Drift-Found': 'The Lane Drifter',
        'Tail-Gating-Detected': 'The Tailgater',
        'Traffic-STOP-Sign-Violated': 'The Rolling Stopper',
        'Traffic-Speed-Violated': 'The Speeder'
    */
  Cornering: 'Cornering',
  'Harsh-Acceleration': 'Acceleration',
  'Harsh-Braking': 'Braking',
  'Lane-Drift-Found': 'Lane Drift',
  'Tail-Gating-Detected': 'Tailgating',
  'Traffic-STOP-Sign-Violated': 'Rolling Stop',
  'Traffic-Speed-Violated': 'Speeding',
};

export const DATE_RANGE_TEXT_MAP = {
  7: 'Last 7 Days',
  14: 'Last 14 Days',
  30: 'Last 30 Days',
  90: 'Last 3 Months',
  180: 'Last 6 Months',
};

// Dashboard models

export const DURATION_LIST = [
  { label: 'Last week', value: 'WEEK' },
  { label: 'Last month', value: 'MONTH' },
  { label: 'Last six months', value: 'SIX_MONTHS' },
];

export const DURATION_MAP = {
  WEEK: 7,
  MONTH: 30,
  SIX_MONTHS: 6 * 30,
};

export const INCIDENTS_CHART_LEGENDS = [
  'Traffic-Speed-Violated',
  'Harsh-Acceleration',
  'Harsh-Braking',
  'Cornering',
  'Lane-Drift-Found',
  'Traffic-STOP-Sign-Violated',
  'Tail-Gating-Detected',
  'Distracted-Driving',
];

export class StatsModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public dutyType: string,
    public minScore: number,
    public maxScore: number,
    public goal: number,
    public divisions?: any[]
  ) {
    if (!dutyType) {
      delete this.dutyType;
    }
    const isEmptyDivisions = Array.isArray(divisions) && !divisions.length;
    if (!divisions || isEmptyDivisions) {
      delete this.divisions;
    }
  }
}

export class DriverStatsModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public limit: number,
    public groupBy?: string,
    public divisions?: string[],
    public activeDivisions?: string[]
  ) {
    const isEmptyDivisions = Array.isArray(divisions) && !divisions.length;
    if (!divisions || isEmptyDivisions) {
      delete this.divisions;
    }
  }
}

export class SearchFilterModel {
  constructor(
    public days: number,
    public minScore?: number,
    public maxScore?: number,
    public homeLocation?: string,
    public dutyType?: string,
    public ids?: string[]
  ) {}
}

export class SearchFilterTableModel {
  constructor(
    public days: number,
    public assetIds?: string[],
    public driverIds?: string[],
    public Incidents?: string[],
    public locationId?: string[]
  ) {}
}
export class FilterStrore {
  constructor(
    public days: number,
    public minScore: number,
    public maxScore: number,
    public rangeValue: number,
    public homeLocation: string,
    public dutyType: string,
    public ids: string[],
    public isHomeLocationEditable?: boolean
  ) {}
}

export class DriversList {
  constructor(public startDate: string, public endDate: string) {}
}

export class DefaultInputModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public dutyType: string = '',
    public duration: string = '',
    public group: string = '',
    public divisions?: string | string[]
  ) {
    if (!startDate) {
      delete this.startDate;
    }
    if (!endDate) {
      delete this.endDate;
    }
    if (!dutyType) {
      delete this.dutyType;
    }
    if (!duration) {
      delete this.duration;
    }
    if (!group) {
      delete this.group;
    }
    if (!divisions) {
      delete this.divisions;
    }
  }
}

export class DefaultInputModelV2 {
  constructor(
    public startDate: string,
    public endDate: string,
    public dutyType: string,
    public minScore: number,
    public maxScore: number,
    public limit?: number,
    public offset?: number,
    public sort?: string,
    public sortBy?: string,
    public divisions?: string[]
  ) {
    if (!dutyType) {
      delete this.dutyType;
    }
    if (!limit) {
      delete this.limit;
    }
    if (!(offset || offset === 0)) {
      delete this.offset;
    }
    if (!sort) {
      delete this.sort;
    }
    if (!sortBy) {
      delete this.sortBy;
    }
    if (!divisions) {
      delete this.divisions;
    }
  }
}

export class FleetAvgScoreGraphModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public groupBy?: string,
    public divisions?: string[]
  ) {
    if (!groupBy) {
      delete this.groupBy;
    }
    const isEmptyDivisions = Array.isArray(divisions) && !divisions.length;
    if (!divisions || isEmptyDivisions) {
      delete this.divisions;
    }
  }
}

export class FleetAllTripsModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public dutyType: string,
    // public driverIds[]: string[],
    public limit?: number,
    public offset?: number,
    public sort?: string,
    public sortBy?: string,
    public divisions?: string[]
  ) {
    if (!dutyType) {
      delete this.dutyType;
    }
    if (!divisions) {
      delete this.divisions;
    }
    // if (!(driverIds && driverIds.length)) {
    //     delete this.driverIds;
    // }
    if (!limit) {
      delete this.limit;
    }
    if (!(offset || offset === 0)) {
      delete this.offset;
    }
    if (!sort) {
      delete this.sort;
    }
    if (!sortBy) {
      delete this.sortBy;
    }
  }
}

export class TripsWithIncidentsModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public minEvents: number,
    public limit: number,
    public offset: number,
    public sort: string = 'desc'
  ) {}
}

export class ChallengesModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public limit: number,
    public offset?: number,
    public divisions?: string[],
    public incidentTypes?: string[]
  ) {}
}
export class IncidentsModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public dutyType: string,
    public group?: string,
    public permile?: boolean
  ) {
    if (!startDate) {
      delete this.startDate;
    }
    if (!endDate) {
      delete this.endDate;
    }
    if (!dutyType) {
      delete this.dutyType;
    }
    if (!group) {
      delete this.group;
    }
    if (!permile) {
      delete this.permile;
    }
  }
}

// Driver details models
// export class DriversScoreModel {
//     constructor(public startDate: string, public endDate: string, public group: string = 'week') {}
// }

export class DriversTripListModel {
  constructor(
    public startDate: string,
    public endDate: string,
    public sort: string,
    public limit: number,
    public offset: number
  ) {}
}

export class ErrorHandling {
  public status: number;
  public message: string;
  public showRetryButton: boolean;

  constructor({
    status = 0,
    message = 'Something went wrong',
    showRetryButton = true,
  } = {}) {
    this.status = status;
    this.message = message;
    this.showRetryButton = showRetryButton;
  }
}

export class ModalParamsModel {
  constructor(
    public state: boolean,
    public component: string,
    public drivername?: string
  ) {}
}

export class UserModel {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public identityProviderId: string,
    public email: string,
    public created: string,
    public modified: string,
    public driverName?: string
  ) {}
}

export class AssetModel {
  constructor(
    public assetId: string,
    public assetName: string,
    public associatedCameraIMEI: string,
    public dutyType: string
  ) {}
}

export class ListItem {
  id: string | number;
  name: string;

  constructor(source: any) {
    this.id = source.id;
    this.name = source.name;
  }
}

export interface GetDriverListOptions {
  params?: any;
}

export interface AssetFromTripRequest {
  tripId: string;
  driverId: string;
}
