import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ListItem } from '@modules/dashboard3/dashboard3.models';
import { ListFilterPipe } from '@modules/shared/pipes/list-filter.pipe';

export interface IDropdownSettings {
  selectAllText?: string;
  selectedItemText?: string;
  dropdownPlaceholder?: string;
  clearSearchFilter?: boolean;
  itemsShowLimit?: number;
  searchPlaceholderText?: string;
  noDataAvailablePlaceholderText?: string;
  noFilteredDataAvailablePlaceholderText?: string;
  defaultOpen?: boolean;
  showChips?: boolean;
  showSearchFilter?: boolean;
  enableCheckAll?: boolean;
}

const defaultSettings = {
  selectAllText: 'All select',
  selectedItemText: '',
  dropdownPlaceholder: 'Select data',
  locationDropdownPlaceholder: 'Locations',
  clearSearchFilter: true,
  itemsShowLimit: 3,
  noDataAvailablePlaceholderText: 'No data available',
  noFilteredDataAvailablePlaceholderText: 'Sorry, no results found.',
  defaultOpen: false,
  showChips: true,
  showSearchFilter: true,
  enableCheckAll: true,
  isSelectAll: undefined,
  isLocationFilter: false,
};

@Component({
  selector: 'app-search-filter-item',
  templateUrl: './search-filter-item.component.html',
  styleUrls: ['./search-filter-item.component.scss'],
})
export class SearchFilterItemComponent implements OnInit {
  @ViewChild('scrollToTop')
  public scrollToTop: ElementRef;

  @Input() public isLoading = false;
  @Input() public isAllowSortInputData = true;
  @Input() public clickOutSideReloadData = false;
  @Input() public isGetSelectedItems = false;
  @Input() public set selectedList(value: any[]) {
    if (value && this.isGetSelectedItems) {
      this.selectedItems = [...value];
    }
  }

  @Output() public selectChange: EventEmitter<ListItem[]> = new EventEmitter<ListItem[]>();
  @Output() public filterChange = new EventEmitter<void>();
  @Output() public toggleDropdownEvent = new EventEmitter<boolean>();

  public settings = defaultSettings;
  public selectedItems: ListItem[] = [];
  public isDropDownListShow = false;
  public dataSource: ListItem[] = [];
  public filter: ListItem = new ListItem(this.dataSource);
  public searchTerm: string;

  @Input()
  public set inputSettings(value: IDropdownSettings) {
    if (value) {
      this.settings = { ...defaultSettings, ...value };
    } else {
      this.settings = { ...defaultSettings };
    }
  }

  @Input()
  public set inputData(value: Array<ListItem>) {
    if (!value) {
      this.dataSource = [];
    } else {
      this.dataSource = this.isAllowSortInputData ? value.sort((a, b) => a.name > b.name ? 1 : -1) : value;
      if (!this.isGetSelectedItems) {
        this.selectedItems = this.dataSource.slice();
      }
    }
  }

  constructor(
    private _listFilterPipe: ListFilterPipe
  ) { }

  public ngOnInit(): void {}

  public onFilterTextChange($event) {
    this.searchTerm = $event;
  }

  public closeDropdown(event = undefined) {
    // only trigger reload data when click outside if checklist is opening
    // do not trigger event emit if click on chip icon
    if (
      this.clickOutSideReloadData
      && this.settings.defaultOpen
      && event.srcElement.id !== 'close-chip-icon'
      && !event.srcElement.classList.contains('selected-item-close-chips')) {
      this.filterChange.emit();
    }

    const ignoredList = [
      'close-chip-icon',
      'close selected-item-close-chips',
    ];

    const elSelected =
      event['srcElement']['id']
      || event['srcElement'].classList.value;

    if (event && ignoredList.includes(elSelected)) {
      return false;
    }
    // close the dropdown checklist
    this.settings.defaultOpen = false;
    // clear search text
    this.filter.name = '';
    this.searchTerm = '';

    // scroll to top when click outside
    this.scrollToTop.nativeElement.scrollTop = 0;
  }

  public toggleDropdown(evt): void {
    evt.preventDefault();
    this.toggleDropdownEvent.emit(this.settings.defaultOpen);
    this.settings.defaultOpen = !this.settings.defaultOpen;
  }

  public toggleSelectAll(): void {
    if (!this.isAllItemsSelected()) {
      // filter out disabled item first before slicing
      this.selectedItems = this._listFilterPipe.transform(this.dataSource, this.filter).slice();
      this.selectChange.emit(this.selectedItems);
    } else {
      this.selectedItems = [];
      this.selectChange.emit(this.selectedItems);
    }
  }

  public onItemClick(_$event: any, item: ListItem): void {
    const found = this.isSelected(item);
    if (!found) {
      this.addSelected(item);
    } else {
      this.removeSelected(item);
    }
  }

  public isSelected(clickedItem: ListItem): boolean {
    return this.selectedItems.some(item => clickedItem.id === item.id);
  }

  public addSelected(itemSel: ListItem): void {
    this.selectedItems.push(itemSel);
    this.selectChange.emit(this.selectedItems);
  }

  public removeSelected(itemSel: ListItem): void {
    this.selectedItems = this.selectedItems.filter(item => itemSel.id !== item.id);
    this.selectChange.emit(this.selectedItems);
  }

  public itemShowRemaining(): number {
    return this.selectedItems.length - this.settings.itemsShowLimit;
  }

  public displaySelectedItem(isEmpty = true): string {
    // isSelectAll is the flag to indicate that if placeholder should be used
    if (this.isAllItemsSelected(true) || this.selectedItems.length === 0 || this.settings['isSelectAll']) {
      return isEmpty ? '' : this.settings.dropdownPlaceholder;
    }

    if (this.selectedItems.length === 1) {
      return this.selectedItems[0].name;
    }

    return `${this.selectedItems.length} ${this.settings.selectedItemText} selected`;
  }

  public tooltipContent(): string {
    return this.selectedItems
      .slice(this.settings.itemsShowLimit)
      .map(item => item.name)
      .join(', ');
  }

  public isAllItemsSelected(showChips = false): boolean {
    const filteredItems = this._listFilterPipe.transform(this.dataSource, this.filter);
    if (!this.dataSource || this.dataSource.length === 0) {
      return false;
    }
    return showChips
      ? this.selectedItems.length === this.dataSource.length
      : filteredItems.length === this.selectedItems.length;
  }
}
