import { Component, OnInit, AfterViewInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcelService } from '@app-core/services/excel.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import {
  SnackbarCustomMessageComponent,
  SnackBarMsg,
} from '@modules/dashboard3/components/snackbar-custom-message/snackbar-custom-message.component';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, Observable, of } from 'rxjs';
import { concatMap, filter, map, share, tap, throttleTime, catchError } from 'rxjs/operators';

export interface ValidateIMEIEvent {
  imei: string;
  isInvalid: boolean;
  validateError?: string;
  apiError?: boolean;
}

export interface AddImeiEventResponse {
  deviceId: string;
  err: string;
  imei: string;
}

@Component({
  selector: 'app-setting-upload-camera-by-id',
  templateUrl: './setting-upload-camera-by-id.component.html',
  styleUrls: ['./setting-upload-camera-by-id.component.scss'],
})
export class SettingUploadCameraByIdComponent implements OnInit, AfterViewInit {
  public listAddCameraIMEI: string[] = [];

  public cameraIMEIValidated: Observable<ValidateIMEIEvent>;
  public validateIMEI: Observable<string>;
  public errorMessage;
  public checkDelete: boolean;
  public formGroup: FormGroup;
  public isValidating: boolean;
  public isRequesting = false;
  public addResponse = null;
  public addCameraIMEIResponse: Subject<AddImeiEventResponse[]> = new Subject<AddImeiEventResponse[]>();

  private _validateIMEISubject: Subject<string> = new Subject();
  private _removeModalRef: BsModalRef;
  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private _zcfleet: ZCFleetService,
    private _snackbar: SnackbarService,
    private _excelService: ExcelService,
    private _sanitizer: DomSanitizer) {
    /**
     * Piping validateIMEI stream to create validated stream.
     * This stream will only emit a string as imei value only if the imei is valid
     */
    this.cameraIMEIValidated = this._validateIMEISubject.asObservable().pipe(
      throttleTime(2000),
      tap(() => this.isValidating = true),
      map<string, ValidateIMEIEvent>(imei => ({ imei, isInvalid: false }))
    ).pipe(
      concatMap((event) => this.checkIMEISequence(event)), // check imei validity
      concatMap((event) => this.checkCameraIMEIExistOnCurrentList(event)), // check on current imei list
      concatMap((event) => this.checkCameraIMEIExistOnServer(event)) // check on server
    ).pipe(
      tap(this.updateValidateErrorMessage.bind(this)),
      tap(() => this.isValidating = false),
      filter((event: ValidateIMEIEvent) => !event.isInvalid),
      share<ValidateIMEIEvent>()
    );
  }

  public ngOnInit() {
    this.cameraIMEIValidated.subscribe(
      (event: ValidateIMEIEvent) => {
        if (!event.apiError) {
          this.onCameraIMEIValidated(event.imei);
        }
      },
      (err) => {
        console.log(err); this.isValidating = false;
      }
    );

    this.formGroup = new FormGroup({
      imei: new FormControl(''),
    });
  }

  public ngAfterViewInit() {
    this.imei.valueChanges.subscribe(_ => {
      if (this.errorMessage != null) {
        this.clearMessage();
      }
    });
  }

  public get imei() {
    return this.formGroup.get('imei');
  }

  public checkInput(kEvent: KeyboardEvent) {
    const isIMEIValid = (/^\d{15}$/).test(this.imei.value);
    if (!isIMEIValid) {
      kEvent.preventDefault();
    }
  }

  public checkIMEISequence(event: ValidateIMEIEvent): Observable<ValidateIMEIEvent> {
    // already invalid from other check
    if (event.isInvalid) {
      return of(event);
    }

    const isIMEIValid = (/^\d{15}$/).test(event.imei);

    return of({
      imei: event.imei,
      isInvalid: !isIMEIValid,
      validateError: isIMEIValid ? '' : 'Invalid IMEI',
    });
  }

  public checkCameraIMEIExistOnServer(event: ValidateIMEIEvent): Observable<ValidateIMEIEvent> {
    if (event.isInvalid) {
      return of(event);
    }
    return this._zcfleet.checkCameraIMEIExist(event.imei).pipe(
      map(isExisted => ({
        imei: event.imei,
        isInvalid: isExisted,
        validateError: isExisted ? 'Camera already added' : '',
      })),
      catchError(_ =>
        of({
          imei: event.imei,
          isInvalid: false,
          validateError: 'Error checking IMEI existence on server!',
          apiError: true,
        })
      )
    );
  }

  public checkCameraIMEIExistOnCurrentList(event: ValidateIMEIEvent): Observable<ValidateIMEIEvent> {
    if (event.isInvalid) {
      return of(event);
    }

    const isExisted = this.listAddCameraIMEI.includes(event.imei);
    return of({
      imei: event.imei,
      isInvalid: isExisted,
      validateError: isExisted ? 'Camera already added' : '',
    });
  }

  public addCameraIMEI(imei: string): void {
    this.addResponse = null;
    this.clearMessage();
    this._validateIMEISubject.next(imei);
  }

  public onCameraIMEIValidated(imei: string): void {
    if (imei) {
      this.listAddCameraIMEI.push(imei);
    }
    this.formGroup.reset();
  }

  public buildAddCameraByIMEIParam(imeiList: string[]): { listCameraIMEI: string [] } {
    return {
      listCameraIMEI: imeiList,
    };
  }

  public updateValidateErrorMessage(event: ValidateIMEIEvent) {
    if (event.isInvalid) {
      this.errorMessage = {
        item: event.imei,
        message: event.validateError || '',
        isInvalid: event.isInvalid,
      };
    }
  }

  public clearMessage() {
    this.errorMessage = null;
  }

  public openModal(confirm: TemplateRef<any>, removeIMEIIndex: number, cameraImei: string) {
    this._removeModalRef = this.modalService.show(confirm, {
      initialState: {
        removeIndex: removeIMEIIndex,
        removeImei: cameraImei,
      },
      class: 'remove-camera-dialog-container',
    } );
  }

  public confirmRemoveCameraIMEI(removeIMEIIndex): void {
    this.listAddCameraIMEI.splice(removeIMEIIndex, 1);
    if (this._removeModalRef) {
      this._removeModalRef.hide();
    }
  }

  public cancel(): void {
    if (this._removeModalRef) {
      this._removeModalRef.hide();
    }
  }

  public closeModal(): void {
    this.bsModalRef.hide();
  }

  public addCameraIMEIToServer() {
    if (!this.isRequesting) {
      this.isRequesting = true;
      this.addResponse = null;
      this._zcfleet.uploadCameraIMEI(this.buildAddCameraByIMEIParam(this.listAddCameraIMEI)).subscribe(
        (res: AddImeiEventResponse[]) => {
          this.addResponse = res;
          this.listAddCameraIMEI = [];
          this.addCameraIMEIResponse.next(res);
          this.isRequesting = false;
          this.formGroup.reset();
          this.clearMessage();
          this.closeModal();
          this.openSnackBarMessage(res as any[]).subscribe(_ => {
            this.listAddCameraIMEI = (res as any[]).filter(imei => imei.err != null).map(item => item.imei);
            this.addCameraIMEIToServer();
          });
        },
        (err) => {
          console.log(err);
          this.isRequesting = false;
          this.formGroup.reset();
          this.clearMessage();
          this.closeModal();
          const list = this.listAddCameraIMEI.map(item => ({imei: item, err}));
          this.openSnackBarMessage(list).subscribe(_ => {
            this.addCameraIMEIToServer();
          });
        });
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getDataUri(obj: object) {
    return this._sanitizer.bypassSecurityTrustUrl('data:text/plain;base64,' +
                                                 btoa(JSON.stringify(obj)));
  }

  public createCSVResultFile(data: any[]) {
    if (data.length === 0) {
      return;
    }

    try {
      const errorList = data.map(imei => ({
        IMEI: imei.imei,
        ERROR: imei.err,
      }));
      const filename = 'download_result';
      const headers = [
        {},
      ];
      const utils = this._excelService.getSheetToJson(headers, {
        header: [],
        skipHeader: false,
      });
      const sheet = this._excelService.sheetAddJson(utils, errorList, {
        origin: 'A1',
      });

      this._excelService.convertToCSV(sheet, filename);
    } catch (error) {
      console.log('Error while saving report', error);
    }
  }

  public openSnackBarMessage(respData: any[]): Observable<void> {
    if (!respData) {
      return;
    }

    const hasError = respData.some(resp => resp.err != null);
    const snackbarDuration = 10 * 1000;

    const msgInfo: SnackBarMsg = {
      textMsg: null,
    };
    const panelClasses = ['custom-snackbar'];

    if (hasError) {
      msgInfo.title = 'Camera could not be added';
      msgInfo.action = 'Try again';
      panelClasses.push('error-msg');
      if (respData.length === 1) {
        // eslint-disable-next-line max-len
        msgInfo.textMsg = `Camera ${respData[0].imei} is already uploaded to this account. If it is not visible under Unassigned Cameras, please contact Zonar Customer Care.`;
      } else {
        const errorList = respData.filter(imei => imei.err != null);
        msgInfo.textMsg = 'to this account. If these are not visible under Unassigned Cameras, please contact Zonar Customer Care.';
        msgInfo.linkMsg = `${errorList.length} cameras are already uploaded`;
        msgInfo.linkAction = this.createCSVResultFile.bind(this);
        msgInfo.customLinkData = errorList;
      }
    } else {
      panelClasses.push('success-msg');
      if (respData.length === 1) {
        msgInfo.textMsg = `Camera ${respData[0].imei} was successfully added to the account.`;
      } else {
        msgInfo.textMsg = `${respData.length} cameras were successfully added to the account.`;
      }
    }

    return this._snackbar.openCustomSnackBar(SnackbarCustomMessageComponent, panelClasses, snackbarDuration, msgInfo);
  }
}
