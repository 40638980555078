<!-- <nav aria-label="Page navigation example">
    <ul class="pagination">
        <li class="page-item" [class.disabled]="activePage <= pageStart" (click)="previousPage()">
            <span class="page-link" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
            </span>
        </li>
        <li class="page-item" [class.active]="activePage === i" *ngFor="let i of frame" (click)="setPage(i)">
            <span class="page-link">
                {{i}}
            </span>
        </li>
        <li class="page-item" [class.disabled]="activePage >= pageEnd" (click)="nextPage()">
            <span class="page-link" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
            </span>
        </li>
    </ul>
</nav> -->

<div class="paginator">
    <img src="assets/images/button-first.png" *ngIf="!(activePage <= pageStart)" (click)="firstPage();">
    <img src="assets/images/button-first-disabled.png" *ngIf="activePage <= pageStart" (click)="firstPage();">
    <span class="spacer"></span>
    <img src="assets/images/button-previous.png" *ngIf="!(activePage <= pageStart)" (click)="previousPage()">
    <img src="assets/images/button-previous-disabled.png" *ngIf="activePage <= pageStart" (click)="previousPage()">
    <span class="pages">{{activePage}} of {{pageEnd}}</span>
    <img src="assets/images/button-next.png" *ngIf="!(activePage >= pageEnd)" (click)="nextPage()">
    <img src="assets/images/button-next-disabled.png" *ngIf="activePage >= pageEnd" (click)="nextPage()">
    <span class="spacer"></span>
    <img src="assets/images/button-last.png" *ngIf="!(activePage >= pageEnd)" (click)="lastPage();">
    <img src="assets/images/button-last-disabled.png" *ngIf="activePage >= pageEnd" (click)="lastPage();">
</div>
