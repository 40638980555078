import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, tap } from 'rxjs/operators';

import { INTERCEPTOR_REGEX_PATTERNS } from '@app-core/services/token.provider';
import { EventBusService } from './event-bus.service';

const GA_EVENT_CATEGORY = 'ga';
const GA_EVENT_TRACKING_NAVIGATION = 'NAVIGATION';

@Injectable({ providedIn: 'root' })
export class NavigationTracking {
  constructor(
    private _eventBusService: EventBusService,
    @Inject(INTERCEPTOR_REGEX_PATTERNS) private _regexPatterns: RegExp[],
    private _router: Router
  ) {}

  public initialize() {
    this._subscribeForNavigation();
  }

  /**
   * Check if the request url matches regex
   */
  public isUrlMatch(url: string, patterns: RegExp[]): boolean {
    return patterns.some((pattern: RegExp) => pattern.test(url));
  }

  /**
   * @description subscribe for route change event and send data to Google Analytics
   *  of the app
   */
  private _subscribeForNavigation() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((eventSuccess) => {
          const eventParsed = eventSuccess as NavigationEnd;
          if (
            this.isUrlMatch(
              eventParsed.url,
              this._regexPatterns[GA_EVENT_TRACKING_NAVIGATION]
            )
          ) {
            this._sendTrackingNavigationEvent(eventParsed.url);
          }
        })
      )
      .subscribe(() => {});
  }

  /**
   * send a performance event to event bus
   */
  private _sendTrackingNavigationEvent(url: string): void {
    this._eventBusService.emit({
      eventCategory: GA_EVENT_CATEGORY,
      eventName: GA_EVENT_TRACKING_NAVIGATION,
      value: {
        label: url,
        times: 1,
      },
    });
  }
}
