<div class="snackbar-stack-wrapper" *ngIf="msgStack.length > 0">
  <div *ngFor="let msg of msgStack; let i = index" class="snackbar-content-wrapper" [ngClass]="msg.panelClasses">
    <ng-container *ngTemplateOutlet="messageSnackbar; context: { $implicit: msg, index: i }"></ng-container>
  </div>
</div>

<ng-template #messageSnackbar let-msgData let-index="index">
  <div class="message-content">
    <div class="title">{{msgData.title}}</div>
    <div class="content" [class.no-title]="!msgData.title">
      <a class="link" *ngIf="msgData.linkMsg" (click)="execLinkAction()">{{msgData.linkMsg + ' '}}</a>
      <span>{{msgData.textMsg}}</span>
      <span *ngIf="msgData.isShowContact"><br><br>If the issue persists, please contact <a class="link" target="_blank" href="https://www.zonarsystems.com/contact/">Zonar Customer Service.</a></span>
    </div>
  </div>
  <div class="action-content" *ngIf="msgData.action">
    <span (click)="execLinkAction(msgData, index)">{{msgData.action}}</span>
  </div>
</ng-template>