<app-default-modal>
  <ng-container header>
    <span> Comments on {{ eventType }} Incident at {{ timeMoment }}</span>
  </ng-container>
  <ng-container body>
    <div>
      <!-- Previous comment -->
      <div class="g-grid" *ngFor="let comment of currentCommentList; index as i" [ngClass]="{'unlimited-width': unlimitedComments}">
        <ng-container *ngTemplateOutlet="userComment; context: { $implicit: comment, index: i }"></ng-container>
      </div>

      <!-- Pending comment -->
      <div class="g-grid" *ngFor="let comment of currentPendingCommentList; index as i" [ngClass]="{'unlimited-width': unlimitedComments}">
        <ng-container *ngTemplateOutlet="userCommentPending; context: { $implicit: comment, index: i }"></ng-container>
      </div>

          <!-- Comment section -->
      <div class="comment-section" *ngIf="remainingNumberOfComment > 0 || unlimitedComments">
        <ng-container *ngTemplateOutlet="commentSection; context: { $implicit: formGroup }"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container footer *ngIf="remainingNumberOfComment > 0 || unlimitedComments; else showNote">
      <!-- Comment action -->
    <ng-container *ngTemplateOutlet="actionButton"></ng-container>
  </ng-container>
</app-default-modal>

<!-- Template Section -->

<ng-template #commentSection let-commentFormGroup>
  <form [formGroup]="commentFormGroup">
    <div class="form-comment" [ngClass]="{'error-input-area': isErrorComment}">
      <span class="title-comment">
        Comments
      </span>
      <textarea cols="45" rows="3" id="comments"
        placeholder="This is a multi lined text input"
        class="form-control text-area"
        formControlName="comments"
        [attr.maxlength]="maxNumberOfCharacters">
      </textarea>
      <div *ngIf="isErrorComment" class="g-grid-col icon-text-area-error">
        <img src="assets/images/comment-modal/error_icon.svg"/>
      </div>
    </div>
    <small *ngIf="!isErrorComment, else errorTextAreaNotify"
      class="form-text-inline text-muted alignment">
      {{ unlimitedComments ? 'Unlimited' : remainingNumberOfComment }} comment{{ remainingNumberOfComment > 1  || unlimitedComments ? 's' : '' }} {{ unlimitedComments ? '' : 'remaining' }}
    </small>
    <ng-template #errorTextAreaNotify>
      <small class="form-text-inline alignment error-text">
        {{ textErrorMsg }}
      </small>
    </ng-template>
    <small class="form-text-inline text-muted right-end alignment">
      {{comments?.value?.length || '0'}}/{{ maxNumberOfCharacters }}
    </small>
  </form>
</ng-template>

<ng-template #userComment let-currentComment let-index="index">
  <div class="g-grid-col col-number">
    <b>({{ index + 1 }} of {{ unlimitedComments ? 'unlimited' : calMaxCommentPerConversation }})</b>
  </div>
  <ng-container *ngTemplateOutlet="commentIncident; context: { $implicit: currentComment }"></ng-container>
</ng-template>

<ng-template #userCommentPending let-currentComment let-index="index">
  <!-- icon -->
  <!-- case pending -->
  <div *ngIf="!currentComment.errorStatus; else showSyncErrorIcon" class="g-grid-col col-status-icon">
    <img src="assets/images/comment-modal/sync_24px.svg"/>
  </div>
  <!-- case no connection and error limit -->
  <ng-template #showSyncErrorIcon>
    <div class="g-grid-col col-status-icon">
      <img src="assets/images/comment-modal/sync_failed_24px.svg"/>
    </div>
  </ng-template>
  <!-- details -->
  <ng-container *ngTemplateOutlet="commentIncident; context: { $implicit: currentComment, index: index }"></ng-container>
</ng-template>

<ng-template #actionButton>
  <div class="btn-cancel-section">
    <button aria-label="Close" class="btn btn-format btn-primary btn-cancel" (click)="bsModalRef.hide()">Cancel</button>
  </div>
  <div (click)="buildCommentToDriver(comments.value)" class="btn-send-driver">
    <button type="button" class="btn btn-primary btn-send btn-format" [disabled]="comments?.value?.length === 0">
      Send to Driver
    </button>
  </div>
</ng-template>

<ng-template #showNote>
  <div class="notify-note" *ngIf="!isCommentListPending">
    <b>Please Note: </b> This incident has reached the maximum number of comments.
    Please contact the driver directly to continue discussing this incident.
  </div>
</ng-template>

<ng-template #showErrorNoConnection let-currentComment let-index="index">
  <div class="notify-note error-no-connection">
    <b>Please Note: </b> No connection detected. Unable to
    save comment. Please <a (click)="buildCommentToDriver(currentComment.text, index)">try again</a>
  </div>
</ng-template>

<ng-template #commentIncident let-currentComment let-index="index">
  <div class="g-grid-col col-text">
    <span class="line-break text-author">{{ currentComment.displayAuthor }}</span>
    <span class="line-break text-time">{{ currentComment.disputeTimeMoment }}</span>
    <span class="line-break text-comment">{{ currentComment.text }}</span>

    <!-- error text-->
    <div *ngIf="currentComment.errorStatus===error.noConnection">
      <!-- no connection -->
        <ng-container *ngTemplateOutlet="showErrorNoConnection; context: { $implicit: currentComment, index: index }"></ng-container> 
    </div>
  </div>
</ng-template>