<div class="mat-card-container {{ elementClasses }}" [ngClass]="{ 'is-isSank': isSank }">
  <div class="mat-card-header">
    <div class="mat-card-title">
      <ng-content select="[card-title]"></ng-content>
    </div>
    <div *ngIf="showSortList && sortList.length > 0">
      <div class="mat-selected-list" (click)="showList = !showList" (appClickOutside)="closeDropdown()"> 
        <span class="sort-display-text">{{sortName}}</span> 
        <span class="arrow-down"></span>
      </div>
      <div class="mat-sort-list" *ngIf="showList">
        <div *ngFor="let item of activeSortList" class="category {{item[1] ? 'active-list' : ''}}"
          (click)="onChangeSortBy(item[0])">{{item[0].displayName}}</div>
      </div>
    </div>
    <div class="menu-option" *ngIf="menuOptionTemplate">
      <ng-container
        *ngTemplateOutlet="menuOptionTemplate">
      </ng-container>
    </div>
  </div>
  <div class="mat-card-body">
    <ng-content select="[card-body]"></ng-content>
  </div>
  <div>
    <ng-content select="[card-error]"></ng-content>
  </div>
  <div class="mat-card-footer" *ngIf="showFooter">
    <ng-content select="[card-footer]"></ng-content>
    <div class="view-card-details" *ngIf="showDetailButton">
      View Details
    </div>
  </div>
</div>
