import { Pipe, PipeTransform } from '@angular/core';
import { ListItem } from '@modules/dashboard3/dashboard3.models';

@Pipe({
  name: 'multiSelectFilter',
  pure: false,
})
export class ListFilterPipe implements PipeTransform {
  public transform(items: ListItem[], filter: ListItem): ListItem[] {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item: ListItem) => this.applyFilter(item, filter));
  }

  public applyFilter(item: ListItem, filter: any): boolean {
    if (typeof item.name === 'string' && typeof filter.name === 'string') {
      return !(filter.name && item.name && item.name.toLowerCase().indexOf(filter.name.toLowerCase()) === -1);
    } else {
      return !(filter.name && item.name && item.name.toString().toLowerCase().indexOf(filter.name.toString().toLowerCase()) === -1);
    }
  }
}
