/* eslint-disable max-len */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { EVENT_NAMES_MAP } from '@modules/dashboard3/dashboard3.models';

interface MediaFile {
  mediaFile: string;
  mediaFileIndex: number;
}

interface IncidentEvent {
  [key: string]: any;

  eventType: string;
  eventIndex: number;

  challengeAccepted: boolean;
  challengeRaised: boolean;
  challengeResolved: boolean;

  latitude: number;
  longitude: number;

  mediaFiles: MediaFile[];

  timestampUTC: string;
}

// interface IncidentDetail {
//   locality: string;
//   district: string;
//   event: IncidentEvent;
// }

@Component({
  selector: 'app-incident-dvr-detail',
  templateUrl: './incident-dvr-detail.component.html',
  styleUrls: ['./incident-dvr-detail.component.scss'],
})
export class IncidentDvrDetailComponent implements OnInit {
  @Input() public tripId: string;
  @Input() public driverId: string;
  @Input() public customTemplate: TemplateRef<any>;

  @Input() public set incidentEvent(value: IncidentEvent) {
    this.incidentEventValue = value;
    this.videoData = this._getVideoData(value);
  }

  @Input() public set dvrIncidentEvent(value) {
    this.incidentEventValue = value;
    this.videoData = this._getDVRVideoData(value);
  }

  @Output() public closeButtonClicked = new EventEmitter<null>();
  @Output() public challengeUpdated = new EventEmitter<any>();

  public incidentEventValue: IncidentEvent;
  public videoData: any;

  constructor() {}

  public ngOnInit(): void {}

  // Helper to map event names to proper tags
  public getEventType(name) {
    return EVENT_NAMES_MAP[name];
  }

  public acceptChallenge() {}

  public onCloseButtonClick() {
    this.closeButtonClicked.emit();
  }

  public onChallengeUpdated(eventData) {
    this.challengeUpdated.emit(eventData);
  }

  private _getVideoData(incidentEvent: IncidentEvent) {
    return {
      mediaLink: incidentEvent.mediaFiles[0].mediaFile,
      driverId: this.driverId,
      tripId: this.tripId,
      eventIndex: incidentEvent.eventIndex,
      eventType: incidentEvent.eventType,
      challengeAccepted: incidentEvent.challengeAccepted,
      challengeRaised: incidentEvent.challengeRaised,
      challengeResolved: incidentEvent.challengeResolved,
      speed: incidentEvent.speed,
      speedSign: {
        eventVideoFilename:
          incidentEvent.speedSign && incidentEvent.speedSign.eventVideoFilename,
        speedSignValue:
          incidentEvent.speedSign && incidentEvent.speedSign.speedSignValue,
      },
    };
  }

  private _getDVRVideoData(dvrIncidentEvent) {
    return {
      mediaLink: dvrIncidentEvent.mediaLink,
      driverId: this.driverId,
      tripId: this.tripId,
      eventVideoFilenameUploadStatus: {
        status: dvrIncidentEvent.status,
      },
      eventType: dvrIncidentEvent.eventType,
      challengeAccepted: dvrIncidentEvent.challengeAccepted,
      challengeRaised: dvrIncidentEvent.challengeRaised,
      challengeResolved: dvrIncidentEvent.challengeResolved,
      speed: undefined,
      speedSign: {
        eventVideoFilename: undefined,
        speedSignValue: undefined,
      },
    };
  }
}
