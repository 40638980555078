import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import {
  HARD_BRAKING_OPTIONS,
  HARD_ACCELERATION_OPTIONS,
  HARD_CORNERING_OPTIONS,
  MAX_SPEED_OPTIONS,
  STOP_SIGN_OPTIONS,
  SPEEDING_OPTIONS,
  LANE_DRIFT_OPTIONS,
  TAILGATING_OPTIONS,
  MaxSpeedOptions,
  DRIVER_DISTRACTION_OPTIONS,
} from './../../../dashboard3.constants';

@Component({
  selector: 'app-duty-settings-options',
  templateUrl: './duty-settings-options.component.html',
  styleUrls: ['./duty-settings-options.component.scss'],
})
export class DutySettingsOptionsComponent implements OnInit, OnChanges {
    @Input() public data: any;
    @Input() public dutyType: string;
    @Input() public readOnly: boolean;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public change = new EventEmitter<any>();

    public config: any = {};
    public hover = new Array(4);

    public hardBrakingOptions = HARD_BRAKING_OPTIONS;
    public hardAccelerationOptions = HARD_ACCELERATION_OPTIONS;
    public hardCorneringOptions = HARD_CORNERING_OPTIONS;
    public maxSpeedOptions: number[];
    public tailgatingOptions = TAILGATING_OPTIONS;
    public stopSignOptions = STOP_SIGN_OPTIONS;
    public speedingOptions = SPEEDING_OPTIONS;
    public laneDriftingOptions = LANE_DRIFT_OPTIONS;
    public driverDistractionOptions = DRIVER_DISTRACTION_OPTIONS;

    public distractionSetting = false;

    constructor(
      private _zcFleetService: ZCFleetService
    ) {}

    public ngOnInit() {
      this.hover.fill(true);
      this.maxSpeedOptions = this.generateSpeedOptions(MAX_SPEED_OPTIONS).reverse();
      this._zcFleetService.isFleetContainsRideCamCamera().subscribe(
        (res) => {
          this.distractionSetting = !res;
        },
        (err) => {
          console.log(err);
        }
      );
    }

    public ngOnChanges(changes: SimpleChanges) {
      if (changes.data.currentValue) {
        this.config = this.data[this.dutyType];
      }
    }

    public changeSettings(val) {
      const ob = {...val };
      this.change.emit(ob);
    }

    public changeSpeedCap(speedCap: number) {
      if (speedCap === undefined || speedCap === null) {
        return;
      }

      const isEnableSpeedCap = speedCap > 0;
      const upperLimit = isEnableSpeedCap ? { speedUpperLimit: speedCap } : {};

      this.changeSettings({
        speedCapEnabled: isEnableSpeedCap,
        ...upperLimit,
      });
    }

    public generateSpeedOptions(options: MaxSpeedOptions): number[] {
      if (!(options && options.maxValue && options.minValue && options.step)) {
        return null;
      }

      const { maxValue, minValue, step } = options;
      if (maxValue < 0 || minValue < 0 || step < 0 || maxValue < minValue) {
        return null;
      }

      const result = [];
      for (let i = minValue; i <= maxValue; i += step) {
        result.push(i);
      }
      return result;
    }

}
