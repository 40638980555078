import { Injectable } from '@angular/core';

@Injectable()
export class LocalService {
  constructor() {}
  public saveSearchFilterData(data: any) {
    window.localStorage['data'] = JSON.stringify(data);
  }

  public getSearchFilterData(): any {
    return JSON.parse(window.localStorage['data'] || '{}');
  }
}
