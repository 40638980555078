import { Injectable } from '@angular/core';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Observable } from 'rxjs';

export interface Configuration {
  maximumCommentPerConversation: number;
  maximumCommentLength: number;
}

@Injectable()
export class ConfigurationService {
  constructor(
    private _zcFleetService: ZCFleetService
  ) {}

  public getViolationConfigurationSettings(): Observable<Configuration> {
    return this._zcFleetService.getViolationConfigurationSettings();
  }
}
