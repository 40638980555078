import { Injectable } from '@angular/core';

import { DVCEvent,
  DVCFeature,
  DVCFeatureSet,
  DVCOptions,
  DVCUser,
  DVCVariable,
  DVCVariableSet,
  initialize,
} from '@devcycle/devcycle-js-sdk';
import { DVCClient } from '@devcycle/devcycle-js-sdk/src/Client';

import { environment } from '@env/environment';

const SDK_KEY_CLIENT = environment.dvc_sdk_key_client;

// To-do: Update get custom data about state
const user: DVCUser = {
  // user_id: '',
  // email: '',
  isAnonymous: true,
};

// To-do: Optimize options
const dvcOptions: DVCOptions = {
  logLevel: 'error',
  enableEdgeDB: false,
  disableRealtimeUpdates: false,
  disableConfigCache: false,
};

@Injectable() export class DevCycleService {

  public dvcClient: DVCClient;

  public get variableList(): DVCVariableSet {
    // If the SDK has not finished initializing, this method will return an empty object
    return this.dvcClient.allVariables();
  }

  public get featureList(): DVCFeatureSet {
    return this.dvcClient.allFeatures();
  }

  constructor() {
    this.dvcClient = initialize(SDK_KEY_CLIENT, user, dvcOptions);
  }

  /**
   * @description initialize DevCycle client
   * @param callback a callback function to handle response of onClientInitialized()
   */
  public initializeClient(callback: (err) => void) {
    this.dvcClient.onClientInitialized(callback);
  }

  /**
   * @description update variable when changes detected
   * @param variable DevCycle variable that need to update value
   * @param callback a callback function to handle response of onUpdate()
   */
  public updateVariable(variable: DVCVariable<any>, callback: (value) => void) {
    variable.onUpdate(callback);
  }

  public getVariable(variableKey: string, defaultValue = false): DVCVariable<any> {
    return this.dvcClient.variable(variableKey, defaultValue);
  }

  /**
   * @description To identify a different user, or the same user passed into the initialize with more attributes
   * @param userInfo the entire user attribute object
   * @param callback a callback function to handle response of identifyUser()
   */

  public identifyUser(userInfo: DVCUser, callback: (err, variables) => void) {
    this.dvcClient.identifyUser(userInfo, callback);
  }

  /**
   * @description To reset the user into an anonymous user,
   * resetUser will reset to the anonymous user created before or will create one with an anonymous user_id
   * @param callback a callback function to handle response of resetUser()
   */
  public resetUser(callback: (err, variables) => void) {
    this.dvcClient.resetUser(callback);
  }

  /**
   * @description: To track events, pass in an object with at least a type key
   * The SDK will flush events every 10s
   */
  public trackEvent(event: DVCEvent) {
    this.dvcClient.track(event);
  }

  /**
   * @description: To manually flush events
   * The SDK will flush events every 10s or flushEventsMS specified in the options
   */
  public flushEvents(callback: () => void) {
    // called back after flushed events
    this.dvcClient.flushEvents(callback);
  }

  public subInitializedEvent(client: DVCClient, handler: (initialized: boolean) => void) {
    client.subscribe('initialized', handler);
  }

  public subErrorEvent(client: DVCClient, handler: (error: unknown | Error) => void) {
    client.subscribe('error', handler);
  }

  public subVarUpdateEvent(client: DVCClient, handler: (key: string, variable: DVCVariable<any> | null) => void, variableKey = '*') {
    const subKey = `variableUpdated:${variableKey}`;
    client.subscribe(subKey as any, handler);
  }

  public subVarEvaluateEvent(client: DVCClient, handler: (key: string, variable: DVCVariable<any>) => void, variableKey = '*') {
    const subKey = `featureUpdated:${variableKey}`;
    client.subscribe(subKey as any, handler);
  }

  public subFeatureUpdateEvent(client: DVCClient, handler: (key: string, feature: DVCFeature | null) => void, featureKey = '*') {
    const subKey = `featureUpdated:${featureKey}`;
    client.subscribe(subKey as any, handler);
  }

  public unSubEvents(client: DVCClient, key: string) {
    client.unsubscribe(key);
  }

  // TO-DO: EdgeDB
}
