<div class="zc-body has-search-filter has-trip-summary">
  <div class="trip-summary">
    <div class="breadcrum">
      <img
        src="assets/images/home.png"
        class="home_icons"
        (click)="gotoFleet()"
      /><img src="assets/images/arrow.png" class="arrowlink" />
      <span class="route driver-link" (click)="driverChange()">{{
        driverName
      }}</span
      ><img src="assets/images/arrow.png" class="arrowlink" />

      <select class="triplist" (change)="onTripChange($event)">
        <option value="" disabled>Select Trip</option>
        <option
          *ngFor="let trip of triplist | slice: 0:14"
          value="{{ trip.tripId }}"
          [selected]="tripId === trip.tripId"
        >
          {{ getGreetingText(trip.startTime) }} Trip ({{ trip.count }}) on
          {{ trip.startTime | dateFormat | date: 'MM/dd/yy' }}
        </option>
      </select>
    </div>
  </div>

  <div class="zc-content-wrap">
    <div class="trip-detail-wrap">
      <div class="trip-map row">
        <div class="incident-div trip-summary-block">
          <ng-container *ngTemplateOutlet="tripDetailTemplate"></ng-container>
        </div>

        <div class="incident-video-div" @fadeAnimation *ngIf="selectedEvent || selectedDvrEvent">
          <ng-container *ngIf="selectedEvent; then dvrVideoModal; else dvrVideoModalOtherEvent"></ng-container>
        </div>

        <div class="map-div">
          <ng-container *ngTemplateOutlet="mapTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tripDetailTemplate>
  <div class="trip_details">
    <div class="tampering-details" *ngIf="showTamperingBlock">
      <img class="dangerSign" src="/assets/images/exclamation-red-circle.svg" />
      {{ tamperingMessage }}
    </div>
    <!-- ZCW-498: move three dot to same line trip summary. -->
    <div class="summary-one">
      Trip Summary
      <div class="capturelist">
        <button class="btn dvr-btn" (click)="captureVideoModel()">Capture DVR</button>
      </div>
    </div>

    <div class="trip_list">
      <!-- first feature flag to detect smart dvr feature enabled or disabled -->
      <!-- this container not affect the layout, it is required to handle directive on component -->
      <ng-container *appFeatureFlag="'smart-fleet-dvr-capture'"></ng-container>
      <div (click)="captureVideoModel()" class="capturebox" *ngIf="captureList">
        <span> Capture new DVR clip </span>
      </div>
      <div></div>
      <div *ngIf="!tripDetail?.ongoing; else ongoingTrip">
        <img src="assets/images/map_icon/map.png" class="map_icons" />{{
          startLocation || 'Unknown'
        }}
        to {{ endLocation || 'Unknown' }}
      </div>
      <ng-template #ongoingTrip>
        <div class="tripedriver_name pt0">
          <img
            src="assets/images/last_updated_icon.svg"
            class="map_icons"
          />Last Update:
          <span *ngIf="ongoingLastUpdate">
            {{ ongoingLastUpdate | momentFormat: 'MM/DD/YYYY HH:mm z' }}
          </span>
          <span *ngIf="!ongoingLastUpdate">
            N/A
          </span>
        </div>
        <div class="tripedriver_name ongoing-trip pt0">
          <img src="assets/images/map_icon/map.png" class="map_icons" />Vehicle
          in transit
        </div>
      </ng-template>
      <!-- ZCW-224: display timezone in trip detail - merge from branch develop -->
      <!-- ZCW-729: display timezone in driver detail page with the new algorithm -->
      <div>
        <img src="assets/images/map_icon/clock.png" class="map_icons" />{{
          tripDetail?.startTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z'
        }}
      </div>
      <div>
        <img src="assets/images/map_icon/hours.png" class="map_icons" />{{
          tripDetail?.duration | duration2: 'seconds':'h:m'
        }}
      </div>
      <div>
        <img src="assets/images/map_icon/mile.png" class="map_icons" />{{
          tripDistance | number: '1.0-0'
        }}
        <span *ngIf="tripDistance > 1">miles</span>
        <span *ngIf="tripDistance <= 1">mile</span>
      </div>
      <div>
        <img src="assets/images/map_icon/incident.png" class="map_icons" />{{
          incidentList.length
        }}
        <span *ngIf="incidentList.length > 1">incidents</span>
        <span *ngIf="incidentList.length <= 1">incident</span>
      </div>
      <div> <img src="assets/images/map_icon/directions_car_24px.svg"
          class="map_icons">
          <a
          *ngIf="assetName !== 'N/A'"
          [routerLink]="['/assets', assetId]"
          style="text-decoration: underline; color: #0075b4"
          >{{assetName}} ({{dutyType.label}})</a>
          <!-- disabled link when asset N/A -->
          <a *ngIf="assetName === 'N/A'">
            {{assetName}} ({{dutyType.label}})
          </a>
      </div>
      <!-- </div> -->
    </div>

    <!-- COMMON EVENTS -->
    <div class="breakline"></div>
    <div
      *ngIf="incidentList?.length"
      class="incidents_list"
    >
      <div class="summary">Incident List</div>
      <div class="selectedlist" (click)="showList = !showList">
        {{ sortListBy }} <span class="arrow-down"></span>
      </div>
      <div class="show-list" *ngIf="showList">
        <div class="category" (click)="onIncidentChange('time')">
          Sorted by time
        </div>
        <div class="category" (click)="onIncidentChange('category')">
          Sorted by category
        </div>
      </div>

      <div *ngFor="let incident of incidentList; let i = index">
        <div class="incident-section"
          [class.active]="incidentArrayIndex === i"
          (click)="eventDetails(incident.event, i)">
          <div class="incident-content">
            <div class="incident-marker">
              <img
                *ngIf="
                  incident?.event?.challengeRaised === true &&
                    incident?.event?.challengeAccepted === true;
                  else noneCircleIcon
                "
                src="assets/images/markericons_v3/incidents_icon_shadow_circle/{{
                  incident.type[0]
                }}-circle.svg"
              />
              <ng-template #noneCircleIcon>
                <img
                  src="assets/images/markericons_v3/pip_incidents_icon/{{
                    incident.type[0]
                  }}.svg"
                />
              </ng-template>
            </div>

            <div class="incident-info">
              <div class="incident-type-address">
                <span class="incident-type">
                  {{ incident.type[1] }}
                  <span
                    *ngIf="
                      incident?.event?.challengeRaised &&
                      incident?.event?.challengeAccepted === false
                    "
                  >
                    <img src="assets/images/black-flag.svg" />
                  </span>
                </span>
                <span>
                  <!-- ZCW-729: display address is unknow and city is NA If there're no data -->
                  {{ incident.locality || 'Unknown' }},
                  {{ incident.district || 'NA' }}
                </span>
              </div>

              <div class="selected-list-time">
                <!-- ZCW-729: display timezone in driver detail page with the new algorithm -->
                {{ incident.timeMoment | momentFormat: 'MM/DD/YYYY HH:mm z' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!incidentList.length && !loading"
      class="incidents_list_null"
      [ngStyle]="{
        height: dvrEventList?.length ? '29vh' : '55vh'
      }"
    >
      <span class="summary-no-incident">Incident List</span>
      <span class="confetti"> <img src="assets/images/confetti.png" /> </span>
      <p class="no_incidents">No Incidents. Wow!</p>
    </div>

    <!-- DVR EVENTS -->
    <div class="breakline"></div>
    <div class="contain-dvr">
      <app-spinner [showPartScreen]="true" *ngIf="loaderDVR"></app-spinner>
      <div *ngIf="dvrEventList?.length" class="incidents_list">
        <div class="summary">DVR</div>
        <div class="selectedlist" (click)="showDVRList = !showDVRList">
          {{ sortDVRListBy }} <span class="arrow-down"></span>
        </div>
        <div class="show-list" *ngIf="showDVRList">
          <div class="category" (click)="onDVRIncidentChange('time')">
            Sorted by time
          </div>
          <div class="category" (click)="onDVRIncidentChange('status')">
            Sorted by status
          </div>
        </div>

        <div *ngFor="let dvrEvent of dvrEventList; let i = index">
          <div
            class="incident-section"
            [class.active]="incidentDvrArrayIndex === i"
            (click)="
              openDVRVideoModal(dvrEvent?.response?.link, dvrEvent?.status, i)
            "
          >
            <div class="incident-content">
              <div class="incident-marker">
                <img
                  class="challenge-accepted"
                  src="assets/images/markericons_v2/Cornering-circle.svg"
                />
              </div>

              <div class="incident-info">
                <div>Other Event</div>
                <div class="selected-list-time">
                  <!-- ZCW-729: display timezone in driver detail page with the new algorithm-->
                  {{
                    dvrEvent.startTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z'
                  }}
                </div>
              </div>

              <div class="incident-info">
                <!-- ZCW-729: display timezone in driver detail page with the new algorithm-->
                {{ dvrEvent.status }} (requested on
                {{
                  dvrEvent.dvrCreatedAtMoment
                    | momentFormat: 'MM/DD/YYYY HH:mm z'
                }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mapTemplate>
  <app-map
    [ngClass]="{ 'hide-popup-media': hidePopupMedia }"
    mapId="eventMap"
    [markerList]="markerList"
    [latlonList]="pathList"
    [driverId]="driverId"
    [tripId]="tripId"
    [driverName]="driverName"
    (updateMapMarkers)="updateEventList($event)"
    [incidentDetail]="incidentList"
    [incidentIndex]="incidentArrayIndex"
    (onIncidentPopupClosed)="resetIncidentArrayIndex()"
    [customTemplates]="actionIncident"
  ></app-map>
  <div class="trip-page-footer">
    <div
      class="container-fluid trippage-footer"
      style="font-size: 12px; float: right"
    >
      © 2020 Zonar Systems. &nbsp;&nbsp; &nbsp; {{ appVersion }} &nbsp;&nbsp;
      &nbsp;
      <a
        style="color: black"
        target="_blank"
        href="https://support.zonarsystems.net/hc/en-us/sections/360002547151-Zonar-Coach"
        >Support</a
      >
      &nbsp;&nbsp; &nbsp;<a
        style="color: black"
        target="_blank"
        href="http://zonarcoach.com/feedback"
      >
        Feedback
      </a>
      &nbsp;&nbsp; &nbsp;
      <a style="color: black" target="_blank" href="javascript:void(0)">
        Terms</a
      >
      &nbsp;&nbsp; &nbsp;<a
        style="color: black"
        target="_blank"
        href="https://www.zonarsystems.com/terms-of-use/zonar-coach"
      >
        Data</a
      >
    </div>
  </div>
</ng-template>

<ng-template #dvrVideoModal>
  <app-incident-dvr-detail
    [driverId]="driverId"
    [tripId]="tripId"
    [customTemplate]="actionIncident"
    [incidentEvent]="selectedEvent"
    (closeButtonClicked)="resetIncidentArrayIndex()"
    (challengeUpdated)="updateEventChallengeProperties($event)"
    ></app-incident-dvr-detail>
</ng-template>

<!-- The DVR Modal -->
<ng-template #dvrVideoModalOtherEvent>
  <app-incident-dvr-detail
    [driverId]="driverId"
    [tripId]="tripId"
    [dvrIncidentEvent]="selectedDvrEvent"
    (closeButtonClicked)="resetIncidentArrayIndex()"
    (challengeUpdated)="updateEventChallengeProperties($event)"
    ></app-incident-dvr-detail>
</ng-template>

<ng-container *ngIf="showEdvrVideoModal">
  <div id="myModal" class="modal" (click)="closeEDVRVideoModal()"></div>
  <!-- Modal content -->
  <div class="modal-content">
    <h5>
      <strong>{{ eDVREventType }}</strong>
    </h5>
    <!-- ZCW-729: display timezone in driver detail page with the new algorithm -->
    <div>
      {{ eDVRTimeStamp | date: 'MM/dd/yy hh:mm a' }}
      {{ (displayTimeZone | timeZone) || displayTimeZone }}
    </div>
    <span class="close" (click)="closeEDVRVideoModal()">&times;</span>

    <video
      class="dvrVideo eDvrVideo"
      width="100%"
      height="100%"
      controls
      autoplay
    >
      <source [src]="eDVRVideoURL" type="video/mp4" />
    </video>
  </div>
</ng-container>

<app-new-capture-dvr-modal
  [tripDetail]="tripDetail"
  [getFullList]="pathInfo"
  [getLatLongList]="pathList"
  [incidentList]="incidentList"
  [starttime]="starttime"
  [displayTimeZone]="displayTimeZone"
  [endtime]="endtime"
  [latestCapturedPosition]="latestCapturedPosition"
></app-new-capture-dvr-modal>

<app-capture-new-incident-model
  [tripDetail]="tripDetail"
  [getFullList]="pathInfo"
  [getLatLongList]="pathList"
  [incidentList]="incidentList"
  [starttime]="starttime"
  [displayTimeZone]="displayTimeZone"
  [endtime]="endtime"
  [latestCapturedPosition]="latestCapturedPosition"
></app-capture-new-incident-model>

<ng-template #actionIncident>
  <app-comment-button-group *isAuthorized="'fleet-ridecam-plus'"
    [bookmarkInfo]="eventTripMetadata"
        [loadingChange$]="loadingChange$"
    (tripMetadataChange)="updateTripMetadata($event)"
    (clickButtonChange)="hidePopupMedia = $event"
  >
  </app-comment-button-group>
</ng-template>
