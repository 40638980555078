import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss'],
})
export class DefaultModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  public ngOnInit(): void {}

}
