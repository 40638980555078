<app-settings-modal class="manual-upload-camera" [class.longer-body]="listAddCameraIMEI.length > 3">
  <ng-container body>
    <h2 class="add-cam-modal-header">Add Cameras (Enter ID)</h2>
    <form [formGroup]="formGroup" (submit)="addCameraIMEI(imei.value)">
      <div class="new-cam-input-field">
        <mat-form-field class="imei-input-field" appearance="outline" [class.imei-invalid]="errorMessage">
          <mat-label>Camera IMEI #*</mat-label>
          <input type="text" matInput appInputPatternCheck autocomplete="off" maxlength="15" id="imei" class="form-control" formControlName="imei" (click)="clearMessage()" (keydown.enter)="checkInput($event)">
        </mat-form-field>
        <div class="hint-text">
          <span *ngIf="!errorMessage; else errorHintText">15-digit ID</span>
        </div>
      </div>
      <ng-template #errorHintText>
        <span class="error-msg">{{errorMessage.message}}</span>
      </ng-template>

      <div class="action-block">
        <button type="button" class="btn-add-camera" (click)="addCameraIMEI(imei.value)" [class.btn-with-bg]="errorMessage"
          [disabled]="isValidating || imei?.value?.length < 15 || errorMessage">
          <img *ngIf="!(isValidating || imei?.value?.length < 15 || errorMessage)" src="assets/images/plus-sign.svg" alt="">
          <img *ngIf="isValidating || imei?.value?.length < 15 || errorMessage" src="assets/images/plus-sign-disable.svg" alt="">
          Add camera
        </button>
        <ng-container *ngIf="isValidating">
          <app-inline-spinner></app-inline-spinner>
        </ng-container>
      </div>

      <div class="breaking-line" *ngIf="listAddCameraIMEI.length > 0"></div>
      <ul class="prepare-imei-list" *ngIf="listAddCameraIMEI.length > 0" [class.show-scrollbar]="listAddCameraIMEI.length > 10">
        <li *ngFor="let cameraIMEI of listAddCameraIMEI; index as i;">
          <img class="camera-icon" src="assets/images/videocam.svg" alt="ico">
          <span class="inserted-imei">{{cameraIMEI}}</span>
          <button type="button" class="btn-img" aria-label="Close"
            (click)="openModal(confirmRemove, i, cameraIMEI)">
            <img class="camera-icon" src="assets/images/trashcan.png" alt="ico">
          </button>
        </li>
      </ul>
    </form>
  </ng-container>
  <ng-container footer>
    <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="listAddCameraIMEI.length <= 0"
      (click)="addCameraIMEIToServer()">
      <span>Save all</span> <span *ngIf="listAddCameraIMEI.length > 0">&nbsp;({{listAddCameraIMEI.length}})</span>
    </button>
  </ng-container>
</app-settings-modal>

<!-- dialog confirm delete camere IMEI -->
<ng-template #confirmRemove class="confirm-dialog">
  <div class="modal-content remove-camera-dialog">
    <div class="modal-body">
      <div class="confirm-remove-msg">
        <span>Discard Camera with IMEI {{ modalService.config.initialState['removeImei'] }}?</span>
      </div>

      <div class="remove-btn-block">
        <button type="button" class="btn btn-primary" (click)="confirmRemoveCameraIMEI(modalService.config.initialState['removeIndex'])">Discard</button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
