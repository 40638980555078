<div
    class="modal fade"
    bsModal
    #modal="bs-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="mySmallModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title pull-left" style="width: 80%;">{{
                    getEventType(event?.eventType) | uppercase
                }}</span>
                <!-- <span class="modal-sub-title">{{event.timestamp | dateFormat | date: 'hh:mm a'}}</span> -->
                <!-- change the black-flag image to white flag image when provided -->
                <span *ngIf="event.challengeRaised && !event.challengeResolved">
                    <img src="assets/images/flag.png" />
                </span>
                <ng-container *ngIf="edvrSwitch">
                <span
                    class="pull-right ml-auto"
                    style="cursor: pointer;"
                    (click)="toggleEnhanceBtn()"
                    appDocumentClick
                    (clickedInside)="clickedInside($event)"
                >
                    <img src="assets/images/x.svg" />
                </span>


                <span *ngIf="showEnhanceBtn" class="enhance-btn" (click)="openEnhanceVideoModal()"
                    >Enhance video</span
                >


                &nbsp;&nbsp; | &nbsp;&nbsp;
                </ng-container>
                <span
                    class="pull-right ml-auto"
                    style="cursor: pointer;"
                    (click)="closeModal()"
                >
                <img src="assets/images/x.svg" />
                </span>
                <br />
            </div>

            <div>
                <ng-container *ngIf="incidentDetail">
                    <span
                        >{{ incidentDetail[incidentIndex]?.locality || 'Unknown'}},
                        {{ incidentDetail[incidentIndex]?.district || 'NA'}}
                    </span>
                </ng-container>
            </div>

            <ng-container *ngTemplateOutlet="customTemplates"></ng-container>

            <div class="modal-body">
                    <app-media-show [event]="event" [tripId]="tripId"></app-media-show>
            </div>

            <!-- in case of no challenge raised and no action taken yet -->
            <div *ngIf="!event.challengeResolved && !event.challengeRaised" class="callout-buttons">
                <div
                    *ngIf="
                        !(event.eventType === 'Calibration') &&
                        !(event.eventType === 'Video-Capture')
                    "
                    class="callout-button1"
                    (click)="reportBug(event)"
                >
                    <span> <img src="assets/images/Bug.svg" /> </span>
                    Report bug
                </div>
                <div
                    *ngIf="
                        !(event.eventType === 'Calibration') &&
                        !(event.eventType === 'Video-Capture')
                    "
                    class="callout-button1"
                    (click)="acceptChallenge(event)"
                >
                    <span> <img src="assets/images/Check.svg" /> </span>
                    <!-- Change for ZCW-504 -->
                    Mark as safe
                </div>
            </div>
            <!-- in case of challenge raised but no action taken -->
            <div
                *ngIf="event.challengeRaised && !event.challengeResolved"
                class="callout-buttons"
                style="height:60px"
            >
                <div class="display-text1 row">{{ driverName }} flagged this incident</div>
                <div
                    *ngIf="
                        !(event.eventType === 'Calibration') &&
                        !(event.eventType === 'Video-Capture')
                    "
                    class="row"
                    style="float:right"
                >
                    <div class="callout-button1" (click)="rejectChallenge(event)">
                        <span> <img src="assets/images/shape.svg" /> </span>
                        Reject flag
                    </div>
                    <div class="callout-button1" (click)="acceptChallenge(event)">
                        <span> <img src="assets/images/Check.svg" /> </span>
                        <!-- Change for ZCW-504 -->
                        Mark as safe
                    </div>
                    <div class="callout-button1">
                        <span> <img src="assets/images/Bug.svg" /> </span>
                        Report bug
                    </div>
                </div>
            </div>

            <!-- in case of marking the incident safe -->
            <div *ngIf="event.challengeResolved && event.challengeAccepted" class="callout-buttons">
                <div>
                    <div class="display-text" *ngIf="!isBugReported">
                        This incident is marked as safe. It no longer appears on the record.
                    </div>
                    <div class="display-text" *ngIf="isBugReported">
                            Bug reported.
                        </div>
                    <div
                        class="cancel-button"
                        [hidden]="hideCancel"
                        (click)="snackbarCancel('accept', event)"
                    >
                        Cancel
                    </div>
                </div>
            </div>
            <!-- in case of rejecting the challenge -->
            <div
                *ngIf="event.challengeResolved && !event.challengeAccepted"
                class="callout-buttons"
            >
                <div>
                    <div class="display-text">This incident remains on the record.</div>
                    <div
                        class="cancel-button1"
                        [hidden]="hideCancel"
                        (click)="snackbarCancel('reject', event)"
                    >
                        Cancel
                    </div>
                </div>
            </div>

        </div>
        <div class="tip-container">
            <div class="tip"></div>
        </div>
    </div>
</div>

<div
    *ngIf="mapheight"
    [id]="mapId"
    class="{{ mapheight === 200 ? 'smallmap' : (ongoing && mapheight === 192) ? 'ongoing-smallmap': 'flagged-incidents-map' }}"
></div>
<div *ngIf="!mapheight" [id]="mapId" class="map"></div>

<app-enhance-video-modal
    [incidentDetails]="event"
    [getDriverId]="driverId"
    [getTripId]="tripId"
></app-enhance-video-modal>


<!-- <ng-template #bookmarkSaved>
    <img src="assets/images/actions_icon/bookmark.svg">
</ng-template> -->