import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

@Component({
  selector: 'app-common-table-modal',
  templateUrl: './common-table-modal.component.html',
  styleUrls: ['./common-table-modal.component.scss'],
})
export class CommonTableModalComponent implements OnInit , OnDestroy {

    @ViewChild('modal', { static: true }) public modal: ModalDirective;
    @Input() public totalTrips?: any;
    @Input() public filterOptions?: any;
    @Input() public driverId?: any;
    @Input() public allAssets?: any;
    public modalSubscription;
    public displayComponent;
    public driverName = '';
    public isShown = true;


    constructor( private _zcfleet: ZCFleetService) {
      this.modalSubscription =  this._zcfleet.commonTableModal.subscribe(params => {
        if (params.state) {
          this.displayComponent = params.component;
          this.driverName = params.drivername;
          this.modal.show();
        }
      });
    }

    public ngOnInit() {
    }


    public ngOnDestroy(): void {
      this.modalSubscription.unsubscribe();
    }

    /**
     * @description: function to hide the modal
     * @param:
     */
    public hideModal() {
      this.modal.hide();
      this.isShown = this.modal.isShown;
    }

    public show() {
      this.isShown = true;
    }

}
