<div
  class="leadboard-card"
  data-test="top-incident-card"
  [ngClass]="{
    'no-incident-background': ((internalTopIncidentData$ | async) <= 0)
      && (notLoadingState?.topIncidentData | async)
  }"
>
  <div class="leadboard-card-head">
    <div class="driver-name">Top Incidents</div>
  </div>

  <ng-container *ngIf="(notLoadingState?.topIncidentData | async); else skeletonLoadingTotalIncident" card-body>
    <ng-container *ngIf="(internalTopIncidentData$ | async) as topIncidentData">
      <ng-container *ngIf="topIncidentData.length > 0; else congratulationNoIncident">
        <div class="leadboard-card-body">
          <div class="center">
            <table class="incident_table" data-test="top-incident-card-table-body">
              <tr class="incident-row" data-test="top-incident-card-table-row" *ngFor="let incident of topIncidentData"
              [ngClass]="{ 'zero-incident': incident?.value === 0 }">
                <td class="incident_text incident-item" [ngClass]="incident?.incidentClass">
                  {{ incident?.name }}
                </td>
                <td class="numbercount">
                  {{ incident?.value }}
                </td>
              </tr>
              <tr>
                <span *ngIf="isDistractedDisclaimerFlag" class="distraction-disclaimer"><img src="assets/images/info_24px.svg">
                  {{distractionDisclaimer}}</span>
              </tr>
            </table>
            <div
              class="view-link card-view-link"
              data-test="top-incident-card-view-detail-button"
              (click)="goToFleetMetrics()"
              [ngClass]="{
                'less-incident': topIncidentData.length < 5 && topIncidentData.length >=3,
                'less-incident-table': topIncidentData.length === 2
              }"
            >
            <span class="top-incident-button">View Details</span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #congratulationNoIncident>
  <div class="leadboard-card-body">
    <div class="incident-no-incidents-message">
      <p>
        Congratulations! <br>There are no incidents in this time period.
      </p>
    </div>
  </div>
  <div
    class="view-link card-view-link incident-view link-card"
    data-test="top-incident-card-view-detail-button"
    (click)="goToFleetMetrics()"
  >
    <span>View Details</span>
  </div>
</ng-template>

<!-- loading skeleton for total incident-->
<ng-template #skeletonLoadingTotalIncident>
  <div class="loading-total-incident">
    <div class="loading-incident-stat-list">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '22px'
      }">
      </app-custom-skeleton-loader>
    </div>
    <div class="loading-incident-stat-list">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '22px'
      }">
      </app-custom-skeleton-loader>
    </div>
    <div class="loading-incident-stat-list">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '22px'
      }">
      </app-custom-skeleton-loader>
    </div>
    <div class="loading-incident-stat-list">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '22px'
      }">
      </app-custom-skeleton-loader>
    </div>
    <div class="loading-incident-stat-list">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '22px'
      }">
      </app-custom-skeleton-loader>
    </div>
  </div>
  <div
    class="no-view-link card-view-link-disable incident-view"
    data-test="top-incident-card-view-detail-button"
  >
    <span class="top-incident-button-skeleton">View Details</span>
  </div>
</ng-template>
