import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'application / csv';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Sheets: { data: worksheet },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this._saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsCSVFile(json: any[], excelFileName: string): void {
    const worksheet0: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const csv0 = XLSX.utils.sheet_to_csv(worksheet0);
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet0 }, SheetNames: ['data'] };
    // const csvBuffer: any = XLSX.write(workbook, { bookType: 'csv', type: 'array' });
    this._saveAsCSVFile(csv0, excelFileName);
  }

  public getSheetToJson(arr: any[], options) {
    return XLSX.utils.json_to_sheet(arr, options);
  }

  public sheetAddJson(sheet: XLSX.WorkSheet, arr: any[], options) {
    return XLSX.utils.sheet_add_json(sheet, arr, options);
  }

  public convertToExcel(ws: any, name: string, isCustom = false) {
    const workbook: XLSX.WorkBook = isCustom ? {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Sheets: ws,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SheetNames: Object.keys(ws),
    } : {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Sheets: { data: ws },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      SheetNames: ['data'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this._saveAsExcelFile(excelBuffer, name);
  }

  public convertToCSV(ws, name) {
    const csv0 = XLSX.utils.sheet_to_csv(ws);
    this._saveAsCSVFile(csv0, name);
  }

  private _saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: CSV_TYPE });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + CSV_EXTENSION
    );
  }

  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
