import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

export interface ErrorMessage {
  message: string;
  action: string;
}

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
  @Input() public showRetryButton = false;
  @Input() public errorMessageChange$: Observable<ErrorMessage>;

  @Output() public tryAgainAction: EventEmitter<any> = new EventEmitter();

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private _snackbarService: SnackbarService) {}

  public ngOnInit(): void {
    this.errorMessageChange$
      .pipe(
        takeUntil(this._destroy$),
        switchMap((res) => this._snackbarService.error(res.message, res.action))
      ).subscribe(() => this.onChange());
  }

  public onChange() {
    this.tryAgainAction.emit();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
