<div class="row pd-b-5">
    <div class="col-lg-3">
    </div>
    <div class="col-lg-6 fs-11 text-center">
        <div *ngIf="fleetId && assetList.length">
            Showing Assets for : {{fleetId}}
        </div>
    </div>
    <div class="col-lg-3 f-r">
        <input type="text" class="form-control" placeholder="Select Account" name="accountCode"
            #searchAccount (keyup)="getAccountsList(searchAccount.value)" />
        <ng-container *ngIf="showFleetList">
            <ul class="fleet-list" *ngIf="accountsList">
                <ng-container *ngFor="let account of accountsList">
                    <li>
                        <button class="list-btn" (click)="getAssetListBasedOnFleetId(account)">
                            {{ account }}
                        </button>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </div>
</div>


<div *ngIf="!fleetId" class="fs-15 pd-t5 text-center">
    Select Account
</div>


<div class="table-300 table-responsive pd-t-5">
    <table class="table table-striped">
        <thead>
            <tr class="fs-12">
                <th scope="col"
                    class="width-15 column-sortable {{sortByKey === 'assetId' ? 'selected' : ''}} "
                    (click)="sortBy('assetId')">
                    Asset ID
                    <span class="sort-arrow">
                        <img *ngIf="!isReversed" class="h-09r"
                            src="assets/images/arrow-up-black.png">
                        <img *ngIf="isReversed" class="h-09r"
                            src="assets/images/arrow-down-black.png">
                    </span>
                </th>
                <th scope="col"
                    class="width-15 column-sortable {{sortByKey === 'assetNumber' ? 'selected' : ''}} "
                    (click)="sortBy('assetNumber')">
                    Asset Number
                    <span class="sort-arrow">
                        <img *ngIf="!isReversed" class="h-09r"
                            src="assets/images/arrow-up-black.png">
                        <img *ngIf="isReversed" class="h-09r"
                            src="assets/images/arrow-down-black.png">
                    </span>
                </th>
                <th scope="col"
                    class="width-15 column-sortable {{sortByKey === 'gpsId' ? 'selected' : ''}} "
                    (click)="sortBy('gpsId')">
                    GPSID
                    <span class="sort-arrow">
                        <img *ngIf="!isReversed" class="h-09r"
                            src="assets/images/arrow-up-black.png">
                        <img *ngIf="isReversed" class="h-09r"
                            src="assets/images/arrow-down-black.png">
                    </span>
                </th>
                <th scope="col" class="width-25">Package</th>
                <th scope="col" class="width-15">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let asset of assetList">
                <td class="width-15">{{asset.assetId}}</td>
                <td class="width-15">{{asset?.metadata?.assetNumber}}</td>
                <td class="width-15">{{asset?.metadata?.gpsId}}</td>
                <td class="width-25">
                    <select class="form-control" [(ngModel)]="asset.displayPackageName"
                        (change)="updatePackage(asset)" [disabled]="!asset.active">
                        <option *ngFor="let option of packageOptions" [value]="option.value">
                            {{option.name}}
                        </option>
                    </select>
                </td>
                <td class="width-15">
                    <button *ngIf="asset.active" type="button" class="btn btn-primary"
                        (click)="disableAsset(asset.assetId)">Disable</button>
                    <button *ngIf="!asset.active" type="button" class="btn btn-secondary"
                        (click)="enableAsset(asset.assetId)">Enable</button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="paginator-wrap asset-paginator" *ngIf="assetList.length">
            <app-paginator3
                [pageLimit]="10"
                [length]="totalAssets"
                (pageChange)="getMoreData($event)">
            </app-paginator3>
        </div>
    <div *ngIf="fleetId && !assetList.length" class="no-match-found">
        <span>No Assets Found</span>
    </div>
</div>
<div class="upload-section" *ngIf="fleetId">
    <h5>Upload assets for this account</h5>

    <div class="asset-template">
        <a href="assets/files/AssetUploadTemplate.xlsx" target="_blank">Assets Upload Template</a>
    </div>

    <form class="form-inline" #f="ngForm" novalidate>
        <div class="form-group">
            <input type="file" id="exampleFormControlFile1" class="form-control-file"
                accept=".xlsx, /.xls" name="assetFile" #fileInput (change)="fileInputChanged()">
        </div>
        <button class="btn btn-primary" [disabled]="!file"
            (click)="uploadAssetList()">Upload</button>
    </form>
</div>
