<div class="modal-lg">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <ng-content select="[header]"></ng-content>
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
