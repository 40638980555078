<div class="zc-body zc-app-wrap">

  <div class="zc-content-wrap">
    <div class="page-title">
      <h1 appCheckBetaFeature="incident-response-center">Incident Response Center</h1>
    </div>

    <app-search-filter
      class="fleet-irc"
      page="incidentResponseCenter"
      (driverChange)="driverChange($event)"
      [histogramData]="histogramData"
      [histogramPercentile]="histogramPercentileData"
      [modeScoreFrequency]="modeScoreFrequency"
      [driverCount]="driverCount"
      (filterChange)="setSearchFilterData($event)">
    </app-search-filter>

    <div class="fleet-page-wrap">
      <div class="disputed-trip-table-wrap">
        <app-fleet-saved-incidents-table
          (isShowError)="notifySavedIncidentsErrorChange($event)">
        </app-fleet-saved-incidents-table>
        <app-fleet-disputed-incidents-table
          [isDashboard]="false"
          (isShowError)="notifyDisputedErrorChange($event)">
        </app-fleet-disputed-incidents-table>
      </div>
    </div>
  </div>

</div>
