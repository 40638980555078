import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from '@app-core/services/storage.service';
import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';
import { zcwAuthenticateConfig } from '@app-core/zcw-auth-module/zcw-authenticate.config';

const ERROR_TITLE = [
  'Page not found.',
  'Please request access to Coach Dashboard.',
  'Sorry, this looks to be our fault.',
];

const ERROR_MSG = [
  'Sorry, but it looks like we couldn’t find the page you were looking for. ',
  'Sorry, but it looks like you don’t have permission to access this page. \nYou\’ll need to request access from your Zonar admin.',
  'We are currently having trouble retrieving the data for your fleet. \nPlease try again in a few minutes, or head back to the homepage.',
];

const ERROR_REASON = [
  'Page not found',
  'Permissions not granted',
  'Fleet ID not found',
];

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
})
export class HttpErrorComponent implements OnInit {

  public title = '';
  public errorMsg = '';
  public reason = '';
  public status = '';
  public url = '';
  public isShownDebug = false;
  public debugData = '';
  public is404 = true;
  public authConfig = zcwAuthenticateConfig;
  public shouldHideAll = true;

  constructor(private _router: Router, private _authService: ZcwAuthenticateService, private _storage: StorageService) {
    this._router.events.subscribe((event => {
      if (event instanceof NavigationEnd) {
        this.is404 = !(event.url === '/error');
      }
    }));
    if (window.location.href.includes('/callback')) {
      this.shouldHideAll = true;
      this._router.navigate(['/dashboard']);
    } else {
      this.shouldHideAll = false;
    }
  }

  public ngOnInit(): void {
    if (!this.is404 && localStorage.getItem('http-error')) {
      const errorValue = JSON.parse(localStorage.getItem('http-error'));
      this.status = errorValue.status;
      this.url = errorValue.url;
      switch (this.status) {
        case '404':
          this.title = ERROR_TITLE[0];
          this.errorMsg = ERROR_MSG[0];
          this.reason = ERROR_REASON[0];
          break;
        case '401':
          this.title = ERROR_TITLE[1];
          this.errorMsg = ERROR_MSG[1];
          this.reason = ERROR_REASON[1];
          break;
        case '500':
          this.title = ERROR_TITLE[2];
          this.errorMsg = ERROR_MSG[2];
          this.reason = ERROR_REASON[2];
          this.debugData = errorValue.debug;
          break;
        default:
          break;
      }
    } else {
      this.status = '404';
      this.title = ERROR_TITLE[0];
      this.errorMsg = ERROR_MSG[0];
      this.reason = ERROR_REASON[0];
      this.url = window.location.href;
    }
  }

  public toggleDebugInfo() {
    this.isShownDebug = !this.isShownDebug;
  }

  public goToHome() {
    this._storage.removeStorageValue('FILTER_STORE');
    this._storage.removeStorageValue('CUSTOM_RANGE');

    if (this.status === '401') {
      this._authService.logout();
    }
    window.location.href = this.authConfig.logoutUrl;
  }

}
