import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MapService } from '@modules/dashboard3/services/map.service';
import {
  EVENT_NAMES_MAP,
  ModalParamsModel,
} from '@modules/dashboard3/dashboard3.models';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { EDVR_SWITCH } from '@app-core/constants/constants';

const CHALLENGE_ACCEPTED = {
  isAccepted: true,
};

const CHALLENGE_REJECTED = {
  isAccepted: false,
};

const REPORT_BUG = {
  isReportBug: true,
};

const TRANSPARENT_CLASS = 'transparent';
const SCALE_3D_TRANSFORM_STRING = ' scale3d(1.5, 1.5, 1.5)';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public markerList: any[] = [];
  @Input()
  public latlonList: any[] = [];
  @Input()
  public mapId = '';
  @Input()
  public driverId = '';
  @Input()
  public tripId = '';
  @Input()
  public driverName = '';
  @Input()
  public mapheight = 0;
  @Input()
  public incidentDetail = [];
  @Input()
  public incidentIndex = 0;
  @Input()
  public ongoing = false;
  @Input()
  public getHighlightLatLon = [];
  @Input()
  public customTemplates: TemplateRef<any>;

  @Output()
  public updateMapMarkers = new EventEmitter<any>();
  @Output()
  public sendClickedMarkerPosition = new EventEmitter<any>();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  public onIncidentPopupClosed = new EventEmitter<any>();

  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  public map: any;
  public hideCancel = true;
  public cancelUpdate = false;
  public event: any = {};
  public media = '';
  public highlightedLatLonList = [];
  public timeoutforAccept;
  public timeoutforReject;
  public timeoutforReportBug;
  public showEnhanceBtn = false;
  public clickedMarkerPosition = [];
  public isEventMediaAvailable: boolean;
  public isBugReported = false;
  public addressteds: any;

  public edvrSwitch = EDVR_SWITCH;

  private _markeGroup;
  private _path;
  private _highlightedPath;
  private _modalParams: ModalParamsModel = { state: false, component: '' };
  private _mapSubscription: any;

  constructor(
    private _mapService: MapService,
    private _zcfleet: ZCFleetService
  ) {}

  public ngOnInit() {
    // Loading the map after some delay since the DOM elements of this component
    // loads after the component initialization which leads to mapId not found
    // error which is avoided by delaying map load
    setTimeout(() => {
      this._loadMap();
    }, 100);
    this._initModal();
  }

  public ngOnDestroy() {
    if (this._mapSubscription) {
      this._mapSubscription.unsubscribe();
    }
  }

  /**
   * @description: setting map view based on lat and long
   * @param: latitude and longitude
   * @returns: previous zoom level
   */
  public setMapView(lat: number, lng: number, options?: { zoomLevel?: number; shouldCalculateSize?: boolean }): number {
    const previousZoomLevel = this.map.getZoom();
    const currentZoomLevel = options.zoomLevel || previousZoomLevel;
    this.map.setView([lat, lng], currentZoomLevel);

    if (options.shouldCalculateSize) {
      setTimeout(() => this.map.invalidateSize(), 400);
    }

    return previousZoomLevel;
  }

  public setZoomLevel(zoomLevel: number, shouldCalculateSize: boolean = false) {
    this.map.setZoom(zoomLevel);

    if (shouldCalculateSize) {
      setTimeout(() => this.map.invalidateSize(), 400);
    }
  }

  /**
   * @description: function to close the modal
   * @param:
   * @returns:
   */
  public closeModal() {
    this.modal.hide();
  }

  public handleOnCloseModal() {
    this._fitToPathBounds();
    this.onIncidentPopupClosed.emit();
    this._setActiveIncidentMapIcon();
  }

  public focusMarker(index: number, zoomLevel: number, shouldCalculateSize: boolean = false) {
    const selectedMarkerPosition = this.markerList[index] && this.markerList[index].getLatLng();

    this.incidentIndex = index;
    this.setMapView(selectedMarkerPosition.lat, selectedMarkerPosition.lng, {
      zoomLevel,
      shouldCalculateSize,
    });
    this._setActiveIncidentMapIcon();
  }

  public unfocusMarker(shouldCalculateSize: boolean = false) {
    this.incidentIndex = undefined;
    this._fitToPathBounds();
    this._setActiveIncidentMapIcon();

    if (shouldCalculateSize) {
      setTimeout(() => this.map.invalidateSize(), 400);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.map) {
      setTimeout(() => {
        this.ngOnChanges(changes);
      }, 100);
      return;
    }
    const list = changes.markerList && changes.markerList.currentValue;
    if (list) {
      this._addMarker(list);
    }
    const latlonList = changes.latlonList && changes.latlonList.currentValue;
    if (latlonList && latlonList.length) {
      this._addPath(latlonList);
      if (this.mapId === 'eventMap9') {
        this.map.options.minZoom = 10;
        this.map.options.maxZoom = 18;
        this._path.on('click', (e) => {
          this.markerList[0].setLatLng(e.latlng);
          this.clickedMarkerPosition = e.latlng;
          this.sendClickedMarkerPosition.emit(this.clickedMarkerPosition);
        });
      }
    }
    if (changes.getHighlightLatLon) {
      this.highlightedLatLonList = this.getHighlightLatLon;
      this.highlightPath(this.highlightedLatLonList);
    }

    if (
      changes.incidentIndex &&
      changes.incidentIndex.currentValue !== undefined
    ) {
      this._setActiveIncidentMapIcon();
    }
  }

  /**
   * @description: highighting a path in map based on user selected time
   * @param: highlighted path lat and lon list
   * @returns:
   */
  public highlightPath(highlightedLatLonList) {
    if (this.mapId === 'eventMap9' && this._highlightedPath) {
      this._highlightedPath.remove(this.map);
    }
    if (this.mapId === 'eventMap9') {
      this._highlightedPath = this._mapService.getHighLightedPolyline(
        highlightedLatLonList
      );
      this._highlightedPath.addTo(this.map);
      this._fitToHighlightedPathBounds();
    }
  }

  /**
   * @description: function to perform operations based on marker click on map
   * @param: path with lat long and marker coordinates
   * @returns:
   */
  public markerClick(path, marker) {
    path.on('click', (e) => {
      marker.setLatLng(e.latlng);
    });
  }

  // Helper to map event names to proper tags
  public getEventType(name) {
    return EVENT_NAMES_MAP[name];
  }

  /**
   * @description: function to accept challenge for a particular incident
   * @param: the incident details
   * @returns:
   */
  public acceptChallenge(incident) {
    this.hideCancel = false;
    incident.challengeAccepted = true;
    incident.challengeResolved = true;
    this.timeoutforAccept = setTimeout(() => {
      if (!this.cancelUpdate) {
        const body = CHALLENGE_ACCEPTED;
        this._updateFleetIncidents(incident, body);
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * @description: funrtion to reject the challenges incidents
   * @param: the incident details
   */
  public rejectChallenge(incident) {
    this.hideCancel = false;
    incident.challengeAccepted = false;
    incident.challengeResolved = true;
    this.timeoutforReject = setTimeout(() => {
      if (!this.cancelUpdate) {
        const body = CHALLENGE_REJECTED;
        this._updateFleetIncidents(incident, body);
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * @description: function to make a incident as bug
   * @param: the incident details
   */
  public reportBug(incident) {
    this.hideCancel = false;
    incident.challengeAccepted = true;
    incident.challengeResolved = true;
    this.isBugReported = true;
    this.timeoutforReportBug = setTimeout(() => {
      if (!this.cancelUpdate) {
        this.isBugReported = true;
        const body = REPORT_BUG;
        this._updateFleetIncidents(incident, body);
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * @description: function to cancel the snackbar based on user input in the snackbar
   * @param: the status of the snackbar and the incident details
   */
  public snackbarCancel(status, incident) {
    if (status === 'accept') {
      clearTimeout(this.timeoutforAccept);
      incident.challengeAccepted = false;
      incident.challengeResolved = false;
    } else if (status === 'bug') {
      clearTimeout(this.timeoutforReportBug);
      incident.challengeAccepted = false;
      incident.challengeResolved = false;
    } else {
      clearTimeout(this.timeoutforReject);
      incident.challengeAccepted = false;
      incident.challengeResolved = false;
    }
    this.hideCancel = true;
  }

  /**
   * @description: function used to invalidate the size of map before it is showed in the dom
   * @param:
   */
  public setInvalidateSize() {
    this.map.invalidateSize(true);
  }

  /**
   * @description: function to open the enhance video modal
   * @param:
   */

  public openEnhanceVideoModal() {
    this._modalParams.state = true;
    this.showEnhanceBtn = false;
    this._modalParams.component = 'enhance';
    this._zcfleet.enhanceVideoModal.next(this._modalParams);
  }

  /**
   * @description: toggle the enhance incident modal dropdown
   * @param:
   */
  public toggleEnhanceBtn() {
    this.showEnhanceBtn = !this.showEnhanceBtn;
  }

  /**
   * @description: function to check if clicked inside based on user input
   * @param: clicked inside boolean value
   */
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      this.showEnhanceBtn = false;
    }
  }

  // Subscribing to modal show event
  private _initModal() {
    if (this.mapId !== 'eventMap9') {
      this._mapSubscription = this._mapService
        .eventModal()
        .subscribe((event) => {
          this.event = event;
          if (window.innerWidth > 1200) {
            this.setMapView(event.latitude, event.longitude);
          }
          const mediaSrc = event.eventVideoFilename;
          if (mediaSrc) {
            this.isEventMediaAvailable = true;
          } else {
            this.isEventMediaAvailable = false;
          }

          if (mediaSrc && mediaSrc.indexOf('.jpg') > -1) {
            this.media = 'image';
          } else {
            this.media = 'video';
          }
          this.modal.show();
          this.modal.onHide.subscribe(this.handleOnCloseModal.bind(this));
        });
    }
  }

  /**
   * @description: loading the map for the initial time
   * @param:
   * @returns: the map service
   */
  private _loadMap() {
    const mapHeightSpecific = [192, 200, 250];
    // Load the map by passing map ID
    const mapOptionsForTripCard =
      this.mapheight === 200
        ? {
          zoomControl: false,
          doubleClickZoom: false,
          dragging: false,
          keyboard: false,
          scrollWheelZoom: false,
          touchZoom: false,
          interactive: false,
          attributionControl: true,
        }
        : { zoomControl: true, attributionControl: true };
    this.map = this._mapService.getMapInstance(this.mapId, {
      ...mapOptionsForTripCard,
      scrollWheelZoom: false,
      zoomControl: mapHeightSpecific.includes(this.mapheight) ? false : true,
      doubleClickZoom: mapHeightSpecific.includes(this.mapheight)
        ? false
        : true,
      dragging: mapHeightSpecific.includes(this.mapheight) ? false : true,
      keyboard: mapHeightSpecific.includes(this.mapheight) ? false : true,
      touchZoom: mapHeightSpecific.includes(this.mapheight) ? false : true,
      attributionControl: true,
    });

    this.map.on('zoomend', () => {
      this._setActiveIncidentMapIcon();
    });
    if ([192, 200].includes(this.mapheight)) {
      this.map.dragging.disable();
    } else {
      this.map.dragging.enable();
    }
    // Add openstreet map by passing map instance
    this._mapService.addOpenstreetTile(this.map);
  }

  private _addMarker(markerList: any[]) {
    // Remove a marker layer if already present
    if (this._markeGroup && markerList.length) {
      this._markeGroup.remove(this.map);
    }

    // Add marker to map if any exist
    if (markerList.length) {
      this._markeGroup = this._mapService.getFeatureGroup(markerList);
      this._markeGroup.addTo(this.map);
      this.map.fitBounds(this._markeGroup.getBounds());
    }
  }

  private _addPath(latlongList: any[]) {
    if (this._path) {
      this._path.remove(this.map);
    }
    if (this.mapId === 'eventMap9') {
      this._path = this._mapService.getPolyline(latlongList, true);
    } else {
      this._path = this._mapService.getPolyline(latlongList, false);
    }
    this._path.addTo(this.map);
    this.map.fitBounds(this._path.getBounds());
  }

  /**
   * @description: private function that adjust map based on parent size
   * @param:
   * @returns:
   */
  private _fitToPathBounds() {
    if (this._path) {
      this.map.fitBounds(this._path.getBounds());
    }
  }

  private _fitToHighlightedPathBounds() {
    if (this._highlightedPath && this.highlightedLatLonList.length) {
      this.map.fitBounds(this._highlightedPath.getBounds());
    }
  }

  /**
   * @description: update the fleet incident and perform http calls based on the type of challenges incident
   * @param: the incident details and the body containing the changes
   */
  private _updateFleetIncidents(incident, body) {
    if (this.driverId && this.tripId) {
      const eventIndex = Number(incident.eventIndex);
      const emitVal = { eventIndex, body };
      this._zcfleet
        .updateFleetIncidents(this.driverId, this.tripId, eventIndex, body)
        .subscribe(
          () => {
            this.updateMapMarkers.emit(eventIndex);
          },
          (err) => {
            this.updateMapMarkers.emit(emitVal);
            console.log(err);
          }
        );
    }
  }

  private _setActiveIncidentMapIcon() {
    for (let i = 0; i < this.markerList.length; ++i) {
      if (this.markerList[i]) {
        const element = this.markerList[i].getElement();
        if (this.incidentIndex !== undefined && this.incidentIndex !== null) {
          if (i !== this.incidentIndex) {
            // this incident is the focused one
            this._setTransparentMarker(element, true);
            this._scaleMarker(element, true);
          } else {
            this._setTransparentMarker(element, false);
            this._scaleMarker(element, false);
          }
        } else {
          this._setTransparentMarker(element, false);
          this._scaleMarker(element, true);
        }
      }
    }
  }

  private _setTransparentMarker(element, isTransparent: boolean) {
    const setMethodName = isTransparent ? 'add' : 'remove';
    element.classList[setMethodName](TRANSPARENT_CLASS);
  }

  private _scaleMarker(element, isReverseScale: boolean) {
    const currentStyle: string = element.style['transform'] || '';
    if (!isReverseScale && currentStyle.includes(SCALE_3D_TRANSFORM_STRING)) {
      return;
    }
    if (isReverseScale) {
      element.style['transform'] = currentStyle.replace(
        SCALE_3D_TRANSFORM_STRING,
        ''
      );
    } else {
      element.style['transform'] = currentStyle + SCALE_3D_TRANSFORM_STRING;
    }
  }
}
