<div class="zc-body zc-app-wrap has-search-filter">
    <div class="container-fluid content-wrap">
        <app-account-config></app-account-config>
        <div class="row">
            <div class="col-md-12">
                <div class="box">
                    <h5>Asset Configuration</h5>
                    <app-asset-config></app-asset-config>
                </div>
            </div>
        </div>
    </div>
</div>
