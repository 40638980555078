/*
  @Tit1le: Flagged Incidents page
  @Author: Stella Maria Mendonca
  @Description: Displays cards of challenged incidents with its trip details.
*/
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Marker } from 'leaflet';

import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { LoadingService } from '@app-core/services/loading.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { ChallengesModel } from '@modules/dashboard3/dashboard3.models';
import { MapService } from '@modules/dashboard3/services/map.service';
import { Title } from '@angular/platform-browser';
import { EDVR_SWITCH, EVENTS_INCIDENT_TYPE } from '@app-core/constants/constants';
import {
  createMomentObjectUtc,
  getTimezoneString,
} from '@modules/shared/utils';

const CHALLENGE_ACCEPTED = {
  isAccepted: true,
};

const CHALLENGE_REJECTED = {
  isAccepted: false,
};

const REPORT_BUG = {
  isReportBug: true,
};
@Component({
  selector: 'app-flagged-incidents',
  templateUrl: './flagged-incidents.component.html',
  styleUrls: ['./flagged-incidents.component.scss'],
})
export class FlaggedIncidentsComponent implements OnInit, OnDestroy {
  public limit = 10;
  public offset = 0;
  public show = 'loading';
  public showSort = 'recent';
  public incidentList = [];
  public cardDetail: any[] = [];
  public currentPage = 0;
  public filterOptions;
  public sortBy = 'timestamp';
  public showDropdown = false;
  public cancelUpdate = false;
  public hideCancel = false;
  public showPopup = '';
  public timeoutforReject;
  public timeoutforAccept;
  public timeoutforReportBug;
  public endOfList = false;
  public showEnhanceVideoPopup = false;
  public imgSrc = 'assets/images/path@2x.png';
  public sortOptions = {
    recent: 'recent',
    leastRecent: 'leastRecent',
    category: 'category',
  };
  public flaggedIncidentsCount = null;
  public currentCount = 10;
  public sortType = 'desc';
  public activeFilter = 'mostRecent';
  public displayFilter = 'Most - Least Recent';

  public edvrSwitch = EDVR_SWITCH;

  private _filterChangeSubscription: any;

  constructor(
    public router: Router,
    private _dateService: DateService,
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _data: Data,
    private _mapService: MapService,
    private _title: Title
  ) {}

  public ngOnInit() {
    this.getData();
    this._title.setTitle('Flagged Incidents - Zonar Coach');
    this.activeFilter = 'mostRecent';
    this.displayFilter = 'Most - Least Recent';
    this.flaggedIncidentsCount = Number(
      localStorage.getItem('flaggedIncidentsCount')
    );
  }

  public ngOnDestroy() {
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  public driverChange(driver) {
    this.gotoDriverDetail(driver);
  }

  // Naviagate to Driver page
  public gotoDriverDetail(driver) {
    // Check if all the details are present to navigate successfully
    if (driver && driver.driverId) {
      // Replace the current url with the next url by setting a flag
      // in the router, so that on back, we go to the fleet page instead
      // of the previous deriver
      this.router.navigate(['/driver-detail'], {
        replaceUrl: true,
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }

  public goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  public toggleMenu() {
    this.showDropdown = !this.showDropdown;
  }

  public clickedInside(clickedInside) {
    if (!clickedInside) {
      this.showDropdown = false;
    }
  }
  /**
   * makes the api call to fetch challenged incident list
   */
  public getData() {
    this.filterOptions = this._data.filterData;
    const { days } = this.filterOptions;
    let date;
    if (days === 2) {
      const from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      const to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      date = { from, to };
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    const params = new ChallengesModel(
      date.from,
      date.to,
      this.limit,
      this.offset
    );
    const OPTIONS = {
      params: {
        ...params,
        raised: true,
        resolved: false,
        sort: this.sortType,
        sortBy: 'timestamp',
      },
    };
    this._loading.show();
    this._zcfleet.getFleetIncidents(OPTIONS).subscribe(
      (res: any) => {
        this._loading.hide();

        res.map((incident, index) => {
          this.getTripDetail(incident, index);
        });
        this.incidentList = this.incidentList.concat(res);
        this.show = this.incidentList.length !== 0 ? 'data' : 'empty';
      },
      (err) => {
        this._loading.hide();
        console.log('error while fetching fleet data', err);
        this.show = 'error';
      }
    );
  }

  /**
   * makes the trip detail api call for each incident's trip
   */
  public getTripDetail(incident?, index?) {
    const driverId = incident.driverId;
    const tripId = incident.tripId;
    const timestampUTC = incident.timestampUTC;
    let startLocation;
    let endLocation;
    this._loading.show();
    this._zcfleet.getTripDetails(driverId, tripId).subscribe(
      async (res) => {
        // this._loading.hide();
        ({ startLocation: startLocation, endLocation: endLocation } =
          await this._getStartAndEndLocation(res.pathInfo));
        const eventsList = this._mapService.combineEvents(res);
        const markerList = this.loadEventsLocation(eventsList, incident);
        const pathList = this._mapService.preparePathList(res.pathInfo || []);
        res.disputeTimeMoment = createMomentObjectUtc(timestampUTC);
        const {
          firstLocation: { latitude = 0, longitude = 0 } = {},
          timezoneOffset = 0,
        } = res || {};
        const disputeTimeMoment = res.disputeTimeMoment.tz(
          getTimezoneString(timezoneOffset, latitude, longitude)
        );
        // to avoid mismatch in data in case of selected other sorts, 2 variables are maintained
        if (this.offset > 0) {
          index = index + this.offset;
        }
        this.incidentList[index] = {
          ...this.incidentList[index],
          markerList: [...markerList],
          pathList: [...pathList],
          startLocation,
          endLocation,
          showDriverName: false,
          showEnhanceVideoPopup: false,
          disputeTimeMoment,
          ...res,
        };
        this._loading.hide();
      },
      (err) => {
        this._loading.hide();
        console.log('Trip details error--', err);
      }
    );
  }

  /**
   * makes the reversegeodecode api call to get start and end location using latitude and longitude
   */
  public async getAddress(lat, lng) {
    try {
      const address: any = await this._mapService.getReverseGeoCode(lat, lng);
      return address.address.City || address.address.Region || 'Unknown';
    } catch (error) {
      return 'Unknown';
    }
  }

  /**
   * checks if the src url consists of jpg file or not
   */
  public isImage(src) {
    if (src.indexOf('.jpg') > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * allots icons for markers(incidents) present in the map based on challenged incident(big circled icon)
   * and other incidents(small circled icons) occured during the trip
   */
  public loadEventsLocation(eventList: any[], incident) {
    /**
     * Create a marker list
     */
    const markerList: Marker<any>[] = [];
    const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);

    eventList
      .filter((evt) => eventTypeAllowedList.includes(evt.eventType))
      .map((ele) => {
        const { latitude, longitude } = ele;
        // Exclude markers with lat lon 0
        let icon;
        if (+latitude || +longitude) {
        // generate an Icon
          if (ele.eventIndex === incident.eventIndex) {
            icon = this._mapService.getIconforFlaggedIncidents(
              ele.eventType + '-circle'
            );
          } else {
            icon = this._mapService.getSmallerIconforFlaggedIncidents(
              ele.eventType + '-circle'
            );
          }
          // Generate a marker with the icon
          const marker = this._mapService.getMarker(
            +latitude,
            +longitude,
            icon,
            false,
            false
          );
          // Push the markers in the markers list
          markerList.push(marker);
        }
      });
    return markerList.slice();
  }

  /**
   * show and hide the name of driver in the card
   */
  public showDriver(i) {
    this.incidentList[i].showDriverName = !this.incidentList[i].showDriverName;
  }

  /**
   * to load 10 more challenged incidents.
   */
  public loadMore() {
    this.offset += this.limit;
    this.currentCount += 10;
    this.getData();
  }

  /**
   * report bug api call is made
   */
  public reportBug(incident) {
    this.showPopup = 'bug';
    this.hideCancel = false;
    this.timeoutforAccept = setTimeout(() => {
      if (!this.cancelUpdate) {
        const body = REPORT_BUG;
        this._updateFleetIncidents(incident, body);
        incident.challengeAccepted = true;
        incident.challengeResolved = true;
        this.showPopup = '';
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * a snackbar with suitable message is displayed for 5 seconds with a cancel button
   * if the cancel button is pressed then no action should be taken
   * if cancel button pressed within 5 seconds, then a patch call is made with param isAccepted: true
   */
  public acceptChallenge(incident) {
    this.showPopup = 'accepted';
    this.hideCancel = false;
    this.timeoutforAccept = setTimeout(() => {
      if (!this.cancelUpdate) {
        const body = CHALLENGE_ACCEPTED;
        this._updateFleetIncidents(incident, body);
        incident.challengeAccepted = true;
        incident.challengeResolved = true;
        this.showPopup = '';
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * a snackbar with suitable message is displayed for 5 seconds with a cancel button
   * if the cancel button is pressed then no action should be taken
   * if cancel button pressed within 5 seconds, then a patch call is made with param isAccepted: false
   */
  public rejectChallenge(incident) {
    this.showPopup = 'reject';
    this.hideCancel = false;
    this.timeoutforReject = setTimeout(() => {
      if (!this.cancelUpdate) {
        const body = CHALLENGE_REJECTED;
        this._updateFleetIncidents(incident, body);
        incident.challengeAccepted = false;
        incident.challengeResolved = true;
        this.showPopup = '';
      }
      this.cancelUpdate = false;
      this.hideCancel = true;
    }, 5000);
  }

  /**
   * triggers on click of cancel button in the snackbar,
   * stops from executing acceptChallenge or rejectChanllege
   */
  public snackbarCancel(status) {
    if (status === 'accept') {
      clearTimeout(this.timeoutforAccept);
    } else if (status === 'reject') {
      clearTimeout(this.timeoutforReject);
    } else {
      clearTimeout(this.timeoutforReportBug);
    }
    this.showPopup = '';
    this.hideCancel = true;
  }

  public openEnhanceVideoModal(incident) {
    incident.showEnhanceVideoPopup = true;
  }

  public closeEnhanceVideoPopup(incident) {
    incident.showEnhanceVideoPopup = false;
  }

  public onFiltering(filterValue) {
    this.limit = 10;
    this.offset = 0;
    this.showDropdown = false;
    if (filterValue === 'mostRecent') {
      this.sortType = 'desc';
      this.activeFilter = 'mostRecent';
      this.displayFilter = 'Most - Least Recent';
      this.incidentList = [];
      this.getData();
    } else if (filterValue === 'leastRecent') {
      this.sortType = 'asc';
      this.activeFilter = 'leastRecent';
      this.displayFilter = 'Least - Most Recent';
      this.incidentList = [];
      this.getData();
    } else if (filterValue === 'aZCategory') {
      this.activeFilter = 'aZCategory';
      this.displayFilter = 'Incident Category (A-Z)';
      this.sortIncidentByAZ();
    }
  }

  public sortIncidentByAZ() {
    this.incidentList.sort((firstVal, secondVal) =>
      firstVal.eventType > secondVal.eventType
        ? 1
        : secondVal.eventType > firstVal.eventType
          ? -1
          : 0
    );
  }

  /**
   * returns the startlocation and endlocation
   */
  private async _getStartAndEndLocation(pathInfo) {
    try {
      const { latitude: startLocationLat, longitude: startLocationLng } =
        pathInfo[0];

      const { latitude: endLocationLat, longitude: endLocationLng } =
        pathInfo[pathInfo.length - 1];
      const locations = await Promise.all([
        this.getAddress(startLocationLat, startLocationLng),
        this.getAddress(endLocationLat, endLocationLng),
      ]);
      return {
        startLocation: locations[0],
        endLocation: locations[1],
      };
    } catch (error) {
      return {
        startLocation: 'Unknown',
        endLocation: 'Unknown',
      };
    }
  }

  /**
   * challenges patch call with param isAccepted:boolean
   */
  private _updateFleetIncidents(incident, body) {
    const eventIndex = Number(incident.eventIndex);
    this._zcfleet
      .updateFleetIncidents(
        incident.driverId,
        incident.tripId,
        eventIndex,
        body
      )
      .subscribe(
        () => {},
        (err) => {
          console.log(err);
        }
      );
  }
}
