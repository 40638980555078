<header>
  <nav class="navbar fixed-top navbar-expand-lg">
    <div class="header-background">
      <div class="header-wrap">
        <div class="navbar-total clearfix">
          <div class="navbar-left" data-test="left-navigation-bar">
            <!-- <a class="logo" data-test="app-logo" routerLink="/dashboard">
              <img src="assets/images/coach_logo.svg">
            </a> -->
            <!-- <span class="divider desktop-version"></span> -->
            <a class="logo-text" data-test="app-logo" routerLink="/dashboard">Coach</a>
            <!---span class="beta-text">beta</span>-->
          </div>
          <div class="navbar-right mobile-version" [class.active]="isOpen">
            <button class="navbar-toggler" type="button" (click)="toggleMenu($event)">
              <img src="assets/images/user.png">
            </button>
          </div>
        </div>

        <div class="nav drop-down " [class.hideMenu]="!isOpen" appDocumentClick (clickedInside)="clickedInside($event)">

          <div class="nav-flag" data-test="nav-flag" (click)="toggleFlaggedMenu()">
            <img src="assets/images/flag.png">
            <div *ngIf="storage.showNotification" class="notification">

            </div>
          </div>

          <div class="show-list flagged-list" *ngIf="showFlaggedDropdown">

            <div class="category" (click)="goToFlaggedIncidents()">
              Review flagged incidents ({{ challengedIncidentCount || '0' }})
            </div>

          </div>

          <div class="dividerpipe">

          </div>
          <div class="nav-item">
            <a class="profile" (click)="toggleMenu()">
              <span class="login-name" data-test="username">
                {{ userName }}
              </span>
              <span class="account-code pull-right" data-test="account-code">{{ userAccount }}</span>
            </a>
          </div>
          <!-- <i class="fa fa-angle-down ml-1"></i> -->
          <a class="dropdown-arrow desktop-version" [class.hide]="!isOpen" (click)="toggleMenu()">
            <img src="assets/images/arrow_up_white.png">
          </a>
          <a class="dropdown-arrow desktop-version" [class.hide]="isOpen" (click)="toggleMenu()">
            <img src="assets/images/arrow_down_white.png">
          </a>
          <div class="drop-down-menu" [class.show]="isOpen">
            <a class="drop-down-item" routerLink="/settings" (click)="toggleMenu()">
              <img src="assets/images/settings.png">
              <span>Settings</span>
            </a>
            <a class="drop-down-item" (click)="toggleMenu()" routerLink="/package-configuration"
              *ngIf="access.canAccess | async">
              <i class="fa fa-list-ul"></i>
              <span>Package Configuration</span>
            </a>
            <a class="drop-down-item" (click)="toggleMenu()" routerLink="/exception-info"
              *ngIf="access.canAccess | async">
              <i class="fa fa-exclamation-triangle"></i>
              <span>Exception Info</span>
            </a>
            <a class="drop-down-item" (click)="toggleMenu()" routerLink="/unconnected-trips"
              *ngIf="access.canAccess | async">
              <i class="fa fa-plug"></i>
              <span>Unconnected Trips</span>
            </a>
            <a class="drop-down-item" (click)="logout()">
              <img src="assets/images/logout.png">
              <span>Log Out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-secondary">
      <div class="header-wrap">
        <ul class="nav navbar-nav">
          <li class="nav-item" *ngFor="let navigationItem of navigationItems">
            <a *ngIf="canAccess(navigationItem.routerLink) | async" class="nav-link"
              routerLink="/{{navigationItem.routerLink}}" routerLinkActive='nav-selected'>{{navigationItem.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
