import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { MapService } from '@modules/dashboard3/services/map.service';
import { MapComponent } from '@modules/dashboard3/components/map/map.component';
import { TARGET_VIDEO_REQUEST_CONFIGURATION } from '@app-core/constants/constants';

export const MAX_DVR_DURATION_TIME = 60000;
const SECOND_PER_MINUTE = 60;
const MILLISECOND_PER_MINUTE = 60 * 1000;

@Component({
  selector: 'app-capture-new-incident-model',
  templateUrl: './capture-new-incident-model.component.html',
  styleUrls: ['./capture-new-incident-model.component.scss'],
})
export class CaptureNewIncidentModelComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  @Input() public incidentList: any;
  @Input() public starttime = '';
  @Input() public endtime = '';

  @Input() public getLatLongList = [];
  @Input() public tripDetail = null;
  @Input() public getFullList = [];
  @Input() public latestCapturedPosition = null;
  @Input() public displayTimeZone = null;

  @ViewChild('mapComponent') public mapComponent: MapComponent;

  public draggableCircleOption = {
    color: 'blue',
    fill: true,
    fillOpacity: 1,
    radius: 15,
  };
  public captureDVRClipParams = null;
  public latLongList = [];
  public modalSubscription;
  public displayComponent;
  public incidents: any[] = [];
  public currentTime = null;
  public timeDiffInSeconds = null;
  public markerList = [];
  public currentTimeInUTC = null;
  public driverId = '';
  public tripId = '';
  public driverName = '';
  public highlightLatLon = [];
  public showMap = false;
  public clickedMarkedPos = [];
  public currentSliderValue = 0;
  public iconOptions = {
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  };
  // eslint-disable-next-line max-len
  public emailValidationRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public currentTimeDisplayPosition = 1;
  public currentTimeDisplayPositionOffset = 0;
  // offset value in minutes
  public ongoingTripEndTimeOffset = 5;
  public sliderStartTime = null;
  public sliderEndTime = null;
  public sliderMaxValue = 0;
  public tripStartLocationTimezoneOffset = null;

  constructor(private _zcfleet: ZCFleetService, private _mapService: MapService, private _loading: LoadingService,
    private _toaster: ToasterService) {
    this.modalSubscription = this._zcfleet.captureIncidentModal.subscribe(params => {
      if (params.state && params.component === 'capture') {
        this.displayComponent = params.component;
        this.modal.show();
        this.incidentList.forEach(data => this.gettimelist(data));
      }
    });
  }

  public ngOnInit() {}

  public updateEventList(e) {
    console.log(e);
  }

  public ngOnChanges() {
    this.latLongList = [...this.getLatLongList];
    if (this.tripDetail) {
      if (this.tripDetail['ongoing']) {
        this._generateEndTimeOngoingTrip(this.ongoingTripEndTimeOffset);
      } else {
        this._generateSliderStartEndTime();
      }
    }
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  /**
   * @description: function to hide modal
   * @param:
   */
  public hideModal() {
    this.modal.hide();
    this.incidents = [];
  }

  /**
   * @description: function to show modal
   * @param:
   */
  public show() {
    this._loading.show();
    setTimeout(() => {
      this.displayMap();
      this._loading.hide();
    }, 100);
  }

  public ngAfterViewInit() {
  }

  /**
   * @description: function to get time list based on data
   * @param: time data
   */
  public gettimelist(data) {
    const tripstarttime = new Date(this.starttime).getTime();
    const tripendtime = new Date(this.endtime).getTime();
    const duration = tripstarttime - tripendtime;
    const eventtime = new Date(data.time).getTime();
    const differnttime = tripstarttime - eventtime;
    const eventdifftime = (differnttime / duration) * 100;
    const timelist = { timetaken: eventdifftime };
    const overall = { ...data, ...timelist };
    this.incidents.push(overall);
  }

  /**
   * @description: function to get slider value based on user input change
   * @param: slider value
   */
  public getSliderValue(sliderValue) {
    this.currentSliderValue = sliderValue;
    const rangeSliderThumbWidth = 20;
    this.currentTimeDisplayPosition = this.sliderMaxValue > 0 ?
      (sliderValue  / this.sliderMaxValue) * 100 : 0;
    this.currentTimeDisplayPositionOffset =
      Math.round(rangeSliderThumbWidth *
        this.currentTimeDisplayPosition / 100) - (rangeSliderThumbWidth / 2);
    this._getCurrentTimeBasedOnSliderValue(sliderValue);
  }

  /**
   * @description: function to display map on initial load of the page
   * @param:
   */
  public displayMap() {
    this.highlightLatLon = [];
    this.showMap = true;
    const firstLatLng = this.latLongList[0];
    this.currentSliderValue = 0;
    this.setCircleMarker(firstLatLng['lat'], firstLatLng['lng']);
    this.getSliderValue(0);
  }

  /**
   * @description: function to set circle marker draggable to intial position
   * @param: lat and long
   */
  public setCircleMarker(lat, long) {
    const markerList = [];
    const circleIcon = this._mapService.getIcon('circle-marker', this.iconOptions);
    const icon = this._mapService.getMarker(lat, long, circleIcon, false, true);
    markerList.push(icon);
    this.markerList = markerList.slice();
  }

  /**
   * @description: function to display one min path on map over the path
   * @param: time difference in seconds
   */
  public showOneMinPathOnMap(timeDiffInSeconds) {
    if (!this.getFullList) {
      return;
    }
    const newTime = this.getFullList[0].timestampUTC + (timeDiffInSeconds * 1000);
    let newMarkerPosition = null;
    for (let iterator = 0; iterator < this.getFullList.length; iterator++) {
      if (this.getFullList[iterator].timestampUTC > newTime) {
        newMarkerPosition = iterator;
        break;
      }
      newMarkerPosition = this.getFullList.length - 1;
    }
    const newlatlong = this.getFullList[newMarkerPosition];
    this.setCircleMarker(newlatlong.latitude, newlatlong.longitude);

    const getOneMinPath = this.getFullList[newMarkerPosition].timestampUTC + (MILLISECOND_PER_MINUTE);
    let nextMarkerPosition = null;
    for (let iterator = newMarkerPosition; iterator < this.getFullList.length; iterator++) {
      if (this.getFullList[iterator].timestampUTC > getOneMinPath) {
        nextMarkerPosition = iterator;
        break;
      }
    }

    const newHighlightArray = this.getFullList.slice(newMarkerPosition, nextMarkerPosition);
    const newArr = [];
    for (let iterator = 0; iterator < newHighlightArray.length; iterator++) {
      newArr[iterator] = {
        lat: newHighlightArray[iterator].latitude,
        lng: newHighlightArray[iterator].longitude,
      };
    }
    this.highlightLatLon = newArr;
  }

  /**
   * @description: function to marker position
   * @param: position
   */
  public getClickedMarkerPos(position) {
    let correctMarkerPos = null;
    for (let iterator = 0; iterator <= this.getFullList.length; iterator++) {
      if (position && position.lat.toFixed(2) === this.getFullList[iterator].latitude.toFixed(2) &&
        position.lng.toFixed(2) === this.getFullList[iterator].longitude.toFixed(2)) {
        correctMarkerPos = this.getFullList[iterator];
        break;
      }
    }
    this.setCircleMarker(correctMarkerPos.latitude, correctMarkerPos.longitude);
    const fullListLength = this.getFullList.length;
    const startTimeInMilliSecondsFromEpoch = new Date(this.getFullList[0].timestampUTC).getTime();
    const endTimeInMilliSecondsFromEpoch = new Date(this.getFullList[fullListLength - 1].timestampUTC).getTime();
    const markerTimeInMilliSecondsFromEpoch = new Date(correctMarkerPos.timestampUTC).getTime();
    const getMarkerPosTimeStamp = (markerTimeInMilliSecondsFromEpoch - startTimeInMilliSecondsFromEpoch) / 1000;
    const getFullListTimeStamp = (endTimeInMilliSecondsFromEpoch - startTimeInMilliSecondsFromEpoch) / 1000;
    this.currentSliderValue = (Math.round(getMarkerPosTimeStamp) / Math.round(getFullListTimeStamp)) * 100;
    this._getCurrentTimeBasedOnSliderValue(this.currentSliderValue);
    this.showOneMinPathOnMap(getMarkerPosTimeStamp);
  }

  /**
   * @description: Calculate current time in UTC captureDVRStartTime and captureDVREndTime
   * @param: currentTimeInUTC, expectedDuration
   */
  public calculateCaptureDVRCurrentTimeInUTC(currentTimeInUTC: number, expectedDuration: number): [string, string] {
    const dvrStartTime = new Date(new Date(currentTimeInUTC).toUTCString()).toISOString();
    const dvrEndTime = new Date(new Date(currentTimeInUTC + expectedDuration).toUTCString()).toISOString();
    return [dvrStartTime, dvrEndTime];
  }

  /**
   * @param dvrDuration the DVR duration object
   * @param tripDuration the trip duration object
   * @return bounded duration object
   */
  public correctDVRDuration(dvrDuration: [number, number],
    tripDuration: [number, number]): [number, number] {
    const START_INDEX = 0;
    const END_INDEX = 1;

    const tripDurationInMilliS = tripDuration[END_INDEX] - tripDuration[START_INDEX];
    const dvrDurationInMillis = dvrDuration[END_INDEX] - dvrDuration[START_INDEX];

    if (dvrDurationInMillis > tripDurationInMilliS) {
      return [tripDuration[START_INDEX], tripDuration[END_INDEX]];
    }

    // If the control reach this line of code, dvrDuration will always be less than
    // trip duration, so we only have case of far left and far right.
    const calculateOffset = (expectedGreater, expectedLesser) => {
      const different = expectedGreater - expectedLesser;
      return different > 0 ? different : 0;
    };

    // leftOffset and rightOffset cannot both be !== 0
    const leftOffset = calculateOffset(tripDuration[START_INDEX], dvrDuration[START_INDEX]);
    const rightOffset = 0 - calculateOffset(dvrDuration[END_INDEX], tripDuration[END_INDEX]);
    const arrayDVRDuration = dvrDuration.map(time => time + leftOffset + rightOffset);
    return [arrayDVRDuration[START_INDEX], arrayDVRDuration[END_INDEX]];
  }

  /**
   * @description: function calculate capture DVRTime
   * @param: currentTimeInUTC, tripDuration[]
   */
  public calculateDVRDuration(currentTimeInUTC: number, tripDuration: [string, string]): [number, number] {
    const dvrDuration = this.calculateCaptureDVRCurrentTimeInUTC(currentTimeInUTC, MAX_DVR_DURATION_TIME);

    const convertToMillisecond = (duration: [string, string]) => duration.map(time => Date.parse(time));

    const dvrDurationMillisecond = convertToMillisecond([dvrDuration[0], dvrDuration[1]]);
    const tripDurationMillisecond = convertToMillisecond([tripDuration[0], tripDuration[1]]);

    return this.correctDVRDuration([dvrDurationMillisecond[0], dvrDurationMillisecond[1]],
      [tripDurationMillisecond[0], tripDurationMillisecond[1]]);
  }

  /**
   * @description: function to capture DVR clip request
   * @param: form values
   * @returns: the capture click DVR status
   */
  public captureDVRClipRequest(form) {
    // form param to be removes if not used in future
    form = form;
    const arrayCaptureDVRDuration = this.calculateDVRDuration(this.currentTimeInUTC,
      [this.tripDetail.startTimeUTC, this.tripDetail.endTimeUTC]);
    const captureDVRStartTime = new Date(arrayCaptureDVRDuration[0]).toISOString();
    const captureDVREndTime = new Date(arrayCaptureDVRDuration[1]).toISOString();
    this.captureDVRClipParams = {
      startTimeUTC: captureDVRStartTime,
      endTimeUTC: captureDVREndTime,
      driverId: this.tripDetail.driverId,
      tripId: this.tripDetail.tripId,
      ...TARGET_VIDEO_REQUEST_CONFIGURATION,
    };
    this.hideModal();
    this._zcfleet.captureDVRClip(this.captureDVRClipParams).subscribe(
      (res) => {
        console.log(res);
        this._toaster.success('DVR Request Successful.');
      },
      (err) => {
        console.log(err);
        this._toaster.error('DVR Request Failed');
      });
  }

  /**
   * @description: function to convert current time of the trip based on slider value
   * @param: slidervalue
   */
  private _getCurrentTimeBasedOnSliderValue(sliderValue) {
    this.timeDiffInSeconds = sliderValue * SECOND_PER_MINUTE;
    const time = new Date(this.sliderStartTime);
    const timeUTC = new Date(this.tripDetail.startTimeUTC);
    this.currentTime = (time.setSeconds(time.getSeconds() + this.timeDiffInSeconds));
    this.currentTimeInUTC = (timeUTC.setSeconds(timeUTC.getSeconds() + this.timeDiffInSeconds));
    this.showOneMinPathOnMap(this.timeDiffInSeconds);
  }

  private _generateEndTimeOngoingTrip(endTimeOffset: number = 0): void {
    if (this.latestCapturedPosition) {
      const latestCapturedTimeStampUTC = this.latestCapturedPosition.timestampUTC;
      const offsetInMsec = (this.tripDetail.timezoneOffset + endTimeOffset) * MILLISECOND_PER_MINUTE;
      const offsetUTCInMsec = endTimeOffset * MILLISECOND_PER_MINUTE;
      this.tripDetail.endTime = (new Date(latestCapturedTimeStampUTC - offsetInMsec)).toISOString();
      this.tripDetail.endTimeUTC = (new Date(latestCapturedTimeStampUTC - offsetUTCInMsec)).toISOString();
      this._generateSliderStartEndTime();
      this.tripDetail.durationLatestPosition = (
        (Date.parse(this.tripDetail.endTimeUTC) -
          Date.parse(this.tripDetail.startTimeUTC)) /
          1000
      );
    }
  }

  private _generateSliderStartEndTime(): void {
    // get start location timezone offset in minutes
    this.tripStartLocationTimezoneOffset = moment
      .tz(this.displayTimeZone)
      .utcOffset();

    this.sliderStartTime = this._stripSecPart(this.tripDetail.startTimeUTC);
    this.sliderEndTime = this._stripSecPart(this.tripDetail.endTimeUTC);
    this.sliderMaxValue = (new Date(this.sliderEndTime).getTime() - new Date(this.sliderStartTime).getTime()) / MILLISECOND_PER_MINUTE;
  }

  private _stripSecPart(value: string): string {
    // calculate timezone offset
    const offsetInMiliseconds = this.tripStartLocationTimezoneOffset * MILLISECOND_PER_MINUTE;

    const date = (new Date(Date.parse(value) + offsetInMiliseconds));
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.toISOString();
  }
}
