import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// Use this service to show and hide loader for pages and components
@Injectable()
export class LoadingService {
  private _loaderSubject = new Subject<boolean>();
  private _loaderState = this._loaderSubject.asObservable();
  private _loaderReset$ = new Subject<boolean>();

  public get loaderReset$(): Observable<boolean> {
    return this._loaderReset$.asObservable();
  }

  public loader() {
    return this._loaderState;
  }

  public show() {
    this._loaderSubject.next(true);
  }

  public hide() {
    this._loaderSubject.next(false);
  }

  public reset() {
    this._loaderReset$.next(true);
  }
}
