import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash-es';

import { DateService } from '@app-core/services/date.service';
import { LoadingService } from '@app-core/services/loading.service';
import { StorageService } from '@app-core/services/storage.service';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { createMomentObjectUtc, getTimezoneString, calcSumTotalIncidents } from '@modules/shared/utils';

import { DATE_RANGE_TEXT_MAP } from './../../../dashboard3.models';
@Component({
  selector: 'app-driver-trips-table',
  templateUrl: './driver-trips-table.component.html',
  styleUrls: ['./driver-trips-table.component.scss'],
})
export class DriverTripsTableComponent implements OnChanges, OnDestroy {
  @Input() public driverId: string;
  @Input() public driverName: string;
  @Input() public totalTrips: number;
  @Input() public filterOptions: any;
  @Input() public allAssets: any;
  @Input() public isShown = true;

  public reset = false;
  public driverTripListPage = [];

  public dateRangeText = '';
  public show = 'loading';

  public limit = 20;
  public sortBy = 'startTime';
  public sort = 'desc';
  public homeLocationList = [];

  public errorMessage = 'Something went wrong';
  public showRetryButton = true;

  private _offset = 0;
  private _filterChangeSubscription: any;

  // Behaviour subject to pass data of each page into the paginator component
  private _list = new BehaviorSubject([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public listData = this._list.asObservable();

  constructor(
    private _zcfleet: ZCFleetService,
    private _dateService: DateService,
    private _loading: LoadingService,
    private _router: Router,
    private _ref: ChangeDetectorRef,
    private _storageService: StorageService
  ) {
    // Subscribe to filter change event
    this._filterChangeSubscription = this._zcfleet.driverFilterChange.subscribe(
      filterOptions => {
        this.filterOptions = filterOptions;
        this.dateRangeText = DATE_RANGE_TEXT_MAP[filterOptions.days];
        this.getData();
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    // Watch for driverId change and call API
    if (changes.driverId) {
      if (changes.driverId.currentValue) {
        this.getData();
      }
    }
  }

  public ngOnDestroy() {
    // On destruction of the component, unsubscribe from the filter change
    // event to prevent unexpected behaviours
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  /**
   * @description: function to get more driver trip list based on user click
   * @param:
   */
  public getMoreData(page) {
    // update limit and offset values and call the API to get the paginated
    // values
    ({ limit: this.limit, offset: this._offset } = page);
    this._getAPIData()
      .then((res: any) => {
        this._list.next(res);
      })
      .catch(err => {
        console.error(`Received Error: ${err}`);
      });
  }

  public driverTripListPageChange(e) {
    setTimeout(() => {
      this.driverTripListPage = e.list.map(
        (item) => {
          const clonedItem = cloneDeep(item);
          if (clonedItem && clonedItem.eventCount) {
            clonedItem.totalIncidentsEventType = calcSumTotalIncidents(clonedItem.eventCount);
          }
          clonedItem.startTimeMoment = createMomentObjectUtc(clonedItem.startTimeUTC).tz(
            getTimezoneString(item.timezoneOffset, item.firstLocation.latitude, item.firstLocation.longitude)
          );
          return clonedItem;
        });
    }, 1);
  }

  /**
   * @description: function to go to trip details page of particular driver with unique trip
   * @param: trip details
   */
  public gotoTripDetail(trip) {
    // Check if all the details are present to navigate successfully
    if (trip && trip.tripId && this.driverId) {
      // Set from driver flag to true so that we can navigate back to
      // driver page on back click
      this._router.navigate(['/trip-detail'], {
        queryParams: {
          tripId: trip.tripId,
          driverId: this.driverId,
        },
      });
    }
  }

  /**
   * @description: function to get initial load data
   * @param:
   */
  public getData() {
    this.show = 'loading';
    // Call API only when both driverId and no of days are available
    const { days } = this.filterOptions;
    if (!((days || days === 0) && this.driverId)) {
      return;
    }
    // set the offset to 0 and set reset flag to true
    this._offset = 0;
    this.reset = true;
    this._ref.detectChanges();
    this._getAPIData()
      .then((res: any) => {
        this.show = res.length !== 0 ? 'data' : 'empty';
        this._list.next(res);
      })
      .catch(err => {
        console.error(`Received Error: ${err}`);
        const errorHanding = this._zcfleet.getErrorMessage(err);
        this.errorMessage = errorHanding.message;
        this.showRetryButton = errorHanding.showRetryButton;
        this.show = 'error';
      });
  }

  /**
   * @description: function to get driver trip list
   * @param:
   * @returns: a promise with driver trip list
   */
  private _getAPIData() {
    const { days } = this.filterOptions;
    let range;
    if (days === 2) {
      const from = this._dateService.toDaysStartISO(this._dateService.customStartdate);
      const to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      range = { from, to };
    } else {
      range = this._dateService.getDateRangeInISO(days);
    }

    this.homeLocationList = this._storageService.getStorageValue('HOME_LOCATION_ABV');

    const driverTripListParam = {
      params: {
        startDate: range.from,
        endDate: range.to,
        limit: this.limit,
        offset: this._offset,
        sortBy: this.sortBy,
        sort: this.sort,
        divisions: this.homeLocationList,
      },
    };
    return new Promise((resolve, reject) => {
      if (this.isShown) {
        this._loading.show();
      }
      this._zcfleet.getDriverTripList(this.driverId, driverTripListParam).subscribe(
        res => {
          this._loading.hide();
          this.allAssets.assets.map((asset) => {
            res.map((trip) => {
              if (trip._assetNumber === asset.assetId) {
                if (asset.metadata && asset.metadata.assetNumber) {
                  trip.assetNumber = asset.metadata.assetNumber;
                } else {
                  trip.assetNumber = 'N/A';
                }
              }
            });
          });
          resolve(res);
        },
        err => {
          this._loading.hide();
          reject(err);
        }
      );
    });
  }
}
