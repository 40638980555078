import { ExportService } from './../../services/export.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { Subject } from 'rxjs';

import { SearchFilterModel, FilterStrore } from './../../dashboard3.models';
import { FILTER_CONFIG } from './../../dashboard3.constants';
import { Data } from './../../services/data.service';
import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { FILTERS_LIST_CALL_APIS, FilterCommand, FilterOptions } from '../search-filter-table/search-filter-table.component';
import { FilterChangeEvent } from '@app-core/constants/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  @Input() public filterOptions: Record<string, FilterOptions>;
  @Input() public refetchFilter: boolean;
  @Input() public page = '';
  @Input() public backText = '';
  @Input() public showExportButton? = true;

  /** Driver Search Models*/
  @Output()
  public driverChange = new EventEmitter<any>();

  @Output()
  public unassignCamChange = new EventEmitter<any>();

  /** Driver filter */
  @Input()
  public histogramData: any[] = [];
  @Input()
  public histogramPercentile: any[] = [];
  @Input()
  public modeScoreFrequency = 1;
  @Input()
  public driverCount = undefined;
  @Input()
  public fleetMetricChange? = false;

  @Output()
  public filterChange = new EventEmitter<SearchFilterModel>();
  @Output()
  public filterChangeVersion = new EventEmitter<FilterCommand[]>();
  @Output() public backClicked = new EventEmitter<void>();

  public isOpen = false;
  /** Models */
  public days = 7;
  public minScore = 0;
  public maxScore = 100;
  public rangeValue = 1;
  public dutyType = '';
  public homeLocation = '';
  public isExportSet = false;
  public daysTemp: number;
  public minScoreTemp: number;
  public maxScoreTemp: number;
  public rangeValueTemp: number;
  public dutyTypeTemp: string;
  public homeLocationTemp: string;
  public isHomeLocationEditable = true;
  public sentSearchFilterData = null;
  public reloadNavigate: string = window.location.pathname;

  public config: any;

  private _driverIds = [];
  private _resetDriver = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public reset = this._resetDriver.asObservable();


  constructor(
    private _data: Data,
    private _export: ExportService,
    private _dateService: DateService,
    private _zcFleetService: ZCFleetService,
    private _router: Router
  ) {}

  public ngOnInit() {
    this.config = FILTER_CONFIG[this.page];
    this.backText = this.backText
      ? this.backText
      : this.config
        ? this.config.backButton.text
        : '';
    this._getStoredValues();
    this._setOldValues();
    this._emitFilterParam();
    this._setFlaggedIncidentsCount();
  }

  // Set date range temp value
  // Apply filter if width < 900 to update Histogram with
  // the selected date range
  public setDateRange(val) {
    this.daysTemp = val;
    if (val !== 2) {
      this.applyFilter();
    }
  }

  public setDutyType(val) {
    this.dutyTypeTemp = val;
    // if (window.innerWidth <= 900) {
    this.applyFilter();
    // }
  }

  public setHomeLocation(location) {
    this.homeLocationTemp = location;
  }

  // Apply temporary changes as new filter values
  public applyFilter(event: FilterChangeEvent = null) {
    if (event) {
      // case location filter event
      if (FILTERS_LIST_CALL_APIS.includes(event.filterName)) {
        if (!event.isChanged) {
          return;
        }
        this.reload();
      }
    }
    // If date or duty type is changed, reset driver filter
    if (this._filterChanged()) {
      this._resetDriverFilter();
      this._resetDriver.next();
    }
    this._setNewValues();
    // Store new values in service
    if (!this.fleetMetricChange) {
      this._setStoredValues();
    }
    this._emitFilterParam();
    this._setFlaggedIncidentsCount();
  }

  public reload() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([this.reloadNavigate]);
  }

  public applyAllFilters() {
    this._closeFilter();
    this.applyFilter();
  }

  // Discard temporary changes by setting initial filter values
  public discardChanges() {
    this._setOldValues();
  }

  // Driver Search Methods
  public driverSelectionChange(value) {
    this.driverChange.emit(value);
  }

  // Driver Search Methods
  public unassignCamSelectionChange(value) {
    this.unassignCamChange.emit(value);
  }

  // Event emitted when back button arrow is clicked
  public goBack() {
    this.backClicked.emit();
  }

  public toggleFilter() {
    this._resetDriver.next();
    if (this.isOpen) {
      this._closeFilter();
    } else {
      this._openFilter();
    }
  }

  public exportClicked() {
    this._export.showExportModal({
      autoExport: true,
    });
  }

  /**
   * @description: get stored filter values from the local storage
   * @param:
   */
  private _getStoredValues() {
    this.days = this._data.filterData.days;
    this.minScore = this._data.filterData.minScore;
    this.maxScore = this._data.filterData.maxScore;
    this.rangeValue = this._data.filterData.rangeValue;
    this.dutyType = this._data.filterData.dutyType;
    this.homeLocation = this._data.filterData.homeLocation;
    this._driverIds = this._data.filterData.ids;
    this.isHomeLocationEditable = this._data.filterData.isHomeLocationEditable;
  }

  /**
   * @description: set the filter values in local storage
   * @param:
   */
  private _setStoredValues() {
    this._data.filterData = new FilterStrore(
      this.days,
      this.minScore,
      this.maxScore,
      this.rangeValue,
      this.homeLocation,
      this.dutyType,
      this._driverIds
    );
  }

  /**
   * @description: setting the default old values in filter
   * @param:
   */
  private _setOldValues() {
    this.daysTemp = this.days;
    this.minScoreTemp = this.minScore;
    this.maxScoreTemp = this.maxScore;
    this.rangeValueTemp = this.rangeValue;
    this.dutyTypeTemp = this.dutyType;
    this.homeLocationTemp = this.homeLocation;
  }

  /**
   * @description: setting the news temporary values in the search filter
   * @param:
   */
  private _setNewValues() {
    this.days = this.daysTemp;
    this.minScore = this.minScoreTemp;
    this.maxScore = this.maxScoreTemp;
    this.rangeValue = this.rangeValueTemp;
    this.dutyType = this.dutyTypeTemp;
    this.homeLocation = this.homeLocationTemp;

    // from histogram data, extract driverIds within the minScore and
    // maxScore range
    if (this.minScore === 0 && this.maxScore === 100) {
      this._driverIds = [];
    } else {
      this._driverIds = this.histogramData.reduce((e1, e2, index) => {
        if (index >= this.minScore && index <= this.maxScore) {
          e1 = e1.concat(e2.driverIds);
        }
        return e1;
      }, []);
    }
  }

  /**
   * @description: resetting the driver filter values to default values
   * @param:
   */
  private _resetDriverFilter() {
    this.minScore = 0;
    this.maxScore = 100;
    this.rangeValue = 1;
    this.minScoreTemp = this.minScore;
    this.maxScoreTemp = this.maxScore;
    this.rangeValueTemp = this.rangeValue;
  }

  // Check date and dutytype has changed
  private _filterChanged() {
    if (this.days !== this.daysTemp) {
      return true;
    }
    if (this.dutyType !== this.dutyTypeTemp) {
      return true;
    }
    return false;
  }


  // Generate filter params and emit
  private _emitFilterParam() {
    const params = new SearchFilterModel(
      this.days,
      this.minScore,
      this.maxScore,
      this.homeLocation,
      this.dutyType,
      this._driverIds
    );
    this.sentSearchFilterData = params;
    this.filterChange.emit(params);
  }

  private _closeFilter() {
    this.isOpen = false;
  }

  private _openFilter() {
    this.isOpen = true;
  }

  private _setFlaggedIncidentsCount() {
    let date = { from: null, to: null };
    if (this.days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(this.days);
    }

    const params = {
      params: {
        startDate: date.from,
        endDate: date.to,
      },
    };

    this._zcFleetService.violationsAggregate(params).subscribe(
      (res) => {
        localStorage.setItem(
          'flaggedIncidentsCount',
          res.violationsAggregate.totalChallengesPending
        );
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
