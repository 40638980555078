import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() public showPartScreen: boolean;
  constructor() {}

  public modeLoading() {
    if (this.showPartScreen) {
      return { 'part-screen': true, 'full-screen': false };
    }
    return { 'part-screen': false, 'full-screen': true };
  }
}
