import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

@Component({
  selector: 'app-remove-camera-modal',
  templateUrl: './remove-camera-modal.component.html',
  styleUrls: ['./remove-camera-modal.component.scss'],
})
export class RemoveCameraModalComponent implements OnInit, OnDestroy  {

  @ViewChild('modal', {static: true}) public modal: ModalDirective;

  @Input() public componentName = '';
  @Input() public cameraList: string[] = null;

  @Output() public onCameraDelete: EventEmitter<boolean> = new EventEmitter();

  public titleName ='Delete camera';
  public modalSubscription;

  constructor(private _zcfleet: ZCFleetService) {
    this.modalSubscription = this._zcfleet.removeCameraModal.subscribe(param => {
      if (param.state) {
        this.modal.show();
        this._getTitleMessage();
      }
    });
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  public deleteCamera() {
    this.onCameraDelete.emit(true);
    this.hideModal();
  }

  public hideModal() {
    this.modal.hide();
  }

  private _getTitleMessage() {
    if (this.cameraList.length > 1) {
      this.titleName = `Delete ${this.cameraList.length} cameras`;
    } else {
      this.titleName = 'Delete camera';
    }
  }
}
