import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

// Service used to show and hide different type of toast message
@Injectable()
export class ToasterService {
  private _options = {
    timeOut: 3000,
    positionClass: 'toast-bottom-center',
  };
  constructor(private _toastr: ToastrService) {}

  public success(message, options: any = {}) {
    const op = { ...this._options, ...options };
    this._toastr.success(message, '', op);
  }

  public error(message, options: any = {}) {
    const op = { ...this._options, ...options };
    this._toastr.error(message, '', op);
  }

  public warning(message, options: any = {}) {
    const op = { ...this._options, ...options };
    this._toastr.warning(message, '', op);
  }

  public info(message, options: any = {}) {
    const op = { ...this._options, ...options };
    this._toastr.info(message, '', op);
  }
}
