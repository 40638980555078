import { Component, OnInit } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  ///////// FIXED for copy right year /////////
  public date = new Date();

  public appVersion = '';

  public ngOnInit() {
    this.appVersion = environment.version;
  }
}
