import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { StorageService } from '@app-core/services/storage.service';
import { EDVR_SWITCH } from '@app-core/constants/constants';
import { AccessService } from '@app-core/zcw-auth-module/services/access.service';
import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';
import { PermissionManagerService } from '@app-core/services/permission-manager.service';

const COACH_HEADER = [
  'dashboard',
];

const SMART_COACH_HEADER = [
  'incident-response-center',
  'asset-view',
];
// handle all incidents pending comment list
const LOCAL_PENDING_COMMENT_LIST = 'error_modal_comment_obj';
const ASSET_VIEW_TABLE_PAGE_SIZE = 'asset-view-table-page-size';

@Component({
  selector: 'app-header2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() public navigationItems: string[];
  public isOpen = false;
  public showNotification = false;
  public userAccount = '';
  public userName = '';
  public showFlaggedDropdown = false;
  public eDvrList = [];
  public challengedIncidentCount = null;
  public edvrSwitch = EDVR_SWITCH;

  constructor(
    public storage: StorageService,
    public access: AccessService,
    public router: Router,
    private _auth: ZcwAuthenticateService,
    private _permission: PermissionManagerService
  ) {}

  public ngOnInit() {
    this._permission.requestPermissionCheck('fleet-ridecam-plus');
    this._auth.userProfile$
      .pipe(
        map((userProfile) => {
          this.userAccount = userProfile.state || '';
          this.userName = userProfile
            ? userProfile.username || userProfile.email
            : '';
          this.storage.setStorageValue('userProfile', userProfile);
        })
      )
      .subscribe();

    this.challengedIncidentCount = Number(
      localStorage.getItem('flaggedIncidentsCount')
    );
  }

  public logout() {
    this._auth.logout();
    // drop localstorage modal comment data
    this.storage.removeStorageValue(LOCAL_PENDING_COMMENT_LIST);
    // drop localstorage asset-view set pageSize
    this.storage.removeStorageValue(ASSET_VIEW_TABLE_PAGE_SIZE);
  }

  /**
   * @description: check whether the click happened inside the dropdown or not
   * @param: the clicked inside boolean flag
   */
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      this.closeMenu();
      this.showFlaggedDropdown = false;
    }
  }

  /**
   * @description: function to open the navigation menu
   */
  public openMenu() {
    this.isOpen = true;
  }

  /**
   * @description: function to close the navigation menu
   */
  public closeMenu() {
    this.isOpen = false;
  }

  /**
   * @description: toggle the menu open and close based on user click
   */
  public toggleMenu(e?: Event) {
    if (this.isOpen) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
    if (e) {
      e.stopPropagation();
    }
  }

  /**
   * @description: navigate to flagged incidents page if user clicks on flag icon
   */
  public goToFlaggedIncidents() {
    this.router.navigate(['/flagged-incidents']);
  }

  public toggleFlaggedMenu() {
    this.challengedIncidentCount = Number(
      localStorage.getItem('flaggedIncidentsCount')
    );
    this.showFlaggedDropdown = !this.showFlaggedDropdown;
  }

  public canAccess(routerLink: string): Observable<boolean> {
    if (SMART_COACH_HEADER.includes(routerLink)) {
      return this._permission.getPermissionObs('fleet-ridecam-plus');
    } else {
      return COACH_HEADER.includes(routerLink)
        ? of(true) : this.access.canAccess;
    }
  }
}
