<!-- Driver table -->
<div class="row tripboard-heading">
    <div class="col-md-12 p-0">
        <span class="heading-left">Drivers Ranked by Score </span>
    </div>
</div>
<div [ngSwitch]="show" class="drivers-table" >
    <div *ngSwitchCase="'loading'">
        <app-spinner></app-spinner>
    </div>

    <div *ngSwitchCase="'data'">
        <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
        <div class="row no-gutters zc-table-wrap">
            <table class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm">
                <thead>
                    <tr>
                        <th class="width-20" scope="col">
                            <app-table-sort-header sortOn="v2Score" [(sortBy)]="sortBy" [(sort)]="sort" [reverseOrder]="true"
                                (sortClicked)="refreshList()">Rank
                            </app-table-sort-header>
                        </th>
                        <th class="width-20" scope="col">
                            <app-table-sort-header sortOn="driverName" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Driver Name
                            </app-table-sort-header>
                        </th>
                        <th class="width-20" scope="col">
                            <app-table-sort-header sortOn="v2Score" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Average Score
                            </app-table-sort-header>
                        </th>
                        <th class="width-20" scope="col">
                            <app-table-sort-header sortOn="v2ScoreChange" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Score
                            </app-table-sort-header>
                        </th>
                        <th class="width-20" scope="col">
                            <app-table-sort-header sortOn="topIncident" [(sortBy)]="sortBy" [(sort)]="sort"
                                (sortClicked)="refreshList()">Top Incident
                            </app-table-sort-header>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let driver of driverListPage" (click)="gotoDriverDetail(driver)">
                        <td class="width-20">{{driver?.rank}}</td>
                        <td class="width-20">{{driver?.driverName}}</td>
                        <td class="width-20">{{driver?.v2Score}}</td>
                        <td *ngIf="checkIfInteger(driver?.v2ScoreChange)" vclass="width-20">
                            <img class="arrow" *ngIf="driver?.v2ScoreChange < 0" src="assets/images/arrow-down-red.png">
                            <img class="arrow" *ngIf="driver?.v2ScoreChange > 0" src="assets/images/arrow-up-green.png">
                            <img class="minus" *ngIf="driver?.v2ScoreChange === 0" src="assets/images/minus.png">
                            {{getAbsolute(driver?.v2ScoreChange)}}
                        </td>
                        <td *ngIf="!checkIfInteger(driver?.v2ScoreChange)" vclass="width-20">
                            <img class="minus"  src="assets/images/minus.png">
                            <img class="minus"  src="assets/images/minus.png">
                        </td>
                        <td class="width-20">{{driver?.topIncidentType}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="paginator-wrap">
                <app-paginator3 [(reset)]="reset" [pageLimit]="limit" [length]="totalDrivers"
                    [data]="listData" (pageChangeData)="pageChange($event)" (pageChange)="getMoreData($event)">
                </app-paginator3>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'empty'">
        <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                    <span>
                        No drivers found
                    </span>
                </div>
                <div class="note sub">
                    <span>Try different filters to view drivers.</span>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="'error'">
        <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
        <div class="no-records-card">
            <div class="no-records no-records-sm">
                <img src="assets/images/exclamation-circle.svg">
                <div class="note">
                  <span>{{ errorMessage }}</span>
                </div>
                <div class="retry" *ngIf="showRetryButton">
                    <button class="btn btn-retry" (click)="refreshList()">Retry</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Driver table -->
