import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DISTRACTION_DISCLAMER } from '@modules/dashboard3/dashboard3.constants';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface DateRangeISO {
  from: string;
  to: string;
}

@Component({
  selector: 'app-top-incident',
  templateUrl: './top-incident.component.html',
  styleUrls: ['./top-incident.component.scss'],
})

export class TopIncidentComponent implements OnInit {
  @Input()
  public set reload$(value: Observable<boolean>) {
    const totalIncidentState = merge(value, this.internalTotalIncident$);
    const topIncidentState = merge(value, this.internalTopIncidentData$);
    const mapper = map(mergeValue => mergeValue !== true);

    this.notLoadingState = {
      totalIncident: totalIncidentState.pipe(mapper),
      topIncidentData: topIncidentState.pipe(mapper),
    };
  }
  @Input() public range: DateRangeISO;
  @Input() public displayAmount = 5;
  @Input()
  public set totalIncident$(value: Observable<number>) {
    this.internalTotalIncident$ = value.pipe(shareReplay());
  }
  @Input()
  public set topIncidentData$(value: Observable<IncidentStat[]>) {
    this.internalTopIncidentData$ = value.pipe(map(res => this.filterIncidentList(res)), shareReplay());
  }
  public notLoadingState: {
    totalIncident: Observable<boolean>;
    topIncidentData: Observable<boolean>;
  };
  public isDistractedDisclaimerFlag = false;
  public distractionDisclaimer = DISTRACTION_DISCLAMER;
  public internalTotalIncident$: Observable<number>;
  public internalTopIncidentData$: Observable<IncidentStat[]>;
  constructor(private _router: Router) { }

  public ngOnInit(): void {
  }

  public filterIncidentList(responseList: IncidentStat[]): IncidentStat[] {
    const filteredList =  responseList.slice().sort((a, b) => b.value - a.value).filter(item => item.value > 0);
    if (filteredList.length > this.displayAmount) {
      const otherEvent: IncidentStat = {
        name: 'Others',
        value: 0,
        incidentClass: 'incident-others',
      };
      filteredList.splice(this.displayAmount - 1, filteredList.length).forEach( evt => {
        otherEvent.value += evt.value;
      });
      filteredList.push(otherEvent);
    }
    return filteredList;
  }

  public goToFleetMetrics() {
    this._router.navigate(['/fleet-trends']);
  }
}
