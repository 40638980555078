<div class="modal fade custom-modal" bsModal #modal="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="mySmallModalLabel" aria-hidden="true" (onShow)="show()" style="z-index:
    1500 !important">
  <div class="backDrop" (click)="hideModal()"></div>
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="width: 610px; overflow-y: hidden; overflow-x: hidden;">
      <h3 class="modal-dvr-title">Capture new DVR clip</h3>
      <div class="modal-body" style="overflow-y: auto; overflow-x: hidden;">
        <!-- empty div to maintain height and width of table till data loads -->
        <div class="capturediv">
          <p>Was there an incident that was not detected by your camera? <br>You can capture additional video
            from this trip by entering the start time and duration of the video you would like to capture.
          </p>
          <form name="form" [formGroup]="timeGroup">
            <div class="control-option" style="display:flex">
              <app-time-input-field [tripTimeInfo]="tripTimeInfo" formControlName="timeInput"></app-time-input-field>
              <app-duration-dropdown [tripLengthInMin]="tripDuration" formControlName="durationInput"></app-duration-dropdown>
            </div>
            <div class="incidentblock">
              <h4 class="slider-name">Preview:</h4>
              <app-dvr-slider [startTimeInput]="currentTimeInMs" [duration]="clipDuration" [sliderRange]="sliderRange"
                [incidents]="incidents" (dvrSliderChange)="notifySliderchange($event)" [displayTimeZone]="displayTimeZone" [disableHandler]="disableSlider"></app-dvr-slider>
            </div>
            <div class="mapblock" *ngIf="showMap">
              <app-map mapId="eventMap1" [markerList]="markerList" [latlonList]="latLongList" [mapheight]="400"
                [getHighlightLatLon]="highlightLatLong">
              </app-map>
            </div>
            <div class="tip-block">
              <p class="tip-header">Tips for successful video retrieval</p>
              <ol>
                <li>Make sure the camera is powered on and connected to Wi-Fi or cellular service. Do
                  not disrupt the connection while the retrieval is in progress.</li>
                <li>Retrieve your video sooner than later. Since new videos replace older videos, it is
                  important to capture the video as soon as you realize that you need it. Keep in mind
                  that long trips (which require more video) will increase the chances of older videos
                  being overwritten.</li>
              </ol>
            </div>
          </form>
        </div>
      </div>
      <div class="action-block">
        <button class="btn btn-main" [disabled]="disableCapture" (click)="captureDVRClipRequest()">Capture</button>
        <button class="btn btn-sub" (click)="hideModal()">Cancel</button>
      </div>
    </div>
  </div>
</div>
