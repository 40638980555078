import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddNewAccountParam } from '@modules/package-config/common/package-config.model';
import { PackageConfigService } from '@modules/package-config/services/package-config.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { LoadingService } from '@app-core/services/loading.service';

import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-account-config',
  templateUrl: './account-config.component.html',
  styleUrls: ['./account-config.component.scss'],
})
export class AccountConfigComponent implements OnInit {
  @ViewChild('addAccountForm', { static: true }) public addAccountForm: NgForm;

  @Output()
  public getAccounts = new EventEmitter<void>();
  public accountsList: any[] = [];

  public searchText = '';
  public accountCode = '';

  public getAccountParams = null;

  constructor(
    private _packageService: PackageConfigService,
    private _toasterService: ToasterService,
    private _loading: LoadingService
  ) {}

  public ngOnInit() {}

  /**
   * @description: Function to add new account
   */
  public addNewAccount() {
    const param = new AddNewAccountParam(this.accountCode);
    // Show loader
    this._loading.show();

    this._packageService.addNewAccount(this.accountCode, param).subscribe(
      async () => {
        this._loading.hide();
        await this.getAccounts.emit();
        this.accountCode = '';
        this._toasterService.success('Account added successfully');
      },
      (err) => {
        this._loading.hide();
        console.log(err);

        if (err.status === 409) {
          this._toasterService.error('Acount already exists');
        } else {
          this._toasterService.error('Add account failed');
        }
      }
    );
  }

  /**
   * @description: Remove account
   */
  public removeAccount(account) {
    this._loading.show();

    this._packageService.removeAccount(account).subscribe(
      async () => {
        this._loading.hide();

        try {
          await this.getAccounts.emit();
          this._toasterService.success('Account removed successfully');
        } catch (error) {}
      },
      (err) => {
        this._loading.hide();

        console.log(err);
      }
    );
  }

  /**
   * @description: function to get account list based on autocomplete
   * @param: search string
   */
  public getAccountsList(searchString) {
    this.getAccountParams = {
      fleetId: searchString,
    };
    this._loading.show();
    this._packageService
      .getListOfAccounts(this.getAccountParams)
      .pipe(debounceTime(500))
      .subscribe(
        (res) => {
          this._loading.hide();
          this.accountsList = res.data.fleets;
        },
        (err) => {
          this._loading.hide();
          console.log(err);
        }
      );
  }
}
