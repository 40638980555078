import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Data } from '../../services/data.service';
import { StorageService } from '@app-core/services/storage.service';
import { FilterStrore, ListItem, SearchFilterTableModel } from '@modules/dashboard3/dashboard3.models';
import { DATE_FILTER, FilterChangeEvent, LOCATION_FILTER } from '@app-core/constants/constants';
import { DATE_FILTER_EVENT } from '../date-filter/date-filter.component';

export interface FilterCommand {
  filterKey: string;
  filterValues: ListItem[];
}

type AvailableFilterType = 'assetFilter' | 'cameraImeisFilter' | 'dutyTypeFilter' | 'driverFilter' | 'incidentType' | 'locationFilter';

const FILTER_LIST = [
  'assetFilter',
  'driverFilter',
  'incidentType',
  'dutyTypeFilter',
  'cameraImeisFilter',
];

export const FILTERS_LIST_CALL_APIS = [
  DATE_FILTER,
  LOCATION_FILTER,
];

export interface FilterOptions {
  filterKey: string;
  availableOptions: ListItem[];
  enable: boolean;
}

export interface FilterList {
  hasDateFilter: boolean;
  hasAssetFilter: boolean;
  hasCameraImeisFilter: boolean;
  hasDutyTypeFilter: boolean;
  hasDriverFilter: boolean;
  hasIncidentTypeFilter: boolean;
  hasLocationFilter: boolean;
}

@Component({
  selector: 'app-search-filter-table',
  templateUrl: './search-filter-table.component.html',
  styleUrls: ['./search-filter-table.component.scss'],
})
export class SearchFilterTableComponent implements OnInit, OnChanges {
  @Input() public filterOptions: Record<string, FilterOptions>;
  @Input() public refetchFilter: boolean;
  @Input() public filterList: FilterList = {
    hasDateFilter: true,
    hasAssetFilter: true,
    hasCameraImeisFilter: false,
    hasDutyTypeFilter: false,
    hasDriverFilter: true,
    hasIncidentTypeFilter: true,
    hasLocationFilter: true,
  };

  @Output()
  public dateChange = new EventEmitter<SearchFilterTableModel>();
  @Output()
  public filterChange = new EventEmitter<FilterCommand[]>();

  public days = 7;
  public daysTemp: number;

  public assetSettings = {
    dropdownPlaceholder: 'Assets',
    selectAllText: 'All assets',
    selectedItemText: 'assets',
  };
  public cameraImeisSettings = {
    dropdownPlaceholder: 'Camera IMEIs',
    selectAllText: 'All camera IMEIs',
    selectedItemText: 'cameras',
  };
  public dutyTypeSettings = {
    dropdownPlaceholder: 'Duty types',
    selectAllText: 'All duty types',
    selectedItemText: 'duty types',
  };
  public driverSettings = {
    dropdownPlaceholder: 'Drivers',
    selectAllText: 'All drivers',
    selectedItemText: 'drivers',
  };
  public incidentTypeSettings = {
    dropdownPlaceholder: 'Incident types',
    selectAllText: 'All incident types',
    selectedItemText: '',
    showChips: false,
    showSearchFilter: false,
  };

  public isHomeLocationEditable = true;
  public homeLocation = '';
  public reloadNavigate: string = window.location.pathname;

  private _resetLocationFilter = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public reset = this._resetLocationFilter.asObservable();

  private _internalFilterValues: Record<string, FilterCommand> = {};
  private _internalFilterValuesBackUp: Record<string, FilterCommand> = {};
  private _internalFilterValuesFull: Record<string, FilterCommand> = {};

  constructor(
    private _data: Data,
    private _dateService: DateService,
    private _zcFleetService: ZCFleetService,
    private _storage: StorageService,
    private _router: Router
  ) { }

  public ngOnInit(): void {
    this._getStoredValues();
    this._setOldValues();
    this._emitFilterParam();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterOptions) {
      FILTER_LIST.forEach( filterType => {
        if (changes.filterOptions.currentValue[filterType]) {
          this._internalFilterValues[filterType] = {
            filterKey: this.filterOptions[filterType].filterKey,
            filterValues: this.filterOptions[filterType].availableOptions,
          };
        }
      });
      this._internalFilterValuesFull = {...this._internalFilterValues};
    }
  }

  // Apply temporary changes as new filter values
  public applyFilter(event: FilterChangeEvent = null) {
    if (event) {
      // case location filter event
      if (FILTERS_LIST_CALL_APIS.includes(event.filterName)) {
        if (!event.isChanged) {
          return;
        }
        this.filterChange.emit(this._getFilterValueEvent());
        this.reload();
      }
    }
    // trigger reset location filter placeholder
    this._resetLocationFilter.next(true);
    this._setNewValues();
    this._emitFilterParam();
    this._setFlaggedIncidentsCount();
    this._setStoredValues();
  }

  public reload() {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([this.reloadNavigate]);
  }

  // Set date range temp value
  // Apply filter if width < 900 to update Histogram with
  // the selected date range
  public setDateRange(val) {
    this.daysTemp = val;
    if (val !== 2) {
      this.applyFilter(DATE_FILTER_EVENT);
    }
  }

  // Discard temporary changes by setting initial filter values
  public discardChanges() {
    this._setOldValues();
  }


  public onItemSelect(filterType: AvailableFilterType, selectedValues: ListItem[]) {
    this._setFilterValue(filterType, selectedValues);
  }

  public onFilterChange(filterType: AvailableFilterType, isOpen = true) {
    // Only backup the dropdown selected list
    if (!isOpen) {
      this.backupCurrentFilterVal(filterType);
      return;
    }

    if (this._isChanged(filterType)) {
      this.filterChange.emit(this._getFilterValueEvent());
    }
  }

  public backupCurrentFilterVal(filterType: AvailableFilterType) {
    this._internalFilterValuesBackUp[filterType] = JSON.parse(JSON.stringify(this._internalFilterValues[filterType]));
  }

  private _isChanged(filterType: AvailableFilterType) {
    const previousList: FilterCommand = this._internalFilterValuesBackUp[filterType];
    const currentList: FilterCommand = this._internalFilterValues[filterType];
    return this._isFilterValuesChanged(previousList.filterValues, currentList.filterValues);
  }



  private _getFilterValueEvent(): FilterCommand[] {
    return Object.entries(this._internalFilterValues).reduce<FilterCommand[]>((acc, [,curr]) => [...acc, curr], []);
  }

  private _setFilterValue(filterType: AvailableFilterType, item: ListItem[]) {
    // This is not done an error will occur. If you don't refetch when filtering date,
    // cached previous filters will lead to incorrect subsequent filters
    if (this.refetchFilter) {
      this._internalFilterValues = {...this._internalFilterValuesFull};
    }
    const isSelectedAll = (this.filterOptions[filterType].availableOptions || []).length === item.length;
    if (isSelectedAll && this._internalFilterValues[filterType]) {
      this._internalFilterValues[filterType] = {...this._internalFilterValuesFull[filterType]};
    } else {
      this._internalFilterValues[filterType] = {
        filterKey: this.filterOptions[filterType].filterKey,
        filterValues: item,
      };
    }
  }

  /**
   * @description: setting the default old values in filter
   * @param:
   */
  private _setOldValues() {
    this.daysTemp = this.days;
  }

  /**
   * @description: setting the news temporary values in the search filter
   * @param:
   */
  private _setNewValues() {
    this.days = this.daysTemp;
  }

  // Generate filter params and emit
  private _emitFilterParam() {
    this.dateChange.emit({ days: this.days });
  }

  private _setFlaggedIncidentsCount() {
    let date = { from: null, to: null };
    if (this.days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(this.days);
    }

    const params = {
      params: {
        startDate: date.from,
        endDate: date.to,
      },
    };

    this._zcFleetService.violationsAggregate(params).subscribe(
      (res) => {
        this._storage.setStorageValue('flaggedIncidentsCount', res.violationsAggregate.totalChallengesPending);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * @description: get stored filter values from the local storage
   * @param:
   */
  private _getStoredValues() {
    this.days = this._data.filterData.days;
    this.homeLocation = this._data.filterData.homeLocation;
    this.isHomeLocationEditable = this._data.filterData.isHomeLocationEditable;
  }

  /**
   * @description: set the filter values in local storage
   * @param:
   */
  private _setStoredValues() {
    this._data.filterData = new FilterStrore(
      this.days,
      this._data.filterData.minScore,
      this._data.filterData.maxScore,
      this._data.filterData.rangeValue,
      this._data.filterData.homeLocation,
      this._data.filterData.dutyType,
      this._data.filterData.ids
    );
  }

  private _isFilterValuesChanged(listPrevious: ListItem[], listCurrent: ListItem[]): boolean {
    const idListPrevious = listPrevious.map(item => item.id);
    const idListCurrent = listCurrent.map(item => item.id);
    return idListPrevious.length !== idListCurrent.length ?
      true : !!(idListPrevious.find(item => !idListCurrent.includes(item)));
  }
}
