<div class="row driver-snapshot" data-test="driver-snapshot-card">
  <ng-container *ngIf="(notLoadingState$ | async); else graphLoading">
    <ng-container *ngIf="(internalDriverStat$ | async) as driverStat" class="row driver-snapshot" data-test="driver-snapshot-card">
      <div class="driver-name col-sm-6 col-md-5 col-lg-3" data-test="driver-name-detail-page">
        <div class="text">
          <strong>Division </strong>
          <ng-container *ngIf="driverStat.driverLocation">
            <span [ngClass]="{ 'highlight-text': driverStat.driverLocation === 'N/A' }">
              {{ driverStat.driverLocation }}
            </span>
          </ng-container>
        </div>
        <h2>{{ driverStat.driverName }}</h2>
      </div>

      <div class="col-sm-12 col-md-7 col-lg-5" [style.display]="'flex'">
        <div class="average-score-detail-page col-sm-4 col-md-4 col-lg-4" data-test="average-score-detail-page">
          <div class="text">Average Score</div>
          <div class="score" [class.text-green]="driverStat.averageScore >= 85"
            [class.text-orange]="driverStat.averageScore > 69 && driverStat.averageScore <= 84" [class.text-red]="driverStat.averageScore <= 69">
            {{ driverStat.averageScore > 0 ? driverStat.averageScore : 'N/A' }}
          </div>
        </div>
        <div class="score-change-detail-page col-sm-4 col-md-4 col-lg-4" data-test="score-change-detail-page">
          <div class="text">Score Change</div>
          <div *ngIf="driverStat.changeInScore !== 200" class="score">
            {{ driverStat.scorechange ? driverStat.scorechange : 'N/A' }}
          </div>
          <div *ngIf="driverStat.changeInScore === 200" class="score">N/A</div>
        </div>
        <div class="fleet-rank-detail-page col-sm-4 col-md-4 col-lg-4" data-test="fleet-rank-detail-page">
          <div class="text">Fleet Rank</div>
          <div class="score">{{ driverStat.rank }}</div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-4" data-test="score-trend-detail-page">
        <div class="text" *ngIf="!isCustomDateRange">
          Past {{ numberofdays | number: '1.0-0' }}
          <span *ngIf="numberofdays > 1.99">Days</span>
          <span *ngIf="numberofdays <= 1.99">Day</span>
          Score Trend
        </div>
        <div class="text" *ngIf="isCustomDateRange">
          Score Trend for Dates selected
        </div>
        <div class="chart">
          <div *ngIf="showGraph">
            <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateChart" [oneToOne]="true">
            </highcharts-chart>
            <div class="chart-score">
              <span class="pull-left">{{ minScore }}</span>
              <span></span>
              <span class="pull-right">{{ maxScore }}</span>
            </div>
          </div>
          <div class="no-graph" *ngIf="!showGraph">
            Graph not available
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Skeleton loading -->
<ng-template #graphLoading>
  <div class="driver-name col-sm-6 col-md-5 col-lg-3">
    <app-custom-skeleton-loader
        [theme]="{
          'width': '100%',
          'height': '80px'
        }">
      </app-custom-skeleton-loader>
  </div>
  <div class="col-sm-12 col-md-7 col-lg-5" [style.display]="'flex'">
    <div class="average-score-detail-page col-sm-4 col-md-4 col-lg-4">
      <app-custom-skeleton-loader
        [theme]="{
          'width': '112px',
          'height': '80px'
        }">
      </app-custom-skeleton-loader>
    </div>
    <div class="score-change-detail-page col-sm-4 col-md-4 col-lg-4">
      <app-custom-skeleton-loader
        [theme]="{
          'width': '112px',
          'height': '80px'
        }">
      </app-custom-skeleton-loader>
    </div>
    <div class="fleet-rank-detail-page col-sm-4 col-md-4 col-lg-4">
      <app-custom-skeleton-loader
        [theme]="{
          'width': '112px',
          'height': '80px'
        }">
      </app-custom-skeleton-loader>
    </div>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-4">
    <app-custom-skeleton-loader
      [theme]="{
        'width': '100%',
        'height': '80px'
      }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>