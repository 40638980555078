<footer class="footer" *ngIf="!hideFooter">
    <div class="logo">
        <img src="assets/images/zonar-logo-white.png" alt="ZonarSystems" style="border:0;">
    </div>
    <div class="footer-content">
        <div class="copyright">
            © {{ date.getFullYear() }} Zonar Systems. Zonar Coach {{appVersion}}
        </div>
        <div class="support">
            <a target="_blank" href="https://support.zonarsystems.net/hc/en-us/sections/360002547151-Zonar-Coach"> Support </a> |
            <a target="_blank" href="http://zonarcoach.com/feedback"> Feedback </a> |
            <a target="_blank" href="https://www.zonarsystems.com/terms-of-use/zonar-coach"> Terms
                & Conditions </a> |
            <a target="_blank" href="https://www.zonarsystems.com/terms-of-use/zonar-coach"> Data
                Disclosure</a>
        </div>
        <div class="contacts">
            <span class="phone">
                877 843 3857
            </span>
            <a href="mailto:customercare@zonarsystems.com">customercare@zonarsystems.com</a>
        </div>
    </div>
    <div class="spacer">
        <img style="visibility: hidden;" src="assets/images/zonar-logo-white.png" alt="ZonarSystems">
    </div>
</footer>
