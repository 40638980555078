import {
  Component,
  OnDestroy,
  ViewEncapsulation,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';

import {
  DefaultInputModelV2,
  SearchFilterModel,
  ModalParamsModel,
} from './../../../dashboard3.models';
import { Data } from '@modules/dashboard3/services/data.service';

import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { LastNameSortPipe } from '@modules/shared/pipes/last-name-sort/last-name-sort.pipe';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { StorageService } from '@app-core/services/storage.service';
import { calcSumTotalIncidents } from '@modules/shared/utils';
import { LOCAL_DRIVER_SCORECARDS_KEY } from '@app-core/constants/constants';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';

const MARKER = {
  enabled: false,
  radius: 5,
};

export interface DriverScorecardsStorage {
  sortDriver: string;
  sortListBy: string;
  ngoType: string;
  showList: boolean;
}

@Component({
  selector: 'app-driver-scorecards',
  templateUrl: './driver-scorecards.component.html',
  styleUrls: ['./driver-scorecards.component.scss'],
  providers: [ArraySortPipe, LastNameSortPipe],
  encapsulation: ViewEncapsulation.None,
})
export class DriverScorecardsComponent implements OnDestroy {
  @Output() public isShowError = new EventEmitter<boolean>();

  @ViewChild('slickModal')
  public slickModal: SlickCarouselComponent;

  public scoreboardList: any;

  public leadboardAsc = false;

  public show = 'loading';

  public filterOptions: SearchFilterModel;


  public boradlist = [];
  public isCustomDateRange = false;

  public driverScoreTrendList = [];

  // public slides = [1];

  public slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    variableWidth: true,
    nextArrow: '',
    prevArrow: '',
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  public highcharts = Highcharts;
  public drivercharts = [];
  // public chartOptions = CHART_OPTION;
  public updateChart = true;

  public linecharts = Highcharts;
  public lineOptions = [];
  public lineupdateChart = true;
  public minScore = [];
  public maxScore = [];
  public difference = [];

  public chartlist = [];

  /*========This for table view ==============*/

  /** Flag for toggleing Driver table */
  public tableHidden = true;
  public filterChanged = true;

  public driverListPage = [];

  public reset = false;

  public limit = 20;
  public sortBy = 'v2Score';
  public sort = 'asc';

  public totalDrivers = 0;

  public driverList: any[] = [];

  /*==========end of table view =================*/

  public circleOptions: any[] = [];
  public incidentlist: any[] = [];
  public range: any;

  public olditemlist = 0;
  public totaldriverlist: any[] = [];
  public nextcard = 4;
  public pervcard = 0;
  public prevtab = false;

  public driverlisttoal = 0;
  public ngoType = 'false';
  public limitdriver = 4;

  public tripList: any[] = [];
  public dummyTripList: any[] = [];
  public skeletonNumberDefault = 3;
  public isShowSkeleton = true;
  public isShowDummySkeleton  = false;
  public showSortList = 'enable';
  public sortdriver = 'asc';
  public drivercardtotal = 0;
  public nextarrow = true;
  public prevarrow = false;
  public numberofdays = 0;
  public showList = false;
  public sortListBy = 'Low-High Score';
  public showRange = false;
  public homeLocationList = [];
  public azSortedList = [];

  private _offsetdriver = 0;
  private _modalParams: ModalParamsModel = { state: false, component: '' };
  private _filterChangeSubscription: any;
  private _subscription: Subscription = new Subscription();

  // Behaviour subject to pass data of each page into the paginator component
  private _list = new BehaviorSubject([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public listData = this._list.asObservable();

  constructor(
    private _zcfleet: ZCFleetService,
    private _dateService: DateService,
    private _loading: LoadingService,
    private _router: Router,
    private _data: Data,
    private _storageService: StorageService,
    private _networkService: TrackNetworkStatusService
  ) {
    this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(
      (filterOptions) => {
        this.getDriverSCFilter(LOCAL_DRIVER_SCORECARDS_KEY);
        // Show 3 skeletons by default for Drivers Scorecards when loading data
        this.dummyTripList = this._createDummyData(this.skeletonNumberDefault);
        this.isShowDummySkeleton = true;
        this.setStatusForSortList('enable');
        this.filterOptions = filterOptions;
        if (this.ngoType === 'false') {
          this.leadboardAsc = false;
        } else {
          this.leadboardAsc = true;
        }
        this.prevtab = false;
        this._resetList();
        this.olditemlist = 0;
        this._offsetdriver = 0;
        this.prevarrow = false;
        this.tripList = [];
        const slide = 'false';
        this.minScore = [];
        this.maxScore = [];
        this.getv2driverlist(slide);
        if (this.filterOptions.days === 2) {
          const date = { from: null, to: null };
          date.from = this._dateService.toDaysStartISO(
            this._dateService.customStartdate
          );
          date.to = this._dateService.toDaysEndISO(
            this._dateService.customEndDate
          );
          const fromdate = date.from.toString();
          const todate = date.to.toString();
          const dateone: any = new Date(fromdate);
          const datetwo: any = new Date(todate);
          const dayDif = (datetwo - dateone) / 1000 / 60 / 60 / 24;
          this.numberofdays = dayDif - 1;
          this.isCustomDateRange = true;
        } else {
          if (this.filterOptions.days === -1) {
            this.numberofdays = 1;
          } else {
            this.numberofdays = this.filterOptions.days;
          }
          this.isCustomDateRange = false;
        }
      }
    );
  }

  public ngOnDestroy() {
    // Unsubscribe from filter change event
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  /*
     desc: This wil control prevarrow and next arrow based on duration filter
     params: It will takes event as params
     result: it will hide prevarrow and next arrow based on card count
   */

  public afterChange(event) {
    if (
      this.tripList.length - 3 === event.nextSlide ||
      this.tripList.length - 2 === event.nextSlide ||
      this.tripList.length - 1 === event.nextSlide
    ) {
      this.nextarrow = false;
    } else {
      this.nextarrow = true;
    }
    if (
      (event.currentSlide === 2 && event.nextSlide === 0) ||
      (event.currentSlide === 3 && event.nextSlide === 0)
    ) {
      this.prevarrow = false;
    } else {
      this.prevarrow = true;
    }

    if (event.currentSlide === 0 && event.nextSlide === 0) {
      this.prevarrow = false;
    }
  }

  /*
       desc: This wil list driver score card for every next slides
       params: empty
       result: it will return driver score list as slides
     */

  public next() {
    // Prevent request next action when skeleton is loading
    if (!this.isShowSkeleton) {
      this.limitdriver = 4;
      if (this.drivercardtotal > this.tripList.length) {
        if (this.prevtab === true) {
          this.slickModal.slickNext();
          this.olditemlist += 4;
          if (this.olditemlist === this.tripList.length) {
            this.prevtab = false;
          }
        } else {
          const slider = 'true';
          this.isShowSkeleton = true;
          this.getv2driverlist(slider);
        }
      } else {
        this.slickModal.slickNext();
      }
    }
  }

  /*
       desc: This wil list driver scroe card for every prev slides
       params: empty
       result: it will return driver score list as slides
     */

  public prev() {
    if (!this.isShowSkeleton) {
      this.prevtab = true;
      const itemcount = this.olditemlist - 4;
      this.olditemlist = itemcount;
      this.slickModal.slickPrev();
    }
  }

  /*
        desc: This wil list driver scroe card based on duration filter
        params: It will takes event as params
        result: it will return driver score list
      */

  public selectScore(event) {
    if (this.drivercardtotal !== this.tripList.length && ['a-z', 'z-a'].includes(event)){
      return;
    }
    this.isShowDummySkeleton = true;
    this.setStatusForSortList('enable');
    this.leadboardAsc = !this.leadboardAsc;
    if (event === 'low-high') {
      this.ngoType = 'false';
      this.sortdriver = 'asc';
      this.showList = false;
      this.sortBy = 'v2Score';
      this.sortListBy = 'Low-High Score';
    } else if (event === 'high-low') {
      this.ngoType = 'true';
      this.sortdriver = 'desc';
      this.showList = false;
      this.sortBy = 'v2Score';
      this.sortListBy = 'High-Low Score';
    } else if (event === 'a-z') {
      this.sortdriver = 'asc';
      this.showList = false;
      this.sortBy = 'driverName';
      this.sortListBy = 'A-Z';
    } else if (event === 'z-a') {
      this.sortdriver = 'desc';
      this.showList = false;
      this.sortBy = 'driverName';
      this.sortListBy = 'Z-A';
    }
    this.slickModal.slickGoTo(0);
    this.prevtab = false;
    this.olditemlist = 0;
    this._offsetdriver = 0;
    this.prevarrow = false;
    if (this.drivercardtotal === this.tripList.length && ['A-Z', 'Z-A'].includes(this.sortListBy)) {
      setTimeout(() => {
        this.tripList = this._sortByName(this.tripList, this.sortListBy);
        this.isShowDummySkeleton = false;
      }, 500);
    } else {
      this.tripList = [];
      const slider = 'false';
      this.getv2driverlist(slider);
    }
    const driverFilter: DriverScorecardsStorage = {
      sortDriver: this.sortdriver,
      ngoType: this.ngoType,
      showList: this.showList,
      sortListBy: this.sortListBy,
    };
    this.setDriverSCFilter(LOCAL_DRIVER_SCORECARDS_KEY, driverFilter);
  }
  /*
    desc: This wil list each driver event and incidents  based on duration filter
    params: It will takes status boolen as params
    result: it will return drivertrandDetails
  */

  public getv2driverlist(status) {
    if (!this._networkService.isOnline) {
      this.show = 'error';
      this.isShowSkeleton = true;
      this.isShowDummySkeleton = true;
      this._loading.hide();
      this.isShowError.emit(true);
      return;
    }
    if (this.reset && this.slickModal !== undefined) {
      this.slickModal.slickGoTo(0);
      this.prevarrow = false;
      this.reset = false;
    }

    this._loading.show();
    this.isShowSkeleton = true;
    const { days, dutyType, minScore, maxScore } = this.filterOptions;
    this.range = this._dateService.getDateRange(days);
    let date = { from: null, to: null };
    let rangdate = { from: null, to: null };
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      rangdate.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      rangdate.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        rangdate = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
      this.showRange = true;
      this.range = rangdate;
      this.range.to = moment(rangdate.to).subtract(2, 'd');
      this.isCustomDateRange = true;
    } else {
      this.showRange = false;
      date = this._dateService.getDateRangeInISO(days);
      this.isCustomDateRange = false;
    }
    this.homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION_ABV');

    const params = new DefaultInputModelV2(
      date.from,
      date.to,
      dutyType,
      minScore,
      maxScore,
      this.limitdriver,
      this._offsetdriver,
      this.sortdriver,
      this.sortBy,
      this.homeLocationList
    );
    const OPTIONS = {
      params,
    };
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = this._zcfleet.getDriverListV2(OPTIONS).subscribe(
      (res) => {
        this.drivercardtotal = res.totalCount;
        this.reset = false;
        res.list.map((driver) => {
          if (driver.eventCount['Anomaly']) {
            return (driver.eventCount.total =
              driver.eventCount.total - driver.eventCount['Anomaly']);
          }
        });

        const tripDetailsApis = (res.list || []).map((driver) =>
          this._zcfleet.getDriverTrandDetails(
            driver.driverId,
            date.from,
            date.to,
            this.homeLocationList
          )
        );
        if (tripDetailsApis.length !== 0) {
          forkJoin(tripDetailsApis).subscribe((results) => {
            if (status === 'false') {
              this.lineOptions = [];
              this.minScore = [];
              this.maxScore = [];
              this.difference = [];
            }
            this.driverScoreTrendList = (
              this.driverScoreTrendList || []
            ).concat(results);
            for (let i = 0; i < results.length; i++) {
              this._generateGraph(results[i]['data']);
              if (res.list[i] && res.list[i].eventCount) {
                res.list[i].eventCount['total'] = calcSumTotalIncidents(res.list[i].eventCount);
              }
              this.tripList.push(res.list[i]);
            }
            if (this.tripList.length <= 3) {
              this.nextarrow = false;
            } else {
              this.nextarrow = true;
            }
            this.olditemlist += 4;
            this._offsetdriver = this.olditemlist;
            this._driverchart(res.list, status);
            if (status === 'true') {
              this.slickModal.slickNext();
            }
            this._loading.hide();
            if (this.tripList) {
              this.isShowSkeleton = false;
              this.isShowDummySkeleton = false;
            }
            // Disable sorting when there are no results on the page
            if (this.tripList.length === 0) {
              this.setStatusForSortList('disable');
            }
          });
        } else {
          this._loading.hide();
          this.isShowSkeleton = false;
          this.isShowDummySkeleton = false;
          // Disable sorting when there are no results on the page
          if (this.tripList.length === 0) {
            this.setStatusForSortList('disable');
          }
        }
        this.isShowError.emit(false);
      },
      (err) => {
        console.error(`Received Error: ${err}`);
        this.show = 'error';
        this.isShowSkeleton = true;
        this.isShowDummySkeleton = true;
        this._loading.hide();
        this.isShowError.emit(true);
      }
    );
  }

  public setStatusForSortList(key: string) {
    this.showSortList = key;
  }

  /*
        desc: This wil model pop for driver score card based on duration filter
        params: empty
        result: it will return driver score cards list
     */
  public toggleTable() {
    this._modalParams.state = true;
    this._modalParams.component = 'drivers';
    this._zcfleet.commonTableModal.next(this._modalParams);
  }

  public get driverCount() {
    return this.filterChanged ? '' : `(${this.totalDrivers})`;
  }

  public gotoDriverDetail(driver) {
    if (driver && driver.driverId) {
      // Set driver name
      // this._data.driverName = driver.driverName;
      this._router.navigate(['/driver-detail'], {
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }

  public getDriverSCFilter(key: string) {
    const filterdriverStorage = this._storageService.getStorageValue(key);
    if (filterdriverStorage) {
      const values: DriverScorecardsStorage = JSON.parse(filterdriverStorage);
      this.sortdriver = values.sortDriver;
      this.ngoType = values.ngoType;
      this.showList = values.showList;
      this.sortListBy = values.sortListBy;
      // Should reset to default sort by score
      if (['A-Z', 'Z-A'].includes(this.sortListBy)) {
        this.ngoType = 'false';
        this.sortdriver = 'asc';
        this.showList = false;
        this.sortBy = 'v2Score';
        this.sortListBy = 'Low-High Score';
      }
    }
  }

  public setDriverSCFilter(key: string, values: DriverScorecardsStorage) {
    this._storageService.setStorageValue(key, JSON.stringify(values));
  }

  /*
    desc: This wil generate line graph for driver trends based on duration filter
    params: It will takes driver trends array as params
    result: min and max and total difference added in graph options
    */

  private _generateGraph(list: any[]) {
    let minScore = 0;
    let maxScore = 0;
    list = list.map((e) => e[1]);
    try {
      minScore = +list[0];
      maxScore = +list[list.length - 1];
      const totalScore = list.reduce((a, b) => a + b, 0);
      let averageScore = +(+totalScore / list.length).toFixed(2);
      averageScore = +(averageScore / 100).toFixed(2);

      const trendScores = list.map((trend, index) => {
        if (index === list.length - 1) {
          const y = +(trend / 100).toFixed(2);
          let marker;
          if (y < averageScore) {
            marker = { ...MARKER, fillColor: 'red', lineColor: 'red' };
          } else {
            marker = { ...MARKER, fillColor: '#7dbb7a', lineColor: '#7dbb7a' };
          }

          return { y, marker };
        } else {
          return +(trend / 100).toFixed(2);
        }
      });
      const chart = {
        title: {
          text: '',
        },
        chart: {
          height: 60,
          width: null, // Height of the chart in pixels
          type: 'line', // type of chart 'line', 'bar'...
          spacingLeft: 10, // number represents pixels
          spacingRight: 10, // number represents pixels
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          visible: false,
        },
        yAxis: {
          // visible: false,
          maxPadding: 0, // padding at the end of the bar
          // gridLineDashStyle: 'dash',
          // gridLineColor: '#979797',
          gridLineWidth: 0,
          // tickInterval: 1,
          labels: {
            enabled: false,
          },
          title: { enabled: false },
          plotLines: [
            {
              value: 1,
              color: '#b8bcc0',
              dashStyle: 'shortdash',
              width: 2,
            },
          ],
        },
        plotOptions: {
          line: {
            states: {
              hover: {
                enabled: false, // Disable hover effect on line
              },
            },
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                  fillColor: '#7dbb7a',
                  lineColor: '#7dbb7a',
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            data: [],
            color: '#2d8ed9',
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                },
              },
            },
          ],
        },
      };

      chart.yAxis.plotLines[0].value = averageScore;
      chart.series[0].data = trendScores;
      chart.series[0].color = '#2d8ed9';
      this.lineupdateChart = true;
      this.lineOptions.push(chart);
      this.minScore.push(minScore);
      this.maxScore.push(maxScore);
      const diff = maxScore - minScore;
      if (diff >= 0) {
        this.difference.push([diff, diff.toString()]);
      } else {
        const diffnumber = diff.toString().split('-');
        this.difference.push([diff, diffnumber[1]]);
      }
      // this.showGraph = true;
    } catch (error) {
      // this.showGraph = false;
    }
  }

  // public toggleTable(){
  //     this.tableHidden = !this.tableHidden;
  // }

  /*===========tabe view start================*/
  private _resetList() {
    this.filterChanged = true;
    this.reset = true;
  }

  /*
        desc: This wil generate pie chart and incidents list for eache driver based on duration filter
        params: It will takes list[driver list] as array and  status boolen as params
        result: it will return drivertrandDetails
      */
  private _driverchart(list, status) {
    if (status === 'false') {
      this.circleOptions = [];
      this.incidentlist = [];
    }
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < list.length; i++) {
      const CHART_OPTION = {
        chart: {
          height: 200, // Height of the chart in pixels
          type: 'pie', // type of chart 'line', 'bar'...
          width: 200, // number represents pixels
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          style: {
            color: '#49555e',
            fontWeight: 'bold',
            fontSize: '64px',
          },
          x: -20,
          y: -5,
        },
        subtitle: {
          text: 'Current Score',
          align: 'center',
          verticalAlign: 'middle',
          x: -20,
          y: 10,
          style: {
            color: '#5b6770',
            fontWeight: 'normal',
            fontSize: '11px',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage}%</b>',
          enabled: false,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            center: ['35%', '35%'],
            size: '100%',
            states: {
              hover: {
                enabled: false, // Disable hover effect on line
              },
            },
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false,
                  fillColor: '#7dbb7a',
                  lineColor: '#7dbb7a',
                },
              },
            },
          },
        },
        series: [
          {
            name: 'Brands   ',
            innerSize: '80%',
            colors: [],
            data: [],
          },
        ],
      };
      // let othervalues = [list[i].eventCount['Tail-Gating-Detected'], list[i].eventCount['Traffic-STOP-Sign-Violated']]
      let others = 0;
      const datalistevent = [
        { name: 'Total Incidents', value: list[i].eventCount['total'] },
        {
          name: 'Accelerating',
          value: list[i].eventCount['Harsh-Acceleration'],
          color: '#8ba600',
          incidentClass: 'incident-accel',
        },
        {
          name: 'Braking',
          value: list[i].eventCount['Harsh-Braking'],
          color: '#e67300',
          incidentClass: 'incident-braking',
        },
        {
          name: 'Lane Drift',
          value: list[i].eventCount['Lane-Drift-Found'],
          color: '#e5389d',
          incidentClass: 'incident-lane-drift',
        },
        {
          name: 'Cornering',
          value: list[i].eventCount['Cornering'],
          color: '#6134b1',
          incidentClass: 'incident-cornering',
        },
        {
          name: 'Speeding',
          value: list[i].eventCount['Traffic-Speed-Violated'],
          color: '#2a8596',
          incidentClass: 'incident-speeding',
        },
        {
          name: 'Tailgating',
          value: list[i].eventCount['Tail-Gating-Detected'],
          color: '#814911',
          incidentClass: 'incident-tailgating',
        },
        {
          name: 'Stop Sign',
          value: list[i].eventCount['Traffic-STOP-Sign-Violated'],
          color: '#a82a89',
          incidentClass: 'incident-stopsign',
        },
      ];
      const eventincidents = datalistevent.sort((obj1, obj2) => obj2.value - obj1.value);
      const othervalues = eventincidents.slice(5, 8);
      for (const p in othervalues) {
        if (Object.prototype.hasOwnProperty.call(othervalues, p)) {
          others += othervalues[p].value;
        }
      }
      if (list[i].v2Score === 100) {
        CHART_OPTION.series[0].data = [{ name: '', y: list[i].v2Score }];
        CHART_OPTION.series[0].colors = ['#2d8ed9'];
        const incidents = eventincidents.slice(1, 5);
        const otherIncident = {
          name: 'Others',
          value: others,
          color: '#5b6770',
        };
        const totalincident = {
          name: 'Total Incidents',
          value: eventincidents.slice(0)[0].value,
        };
        this.incidentlist.push([totalincident, ...incidents, otherIncident]);
      } else {
        const incidents = eventincidents.slice(1, 5);
        const otherIncident = {
          name: 'Others',
          value: others,
          color: '#5b6770',
        };
        const chartData = [...incidents, otherIncident].reduce(
          (a, b) => ({
            data: [...a.data, { name: b.name, y: b.value }],
            colors: [...a.colors, b.color],
          }),
          { data: [], colors: [] }
        );
        CHART_OPTION.series[0].data = chartData.data;
        CHART_OPTION.series[0].colors = chartData.colors;
        const totalincident = {
          name: 'Total Incidents',
          value: eventincidents.slice(0)[0].value,
        };
        this.incidentlist.push([totalincident, ...incidents, otherIncident]);
      }

      CHART_OPTION.title.text = list[i].v2Score;
      this.updateChart = true;
      this.circleOptions.push(CHART_OPTION);
    }
  }

  private _createDummyData(itemNumber: number) {
    const result = [];
    for (let i = 0; i < itemNumber; i ++) {
      result.push({
        driverId: i,
        driverName: i,
        lastTrip: i,
        totalTrip: i,
        totalIncident: i,
        topIncident: i,
      });
    }
    return result;
  }

  private _sortByName(arr: any[], order: string = 'A-Z'): any[] {
    if (arr.length) {
      arr.sort((a,b) => {
        const nameA = a.driverName.toUpperCase();
        const nameB = b.driverName.toUpperCase();
        if (nameA < nameB) {
          return (order === 'A-Z') ? -1 : 1;
        }
        if (nameA > nameB) {
          return (order === 'A-Z') ? 1 : -1;
        }
        return 0;
      });
    }
    return arr;
  }
}
