import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { createMomentObjectUtc } from '@modules/shared/utils';

export interface SliderInfo {
  timeInMs: number;
  timeWithDurationMs: number;
}

export interface SliderPointValue {
  min: string;
  max: string;
  isSameDate: boolean;
  midPoint: number;
}

// 60 000 milliseconds
const MINIMUM_DURATION = 60000;

// 300 000 milliseconds
const MAXIMUM_DURATION = 300000;

@Component({
  selector: 'app-dvr-slider',
  templateUrl: './dvr-slider.component.html',
  styleUrls: ['./dvr-slider.component.scss'],
})
export class DvrSliderComponent implements OnInit{
  @Input() public set startTimeInput(value: number) {
    if (value) {
      this.durationStartTime = value;
      this.durationEndTime = this.durationInMin * MINIMUM_DURATION + new Date(value).getTime();
      this._correctStartTimeLimit();
    }
  }
  @Input() public set duration(value: number) {
    if (value) {
      this.durationInMin = value;
      this.durationEndTime = new Date(this.durationStartTime).getTime() + value * MINIMUM_DURATION;
      this._correctStartTimeLimit();
    }
  }
  @Input() public set sliderRange(value: SliderPointValue) {
    if (value) {
      this.sliderStartTime = value.min;
      this.sliderEndTime = value.max;
      this.isPeriodAm = value.isSameDate;
      this.customDateRange(value.min, value.max, value.midPoint);
    }
  }
  @Input() public incidents = [];

  @Input() public displayTimeZone: any;
  @Input() public disableHandler = false;

  @Output()
  public dvrSliderChange: EventEmitter<SliderInfo> = new EventEmitter();

  public isDisplaySlider = false;
  public isPeriodAm = true;
  public halfDatePoint = 0;
  public durationInMin = 1;
  public durationStartTime: number = null;
  public durationEndTime: number = null;
  public sliderStartTime = null;
  public sliderEndTime = null;
  public durationInMs: number[] = [];
  public sliderOptions: Options = {
    translate: (value: number): string => {
      if (value) {
        const date = new Date(value);
        // timeZone is ignored because the data from the parent has been calculated and shows the correct time
        const time = createMomentObjectUtc(date.toISOString())
          .tz(this.displayTimeZone);
        return time.format('hh:mm A');
      }
      return '';
    },
    minRange: MINIMUM_DURATION,
    maxRange: MAXIMUM_DURATION,
    pushRange: true,
    noSwitching: true,
    draggableRange: true,
  };

  constructor() {
    for (let i = 1; i <= 5; i++) {
      this.durationInMs.push(i * MINIMUM_DURATION);
    }
  }
  public ngOnInit(): void {}

  public customDateRange(startTime: string, endTime: string, midPoint: number) {
    const startTimeMs = new Date(startTime).getTime();
    const endTimeMs = new Date(endTime).getTime();
    this.durationStartTime = startTimeMs;

    // set floor and ceil data for dvr slider
    this.sliderOptions.floor = startTimeMs;
    this.sliderOptions.ceil = endTimeMs;
    this.isDisplaySlider = true;
    this.halfDatePoint = midPoint;

    // set initial handler value for dvr slider
    this.durationEndTime = this.durationStartTime + (this.durationInMin * MINIMUM_DURATION);
  }

  public onUserChangeEnd(changeContext: ChangeContext): void {
    const emitValue: SliderInfo = {
      timeInMs: changeContext.value,
      timeWithDurationMs: changeContext.highValue,
    };
    // Correct the duration
    const deltaTime = changeContext.highValue - changeContext.value;
    if (!this.durationInMs.includes(deltaTime)) {
      const newDuration = this.durationInMs.reduce((prev, curr) => Math.abs(curr - deltaTime) < Math.abs(prev - deltaTime) ? curr : prev);
      emitValue.timeWithDurationMs = emitValue.timeInMs + newDuration;
      this.durationEndTime = emitValue.timeWithDurationMs;
    }

    this.dvrSliderChange.emit(emitValue);
  }

  private _correctStartTimeLimit() {
    // Correct start time when including duration exceed the slider limit
    const sliderEndTimeMs = Date.parse(this.sliderEndTime);
    if (this.durationEndTime >= sliderEndTimeMs) {
      this.durationEndTime = sliderEndTimeMs;
      this.durationStartTime = sliderEndTimeMs - this.durationInMin * MINIMUM_DURATION;
      this.dvrSliderChange.emit({
        timeInMs: this.durationStartTime,
        timeWithDurationMs: this.durationEndTime,
      });
    }
  }
}
