/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';

import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DateService } from '@app-core/services/date.service';
import {
  DEFAULT_BUFFER_SIZE,
  ERROR_MESSAGE,
  EVENTS_INCIDENT_TYPE} from '@app-core/constants/constants';
import { StorageService } from '@app-core/services/storage.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { createMomentObjectUtc, makeUniqueWithIndex } from '@modules/shared/utils';
import { PaginationData } from '@modules/shared/components/table/table.component';
import { SortInfo } from '@modules/shared/components/card/card.component';
import { BookmarkInfo } from '@modules/dashboard3/components/comment-button-group/comment-button-group.component';
import { IncidentData } from '@modules/dashboard3/components/incident-media-control/incident-media-control.component';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { API, AssetFromTripRequest } from '@modules/dashboard3/dashboard3.models';

export interface SortOption {
  sortBy: string;
  sort: string;
}

interface SpeedMedia {
  media: string;
  speed: number;
  postedMedia: string;
  postedSpeed: number;
  dataAsync: Observable<any>;
  isLoading: boolean;
}

const SPEEDING_EVENT = 'Traffic-Speed-Violated';

@Component({
  selector: 'app-fleet-saved-incidents-table',
  templateUrl: './fleet-saved-incidents-table.component.html',
  styleUrls: ['./fleet-saved-incidents-table.component.scss'],
})
export class FleetSavedIncidentsTableComponent implements OnInit, OnDestroy {
  @Output() public loaded = new EventEmitter<string>();
  @Output() public isShowError = new EventEmitter<boolean>();

  public mediaRecord: Record<string, SpeedMedia> = {};
  public incidentsList: any[] = [];
  public update = false;
  public allAssetsList;
  public syncSortCard = {
    displayName: 'Most recent',
    sortColumn: 'disputeTimeMoment',
    sortOrder: 'desc',
  };
  public isRetry = false;

  public filterOptions: any;
  public homeLocationList = [];
  public isDisabledAction = false;

  public currentPageInfo = {
    pageIndex: 0,
    pageSize: 0,
    totalItems: 0,
    totalPage: 0,
  };

  public incidentData: IncidentData = {};
  public switch = false;
  public selectedIncident = null;
  public isValidating: Record<string, boolean> = {};
  public paramOption = {};

  public show = 'loading';

  public storeCurrentIncidentData = {};

  public bsModalRef: BsModalRef;

  public violationIdentifier: string;

  public sortList: SortInfo[] = [
    {
      displayName: 'Most recent',
      sortColumn: 'disputeTimeMoment',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least recent',
      sortColumn: 'disputeTimeMoment',
      sortOrder: 'asc',
    },
  ];

  public sortHeader = [
    'disputeTimeMoment',
  ];

  public colDescriptions = [
    {
      colKey: 'disputeTimeMoment',
      colDisplayName: 'Date + Time',
      sortable: true,
      type: 'date-time',
      getter: (item) => item.disputeTimeMoment,
    },
    {
      colKey: '_eventType',
      colDisplayName: 'Category',
      sortable: true,
      type: 'bold-text',
      getter: (item) => item._eventType,
    },
    {
      colKey: 'assetName',
      colDisplayName: 'Asset',
      sortable: true,
      type: 'custom',
      async: true,
      getter: (item) => item.assetName,
    },
    {
      colKey: 'driverName',
      colDisplayName: 'Driver',
      sortable: true,
      type: 'custom',
      async: true,
      getter: (item) => item.driverNameAsync,
    },
    {
      colKey: 'action',
      colDisplayName: 'Actions',
      sortable: false,
      type: 'custom',
      getter: () => ({}),
    },
  ];
  public savedIncidentCustomTemplates = ['assetName', 'driverName'];

  public reload$: Observable<boolean>;
  public reloadSort$ = new Observable<[string, 'desc' | 'asc']>();

  public get incidentListTable$(): Observable<PaginationData<any>> {
    return this._incidentListTable$;
  }
  private _incidentListTable$ = new ReplaySubject<PaginationData<any>>(
    DEFAULT_BUFFER_SIZE
  );

  public get notifySortChange$(): Observable<[string, 'desc' | 'asc']> {
    return this._notifyPageChange$;
  }
  private _notifyPageChange$ = new ReplaySubject<[string, 'desc' | 'asc']>();

  public get showOption() {
    return this.incidentsList.length > 0;
  }

  private _filterChangeSubscription: any;
  private _sortOption: SortOption = {
    sortBy: 'disputeTimeMoment',
    sort: 'desc',
  };
  private offset = 0;

  private _incidentTableSort$ = new ReplaySubject<any>();
  public get incidentTableSort$(): Observable<any> {
    return this._incidentTableSort$;
  };
  private _dateFilter$ = new ReplaySubject<any>();
  public get dateFilter$(): Observable<any> {
    return this._dateFilter$;
  }

  private _pageChange$ = new ReplaySubject<any>();
  public get pageChange$(): Observable<any> {
    return this._pageChange$;
  }

  constructor(
    private _dateService: DateService,
    private _zcFleet: ZCFleetService,
    private _data: Data,
    private _storage: StorageService,
    private _snackbarService: SnackbarService
  ) {
    this._filterChangeSubscription = this._zcFleet.fleetFilterChange.subscribe(
      (filterOptions) => {
        this.filterOptions = filterOptions;
        this._dateFilter$.next(this.filterOptions);
        this.getData();
      }
    );

    const distinctDateFilter$ = this.dateFilter$.pipe(distinctUntilChanged());
    const incidentTableSortChange$ = this.incidentTableSort$.pipe(distinctUntilChanged());
    const distinctPageChange$ = this.pageChange$.pipe(distinctUntilChanged());
    this.reload$ = merge(
      incidentTableSortChange$,
      distinctDateFilter$,
      distinctPageChange$).pipe(
      map(() => true)
    );
  }

  public ngOnInit(): void {
    this._listenEventCommentViolationRequest();
  }

  public ngOnDestroy() {
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  /**
   * @description: function to get challenged incident data list
   * @param: isRetry
   */
  public getData(isRetry?: boolean) {
    if (isRetry) {
      this.isRetry = true;
    }
    const pageIndex = 0;
    const pageSize = 6;
    this.loadPageData(pageIndex, pageSize, true, this._sortOption, -1, true);
  }

  public isDisabledClick(event) {
    this.isDisabledAction = event;
  }

  public sortChangeInternal(event) {
    this._sortOption = {
      sortBy: event[0],
      sort: event[1],
    };
    switch (this._sortOption.sortBy) {
      case 'disputeTimeMoment':
        if (this._sortOption.sort === 'asc') {
          this._setSyncSortCard('Least recent');
        } else {
          this._setSyncSortCard('Most recent');
        }
        break;
    }
    this._incidentTableSort$.next(event);
    this.loadPageData(
      this.currentPageInfo.pageIndex,
      this.currentPageInfo.pageSize,
      true,
      this._sortOption
    );
  }

  public notifySortChange(event) {
    if (event[0] !== this.syncSortCard.sortColumn || event[1] !== this.syncSortCard.sortOrder) {
      this._notifyPageChange$.next(event);
    }
  }

  /**
   * @description: function to set media type of a particular incident
   * @param: incident details, is called from media record update
   */
  public setMedia(incident, updateRecord = false) {
    if (!incident) {
      this.incidentData = {};
    } else {
      this.storeCurrentIncidentData = Object.assign(incident);
      this.switch = !this.switch;
      this.selectedIncident = incident;
      if (this.selectedIncident.index !== undefined) {
        delete this.selectedIncident.index;
      }

      // support getting missing speed data for speeding violation
      if (SPEEDING_EVENT === incident.eventType) {
        const id = `${incident.eventIndex}_${incident.tripId}_${incident.driverId}`;
        if (!this.mediaRecord[id] || (!updateRecord && !this.mediaRecord[id].speed)) {
          this._requestSpeedingData(incident.eventIndex, id, incident);
        }
        this.incidentData = {
          mediaLink: this.mediaRecord[id].media,
          driverId: incident.driverId,
          tripId: incident.tripId,
          eventIndex: incident.eventIndex,
          eventType: incident.eventType,
          speed: this.mediaRecord[id].speed,
          speedSign: {
            eventVideoFilename: this.mediaRecord[id].postedMedia,
            speedSignValue: this.mediaRecord[id].postedSpeed,
          },
          isLoadingSpeedData: this.mediaRecord[id].isLoading,
        };
      } else {
        this.incidentData = {
          mediaLink: incident.videoLink,
          driverId: incident.driverId,
          tripId: incident.tripId,
          eventIndex: incident.eventIndex,
          eventType: incident.eventType,
          speed: incident.speed,
          speedSign: {
            eventVideoFilename: incident.speedSign
              ? incident.speedSign.eventVideoFilename
              : undefined,
            speedSignValue: incident.speedSign
              ? incident.speedSign.speedSignValue
              : undefined,
          },
        };
      }
    }
  }

  /**
   * @description: to check whether the http link is a image or not
   * @param: the src
   */
  public isImage(src) {
    return src.indexOf('.jpg') > -1;
  }

  /**
   * @description: Update page info and emit the information to table data source
   * @param: pageIndex pageIndex begin with 0
   * @param: pageSize item per page
   */
  public loadPageData(
    pageIndex: number,
    pageSize: number,
    selectFirstRow = false,
    sortOption: SortOption,
    selectCustomRow = -1,
    isInitTable = false,
    isUpdateSelectedIncident = false
  ) {
    const isReverseData = false;
    this._zcFleet
      .getSavedIncident(
        this._buildOptionQuerySavedIncident(pageIndex, pageSize, sortOption),
        isReverseData
      )
      .subscribe(
        (res) => {
          res.rows.forEach(incident => {
            incident.displayTimeZone =
          (new Date(incident.timestampUTC).getTime() -
            new Date(incident.timestamp).getTime()) /
          60000 /
          60;
            incident.bookmark = true;
            incident._eventType = EVENTS_INCIDENT_TYPE.find((x) => x[0] === incident.eventType)[1] || '';
          });

          this.incidentsList = res.rows.map(this._addDisputeTimeMoment.bind(this));
          this._requestTableData();
          // If reloaded incidentsList length shorter than selected row index,
          // select the incidentsList last item index instead
          if (selectCustomRow >= this.incidentsList.length) {
            selectCustomRow = this.incidentsList.length - 1;
          }
          this.currentPageInfo = {
            pageIndex,
            pageSize,
            totalItems: res.totalItems,
            totalPage: res.totalPage,
          };

          // Reload page data when an incident unbookmarked
          // and reselect new one if available
          if (isUpdateSelectedIncident) {
            // update pageIndex and selected incident
            // if removed incident does not have any incidents below it
            // if response offset does not match current page offset
            if (this.incidentsList.length && selectCustomRow === -1
                 || this.offset !== parseInt(res.skip, 10)) {
              selectCustomRow = this.incidentsList.length - 1;
              this.currentPageInfo.pageIndex = res.totalPage - 1;
            }
            this.selectedIncident = this.incidentsList[selectCustomRow];
            this.setMedia(this.selectedIncident);
          }

          // Initializing the saved incident list table's data
          if (isInitTable) {
            // Set the image/video from first incident
            if (this.incidentsList.length) {
              this.setMedia(this.incidentsList[0]);
              this._storage.showNotification = true;
            } else {
              this.setMedia(null);
              this._storage.showNotification = false;
            }
          }

          this._incidentListTable$.next({
            data: this.incidentsList,
            totalItems: res.totalItems,
            totalPage: res.totalPage,
            perPage: res.limit,
            pageIndex: this.currentPageInfo.pageIndex,
            selectFirstRow,
            selectCustomRow,
          });
          this.show = 'data';
          this.isShowError.emit(false);
        },
        (err) => {
          console.log('error while fetching saved incident table data', err);
          this.isShowError.emit(true);
        }
      );
  }

  /**
   * @description update data by paginator index
   * @param event paginator object include pageIndex and PageSize
   */
  public updatePage(event) {
    this.currentPageInfo.pageIndex = event.pageIndex;
    this.loadPageData(event.pageIndex, event.pageSize, false, this._sortOption);
    this._pageChange$.next(event);
  }

  /**
   * @description call after bug reported for selected row
   * @param incident selected incident
   */
  public updateIncidentList(incident) {
    const selectedIncident = this.incidentsList.find(item => this._isIncidentEqual(item, incident));
    this._updateIncidentList(selectedIncident);
  }

  /**
   * @description handle actions while update comments or bookmark
   * @param event updated incident's detail
   */
  public updateTable(event: BookmarkInfo) {
    try {
      const isBookmarkChanged = (item) => {
        const target = this.incidentsList.find(incident =>
          incident.tripId + incident.eventIndex === item.tripId + item.eventIndex
        );
        return target && target.bookmark !== item.bookmark;
      };

      if ( event.status && event.status === 'updateBookmark' && isBookmarkChanged(event)) {
        this.updateIncidentList(event);
      } else if (event.status && event.status === 'updateComment') {
        this.loadPageData(
          this.currentPageInfo.pageIndex,
          this.currentPageInfo.pageSize,
          false,
          this._sortOption
        );
      }
    } catch (e) {
      this._snackbarService.error(
        'Something went wrong',
        'Try again'
      ).subscribe(() => this.updateTable(event));
    }
  }

  public generateUrl(oneTimeToken, format): string{
    const url = API.EXPORT_SAVED_INCIDENT(format);
    return url + '?' + Object.entries({
      oneTimeToken,
    }).map(([x, y]) => `${x}=${y}`);
  }

  public getRowDataIdentifier(element: any): string {
    if (element) {
      return element.tripId + '-' + element.eventIndex;
    }
    return '';
  }


  public downloadFile(event) {
    this._zcFleet.getOneTimeToken().subscribe(otp => {
      const downloadUrl = this.generateUrl(otp.oneTimeToken, event);
      const divisions = this._storage.getStorageValue('HOME_LOCATION_ABV') || [];
      const mine = event === 'pdf' ? 'application/pdf' : 'text/csv';
      const params = {
        divisions,
        isAllLocationsQuery: this._storage.getStorageValue('HOME_LOCATION_FULL_LIST')?.length === divisions.length,
        minScore: 0,
        maxScore: 100,
        dutyType: 'All',
        ...this.paramOption,
      };
      this._zcFleet.makeExportRequest(downloadUrl, {params}, mine).subscribe(
        data => {
          const downloadURL = URL.createObjectURL(data);
          const link = document.createElement('a');
          link.target = '_blank';
          link.href = downloadURL;
          link.download = this._getfileName(event);
          link.click();
          link.remove();
        },
        _err => {
          this._snackbarService.error(
            'Something went wrong',
            'Try again'
          ).subscribe(() => this.downloadFile(event));
        }
      );
    },
    (_err) => {
      this._snackbarService.error(
        'Something went wrong',
        'Try again'
      ).subscribe(() => this.downloadFile(event));
    });
  }

  private _getfileName(extension: string) {
    return `exportSavedIncidentFile.${extension}`;
  }

  private _buildOptionQuerySavedIncident( pageIndex: number, pageSize: number, sortOption: SortOption) {
    const { days } = this.filterOptions;
    let date;
    if (days === 2) {
      const from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      const to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      date = { from, to };
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList =
      this._storage.getStorageValue('HOME_LOCATION')?.length === 0
        ? JSON.parse(localStorage.getItem('HOME_LOCATION_ABV'))
        : this._storage.getStorageValue('HOME_LOCATION');
    this.offset = pageIndex * pageSize;

    this.paramOption = {
      startTime: date.from,
      endTime: date.to,
      sortBy: this._convertParamQuery(sortOption.sortBy),
      sort: sortOption.sort,
      autoLoadLastPage: true,
    };

    return {
      startTime: date.from,
      endTime: date.to,
      limit: pageSize,
      offset: this.offset,
      sortBy: this._convertParamQuery(sortOption.sortBy),
      sort: sortOption.sort,
      autoLoadLastPage: true,
      divisions: this.homeLocationList,
    };
  }

  private _updateIncidentList(incident) {
    // current index of incident
    const listIndex = this.incidentsList.indexOf(incident);
    // Remove the incident from current incident list - list line should always remove one item successfully
    // if not, please consider making some check
    this.incidentsList = this.incidentsList.filter(item => !this._isIncidentEqual(item, incident));

    // Clear selected row when the user delete that row
    let selectedPageRowIndex = -1;
    let isUpdateSelectedIncident = false;
    if (this.selectedIncident && this._isIncidentEqual(incident, this.selectedIncident)) {
      if (this.incidentsList.length > 0) {
        selectedPageRowIndex = listIndex;
      } else if (this.incidentsList.length === 0 && this.currentPageInfo.pageIndex === 0) {
        this.selectedIncident = null;
        this.setMedia(this.selectedIncident);
      }
      isUpdateSelectedIncident = true;
    }

    // Reload table page data after remove incident
    this.loadPageData(
      this.currentPageInfo.pageIndex,
      this.currentPageInfo.pageSize,
      false,
      this._sortOption,
      selectedPageRowIndex,
      false,
      isUpdateSelectedIncident
    );
  }

  /**
   * @description: check if two incidents are equal
   */
  private _isIncidentEqual(incident1, incident2): boolean {
    return (
      incident1.tripId + incident1.eventIndex ===
      incident2.tripId + incident2.eventIndex
    );
  }

  private _addDisputeTimeMoment(item): any {
    item.disputeTimeMoment = createMomentObjectUtc(item.timestampUTC).tz('Etc/GMT+7');

    return item;
  }

  private _setSyncSortCard(name: string) {
    this.syncSortCard = {
      displayName: name,
      sortColumn: this._sortOption.sortBy,
      sortOrder: this._sortOption.sort,
    };
  }

  private _convertParamQuery(sortBy: string): string {
    const dataMapping = {
      disputeTimeMoment: 'violationTime',
      _eventType: 'category',
    };
    return dataMapping[sortBy] || 'violationTime';
  }

  /**
   * @description: function listen when update event comments request
   */
  private _listenEventCommentViolationRequest() {
    this._zcFleet.refreshAPI$.subscribe(() => {
      this._zcFleet.getSingleCommentByViolationIdentifier(this.violationIdentifier)
        .subscribe(
          res => {
            this.incidentsList.forEach(incident => {
              const violationId = incident.tripId + '-' + incident.eventIndex;
              if (violationId === this.violationIdentifier) {
                incident.comments = res;
              }
            });
            this._incidentListTable$.next({
              data: this.incidentsList,
              totalItems: this.currentPageInfo.totalItems,
              totalPage: this.currentPageInfo.totalPage,
              perPage: this.currentPageInfo.pageSize,
              pageIndex: this.currentPageInfo.pageIndex,
              selectFirstRow: false,
            });
            this.show = 'data';
          }
        );
    });
  }

  private _requestTableData() {

    // Set driverName to '_UNASSIGNED' for violations with driverId is '_UNASSIGNED'
    // Or if violation already has driverName === '', set driverName to 'Unidentified driver'
    this.incidentsList.forEach(item => {
      if (item.driverName) {
        item.driverNameAsync = of(item.driverName);
      }
    });

    // get asset number API request's body payload
    const tripList: [AssetFromTripRequest, number][] = this.incidentsList.map(
      (item, index) => [{ tripId: item.tripId, driverId: item.driverId }, index]
    );

    // get driver name API request's body payload
    // only reverse driverName for violations that not included driverName info
    const driverIds: [string, number][] = this.incidentsList.filter(
      item => !item.driverName).map((item, index) => [item.driverId, index]);

    this._reverseData(tripList, driverIds);
  }

  private _reverseData(tripList, driverIds) {
    this._reverseAssetNameJobs(tripList);
    if (driverIds.length) {
      this._reverseDriverNameJobs(driverIds);
    }
  }

  private _reverseAssetNameJobs(tripList: [AssetFromTripRequest, number][]) {
    const uniqueList = makeUniqueWithIndex(
      tripList,
      (v1, v2) => v1.tripId === v2.tripId
    );

    this._zcFleet.getMultipleAssetFromTrips(uniqueList.map((item) => item[0]))
      .subscribe((res) => {
        this.incidentsList.forEach(incident => {
          const data = res[incident.tripId];
          if (data && data.assetName) {
            incident.assetName = of(res[incident.tripId].assetName);
          } else {
            incident.assetName = of('N/A');
          }
        });
      });
  }

  private _reverseDriverNameJobs(
    driverIdList: [string, number][]
  ) {

    if (!driverIdList.length) {
      return;
    }

    const uniqueList = makeUniqueWithIndex(
      driverIdList,
      (driverId, currentVal) => driverId === currentVal
    );
    const uniqueDriverIds = uniqueList.map(item => item[0]);
    this._zcFleet.getMultipleUserInfoFromUserProfileIds(uniqueDriverIds)
      .subscribe((res) => {
        this.incidentsList.forEach(item => {
          const data = res[item.driverId];
          if (data && data.firstName && data.lastName) {
            item.driverName = res[item.driverId].firstName + ' ' + res[item.driverId].lastName;
          } else if (uniqueDriverIds.includes(item.driverId)) {
            item.driverName = ERROR_MESSAGE.INVALID_DRIVER_ID;
          }
          item.driverNameAsync = of(item.driverName);
        });
      }, (error) => {
        console.log(error);
        this.incidentsList.forEach(item => {
          if (uniqueDriverIds.includes(item.driverId)) {
            item.driverNameAsync = of(ERROR_MESSAGE.INVALID_DRIVER_ID);
          }
        });
      });
  }

  private _requestSpeedingData(index: number, id: string, incident: any) {
    const requestData = this._zcFleet.getTripDetails(incident.driverId, incident.tripId).pipe(
      // In event of data requesting is errored or internet is lost, response an empty data
      catchError(_err => of({}))
    );
    this.mediaRecord[id] = {
      media: incident.videoLink,
      speed: undefined,
      postedMedia: undefined,
      postedSpeed: undefined,
      dataAsync: requestData,
      isLoading: true,
    };

    this.mediaRecord[id].dataAsync.subscribe(
      res => {
        // No error encounter and can find the speeding event, update the record
        if (res?.violations?.length > 0) {
          const event = res.violations.find(item => item.eventIndex === index);
          if (event) {
            this.mediaRecord[id].speed = event.speed;
            this.mediaRecord[id].postedMedia = event.speedSign.eventVideoFilename;
            this.mediaRecord[id].postedSpeed = event.speedSign.speedSignValue;
          }
        }
        this.mediaRecord[id].isLoading = false;

        // If current display media is still the same requested media, update it.
        if (
          this.selectedIncident.eventIndex === incident.eventIndex &&
          this.selectedIncident.driverId === incident.driverId &&
          this.selectedIncident.tripId === incident.tripId
        ) {
          this.setMedia(incident, true);
        }
      }
    );
  }

}
