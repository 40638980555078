import { Directive, EmbeddedViewRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission, PermissionManagerService } from '@app-core/services/permission-manager.service';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

/**
 * Directive that is same as ngIf, but accept permission string instead of
 * boolean.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifOnPermission]',
})
export class IfOnPermissionDirective implements OnInit, OnDestroy {
  /** Template that will be rendered when has permission */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ifOnPermissionThen')
  public authorizedTemplate: TemplateRef<any>;

  /** Template that will be rendered when has no permission */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ifOnPermissionElse')
  public unauthorizedTemplate: TemplateRef<any>;

  /** The target permission to check on */
  @Input('ifOnPermission')
  public set onCheckPermissionChange(checkPermission: Permission) {
    this._checkPermission = checkPermission;
  }

  private _checkPermission: Permission;
  private _onDestroy$ = new ReplaySubject();

  constructor(
    private _permissionService: PermissionManagerService,
    private _viewContainer: ViewContainerRef,
    private _templateRef: TemplateRef<any>
  ) { }

  public ngOnInit(): void {
    this._getIsAuthorizedObs().subscribe(
      this._viewOnPermission.bind(this)
    );
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getIsAuthorizedObs(): Observable<boolean> {
    this._permissionService.requestPermissionCheck(this._checkPermission);
    return this._permissionService.getPermissionObs(this._checkPermission)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      );
  }

  private _viewOnPermission(hasPermission: boolean) {
    this._viewContainer.clear();
    this._renderTemplate(this._getTemplateRender(hasPermission));
  }

  private _getTemplateRender<T = any>(hasPermission: boolean): TemplateRef<T> {
    const unauthorizedTemplate = this.unauthorizedTemplate || null;
    const authorizedTemplate = this.authorizedTemplate || this._templateRef;
    return hasPermission ? authorizedTemplate : unauthorizedTemplate;
  }

  private _renderTemplate<T = any>(templateRef: TemplateRef<T>): EmbeddedViewRef<T> {
    if (templateRef !== null) {
      return this._viewContainer.createEmbeddedView(templateRef);
    }
  }
}
