import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';

export interface IncidentChallenge {
  type: string;
  body: {
    isReportBug?: boolean;
    isAccepted?: boolean;
  };
}

export interface PopupCancel {
  showPopup: string;
  hideCancel: boolean;
}
@Component({
  selector: 'app-incident-button-control',
  templateUrl: './incident-button-control.component.html',
  styleUrls: ['./incident-button-control.component.scss'],
})
export class IncidentButtonControlComponent implements OnInit {
  @Input()
  public challengeAccepted: boolean;

  @Input()
  public challengeRaised: boolean;

  @Input()
  public challengeResolved: boolean;

  @Input()
  public eventType: string;

  @Input()
  public popupCancel: PopupCancel = {
    showPopup: '',
    hideCancel: false,
  };

  @Input()
  public notLoadingState$: Observable<boolean>;

  @Output()
  public incidentButtonChange: EventEmitter<IncidentChallenge> = new EventEmitter();

  @Output()
  public snackbarCancelChange: EventEmitter<Record<string, string>> = new EventEmitter();
  constructor() { }

  public ngOnInit(): void {}

  public onIncidentButtonChange(type: string) {
    this.popupCancel.showPopup = type;
    this.popupCancel.hideCancel = false;
    const incidentBody = {
      bug: { isReportBug: true },
      accept: { isAccepted: true },
      reject: { isAccepted: false },
    };
    this.incidentButtonChange.emit({
      type,
      body: incidentBody[type],
    });
  }

  public onSnackbarCancelChange(status: string) {
    this.popupCancel.showPopup = '';
    this.popupCancel.hideCancel = true;
    this.snackbarCancelChange.emit({
      status,
    });
  }
}
