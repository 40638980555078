/**
 * @description: To reset the paginator to first page, set "reset" flag
 * on the paginator to true
 *
 * the flag will be set to false after paginator gets reset
 *
 * @example :
 *   <app-paginator2 [arrayList]="list" [(reset)]="reset" [pageLimit]="10">
 *   </app-paginator2>
 *
 *          this.list = [];
 *          reset = true;
 */

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

interface Result {
  list: any[];
  page?: number;
}

const PAGE_LIMIT = 10;

@Component({
  selector: 'app-paginator2',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorTwoComponent implements OnChanges {
  @Input()
  public pageLimit: number = PAGE_LIMIT;
  @Input()
  public arrayList: any[] = [];
  @Output()
  public pageChange = new EventEmitter<Result>();

  @Input() public reset = false;
  @Output() public resetChange = new EventEmitter<boolean>();

  @Input() public update = false;
  @Output() public updateChange = new EventEmitter<boolean>();

  public activePage = 0;
  public pageStart = 0;
  public pageEnd = 0;

  private _firstUpdate = true;
  private _pageArray: any[] = [];

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.reset) {
      if (changes.reset.currentValue === true) {
        this._resetPaginator();
        setTimeout(() => {
          this.resetChange.emit(false);
        }, 1);
        this._paginate();
      }
    }
    if (changes.update) {
      if (changes.update.currentValue === true) {
        setTimeout(() => {
          this.updateChange.emit(false);
        }, 1);
        this._paginate();
      }
    }
  }

  public setPage(n) {
    if (!this._pageArray.length) {
      this.pageChange.emit({ list: [] });
      this._resetPaginator();
      return;
    }
    this.activePage = n;
    this.pageChange.emit({
      list: this._pageArray[this.activePage - 1] || [],
      page: this.activePage,
    });
  }

  public firstPage() {
    this.setPage(this.pageStart);
  }

  public nextPage() {
    if (this.pageEnd > this.activePage) {
      this.setPage(this.activePage + 1);
      // this.generateFrame();
    }
  }

  public previousPage() {
    if (this.pageStart < this.activePage) {
      this.setPage(this.activePage - 1);
      // this.generateFrame();
    }
  }

  public lastPage() {
    this.setPage(this.pageEnd);
  }

  private _resetPaginator() {
    this.activePage = 0;
    this.pageStart = 0;
    this.pageEnd = 0;
    this._firstUpdate = true;
  }

  private _paginate() {
    if (this._firstUpdate) {
      this.activePage = 1;
      this.pageStart = 1;
    }

    if (+this.pageLimit) {
      this.pageEnd = Math.floor(this.arrayList.length / +this.pageLimit);
      const rem = +this.arrayList.length % +this.pageLimit;

      if (rem !== 0) {
        this.pageEnd += 1;
      }

      let i;
      let j;
      const tempArray = [];
      for (i = 0, j = this.arrayList.length; i < j; i += +this.pageLimit) {
        tempArray.push(this.arrayList.slice(i, i + +this.pageLimit));
      }
      this._pageArray = tempArray;
    }

    // On some updates,if last element from last page is deleted, then update
    // the active page to be the previous page
    if (this.activePage > this.pageEnd) {
      this.activePage = this.pageEnd;
    }
    if (this._firstUpdate) {
      this._firstUpdate = false;
    }
    this.setPage(this.activePage);
  }
}
