import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortInfo } from '@modules/shared/components/card/card.component';
import { PaginationData } from '@modules/shared/components/table/table.component';
import { ErrorMessage } from '@modules/shared/components/error-message/error-message.component';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-driver-history',
  templateUrl: './driver-history.component.html',
  styleUrls: ['./driver-history.component.scss'],
})
export class DriverHistoryComponent implements OnInit {

  @Input() public set hasError(value: boolean) {
    this.errorState = value;
    if (value) {
      this._errorMessage$.next({
        message: '',
        action: '',
      });
    }
  }
  @Input() public pageSize = 10;
  @Input()
  public set driverHistoryData$(value: Observable<PaginationData<any>>) {
    this.internalDriverHistoryData$ = value.pipe(shareReplay());
  }
  @Input()
  public set reload$(value: Observable<boolean>) {
    const driverHistoryState = merge(value, this.internalDriverHistoryData$);
    const mapper = map(mergeValue => (mergeValue !== true && mergeValue !== undefined));
    this.notLoadingState$ = driverHistoryState.pipe(mapper);
  }

  @Output() public pageChange: EventEmitter<any> = new EventEmitter();
  @Output() public sortChange: EventEmitter<any> = new EventEmitter();

  public errorState = false;
  public get errorMessage$(): Observable<ErrorMessage> {
    return this._errorMessage$;
  }
  public notLoadingState$: Observable<boolean>;
  public internalDriverHistoryData$: Observable<PaginationData<any>>;
  public sortList: SortInfo[] = [
    {
      displayName: 'Most recent',
      sortColumn: 'lastTrip',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least recent',
      sortColumn: 'lastTrip',
      sortOrder: 'asc',
    },
    {
      displayName: 'Most frequent',
      sortColumn: 'totalTrip',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least frequent',
      sortColumn: 'totalTrip',
      sortOrder: 'asc',
    },
    {
      displayName: 'Most incidents',
      sortColumn: 'totalIncident',
      sortOrder: 'desc',
    },
    {
      displayName: 'Least incidents',
      sortColumn: 'totalIncident',
      sortOrder: 'asc',
    },
  ];

  public displayedColumns = ['driver', 'lastTrip', 'totalTrip', 'topIncident', 'totalIncident'];

  public colDescriptions = [
    {
      colKey: 'driver',
      colDisplayName: 'Driver',
      sortable: false,
      type: 'custom',
      getter: (item) => item.driverNameAsync,
      async: true,
    },
    {
      colKey: 'lastTrip',
      colDisplayName: 'Last Trip on Asset',
      sortable: false,
      type: 'date-time',
      getter: (item) => item.lastTrip,
    },
    {
      colKey: 'totalTrip',
      colDisplayName: 'Total Trips on Asset',
      sortable: false,
      type: 'text',
      getter: (item) => item.totalTrip,
    },
    {
      colKey: 'topIncident',
      colDisplayName: 'Top Incident on Asset',
      sortable: false,
      type: 'text',
      getter: (item) => item.topIncident,
    },
    {
      colKey: 'totalIncident',
      colDisplayName: 'Total Incidents on Asset',
      sortable: false,
      type: 'text',
      getter: (item) => item.totalIncident,
    },
  ];
  public dummyLoadData = [];

  public driverTable$: Observable<PaginationData<any>>;
  public get notifySortChange$(): Observable<[string, 'desc' | 'asc']> {
    return this._notifyPageChange$;
  }

  public get driverHistoryTable$(): Observable<PaginationData<any>> {
    return this._driverHistoryTable$;
  }
  private _driverHistoryTable$ = new ReplaySubject<PaginationData<any>>();

  private _notifyPageChange$ = new ReplaySubject<[string, 'desc' | 'asc']>();

  private _errorMessage$ = new ReplaySubject<ErrorMessage>();

  constructor() {
    this.dummyLoadData = this._createDummyData();
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.updatePage({pageIndex: 0});
  }

  public updatePage(event: any) {
    this.pageChange.emit(event);
  }

  public updateSort(event: any) {
    this.sortChange.emit(event);
  }

  public getUniqueKey(element: any) {
    return element ? element.driverId : '';
  }

  private _createDummyData() {
    const result = [];
    for (let i = 0; i < this.pageSize; i ++) {
      result.push({
        driverId: i,
        driverName: i,
        lastTrip: i,
        totalTrip: i,
        totalIncident: i,
        topIncident: i,
      });
    }
    return result;
  }

}
