<div class="header">
  Incident Video: <span>{{ getEventType(incidentEventValue?.eventType) }}</span>
</div>

<div class="close-button" (click)="onCloseButtonClick()">
  <img src="assets/images/close_24px.svg" alt="close" />
</div>

<div class="comment-section">
  <ng-container *ngTemplateOutlet="customTemplate"></ng-container>
</div>

<div class="incident-media">
  <!-- <app-media-show [event]="incidentEvent" [tripId]="tripId"></app-media-show> -->
  <app-incident-media-control
    [incidentData]="videoData"
    [selectedRowData]="incidentEventValue"
    (challengeUpdated)="onChallengeUpdated($event)"
    [showSmallSizeMediaPanel]="true"
    [hideViewTripButton]="true"
    [switch]="true">
  </app-incident-media-control>
</div>
