<div class="custom-ngx-slider" [class.no-handler]="disableHandler" *ngIf="isDisplaySlider">
  <ngx-slider [(value)]="durationStartTime" [(highValue)]="durationEndTime"
    [options]="sliderOptions" (userChangeEnd)="onUserChangeEnd($event)"></ngx-slider>

  <div class="incidentlist">
    <div class="row">
        <div *ngFor="let incident of incidents">
            <div class="icons {{incident.type[0]}}"
                [style.left]="incident.timetaken+'%'">
            </div>
        </div>
    </div>
  </div>

  <div class="date-point" *ngIf="halfDatePoint > 0">
    <div class="icons"
        [style.left]="halfDatePoint+'%'">
    </div>

    <div *ngIf="isPeriodAm; else elseBlock">
      <div class="pre-period"
        [style.left]="halfDatePoint+'%'">
      <span>AM</span>
      </div>

      <div class="after-period"
          [style.left]="halfDatePoint+'%'">
        <span>PM</span>
      </div>
    </div>

    <ng-template #elseBlock>
      <div class="after-period"
        [style.left]="halfDatePoint+'%'">
      <span>AM</span>
      </div>

      <div class="pre-period"
          [style.left]="halfDatePoint+'%'">
        <span>PM</span>
      </div>
    </ng-template>
  </div>
</div>