<div class="clearfix"></div>
<footer class="footer">
  <div class="container-fluid">
    <a href="https://www.zonarsystems.com" target="_blank">
      <img src="assets/images/zonar-logo.png" alt="ZonarSystems" style="border:0;">
    </a>
    <h6>© {{ date.getFullYear() }} Zonar Systems | v {{appVersion}}</h6>
    <a target="_blank" href="https://support.zonarsystems.net/hc/en-us/sections/360002547151-Zonar-Coach">Support</a>
    &nbsp;&nbsp;
    <a target="_blank" href="javascript:void(0)"> Terms of Service</a>
  </div>
</footer>