import { Component, Input } from '@angular/core';
import { EVENT_NAMES_MAP } from '@modules/dashboard3/dashboard3.models';

@Component({
  selector: 'app-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss'],
})
export class MapTooltipComponent {
  @Input() public event: any;

  constructor() {}

  /**
   * @description: function to get the type of event based on tooltip click
   * @param: incident name
   */
  public getEventType(name) {
    return EVENT_NAMES_MAP[name];
  }
}
