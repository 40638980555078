import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface ExportOptions {
  autoExport?: boolean;
}

@Injectable()
export class ExportService {
  private _exportModalSubject = new Subject<any>();
  private _exportModalState = this._exportModalSubject.asObservable();

  /**
   * @description: export modal function
   * @returns: the export modal open and close state
   */
  public exportModal() {
    return this._exportModalState;
  }

  /**
   * @description: shwowing the export modal based on user click
   * @param: the options for the export modal such as size and position
   */
  public showExportModal(options: ExportOptions) {
    options['show'] = true;
    this._exportModalSubject.next(options);
  }

  /**
   * @description: hiding the export modal based on user action
   * @param:
   */
  public hideExportModal() {
    this._exportModalSubject.next({ show: false });
  }
}
