import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({ selector: '[appClickOutside]' })
export class ClickOutSideDirective {
  @Output() public appClickOutside = new EventEmitter<MouseEvent>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  public onDocClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Check if the click was outside the element
    if (
      targetElement &&
      !this._elementRef.nativeElement.contains(targetElement)
    ) {
      this.appClickOutside.emit(event);
    }
  }
}
