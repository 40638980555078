<div class="page-wrapper zc-body zc-app-wrap">
  <div class="front-drop" *ngIf="shouldHideAll">

  </div>
  <div class="page-body">
    <div class="upper-part">
      <div class="title">{{title}}</div>
      <div class="desc"><span>{{errorMsg}}</span></div>
      <button (click)="goToHome()" class="link">Go to homepage</button>
    </div>
    <div class="lower-part">
      <div class="common-part">
        <div class="info">
          <span class="name">Support Phone: </span>
          <span class="desc">877-843-347</span>
        </div>
        <div class="info">
          <span class="name">Support Email: </span>
          <span class="desc">customercare@zonarsystems.com</span>
        </div>
        <div class="info">
          <span class="name">Authorization Error: </span>
          <span class="desc">{{reason}}</span>
        </div>
        <div class="info">
          <span class="name">Url: </span>
          <span class="desc">"{{url}}"</span>
        </div>
        <div class="info">
          <span class="name">Status: </span>
          <span class="desc">{{status}}</span>
        </div>
        <div class="info" *ngIf="status === '500'">
          <span class="name">Debug Data:     </span>
          <span class="debug-info-toggler" (click)="toggleDebugInfo()">
            <span class="toggler" *ngIf="!isShownDebug">
              <img src="/assets/images/arrow_down_white.png" width="12px" alt="">
              <span>show more</span>
            </span>
            <span class="toggler" *ngIf="isShownDebug">
              <img src="/assets/images/arrow_up_white.png" width="12px" alt="">
              <span>show less</span>
            </span>
          </span>
        </div>
      </div>
      <div class="detail-part" [hidden]="!isShownDebug">
        {{debugData}}
      </div>
    </div>
  </div>
  <div class="logo"></div>
</div>
