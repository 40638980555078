import { Injectable } from '@angular/core';

import { FilterStrore } from './../dashboard3.models';
import { StorageService } from '@app-core/services/storage.service';
import { DateService } from '@app-core/services/date.service';
import * as moment from 'moment';

const FILTER_STORE = 'FILTER_STORE';
const CUSTOM_RANGE = 'CUSTOM_RANGE';

export const DRIVER_AVG_SCORE_GRAPH_OPTION = {
  chart: {
    height: 300, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 20, // number represents pixels
    spacingRight: 57, // number represents pixels
    // eslint-disable-next-line quotes,@typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
  },
  title: {
    text: 'Driver Score Over Time',
    align: 'left',
    margin: 33,
    spacingTop: 32,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: '#e9ebec',
    borderColor: '#e9ebec',
  },
  yAxis: {
    pointPlacement: 'on',
    gridLineWidth: 1,
    title: {
      text: 'Fleet Average',
    },
    min: null,
    max: 100,
    endOnTick: false,
    maxPadding: 0.25,
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemStyle: {
      color: '#293239',
      fontWeight: 'light',
      fontSize: '12px',
    },
  },
  plotOptions: {
    // area : {
    //     pointStart: 0
    // },
    line: {
      pointPlacement: 'on',
    },
    series: {
      connectNulls: false,
      marker: {
        lineWidth: 1,
        lineColor: 'white',
      },
      label: {
        connectorAllowed: false,
      },
      // events: {
      //     legendItemClick: (event) => { event.preventDefault(); }
      // },
      states: {
        hover: {
          halo: {
            size: 0,
          },
        },
      },
    },
  },
  xAxis: {
    tickmarkPlacement: 'on',
    tickInterval: 1,
    gridLineWidth: 1,
    categories: [],
    endOnTick: false,
    maxPadding: 5,
    labels: {
      step: 1,
    },
  },
  series: [
    {
      name: 'Fleet Average',
      data: [],
      color: '#a2aaaf',
    },
    {
      name: 'Score',
      data: [],
      color: '#2d8ed9',
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

export const DRIVER_INCIDENTS_GRAPH_OPTION = {
  chart: {
    height: 534, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 20, // number represents pixels
    spacingRight: 57, // number represents pixels
    // eslint-disable-next-line quotes, @typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
  },

  title: {
    text: 'Incidents Over Time',
    align: 'left',
    margin: 33,
    spacingTop: 32,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    backgroundColor: '#e9ebec',
    borderColor: '#e9ebec',
  },

  yAxis: {
    pointPlacement: 'on',
    gridLineWidth: 1,
    title: {
      text: 'Number of Incidents',
      margin: 20,
    },
    min: 0,
    max: null,
    tickInterval: 2,
    maxPadding: 0.25,
    tickWidth: 1, // extra line on y-axis for labels
    tickLength: 10,
    labels: {
      x: -20,
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemStyle: {
      color: '#293239',
      fontWeight: 'light',
      fontSize: '12px',
    },
  },

  plotOptions: {
    line: {
      pointPlacement: 'on',
    },
    series: {
      connectNulls: false,
      label: {
        connectorAllowed: false,
      },
      states: {
        hover: {
          halo: {
            size: 0,
          },
        },
      },
      marker: {
        lineWidth: 1,
        lineColor: 'white',
      },
    },
  },

  xAxis: {
    tickmarkPlacement: 'on',
    tickInterval: 1,
    gridLineWidth: 1,
    categories: [],
    maxPadding: 5,
    min: null,
    max: null,
    labels: {
      step: 1,
    },
  },
  series: [
    {
      name: 'Speeding',
      data: [],
      color: '#2a8596',
    },
    // {
    //     name: 'Max Speed',
    //     data: [],
    //     color: '#2a8596'
    // },
    {
      name: 'Accelerating',
      data: [],
      color: '#8ba600',
    },
    {
      name: 'Braking',
      data: [],
      color: '#e67300',
    },
    {
      name: 'Cornering',
      data: [],
      color: '#6134b1',
    },
    {
      name: 'Lane Drift',
      data: [],
      color: '#e5389d',
    },
    {
      name: 'Rolling Stop',
      data: [],
      color: '#a82a89',
    },
    {
      name: 'Tailgating',
      data: [],
      color: '#814911',
    },
    {
      name: 'Distraction',
      data: [],
      color: '#731042',
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

/* eslint-disable @typescript-eslint/naming-convention */
export const defaultEventCount: any = {
  Cornering: null,
  'Harsh-Acceleration': null,
  'Harsh-Braking': null,
  'Lane-Drift-Found': null,
  'Tail-Gating-Detected': null,
  'Traffic-STOP-Sign-Violated': null,
  'Traffic-Speed-Violated': null,
  'Distracted-Driving': null,
};
/* eslint-enable @typescript-eslint/naming-convention */

@Injectable()
export class Data {
  public customRange;

  private _isFromFleet = false;
  private _filterStore: FilterStrore;

  constructor(
    private _storage: StorageService,
    private _dateService: DateService
  ) {
    const customRange = this._storage.getStorageValue(CUSTOM_RANGE);
    if (!customRange) {
      const from = new Date();
      const to = moment(new Date()).add(1, 'd');
      from.setDate(from.getDate() - 7);
      const range = { from, to };
      const DATA1 = {
        time: Date.now(),
        data: range,
      };
      this.customRange = DATA1;
      this._storage.setStorageValue(CUSTOM_RANGE, DATA1);
    }
    this._getFiltersFromLocalStorage();
  }

  public get fromFleet() {
    return this._isFromFleet;
  }

  public set fromFleet(flag: boolean) {
    this._isFromFleet = flag;
  }

  public get filterData() {
    return this._filterStore;
  }

  public set filterData(filterStore: FilterStrore) {
    const { isHomeLocationEditable = true } = {};
    // Setting isHomeLocationEditable here to avoid code change
    // in all other components which emits filter change event.
    this._filterStore = { ...filterStore, isHomeLocationEditable };
    this._setFiltersToLocalStorage();
  }

  // shared code b/w fleet-score-graph component & driver-score-graph component
  /** To decide the groupBy param for API request
   */
  public decideGroup(days, date, chartOptions, incidentsChartOptions) {
    let group;
    let dateArray = [];
    chartOptions.xAxis.labels.step = 1;
    incidentsChartOptions.xAxis.labels.step = 1;
    switch (days) {
      case -1:
      case 0:
      case 1:
        group = 'day';
        dateArray = this.generateDateArray('day', date, days);
        break;
      case 2:
        const fromInMSec = new Date(date.from).getTime();
        const toInMSec = new Date(date.to).getTime();
        const diff = (toInMSec - fromInMSec) / (1000 * 60 * 60 * 24);
        if (diff < 30) {
          group = 'day';
          dateArray = this.generateDateArray('day', date);
        } else if (diff > 30 && diff < 180) {
          group = 'week';
          dateArray = this.generateDateArray('week', date);
        } else {
          group = 'month';
          dateArray = this.generateDateArray('month', date);
          chartOptions.xAxis.labels.step = 1;
          incidentsChartOptions.xAxis.labels.step = 1;
        }
        if ((diff > 120 && diff < 180) || (diff < 30 && diff > 20)) {
          chartOptions.xAxis.labels.step = 2;
          incidentsChartOptions.xAxis.labels.step = 2;
        }
        break;
      case 7:
        group = 'day';
        dateArray = this.generateDateArray('day', date);
        break;
      case 14:
        group = 'day';
        dateArray = this.generateDateArray('day', date);
        break;
      case 30:
        group = 'day';
        dateArray = this.generateDateArray('day', date);
        chartOptions.xAxis.labels.step = 2;
        incidentsChartOptions.xAxis.labels.step = 2;
        break;
      case 90:
        group = 'week';
        dateArray = this.generateDateArray('week', date);
        chartOptions.xAxis.labels.step = 1;
        incidentsChartOptions.xAxis.labels.step = 1;
        break;
      case 180:
        group = 'week';
        dateArray = this.generateDateArray('week', date);
        chartOptions.xAxis.labels.step = 2;
        incidentsChartOptions.xAxis.labels.step = 2;
        break;
      default:
        group = 'week';
        break;
    }
    return { group, dateArray };
  }

  /**
   * create an array of objects containing all the dates during the span mentioned in groupBy
   * i.e, day(last 7 days), week (sunday of every week), month(first date of every month
   *
   * @param groupBy : String('day'|'week'|'month')
   * @param date : Object {from , to}
   * @param days : Number of days [ 2 defines custom range ]
   */
  public generateDateArray(groupBy: string, date, days?) {
    const dateArray = [];
    let currentDate: moment.Moment;
    currentDate = moment.utc(date.from);
    let stopDate = moment.utc(date.to).subtract(1, 'd');
    if (groupBy === 'day') {
      // condition for yesterday and today
      if (days === 0 || days === -1) {
        currentDate = moment.utc(new Date()).subtract(6, 'days');
        stopDate = moment.utc(new Date());
      }
      while (currentDate <= stopDate) {
        dateArray.push({
          label: moment(currentDate).format('YYYY-MM-DD'),
          score: null,
        });
        currentDate = moment.utc(currentDate).add(1, 'days');
      }
    } else if (groupBy === 'week') {
      while (currentDate <= stopDate) {
        currentDate =
          currentDate.weekday() > 0
            ? currentDate.subtract(currentDate.weekday(), 'days')
            : currentDate;
        dateArray.push({
          label: moment(currentDate).format('YYYY-MM-DD'),
          score: null,
        });
        currentDate = moment.utc(currentDate).add(7, 'days');
      }
    } else {
      while (currentDate <= stopDate) {
        const tmpCurrentDate = new Date(currentDate.year(), currentDate.month(), 1);
        dateArray.push({
          label: moment(tmpCurrentDate).format('YYYY-MM-DD'),
          score: null,
        });
        currentDate = moment.utc(currentDate).add(1, 'month');
      }
    }
    return dateArray;
  }

  public formatData(fleetAvgScore, dateArray) {
    let count = 0;
    for (const date of dateArray) {
      if (fleetAvgScore[count]) {
        if (date.label === fleetAvgScore[count].label) {
          date.score = fleetAvgScore[count].v2Score;
          date.eventCount = fleetAvgScore[count].eventCount;
          count++;
        } else {
          date.score = null;
          date.eventCount = defaultEventCount;
        }
        // condition when the last element of the array is missing
      } else {
        date.score = null;
        date.eventCount = defaultEventCount;
      }
    }
    return dateArray;
  }

  public getDateRangeFromDays(days: number){
    let date: any = {
      from: '',
      to: '',
    };
    // days = 2 in case date range custom
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );

      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this.customRange.data;
        this._dateService.customStartdate = this.customRange.data.from;
        this._dateService.customEndDate = this.customRange.data.to;
      }
    } else if (days === 0 || days === -1) {
      date = this._dateService.getDateRangeInISO(7);
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    return date;
  }

  /**
   * Set default value to filters
   */
  private _setDefaultStoreValue() {
    const { defaultHomeLocation = '', isHomeLocationEditable = true } = {};
    this._filterStore = new FilterStrore(
      7,
      0,
      100,
      1,
      defaultHomeLocation,
      '',
      [],
      isHomeLocationEditable
    );
    localStorage.setItem('HOME_LOCATION', '[]');
  }

  /**
   * Get filter value from local storage
   * if does not exist, then set the filter to default value and store it
   * in local storage
   */
  private _getFiltersFromLocalStorage() {
    const data = this._storage.getStorageValue(FILTER_STORE);
    if (this._storage.getStorageValue(CUSTOM_RANGE)) {
      this.customRange = this._storage.getStorageValue(CUSTOM_RANGE);
      this.customRange.data.from = this._dateService.toDaysStartISO(
        this.customRange.data.from
      );
      this._dateService.customStartdate = this.customRange.from;
      this._dateService.customEndDate = this.customRange.to;
    } else if (data.data.days === 2) {
      this._setDefaultStoreValue();
      this._setFiltersToLocalStorage();
    }
    /**
     * If data exists and time is less than 30 minues, then use the
     * stored value, else use the default value
     */
    if (data) {
      const time = Date.now();
      if (time - data.time < 1800000) {
        this._filterStore = data.data;
        return;
      }
    }
    this._setDefaultStoreValue();
    this._setFiltersToLocalStorage();
  }

  /**
   * Store the filter to local storage for persistency on refresh
   */
  private _setFiltersToLocalStorage() {
    const DATA = {
      time: Date.now(),
      data: this._filterStore,
    };
    this._storage.setStorageValue(FILTER_STORE, DATA);
  }
}
