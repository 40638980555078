<div class="time-input-form">
  <div class="start-time-field">
    <mat-form-field class="start-time-input" appearance="outline">
      <mat-label>Start time*</mat-label>
      <input type="text" matInput [placeholder]="formatHint" autocomplete="off" [(ngModel)]="displayValue"
        (blur)="finalizeUserInputTime()" (keydown)="handleKeyDown($event, $event.target.value)" (keydown.enter)="$event.target.blur()"
        (keydown.tab)="$event.target.blur()">
    </mat-form-field>
    <span class="hint-msg">{{formatHint}}</span>
  </div>

  <div class="half-day-field">
    <mat-form-field class="am-pm-dropdown" appearance="outline">
      <mat-label>AM/PM*</mat-label>
      <mat-select [disableOptionCentering]="true" [disabled]="disableDropDown" [value]="ampmValue" (selectionChange)="onDropdownChange($event)">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
