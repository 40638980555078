<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" style="z-index:
    1500 !important">
    <div class="backDrop"></div>
    <div class="modal-dialog modal-lg" style="height: 80%">
        <div class="modal-content">
            <div class="modal-body" style="height: 230px;  overflow-y: auto; overflow-x: hidden;">
                <div class="capturediv">
                    <span class="pull-right ml-auto" style="cursor: pointer;"
                        (click)="hideModal()"><img src="assets/images/x.svg"></span>
                    <h3 style="margin-bottom: 20px;">Enhance Video</h3>

                    <p>This video will be replaced with highest quality available, and its duration
                        expanded to one minute.</p>

                        <br>
                        <div class="action-block" style="margin-top: 20px;">
                            <button class="btn btn-primary pull-right" (click)="enhanceDVR(incidentDetails, getTripId, getDriverId)">Enhance</button>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>
