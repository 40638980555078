<app-card [showSortList]="true" [sortList]="sortList" (changeSortBy)="updateSort($event)" class="driver-distory-card">
  <div card-title>Driver History</div>
  <ng-container card-body>
    <app-table
      [displayedColumns]="displayedColumns"
      [notLoadingState$]="notLoadingState$"
      [loadingDummyData]="dummyLoadData"
      [dataSource$]="internalDriverHistoryData$"
      [colDescriptions]="colDescriptions"
      [customTemplates]="{ 'driver': driverLink }"
      (pageChange)="updatePage($event)"
      [getUniqueKey]="getUniqueKey"
      [sortOrderChange$]="notifySortChange$"
      [isDisabledClick]="false">
    </app-table>
  </ng-container>
</app-card>


<ng-template #driverLink let-asyncData let-element="element">
  <ng-container *ngIf="asyncData | async as data; else loading">
    <a [routerLink]="['/driver-detail']"
      [queryParams]="{driverId: element.driverId, driverName: element.driverName}"
      appTooltip toolTipClass="driver-history-tooltip" toolTipPosition="below">
        {{ data }}
    </a>
  </ng-container>
</ng-template>

<ng-template #loading>
  <div style="padding-right: 10px;">
    <app-custom-skeleton-loader></app-custom-skeleton-loader>
  </div>
</ng-template>
