import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class DateService {

  public customStartdate;
  public customEndDate;
  public exportCustomStartDate;
  public exportCustomEndDate;

  constructor() {}

  public toISOString(date: Date) {
    if (!date) {
      return;
    }
    return date.toISOString();
  }

  /**
   * @description: Format the date to midnight 12 am
   * @param date
   */
  public toDaysStart(date: Date) {
    if (!date) {
      return;
    }
    const apoche = date.setHours(0, 0, 0, 0);
    return new Date(apoche);
  }

  /**
   * @description: Format the date to midnight 11:59 pm
   * @param date
   */
  public toDaysEnd(date: Date) {
    if (!date) {
      return;
    }
    const apoche = date.setHours(23, 59, 59, 999);
    return new Date(apoche);
  }

  /**
   * @description: return ISO string format of start date
   * @param date
   */

  public toDaysStartISO(date: Date) {
    return moment(new Date(date)).format('YYYY-MM-DD') + 'T00:00:00.000Z';
  }
  // public toDaysStartISO1(date: Date) {
  //    // return this.toDaysStart(date).toISOString();
  //    return moment(new Date(date)).format('YYYY-MM-DD') + 'T00:00:00.000Z';
  // }

  /**
   * @description: return ISO string format of end date
   * @param date
   */

  public toDaysEndISO(date: Date) {
    return moment(new Date(date)).format('YYYY-MM-DD') + 'T23:59:59.999Z';
  }
  // public toDaysEndISO1(date: Date) {
  //    // return this.toDaysEnd(date).toISOString();
  //    return moment(new Date(date)).format('YYYY-MM-DD') + 'T23:59:59.999Z';
  // }

  public getDateRange(number: number, date?: Date) {
    let to = this.toDaysEnd(new Date(moment(date).toDate()));
    let from: Date;
    if (number === 0) {
      from = this.toDaysStart(new Date(moment(date).toDate()));
    } else if (number < 0) {
      number = -number;
      to = this.toDaysEnd(new Date(moment(date).subtract(number, 'd').toDate()));
      from = this.toDaysStart(
        new Date(moment(date).subtract(number, 'd').toDate())
      );
    } else if (number) {
      number = +number;
      from = this.toDaysStart(
        new Date(
          moment(date)
            .subtract(number - 1, 'd')
            .toDate()
        )
      );
    } else {
      from = this.toDaysStart(new Date(1000));
    }
    return {
      from,
      to,
    };
  }

  public getDateRangeInISO(number?: number, date?: Date) {
    let to = this.toDaysEndISO(new Date(moment(date).add(1, 'd').toDate()));
    let from = '';
    if (number === 0) {
      from = this.toDaysStartISO(new Date(moment(date).toDate()));
    } else if (number < 0) {
      to = this.toDaysEndISO(new Date(moment(date).toDate()));
      number = -number;
      from = this.toDaysStartISO(
        new Date(moment(date).subtract(number, 'd').toDate())
      );
    } else if (number) {
      number = +number;
      from = this.toDaysStartISO(
        new Date(
          moment(date)
            .subtract(number - 1, 'd')
            .toDate()
        )
      );
    } else {
      from = this.toDaysStartISO(new Date(1000));
    }

    return {
      from,
      to,
    };
  }

  public isDayLightSavingTime(date?: Date) {
    return moment(date).isDST();
  }
}
