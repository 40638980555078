import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';
import { SnackbarService } from '@app-core/services/snackbar.service';


@Injectable()
export class DashboardAccessGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _auth: ZcwAuthenticateService,
    private _snackbarService: SnackbarService
  ) { }

  /**
   * @description: using canactivate guard to check user acces to the dashboard panel
   * @param:
   */
  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Add a check to close current shown snackbar
    if (this._snackbarService.isSnackbarOpened) {
      this._snackbarService.closeCurrentSnackbar();
    }

    return this._auth.userProfile$.pipe(
      map(userProfile => {
        if (!userProfile.state) {
          this._router.navigate(['/dashboard']);
          return false;
        }
        return true;
      })
    );
  }
}
