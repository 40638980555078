<app-card class="incident-overtime-card">
  <div card-title>Incidents Over Time</div>
  <div card-body>
    <ng-container *ngIf="(notLoadingState$ | async); else graphLoading">
      <ng-container *ngIf="(internalDataSource$ | async) as chartOptions">
        <highcharts-chart
          [Highcharts]="highcharts"
          [options]="chartOptions"
          [(update)]="updateIncidentChart"
          [oneToOne]="true"
        >
        </highcharts-chart>
      </ng-container>
    </ng-container>
    
    <ng-template #graphLoading>
      <ng-container *ngIf="chartLoading">
        <highcharts-chart
        [Highcharts]="highcharts"
        [options]="chartLoading"
        [(update)]="updateIncidentChart"
        [oneToOne]="true">
        </highcharts-chart>
      </ng-container>
      <div class="skeleton-wrapper">
        <div class="row">
          <app-custom-skeleton-loader class="graph"
            [theme]="{
              'margin-left': '10px',
              'height': '370px'
            }"
          ></app-custom-skeleton-loader>
          <app-custom-skeleton-loader class="legend"
            [theme]="{
              'margin-left': '10px',
              'width': '20px',
              'height': '370px'
            }"
          ></app-custom-skeleton-loader>
          <app-custom-skeleton-loader class="title"
            [theme]="{
              'height': '152px',
              'width': '20px'
            }"
          ></app-custom-skeleton-loader>
        </div>
        <div class="row">
          <app-custom-skeleton-loader class="graph" [theme]="{
            'height': '20px',
            'margin-left': '10px'
          }"
          ></app-custom-skeleton-loader>
          <div class="bound-space"></div>
          <div class="bound-space"></div>
        </div>
      </div>
    </ng-template>
  </div>
</app-card>
