import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  OnChanges,
} from '@angular/core';

import { ModalDirective } from 'ngx-bootstrap/modal';
import * as moment from 'moment';

import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { MapService } from '@modules/dashboard3/services/map.service';
import { MapComponent } from '@modules/dashboard3/components/map/map.component';
import { TARGET_VIDEO_REQUEST_CONFIGURATION } from '@app-core/constants/constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SliderInfo, SliderPointValue } from '@modules/dashboard3/components/dvr-slider/dvr-slider.component';
import { createMomentObjectUtc, getTimezoneString } from '@modules/shared/utils';
import { EmitTimeValue, TripTimeInfo } from '@modules/dashboard3/components/time-input-field/time-input-field.component';

export const MAX_DVR_DURATION_TIME = 60000;
const MILLISECOND_PER_MINUTE = 60 * 1000;
const MILLISECOND_PER_DAY = 86400000;

@Component({
  selector: 'app-new-capture-dvr-modal',
  templateUrl: './new-capture-dvr-modal.component.html',
  styleUrls: ['./new-capture-dvr-modal.component.scss'],
})
export class NewCaptureDvrModalComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;

  @Input() public incidentList: any;
  @Input() public starttime = '';
  @Input() public endtime = '';

  @Input() public getLatLongList = [];
  //@Input() public tripDetail = null;
  @Input() public set tripDetail(value: any) {
    if (value) {
      this._tripDetail = value;
      // ongoing trip has no endtime or current trip details doesn't response with endtime
      if (value['ongoing'] || !(value['endTime'] || value['endTimeUTC'])) {
        this._generateEndTimeOngoingTrip(this.ongoingTripEndTimeOffset);
      }
      this.starttime = value.startTime;
      this.endtime = value.endTime;

      this.displayTimeZone = getTimezoneString(
        value.timezoneOffset,
        value.firstLocation.latitude,
        value.firstLocation.longitude
      );

      // Get then time
      this._startMoment = createMomentObjectUtc(value.startTimeUTC).tz(this.displayTimeZone);
      this._endMoment = createMomentObjectUtc(value.endTimeUTC).tz(this.displayTimeZone);
      this._startMoment.set('second', 0);
      this._endMoment.set('second', 0);
      this._startMoment.set('millisecond', 0);
      this._endMoment.set('millisecond', 0);
      this.tripDuration = Math.floor(value.duration / 60);

      // Generate input for the time input form control
      this.tripTimeInfo = {
        startUTC: value.startTimeUTC,
        endUTC: value.endTimeUTC,
        timezone: this.displayTimeZone,
      };

      // Generate input for the dvr slider
      this._generateSliderStartEndTime();

      // Set initial value for map and slider
      this.currentTimeInMs = Date.parse(this.sliderStartTime);
      this.currentTimeStampTz = new Date(Date.parse(this.startTimeTz)).toISOString();
      this._setMapDisplayLocation(this.currentTimeStampTz);
    }
  }
  public get tripDetail() {
    return this._tripDetail;
  }
  @Input() public getFullList = [];
  @Input() public latestCapturedPosition = null;
  @Input() public displayTimeZone = null;

  @ViewChild('mapComponent') public mapComponent: MapComponent;

  public captureDVRClipParams = null;
  public latLongList = [];
  public modalSubscription;
  public displayComponent;
  public incidents: any[] = [];
  public currentTime = null;
  public timeDiffInSeconds = null;
  public markerList = [];
  public currentTimeInUTC = null;
  public driverId = '';
  public tripId = '';
  public driverName = '';
  public highlightLatLong = [];
  public showMap = false;
  public iconOptions = {
    iconSize: [30, 30],
    iconAnchor: [15, 15],
  };
  public ongoingTripEndTimeOffset = 5;
  public sliderStartTime = null;
  public sliderEndTime = null;
  public tripStartLocationTimezoneOffset = null;

  public timeGroup: FormGroup;
  public startTimeTz: string = null;
  public endTimeTz: string = null;
  public currentTimeStampTz: string = null;
  public currentTimeInMs: number = null;
  public clipDuration = 1;
  public sliderRange: SliderPointValue;
  public disableCapture = true;
  public tripTimeInfo: TripTimeInfo = null;
  public tripDuration = 5;
  public disableSlider = true;

  // experiment zone
  private _tripDetail = null;
  private _startMoment: moment.Moment;
  private _endMoment: moment.Moment;

  constructor(
    private _zcfleet: ZCFleetService,
    private _mapService: MapService,
    private _loading: LoadingService,
    private _toaster: ToasterService,
    private _fb: FormBuilder
  ) {
    this.modalSubscription = this._zcfleet.captureIncidentModal.subscribe(
      (params) => {
        if (params.state && params.component === 'smartcapture') {
          this.displayComponent = params.component;
          this.modal.show();
          this.incidentList.forEach((data) => this.getTimeList(data));
          // Directly set the start time if the trip duration less than 1 minute
          if (this.tripDuration === 0) {
            const timeStr = new Date(this._tripDetail.startTimeUTC);
            timeStr.setSeconds(0);
            timeStr.setMilliseconds(0);
            this.timeInput.setValue(timeStr.toISOString());
            this.currentTimeInUTC = this._getCurrentTimeUTC(0);
          }
        }
      }
    );
    this.timeGroup = this._fb.group({
      timeInput: [null],
      durationInput: [null],
    });
  }

  public ngOnInit() {
    // Prevent click outside
    this.modal.onHidden.subscribe(_ => {
      this.hideModal();
    });
    // Watch form control change
    this.timeGroup.valueChanges.subscribe((data) => {
      if (data.timeInput && typeof data.timeInput !== 'string') {
        const timeInfo: EmitTimeValue = data.timeInput;
        if (timeInfo.timeStrTz === '' || timeInfo.deltaTime === 0) {
          const timeStr = new Date(this._tripDetail.startTimeUTC);
          timeStr.setSeconds(0);
          timeStr.setMilliseconds(0);
          this.timeInput.setValue(timeStr.toISOString(), { emitEvent: false });
        }
        // For the slider
        this.currentTimeInMs = Date.parse(this.sliderStartTime) + timeInfo.deltaTime;
        // For the map
        this.currentTimeStampTz = new Date(Date.parse(this.startTimeTz) + timeInfo.deltaTime).toISOString();
        this._setMapDisplayLocation(this.currentTimeStampTz);
        // For DVR capture button
        this.currentTimeInUTC = this._getCurrentTimeUTC(timeInfo.deltaTime);
      }
      if (data.durationInput) {
        // Initialize the value for the input in case it's empty
        if (!this._isTimeInputHasValue()) {
          const timeStr = new Date(this._tripDetail.startTimeUTC);
          timeStr.setSeconds(0);
          timeStr.setMilliseconds(0);
          this.timeInput.setValue(timeStr.toISOString(), { emitEvent: false });
          this.currentTimeInUTC = this._getCurrentTimeUTC(0);
        }

        this.clipDuration = data.durationInput;
      }
      this._updateModalActionStatus(this.clipDuration);
    });
  }

  public ngOnChanges() {
    this.latLongList = [...this.getLatLongList];
  }

  public ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  public get timeInput() {
    return this.timeGroup.get('timeInput');
  }

  public get durationInput() {
    return this.timeGroup.get('durationInput');
  }

  /**
   * @description: function to hide modal
   * @param:
   */
  public hideModal() {
    this.modal.hide();
    this.incidents = [];
    this.currentTimeStampTz = '';
    this.currentTimeInMs = Date.parse(this.sliderStartTime);
    this.clipDuration = 1;
    this.timeInput.setValue('', { emitEvent: false });
    this.durationInput.setValue(this.clipDuration, { emitEvent: false });
    this.disableCapture = true;
  }

  /**
   * @description: function to show modal
   * @param:
   */
  public show() {
    this._loading.show();
    setTimeout(() => {
      this.displayMap();
      this._loading.hide();
    }, 100);
  }

  public notifySliderchange(sliderData: SliderInfo) {
    if (sliderData) {
      const timeDiffInMs = sliderData.timeInMs - Date.parse(this.sliderStartTime);
      this.currentTimeInUTC = this._getCurrentTimeUTC(timeDiffInMs);
      this.currentTimeStampTz = new Date(
        Date.parse(this.startTimeTz) + timeDiffInMs
      ).toISOString();
      this.currentTimeInMs = Date.parse(this.sliderStartTime) + timeDiffInMs;
      this._setMapDisplayLocation(this.currentTimeStampTz);
      this.clipDuration = (sliderData.timeWithDurationMs - sliderData.timeInMs) / MILLISECOND_PER_MINUTE;
      // For the time input field
      const timeStr = new Date(this.tripDetail.startTimeUTC);
      timeStr.setSeconds(0);
      timeStr.setMilliseconds(0);
      this.timeInput
        .setValue(new Date(timeStr.getTime() + timeDiffInMs).toISOString(), { emitEvent: false });
      this.durationInput
        .setValue(this.clipDuration, { emitEvent: false });
      this._updateModalActionStatus(this.clipDuration);
    }
  }

  public getTimeList(data) {
    const tripStartTime = new Date(this.starttime).getTime();
    const tripEndTime = new Date(this.endtime).getTime();
    const duration = tripStartTime - tripEndTime;
    const eventTime = new Date(data.time).getTime();
    const differntTime = tripStartTime - eventTime;
    const eventDiffTime = (differntTime / duration) * 100;
    const timeList = { timetaken: eventDiffTime };
    const overall = { ...data, ...timeList };
    this.incidents.push(overall);
  }

  public displayMap() {
    this.highlightLatLong = [];
    this.showMap = true;
    const firstLatLng = this.latLongList[0];
    if (firstLatLng) {
      this.setCircleMarker(firstLatLng['lat'], firstLatLng['lng']);
    }
  }

  public setCircleMarker(lat, long) {
    const markerList = [];
    const circleIcon = this._mapService.getIcon(
      'circle-marker',
      this.iconOptions
    );
    const icon = this._mapService.getMarker(lat, long, circleIcon, false, true);
    markerList.push(icon);
    this.markerList = markerList.slice();
  }

  public showOneMinPathOnMap(timeDiffInSeconds) {
    if (this.getFullList && this.getFullList.length > 0) {
      const newTime = this.getFullList[0].timestampUTC + timeDiffInSeconds * 1000;
      let newMarkerPosition = null;
      for (let iterator = 0; iterator < this.getFullList.length; iterator++) {
        if (this.getFullList[iterator].timestampUTC > newTime) {
          newMarkerPosition = iterator;
          break;
        }
        newMarkerPosition = this.getFullList.length - 1;
      }
      const newlatlong = this.getFullList[newMarkerPosition];
      this.setCircleMarker(newlatlong.latitude, newlatlong.longitude);

      const getOneMinPath =
        this.getFullList[newMarkerPosition].timestampUTC +
        this.clipDuration * MILLISECOND_PER_MINUTE;
      let nextMarkerPosition = null;
      for (
        let iterator = newMarkerPosition;
        iterator < this.getFullList.length;
        iterator++
      ) {
        if (this.getFullList[iterator].timestampUTC > getOneMinPath) {
          nextMarkerPosition = iterator;
          break;
        }
      }

      const newHighlightArray = this.getFullList.slice(
        newMarkerPosition,
        nextMarkerPosition
      );
      const newArr = [];
      for (let iterator = 0; iterator < newHighlightArray.length; iterator++) {
        newArr[iterator] = {
          lat: newHighlightArray[iterator].latitude,
          lng: newHighlightArray[iterator].longitude,
        };
      }
      this.highlightLatLong = newArr;
    }
  }

  public calculateCaptureDVRCurrentTimeInUTC(
    currentTimeInUTC: number,
    expectedDuration: number
  ): [string, string] {
    const dvrStartTime = new Date(
      new Date(currentTimeInUTC).toUTCString()
    ).toISOString();
    const dvrEndTime = new Date(
      new Date(currentTimeInUTC + expectedDuration).toUTCString()
    ).toISOString();
    return [dvrStartTime, dvrEndTime];
  }

  public correctDVRDuration(
    dvrDuration: [number, number],
    tripDuration: [number, number]
  ): [number, number] {
    const START_INDEX = 0;
    const END_INDEX = 1;
    const clipLength = this.clipDuration * MILLISECOND_PER_MINUTE;
    const result: [number, number] = [dvrDuration[START_INDEX], dvrDuration[END_INDEX]];

    //Check start/end of trip with start/end of dvr clip
    if (dvrDuration[START_INDEX] < tripDuration[START_INDEX]) {
      result[START_INDEX] = tripDuration[START_INDEX];
      result[END_INDEX] = result[START_INDEX] + clipLength;
    }

    if (dvrDuration[END_INDEX] > tripDuration[END_INDEX]) {
      result[END_INDEX] = tripDuration[END_INDEX];
      result[START_INDEX] = result[END_INDEX] - clipLength;
    }

    return result;
  }

  public calculateDVRDuration(
    currentTimeInUTC: number,
    tripDuration: [string, string]
  ): [number, number] {
    // In case of durationis not updated to the latest
    if (this.tripDetail.ongoing || this.tripDuration === 0) {
      // Special case where duration last update = 0, make a buffer of 1 second to the DVR request
      const startTime = Date.parse(tripDuration[0]);
      const endTime = Date.parse(tripDuration[1]);
      return [
        startTime,
        (this.tripDetail.duration === 0) ? startTime + 1000 : endTime,
      ];
    }
    const dvrDuration = this.calculateCaptureDVRCurrentTimeInUTC(
      currentTimeInUTC,
      this.clipDuration * 60000
    );

    const convertToMillisecond = (duration: [string, string]) =>
      duration.map((time) => Date.parse(time));

    const dvrDurationMillisecond = convertToMillisecond([
      dvrDuration[0],
      dvrDuration[1],
    ]);
    const tripDurationMillisecond = convertToMillisecond([
      tripDuration[0],
      tripDuration[1],
    ]);

    return this.correctDVRDuration(
      [dvrDurationMillisecond[0], dvrDurationMillisecond[1]],
      [tripDurationMillisecond[0], tripDurationMillisecond[1]]
    );
  }

  public captureDVRClipRequest() {
    const arrayCaptureDVRDuration = this.calculateDVRDuration(
      this.currentTimeInUTC.getTime(),
      [this.tripTimeInfo.startUTC, this.tripTimeInfo.endUTC]
    );
    const captureDVRStartTime = new Date(
      arrayCaptureDVRDuration[0]
    ).toISOString();
    const captureDVREndTime = new Date(
      arrayCaptureDVRDuration[1]
    ).toISOString();
    this.captureDVRClipParams = {
      startTimeUTC: captureDVRStartTime,
      endTimeUTC: captureDVREndTime,
      driverId: this._tripDetail.driverId,
      tripId: this._tripDetail.tripId,
      ...TARGET_VIDEO_REQUEST_CONFIGURATION,
    };
    this.hideModal();
    this._zcfleet.captureDVRClip(this.captureDVRClipParams).subscribe(
      (res) => {
        console.log(res);
        this._toaster.success('DVR Request Successful.');
      },
      (err) => {
        console.log(err);
        this._toaster.error('DVR Request Failed');
      }
    );
  }

  private _generateEndTimeOngoingTrip(endTimeOffset: number = 0): void {
    if (this.latestCapturedPosition) {
      const latestCapturedTimeStampUTC =
        this.latestCapturedPosition.timestampUTC;
      const offsetInMsec =
        (this._tripDetail.timezoneOffset + endTimeOffset) *
        MILLISECOND_PER_MINUTE;
      const offsetUTCInMsec = endTimeOffset * MILLISECOND_PER_MINUTE;
      this.endtime = new Date(
        latestCapturedTimeStampUTC - offsetInMsec
      ).toISOString();
      this._tripDetail.endTime = new Date(
        latestCapturedTimeStampUTC - offsetInMsec
      ).toISOString();
      this._tripDetail.endTimeUTC = new Date(
        latestCapturedTimeStampUTC - offsetUTCInMsec
      ).toISOString();
    } else {
      // Add temporary value of end time base on the duration value
      // const offsetInMsec =
      //   (this._tripDetail.timezoneOffset + this._tripDetail.ongoing ? endTimeOffset : 0) *
      //   MILLISECOND_PER_MINUTE;
      // const offsetUTCInMsec = (this._tripDetail.ongoing ? endTimeOffset : 0) * MILLISECOND_PER_MINUTE;
      this._tripDetail.endTime = new Date(
        Date.parse(this.tripDetail.startTime) + (this._tripDetail.duration * 1000)
      ).toISOString();
      this._tripDetail.endTimeUTC = new Date(
        Date.parse(this.tripDetail.startTimeUTC) + (this._tripDetail.duration * 1000)
      ).toISOString();
    }
    this._tripDetail.durationLatestPosition =
      (Date.parse(this._tripDetail.endTimeUTC) -
        Date.parse(this._tripDetail.startTimeUTC)) /
      1000;
  }

  private _generateSliderStartEndTime(): void {
    // get start location timezone offset in minutes
    this.tripStartLocationTimezoneOffset = moment
      .tz(this.displayTimeZone)
      .utcOffset();
    this.startTimeTz = this._stripSecPart(this.starttime);
    this.endTimeTz = this._stripSecPart(this.endtime);
    this.sliderStartTime = this._startMoment.toISOString();
    this.sliderEndTime = new Date(this._startMoment.valueOf() + (this.tripDuration * 60000)).toISOString();
    const isSameDate = this._startMoment.isSame(this._endMoment, 'day');
    const tempStart = createMomentObjectUtc(this.tripDetail.startTimeUTC).tz(this.displayTimeZone);
    tempStart.set('hour', 0);
    tempStart.set('minute', 0);
    tempStart.set('second', 0);
    tempStart.set('millisecond', 0);
    const halfDayMs = isSameDate ? tempStart.valueOf() + (MILLISECOND_PER_DAY / 2) : tempStart.valueOf() + MILLISECOND_PER_DAY;
    const differentTime = halfDayMs - this._startMoment.valueOf();
    this.sliderRange = {
      min: this.sliderStartTime,
      max: this.sliderEndTime,
      isSameDate,
      midPoint: Math.abs(((differentTime / ((this.tripDuration === 0 ? 1 : this.tripDuration) * 60000)) * 100)),
    };
  }

  private _stripSecPart(value: string): string {
    // calculate timezone offset
    const offsetInMiliseconds =
      this.tripStartLocationTimezoneOffset * MILLISECOND_PER_MINUTE;

    const date = new Date(Date.parse(value) + offsetInMiliseconds);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date.toISOString();
  }

  private _setMapDisplayLocation(currentTimeStamp: string) {
    const startTimeTz = new Date(
      Date.parse(this.starttime) +
        this.tripStartLocationTimezoneOffset * MILLISECOND_PER_MINUTE
    );
    startTimeTz.setSeconds(0);
    startTimeTz.setMilliseconds(0);
    const timeDiffinSeconds =
      (new Date(currentTimeStamp).getTime() - new Date(startTimeTz).getTime()) /
      1000;
    this.showOneMinPathOnMap(timeDiffinSeconds);
  }

  private _getCurrentTimeUTC(timeDiffInMs: number) {
    return new Date(Date.parse(this._tripDetail.startTimeUTC) + timeDiffInMs);
  }

  private _updateModalActionStatus(clipMinutes: number) {
    const isClipDurationValid = (this.tripDetail.ongoing || this.tripDuration === 0) ? true : clipMinutes <= this.tripDuration;
    const isTimeInputHasValue = this._isTimeInputHasValue();
    this.disableCapture = !(isTimeInputHasValue && isClipDurationValid);
    this.disableSlider = this.tripDuration < 1 || this.disableCapture;
  }

  private _isTimeInputHasValue(): boolean {
    if (this.timeInput.value) {
      if (typeof this.timeInput.value === 'string') {
        return this.timeInput.value.length > 0;
      } else {
        return this.timeInput.value.timeStrTz.length > 0;
      }
    }
    return false;
  }
}
