<div class="row tripboard-heading">
    <div *ngIf="!driverName" class="col-md-12 p-0">
        <span class="heading-left">Trips Driven By Fleet  </span>
    </div>
    <div *ngIf="driverName" class="col-md-12 p-0">
            <span class="heading-left">Trips Driven By {{ driverName }}  </span>
    </div>
</div>

<div [ngSwitch]="show" class="all-trip-table">
    <div class="tripeboard-content">
        <div *ngSwitchCase="'loading'">
            <app-spinner></app-spinner>
        </div>

        <div *ngSwitchCase="'data'">
            <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
            <div class="row no-gutters zc-table-wrap">
                <table class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm">
                    <thead>
                        <tr>
                            <th scope="col">
                                        <app-table-sort-header sortOn="startTime" [(sortBy)]="sortBy" [(sort)]="sort"
                                            (sortClicked)="tabletRefreshList()">Date/Time
                                        </app-table-sort-header>
                            </th>
                            <th scope="col">
                                    <!-- TODO: Uncomment below line once location sort is supported -->
                                    <!-- <app-table-sort-header sortOn="location" [(sortBy)]="sortBy" [(sort)]="sort"
                                        (sortClicked)="refreshList()">Start Location
                                    </app-table-sort-header> -->
                                    Start Location
                                </th>
                            <th scope="col">
                                <app-table-sort-header sortOn="driverName" [(sortBy)]="sortBy" [(sort)]="sort"
                                    [checkForFleetPermission]="true"
                                    (sortClicked)="tabletRefreshList()">Driver
                                </app-table-sort-header>
                            </th>
                            <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="total" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="tabletRefreshList()">Total Incidents
                                </app-table-sort-header>
                            </th>
                            <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="tripDistance" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="tabletRefreshList()">Distance (MI)
                                </app-table-sort-header>
                            </th>
                            <th class="w-10" scope="col">
                                <app-table-sort-header sortOn="duration" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="tabletRefreshList()">Duration (HH:MM)
                                </app-table-sort-header>
                            </th>
                            <th scope="col">
                                <!-- ZCW-515: Disable sort by asset in trip table. -->
                                <!-- <app-table-sort-header sortOn="assetNumber" [(sortBy)]="sortBy" [(sort)]="sort"
                                    (sortClicked)="tabletRefreshList()">Asset
                                </app-table-sort-header> -->
                                Asset
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let trip of tripListPage;let i = index" (click)="gotoTripDetail(trip)">
                            <td>{{trip?.startTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z' }}</td>
                            <td>{{trip?.location}}</td>
                            <td>{{trip?.driverName || trip?.driverId}}</td>
                            <td *ngIf="trip.cameraConnected && !trip.tampered; else tamperedBlockRow">{{trip?.totalIncidentsEventType}}</td>
                            <ng-template #tamperedBlockRow>
                                <td>
                                    <img class="dangerSign" src="../../../../../../../assets/images/exclamation-red-circle.svg">
                                </td>
                            </ng-template>
                            <td>{{trip?.tripDistance | distance: 'miles' |
                                    number: '1.0-0'}}</td>
                            <td>{{trip?.duration | duration2: 'seconds':
                                'HH:MM' }}</td>
                            <td>{{trip?.assetNumber || 'N/A'}}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="paginator-wrap">
                    <app-paginator3
                        [(reset)]="reset"
                        [pageLimit]="20"
                        [length]="totalTrips"
                        [data]="listData"
                        (pageChangeData)="tripListPageChange($event)"
                        (pageChange)="getMoreData($event)">
                    </app-paginator3>
                </div>
            </div>
        </div>

        <div *ngSwitchCase="'empty'">
            <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
            <div class="no-records-card">
                <div class="no-records no-records-sm">
                    <img src="assets/images/exclamation-circle.svg">
                    <div class="note">
                        <span>
                            No trips found
                        </span>
                    </div>
                    <div class="note sub">
                        <span>Try different filters to view trips.</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngSwitchCase="'error'">
            <app-spinner *ngIf="isShowNextPageSpinner"></app-spinner>
            <div class="no-records-card">
                <div class="no-records no-records-sm">
                    <img src="assets/images/exclamation-circle.svg">
                    <div class="note">
                        <span>{{ errorMessage }}</span>
                    </div>
                    <div class="retry">
                        <button class="btn btn-retry" (click)="tabletRefreshList()">Retry</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
