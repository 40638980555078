<div
  class="custom-select-dropdown"
  (click)="toggleDropdown()"
  appDocumentClick
  (clickedInside)="clickedInside($event)"
>
  <div
    *ngIf="!isShowAction; else actionTemplate"
    class="custom-select-dropdown-text"
  >
    <span>{{ value }}</span>
    <span class="arrow">
      <img src="assets/images/arrow_down.png" />
    </span>
    <div class="dropdown">
      <ul *ngIf="showList">
        <li class="item" *ngFor="let item of itemList" (click)="onSelect(item)">
          {{ displayText(item) }}
        </li>
      </ul>
    </div>
  </div>
  <ng-template #actionTemplate>
    <span class="three-dot-menu">
      <img src="/assets/images/more_horiz.svg" />
    </span>
    <div class="more-detail-dropdown">
      <ul *ngIf="showList">
        <li class="item" *ngFor="let item of itemList" (click)="onSelect(item)">
          {{ displayText(item) }}
        </li>
      </ul>
    </div>
  </ng-template>
</div>
