<div class="incident-summary-wrapper">

  <div class="graph-data-wrapper">
    <app-card [elementClasses]="'total-incident-wrapper'" class="total-incident-card" [ngClass]="{
      'no-incident-background': ((internalIncidentStat$ | async) <= 0)
        && (notLoadingState.incidentStat$ | async)
    }">
      <ng-container card-body *ngIf="(notLoadingState.tripDetail$ | async); else pieLoading">
        <ng-container *ngIf="(internalTripDetails$ | async) as tripDetails">
          <ng-container *ngIf="tripDetails.eventCount && tripDetails.eventCount.total !== 0, else incidentNoInformation">
            <div *appTransformIncidentStat="
            let incidentStatList;
            from: tripDetails;
            let pieChartOptions = pieChartOptions;
            options: {
              ignoreZero: true,
              sliceIndex: 4
            };
            customs: true;
          " card-body class="pie-chart-driver-custom">
              <highcharts-chart
                [Highcharts]="highcharts"
                [options]="buildPieChartInnerData(pieChartOptions)"
                [(update)]="shouldUpdatePieChart"
                [oneToOne]="true"
              ></highcharts-chart>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-card>

    <!-- Top incident chart -->
    <app-card [elementClasses]="'graph-incident-wrapper'" class="top-incident-card">
      <div card-title class="title">Top Incident Count</div>

      <ng-container *ngIf="(notLoadingState.incidentStat$ | async); else graphLoading" card-body class="class-graph-display">
        <ng-container *ngIf="(internalIncidentStat$ | async) as topIncidentData">
          <ng-container *ngIf="topIncidentData.length > 0; else congratulationNoIncident">
            <div class="graph-row" *ngFor="let incident of topIncidentData">
              <ng-container *ngTemplateOutlet="chartBarTemplate; context: { $implicit: incident, assetId: assetId, maxValue: getMaxValue(topIncidentData)}"></ng-container>
            </div>
            <div class="gradient-graph-color"></div>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-card>

  </div>
</div>

<ng-template #congratulationNoIncident>
  <div class="col-12 no-incident-message">
    <div style="width:100%" class="incident_table_zero">
        <p>Congratulations! <br> There are no incidents in this time period.</p>
    </div>
  </div>
</ng-template>

<!-- Tempalte for chart bar -->
<ng-template #chartBarTemplate let-incident let-assetId="assetId" let-maxValue="maxValue">
  <div class="graph-legend">
    <div class="incident-item" [ngClass]="incident?.incidentClass">
      <a [routerLink]="['/asset-view']" [queryParams]="{assetId: assetId, category: incident.name}">{{ incident?.name }}</a>
    </div>
  </div>
  <div class="graph-data">
    <div class="graph-value">{{incident?.value}}</div>
    <div class="graph-bar">
      <div [ngStyle]="{ 'width': (incident?.value / maxValue * 100).toString() + '%' }"></div>
    </div>
  </div>
</ng-template>

<!-- Skeleton loading for top incident chart -->
<ng-template #graphLoading>
  <div class="graph-row" *ngFor="let row of [0,1,2,3,4]">
    <div class="graph-legend">
      <app-custom-skeleton-loader
        [theme]="{
          'margin-bottom': '10px',
          'width': '100%',
          'height': '20px'
        }">
      </app-custom-skeleton-loader>
    </div>
    <div class="graph-data">
      <div class="skeleton-graph-display">
        <app-custom-skeleton-loader
          [theme]="{
            'margin-bottom': '10px',
            'width': '100%',
            'height': '20px'
          }">
        </app-custom-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>

<!-- Skeleton loading for donut chart -->
<ng-template #pieLoading>
  <div class="pie-loading">
    <span class="pie-title">Total<br>Incidents</span>
    <app-custom-skeleton-loader
      [theme]="{
        'width': '164px',
        'height': '66px'
      }">
    </app-custom-skeleton-loader>
    <span class="pie-subtitle">since MM/DD</span>
  </div>
</ng-template>

<!-- Template for display when no incident-->
<ng-template #incidentNoInformation>
  <p class="info-title">Total Incidents</p>
  <p class="info-number">0</p>
  <p class="info-date">since {{ranges?.from | date: 'MM/dd'}}</p>
</ng-template>
