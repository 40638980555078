import { Observable } from 'rxjs';

export interface UserProfile {
  email: string;
  username?: string;
  state?: string;
}

export interface AuthenticateInterface {
  tokenReceived$: Observable<boolean>;
  userProfile$: Observable<UserProfile>;

  initialize(): Promise<boolean>;
  logout(): void;
}
