import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { DISTRACTION_DISCLAMER } from '@modules/dashboard3/dashboard3.constants';

@Component({
  selector: 'app-incident-stat',
  templateUrl: './incident-stat.component.html',
  styleUrls: ['./incident-stat.component.scss'],
})
export class IncidentStatComponent implements OnInit, OnChanges {
  @Input()
  public incidentStatList: IncidentStat[];

  @Input()
  public showOthers: boolean;

  @Input()
  public showTotalIncidents: boolean;

  @Input()
  public showDisclaimer = false;

  public filteredStatList: IncidentStat[];
  public isDistractedDisclaimerFlag = false;
  public distractionDisclaimer = DISTRACTION_DISCLAMER;

  public ngOnInit(): void {}

  public ngOnChanges(_: SimpleChanges): void {
    this.filteredStatList = this._filterIncidentStatList(this.incidentStatList);
    if (
      this.showDisclaimer &&
      this.incidentStatList.filter(
        (item) => item.name === 'Distraction' && item.value
      ).length
    ) {
      this.isDistractedDisclaimerFlag = true;
    }
  }

  public get isShowOthers() {
    return this.showOthers !== undefined ? this.showOthers : false;
  }

  public get isShowTotal() {
    return this.showTotalIncidents !== undefined
      ? this.showTotalIncidents
      : true;
  }

  private _filterIncidentStatList(inputList: IncidentStat[]): IncidentStat[] {
    return inputList.filter((item) => {
      if (!this.isShowOthers && item.name === 'Others') {
        return false;
      }
      if (!this.isShowTotal && item.name === 'Total Incidents') {
        return false;
      }
      return true;
    });
  }
}
