import { Component, OnInit, Input } from '@angular/core';
import { NgxSkeletonLoaderConfig } from 'ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types';

@Component({
  selector: 'app-custom-skeleton-loader',
  templateUrl: './custom-skeleton-loader.component.html',
  styleUrls: ['./custom-skeleton-loader.component.scss'],
})
export class CustomSkeletonLoaderComponent implements OnInit {
  @Input() public theme: NgxSkeletonLoaderConfig['theme'];
  @Input() public appearance: NgxSkeletonLoaderConfig['appearance'];
  @Input() public count: NgxSkeletonLoaderConfig['count'];
  @Input() public loadingText: NgxSkeletonLoaderConfig['loadingText'];
  @Input() public arialLabel: NgxSkeletonLoaderConfig['ariaLabel'];

  public applyTheme = {
    'border-radius': '8px',
    'margin-bottom': '0px',
    background: '#e3e3e3 no-repeat',
  };

  constructor() { }

  public ngOnInit(): void {
    if (this.theme) {
      this.applyTheme = {...this.applyTheme, ...this.theme};
    }
  }

}
