<div *ngIf="!isExportSet" class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)">
  <div>
    <div>
      <a class="dropdown-head" [class.active]="isOpen" (click)="toggleFilter()">
        <ng-container *ngIf="checkDriverCount(driverCountValue); else loadingDriverFilter">
          <span class="label">{{ rangeText }} </span>
          <span class="label" *ngIf="rangeValue === 4">
            with score {{ minScore }} - {{ maxScore }}
          </span>
          <span class="range">({{ driverCountValue }})</span>
        </ng-container>
        <span class="arrow-down" *ngIf="!isOpen" (click)="toggleFilter($event)"></span>
        <span class="arrow-up" *ngIf="isOpen" (click)="toggleFilter($event)"></span>
      </a>
    </div>
  </div>

  <div class="drop-down score-dropdown" [class.show]="isOpen">
    <div data-test="driver-filter-content" class="drop-down-content">
      <span class="heading">Drivers</span>
      <!-- <app-histogram-slider [(minValue)]="minValue" [(maxValue)]="maxValue"></app-histogram-slider> -->
      <div class="position-heading">Driver Score</div>
      <div class="button-group">
        <div class="button-position">
          <button class="radio-button" [class.active]="rangeValueTemp === 1 || rangeValueTemp === 4"
            (click)="allDrivers()"></button>
          <span class="button-name">All Drivers</span>
        </div>

        <div class="button-position">
          <button class="radio-button" [class.active]="rangeValueTemp === 2" (click)="topDrivers()"></button><span
            class="button-name">Top 20% Drivers</span>
        </div>
        <div class="button-position">
          <button class="radio-button" [class.active]="rangeValueTemp === 3" (click)="bottomDrivers()"></button>
          <span class="button-name">Bottom 20% Drivers</span>
        </div>
      </div>
      <div>
        <div class="histogram-wrap">
          <div class="histogram">
            <div class="histogram-bar" *ngFor="let data of histogramList" [style.height.px]="data"></div>
          </div>
          <ng5-slider [value]="minValue" (valueChange)="minValueChange($event)" [highValue]="maxValue"
            (highValueChange)="maxValueChange($event)" [options]="options">
          </ng5-slider>
        </div>
        <div class="histo-inputs">
          <input type="number" class="form-control" placeholder="Min" [ngModel]="minValue"
            (ngModelChange)="minValueChange($event)" />
          <span class="bar"></span>
          <input type="number" class="form-control" placeholder="Max" [ngModel]="maxValue"
            (ngModelChange)="maxValueChange($event)" />
        </div>
      </div>

      <hr>

      <div class="driver-action">
        <button type="submit" class="btn btn-driver" (click)="applyFilter()">Apply</button>
      </div>

    </div>
  </div>
</div>



<!-- Export modal filter code -->
<div *ngIf="isExportSet" class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)">
  <div>
    <div class="filter-choice" *ngIf="isExportSet">
      <div class="filter-element" [class.active]="isOpen" (click)="toggleFilter()">
        <div class="filter-heading">Drivers</div>

        <div class="content-row">
          <span class="filter-content">{{ rangeText }} </span>
          <span class="label" *ngIf="rangeValue === 4">
            with score {{ minScore }} - {{ maxScore }}
          </span>
          <span *ngIf="isOpen" class="arrow-up" (click)="toggleFilter($event)"></span>
          <span *ngIf="!isOpen" class="arrow-down" (click)="toggleFilter($event)"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="drop-down score-dropdown" [class.show]="isOpen">
    <div class="drop-down-content">
      <span class="heading">Driver Scores</span>
      <div class="options-group">
        <button class="btn btn-option" [class.active]="rangeValueTemp === 1 || rangeValueTemp === 4"
          (click)="allDrivers()">
          All Drivers
        </button>
        <button class="btn btn-option" [class.active]="rangeValueTemp === 2" (click)="topDrivers()">
          Top 20% Drivers
        </button>
        <button class="btn btn-option" [class.active]="rangeValueTemp === 3" (click)="bottomDrivers()">
          Bottom 20% Drivers
        </button>
      </div>
      <!-- <app-histogram-slider [(minValue)]="minValue" [(maxValue)]="maxValue"></app-histogram-slider> -->
      <div>
        <div class="histogram-wrap">
          <div class="histogram">
            <div class="histogram-bar" *ngFor="let data of histogramList" [style.height.px]="data"></div>
          </div>
          <ng5-slider [value]="minValue" (valueChange)="minValueChange($event)" [highValue]="maxValue"
            (highValueChange)="maxValueChange($event)" [options]="options">
          </ng5-slider>
        </div>
        <div class="histo-inputs">
          <input type="number" class="form-control" placeholder="Min" [ngModel]="minValue"
            (ngModelChange)="minValueChange($event)" />
          <span class="bar"></span>
          <input type="number" class="form-control" placeholder="Max" [ngModel]="maxValue"
            (ngModelChange)="maxValueChange($event)" />
        </div>
      </div>

      <hr>

      <div class="driver-action">
        <button type="submit" class="btn btn-driver" (click)="applyFilter()">Apply</button>
      </div>

    </div>
  </div>
</div>

<!-- loading skeleton for info name and division -->
<ng-template #loadingDriverFilter>
    <app-custom-skeleton-loader
    [theme]="{
      width: '130px',
      height: '10px'
    }">
  </app-custom-skeleton-loader>
</ng-template>
