import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { convertAssetTripListForTripRecap } from '@modules/dashboard3/minimap_utils';
import { MapService } from '@modules/dashboard3/services/map.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { DataSource } from '@modules/shared/components/async-carousel/async-carousel.component';
import { SortEvent, SortInfo } from '@modules/shared/components/card/card.component';
import { DateFormatPipe } from '@modules/shared/pipes/date-format.pipe';
import {
  calcSumTotalIncidents,
} from '@modules/shared/utils';

const lessThanMaxLengthOrEmpty = (maxLength: number) => (input: string) =>
  input.length > maxLength ? input : '';

@Component({
  selector: 'app-asset-trip-recap-component',
  templateUrl: './asset-trip-recap-component.component.html',
  styleUrls: ['./asset-trip-recap-component.component.scss'],
})
export class AssetTripRecapComponent implements OnInit {
  @Input() public set reloadObs$(value: Observable<any>) {
    this._reloadObs$ = value;
    this._source$ = this.generateNewSlides(this.pageSize, 0, true);
  }

  @Input() public getTripList: (
    data: any,
    _: number,
    __: number
  ) => Observable<any>;

  @Input() public set name(name: Observable<string>) {
    if (!name) {
      return;
    }

    this.displayName$ = name;
    this.nameTooltip$ = combineLatest([
      name,
      this._maxNameLength$,
    ]).pipe(
      map(([value, maxLength]) => lessThanMaxLengthOrEmpty(maxLength)(value))
    );
  }

  @Input() public set maxNameLength(length: number) {
    this._maxNameLength$.next(length);
  }

  @Input() public sortList: SortInfo[] = [];
  @Output() public changeSortBy: EventEmitter<SortEvent> = new EventEmitter();

  public generateNewSlidesBinded = this.generateNewSlides.bind(this);

  public get source$(): Observable<DataSource> {
    return this._source$;
  }

  public pageSize = 4;
  public displayName$ = of('');
  public nameTooltip$ = of('');

  public isLoading = false;
  public hasError = false;
  public longestFromToText = '';

  private _reloadObs$: Observable<any>;
  private _source$: Observable<DataSource> = of(null);
  private _maxNameLength$ = new BehaviorSubject(15);

  constructor(
    private _zcFleet: ZCFleetService,
    private _mapService: MapService,
    private _customDateFormatPipe: DateFormatPipe
  ) {}

  public ngOnInit(): void {}

  public getFromToText({ startPoint = '', endPoint = '' }): string {
    return `${startPoint} to ${endPoint}`;
  }

  public getGreetingText(date): string {
    if (date) {
      let currentTime = date;
      currentTime = this._customDateFormatPipe.transform(currentTime);
      const time = new Date(currentTime).getHours();
      const data = [
        // [start, end, greeting]
        [6, 11, 'Morning'], // 6AM to 11AM Morning
        [12, 17, 'Afternoon'], // 12AM to 5PM Afternoon
        [18, 22, 'Evening'], // 6PM to 22PM Evening
      ];
      for (const item of data) {
        if (time >= item[0] && time <= item[1]) {
          return item[2] as string;
        }
      }
      // from 23PM to 5AM Night
      return 'Night';
    }
  }

  public totalIncidentsEventType(eventCount: Record<string, string>): number {
    return calcSumTotalIncidents(eventCount);
  }

  public trackBy(trip: any): string {
    return trip.tripId ? trip.tripId : trip;
  }

  public notifySortChange(event: SortEvent) {
    this.changeSortBy.emit(event);
  }

  public generateNewSlides(
    limit: number,
    skip: number,
    fullReload = false
  ): Observable<DataSource> {
    return this._reloadObs$.pipe(
      fullReload ? tap((_) => {
        this.hasError = false;
        this.isLoading = true;
      }) : tap(_ => {}),
      fullReload ? tap(_ => {}) : take(1),
      switchMap((value) => this._getTripList(value, limit, skip).pipe(
        catchError<unknown, Observable<any[]>>((_) => {
          console.log('trip catch error');
          this.hasError = true;
          return of([]);
        })
      )),
      map(this._convertToTripRecapData.bind(this)),
      fullReload ? tap((_) => {
        this.isLoading = !!this.hasError;
      }) : tap(_ => {}),
      tap((tripList: DataSource) => {
        this.longestFromToText = tripList.list.reduce((longest, curr) => {
          const currentFromTo = this.getFromToText(curr);
          if (currentFromTo.length > longest.length) {
            return currentFromTo;
          }
          return longest;
        }, this.longestFromToText);
      })
    );
  }

  private _convertToTripRecapData(list: any): DataSource {
    if (!list) {
      list = [];
    }
    const assetTripDetails = list.map(
      trip => convertAssetTripListForTripRecap(trip, this._mapService, this._zcFleet)
    );
    return {
      list: assetTripDetails,
      totalSize: 100,
    };
  }

  private _getTripList(value, limit: number, skip: number) {
    return this.getTripList(value, limit, skip).pipe(
      switchMap((res) => {
        const driverTripList = (res.rows || []).map((result) => ({
          driverId: result.driverId,
          tripId: result.tripId,
        }));

        if (driverTripList.length <= 0) {
          return of([]);
        }

        return this._getTripDetails(driverTripList, (partialList) =>
          this._zcFleet.getTripDetails(partialList.driverId, partialList.tripId)
        ).pipe(map((tripDetail) => this._combineAssetTrip(res, tripDetail)));
      })
    );
  }

  private _getTripDetails(list, requestAPI) {
    const obs$: Observable<any>[] = [];
    for (const item of list) {
      obs$.push(requestAPI(item));
    }
    return forkJoin(obs$);
  }

  private _combineAssetTrip(firstData, lastData): any[] {
    const assetTripDetails = firstData.rows.map((result) => {
      const data = lastData.filter(
        (item) =>
          item.driverId === result.driverId && item.tripId === result.tripId
      )[0];
      return {
        ...result,
        driverName: data.driverName,
        pathInfo: data.pathInfo,
        violations: data.violations,
      };
    });
    return assetTripDetails || [];
  }
}
