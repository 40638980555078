import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight',
})
export class HighlightSearchPipe implements PipeTransform {
  constructor() { }
  public transform(value: string, args: string): any {
    if (args && value) {
      value = String(value);
      if (args.length > 0) {
        const re = new RegExp(args, 'gi');
        value = value.replace(re, (match) => '<b>' + match + '<b>');

        const parts = value.split('<b>');
        return parts.map(part => re.test(part) ? part : `<b>${part}</b>`).join('');
      }
    }
    return value;
  }

}
