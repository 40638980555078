import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

export interface SortInfo {
  displayName: string;
  sortOrder: 'asc' | 'desc';
  sortColumn: string;
}

export type SortEvent = [string, 'asc' | 'desc'];

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public elementClasses = '';
  @Input() public isSank = false;
  @Input() public showDetailButton = false;
  @Input() public showFooter = false;
  @Input() public showSortList = false;
  @Input() public updateSortList = {};
  @Input() public sortList: SortInfo[] = [];
  @Input() public menuOptionTemplate: TemplateRef<any>;

  @Output() public changeSortBy: EventEmitter<SortEvent> = new EventEmitter();

  public showList = false;

  public activeSortList: [SortInfo, boolean][];

  public sortName: string;

  constructor() {}

  public ngOnInit(): void {
    this.sortName = this.sortList[0] && this.sortList[0].displayName;
    this.activeSortList = this.sortList.map((item) => [item, false]);
    // default sort list order
    if (this.activeSortList.length) {
      // set active-list
      this.activeSortList[0][1] = true;
    }
  }

  public ngAfterViewInit() {
    if (this.activeSortList.length) {
      // emit default sort option
      this._emitSortEvent(this.activeSortList[0][0]);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const sortChangeValue =
      (changes.updateSortList && changes.updateSortList.currentValue) || {};
    if (Object.keys(sortChangeValue).length) {
      const sortUpdate = changes.updateSortList.currentValue;
      this.sortName = sortUpdate.displayName;
      if (this.activeSortList) {
        this.activeSortList.forEach(item => {
          item[1] = item[0].displayName === this.sortName ? true : false;
        });
      }
    }
  }

  public onChangeSortBy(event: SortInfo) {
    this.sortName = event.displayName;
    this.activeSortList = this.activeSortList.map(
      ([info, _]) => info && [info, info.displayName === event.displayName]
    );
    this._emitSortEvent(event);
    this.showList = false;
  }

  public closeDropdown() {
    this.showList = false;
  }

  private _emitSortEvent(item: SortInfo) {
    this.changeSortBy.emit([item.sortColumn, item.sortOrder]);
  }
}
