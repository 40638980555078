import { Component, ViewChild, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription, Subject } from 'rxjs';
import { ExportService } from './../../services/export.service';
import { DateService } from '@app-core/services/date.service';
import { LoadingService } from '@app-core/services/loading.service';
import { ToasterService } from '@app-core/services/toaster.service';
import { Data } from './../../services/data.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import {
  StatsModel,
  DefaultInputModelV2,
  FleetAllTripsModel,
} from './../../dashboard3.models';
import { DistancePipe } from '@modules/shared/pipes/distance.pipe';
import { ExcelService } from '@app-core/services/excel.service';
import { DatePipe } from '@angular/common';
import { StorageService } from '@app-core/services/storage.service';
import { LocationFilterComponent } from '../location-filter/location-filter.component';
import { filterAssetEventCount } from '@modules/shared/utils';
import { ERROR_MESSAGE, UNASSIGNED_DRIVER, UNIDENTIFIED_DRIVER_NAME } from '@app-core/constants/constants';
// import * as moment from 'moment';

const CONFIG = {
  class: 'modal-dialog-centered',
};

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
  providers: [DistancePipe, DatePipe],
})
export class ExportModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) public modal: ModalDirective;
  @ViewChild('locationFilter', { static: true })
  public locationFilter: LocationFilterComponent;
  /** Driver filter */
  @Input()
  public histogramData: any[] = [];
  @Input()
  public histogramPercentile: any[] = [];
  @Input()
  public modeScoreFrequency = 1;
  @Input()
  public driverCount = undefined;

  public config = CONFIG;
  public options: any;
  public isExportSet = true;
  public days = 7;
  public minScore = 0;
  public maxScore = 100;
  public rangeValue = 1;
  public dutyType = '';
  public daysTemp: number;
  public minScoreTemp: number;
  public maxScoreTemp: number;
  public rangeValueTemp: number;
  public dutyTypeTemp: string;
  public date1;
  public date2;
  public fleetRating;
  public radioXLS = 1;
  public driverCheckbox = true;
  public fleetCheckbox = true;
  public totalTrips;
  public tripData;
  public driverData;
  public fleetData;
  public fleetDriverCount;
  public counter = 0;
  public sort;
  public sortBy;
  public apply = false;
  public range;
  public homeLocationList = [];
  public driverIds = [];
  public isAutoExport = false;

  private _modalEventSubscription: Subscription;

  private _resetDriverforExport = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public resetforExport = this._resetDriverforExport.asObservable();

  constructor(
    private _export: ExportService,
    private _dateService: DateService,
    private _loading: LoadingService,
    private _toast: ToasterService,
    private _zcFleetService: ZCFleetService,
    private _data: Data,
    private _excelService: ExcelService,
    private _storage: StorageService,
    private _date: DatePipe,
    private _distancePipe: DistancePipe
  ) {}

  public ngOnInit() {
    console.log('ExportModalComponent');
    this._initModal();
  }

  public ngOnDestroy() {
    if (this._modalEventSubscription) {
      this._modalEventSubscription.unsubscribe();
    }
  }

  /**
   * @description: on show function gets called whenever the user click on open modal button
   * and it will call getStats http call
   */
  public onShow() {
    this.days = this._data.filterData.days;
    this.driverCheckbox = true;
    this.fleetCheckbox = true;
    this.radioXLS = 1;

    // document.body.classList.add('modal-open');
    // document.getElementById('html').classList.add('modal-open');

    this._getStoredValues();
    this._setOldValues();
    this._getDateRange();
    this._dateService.exportCustomStartDate = this._dateService.customStartdate;
    this._dateService.exportCustomEndDate = this._dateService.customEndDate;
    this.range = {
      from: this._dateService.customStartdate,
      to: this._dateService.customEndDate,
    };
    this.homeLocationList = this._storage.getStorageValue('HOME_LOCATION_ABV');
    const displayHomeLocation = (this.locationFilter.homeLocationList || [])
      .filter(location => location.locationId === this.homeLocationList[0]);
    this.locationFilter.displayHomeLocation = (displayHomeLocation[0] && displayHomeLocation[0].locationName) || 'All Locations';
    this.locationFilter.selectedHomeLocation = this.homeLocationList && this.homeLocationList[0];
    this.getStats({
      params: new StatsModel(
        this.date1,
        this.date2,
        '',
        0,
        100,
        85,
        this.homeLocationList
      ),
    });
  }

  public getHomeLocationList(event) {
    this.homeLocationList = event;
  }

  /**
   * @description: function used to hide modal
   */
  public hideModal() {
    this.modal.hide();

    // document.body.classList.remove('modal-open');
    // document.getElementById('html').classList.remove('modal-open');

    window.scrollTo(0, 0);
  }

  /**
   * @description Download the trip search in the form of CSV
   */
  public exportReport() {
    this._loading.show();
    this._getDateRange();
    this.selectReport();
  }

  public selectReport() {
    this.recursiveFleetList();
  }

  /**
   * @description: formatting the trip data based on fetched values to generate the export report
   */
  public recursiveTripList() {
    const limit = 100;
    let offset = 0;
    for (let i = this.totalTrips; i >= 0; i = i - 100) {
      this.getTripList(limit, offset).then((res: any) => {
        if (Array.isArray(res) && res.length === 0) {
          return;
        }
        if (this.tripData === undefined) {
          this.tripData = res;
        } else {
          this.tripData = [...this.tripData, ...res];
        }
        if (this.tripData) {
          if (this.totalTrips === this.tripData.length) {
            this.formatData(
              this.tripData,
              'Trip List',
              this.tripData.totalCount
            );
          }
        }

        this._loading.hide();
      });

      offset = offset + limit;
    }
  }
  /**
   * @description: check whether the click has been inside the dropdown body
   * @param: click inside boolean value
   */
  public async recursiveFleetList() {
    const driverCount = this.driverCount;
    const limit = 100;
    const params = new Array(Math.ceil(driverCount / (limit || 1)))
      .fill(0)
      .map((_, index) => this.getFleetList(limit, limit * index));

    await Promise.all(params)
      .then((res: any) => {
        this.fleetData = res.reduce(
          (a, b) => ({
            list: [...a.list, ...b.list],
            totalCount: b.totalCount,
          }),
          { list: [], totalCount: 0 }
        );
        if (this.fleetCheckbox) {
          if (this.fleetData.totalCount === this.fleetData.list.length) {
            if (this.fleetData.totalCount === 0) {
              this._toast.error('There are no drivers in this score range');
              this.hideModal();
              this._loading.hide();
              this.isAutoExport = false;
              return;
            }
            this.formatData(
              this.fleetData.list,
              'Fleet List',
              this.fleetData.totalCount
            );
          }
        }
        if (this.driverCheckbox) {
          if (this.fleetData.totalCount === 0) {
            this._toast.error('There are no drivers in this score range');
            this.hideModal();
            this._loading.hide();
            this.isAutoExport = false;
            return;
          }
          if (this.fleetData.totalCount === this.fleetData.list.length) {
            this.formatData(
              this.fleetData.list,
              'Driver List',
              this.fleetData.totalCount
            );
          }
        }
        this.isAutoExport = false;
        this._loading.hide();
      })
      .catch(() => {
        this._toast.error('An error occurred. Please try again');
        this.hideModal();
        this.isAutoExport = false;
        this._loading.hide();
      });
  }

  /**
   * @description: formatting the driver data based on fetched values to generate the export report
   */
  public recursiveDriverList() {
    const limit = 100;
    let offset = 0;
    for (let i = this.driverCount; i >= 0; i = i - 100) {
      this.getDriverList(limit, offset).then((res: any) => {
        if (this.driverData === undefined) {
          this.driverData = res.list;
        } else {
          this.driverData = [...this.driverData, ...res.list];
        }
        if (this.driverData) {
          if (this.driverData.totalCount === this.driverData.length) {
            if (this.driverData.totalCount === 0) {
              this._toast.error('There are no drivers in this score range');
              this.hideModal();
              this._loading.hide();
              return;
            }
            this.formatData(
              this.driverData,
              'Driver List',
              this.driverData.totalCount
            );
          }
        }

        this._loading.hide();
      });

      offset = offset + limit;
    }
  }

  public setDriverCheckbox() {
    this.driverCheckbox = !this.driverCheckbox;
  }

  public setFleetCheckbox() {
    this.fleetCheckbox = !this.fleetCheckbox;
  }

  /**
   * @description: getting driver list based on limit and offset
   * @param: the limit and offset for the paginated driver list call
   * @returns: a promise with the driver list and other params
   */
  public getDriverList(limit, offset) {
    const params = new DefaultInputModelV2(
      this.date1,
      this.date2,
      this.dutyType,
      this.minScore,
      this.maxScore,
      limit,
      offset,
      this.sort,
      this.sortBy,
      this.homeLocationList
    );
    const OPTIONS = {
      params,
    };

    return new Promise((resolve, reject) => {
      this._loading.show();
      this._zcFleetService.getDriverListV2(OPTIONS).subscribe(
        (res) => {
          this._loading.hide();
          resolve(res);
        },
        (err) => {
          this._loading.hide();
          reject(err);
        }
      );
    });
  }

  /**
   * @description: getting fleet list based on limit and offset
   * @param: the limit and offset for the paginated fleet list call
   * @returns: a promise with the fleet list and other params
   */
  public getFleetList(limit, offset) {
    const params = new DefaultInputModelV2(
      this.date1,
      this.date2,
      this.dutyType,
      this.minScore,
      this.maxScore,
      limit,
      offset,
      this.sort,
      this.sortBy,
      this.homeLocationList
    );
    const OPTIONS = {
      params,
    };

    return new Promise((resolve, reject) => {
      this._loading.show();
      this._zcFleetService.getDriverListV2(OPTIONS).subscribe(
        (res) => {
          this._loading.hide();
          resolve(res);
        },
        (err) => {
          this._loading.hide();
          reject(err);
        }
      );
    });
  }
  /**
   * @description: getting trip list based on limit and offset
   * @param: the limit and offset for the paginated trip list call
   * @returns: a promise with the trip list and other params
   */
  public getTripList(limit, offset) {
    // When minscore and maxscore filter is applied and if ids list is empty
    // then return an empty array
    // Because when the ids are empty, the API returns all trips, which is not
    // desired

    try {
      if (this.minScore !== 0 || this.maxScore !== 100) {
        if (this.driverIds.length === 0) {
          this._toast.error('There are no trips in this duration');
          this.modal.hide();
          return Promise.resolve([]);
        }
      }

      const params = new FleetAllTripsModel(
        this.date1,
        this.date2,
        this.dutyType,
        limit,
        offset,
        this.sort,
        this.sortBy,
        this.homeLocationList
      );
      const OPTIONS = {
        ...params,
        driverIds: this.driverIds,
      };

      return new Promise((resolve, reject) => {
        this._loading.show();
        this._zcFleetService.getTripList(OPTIONS).subscribe(
          (res) => {
            this._loading.hide();
            resolve(res);
          },
          (err) => {
            this._loading.hide();
            reject(err);
          }
        );
      });
    } catch (err) {
      this._toast.error('There are no trips in this duration');
    }
  }

  /**
   * @description: applying the filter values based on user input
   * @param: date as string
   *
   */
  public applyFilter(date?: string) {
    this.apply = true;
    if (this._filterChanged()) {
      this._resetDriverFilter();
      this._resetDriverforExport.next();
    }
    if (this.days === 2 && date) {
      this._resetDriverFilter();
    }
    this.setNewValues();
    this._getDateRange();
    this.getStats();

    if (date) {
      this.getStats({
        params: new StatsModel(
          this.date1,
          this.date2,
          '',
          0,
          100,
          85,
          this.homeLocationList
        ),
      });
      this.getStats();
    }
  }

  /**
   * @description: set the default values to the different filter present in dashboard
   */
  public setNewValues() {
    this.days = this.daysTemp;
    this.minScore = this.minScoreTemp;
    this.maxScore = this.maxScoreTemp;
    this.rangeValue = this.rangeValueTemp;
    this.dutyType = this.dutyTypeTemp;

    // from histogram data, extract driverIds within the minScore and
    // maxScore range
    if (this.minScore === 0 && this.maxScore === 100) {
      this.driverIds = [];
    } else {
      // from histogram data, extract driverIds within the minScore and
      // maxScore range
      this.driverIds = this.histogramData.reduce((e1, e2, index) => {
        if (index >= this.minScore && index <= this.maxScore) {
          e1 = e1.concat(e2.driverIds);
        }
        return e1;
      }, []);
    }
  }

  public setDutyType(val) {
    this.dutyTypeTemp = val;
    this.applyFilter();
  }

  // Discard temporary changes by setting initial filter values
  public discardChanges() {
    if (!this.apply) {
      this._getStoredValues();
    }
  }

  /**
   * @description: set date range based on user input
   * @param: the date value as string
   */
  public setDateRange(val) {
    this.daysTemp = val;
    this.applyFilter('date');
  }

  /**
   * @description: get stats http call
   * @param: the required stats params
   * @returns: the stats of the selected fleet
   */
  public getStats(statsParams?: any) {
    let param = 1;
    if (!statsParams) {
      param = 0;
      statsParams = {
        params: new StatsModel(
          this.date1,
          this.date2,
          this.dutyType,
          this.minScore,
          this.maxScore,
          85,
          this.homeLocationList
        ),
      };
    }

    this._loading.show();

    this._zcFleetService.getStats(statsParams).subscribe(
      (res) => {
        this._loading.hide();
        this.fleetRating = res.score;
        if (param === 1) {
          this.fleetDriverCount = res.driverCount;
        } else if (param === 0) {
          this.driverCount = res.driverCount;
          this.setStats(res);
        }
        if (this.apply === true) {
          this.setNewValues();
        }
      },
      (err) => {
        this._loading.hide();
        console.error(`Received Error: ${err}`);
      }
    );
  }

  public setStats(data) {
    const {
      percentile,
      distribution,
      modeScoreFrequency,
      driverCount,
      tripCount,
    } = data;

    this.histogramData = distribution;
    this.histogramPercentile = percentile;
    this.modeScoreFrequency = modeScoreFrequency;
    this.driverCount = driverCount;
    this.totalTrips = tripCount;
  }

  /**
   * @description: formatting the data for export report
   * @param: response, the duty type and the total count of drivers
   */
  public formatData(res, type, totalCount) {
    let formatteddriver;
    let formattedPerformace;
    let utils; let sheet;
    let filename;
    const date1 = JSON.stringify(this.convertDate(this.date1));
    const date2 = JSON.stringify(this.convertDate(this.date2));
    const dutyType = this.dutyType === '' ? 'All duty types' : this.dutyType;
    try {
      if (type === 'Driver List') {
        // eslint-disable-next-line max-len
        filename =
          'ZCoach_Drivers_' +
          this._storage.getStorageValue('state') +
          '_' +
          date1 +
          '_' +
          date2 +
          '_#' +
          dutyType;
        formattedPerformace = this.driverPerformace(totalCount, type);
        formatteddriver = res.map((ob) => this.driver(ob));

        utils = this._excelService.getSheetToJson(formattedPerformace, {
          header: ['A', 'B'],
          skipHeader: true,
        });
        sheet = this._excelService.sheetAddJson(utils, formatteddriver, {
          origin: 'A10',
        });
      }
      if (type === 'Fleet List') {
        // eslint-disable-next-line max-len
        filename =
          'ZCoach_Fleet_' +
          this._storage.getStorageValue('state') +
          '_' +
          date1 +
          '_' +
          date2 +
          '_#' +
          dutyType;
        formattedPerformace = this.driverPerformace(totalCount, type);
        // formattedPerformace = formattedPerformace.map(({A, B}) => `${A} : ${B}`)
        // eslint-disable-next-line max-len
        const aggregate = formattedPerformace.reduce(
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ({ keys, values }, { A, B }) => ({
            keys: [...keys, A],
            values: [...values, B],
          }),
          { keys: [], values: [] }
        );
        formatteddriver = res.map((ob) => this.fleet(ob));
        const incidents = [
          'Harsh Braking Incidents',
          'Harsh acceleration incidents',
          'Cornering incidents',
          'Stop sign violations',
          'Lane drift incidents',
          'Tailgating incidents',
          'Speed limit violations',
        ];
        const metrics = [
          'Total miles driven',
          'Total hours driven',
          'Total Number of Trips',
          'Top Incident Type',
          'Avg trip duration',
          'Total incidents',
          ...incidents,
        ];
        const totalData = formatteddriver.reduce((a, b) =>
          metrics
            .map((x) => ({ [x]: a[x] + b[x] }))
            .reduce((c, d) => ({ ...c, ...d }), {})
        );
        const topIncidentTypeMetric = incidents
          .map((x) => [x, totalData[x]])
          .reduce(
            ([k1, v1], [k2, v2]) => (v1 > v2 ? [k1, v1] : [k2, v2]),
            ['No incidents', 0]
          );

        // when topIncidentTypeMetric[1] returns the value 0
        // it means no incidents exist so topIncidentType will display 'No Incidents Found'
        const topIncidentType = topIncidentTypeMetric[1] !== 0 ? topIncidentTypeMetric[0] : 'No Incidents Found';
        const result = metrics.map((x) =>
          x !== 'Top Incident Type' ? totalData[x] : topIncidentType
        );
        const dataFleet = [aggregate.keys, aggregate.values];
        const dataAgg = [metrics, result];
        const totalSeconds = dataAgg[1][1];
        let totalHoursDriven = dataAgg[1][1] / 3600;
        totalHoursDriven = this.appendZero(totalHoursDriven);

        let minutes = (dataAgg[1][1] % 3600) / 60;
        minutes = this.appendZero(minutes);
        dataAgg[1][1] = totalHoursDriven + ' : ' + minutes;

        let avgTripDurationhours = totalSeconds / dataAgg[1][2] / 3600;
        avgTripDurationhours = this.appendZero(avgTripDurationhours);

        let avgTripDurationMinutes =
          ((totalSeconds / dataAgg[1][2]) % 3600) / 60;
        avgTripDurationMinutes = this.appendZero(avgTripDurationMinutes);
        dataAgg[1]['4'] = avgTripDurationhours + ' : ' + avgTripDurationMinutes;

        utils = this._excelService.getSheetToJson([], {
          skipHeader: true,
        });
        sheet = this._excelService.sheetAddJson(utils, dataFleet, {
          origin: { r: 1, c: 0 },
          skipHeader: true,
        });
        sheet = this._excelService.sheetAddJson(utils, dataAgg, {
          origin: { r: 1, c: 8 },
          skipHeader: true,
        });
      }

      // const formattedPerformace = this.driverPerformace();

      if (this.radioXLS === 1) {
        this._excelService.convertToExcel(sheet, filename);
      } else {
        this._excelService.convertToCSV(sheet, filename);
      }

      if (this.driverCheckbox && this.fleetCheckbox) {
        this.counter++;
        if (this.counter === 2) {
          this.hideModal();
        }
      } else {
        this.hideModal();
      }
    } catch (error) {
      console.log('Error while saving report', error);
      this.hideModal();
      this._toast.error('Error while saving Report');
    }
  }

  // public driver(ob: any) {
  //     return {
  //         'RANK': ob.rank,
  //         'DRIVER NAME': ob.driverName,
  //         'AVERAGE SCORE': ob.v2Score,
  //         'SCORE': ob.v2ScoreChange,
  //         'TOP INCIDENT': ob.topIncidentType
  //     };
  // }

  public driver(ob: any) {
    let topIncidentType;
    const obj = Object.assign({}, ob.eventCount);
    delete obj['total'];

    topIncidentType = Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);
    if (obj[topIncidentType] === 0) {
      topIncidentType = 'No Incidents Found';
    }

    const distanceInMiles = this._distancePipe.transform(
      ob.tripDistance,
      'miles'
    );
    // let avgIncidents = ((ob.eventCount.total * 100) / distanceInMiles).toFixed(2);
    // if (distanceInMiles === 0) {
    //     avgIncidents = ((ob.eventCount.total * 100) / 1).toFixed(2);
    // }

    let totalHoursDriven = Math.trunc(ob.tripDuration / 3600);
    totalHoursDriven = this.appendZero(totalHoursDriven);
    let minutes = Math.trunc((ob.tripDuration % 3600) / 60);
    minutes = this.appendZero(minutes);

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      'Driver Name': this.getDriverName(ob),
      'Driver Number': ob.driverId,
      'Average Score': ob.v2Score,
      'Total miles driven': parseFloat(distanceInMiles.toFixed(2)),
      'Total hours driven(HH:MM)': totalHoursDriven + ' : ' + minutes,
      'Total Number of Trips': ob.tripCount,
      'Top Incident Type': topIncidentType,
      'Total incidents': ob.eventCount.total,
      'Harsh Braking Incidents': ob.eventCount['Harsh-Braking'],
      'Harsh acceleration incidents': ob.eventCount['Harsh-Acceleration'],
      'Cornering incidents': ob.eventCount.Cornering,
      'Stop sign violations': ob.eventCount['Traffic-STOP-Sign-Violated'],
      'Lane drift incidents': ob.eventCount['Lane-Drift-Found'],
      'Tailgating incidents': ob.eventCount['Tail-Gating-Detected'],
      'Speed limit violations': ob.eventCount['Traffic-Speed-Violated'],
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  public getDriverName(ob: any) {
    if (ob.driverId === UNASSIGNED_DRIVER) {
      return UNIDENTIFIED_DRIVER_NAME;
    } else {
      return ob.driverName ? ob.driverName : ERROR_MESSAGE.INVALID_DRIVER_ID;
    }
  }

  // public trip(ob: any) {
  //     const distanceInMiles = this.distancePipe.transform(ob.tripDistance, 'miles');

  //     return {
  //         DRIVER: ob.driverName,
  //         'TOTAL INCIDENTS': ob.total,
  //         'START LOCATION': ob.location,
  //         'DATE/TIME (PST)': ob.startTime,
  //         'DURATION (HH:MM)': ob.duration,
  //         'DISTANCE (MI)': distanceInMiles,
  //         ASSET: ob._assetNumber
  //     };
  // }

  /**
   * format the driverlist
   *
   * @param ob (driver list response)
   */
  public fleet(ob: any) {
    ob.eventCount = filterAssetEventCount(ob.eventCount);
    const { total, ...obj } = ob.eventCount;

    const topIncidentKey = Object.keys(obj).reduce((a, b) => obj[a] > obj[b] ? a : b);

    const topIncidentType =
      obj[topIncidentKey] === 0 ? 'No Incidents Found' : topIncidentKey;

    const distanceInMiles = this._distancePipe.transform(
      ob.tripDistance,
      'miles'
    );
    // let avgIncidents = ((ob.eventCount.total * 100) / distanceInMiles).toFixed(2);
    // if (distanceInMiles === 0) {
    //     avgIncidents = ((ob.eventCount.total * 100) / 1).toFixed(2);
    // }

    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      'Total miles driven': parseFloat(distanceInMiles.toFixed(2)),
      'Total hours driven': ob.tripDuration,
      'Total Number of Trips': ob.tripCount,
      'Top Incident Type': topIncidentType,
      'Avg trip duration': parseFloat(
        (ob.tripDuration / ob.tripCount).toFixed(2)
      ),
      'Total incidents': ob.eventCount.total,
      'Harsh Braking Incidents': ob.eventCount['Harsh-Braking'],
      'Harsh acceleration incidents': ob.eventCount['Harsh-Acceleration'],
      'Cornering incidents': ob.eventCount.Cornering,
      'Stop sign violations': ob.eventCount['Traffic-STOP-Sign-Violated'],
      'Lane drift incidents': ob.eventCount['Lane-Drift-Found'],
      'Tailgating incidents': ob.eventCount['Tail-Gating-Detected'],
      'Speed limit violations': ob.eventCount['Traffic-Speed-Violated'],
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  /**
   * @description: setting the driver performance values
   * @param: total count of incidents and duty type
   */
  public driverPerformace(totalCount, type) {
    let dateRange = { from: null, to: null };
    let date1; let date2;
    if (this.days === 2) {
      dateRange.from = this._dateService.toDaysStartISO(
        this._dateService.exportCustomStartDate
      );
      dateRange.to = this._dateService.toDaysEndISO(
        this._dateService.exportCustomEndDate
      );
      if (
        this._dateService.exportCustomStartDate === undefined ||
        this._dateService.exportCustomEndDate === undefined
      ) {
        dateRange = this._data.customRange.data;
        this._dateService.exportCustomStartDate =
          this._data.customRange.data.from;
        this._dateService.exportCustomEndDate = this._data.customRange.data.to;
      }
      date1 = this._dateService.toDaysStartISO(dateRange.from);
      // this.date2 = (new Date(dateRange.to)).setDate(new Date(dateRange.to).getDate() + 1);
      date2 = new Date(dateRange.to);
      date2 = date2.setDate(date2.getDate() - 2);
    } else {
      dateRange = this._dateService.getDateRange(this.days);
      date1 = this._dateService.toDaysStartISO(dateRange.from);
      date2 = dateRange.to;
    }

    if (type === 'Fleet List') {
      return [
        { A: 'Report Title', B: type },
        { A: 'Account Code', B: this._storage.getStorageValue('state') },
        { A: 'Date of report', B: this.convertDate(new Date()) },
        {
          A: 'Date Range for Report',
          B: this.convertDate(date1) + '-' + this.convertDate(date2),
        },
        { A: 'Drivers in Fleet', B: this.fleetDriverCount },
        { A: 'Drivers in Export', B: totalCount },
        { A: 'Fleet Average Score', B: this.fleetRating },
        {
          A: 'Duty Type',
          B: this.dutyType === '' ? 'All duty types' : this.dutyType,
        },
      ];
    } else {
      return [
        { A: 'Report Title', B: type },
        { A: 'Account Code', B: this._storage.getStorageValue('state') },
        { A: 'Date of report', B: this.convertDate(new Date()) },
        {
          A: 'Date Range for Report',
          B: this.convertDate(date1),
          C: this.convertDate(date2),
        },
        { A: 'Drivers in Fleet', B: this.fleetDriverCount },
        { A: 'Drivers in Export', B: totalCount },
        { A: 'Fleet Average Score', B: this.fleetRating },
        {
          A: 'Duty Type',
          B: this.dutyType === '' ? 'All duty types' : this.dutyType,
        },
      ];
    }
  }

  /**
   * @description: converting the date format using the custom date service
   */
  public convertDate(date) {
    return this._date.transform(date, 'MM/dd/yy');
  }

  public appendZero(value) {
    value = this.trimFloat(value);
    if (typeof value === 'string') {
      value = parseInt(value, 10);
      if (value < 10) {
        value = '0' + value;
        return value;
      } else {
        return value;
      }
    } else {
      if (value < 10) {
        value = value.toString();
        value = '0' + value;
        return value;
      } else {
        return value;
      }
    }
  }

  /**
   * to avoid the issues caused by using toFixed()
   */
  public trimFloat(num) {
    num = num.toString(); // If it's not already a String
    if (num.indexOf('.') > 0) {
      num = num.slice(0, num.indexOf('.') + 3);
      return num;
    } else {
      return num;
    }
  }

  /**
   * @description: initialising the export modal to default values
   */
  private _initModal() {
    this._modalEventSubscription = this._export
      .exportModal()
      .subscribe((options) => {
        if (options.show) {
          delete options.show;
          this.options = options;
          if (options && options.autoExport) {
            this._autoExportCSV();
            return;
          }
          this.modal.show();
          // TODO: Use ManualRefresh provided by ng5-slider
          setTimeout(() => window.dispatchEvent(new Event('resize')), 2000);
        } else {
          this.hideModal();
        }
      });
  }

  private _autoExportCSV() {
    if (!this.isAutoExport) {
      this.isAutoExport = true; // flag to avoid multiple auto export process at the same time
      this.onShow();
      this.radioXLS = 2; // only export CSV files in auto export
      this.exportReport();
    }
  }

  /**
   * @description: inorder to get stats based on storage values present in local storage
   */
  private _getStoredValues() {
    this.days = this._data.filterData.days;
    this.minScore = this._data.filterData.minScore;
    this.maxScore = this._data.filterData.maxScore;
    this.rangeValue = this._data.filterData.rangeValue;
    this.dutyType = this._data.filterData.dutyType;
    this.driverIds = this._data.filterData.ids;
    this.driverCount = this._zcFleetService.driverCount;
    this.fleetDriverCount = this._zcFleetService.fleetDriverCount;
    this.fleetRating = this._zcFleetService.fleetRating;
    this.totalTrips = this._zcFleetService.tripCount;
    this.sort = this._zcFleetService.sort;
    this.sortBy = this._zcFleetService.sortBy;
  }

  /**
   * @description: function to check the date range for 1,2 and more days and apply filter values based on selected date range
   */
  private _getDateRange() {
    let dateRange = { from: null, to: null };
    if (this.days === 2) {
      dateRange.from = this._dateService.toDaysStartISO(
        this._dateService.exportCustomStartDate
      );
      dateRange.to = this._dateService.toDaysEndISO(
        this._dateService.exportCustomEndDate
      );
      if (
        this._dateService.exportCustomStartDate === undefined ||
        this._dateService.exportCustomEndDate === undefined
      ) {
        dateRange = this._data.customRange.data;
        this._dateService.exportCustomStartDate =
          this._data.customRange.data.from;
        this._dateService.exportCustomEndDate = this._data.customRange.data.to;
      }
      this.date1 = this._dateService.toDaysStartISO(dateRange.from);
      this.date2 = dateRange.to;
    } else {
      dateRange = this._dateService.getDateRange(this.days);
      this.date1 = this._dateService.toDaysStartISO(dateRange.from);
      this.date2 = dateRange.to.setDate(dateRange.to.getDate() + 1);
      this.date2 = this._dateService.toDaysEndISO(this.date2);
    }
  }

  // Check date and dutytype has changed
  private _filterChanged() {
    if (this.days !== this.daysTemp) {
      return true;
    }
    if (this.dutyType !== this.dutyTypeTemp) {
      return true;
    }
    return false;
  }

  private _setOldValues() {
    this.daysTemp = this.days;
    this.minScoreTemp = this.minScore;
    this.maxScoreTemp = this.maxScore;
    this.rangeValueTemp = this.rangeValue;
    this.dutyTypeTemp = this.dutyType;
  }

  /**
   * @description: reset the filter values of export modal
   */
  private _resetDriverFilter() {
    this.minScore = 0;
    this.maxScore = 100;
    this.rangeValue = 1;
    this.minScoreTemp = this.minScore;
    this.maxScoreTemp = this.maxScore;
    this.rangeValueTemp = this.rangeValue;
  }
}
