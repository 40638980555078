<div class="zc-body zc-app-wrap">
  <div class="zc-content-wrap small-screen">
    <div class="page-title">
      <img
        class="home-icon"
        (click)="goToDashboard()"
        src="assets/images/home_24px.svg"
      />
      <img class="arrow-icon" src="assets/images/arrow_forward_24px.svg" />
      <div class="route">
        <span> {{ assetName$ | async }} </span>
      </div>
    </div>
    <div style="padding-bottom: 48px">
      <div class="col-md-12 p-0">
        <span class="heading-left">Asset Snapshot</span>
      </div>
    </div>

    <div class="card-content-wrapper">
      <!-- filter bar -->
      <app-search-filter-table
        (dateChange)="onDateChange($event)"
        [refetchFilter]="refetchFilter"
        [filterList]="filterList"
      ></app-search-filter-table>

      <!-- identification card with dummy data -->
      <div class="identification-card">
        <app-identification-card
          [name]="assetName$"
          [divisionName]="divisionName$"
          [isCustomDateRange]="isCustomDateRange"
          [graphData]="graphData$"
          [reload$]="reload$"
        ></app-identification-card>
      </div>
      <!-- top incident count card & key metrics card -->
      <div class="incident-stats">
        <app-total-incident-card
          [totalIncident$]="totalIncident$"
          [topIncidentData$]="topIncidentData$"
          [range]="dateFilter$ | async"
          [assetId]="assetId$ | async"
          [reload$]="reload$"
        ></app-total-incident-card>

        <app-key-metric-overview
          [metricData$]="metricData$"
          [metricHeaders]="metricHeaders"
          [reload$]="reload$"
        >
        </app-key-metric-overview>
      </div>

      <div class="asset-detail-content" *appFeatureFlag="'asset-snapshot'">
        <!-- asset incident table card -->
        <div [ngSwitch]="show" class="asset-incidents-card">
          <div>
            <app-card
              [sortList]="sortListIncidentTable"
              [showSortList]="showSortOption"
              (changeSortBy)="notifySortChangeIncidentTable($event)"
              [updateSortList]="syncSortCard"
            >
              <div card-title>Incidents</div>
              <div card-body *ngSwitchCase="'data'">
                <app-incident-media-table-wrapper
                  [dataSource$]="incidentListTable$"
                  [sortOrderChange$]="notifySortChange$"
                  [reload$]="assetIncidentTableReload$"
                  [colDescriptions]="colDescriptionsIncidentTable"
                  [customTemplatesList]="assetIncidentCustomTemplates"
                  [customTemplates]="{
                    action: actionButtons
                  }"
                  [getUniqueKey]="getRowDataIdentifier"
                  [isDisabledAction]="isDisabledAction"
                  [incidentData]="incidentData"
                  [selectedIncident]="selectedIncident"
                  [showSmallSizeMediaPanel]="true"
                  [switch]="switch"
                  (rowSelected)="setMedia($event)"
                  (pageChange)="updatePageIncidentTable($event)"
                  (sortChange)="sortChangeInternalAssetTable($event)"
                  (challengeUpdated)="updateIncidentChallenge($event)"
                  (isDisabledClickEvent)="isDisabledClick($event)"
                ></app-incident-media-table-wrapper>
              </div>
            </app-card>
          </div>
        </div>

        <app-driver-history
          [driverHistoryData$]="driverHistory$"
          [hasError]="getDriverTripListError"
          [reload$]="driverDataReload$"
          (pageChange)="driverHistoryPageChange($event)"
          (sortChange)="driverHistorySortChange($event)">
        </app-driver-history>

        <app-asset-trip-recap-component
          [sortList]="sortListRecap"
          [name]="assetTripTitle$"
          [maxNameLength]="assetTripPrefix.length + 5"
          [reloadObs$]="assetTripList$"
          [getTripList]="getAssetTripListBinded"
          (changeSortBy)="notifySortChangeRecap($event)"
        ></app-asset-trip-recap-component>

        <!-- TO-DO: unhide when groupBy supported for API Aggregate Asset Violations -->
        <app-incidents-overtime-chart *appFeatureFlag="'showAssetSnapshotTables'"
          [dataSource$]="incidentOverTimeSource$"
          [reload$]="reload$"
          [updateIncidentChart]="true"
          [showGraph]="true"
        ></app-incidents-overtime-chart>
      </div>
    </div>
  </div>
</div>

<!-- TO-DO: This templates need component methods to handle events -->
<!-- buttons -->
<ng-template #actionButtons let-asyncData let-element="element">
  <div class="asset-view-buttons">
    <ng-container *ngIf="asyncData | async as data; else buttonLoading">
      <app-comment-button-group
        [ngClass]="{ 'disabled-button': isDisabledAction }"
        [bookmarkInfo]="data"
        [isMomentObjCreated]="true"
        [showSpinner]="false"
        [isSelfUpdate]="true"
        (violationIdentifierChange)="
          violationIdentifier = $event.violationIdentifier
        "
      >
      </app-comment-button-group>
    </ng-container>
    <ng-template #buttonLoading>
      <div class="loading-action-buttons">
        <div class="loading-comment-button">
          <app-custom-skeleton-loader
          [theme]="{
            width: '24px',
            height: '24px',
            'top': '3px',
            'left': '11px'
          }">
        </app-custom-skeleton-loader>
        </div>
        <div class="loading-bookmark-button">
          <app-custom-skeleton-loader
          [theme]="{
            width: '24px',
            height: '24px',
            'top': '3px',
            'left': '7px'
          }">
        </app-custom-skeleton-loader>
        </div>
      </div>
    </ng-template>
  </div>
</ng-template>
