import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  NgZone,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-media-show',
  templateUrl: './media-show.component.html',
  styleUrls: ['./media-show.component.scss'],
})
export class MediaShowComponent implements OnChanges {
  @Input() public event;
  @Input() public tripId = '';
  public media = '';
  public mediaSrc = '';
  public switch = true;

  public isShowingActualSpeed = true;

  public actualSpeed: number;
  public postedSpeed: number;
  public time = '';
  public isMediaAvailable = true;
  public mediaStatus = null;
  public isPostedSpeedImageAvailable = false;

  constructor(private _zone: NgZone, private _elRef: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges) {
    this._zone.run(() => {
      // Workaround for image update issue on the same image tag, by switching
      // between two image tags
      this.switch = !this.switch;
      this.media = null;
      this.mediaSrc = null;
      this.isShowingActualSpeed = true;
      this.isMediaAvailable = true;
      const currentValue = changes.event.currentValue;
      this.mediaStatus = currentValue.eventVideoFilenameUploadStatus
        ? currentValue.eventVideoFilenameUploadStatus.status
        : 'UPLOADED';
      if (currentValue && Object.keys(currentValue).length) {
        this.time = currentValue.timestamp;

        // ZCW-545: keep solution from LM, converting meter per sec to km per hour
        if (currentValue.eventType === 'Traffic-Speed-Violated') {
          if (this.tripId.includes('trip')) {
            this.actualSpeed = currentValue.speed * 3.6; // converting meter per sec to km per hour
          } else {
            this.actualSpeed = currentValue.speed;
          }
          this.postedSpeed = currentValue.speedSign.speedSignValue;
        }

        if (!currentValue.edvrRequests) {
          this.mediaSrc = currentValue.eventVideoFilename;
          this.showActualSpeed();
        }

        if (currentValue.edvrRequests) {
          currentValue.edvrRequests = currentValue.edvrRequests.filter((ed) => ed.status === 'FINISHED');

          currentValue.edvrRequests.sort((a, b) => b.videoQuality - a.videoQuality, 0);

          if (
            currentValue.edvrRequests[0] &&
            currentValue.edvrRequests[0].status === 'FINISHED'
          ) {
            this.mediaSrc = currentValue.edvrRequests[0].eventVideoFile;
            this.showActualSpeed();
          } else {
            this.isShowingActualSpeed = true;
            this.mediaSrc = currentValue.eventVideoFilename;
            this.showActualSpeed();
          }
        } else {
          this.mediaSrc = currentValue.eventVideoFilename;
          this.showActualSpeed();
        }

        if (currentValue.eventVideoFilename) {
          const player = this._elRef.nativeElement.querySelector('video');
          if (player) {
            player.load();
          }
        }

        this.isPostedSpeedImageAvailable = false;
        this._setMediaType();
      }
    });
  }

  /**
   * @description: showing the actual speed media file
   * @param:
   */
  public showActualSpeed() {
    this.isShowingActualSpeed = true;
    this.mediaSrc = this.event ? this.event.eventVideoFilename : null;
    this._setMediaType();
  }

  /**
   * @description: function to show the posted speed media file
   * @param:
   */
  public showPostedSpeed() {
    this.isShowingActualSpeed = false;
    this.mediaSrc =
      this.event && this.event.speedSign
        ? this.event.speedSign.eventVideoFilename
        : null;
    if (this.mediaSrc && this.mediaSrc.indexOf('.jpg') > -1) {
      this.isPostedSpeedImageAvailable = true;
    } else {
      this.isPostedSpeedImageAvailable = false;
    }
    this._setMediaType();
  }

  public onError() {
    this.isMediaAvailable = false;
  }

  /**
   * @description: checking and setting the type of media file to be shown
   * @param:
   */
  private _setMediaType() {
    if (this.mediaSrc && this.mediaSrc.indexOf('.jpg') > -1) {
      this.media = 'image';
    } else {
      this.media = 'video';
    }
    this.isMediaAvailable = true;
  }
}
