import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Permission,
  PermissionManagerService,
} from '@app-core/services/permission-manager.service';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

/**
 * Directive to ignore button click when the user has permission
 * Usage:
 *  - *isDisableOnPermission="'<permisison>'"
 *  - *isDisableOnPermission="'<permisison>' passthrough true"
 *  - *isDisableOnPermission="'<permisison>' changeCursor false"
 *  - isDisableOnPermission [isDisableOnPermission]="'<permission'" [isDisableOnPermissionChangeCursor]="false"
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isDisableOnPermission]',
})
export class IsDisableOnPermissionDirective implements OnInit, OnDestroy {
  /** The permission to check on */
  @Input() public isDisableOnPermission: Permission;

  /** If set to true, always allow click, otherwise click event depends on the permission variable */
  @Input() public isDisableOnPermissionPassthrough = false;

  /** If set to true, change the cursor style to default, otherwise keep the cursor as-is */
  @Input() public isDisableOnPermissionChangeCursor = true;

  /** Emit an event on click only if the user do not have permission */
  @Output() public clickWithPermission = new EventEmitter();

  private _clicks$ = new Subject<MouseEvent>();
  private _onDestroy$ = new ReplaySubject();
  private _shouldHandleClick = false;

  constructor(
    private _permissionManager: PermissionManagerService,
    private _networkService: TrackNetworkStatusService,
    private _el: ElementRef
  ) {}

  @HostListener('click', ['$event'])
  public onClickEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this._clicks$.next(event);
  }

  public ngOnInit() {
    // This line will change _shouldHandleCLick accordingly
    this._tryForwardingClickEvenOnPermission(this.isDisableOnPermission);

    this._clicks$.pipe(takeUntil(this._onDestroy$)).subscribe((event) => {
      this._handleClickEvent(event);
    });
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _getPermissionObs(permission: Permission): Observable<boolean> {
    // TO-DO: investigate how to avoid calling requestPermissionCheck for performance purpose
    if (this._networkService.isOnline) {
      this._permissionManager.requestPermissionCheck(permission);
    }

    if (this.isDisableOnPermissionPassthrough) {
      return of(false); // return as unauthorized no matter what
    }

    return this._permissionManager
      .getPermissionObs(permission)
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$));
  }

  private _tryForwardingClickEvenOnPermission(permission: Permission) {
    this._getPermissionObs(permission).subscribe((isAuthorized) => {
      if (!isAuthorized) {
        this._handleUnauthorizedEvent();
      } else {
        this._handleAuthorizedEvent();
      }
    });
  }

  private _handleUnauthorizedEvent() {
    this._shouldHandleClick = true;
    if (this.isDisableOnPermissionChangeCursor) {
      this._el.nativeElement.style.cursor = 'pointer';
    }
  }

  private _handleAuthorizedEvent() {
    this._shouldHandleClick = false;
    if (this.isDisableOnPermissionChangeCursor) {
      this._el.nativeElement.style.cursor = 'default';
    }
  }

  private _handleClickEvent(event: MouseEvent) {
    if (this._shouldHandleClick) {
      this.clickWithPermission.emit(event);
    }
  }
}
