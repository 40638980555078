import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { DateService } from '@app-core/services/date.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { StorageService } from '@app-core/services/storage.service';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { LocalService } from './../../../../core/services/local.service';
import { SearchFilterModel, StatsModel } from './../../dashboard3.models';
import { StackableCustomSnackbarComponent }
  from '@modules/dashboard3/components/stackable-custom-snackbar/stackable-custom-snackbar.component';
import { SnackBarMsg } from '@modules/dashboard3/components/snackbar-custom-message/snackbar-custom-message.component';
import { Subscription } from 'rxjs';
import { HomeLocationService } from '@app-core/services/home-location.service';

const SNACKBAR_TITLE = 'An error has occurred';
const SNACKBAR_MSG = (target: string) => (`We were unable to load ${target || ''} due to a connection issue. Please try again.`);

@Component({
  selector: 'app-incident-response-center',
  templateUrl: './incident-response-center.component.html',
  styleUrls: ['./incident-response-center.component.scss'],
})
export class IncidentResponseCenterComponent implements OnInit, OnDestroy {
  public histogramData = [];
  public histogramPercentileData = [];
  public modeScoreFrequency = 1;
  public totalTrips = 0;

  public driverCount = undefined;
  public mediaLinkFromTableIncident = '';
  public switch = false;
  public isReloadPage = false;
  public isReloadFunction = false;
  public isGetHomeLocation = false;

  public searchFilterData: SearchFilterModel;
  public isSavedIncidentsError = undefined;
  public isDisputedError = undefined;
  public isShowBothError = false;
  public stackMsg = [];
  private _homeLocationList = null;
  private _subscription: Subscription = new Subscription();

  public get localStorageHomeLocation() {
    return this._storageService.getStorageValue('HOME_LOCATION');
  };

  public get localStorageHomeLocationABV() {
    return this._storageService.getStorageValue('HOME_LOCATION_ABV');
  };

  constructor(
    private _zcFleet: ZCFleetService,
    private _router: Router,
    private _dateService: DateService,
    private _data: Data,
    private _localService: LocalService,
    private _storageService: StorageService,
    private _title: Title,
    private _snackbarService: SnackbarService,
    private _networkService: TrackNetworkStatusService,
    private _homeLocationService: HomeLocationService
  ) {}

  public ngOnDestroy() {
    this._subscription.unsubscribe();
    if (!this.isReloadFunction) {
      // TO-DO: currently make it consistent with fleet-detail, update in the future if need
    }
    this._syncLocalStorageHomeLocation(true);
  }

  public ngOnInit(): void {
    this._title.setTitle('Incident Response Center - Zonar Coach');
    this._syncLocalStorageHomeLocation(false);
  }

  /**
   * @description: function to set fleet stats
   * @param: data such as percentile, distribution and other fields
   */
  public setStats(data) {
    const {
      percentile,
      distribution,
      modeScoreFrequency,
      driverCount,
      tripCount,
    } = data;
    setTimeout(() => {
      this.histogramData = distribution;
      this.histogramPercentileData = percentile;
      this.modeScoreFrequency = modeScoreFrequency;
      this.driverCount = driverCount;
      this.totalTrips = tripCount;
    }, 1);
  }

  /**
   * @description: function to get initial page date of fleet details
   * @param:
   */
  public getData() {
    this._localService.saveSearchFilterData(this.searchFilterData);
    const { days, dutyType, minScore, maxScore } = this.searchFilterData;
    let date = { from: null, to: null };
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }

    this._homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION_ABV');

    // Stats
    const statsParams = {
      params: new StatsModel(
        date.from,
        date.to,
        dutyType,
        minScore,
        maxScore,
        85,
        this._homeLocationList
      ),
    };

    this._zcFleet.getStats(statsParams).subscribe(
      (res) => {
        this._zcFleet.stats = res;
        this.setStats(res);
        this._zcFleet.fleetRating = res.score;
        this._zcFleet.driverCount = res.driverCount;
        this.driverCount = res.driverCount;
        this._zcFleet.tripCount = res.tripCount;
        this._zcFleet.fleetDriverCount = res.driverCount;
      },
      (err) => {
        console.error(`Received Error: ${err}`);
      }
    );
  }

  /**
   * @description: function to set search filter data based on user input
   * @param: filter values
   */
  public setSearchFilterData(val) {
    this.searchFilterData = val;
    this._snackbarService.closeCurrentSnackbar();
    this.getData();
    if (!this.isReloadPage) {
      this._zcFleet.fleetFilterChange.next(val);
    } else {
      this.reload();
    }
    this.isReloadPage = true;
  }

  public reload() {
    this.isReloadFunction = true;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['/incident-response-center']);
  }

  /**
   * @description: function to navigate to particular driver page
   * @param: driver details such as id and name
   */
  public gotoDriverDetail(driver) {
    if (driver && driver.driverId) {
      this._router.navigate(['/driver-detail'], {
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }

  public driverChange(driver) {
    this.gotoDriverDetail(driver);
  }

  public notifySavedIncidentsErrorChange(flag: boolean) {
    this.isSavedIncidentsError = flag;
    if (!this._checkNetworkAndBothTablesFail() && this.isSavedIncidentsError !== undefined && this.isDisputedError !== undefined) {
      this.showIndividualError();
    }
  }

  public notifyDisputedErrorChange(flag: boolean) {
    this.isDisputedError = flag;
    if (!this._checkNetworkAndBothTablesFail() && this.isDisputedError !== undefined && this.isSavedIncidentsError !== undefined) {
      this.showIndividualError();
    }
  }

  public showIndividualError() {
    if (!this.isShowBothError && this.isDisputedError) {
      this.showIrcError(SNACKBAR_TITLE, SNACKBAR_MSG('disputed incidents'));
    }
    if (!this.isShowBothError && this.isSavedIncidentsError) {
      this.showIrcError(SNACKBAR_TITLE, SNACKBAR_MSG('saved incidents'));
    }
  }

  public showIrcError(title: string, textMsg: string, isBothError?: boolean) {
    if (this._snackbarService.isSnackbarOpened && !this._networkService.isOnline) {
      this._subscription = this._snackbarService.snackbarRef.afterDismissed().subscribe(_ => {
        this.showIrcError(title, textMsg, isBothError);
      });
    } else if (!this._checkDuplicateErrorMsg(textMsg)) {
      this.initSnackbarInstance();
      const msg: SnackBarMsg = {
        textMsg,
        panelClasses: ['error-msg'],
        title,
        action: 'Try again',
        isShowContact: true,
        linkAction: this.reload.bind(this),
      };
      if (isBothError) {
        this.stackMsg = [];
      }
      this.stackMsg.push(msg);
      this._snackbarService.toastMsgNotifier.next(msg);
    }
  }

  public initSnackbarInstance() {
    if (!this._snackbarService.isSnackbarOpened) {
      this._snackbarService.openStackableSnackbar(StackableCustomSnackbarComponent);
    }
  }

  private _checkDuplicateErrorMsg(textMsg: string) {
    let flag = false;
    this.stackMsg.forEach((item: SnackBarMsg) => {
      if (item.textMsg === textMsg && !this._snackbarService.isSnackbarOpened) {
        flag = true;
        return;
      }
    });
    return flag;
  }

  private _checkNetworkAndBothTablesFail(): boolean{
    let  title = '';
    let textMsg = '';
    if (!this._networkService.isOnline) {
      title = 'No network found';
      textMsg = 'We were unable to load incident data. Please wait a few minutes and try again.';
      this.showIrcError(title, textMsg, true);
      this.isShowBothError = true;
    } else if (this.isSavedIncidentsError && this.isDisputedError) {
      title = SNACKBAR_TITLE;
      textMsg = 'We were unable to load incident data due to a connection issue. Please try again.';
      this.showIrcError(title, textMsg, true);
      this.isShowBothError = true;
    } else {
      this.isShowBothError = false;
    }
    return this.isShowBothError;
  }

  private _syncLocalStorageHomeLocation(isDestroy: boolean) {
    const locationFullList = this._storageService.getStorageValue('HOME_LOCATION_FULL_LIST');
    if (!isDestroy) { // load IRC page
      if (this.localStorageHomeLocation?.length === 0) {
        // TO-DO: optimize Old All locations logic
        if (!locationFullList) {
          this._homeLocationService.getHomeLocations().subscribe(
            () => {
              this.isGetHomeLocation = true;
              this.reload();
            }
          );
        } else {
          this._storageService.setStorageValue('HOME_LOCATION_ABV', locationFullList.map(item => item.locationId));
        }
      } else {
        this._storageService.setStorageValue('HOME_LOCATION_ABV', this.localStorageHomeLocation);
      }
    } else if (isDestroy) { // leave IRC page
      const currentLocationListSelected = this.localStorageHomeLocationABV?.filter(item => item !== 'null');
      const isCheckAllLocation = locationFullList?.length === currentLocationListSelected?.length;
      if (isCheckAllLocation || this.isGetHomeLocation) {
        this._storageService.setStorageValue('HOME_LOCATION', []); // all locations
      } else {
        this._storageService.setStorageValue('HOME_LOCATION', this.localStorageHomeLocationABV); // no location selected, select multiple
      }
    }
  }
}
