<div class="error-container">
  <div class="error-content">
    <app-card [showFooter]="true">
      <div card-title class="error-title">An error has occurred</div>

      <div card-body class="error-body">
        <span class="text-content-first">
          We were unable to load this data due to a connection issue.
          Please try again.
        </span>
        <br>
        <span class="text-content-second">
          If the issue persists, please contact <a target="_blank" href="https://support.zonarsystems.net/hc/en-us/sections/360002547151-Zonar-Coach">Zonar Customer Care.</a>
        </span>
      </div>

      <div card-footer class="error-footer">
        <div class="btn-section" *ngIf="showRetryButton">
          <button (click)="onChange()"
            type="button" class="btn btn-primary btn-try-again btn-format">Try again</button>
        </div>
      </div>
    </app-card>
  </div>
</div>
