<div class="box">
   <form #f="ngForm" novalidate>
      <div class="row">

         <div class="form-group col-md-3">
            <label for="FromDate" class="text-blue">From</label>
            <div class='input-group datepicker'>
               <input type="text" id="FromDate" class="form-control" placeholder="From" name="from" [maxDate]="todaysDate"
                  [bsConfig]="bsConfig" [(ngModel)]="from" bsDatepicker required #dpicker1="bsDatepicker"
               />
               <span class="input-group-btn">
                  <button class="btn calendar-button" type="button" (click)="dpicker1.show()">
                     <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
               </span>
            </div>
         </div>

         <div class="form-group col-md-3">
            <label for="toDate" class="text-blue">To</label>
            <div class='input-group datepicker'>
               <input type="text" id="toDate" class="form-control" placeholder="To" name="to" [minDate]="from" [maxDate]="todaysDate"
                  [disabled]="!from" [bsConfig]="bsConfig" [(ngModel)]="to" bsDatepicker required #dpicker2="bsDatepicker"
               />
               <span class="input-group-btn">
                  <button class="btn calendar-button" type="button" [disabled]="!from" (click)="dpicker2.show()">
                     <i class="fa fa-calendar" aria-hidden="true"></i>
                  </button>
               </span>
            </div>
         </div>

         <div class="col-md-3 search-button">
            <button type="button" class="btn btn-primary" [disabled]="f.invalid" (click)="onSearch();">Search</button>
         </div>
      </div>
   </form>

</div>

<app-display-container [show]="show">

   <div class="row" *ngIf="exceptionInfo.length">
      <div class="col-lg-4" *ngFor="let exception of exceptionInfo;let i = index;">
         <div class="box">
            <h4>Exception {{i}}</h4>
            <div class="exception-details table-responsive">
               <table class="table table-sm table-striped table-bordered table-hover">
                  <tbody>
                     <tr>
                        <th scope="row">Url</th>
                        <td>
                           <a href="{{exception?.logFile}}" target="_blank">Error Log</a>
                        </td>
                     </tr>
                     <tr>
                        <th scope="row">Trip start time</th>
                        <td>{{exception?.startTime || 'N/A'}}</td>
                     </tr>
                     <tr>
                        <th scope="row">Exception time</th>
                        <td>{{exception?.timestamp || 'N/A'}}</td>
                     </tr>
                     <tr>
                        <th scope="row">Threadname</th>
                        <td>{{exception?.threadName || 'N/A'}}</td>
                     </tr>
                     <tr>
                        <th scope="row">Driver ID</th>
                        <td>{{exception?.driverId || 'N/A'}}</td>
                     </tr>
                     <tr scope="row">
                        <th>Trip ID</th>
                        <td>{{exception?.tripId || 'N/A'}}</td>
                     </tr>
                     <tr scope="row">
                        <th>Device IMEI</th>
                        <td>{{exception?.device?.imei || 'N/A'}}</td>
                     </tr>
                     <tr scope="row">
                        <th>Version</th>
                        <td>{{exception?.sdk?.versionCode}} "/" {{exception?.sdk?.version}}</td>
                     </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
      <div class="paginator-wrap">
        <app-paginator3
           [(reset)]="resetPaginator"
           [pageLimit]="pageSize"
           [length]="totalExceptions"
           (pageChange)="getExceptionInfo($event)">
        </app-paginator3>
      </div>
   </div>


   <div class="no-content" *ngIf="!exceptionInfo.length">
      <h3>No Exceptions Found</h3>
   </div>

</app-display-container>
