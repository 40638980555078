
<div class="card-margin">
            <div class="disputed-table-card-wrap"  >
                    <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                        <table
                            class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
                        >
                        <div *ngIf="!showGraph" class ="display-img">
                                <img class="img-position" src="./../../../../../../assets/images/warning_2019-06-07/warning.png" />
                                <p class ="text-position">The fleet had no driving recorded during this period.</p>
                            </div>
                            <highcharts-chart
                                [Highcharts]="highcharts"
                                [options]="chartOptions"
                                [(update)]="updateChart"
                                [oneToOne]="true"
                            >
                            </highcharts-chart>
                        </table>
                    </div>
                </div>
</div>


<div class="card-margin">
    <div class="disputed-table-card-wrap">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table" style="padding-top: 30px; background-color: white">
                <table
                    class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
                >
                <div *ngIf="!showGraph" class ="incidents-display-img">
                        <img class="img-position" src="./../../../../../../assets/images/ok_2019-06-07/ok.png" />
                        <p class ="text-position">The fleet had no incidents recorded during this period.</p>
                    </div>
                    <highcharts-chart
                        [Highcharts]="highcharts"
                        [options]="incidentsChartOptions"
                        [(update)]="updateChart"
                        [oneToOne]="true"
                    >
                    </highcharts-chart>
                </table>
            </div>
        </div>
</div>

