import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as Highcharts from 'highcharts';

import { DISTRACTION_DISCLAMER } from '@modules/dashboard3/dashboard3.constants';
import { distractionFlag } from '@modules/dashboard3/dashboard3.models';

interface DriverScoreStat {
  earliestScore: number;
  latestScore: number;
  difference: number;
  sum: number;
  average: string; // in percentage, string format
}

@Component({
  selector: 'app-driver-score-item',
  templateUrl: './driver-score-item.component.html',
  styleUrls: ['./driver-score-item.component.scss'],
})
export class DriverScoreItemComponent implements OnInit {
  @Input()
  public driverIndex: number;
  @Input()
  public tripList: any[] = [];
  @Input()
  public numberOfDays;
  @Input()
  public showRange = true;
  @Input()
  public scoreTrend: [{ data: [string, number] }];
  @Input()
  public lineOption;
  @Input()
  public range;
  @Input()
  public isShowSkeletonLoading = false;

  public driver: any;
  public scoreStat: DriverScoreStat = {
    earliestScore: 0,
    latestScore: 0,
    difference: null,
    sum: 0,
    average: '',
  };
  public highcharts = Highcharts;
  public linecharts = Highcharts;
  public otherIndex = 4;
  public isDistractedDisclaimerFlag = false;
  public distractionDisclaimer = DISTRACTION_DISCLAMER;

  constructor(private _router: Router) {}

  public ngOnInit(): void {
    if (!this.isShowSkeletonLoading) {
      this.driver = this.tripList[this.driverIndex];
      if (this.driver.eventCount['Distracted-Driving'] && distractionFlag) {
        this.isDistractedDisclaimerFlag = true;
      }
      this.scoreStat = this.calculateScoreStat(
        this.scoreTrend['data'].map((item) => item && item[1])
      );
    }
  }

  public calculateScoreStat(scoreTrend: number[]): DriverScoreStat {
    if (!scoreTrend || scoreTrend.length <= 0) {
      return null;
    }
    const sum = scoreTrend.reduce((a, b) => a + b, 0);
    return {
      earliestScore: scoreTrend[0],
      latestScore: scoreTrend[scoreTrend.length - 1],
      difference: scoreTrend[scoreTrend.length - 1] - scoreTrend[0],
      sum,
      average: (sum / (scoreTrend.length * 100)).toFixed(2),
    };
  }

  public gotoDriverDetail(driver) {
    if (driver && driver.driverId) {
      this._router.navigate(['/driver-detail'], {
        queryParams: {
          driverId: driver.driverId,
          driverName: driver.driverName,
        },
      });
    }
  }
}
