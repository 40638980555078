<div *ngIf="!isExportSet" class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)">
  <div class="search-filter-content-location">
    <div class="location-mobile" *ngIf="showHeader">
      <span class="heading">Home Location</span>
    </div>
    <app-search-filter-item
      [isLoading]="isLoading"
      [inputData]="divisionList"
      [inputSettings]="locationSettings"
      [isGetSelectedItems]="true"
      [selectedList]="locationSelectedList"
      [clickOutSideReloadData]="true"
      (selectChange)="onSelectLocation($event)"
      (filterChange)="onFilterChange()"
      (toggleDropdownEvent)="onFilterChange($event)">
    </app-search-filter-item>
  </div>
</div>

<!-- Export modal filter code -->
<div *ngIf="isExportSet" class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)">
  <div>
    <div class="filter-choice" *ngIf="isExportSet">
      <div class="filter-element" [class.active]="isOpen" (click)="toggleFilter()">
        <div class="filter-heading">Home Location</div>

        <div class="content-row">
          <span class="filter-content">{{ displayHomeLocation }} </span>
          <span *ngIf="isOpen" class="arrow-up" (click)="toggleFilter($event)"></span>
          <span *ngIf="!isOpen" class="arrow-down" (click)="toggleFilter($event)"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="drop-down score-dropdown" [class.show]="isOpen">
    <div class="drop-down-content">
      <span class="heading">Home Location</span>
      <!-- home location dropdown area -->
      <div class="home-location-container" *ngIf="showHomeLocationDropdown">
        <div>
          <div class="home-location-dropdown">
            <div class="dropdown-container">
              <div class="dropdown-label" role="button" aria-label="visit pages" role="link" (click)="toggleDropdown()"
                [ngClass]="isDropdownOpen ? 'arrow-upside' : 'arrow-downside'">
                {{ displayHomeLocation }}
              </div>
              <div class="dropdown-body" *ngIf="isDropdownOpen">
                <ul class="dropdown-nav">
                  <ng-container *ngFor="let homeLocation of uniqueHomeLocationList">
                    <li>

                      <button (click)="getSelectedHomeLocation(homeLocation)"
                        [ngClass]="{ 'dropdown-btn-active': displayHomeLocation === homeLocation?.locationName }"
                        class="no-btn dropdown-nav-btn">{{ homeLocation?.locationName }}
                      </button>

                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="home-location-action">
        <button type="submit" class="btn btn-home-location" (click)="submitHomeLocation(); applyFilter()">Apply</button>
      </div>

    </div>
  </div>
</div>
