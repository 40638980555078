import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { finalize, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { createMomentObjectUtc } from '@modules/shared/utils';
import { EVENTS_INCIDENT_TYPE } from '@app-core/constants/constants';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { CommentModalComponent } from '../custom-modal/comment-modal/comment-modal.component';
import { EventTripMetadata, RemoveModalComponent } from '../custom-modal/remove-modal/remove-modal.component';

export interface BookmarkInfo {
  eventIndex: number;
  bookmark: boolean;
  comments?: any[];
  tripId?: string;
  driverId?: string;
  displayTimeZone?: string;
  status?: string;
  violationIdentifier?: string;
  disputeTimeMoment?: string;
  timestampUTC?: string;
  eventType?: string;
}

export interface ViolationIdentifier {
  violationIdentifier: string;
}

export interface LoadingEvent {
  eventIndex: number;
  isLoading: boolean;
}

const ADD_BOOKMARK_FAILED = 'Can\'t bookmark violation.\nDriver ID: ';

@Component({
  selector: 'app-comment-button-group',
  templateUrl: './comment-button-group.component.html',
  styleUrls: ['./comment-button-group.component.scss'],
})
export class CommentButtonGroupComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public bookmarkInfo: BookmarkInfo;

  @Input()
  public isMomentObjCreated = false;

  @Input()
  public showSpinner = true;

  @Input()
  public loadingChange$: Observable<LoadingEvent>;

  @Input()
  public isSelfUpdate = false;

  @Output()
  public tripMetadataChange: EventEmitter<BookmarkInfo> = new EventEmitter();

  @Output()
  public clickButtonChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public violationIdentifierChange: EventEmitter<ViolationIdentifier> = new EventEmitter();

  public bsModalRef: BsModalRef;
  public isValidating: Record<string,boolean> = {};
  public isUpdateIncidentBookmark = false;

  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private _zcFleet: ZCFleetService,
    private _modalService: BsModalService,
    private _snackbarService: SnackbarService
  ) {}

  public ngOnInit(): void {
    if (this.showSpinner) {
      this.loadingChange$.pipe(
        takeUntil(this._destroy$),
        tap((res) => this.isValidating[res.eventIndex] = res.isLoading)
      ).subscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.bookmarkInfo
          && changes.bookmarkInfo.currentValue
          && this.bsModalRef
          && this.bsModalRef.content
    ) {
      this.bsModalRef.content.commentList = this._addDisplayAuthorComment((changes.bookmarkInfo.currentValue.comments || []).slice());
    }
  }

  public ngOnDestroy() {
    // Close modal when leaving the page
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * @description: function show comment incident component
   */
  public openModalCommentIncident(item, event: MouseEvent): void {
    const violationIdentifier = item.tripId + '-' + item.eventIndex;
    const initialState = {
      timeMoment: (this.isMomentObjCreated
        ? item.disputeTimeMoment
        : createMomentObjectUtc(item.timestampUTC).tz(item.displayTimeZone)
      ).format('MM/DD/YYYY HH:mm z'),
      commentList: this._addDisplayAuthorComment(item.comments && item.comments.slice() || []),
      violationIdentifier,
      eventType:
        EVENTS_INCIDENT_TYPE.find((x) => x[0] === item.eventType)[1] || '',
      driverId: item.driverId,
      bookmark: item.bookmark,
      onNewCommentSubmitted: this.onNewCommentSubmitted.bind(this),
    };

    this.bsModalRef = this._modalService.show(CommentModalComponent, { initialState });

    this.bsModalRef.content.isValidating.subscribe(result => {
      this.isValidating[item.eventIndex] = result;
    });

    /**
     * Update status read comment
     * param: violationIdentifier, body status read comment
     */
    if (item.comments && item.comments.length > 0) {
      this._zcFleet.updateCommentStatus(violationIdentifier, { read: true }).subscribe(
        () => {
          if (this.isSelfUpdate) {
            this.bookmarkInfo.comments.forEach(
              comment => comment['unread'] = false
            );
          }
        }, (error) => {
          console.log(error);
        }
      );
    }

    /**
     * Emit information comment
     */
    this.violationIdentifierChange.emit({
      violationIdentifier,
    });

    /**
     * Here is the code used when using button
     * returns true when the button is clicked
     * returns false when hide instance method has been called
     */
    this.clickButtonChange.emit(true);
    this._modalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        this.clickButtonChange.emit(false);
      });
    event.stopPropagation();
  }

  public updateIncidentBookmark(item: BookmarkInfo, event: MouseEvent): void {
    this.isUpdateIncidentBookmark = true;
    const bookmark = item.bookmark;
    const violationIdentifier = item.tripId + '-' + item.eventIndex;
    const bodyTripMetadata: EventTripMetadata = {
      driverId: item.driverId,
      bookmark: !bookmark,
    };
    const initialState = {
      bodyTripMetadata,
      violationIdentifier,
    };

    if (bookmark) {
      this.openModalRemoveIncident(initialState, item);
    } else {
      this.addBookmarkToIncident(violationIdentifier, bodyTripMetadata, item.eventIndex);
    }
    event.stopPropagation();
  }

  /**
   * @description: function show modal save incident component
   * @param: initialState info event metadata
   */
  public openModalRemoveIncident(initialState: Record<string, unknown>, event): void {
    this.bsModalRef = this._modalService.show(RemoveModalComponent, { initialState });
    this.bsModalRef.content.isValidating.subscribe(result => {
      this.isValidating[event.eventIndex] = result;
    });

    this.bsModalRef.content.updateStatusBookmark.subscribe(result => {
      if (result !== undefined) {
        this.tripMetadataChange.emit({
          bookmark: result,
          eventIndex: event.eventIndex,
          tripId: event.tripId,
          status: 'updateBookmark',
        });
        // self update data
        if (this.isSelfUpdate) {
          this.bookmarkInfo.bookmark = result;
        }
      }
      this.isUpdateIncidentBookmark = false;
    });

    /**
     * Here is the code used when using button
     * returns true when the button is clicked
     * returns false when hide instance method has been called
     */
    this.clickButtonChange.emit(true);
    this._modalService.onHide
      .pipe(take(1))
      .subscribe(() => {
        this.clickButtonChange.emit(false);
      });
  }

  /**
   * @description: function add bookmark to save incident component
   * @param: violationIdentifier, bodyTripMetadata
   */
  public addBookmarkToIncident(violationIdentifier: string, bodyTripMetadata: EventTripMetadata, eventIndex: number): void {
    if (!this.isValidating[eventIndex]) {
      this.isValidating[eventIndex] = false;
    }
    this.isValidating[eventIndex] = true;
    this._zcFleet.updateEventTripMetadata(violationIdentifier, bodyTripMetadata).pipe(
      finalize(() => {
        this.isUpdateIncidentBookmark = false;
      })
    ).subscribe(
      () => {
        this.tripMetadataChange.emit({
          bookmark: bodyTripMetadata.bookmark,
          eventIndex,
          violationIdentifier,
        });

        // self update data
        if (this.isSelfUpdate) {
          this.bookmarkInfo.bookmark = bodyTripMetadata.bookmark;
        }

        /** Information for not display toaster
         * https://jira.zonarsystems.net/browse
         * /ZCW-2382?focusedCommentId=475520&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-475520
         */
        // this._toast.success('Add bookmark successfully');
      },
      _err => {
        this._snackbarService.error(ADD_BOOKMARK_FAILED + bodyTripMetadata.driverId);

        this.tripMetadataChange.emit({
          bookmark: !bodyTripMetadata.bookmark,
          eventIndex,
        });

        // self update data
        if (this.isSelfUpdate) {
          this.bookmarkInfo.bookmark = !bodyTripMetadata.bookmark;
        }
      }
    ).add(() => this.isValidating[eventIndex] = false);
  }

  public checkStatusUnreadComment(comments): number {
    return (comments || []).map(comment => comment.unread).filter(item => item).length;
  }

  public onNewCommentSubmitted() {
    const violationIdentifier = this.bookmarkInfo.tripId + '-' + this.bookmarkInfo.eventIndex;
    this._zcFleet.getSingleCommentByViolationIdentifier(violationIdentifier).subscribe(res => {
      this.bsModalRef.content.commentList = this._addDisplayAuthorComment((res || []).slice());
    });
  }

  private _addDisplayAuthorComment(comments): Record<string, unknown> {
    for (const comment of comments) {
      comment.displayAuthor = comment.userType === 'FLEET_DRIVER' ? comment.author + ' (Driver)' : comment.author;
    }

    if (this.isSelfUpdate) {
      this.bookmarkInfo.comments = comments;
    }
    return comments;
  }
}
