<div class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)"  >

<div>
    <div *ngIf="!isExportSet">
        <fieldset class="dropdown-head" [class.active]="isOpen" (click)="toggleFilter()">
            <legend>Date range</legend>
            <div class="date-filter-dropdown">
                <span class="label">{{rangeText}}</span>
                <span class="range" *ngIf="showRange">{{range?.from | date:'MM/dd'}} - {{range?.to | date:'MM/dd'}}</span>
                <span class="range" *ngIf="!showRange">{{range?.from | date:'MM/dd'}}</span>
                <span class="arrow-down" *ngIf="!isOpen" (click)="toggleFilter($event)"></span>
                <span class="arrow-up" *ngIf="isOpen" (click)="toggleFilter($event)"></span>
            </div>
        </fieldset>
    </div>
    <div class="filter-choice" *ngIf="isExportSet" >
            <div class="filter-element " (click)="toggleFilter()">
                    <div class="filter-heading">Date</div>
                    <div class="content-row">
                        <span class="range" *ngIf="showRange">{{range?.from | date:'MM/dd'}} - {{range?.to | date:'MM/dd'}}</span>
                        <span class="range" *ngIf="!showRange">{{range?.from | date:'MM/dd'}}</span>
                        <span *ngIf="isOpen" class="arrow-up"></span>
                        <span *ngIf="!isOpen" class="arrow-down"></span>
                    </div>
                </div>
    </div>
</div>

    <div class="drop-down date-dropdown" [class.show]="isOpen">
        <div class="drop-down-content">
            <span class="heading">Dates</span>
            <div class="options-group">
                <button
                    class="btn btn-option"
                    *ngFor="let _date of dateRangeList"
                    [class.active]="selectedRange === _date.value"
                    (click)="selectDate(_date.value)">
                        {{_date.lable}}
                </button>
            </div>
            <div [hidden]= "!showCalendar" style="padding-top: 20px" >
                <ngx-daterangepicker-material
                [autoApply]="true"
                [maxDate]="today"
                [minDate]="minDate"
                (choosedDate)="dateRangeSelected($event)"
                [locale]="{format: 'MM-DD-YYYY'}"
                 #datePicker>
                </ngx-daterangepicker-material>
            </div>

        </div>
        <div *ngIf="(selectedRange === 2 )"   class="dropdown-apply desktop">
            <a (click)="applyFilter()" [hidden]="(!showApply || !((datePicker.startDate && datePicker.endDate )))">Apply</a>
    </div>

    <div *ngIf="selectedRange !== 2" class="dropdown-apply desktop">
        <!-- <a (click)="applyFilter()" [hidden]="!showApply ">Apply</a> -->
    </div>
</div>
