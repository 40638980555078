import { Component, Input, Output, EventEmitter, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { SortDirection } from '@angular/material/sort/sort-direction';

import { merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {
  ColDescription,
  PaginationData,
} from '@modules/shared/components/table/table.component';
import { IncidentData } from '../incident-media-control/incident-media-control.component';

export interface PageChange {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-incident-media-table-wrapper',
  templateUrl: './incident-media-table-wrapper.component.html',
  styleUrls: ['./incident-media-table-wrapper.component.scss'],
})
export class IncidentMediaTableWrapperComponent implements OnInit, AfterViewInit {

  @ViewChild('hyperlinkDriver') public hyperlinkDriverTemplate: TemplateRef<any>;
  @ViewChild('hyperlinkAssetName') public hyperlinkAssetNameTemplate: TemplateRef<any>;

  // table component's input/output
  @Input() public sortHeader = ['disputeTimeMoment'];
  @Input() public displayedColumns = [
    'disputeTimeMoment',
    '_eventType',
    'assetName',
    'driverName',
    'action',
  ];

  @Input() public set dataSource$(value: Observable<PaginationData<any>>) {
    this.internalDataSource$ = value.pipe(
      map( res => {
        if (!res.data && this.isDataLoading) {
          this.isDataLoading = false;
          return true;
        } else if (!this.isDataLoading || res.data) {
          return res;
        }
      }),
      shareReplay());
  }
  @Input() public set reload$(value: Observable<boolean>) {
    const state = merge(value, this.internalDataSource$);
    const mapper = map(mergeValue => (mergeValue !== true && mergeValue !== undefined));
    this.notLoadingState$ = state.pipe(mapper);
  }
  @Input() public sortOrderChange$: Observable<[string, 'desc' | 'asc']>;
  @Input() public customTemplates: Record<string, TemplateRef<any>> = {};
  @Input() public customTemplatesList: string[] = [];
  @Input() public colDescriptions: ColDescription[];
  @Input() public isDisabledAction = false;

  @Input() public pageSize = 6;
  @Input() public dummyLoadData = [];
  @Input() public noMediaText = 'Please select an incident to display media';

  @Output() public rowSelected: EventEmitter<Record<string, any>> = new EventEmitter();
  @Output() public pageChange: EventEmitter<PageChange> = new EventEmitter();
  @Output() public sortChange: EventEmitter<[string, SortDirection]> = new EventEmitter();

  // media-control component input/output
  @Input() public incidentData: IncidentData = {};
  @Input() public selectedIncident;
  @Input() public showSmallSizeMediaPanel = false;
  @Input() public switch: boolean;
  @Input() public disableRowExpand = true;
  @Output() public challengeUpdated = new EventEmitter<any>();
  @Output() public isDisabledClickEvent = new EventEmitter<boolean>();

  public customTemplatesObj: Record<string, TemplateRef<any>> = {};

  public notLoadingState$: Observable<boolean>;
  public internalDataSource$: Observable<any>;
  public isDataLoading = true;

  constructor() {
    this.dummyLoadData = this._createDummyData();
  }

  @Input() public getUniqueKey: (element: any) => string = (element: any) => JSON.stringify(element);

  public ngOnInit() {}

  public ngAfterViewInit(){
    // Need to call afterViewInit() for templates to work
    // Can not get templateRef on OnInit()
    this._initCustomTemplates();
  }

  public setMedia($event) {
    this.rowSelected.emit($event);
  }

  public sortChangeInternal($event) {
    this.sortChange.emit($event);
  }

  public updatePage($event) {
    this.pageChange.emit($event);
  }

  public isDisabledClick($event) {
    this.isDisabledClickEvent.emit($event);
  }

  public updateIncidentList($event) {
    this.challengeUpdated.emit($event);
  }

  public setDriverName(driverName: string) {
    // flexible logic to handle driver names if needed
    return driverName;
  }

  // TO-DO: ZCW-2840 update dummy data
  private _createDummyData() {
    const result = [];
    for (let i = 0; i < this.pageSize; i ++) {
      result.push({
        driverId: i,
        driverName: i,
        lastTrip: i,
        totalTrip: i,
        totalIncident: i,
      });
    }
    return result;
  }

  private _initCustomTemplates() {
    if (this.customTemplatesList.length) {
      this.customTemplatesList.forEach(
        item => {
          this.customTemplatesObj[item] = this._getCustomTemplates(item);
        }
      );
    }
    // Merging shared templates from wrapper component and specific templates from parent component
    this.customTemplatesObj = Object.assign(this.customTemplatesObj, this.customTemplates);
  }

  private _getCustomTemplates(item: string) {
    switch (item) {
      case 'driverName':
        return this.hyperlinkDriverTemplate;
      case 'assetName':
        return this.hyperlinkAssetNameTemplate;
      default:
        console.log('Template not found');
        break;
    }
  }
}
