<div class="uc-dropdown-wrap">
  <div class="uc-driver-search">
      <img class="uc-search-icon" src="assets/images/search.png">
      
      <div appDocumentClick (clickedInside)="clickedInside($event)">
        <input type="text"
            appInputPatternCheck
            class="uc-input-control"
            placeholder="IMEIs"
            maxlength="15"
            [(ngModel)]="searchKey"
            (focus)="onFocus()"
            (keydown.enter)="onEnter()" />
      </div>
      <span *ngIf="searchKey && searchKey !== ''" class="uc-close-icon" (click)="cleanInput()">
        &#215; <!-- This will render the 'X' -->
      </span>
  </div>
</div>
