import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputPatternCheck]',
})
export class InputPatternCheckDirective {

  @Input() public set regexPattern(value: string) {
    this._regex = new RegExp(value);
  };

  private _regex = new RegExp('^([0-9]|\b){1,15}$');
  private _excludeKeys = ['Backspace', 'Enter', 'Up', 'ArrowUp', 'Left', 'ArrowLeft', 'Right', 'ArrowRight', 'Down', 'ArrowDown'];

  constructor() { }

  @HostListener('keydown', ['$event'])
  public handleKeyDown(evt: KeyboardEvent) {
    if (this._excludeKeys.includes(evt.key)) {
      return true;
    }
    return this._regex.test(evt.key);
  }

  @HostListener('paste', ['$event'])
  public handlePasteData(evt: ClipboardEvent) {
    evt.preventDefault();
  }

}
