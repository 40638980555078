import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SharedModule } from '@modules/shared/shared.module';
import { Dashboard3Module } from '@modules/dashboard3/dashboard3.module';

import { PackageConfigComponent } from '@modules/package-config/pages/package-config/package-config.component';

import { PackageConfigService } from '@modules/package-config/services/package-config.service';
import { AccountConfigComponent } from './components/account-config/account-config.component';
import { AssetConfigComponent } from './components/asset-config/asset-config.component';

@NgModule({
  imports: [
    TypeaheadModule.forRoot(),
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    Dashboard3Module,
  ],
  declarations: [PackageConfigComponent, AccountConfigComponent, AssetConfigComponent],
  providers: [PackageConfigService],
})
export class PackageConfigModule {}
