import { Component } from '@angular/core';

import { AccessService } from '@app-core/zcw-auth-module/services/access.service';
import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private _auth: ZcwAuthenticateService,
    public access: AccessService
  ) {}

  public logout() {
    this._auth.logout();
  }
}
