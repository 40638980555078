import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { merge, Observable, of } from 'rxjs';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';

import {
  Data,
  DRIVER_AVG_SCORE_GRAPH_OPTION,
  DRIVER_INCIDENTS_GRAPH_OPTION,
} from '@modules/dashboard3/services/data.service';
import { INCIDENTS_CHART_LEGENDS } from '@modules/dashboard3/dashboard3.models';
import { catchError, map } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

const legends = INCIDENTS_CHART_LEGENDS;

interface DriverScoreGraph {
  fleetTrend: any;
  driverTrend: any;
  dateArray: any;
  updateChart: boolean;
}

@Component({
  selector: 'app-driver-score-graph',
  templateUrl: './driver-score-graph.component.html',
  styleUrls: ['./driver-score-graph.component.scss'],
})
export class DriverScoreGraphComponent implements OnInit, OnDestroy {
  @Input() public driverName$: Observable<string>;
  @Input() public set trendGraphChange$(value: Observable<DriverScoreGraph>) {
    if (!value) {
      return;
    }
    this.internalTrendGraphData$ = value.pipe(
      catchError(() => {
        this.isShowNoData = true;
        return of<DriverScoreGraph>(undefined);
      })
    );
    this.driverGraphOptions$ = value.pipe(
      map((graphValue) => {
        const chartOptions = cloneDeep(DRIVER_AVG_SCORE_GRAPH_OPTION);

        if (!graphValue) {
          this.isShowNoData = true;
          this.updateScoreChart = true;
          return chartOptions;
        }

        this.isShowNoData = false;
        this.updateScoreChart = true;
        return this.getDriverTrendGraph(graphValue, chartOptions);
      })
    );

    this.trendGraphOptions$ = value.pipe(
      map((graphValue) => {
        const chartOptions = cloneDeep(DRIVER_INCIDENTS_GRAPH_OPTION);
        if (!graphValue) {
          this.isShowNoData = true;
          this.updateIncidentChart = true;
          return chartOptions;
        }
        this.isShowNoData = false;
        this.updateIncidentChart = true;
        return this.getFleetTrendGraph(graphValue, chartOptions);
      })
    );
  }
  @Input() public set reload$(value: Observable<boolean>) {
    const merger = merge(value, this.internalTrendGraphData$);
    this.notLoadingState$ = merger.pipe(map((res) => res !== true));
  }

  public internalTrendGraphData$: Observable<DriverScoreGraph>;
  public driverGraphOptions$: Observable<any>;
  public trendGraphOptions$: Observable<any>;
  public notLoadingState$: Observable<boolean>;

  public loadComponent = false;
  public isShowSkeleton = true;
  public isShowNoData = false;
  public driverId;
  public driverName: string;
  public driverFirstName;
  public queryParamSubscription;
  public filterChangeSubscription;
  public filterOptionSubscription;
  public highcharts = Highcharts;
  public chartOptions = DRIVER_AVG_SCORE_GRAPH_OPTION;
  public chartOptionsSkeleton = cloneDeep(DRIVER_AVG_SCORE_GRAPH_OPTION);
  public incidentsChartOptions = DRIVER_INCIDENTS_GRAPH_OPTION;
  public incidentsChartOptionsSkeleton = cloneDeep(
    DRIVER_INCIDENTS_GRAPH_OPTION
  );
  public updateScoreChart = false;
  public updateIncidentChart = false;
  public updatedFleetTrend = false;
  public date = {
    from: null,
    to: null,
  };

  constructor(private _data: Data, private _route: ActivatedRoute) {}

  public ngOnDestroy(): void {
    this.queryParamSubscription.unsubscribe();
  }

  public ngOnInit() {
    this.queryParamSubscription = this._route.queryParams.subscribe(
      (params) => {
        this.driverId = params.driverId;
        this.driverName = params.driverName;
        this.driverFirstName = this.driverName
          ? this.driverName.split(' ')[0]
          : '';
        this._updateDriverName();
      }
    );
    this.driverName$.subscribe((res) => {
      this.driverName = res;
      this._updateDriverName();
    });
  }

  public getDriverTrendGraph(graphValue: DriverScoreGraph, chartOptions: any) {
    if (graphValue.driverTrend && graphValue.driverTrend.length) {
      const driverAvgScore = this._data.formatData(
        graphValue.driverTrend,
        graphValue.dateArray
      );
      chartOptions.series[1].data = driverAvgScore.map((ele) => ele.score);
      chartOptions.xAxis.categories = driverAvgScore.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
      chartOptions.series[1].name = this.driverName + '\'s Score';
      const fleetAvgScore = this._data.formatData(
        graphValue.fleetTrend,
        graphValue.dateArray
      );
      chartOptions.series[0].data = fleetAvgScore.map((ele) => ele.score);
      chartOptions.xAxis.categories = fleetAvgScore.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
    } else {
      chartOptions.yAxis.min = 0;
      chartOptions.series[0].data = [];
      // this.chartOptions.series[0].data = this.chartOptionsSkeleton.series[0].data;
      for (
        let i = 0;
        i < graphValue.dateArray ? graphValue.dateArray.length : 0;
        i++
      ) {
        // assigning -1 when no data so that gridlines are plotted
        chartOptions.series[0].data[i] = -1;
      }
      chartOptions.series[1].data = [];
      chartOptions.xAxis.categories = graphValue.dateArray.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
      chartOptions.series[1].name = this.driverName + '\'s Score';
    }
    return chartOptions;
  }

  public getFleetTrendGraph(
    graphValue: DriverScoreGraph,
    incidentsChartOptions: any
  ) {
    if (graphValue.driverTrend && graphValue.driverTrend.length) {
      const driverAvgScore = this._data.formatData(
        graphValue.driverTrend,
        graphValue.dateArray
      );
      for (let i = 0; i < incidentsChartOptions.series.length; i++) {
        incidentsChartOptions.series[i].data = driverAvgScore.map(
          (ele) => ele.eventCount[legends[i]]
        );
      }
      incidentsChartOptions.xAxis.categories = driverAvgScore.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
    } else {
      for (let i = 0; i < incidentsChartOptions.series.length || 0; i++) {
        incidentsChartOptions.series[i].data =
          this.incidentsChartOptionsSkeleton.series[i].data;
        for (let j = 0; j < graphValue.dateArray.length; j++) {
          // assigning -1 when no data so that gridlines are plotted
          incidentsChartOptions.series[i].data[j] = -1;
        }
      }
      incidentsChartOptions.xAxis.categories = graphValue.dateArray.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
      incidentsChartOptions.yAxis.min = 0;
      incidentsChartOptions.yAxis.max = 20;
    }
    return incidentsChartOptions;
  }

  /**
   * @description: function to clear previous highchart values
   * @param:
   */
  public clearPreviousValues() {
    this.incidentsChartOptions.yAxis.min = null;
    this.incidentsChartOptions.yAxis.max = null;
    this.chartOptions.yAxis.min = null;
    this.chartOptions.series[0].data = [25];
    this.chartOptions.series[1].name = '';
    for (let i = 0; i < this.incidentsChartOptions.series.length || 0; i++) {
      this.incidentsChartOptions.series[i].data = [];
    }
    for (let i = 0; i < this.chartOptions.series.length || 0; i++) {
      this.chartOptions.series[i].data = [];
    }
    this.loadComponent = false;
    this.updateScoreChart = true;
    this.updateIncidentChart = true;
    this.isShowSkeleton = true;
    this.isShowNoData = false;
  }

  private _updateDriverName() {
    if (this.isShowSkeleton) {
      this.chartOptions.series[1].name = '';
    } else {
      this.chartOptions.series[1].name = this.driverName + '\'s Score';
    }
  }
}
