import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { SnackbarService } from '@app-core/services/snackbar.service';
import { EventTripMetadata } from '../remove-modal/remove-modal.component';
import { createMomentObjectUtc } from '@modules/shared/utils';
import { ConfigurationService } from '@app-core/services/configuration.service';
import { StorageService } from '@app-core/services/storage.service';
import { emptyStringValidator } from '@app-core/validators/emptyString.validator';

const ERROR_LIMIT_MGS = 'This violation has reached the maximum number of comments';
const ERROR_SAVING_MSG = 'Error saving comment. Please try again';
const ERROR_EMPTY_MSG = 'Blank comment. Please write something and try again';
// handle all incidents pending comment list
const LOCAL_PENDING_COMMENT_LIST = 'error_modal_comment_obj';
const UNLIMITED_COMMENT_NUMBER = -1;

export interface CommentToDriver {
  userType: string;
  text: string;
  author: string;
  displayAuthor?: string;
  timestamp?: string;
  disputeTimeMoment?: string;
  unread?: boolean;
  errorStatus?: string;
  retryCreateComment?: boolean;
}

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss'],
})
export class CommentModalComponent implements OnInit, OnDestroy {
  public maxNumberOfCharacters = 140;
  public maxCommentPerConversation: number;
  public unlimitedComments = false;
  public timeMoment;
  public violationIdentifier: string;
  public isValidating: Subject<boolean> = new Subject<boolean>();
  public commentList: CommentToDriver[] = [];
  public pendingCommentList: CommentToDriver[] = [];
  public isCommentListPending = false;
  public eventType: string;
  public driverId: string;
  public bookmark: boolean;
  public isErrorComment = false;
  public getPendingCommentLists = this._storage.getStorageValue(LOCAL_PENDING_COMMENT_LIST);
  public error = {
    noConnection: 'error-no-connection',
    reachLimit: 'error-reached-limit',
    server: 'error-server',
  };
  public isOpened = true;
  public onNewCommentSubmitted: () => void;
  public textErrorMsg = ERROR_SAVING_MSG;

  public formGroup;

  private _username = '';
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  public get calMaxCommentPerConversation(): number {
    return this.commentList.length > this.maxCommentPerConversation ?
      this.commentList.length : this.maxCommentPerConversation;
  }

  public get remainingNumberOfComment(): number {
    this.isCommentListPending = !!this.pendingCommentList.length &&
      this.pendingCommentList.every(item => item.errorStatus !== this.error.reachLimit);

    let remainNumber = this.calMaxCommentPerConversation - this.commentList.length;

    // if maximum is reached, show note about maximum reached,
    // regardless how many pending comment remains
    if (remainNumber === 0) {
      this.isCommentListPending = false;
    }

    const isCommentPending = !!this.pendingCommentList.find(
      item => !item.errorStatus || item.errorStatus === this.error.noConnection);

    // ZCW-2584 case 1: If the comment being posted is the last one under the comment limit,
    // remove the text box while the sync icon is shown
    remainNumber = (remainNumber === 1 && isCommentPending) ? 0 : remainNumber;

    return remainNumber;
  }

  public get currentCommentList(): CommentToDriver[] {
    this._addDisputeTimeMoments(this.commentList);
    return this.commentList || [];
  }

  public get currentPendingCommentList(): CommentToDriver[] {
    this._addDisputeTimeMoments(this.pendingCommentList);
    return this.pendingCommentList || [];
  }

  constructor(
    public bsModalRef: BsModalRef,
    private _auth: ZcwAuthenticateService,
    private _zcFleet: ZCFleetService,
    private _snackbarService: SnackbarService,
    private _configurationService: ConfigurationService,
    private _storage: StorageService
  ) {
    this._configurationService.getViolationConfigurationSettings().pipe(
      tap((res) => {
        this.unlimitedComments = res.maximumCommentPerConversation === UNLIMITED_COMMENT_NUMBER;
        this.maxCommentPerConversation = res.maximumCommentPerConversation;
        this._storage.setStorageValue('max-comment-per-conversation', this.maxCommentPerConversation);
        this.maxNumberOfCharacters = res.maximumCommentLength;
      }),
      // when error, set max comment to the latest value stored in local storage if have
      // local storage value will be removed after modal closed
      catchError(() => of(
        this.maxCommentPerConversation = this._storage.getStorageValue('max-comment-per-conversation') || this.maxCommentPerConversation
      ))
    ).subscribe();
  }

  public ngOnInit(): void {
    this._auth.userProfile$
      .pipe(
        map((userProfile) => {
          this._username = userProfile
            ? userProfile.email
            : '';
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
    this.formGroup = new FormGroup({
      comments: new FormControl('', {
        validators: [Validators.required, emptyStringValidator],
      }),
    });
    this._getErrorCommentListStorage();
  }

  public ngOnDestroy(): void {
    this.isOpened = false;
    this.isErrorComment = false;
    this._destroy$.next(true);
    this._destroy$.unsubscribe();
  }

  public get comments() {
    return this.formGroup.get('comments');
  }

  public buildCommentToDriver(comments: string, index: number = undefined): void {
    if (this.comments.hasError('isEmpty') && isNaN(index)) {
      this.isErrorComment = true;
      this.textErrorMsg = ERROR_EMPTY_MSG;
      this.comments.reset();
      return null;
    }
    // when click try again, check comment index if it is already retried or not
    if (index !== undefined && this.pendingCommentList[index].retryCreateComment) {
      return null;
    } else if (index !== undefined) {
      this.pendingCommentList[index].retryCreateComment = true;
    }
    this.isErrorComment = false;
    const formBookmark: EventTripMetadata = {
      driverId: this.driverId,
      bookmark: !this.bookmark,
    };
    const formComment: CommentToDriver =  {
      userType: 'FLEET_OPERATOR',
      text: comments,
      author: this._username,
    };
    // build new comment if index undefined
    if (index === undefined) {
      formComment.errorStatus = undefined;
      formComment.retryCreateComment = false;
      this.pendingCommentList.push({
        ...formComment,
        displayAuthor: this._username,
      });
      this._setErrorCommentListStorage();
      // reset text area and comment value
      this.formGroup.controls['comments'].reset();
    }

    this.isValidating.next(true);
    this._createCommentToDriver(formComment, formBookmark)
      .pipe(finalize(() => {
        this.isValidating.next(false);
        this._setErrorCommentListStorage();
        this.onNewCommentSubmitted();
      }))
      .subscribe(
        () => {
          this._snackbarService.success(
            `Success saving comment for ${this.eventType} at ${this.timeMoment}`,
            'Close'
          );
          // after comment added successfully, remove target comment from pendingCommentList
          if (index === undefined) {
            // the latest comment added to pending comment list
            this.pendingCommentList.pop();
          } else {
            // pending comment list with index
            this.pendingCommentList = this.pendingCommentList.filter(
              (item, itemIndex) => !this._isCommentEqual(item, formComment, itemIndex, index)
            );
          }
        },
        _err => {
          // assign error status
          const errorStatus = this._getErrorStatus(_err);
          // find result will always defined because formComment always pushed to pendingCommentList
          if (index === undefined) {
            // select latest comment on the pending list
            this.pendingCommentList[this.pendingCommentList.length - 1].retryCreateComment = false;
            this.pendingCommentList[this.pendingCommentList.length - 1].errorStatus = errorStatus;
          } else {
            this.pendingCommentList[index].retryCreateComment = false;
            this.pendingCommentList[index].errorStatus = errorStatus;
          }

          if (errorStatus === this.error.server) {
            this.isErrorComment = true;
            this.textErrorMsg = ERROR_SAVING_MSG;
            this.formGroup.setValue({
              comments: formComment.text,
            });
            // remove error server comments from pending comment list
            this.pendingCommentList = this.pendingCommentList.filter(item =>
              item.errorStatus !== this.error.server);
          }
          if (!this.isOpened) {
            this._snackbarService.error( _err.error.message ||
              `Error saving comment for ${this.eventType} at ${this.timeMoment}`,
            'Try Again'
            ).subscribe(() => {
              this._createCommentToDriver(formComment, formBookmark);
            });
          }
        }
      );
  }

  private _createCommentToDriver(formComment: CommentToDriver, formBookmark: EventTripMetadata): Observable<any>{
    if (this.bookmark) {
      return this._zcFleet.createCommentToDriver(this.violationIdentifier, formComment);
    } else {
      return this._zcFleet.updateEventTripMetadata(this.violationIdentifier, formBookmark).pipe(
        switchMap(() => this._zcFleet.createCommentToDriver(this.violationIdentifier, formComment))
      );
    }
  }

  private _addDisputeTimeMoments(timeMoments): Record<string, unknown> {
    return timeMoments.forEach(timeMoment => {
      if (!timeMoment.disputeTimeMoment) {
        timeMoment.disputeTimeMoment = createMomentObjectUtc(timeMoment.timestamp)
          .tz('Etc/GMT+7')
          .format('MM/DD/YYYY, HH:mm z');
      }
    });
  }

  private _isCommentEqual(comment1, comment2, index1?, index2?): boolean {
    return (
      comment1.author + comment1.text + index1 ===
      comment2.author + comment2.text + index2
    );
  }

  private _setErrorCommentListStorage() {
    this.pendingCommentList = this.pendingCommentList.filter(
      item => !(item.errorStatus === this.error.server)
    );
    // get data stored on local storage
    const errorModalCommentObj = this.getPendingCommentLists || {};
    errorModalCommentObj[this.violationIdentifier] = this.pendingCommentList;
    this._storage.removeStorageValue(LOCAL_PENDING_COMMENT_LIST);
    this._storage.setStorageValue(LOCAL_PENDING_COMMENT_LIST, errorModalCommentObj);
  }

  private _getErrorCommentListStorage() {
    this.pendingCommentList = this.getPendingCommentLists && this.getPendingCommentLists[this.violationIdentifier] || [];
  }

  private _getErrorStatus(err): string {
    if (err.status === 0) {
      return this.error.noConnection;
    } else if (err.error.message === ERROR_LIMIT_MGS) {
      return this.error.reachLimit;
    } else {
      return this.error.server;
    }
  }
}
