import { Component, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import {
  FleetAllTripsModel,
  SearchFilterModel,
} from './../../../dashboard3.models';
import { Data } from '@modules/dashboard3/services/data.service';
import {
  GoogleTagManagerService,
  ToggleTableViewStatus,
} from '@app-core/services/google-tag-manager.service';
import { StorageService } from '@app-core/services/storage.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-fleet-all-trips-table',
  templateUrl: './fleet-all-trips-table.component.html',
  styleUrls: ['./fleet-all-trips-table.component.scss'],
})
export class FleetAllTripsTableComponent implements OnDestroy {

  @Input() public totalTrips: number;

  /** Flag for toggleing Trips table */
  public tableHidden = true;
  public filterChanged = true;

  public filterOptions: SearchFilterModel;
  public tripListPage = [];

  public show = 'loading';

  public limit = 20;
  public sortBy = 'startTime';
  public sort = 'desc';
  public homeLocationList = [];
  public reset = false;

  public errorMessage = 'Something went wrong';
  public showRetryButton = true;

  private _offset = 0;
  private _filterChangeSubscription: any;

  // Behaviour subject to pass data of each page into the paginator component
  private _list = new BehaviorSubject([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public listData = this._list.asObservable();

  constructor(
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _dateService: DateService,
    private _router: Router,
    private _data: Data,
    private _gtmService: GoogleTagManagerService,
    private _storageService: StorageService
  ) {
    this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(
      (filterOptions) => {
        this.filterOptions = filterOptions;
        this._resetList();
        this.getData();
      }
    );
  }

  public ngOnDestroy() {
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  public getData() {
    // Do not call the API when table is hidden
    if (this.tableHidden) {
      return;
    }
    // When the table is open, only call the API if filter was changed
    if (!this.filterChanged) {
      return;
    }
    // else, call the API and set filter change falg to false
    this.filterChanged = false;
    this.refreshList();
  }

  /**
   * @description: function to get trip list
   * @param: date range, duty type, score value and driver id
   * @returns: a promise with trip list
   */
  public getTripList() {
    const { days, dutyType, minScore, maxScore, ids } = this.filterOptions;
    // When minscore and maxscore filter is applied and if ids list is empty
    // then return an empty array
    // Because when the ids are empty, the API returns all trips, which is not
    // desired
    if (minScore !== 0 || maxScore !== 100) {
      if (ids.length === 0) {
        return Promise.resolve([]);
      }
    }
    let date = { from: null, to: null };
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }

    this.homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION');

    const params = new FleetAllTripsModel(
      date.from,
      date.to,
      dutyType,
      this.limit,
      this._offset,
      this.sort,
      this.sortBy,
      this.homeLocationList
    );

    const driverIds = ids;
    const OPTIONS = {
      ...params,
      driverIds,
    };

    return new Promise((resolve, reject) => {
      this._loading.show();
      this._zcfleet.getTripList(OPTIONS).subscribe(
        (res) => {
          this._loading.hide();
          resolve(res);
        },
        (err) => {
          this._loading.hide();
          reject(err);
        }
      );
    });
  }

  /**
   * @description: function to get more data based on current page values
   * @param: page details such as limit and offset
   */
  public getMoreData(page) {
    // update limit and offset values and call the API to get the paginated
    // values
    ({ limit: this.limit, offset: this._offset } = page);
    this.getTripList()
      .then((res: any) => {
        this._list.next(res);
      })
      .catch((err) => {
        console.error(`Received Error: ${err}`);
      });
  }

  public tripListPageChange(e) {
    setTimeout(() => {
      this.tripListPage = e.list;
    }, 1);
  }

  /**
   * @description: function to show and hide table
   * @param:
   */
  public toggleTable() {
    this.tableHidden = !this.tableHidden;
    const tableStatus = this.tableHidden
      ? ToggleTableViewStatus.CLOSE
      : ToggleTableViewStatus.OPEN;
    this._gtmService.toogleTripDetailsTable(tableStatus);
    this.getData();
  }

  /**
   * @description: function to get trip count from http call or filter storage
   * @param:
   */
  public get tripCount() {
    return this.filterChanged ? '' : `(${this.totalTrips})`;
  }

  /**
   * @description: function to navigate to particular trip page
   * @param:
   */
  public gotoTripDetail(trip) {
    // Check if all the details are present to navigate successfully
    if (trip && trip.driverId && trip.tripId) {
      // Set fromFleet flag to true so that we can navigate back to
      // fleet page on back click from trip detail
      this._data.fromFleet = true;
      // Set driver name
      // this._data.driverName = trip.driverName;
      this._router.navigate(['/trip-detail'], {
        queryParams: {
          tripId: trip.tripId,
          driverId: trip.driverId,
        },
      });
    }
  }

  /**
   * @description: function to refresh trip list with default values
   * @param:
   */
  public refreshList() {
    // set the offset to 0 and set reset flag to true
    this._offset = 0;
    this.reset = true;

    this.show = 'loading';
    this.getTripList()
      .then((res: any) => {
        this.show = res.length !== 0 ? 'data' : 'empty';
        this._list.next(res);
      })
      .catch((err) => {
        console.error(`Received Error: ${err}`);
        const errorHanding = this._zcfleet.getErrorMessage(err);
        this.errorMessage = errorHanding.message;
        this.showRetryButton = errorHanding.showRetryButton;
        this.show = 'error';
      });
  }

  private _resetList() {
    this.filterChanged = true;
    this.reset = true;
  }
}
