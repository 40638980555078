import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { pickBy } from 'lodash-es';

import { IncidentPieChartService } from '@modules/dashboard3/services/incident-pie-chart.service';
import {
  IncidentStat,
  IncidentStatService,
  SourceEventList,
} from '@modules/dashboard3/services/incident-stat.service';

interface TransformOptions {
  ignoreZero: boolean; // Flag to know if we need to remove any not occur incidents
  sliceIndex: number; // If specify, cut the incident list instead of show all incidents
}

@Directive({
  selector: '[appTransformIncidentStat]',
})
export class TransformIncidentStatDirective implements OnChanges {
  @Input()
  public appTransformIncidentStatFrom: SourceEventList;

  @Input()
  public appTransformIncidentStatOptions: TransformOptions;

  @Input()
  public appTransformIncidentStatCustoms = false;

  private readonly _transformDefaultOptions: TransformOptions = {
    ignoreZero: false,
    sliceIndex: 0,
  };

  private _defaultSourceEvent: SourceEventList = {
    eventCount: {},
  };
  private _defaultColorCode = '#000000';
  private _totalEventKey = 'total';

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _incidentPieChartService: IncidentPieChartService,
    private _incidentStatService: IncidentStatService
  ) {}

  public ngOnChanges(_changes: SimpleChanges): void {
    this.clearAndCreateChildView();
  }

  public clearAndCreateChildView(): void {
    this._viewContainerRef.clear();
    this._viewContainerRef.createEmbeddedView(
      this._templateRef,
      this.buildChildViewContext()
    );
  }

  public buildChildViewContext(): Record<string, any> {
    const statList = this._incidentStatService.transformToIncidentStatList(
      this.appTransformIncidentStatFrom || this._defaultSourceEvent,
      this.buildTransformOptions()
    );
    const scorePieChartOptions =
      this._incidentPieChartService.buildScorePieChartOptions(
        statList,
        this.score,
        this.totalIncident,
        this._defaultColorCode
      );
    const incidentPieChartOptions =
      this._incidentPieChartService.buildIncidentPieChartOptions(
        statList,
        this.totalIncident,
        this._defaultColorCode,
        this.appTransformIncidentStatCustoms
      );

    return this.buildContextObject(
      statList,
      scorePieChartOptions,
      incidentPieChartOptions
    );
  }

  /**
   * Build template context to be used by child view.
   *
   * @param statList Source incident stats list
   * @param pieChartOptions Pie chart options object
   * @return Template context object, populated with provided arguments
   */
  public buildContextObject(
    statList: IncidentStat[],
    scorePieChartOptions,
    incidentPieChartOptions
  ): Record<string, any> {
    return {
      $implicit: statList,
      pieChartOptions: incidentPieChartOptions,
      statList,
      scorePieChartOptions,
    };
  }

  public get score() {
    return (
      (this.appTransformIncidentStatFrom &&
        this.appTransformIncidentStatFrom.v2Score) ||
      0
    );
  }

  public get totalIncident() {
    return (
      (this.appTransformIncidentStatFrom &&
        this.appTransformIncidentStatFrom.eventCount &&
        this.appTransformIncidentStatFrom.eventCount[this._totalEventKey]) ||
      0
    );
  }

  public buildTransformOptions(): TransformOptions {
    // Build user options, but ignore options with undefined value
    const userOptions = pickBy(
      this.appTransformIncidentStatOptions,
      (item) => item !== undefined
    );

    return {
      ...this._transformDefaultOptions,
      ...userOptions,
    };
  }
}
