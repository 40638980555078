import { Marker } from 'leaflet';
import moment from 'moment-timezone';

import {
  EventDisplayData,
  EVENTS_INCIDENT,
  EVENTS_INCIDENT_TYPE,
} from '@app-core/constants/constants';
import {
  calcSumTotalIncidents,
  createMomentObjectUtc,
  getTimezoneString,
} from '@modules/shared/utils';
import { MapService } from './services/map.service';
import { ZCFleetService } from './services/zcfleet.service';

interface TripRecapData {
  cameraConnected: boolean;
  tampered: boolean;

  driverName: string;
  driverId: string;
  startTime: string;
  startTimeMoment: moment.Moment;
  tripDistance: number;
  duration: number;
  tripId: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  incident_type: EventDisplayData;
  eventCount: number;
  ongoing: boolean;

  startPoint: string;
  endPoint: string;
  markerList: Marker[];
  pathList: L.LatLng[];
  pathInfo: L.LatLng[];
}

export const convertAssetTripListForTripRecap = (
  tripInfo: any,
  mapService: MapService,
  zcFleetService: ZCFleetService
): TripRecapData => {
  const tripDetail = createEmptyTripRecapData();

  const eventsList = mapService.combineEvents(tripInfo);
  const cameraConnected = tripInfo.cameraSerialId === 'null' ? false : true;
  tripDetail.cameraConnected = cameraConnected;
  tripDetail.tampered =
    cameraConnected && tripInfo.eventCount.total > 0
      ? zcFleetService.getTamepringStatus(tripInfo)
      : false;

  let startPointName = 'Unknown';
  let endPointName = 'Unknown';
  if (tripInfo.firstLocation && tripInfo.firstLocation.locationInfo) {
    if (tripInfo.firstLocation.locationInfo.City) {
      startPointName = tripInfo.firstLocation.locationInfo.City;
    } else if (tripInfo.firstLocation.locationInfo.Region) {
      startPointName = tripInfo.firstLocation.locationInfo.Region;
    }
  }
  if (tripInfo.lastLocation && tripInfo.lastLocation.locationInfo) {
    if (tripInfo.lastLocation.locationInfo.City) {
      endPointName = tripInfo.lastLocation.locationInfo.City;
    } else if (tripInfo.lastLocation.locationInfo.Region) {
      endPointName = tripInfo.lastLocation.locationInfo.Region;
    }
  }
  tripDetail.startPoint = startPointName;
  tripDetail.endPoint = endPointName;
  tripDetail.driverName = tripInfo.driverName;
  tripDetail.driverId = tripInfo.driverId;
  tripDetail.startTime = tripInfo.startTime;
  tripDetail.startTimeMoment = createMomentObjectUtc(tripInfo.startTimeUTC).tz(
    getTimezoneString(
      tripInfo.timezoneOffset,
      tripInfo.firstLocation.latitude,
      tripInfo.firstLocation.longitude
    )
  );
  tripDetail.tripDistance = tripInfo.tripDistance;
  tripDetail.tripId = tripInfo.tripId;
  tripDetail.markerList = loadEventsLocation(eventsList, mapService);
  tripDetail.pathList = mapService.preparePathList(tripInfo.pathInfo || []);
  const filteredList = tripInfo.pathInfo.filter(
    (event) => +event.latitude || +event.longitude
  );

  const listLength = filteredList.length;
  // Generate a marker with the icon

  if (
    listLength &&
    filteredList[listLength - 1].hasOwnProperty('latitude') &&
    filteredList[listLength - 1].hasOwnProperty('longitude')
  ) {
    tripDetail.markerList.push(
      ...mapService.buildTripEndPointIconMarkers(tripInfo)
    );
  }

  // Loop through filtered list and generate an array of lat-long
  const path = filteredList.map((event) =>
    mapService.getLatLong(event.latitude, event.longitude)
  );
  tripDetail.pathInfo = path.slice();
  tripDetail.duration = tripInfo.duration;

  const incidentList: EventDisplayData[] = [];

  for (const event of EVENTS_INCIDENT(tripInfo.eventCount)) {
    incidentList.push(event);
  }

  const highest = incidentList.reduce(
    (max, arr) => Math.max(max, arr.value),
    -Infinity
  );

  tripInfo.eventCount.total = calcSumTotalIncidents(tripInfo.eventCount);

  const incidentType = incidentList.find((obj) => obj.value === highest);
  tripDetail.incident_type = incidentType;
  tripDetail.eventCount = tripInfo.eventCount;
  tripDetail.ongoing = tripInfo.ongoing;
  return tripDetail;
};

const createEmptyTripRecapData = (): TripRecapData => ({
  cameraConnected: false,
  tampered: false,
  startPoint: '',
  endPoint: '',
  driverName: '',
  driverId: '',
  startTime: '',
  startTimeMoment: null,
  tripDistance: 0,
  incident_type: null,
  eventCount: 0,
  ongoing: false,
  duration: 0,
  tripId: '',
  markerList: [],
  pathList: [],
  pathInfo: [],
});

const loadEventsLocation = (eventList: any[], mapService: MapService) => {
  /**
   * Create a marker list
   */
  const markerList: Marker<any>[] = [];
  const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);

  eventList
    .filter((evt) => eventTypeAllowedList.includes(evt.eventType))
    .map((ele) => {
      const { latitude, longitude } = ele;
      // Exclude markers with lat lon 0
      if (+latitude || +longitude) {
        // generate an Icon
        const icon = mapService.getCircleicon(ele.eventType + '-circle');
        // Generate a marker with the icon
        const marker = mapService.getMarker(
          +latitude,
          +longitude,
          icon,
          false,
          false
        );
        // Push the markers in the markers list
        markerList.push(marker);
      }
    });
  return markerList.slice();
};
