import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

import { cloneDeep } from 'lodash-es';
import * as Highcharts from 'highcharts';
import { merge, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { CHART_OPTION } from '@app-core/constants/constants';

const MAX_NAME_LENGTH = 13;
const MAX_DIVISION_LENGTH = 25;

export type PlotData = number;
export interface GraphData {
  numberOfDays: number;
  updateChart: boolean;
  plotData: PlotData[];
}

const lessThanMaxLengthOrEmpty = (maxLength: number) => (input: string) =>
  input.length > maxLength ? input : '';

@Component({
  selector: 'app-identification-card',
  templateUrl: './identification-card.component.html',
  styleUrls: ['./identification-card.component.scss'],
})
export class IdentificationCardComponent {
  @Input() public isCustomDateRange = false;

  @Input() public set name(value: Observable<string>) {
    if (!value) {
      return;
    }
    this.displayName$ = value;
    this.nameTooltip$ = value.pipe(
      map(lessThanMaxLengthOrEmpty(MAX_NAME_LENGTH))
    );
  }

  @Input() public set divisionName(value: Observable<string>) {
    if (!value) {
      return;
    }
    this.divisionName$ = value;
    this.divisionNameTooltip$ = value.pipe(
      map(lessThanMaxLengthOrEmpty(MAX_DIVISION_LENGTH))
    );
  }

  @Input() public set graphData(value: Observable<GraphData>) {
    if (!value) {
      return;
    }
    this.graphData$ = value.pipe(
      catchError(() => {
        this.hasGraphError = true;
        return of<GraphData>(undefined);
      }),
      map(res => {
        if (res && res.numberOfDays === -1) {  // date range yesterday selected
          res.numberOfDays = 1;
        }
        return res;
      })
    );

    this.chartOptions$ = value.pipe(
      map((graphData) => {
        const options = cloneDeep(CHART_OPTION);
        if (!graphData) {
          this.hasGraphError = true;
          return options;
        }

        this.hasGraphError = false;
        const plotData = [...graphData.plotData];
        options.series[0].data = plotData;
        const sumValue = plotData.reduce((acc, curr) => acc + curr, 0);
        const averageValue = parseFloat((sumValue / plotData.length).toFixed(2));

        options.yAxis.plotLines[0].value = averageValue;
        options.series[0].color = this.plotLineColor;

        this.firstGraphValue = plotData[0];
        this.lastGraphValue = plotData[plotData.length - 1];
        this.averageGraphValue = averageValue;
        return options;
      })
    );
  }

  @Input()
  public set reload$(value: Observable<boolean>) {
    const graphState = merge(value, this.graphData$);
    const infoState = merge(value, this.displayName$, this.divisionName$);
    const mapper = map(mergeValue => (mergeValue !== true && mergeValue !== undefined));

    this.notLoadingState = {
      graphData: graphState.pipe(mapper),
      info: infoState.pipe(mapper),
    };
  }

  @Input() public plotLineColor = '#2d8ed9';

  public graphData$: Observable<GraphData>;

  public displayName$: Observable<string>;
  public nameTooltip$: Observable<string>;

  public divisionName$: Observable<string>;
  public divisionNameTooltip$: Observable<string>;

  public chartOptions$: Observable<any>;
  public highcharts = Highcharts;
  public firstGraphValue: number;
  public lastGraphValue: number;
  public averageGraphValue: number;

  public hasGraphError = false;

  public divisionTooltipPosition: TooltipPosition = 'above';
  public nameTooltipPosition: TooltipPosition = 'below';

  public notLoadingState: {
    graphData: Observable<boolean>;
    info: Observable<boolean>;
  };
}
