<div *ngIf="isDashboard">
  <div *isAuthorized="!'fleet-ridecam-plus'"></div>
  <div *appFeatureFlag="'show-disputed-smart-fleet'">
    <div class="disputed-table-heading" data-test="incident-disputed-header">
      <span>Incidents Disputed by Drivers</span>
    </div>
    <ng-container *ngIf="!notLoadingState$ || (notLoadingState$ | async); else tableSkeleton">
      <div [ngSwitch]="show" data-test="incidents-disputed-card">
    
        <!-- loading data -->
        <div *ngSwitchCase="'loading'">
        </div>
    
        <!-- show data -->
        <div *ngSwitchCase="'data'">
          <!-- dashboard layout -->
          <div class="disputed-table-card-wrap" data-test="disputed-table-wrap">
            <div class="row no-gutters zc-table-wrap disputed-incidents-table">
              <table class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
                data-test="disputed-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <app-table-sort-header sortOn="disputeTimeMoment" [(sortBy)]="sortBy" [(sort)]="sort"
                        (sortClicked)="sortChange()">
                        Date/Time
                      </app-table-sort-header>
                    </th>
                    <th scope="col">
                      <app-table-sort-header sortOn="_eventType" [(sortBy)]="sortBy" [(sort)]="sort"
                        (sortClicked)="sortChange()">
                        Incident Category
                      </app-table-sort-header>
                    </th>
                    <th scope="col">
                      <app-table-sort-header sortOn="driverName" [(sortBy)]="sortBy" [(sort)]="sort"
                        (sortClicked)="sortChange()">
                        Driver
                      </app-table-sort-header>
                    </th>
                    <th class="wth-25" scope="col">Dispute Status</th>
                    <th class="wth-10" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let incident of incidentsPageList">
                    <td>{{incident?.disputeTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z' }}</td>
                    <td>{{incident?._eventType}}</td>
                    <td>{{incident?.driverName}}</td>
                    <td>
                      <span class="challenge-group">
                        <button type="button" class="btn" (click)="updateChallenge(incident, 'accept')">Accept</button>
                        <button type="button" class="btn" (click)="updateChallenge(incident, 'reject')">Reject</button>
                      </span>
                    </td>
                    <td>
                      <span class="camera-icon float-right" [class.active]="incident === selectedIncident"
                        (click)="setMedia(incident)">
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="paginator-wrap">
              <app-paginator2 [arrayList]="incidentsList" [(reset)]="reset" [(update)]="update" [pageLimit]="10"
                (pageChange)="pageChange($event)">
              </app-paginator2>
            </div>
    
            <div class="incident-media dashboard-media">
              <div *ngIf="incidentData.mediaLink && !isImage(incidentData.mediaLink)">
                <video controls *ngIf="switch">
                  <source [src]="incidentData.mediaLink" type="video/mp4" />
                  Browser not supported
                </video>
                <video controls *ngIf="!switch">
                  <source [src]="incidentData.mediaLink" type="video/mp4" />
                  Browser not supported
                </video>
              </div>
              <div *ngIf="incidentData.mediaLink && isImage(incidentData.mediaLink)">
                <img *ngIf="switch" [src]="incidentData.mediaLink" alt="Event Image">
                <img *ngIf="!switch" [src]="incidentData.mediaLink" alt="Event Image">
              </div>
            </div>
          </div>
        </div>
    
        <!-- empty -->
        <div *ngSwitchCase="'empty'">
          <div class="no-records-card">
            <div class="no-records">
              <img src="assets/images/check-circle.svg">
              <div class="note">
                <span>
                  Your fleet has no disputed incidents to review
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="!isDashboard">

  <div [ngSwitch]="show" class="card-margin" data-test="incidents-disputed-card">
    <div>
      <!-- incident response center layout -->
      <app-card [showDetailButton]="false" [sortList]="sortList" [showSortList]="showOption"
        (changeSortBy)="notifySortChange($event)" [updateSortList]="syncSortCard" [menuOptionTemplate]="menuOption">
        <div card-title>Disputed Incidents</div>
        <div card-body *ngSwitchCase="'loading'" class="body-content"></div>
        <div card-body *ngSwitchCase="'data'">
          <app-incident-media-table-wrapper
            [dataSource$]="incidentListTable$"
            [sortOrderChange$]="notifySortChange$"
            [reload$]="reload$"
            [displayedColumns]="displayedColumns"
            [colDescriptions]="colDescriptions"
            [customTemplates]="{ 
              ruling: twoButton 
            }"
            [isDisabledAction]="isDisabledChallenge"
            [sortHeader]="sortHeader"
            [selectedIncident]="selectedIncident"
            [incidentData]="incidentData"
            [noMediaText]="noMediaText"
            [switch]="switch"
            (pageChange)="updatePage($event)"
            (sortChange)="sortChangeInternal($event)"
            (challengeUpdated)="updateIncidentList($event)"
            (isDisabledClick)="isDisabledClick($event)"
            (rowSelected)="setMedia($event)"
          ></app-incident-media-table-wrapper>
        </div>
      </app-card>
    </div>
  </div>

</div>

<!-- buttons -->
<!-- TO-DO: Because this template has some events handle need to call FleetDisputedIncidentsTableComponent methods directly so it will not be moved to IncidentMediaTableWrapperComponent -->
<ng-template #twoButton let-element="element" let-index="index">
  <div class="ruling-buttons-container" [ngClass]="{'disabled-button': isDisabledChallenge}" style="position: relative; left:35%">
        <a class="ruling-button" style="border: 1px solid #B9BEC1; padding: 4px 12px; border-radius: 3px 0 0 3px;"
          (click)="updateChallenge(element, 'reject', $event)"><img style="width: 20px; padding-bottom: 3px;"
            src="assets/images/reject_dispute.svg"></a>
        <a class="ruling-button" style="border: 1px solid #B9BEC1; padding: 4px 12px; border-radius: 0px 3px 3px 0;"
          (click)="updateChallenge(element, 'accept', $event)"><img style="width: 20px; padding-bottom: 3px;"
            src="assets/images/accept_dispute.svg"></a>
  </div>
</ng-template>

<!-- menu option-->
<ng-template #menuOption>
  <app-menu-option [options]="['PDF', 'CSV']" [showMeuOption]="showOption" (clickOption)="downloadFile($event)"></app-menu-option>
</ng-template>

<ng-template #tableSkeleton>
  <div class="disputed-table-card-wrap" data-test="disputed-table-wrap">
    <div class="row no-gutters zc-table-wrap disputed-incidents-table">
      <table class="table zc-table table-striped table-text-wrap table-hower header-sort table-sm"
        data-test="disputed-table">
        <thead>
          <tr>
            <th scope="col">
              <app-table-sort-header sortOn="disputeTimeMoment" [(sortBy)]="sortBy" [(sort)]="sort"
                (sortClicked)="sortChange()">
                Date/Time
              </app-table-sort-header>
            </th>
            <th scope="col">
              <app-table-sort-header sortOn="_eventType" [(sortBy)]="sortBy" [(sort)]="sort"
                (sortClicked)="sortChange()">
                Incident Category
              </app-table-sort-header>
            </th>
            <th scope="col">
              <app-table-sort-header sortOn="driverName" [(sortBy)]="sortBy" [(sort)]="sort"
                (sortClicked)="sortChange()">
                Driver
              </app-table-sort-header>
            </th>
            <th class="wth-25" scope="col">Dispute Status</th>
            <th class="wth-10" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let incident of dummyData">
            <td>
              <app-custom-skeleton-loader [theme]="skeletonStyle"></app-custom-skeleton-loader>
            </td>
            <td>
              <app-custom-skeleton-loader [theme]="skeletonStyle"></app-custom-skeleton-loader>
            </td>
            <td>
              <app-custom-skeleton-loader [theme]="skeletonStyle"></app-custom-skeleton-loader>
            </td>
            <td>
              <span class="challenge-group">
                <app-custom-skeleton-loader style="margin-right: 6px;" [theme]="skeletonStyleBtn"></app-custom-skeleton-loader>
                <app-custom-skeleton-loader [theme]="skeletonStyleBtn"></app-custom-skeleton-loader>
              </span>
            </td>
            <td>
              <span class="camera-icon float-right">
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginator-wrap">
      <app-custom-skeleton-loader [theme]="paginationSkeleton"></app-custom-skeleton-loader>
    </div>

    <div *ngIf="screenWidth > 600"class="incident-media dashboard-media">
      <app-custom-skeleton-loader [theme]="videoSkeletonStyle"></app-custom-skeleton-loader>
    </div>

    <div *ngIf="screenWidth <= 600" class="incident-media dashboard-media">
      <app-custom-skeleton-loader [theme]="videoSkeletonStyleSmall"></app-custom-skeleton-loader>
    </div>
  </div>
</ng-template>
