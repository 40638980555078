import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class ArraySortPipe implements PipeTransform {
  public transform(array: any[], key?: any, asc: boolean = true): any[] {
    if (array && array.length) {
      let order = asc;

      if (typeof key === 'boolean') {
        order = key;
      }

      const x = order ? -1 : 1;
      const y = order ? 1 : -1;

      array.sort((a: any, b: any) => {
        let val1; let val2;

        if (
          typeof a === 'object' &&
          typeof b === 'object' &&
          typeof key === 'string'
        ) {
          val1 = a[key];
          val2 = b[key];
        } else {
          val1 = a;
          val2 = b;
        }
        val1 = typeof val1 === 'string' ? val1.toLocaleLowerCase() : val1;
        val2 = typeof val2 === 'string' ? val2.toLocaleLowerCase() : val2;
        return val1 < val2 ? x : val1 > val2 ? y : 0;
      });
    }
    return array;
  }
}
