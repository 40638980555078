export const ASSET_TYPES = [
  { label: 'Heavy', value: 'heavy' },
  { label: 'Medium', value: 'medium' },
  { label: 'Light', value: 'light' },
];

export const ASSET_VOLUME = [
  { label: 'On', value: 'manual' },
  { label: 'Muted', value: 'silent' },
  { label: 'Max', value: 'loud' },
];

export const ASSET_ACTION = [
  { label: 'Delete camera', value: 'delete' },
];

// The low is made and high is made low based on the tool tip change
const OPTIONS_SET_1 = (setting) => [
  {
    label: 'High',
    value: 4.5,
    body: {
      [`${setting}Enabled`]: true,
      [`${setting}Threshold`]: 4.5,
    },
  },
  {
    label: 'Medium',
    value: 6.0,
    body: {
      [`${setting}Enabled`]: true,
      [`${setting}Threshold`]: 6.0,
    },
  },
  {
    label: 'Low',
    value: 8.0,
    body: {
      [`${setting}Enabled`]: true,
      [`${setting}Threshold`]: 8.0,
    },
  },
  {
    label: 'Off',
    value: false,
    body: {
      [`${setting}Enabled`]: false,
    },
  },
];

export const HARD_BRAKING_OPTIONS = OPTIONS_SET_1('hardBraking');
export const HARD_ACCELERATION_OPTIONS = OPTIONS_SET_1('harshAcceleration');
export const HARD_CORNERING_OPTIONS = OPTIONS_SET_1('cornering');

export interface MaxSpeedOptions {
  minValue: number;
  maxValue: number;
  step: number;
}

export const MAX_SPEED_OPTIONS: MaxSpeedOptions = {
  // value must be in mile per hour
  minValue: 60,
  maxValue: 85,
  step: 5,
};

// low to high is reversed , values updated to 0, 5 and 10 from 5, 10 and 15
export const SPEEDING_OPTIONS = [
  {
    label: 'High',
    value: 0,
    body: {
      speedingEnabled: true,
      speedingAllowance: 0,
    },
  },
  {
    label: 'Medium',
    value: 5,
    body: {
      speedingEnabled: true,
      speedingAllowance: 5,
    },
  },
  {
    label: 'Low',
    value: 10,
    body: {
      speedingEnabled: true,
      speedingAllowance: 10,
    },
  },
  {
    label: 'Off',
    value: false,
    body: {
      speedingEnabled: false,
    },
  },
];

export const TAILGATING_OPTIONS = [
  {
    label: 'High',
    value: 1.5,
    body: {
      tailgatingEnabled: true,
      tailgatingSensitivity: 1.5,
    },
  },
  {
    label: 'Medium',
    value: 2,
    body: {
      tailgatingEnabled: true,
      tailgatingSensitivity: 2,
    },
  },
  {
    label: 'Low',
    value: 2.5,
    body: {
      tailgatingEnabled: true,
      tailgatingSensitivity: 2.5,
    },
  },
  {
    label: 'Off',
    value: false,
    body: {
      tailgatingEnabled: false,
    },
  },
];

export const STOP_SIGN_OPTIONS = [
  { label: 'Off', value: false, body: { stopSignEnabled: false } },
  { label: 'On', value: true, body: { stopSignEnabled: true } },
];

export const LANE_DRIFT_OPTIONS = [
  { label: 'On', value: true, body: { laneDriftEnabled: true } },
  { label: 'Off', value: false, body: { laneDriftEnabled: false } },
];

export const DRIVER_DISTRACTION_OPTIONS = [
  { label: 'On', value: true, body: { distractedDrivingEnabled: true } },
  { label: 'Off', value: false, body: { distractedDrivingEnabled: false } },
];

export const MARKER = {
  enabled: true,
  radius: 5,
};

// Search filter bar configuration
const config = {
  backButton: {
    visible: true,
    text: 'Back to Fleet',
  },
  showSearch: false,
  showDateFilter: false,
  showDriverFilter: false,
  showLocationFilter: false,
  showDutyTypeFilter: false,
  showExport: true,
  showUnassignCamFilter: false,
};

// Configuration per page
export const FILTER_CONFIG = {
  fleet: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showSearch: true,
    showDateFilter: true,
    showDriverFilter: true,
    showDutyTypeFilter: true,
    showLocationFilter: true,
  },
  driver: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showExport: false,
    showSearch: true,
    showDateFilter: true,
  },
  trip: {
    ...config,
    backButton: {
      visible: true,
      text: 'Back to Driver',
    },
    showExport: false,
    showSearch: true,
  },
  settings: { ...config, showSearch: true, showExport: false },
  disputes: {
    ...config,
    showSearch: true,
    showDateFilter: true,
    showDriverFilter: true,
    showDutyTypeFilter: true,
    showLocationFilter: true,
  },
  reports: { ...config, showDateFilter: true },
  fleetmetrics: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showSearch: true,
    showDateFilter: true,
    showDriverFilter: true,
    showDutyTypeFilter: true,
    showExport: false,
    showLocationFilter: true,
  },
  flagged: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showExport: false,
    showSearch: true,
  },

  incidentResponseCenter: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showSearch: true,
    showDateFilter: true,
    showDriverFilter: false,
    showDutyTypeFilter: false,
    showExport: false,
    showLocationFilter: true,
  },

  unassignedCameras: {
    ...config,
    backButton: {
      visible: false,
      text: '',
    },
    showExport: false,
    showUnassignCamFilter: true,
  },
};

export const BAD_SCORE_COLOR = '#ce0100';
export const AVERAGE_SCORE_COLOR = '#5b6770';
export const GOOD_SCORE_COLOR = '#1d811e';

export const USERS_DEFAULT_HOME_LOCATION_CONFIG = [
  // isEditable boolean is set here for future use cases
  // below is the demo object on how mapping must be implemented
  {
    userEmail: 'demo@test.com',
    homeLocation: 'Arizona',
    isEditable: false, // use this flag to enable/disable home location dropdown for a particular user
  },
];

export const DISTRACTION_DISCLAMER = 'Distraction incidents are not included in Driver Score.';
