<div class="map-popup">
    <h6 id="eventType">
        Event type: {{getEventType(event.eventType)}}
    </h6>
    <!--<h6 id="eventTime">Time: {{event.timestamp | date:'MM/dd/yy hh:mm a'}}</h6>-->
    <h6 id="eventTime">Time: {{event.timestamp | dateFormat | date:'MM/dd/yy hh:mm a'}}</h6>
    <button type="button" class="btn btn-primary" *ngIf="event.eventVideoFilename" (click)="showMedia()">
        Watch Video/Image
    </button>
</div>
