<div class="modal fade" bsModal #modal="bs-modal" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onShow)="show()" style="z-index:
    1500 !important">
    <div class="backDrop" (click)="hideModal()"></div>
    <div class="modal-dialog modal-lg" style="height: 80%">
        <div class="modal-content">
            <!-- <div class="modal-header">
                <span class="pull-right ml-auto" style="cursor: pointer;" (click)="hideModal()">
                    <img src="assets/images/x.svg" />
                </span>
            </div> -->
            <div class="modal-body" style="height: 610px; overflow-y: auto; overflow-x: hidden;">
                <!-- empty div to maintain height and width of table till data loads -->
                <div class="capturediv">
                    <h3>Capture new DVR clip</h3>
                    <p>An incident or event that was not detected by the camera may be captured as a
                        one-minute video beginning at the start point you identify.</p>
                    <form name="form" (ngSubmit)="f.valid && captureDVRClipRequest(f)" #f="ngForm"
                        novalidate>
                        <div class="incidentblock">
                            <p>Start time of video clip:</p>

                            <div class="slidecontainer">
                                <input type="range" min="0" [max]="sliderMaxValue" [value]="currentSliderValue"
                                    class="slider" id="myRange"
                                    (change)="getSliderValue(slider.value)" #slider>
                                <output class="show-current-time"
                                    [ngStyle]="{ left: 'calc(' + currentTimeDisplayPosition + '% - ' + currentTimeDisplayPositionOffset + 'px)' }">
                                    {{ currentTime | date: 'HH:mm' : 'UTC'}}</output>
                                <div class="incidentlist">
                                    <div class="row">
                                        <div *ngFor="let incident of incidents">
                                            <div class="icons {{incident.type[0]}}"
                                                [style.left]="incident.timetaken+'%'">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="timeduration">
                                <span
                                    class="starttime">{{sliderStartTime | dateFormat | date: 'HH:mm'}}</span>
                                <span
                                    class="endtime">{{sliderEndTime | dateFormat | date: 'HH:mm'}}</span>
                            </div>
                        </div>
                        <div class="mapblock" *ngIf="showMap">
                            <app-map mapId="eventMap9" [markerList]="markerList"
                                [latlonList]="latLongList" [mapheight]="400"
                                [getHighlightLatLon]="highlightLatLon"
                                (sendClickedMarkerPosition)="getClickedMarkerPos($event)">
                            </app-map>

                        </div>
                        <div class="lastblock">
                            <div class="textblock">The device
                                used
                                for this trip must be connected to wi-fi or cellular service without
                                distruption for the video to be retrieved.</div>
                        </div>

                        <div class="action-block">
                            <button class="btn btn-main pull-right">Capture</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
