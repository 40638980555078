import { Component, Input, OnInit } from '@angular/core';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface DateRangeISO {
  from: string;
  to: string;
}

@Component({
  selector: 'app-total-incident-card',
  templateUrl: './total-incident-card.component.html',
  styleUrls: ['./total-incident-card.component.scss'],
})
export class TotalIncidentCardComponent implements OnInit {
  @Input()
  public set totalIncident$(value: Observable<number>) {
    this.internalTotalIncident$ = value.pipe(shareReplay());
  }

  @Input()
  public set topIncidentData$(value: Observable<IncidentStat[]>) {
    this.internalTopIncidentData$ = value.pipe(map(res => this.filterIncidentList(res)), shareReplay());
  }

  @Input() public range: DateRangeISO;
  @Input() public assetId: string;
  @Input() public displayAmount = 5;

  @Input()
  public set reload$(value: Observable<boolean>) {
    const totalIncidentState = merge(value, this.internalTotalIncident$);
    const topIncidentState = merge(value, this.internalTopIncidentData$);
    const mapper = map(mergeValue => (mergeValue !== true && mergeValue !== undefined));

    this.notLoadingState = {
      totalIncident: totalIncidentState.pipe(mapper),
      topIncidentData: topIncidentState.pipe(mapper),
    };
  }

  public notLoadingState: {
    totalIncident: Observable<boolean>;
    topIncidentData: Observable<boolean>;
  };

  public internalTotalIncident$: Observable<number>;
  public internalTopIncidentData$: Observable<IncidentStat[]>;

  public internationalNumberFormat = new Intl.NumberFormat('en-US');

  constructor() {}

  public ngOnInit(): void {}

  public filterIncidentList(responseList: IncidentStat[]): IncidentStat[] {
    if (responseList) {
      const filteredList =  responseList.slice().sort((a, b) => b.value - a.value).filter(item => item.value > 0);
      if (filteredList.length > this.displayAmount) {
        const otherEvent: IncidentStat = {
          name: 'Others',
          value: 0,
          incidentClass: 'incident-others',
        };
        filteredList.splice(this.displayAmount - 1, filteredList.length).forEach( evt => {
          otherEvent.value += evt.value;
        });
        filteredList.push(otherEvent);
      }
      return filteredList;
    }
    return undefined;
  }

  public getGraphSkeletonArray() {
    return Array(this.displayAmount);
  }

  public getMaxValue(responseList: IncidentStat[]): number {
    return Math.max(...responseList.map(evt => evt.value));
  }
}
