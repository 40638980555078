import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';

import { API } from '@app-core/constants/api.constants';
import {
  PackageOptions,
  SortOption,
} from '@modules/package-config/common/package-config.constants';

import { UpdateAssetInfoParam } from '@modules/package-config/common/package-config.model';

import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class PackageConfigService {
  constructor(private _http: HttpClient) {}

  public addNewAccount(accountCode: string, param: any): Observable<any> {
    return this._http.put(
      `${API.INTERMEDIATE_URL}/fleets/${accountCode}`,
      param
    );
  }

  public removeAccount(param: any): Observable<any> {
    return this._postData(API.REMOVE_ACCOUNT_CODE, param);
  }

  public getListOfAccounts(params): Observable<any> {
    return this._getData(API.GET_LIST_OF_ACCOUNTS, params);
  }

  public getAssetListOfAllOrgs(): Observable<any> {
    return this._getData(API.GET_ASSET_LIST_OF_ALL_ORGS);
  }

  public getAssetListOf(
    fleetId: string,
    limit: number = 20,
    offset: number = 0
  ): Observable<any> {
    return this._getData(API.ASSETS_LIST(fleetId, limit, offset));
  }

  public updateAsset(
    fleetId: string,
    assetId: string,
    body: any
  ): Observable<any> {
    return this._patchData(API.FLEET_ASSET_UPDATE(fleetId, assetId), body);
  }

  public uploadAssetList(fleetId: string, param: any): Observable<any> {
    return this._postData(API.FLEET_ASSETS(fleetId), param);
  }

  public updateAssetInfo(
    param: UpdateAssetInfoParam,
    header: any
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders(header),
    };
    return this._postData(API.UPDATE_ASSET_INFO, param, options);
  }

  public removeAsset(param: any): Observable<any> {
    return this._postData(API.REMOVE_ASSET, param);
  }

  public getPackageOptions() {
    return PackageOptions;
  }

  public getAssetConfigSortOptions() {
    return SortOption;
  }

  /**
   * @description: Creating request object for GET requests
   */
  private _setParams(params) {
    return new HttpParams({
      fromObject: params,
    });
  }

  private _getData(url: string, params?: any) {
    const options = {
      params: this._setParams(params),
    };
    return this._http.get(url, options).pipe(retry(3));
  }

  private _postData(url: string, param: any, options?: any) {
    return this._http.post(url, param, options).pipe(retry(3));
  }

  private _patchData(url: string, param: any) {
    return this._http.patch(url, param);
  }
}
