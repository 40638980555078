<div  *ngIf="showMeuOption">
  <mat-menu #menu="matMenu"
  class="app-menu"
  xPosition="before"
  >
  <button class="menu-option"
    mat-menu-item
    *ngFor="let option of options"
    (click)="onClickOption(option)">
    Export to {{ option }}
  </button>
</mat-menu>
<i class="material-icons more_vert"
  mat-button
  [matMenuTriggerFor]="menu">more_vert</i>
</div>
