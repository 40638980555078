import { Component, OnInit } from '@angular/core';

import { ExceptionInfoService } from '@modules/exception-info/services/exception-info.service';

@Component({
  selector: 'app-exception-info',
  templateUrl: './exception-info.component.html',
  styleUrls: ['./exception-info.component.scss'],
})
export class ExceptionInfoComponent implements OnInit {
  public crashCount: any[] = [];

  public show = 'loading';

  constructor(private _exceptionService: ExceptionInfoService) {}

  public ngOnInit() {
    this._getCrashDetails();
  }

  private _getCrashDetails() {
    this._exceptionService.getVersionCrashCount().subscribe(
      res => {
        this.show = 'content';
        this.crashCount = res.rows || [];
      },
      err => {
        this.show = err;
        console.log(err);
      }
    );
  }
}
