import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Components
import { HeaderComponent } from '@modules/shared/components/header/header.component';
import { FooterComponent } from '@modules/shared/components/footer/footer.component';
import { DisplayContainerComponent } from './components/display-container/display-container.component';
import { LoaderComponent } from '@modules/shared/components/loader/loader.component';

// Pipes
import { DistancePipe } from '@modules/shared/pipes/distance.pipe';
import { DurationPipe } from '@modules/shared/pipes/duration.pipe';
import { ArraySortPipe } from '@modules/shared/pipes/array-sort.pipe';
import { SearchFilterPipe } from '@modules/shared/pipes/search-filter.pipe';
import { SpinnerComponent } from '@modules/shared/components/spinner/spinner.component';
import { DateFormatPipe } from '@modules/shared/pipes/date-format.pipe';
import { Duration2Pipe } from './pipes/duration2.pipe';
import { ListFilterPipe } from './pipes/list-filter.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
// Pipes
import { ArrayFormatPipe } from './pipes/array-format/array-format.pipe';
import { LastNameSortPipe } from './pipes/last-name-sort/last-name-sort.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateSuffixPipe } from './pipes/date-suffix.pipe';
import { TimeZonePipe } from './pipes/time-zone.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { InlineSpinnerComponent } from './components/inline-spinner/inline-spinner.component';
import { AbsPipe } from './pipes/abs-pipe/abs-pipe';
import { IsAuthorizedDirective } from './directives/is-authorized.directive';
import { IsDisableOnPermissionDirective } from './directives/is-disable-on-permission.directive';
import { IfOnPermissionDirective } from './directives/if-on-permission.directive';
import { CardComponent } from './components/card/card.component';
import { TwoColumnsLayoutComponent } from './components/two-column-layout/two-columns-layout.component';
import { TableComponent } from './components/table/table.component';
import { MatTableModule } from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import { ClickOutSideDirective } from './directives/click-outside.directive';
import { DefaultModalComponent } from './components/default-modal/default-modal.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { MenuOptionComponent } from './components/menu-option/menu-option.component';
import { CheckBetaFeatureDirective } from './directives/check-beta-feature.directive';
import { CustomSkeletonLoaderComponent } from './components/custom-skeleton-loader/custom-skeleton-loader.component';
import { AsyncCarouselComponent } from './components/async-carousel/async-carousel.component';
import { FeatureFlagModule } from '@modules/feature-flag/feature-flag.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSnackBarModule,
    MatMenuModule,
    NgxSkeletonLoaderModule,
    SlickCarouselModule,
    FeatureFlagModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    DisplayContainerComponent,
    LoaderComponent,
    SpinnerComponent,
    InlineSpinnerComponent,
    DistancePipe,
    DurationPipe,
    ArraySortPipe,
    SearchFilterPipe,
    DateFormatPipe,
    Duration2Pipe,
    ArrayFormatPipe,
    LastNameSortPipe,
    DateSuffixPipe,
    TimeZonePipe,
    MomentFormatPipe,
    AbsPipe,
    ListFilterPipe,
    HighlightSearchPipe,
    IsAuthorizedDirective,
    IsDisableOnPermissionDirective,
    IfOnPermissionDirective,
    ClickOutSideDirective,
    TwoColumnsLayoutComponent,
    CardComponent,
    TableComponent,
    DefaultModalComponent,
    ErrorMessageComponent,
    MenuOptionComponent,
    CheckBetaFeatureDirective,
    CustomSkeletonLoaderComponent,
    AsyncCarouselComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    DisplayContainerComponent,
    LoaderComponent,
    SpinnerComponent,
    InlineSpinnerComponent,
    DistancePipe,
    DurationPipe,
    ArraySortPipe,
    SearchFilterPipe,
    DateFormatPipe,
    Duration2Pipe,
    ArrayFormatPipe,
    LastNameSortPipe,
    ListFilterPipe,
    HighlightSearchPipe,
    FormsModule,
    ReactiveFormsModule,
    DateSuffixPipe,
    TimeZonePipe,
    MomentFormatPipe,
    AbsPipe,
    IsAuthorizedDirective,
    IsDisableOnPermissionDirective,
    IfOnPermissionDirective,
    ClickOutSideDirective,
    TwoColumnsLayoutComponent,
    CardComponent,
    TableComponent,
    DefaultModalComponent,
    ErrorMessageComponent,
    MenuOptionComponent,
    CheckBetaFeatureDirective,
    NgxSkeletonLoaderModule,
    CustomSkeletonLoaderComponent,
    AsyncCarouselComponent,
    FeatureFlagModule,
  ],
  providers: [
    DateFormatPipe,
    ArraySortPipe,
    SearchFilterPipe,
    LastNameSortPipe,
    ListFilterPipe,
    HighlightSearchPipe,
  ],
})
export class SharedModule {}
