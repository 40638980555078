import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { HttpCacheService } from '@app-core/services/cache.service';

import { APIS_TO_CACHE } from '@app-core/constants/constants';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptorService implements HttpInterceptor {
  constructor(private _cache: HttpCacheService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * @description: Check to see if the API needs to be cached from the
     *                ApisToCache array
     *                else fetch the data from server
     */
    if (req.method === 'GET') {
      const url = req.url;
      // Get params ignoreCache and converse to boolean
      const ignoreCache = JSON.parse(req.params.get('ignoreCache'));
      const isPatternMatch = (pattern: string | RegExp, targetUrl: string) => {
        if (typeof pattern === 'string') {
          return targetUrl.indexOf(pattern) > -1;
        }
        return !!pattern.test(url);
      };
      const found: any = APIS_TO_CACHE.find((api) => ignoreCache !== true ? isPatternMatch(api.url, url) : false);
      if (found) {
        const cache = this._cache.getCache(req) || null;

        if (cache) {
          return of(cache);
        }

        return next.handle(req).pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              this._cache.setCache(req, event, found.expiry);
            }
          })
        );
      }
    }

    return next.handle(req);
  }
}
