<div [ngSwitch]="show">

   <!-- <div *ngSwitchCase="'loading'" class="spinner" text-center>
    <img src="assets/images/bars.svg" alt="loader">
  </div> -->

   <div *ngSwitchCase="'loading'">
      <app-spinner></app-spinner>
   </div>

   <div *ngSwitchCase="'content'">
      <ng-content></ng-content>
   </div>

   <div *ngSwitchDefault class="no-content">
      <h2>No Content</h2>
   </div>

</div>
