import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DateService } from '@app-core/services/date.service';
import { StorageService } from '@app-core/services/storage.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { DefaultInputModel } from './../../dashboard3.models';
import { FeatureFlagService } from '@modules/feature-flag/services/feature-flag.service';

@Component({
  selector: 'app-driver-search',
  templateUrl: './driver-search.component.html',
  styleUrls: ['./driver-search.component.scss'],
})
export class DriverSearchComponent implements OnInit, OnDestroy {
  @Output()
  public selectChange = new EventEmitter<any>();

  public listEnabled = false;
  public selectedName = '';
  public focusedIndex = 0;
  public displayList = [];
  public list = [];
  public displayProp = 'driverName';
  public homeLocationList = [];
  public key = '';
  public divisionNames: string | string[] = '';
  public isLoading = false;
  public mock = [1,2,3,4,5,6];

  private _filterChangeSubscription: any;

  constructor(
    private _dateService: DateService,
    private _zcfleet: ZCFleetService,
    private _storageService: StorageService,
    private _featureFlagService: FeatureFlagService
  ) {
    this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(
      () => this._getDrivers()
    );
  }

  public ngOnInit() {
    this._getDrivers();
  }

  public ngOnDestroy() {
    // Unsubscribe from filter change event
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  // Called when the search input value changes
  public searchChange(key) {
    this._enableList();
    this.key = key;
    // If nothing is entered or when the input is cleared, show the entire list
    if (!key) {
      this.displayList = this.list;
      return;
    }
    // else filter the list on the entered key text
    this.displayList = this._filterList(key);
  }

  // On focus, set focus index to 0 and empty the search result array
  public onFocus() {
    this.focusedIndex = 0;
    if (this.key === '') {
      this.displayList = this.list;
    } else {
      this._enableList();
    }
  }

  // Increase focus index by 1
  public onKeyDown() {
    this.focusedIndex = this._getIndex(this.focusedIndex + 1);
  }

  // Decrease focus index by 1
  public onKeyUp() {
    this.focusedIndex = this._getIndex(this.focusedIndex - 1);
  }

  // On enter, pick the element from the current focus index
  public onEnter() {
    this.onSelect(this.displayList[this.focusedIndex]);
  }

  // Set the input with the selected text
  // Emit the selected value and disable the list
  public onSelect(item) {
    this.selectedName = this.getText(item);
    this._disableList();
    this.selectChange.emit(item);
  }

  public getText(ob) {
    if (typeof ob === 'string') {
      return ob;
    }
    if (typeof ob === 'object') {
      return ob[this.displayProp];
    }
  }

  /**
   * @description: check whether the click has been inside the dropdown body or not
   * @param: clickinside boolean value
   */
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      this._disableList();
    }
  }

  /** Gets drivers list from Server or cache */
  private _getDrivers() {
    // get division
    const divisionsFromStorageService =
      this._storageService.getStorageValue('HOME_LOCATION');
    if (Array.isArray(divisionsFromStorageService)) {
      this.divisionNames = divisionsFromStorageService
        ? [...divisionsFromStorageService] : '';
    } else {
      this.divisionNames = divisionsFromStorageService
        ? divisionsFromStorageService[0] : '';
    }

    // use flow get driver list from lightmetrics
    const date = this._dateService.getDateRangeInISO(60);
    const driverSearchParams = {
      params: new DefaultInputModel(
        date.from,
        date.to,
        '',
        '',
        '',
        this.divisionNames
      ),
    };
    // use flow get driver list from entity api
    const driverSearchParamsEntity = this.divisionNames
      ? { params: { divisionNames: this.divisionNames } }
      : {};

    const driverProfileList$ = combineLatest([
      this._featureFlagService.isFlagEnabled('driverFilterFallbackToLM'),
      this._featureFlagService.isFlagEnabled('driverFilterAlwaysUseLM'),
    ]).pipe(
      switchMap(([flagFallbackToLM, flagAlwaysUseLM]) => {
        this.isLoading = true;
        if (flagFallbackToLM && !flagAlwaysUseLM) {
          // regardless of fleet 1.0 or fleet 2.0, get driver profile from entity database
          return this._zcfleet.getDriverProfileList(driverSearchParamsEntity).pipe();
        } else if (flagAlwaysUseLM) {
          return this._zcfleet.getDriverList(driverSearchParams);
        } else {
          return this._zcfleet.getDriverProfileList(driverSearchParamsEntity);
        }
      })
    );

    driverProfileList$.subscribe(
      (res) => {
        this.isLoading = false;
        this.list = res;
        if (this.listEnabled) {
          this.searchChange(this.key);
        }
      },
      (err) => {
        this.isLoading = false;
        console.error(`Received Error: ${err}`);
      }
    );
  }

  // Helpers
  // function to keep the index within the range form 0 to array length
  private _getIndex(val) {
    if (val < 0) {
      return this.displayList.length - 1;
    }
    if (val >= this.displayList.length) {
      return 0;
    }
    return val;
  }

  // Function to filter the matching names
  private _filterList(key: string) {
    key = key.toLowerCase();
    const list = this.list.filter((ele) => {
      const name = this.getText(ele);
      if (name) {
        // Search function
        const search = (searchKey, searchText) => {
          const text = searchText.slice(0, searchKey.length).toLowerCase();
          return searchKey === text;
        };
        // When the search string is a single word
        if (key.indexOf(' ') !== -1) {
          return search(key, name);
        }
        // When the search string has more than a single word
        const words = name.split(' ');
        return words.some((word) => search(key, word));
      }
      return false;
    });

    return list;
  }

  // Shows list dropdown
  private _enableList() {
    this.listEnabled = true;
  }

  // Hides list dropdown
  private _disableList() {
    this.listEnabled = false;
  }
}
