import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import * as Highcharts from 'highcharts';
import { merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-driver-total-incident',
  templateUrl: './driver-total-incident.component.html',
  styleUrls: ['./driver-total-incident.component.scss'],
  providers: [DatePipe],
})
export class DriverTotalIncidentComponent implements OnInit {
  @Input()
  public set tripDetails$(value: Observable<any>) {
    this.internalTripDetails$ = value.pipe(shareReplay());
  }

  @Input() public set incidentStat$(value: Observable<any>) {
    this.internalIncidentStat$ = value.pipe(
      map(this.filterIncidentList.bind(this))
    );
  }

  @Input()
  public set reload$(value: Observable<boolean>) {
    const tripDetailState = merge(value, this.internalTripDetails$);
    const incidentStatState = merge(value, this.internalIncidentStat$);
    const mapper = map((mergeValue) => mergeValue !== true);

    this.notLoadingState = {
      tripDetail$: tripDetailState.pipe(mapper),
      incidentStat$: incidentStatState.pipe(mapper),
    };
  }
  @Input()
  public ranges: any;

  public highcharts = Highcharts;
  public tripDetailTotal = false;
  public shouldUpdatePieChart = true;
  public displayAmount = 5;

  public internalIncidentStat$: Observable<any>;
  public internalTripDetails$: Observable<any>;
  public notLoadingState: {
    tripDetail$: Observable<boolean>;
    incidentStat$: Observable<boolean>;
  };

  constructor(private _datePipe: DatePipe) {}

  public ngOnInit(): void {}

  public filterIncidentList(responseList: IncidentStat[]): IncidentStat[] {
    if (responseList) {
      const filteredList = responseList
        .slice()
        .sort((a, b) => b.value - a.value)
        .filter((item) => item.value > 0);
      if (filteredList.length > this.displayAmount) {
        const otherEvent: IncidentStat = {
          name: 'Others',
          value: 0,
          incidentClass: 'incident-others',
        };
        filteredList
          .splice(this.displayAmount - 1, filteredList.length)
          .forEach((evt) => {
            otherEvent.value += evt.value;
          });
        filteredList.push(otherEvent);
      }
      return filteredList;
    }
    return [];
  }

  public getMaxValue(responseList: IncidentStat[]): number {
    return Math.max(...responseList.map((evt) => evt.value));
  }

  public buildPieChartInnerData(pieChartOptions: any) {
    const title =
      '<span class="info-title">Total<br>Incidents</span><span class="info-number">' +
      pieChartOptions.title.text +
      '</span>' +
      `<span class="info-date">since ${this._datePipe.transform(
        this.ranges?.from,
        'MM/dd'
      )}</span>`;
    pieChartOptions.title.text = title;
    return pieChartOptions;
  }
}
