import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { createMomentObjectUtc } from '@modules/shared/utils';
import moment from 'moment-timezone';

const SECOND_IN_HALF_DAY = 43200;
const MAXIMUM_DIGIT_IN_FORMAT = 6;

type DateTimeStrWithTz = string;
type FormattedTimeStr = string;

interface TimeRange {
  isSameDate: boolean;
  isAMOnly: boolean;
  isPMOnly: boolean;
  am: {
    min: number;
    max: number;
  };
  pm: {
    min: number;
    max: number;
  };
}

export interface EmitTimeValue {
  timeStrTz: string;
  deltaTime: number;
}
export interface TripTimeInfo {
  startUTC: string;
  endUTC: string;
  timezone: string;
}

@Component({
  selector: 'app-time-input-field',
  templateUrl: './time-input-field.component.html',
  styleUrls: ['./time-input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeInputFieldComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TimeInputFieldComponent,
    },
  ],
})
export class TimeInputFieldComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() public set tripTimeInfo(value: TripTimeInfo) {
    if (value) {
      this._timezone = value.timezone;
      this._startMoment = createMomentObjectUtc(value.startUTC).tz(value.timezone);
      this._endMoment = createMomentObjectUtc(value.endUTC).tz(value.timezone);
      this._startMoment.set('second', 0);
      this._endMoment.set('second', 0);
      this._startMoment.set('millisecond', 0);
      this._endMoment.set('millisecond', 0);
      this.timeRange = this._calculateValidTimeRange();
    }
  }
  public formatHint = 'HH:MM:SS';
  public disableDropDown = false;
  public displayValue = '';
  public tzOffset = null;
  public timeRange: TimeRange = null;
  public ampmValue: 'AM' | 'PM' = null;
  public deltaTime = 0;

  private _startMoment: moment.Moment;
  private _endMoment: moment.Moment;
  private _timezone: string;

  constructor() {}

  public validate(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value;
    if (!controlValue) {
      return null;
    }
    const startDate = this._startMoment.valueOf();
    return controlValue >= startDate ? null : { incorrectTimeInput: true };
  }

  public registerOnValidatorChange?(_fn: () => void): void {}

  public onChange = (_item: EmitTimeValue) => {};

  public onTouch = (_item: any) => {};

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disableDropDown = isDisabled;
  }

  /** Process input value from set function*/
  public writeValue(value: string): void {
    if (value) {
      const setTime = createMomentObjectUtc(value).tz(this._timezone);
      this.ampmValue = setTime.hour() < 12 ? 'AM' : 'PM';
      const hours = setTime.hour() % 12;
      const displayTime = [hours ? hours : 12, setTime.minute(), setTime.second()].join(':');
      this.displayValue = this._fillMissingNumber(displayTime);
      this.deltaTime = setTime.valueOf() - this._startMoment.valueOf();
    } else {
      this.displayValue = null;
      this.deltaTime = 0;
      this.ampmValue = null;
    }
  }

  public ngOnInit(): void {}

  public handleKeyDown(evt: KeyboardEvent, currentValue: string) {
    const isDigit = (key: string) => /\d/g.test(key);
    if (currentValue && 'Backspace' === evt.key) {
      if (currentValue.slice(-1) === ':') {
        this.displayValue = this.displayValue.replace(/.$/, '');
      }
      return true;
    }
    // Check allow characters
    const withoutColonStr = currentValue.replace(/:/g, '');
    if (isDigit(evt.key) && withoutColonStr.length < MAXIMUM_DIGIT_IN_FORMAT) {
      if (withoutColonStr && withoutColonStr.length % 2 === 0 && currentValue.split('').pop() !== ':') {
        this.displayValue += ':';
      }
      return true;
    }
    return false;
  }

  /** Calculate ms user input and emit the value to form control */
  public finalizeUserInputTime() {
    const emitValue: EmitTimeValue = {
      deltaTime: 0,
      timeStrTz: '',
    };

    // User has typed in something already
    if (this.displayValue) {
      // Format it to HH:MM:SS first
      const formatedTime = this._fillMissingNumber(this.displayValue);
      const [hour, min, sec] = formatedTime.split(':');

      // validate the input time and auto-correct it then update the delta time in ms
      const [isoString, displayTime] = this._fixToTimeRange(Number(hour), Number(min), Number(sec));
      emitValue.deltaTime = this.deltaTime;
      emitValue.timeStrTz = isoString;

      // update the display time in the input field after auto-correct
      this.displayValue = displayTime;
    }

    // Notify form control about the time changed
    this.onChange(emitValue);
  }

  /**Calcualte new time value with selected option for the duration and emit new value to form control */
  public onDropdownChange(selectedOption) {
    const emitValue: EmitTimeValue = {
      timeStrTz: '',
      deltaTime: 0,
    };
    const newVal = selectedOption.value;
    const halfDayMs =  SECOND_IN_HALF_DAY * 1000;
    const startMs = this._startMoment.valueOf();
    const endMs = this._endMoment.valueOf();

    if (this.displayValue) {
      const [hour, min, sec] = this.displayValue.split(':');
      const inputSec = (Number(hour) === 12 ? 0 : Number(hour)) * 3600 + Number(min) * 60 + Number(sec);
      const isCurrentTimeAM = this.timeRange.am.min <= inputSec && inputSec <= this.timeRange.am.max;
      const isCurrentTimePM = this.timeRange.pm.min <= inputSec && inputSec <= this.timeRange.pm.max;
      let estimatedTime = startMs + this.deltaTime;

      if (newVal === 'PM') {
        estimatedTime += this.timeRange.isSameDate ? halfDayMs : (-1) * halfDayMs;
      } else {
        estimatedTime += this.timeRange.isSameDate ? (-1) * halfDayMs : halfDayMs;
      }

      // AM/PM after changed is valid
      if ((isCurrentTimeAM && newVal === 'AM') || (isCurrentTimePM && newVal === 'PM')) {
        emitValue.deltaTime = estimatedTime - startMs;
        emitValue.timeStrTz = new Date(estimatedTime).toISOString();
        this.deltaTime = emitValue.deltaTime;
      } else {
        if (newVal === 'AM') {
          estimatedTime = this.timeRange.isSameDate ? startMs : endMs;
        } else {
          estimatedTime = this.timeRange.isSameDate ? endMs : startMs;
        }
        this.deltaTime = estimatedTime - startMs;
        const isoString = new Date(estimatedTime).toISOString();
        const currentMoment = createMomentObjectUtc(isoString).tz(this._timezone);
        let newHour = currentMoment.hour() % 12;
        newHour = newHour ? newHour : 12;
        this.displayValue = this._fillMissingNumber([newHour, currentMoment.minute(), currentMoment.second()].join(':'));
        emitValue.deltaTime = this.deltaTime;
        emitValue.timeStrTz = isoString;
      }
      this.onChange(emitValue);
    }
  }

  /** Fill the missing 00 when user not finishing the input time */
  private _fillMissingNumber(timeStr: string): string {
    let [hour = 0, minute = 0, second = 0] = timeStr.split(':');

    if (hour === 0) {
      return '';
    }

    hour = ('0' + hour).slice(-2);
    minute = ('0' + minute).slice(-2);
    second = ('0' + second).slice(-2);

    return [hour, minute, second].join(':');
  }

  /** Check if the start time and end time are from the same day */
  private _isSameDate(start: moment.Moment, end: moment.Moment): boolean {
    return start.isSame(end, 'day');
  }

  /** Update the user input time to fixed within the trip duration */
  private _fixToTimeRange(hour: number, min: number, sec: number): [DateTimeStrWithTz, FormattedTimeStr] {
    const inputInSeconds = (hour === 12 ? 0 : hour) * 3600 + min * 60 + sec;
    const generateTimePart = (date: moment.Moment) =>
      [
        date.hour() % 12 ? date.hour() % 12 : 12,
        date.minute(),
        date.second(),
      ].join(':');
    this.deltaTime = this._calculateDeltaTimeMs(inputInSeconds);
    const isoString = new Date(this._startMoment.valueOf() + this.deltaTime).toISOString();
    const currentTimeMoment = createMomentObjectUtc(isoString).tz(this._timezone);
    const formattedTime = generateTimePart(currentTimeMoment);
    return [isoString, this._fillMissingNumber(formattedTime)];
  }

  /** Return the time range */
  private _calculateValidTimeRange(): TimeRange {
    const startTime = this._startMoment;
    const endTime = this._endMoment;
    const tripStartSec = this._convertToSecWithAmPm(startTime);
    const tripEndSec = this._convertToSecWithAmPm(endTime);
    const isStartAM = startTime.hour() < 12;
    const isEndAM = endTime.hour() < 12;

    const timeRange = {
      isAMOnly: false,
      isPMOnly: false,
      isSameDate: this._isSameDate(startTime, endTime),
      am: {
        min: 0,
        max: 0,
      },
      pm: {
        min: 0,
        max: 0,
      },
    };

    if (isStartAM && !isEndAM) {
      //AM to PM
      timeRange.am.min = tripStartSec;
      timeRange.am.max = SECOND_IN_HALF_DAY;
      timeRange.pm.min = 0;
      timeRange.pm.max = tripEndSec;
    } else if (!isStartAM && isEndAM) {
      // Overnight
      timeRange.am.max = tripEndSec;
      timeRange.am.min = 0;
      timeRange.pm.max = SECOND_IN_HALF_DAY;
      timeRange.pm.min = tripStartSec;
    } else if (isStartAM && isEndAM) {
      // AM only
      timeRange.isAMOnly = true;
      timeRange.am.min = tripStartSec;
      timeRange.am.max = tripEndSec;
    } else {
      //PM only
      timeRange.isPMOnly = true;
      timeRange.pm.min = tripStartSec;
      timeRange.pm.max = tripEndSec;
    }

    // Disable the value option for dropdown
    if (timeRange.isAMOnly) {
      this.ampmValue = 'AM';
      this.disableDropDown = true;
    } else if (timeRange.isPMOnly) {
      this.ampmValue = 'PM';
      this.disableDropDown = true;
    }

    return timeRange;
  }

  private _convertToSecWithAmPm(date: moment.Moment) {
    const hour = (date.hour() + 11) % 12 + 1;
    const minute = date.minute();
    const second = date.second();
    return (hour === 12 ? 0 : hour) * 3600 + minute * 60 + second;
  }

  /** Calculate the delta Ms from the start to the time of that user input*/
  private _calculateDeltaTimeMs(inputSec: number): number {
    let deltaMs = 0;
    const isCurrentTimeAM = this.timeRange.am.min <= inputSec && inputSec <= this.timeRange.am.max;
    const isCurrentTimePM = this.timeRange.pm.min <= inputSec && inputSec <= this.timeRange.pm.max;

    if (this.timeRange.isAMOnly) {
      this.ampmValue = 'AM';
      deltaMs = isCurrentTimeAM ? (inputSec - this.timeRange.am.min) * 1000 : 0;
    } else if (this.timeRange.isPMOnly) {
      this.ampmValue = 'PM';
      deltaMs = isCurrentTimePM ? (inputSec - this.timeRange.pm.min) * 1000 : 0;
    } else {
      if (isCurrentTimeAM && !isCurrentTimePM) {
        this.ampmValue = 'AM';
        deltaMs = this.timeRange.isSameDate
          ? (inputSec - this.timeRange.am.min) * 1000
          : (inputSec + this.timeRange.pm.max - this.timeRange.pm.min) * 1000;
      } else if (!isCurrentTimeAM && isCurrentTimePM) {
        this.ampmValue = 'PM';
        deltaMs = this.timeRange.isSameDate
          ? (this.timeRange.am.max - this.timeRange.am.min + inputSec) * 1000
          : (inputSec - this.timeRange.pm.min) * 1000;
      } else if (isCurrentTimeAM && isCurrentTimePM) {
        if (!this.ampmValue) {
          this.ampmValue = this.timeRange.isSameDate ? 'AM' : 'PM';
        }
        switch (this.ampmValue) {
          case 'AM':
            deltaMs = this.timeRange.isSameDate
              ? (inputSec - this.timeRange.am.min) * 1000
              : (inputSec + this.timeRange.pm.max - this.timeRange.pm.min) * 1000;
            break;
          case 'PM':
            deltaMs = this.timeRange.isSameDate
              ? (inputSec + this.timeRange.am.max - this.timeRange.am.min) * 1000
              : (inputSec - this.timeRange.pm.min) * 1000;
            break;
          default:
            break;
        }
      } else {
        this.ampmValue = this.timeRange.isSameDate ? 'AM' : 'PM';
      }
    }

    return deltaMs;
  }
}
