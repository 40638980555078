import {
  Component,
  SimpleChanges,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { Options } from 'ng5-slider';
import { Observable } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/naming-convention
const OptionsList = {
  1: 'All Drivers',
  2: 'Top 20% Drivers',
  3: 'Bottom 20% Drivers',
  4: 'All Drivers',
};

// Options for ng5-slider
const OPTIONS: Options = {
  floor: 0,
  ceil: 100,
  step: 1,
  noSwitching: true,
  disabled: false,
  hideLimitLabels: true,
  hidePointerLabels: true,
};

@Component({
  selector: 'app-driver-filter',
  templateUrl: './driver-filter.component.html',
  styleUrls: ['./driver-filter.component.scss'],
})
export class DriverFilterComponent implements OnInit, OnChanges {
  @Input()
  public reset: Observable<void>;
  @Input()
  public histogramData: any[] = [];
  @Input()
  public histogramPercentile: any[] = [];
  @Input()
  public modeScoreFrequency = 1;
  @Input()
  public set driverCount(value: number) {
    if (!Number.isInteger(value)) {
      return;
    }
    this.driverCountValue = value;
  };
  @Input()
  public minScore = 0;
  @Input()
  public maxScore = 100;
  @Input()
  public rangeValue;
  @Input()
  public isExportSet: boolean;

  @Output()
  public minScoreChange = new EventEmitter<number>();
  @Output()
  public maxScoreChange = new EventEmitter<number>();
  @Output()
  public rangeValueChange = new EventEmitter<number>();
  @Output()
  public applyChanges = new EventEmitter<void>();
  @Output()
  public discardChanges = new EventEmitter<void>();

  public isOpen = false;
  public options = OPTIONS;
  public optionsList = OptionsList;

  public minValue = 0;
  public maxValue = 100;
  public histogramList = [];
  public rangeValueTemp: number;
  public customRange = false;
  public rangeText = '';
  public showApply = false;
  public driverCountValue: number;

  constructor() {}

  public ngOnInit() {
    // Subscribe for the reset subscription
    if (this.reset) {
      this.reset.subscribe(() => {
        this._setAllToInitial();
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.minScore) {
      if (
        changes.minScore.currentValue ||
        changes.minScore.currentValue === 0
      ) {
        this._setInitialMin();
      }
    }
    if (changes.maxScore) {
      if (
        changes.maxScore.currentValue ||
        changes.maxScore.currentValue === 0
      ) {
        this._setInitialMax();
      }
    }
    if (changes.rangeValue) {
      if (
        changes.rangeValue.currentValue ||
        changes.rangeValue.currentValue === false
      ) {
        this._setInitialRange();
      }
    }

    if (changes.histogramData) {
      const list = changes.histogramData.currentValue;
      if (list && list.length) {
        this._formatHistogram();
      }
    }
  }

  /**
   * @description: get min value change from the user input
   * @param: min score value
   */
  public minValueChange(val) {
    this.showApply = true;
    this._setMin(val);
    this._checkIsCustom();
  }

  /**
   * @description: getting max value change from the user input
   * @param: max score value
   */
  public maxValueChange(val) {
    this.showApply = true;
    this._setMax(val);
    this._checkIsCustom();
  }

  /**
   * @description: If get all drivers selected apply min and max score and apply filter changes
   */
  public allDrivers() {
    this._setMin(0);
    this._setMax(100);
    this._setRange(1);
    this.applyChanges.emit();
    this.applyChanges.emit();
    this._closeFilter();
  }

  /**
   * @description: getting list of top drivers based on top 20% drivers
   */
  public topDrivers() {
    const score = this._getScore(80);
    this._setMin(score);
    this._setMax(100);
    this._setRange(2);
    this.applyChanges.emit();
    this._closeFilter();
  }

  /**
   * @description: getting list of bottom drivers based on bottom 20% drivers
   */
  public bottomDrivers() {
    const score = this._getScore(20);
    this._setMin(0);
    this._setMax(score);
    this._setRange(3);
    this.applyChanges.emit();
    this._closeFilter();
  }

  public emitValueChange() {
    this.minScoreChange.emit(this.minValue);
    this.maxScoreChange.emit(this.maxValue);
  }

  /**
   * @description: apply filters based on user input and close the filter
   */
  public applyFilter() {
    this.applyChanges.emit();
    this._closeFilter();
  }

  public checkDriverCount(value: any): boolean {
    return Number.isInteger(value);
  }

  /**
   * @description: function to toggle filter based on user click
   * @param: type of event
   */
  public toggleFilter(e?: Event) {
    if (this.isOpen) {
      this._setAllToInitial();
      this._closeFilter();
    } else {
      this._openFilter();
    }
    if (e) {
      e.stopPropagation();
    }
  }

  /**
   * @description: check whether the click has been inside the dropdown body
   * @param: clickinside boolean value
   */
  public clickedInside(isClickedInside) {
    if (!isClickedInside) {
      if (this.isOpen) {
        this.discardChanges.emit();
      }
      this._setAllToInitial();
      this._closeFilter();
    }
  }

  // set minValue to initial value which was before changing
  private _setInitialMin() {
    this.minValue = this.minScore;
  }

  // set maxValue to initial value which was before changing
  private _setInitialMax() {
    this.maxValue = this.maxScore;
  }

  // Set range text from the rangeValue input
  private _setInitialRange() {
    this.rangeValueTemp = this.rangeValue;
    this.customRange = this.rangeValue === 4 ? true : false;
    this.rangeText = OptionsList[this.rangeValue];
  }

  // Set everything to initial value discarding the changes made to each value
  private _setAllToInitial() {
    this._setInitialMin();
    this._setInitialMax();
    this._setInitialRange();
    this.showApply = false;
  }

  /**
   * @description: setting min score value
   */
  private _setMin(val) {
    this.minValue = val;
    this.minScoreChange.emit(val);
  }

  /**
   * @description: setting max score value
   */
  private _setMax(val) {
    this.maxValue = val;
    this.maxScoreChange.emit(val);
  }

  /**
   * @description: set range for driver score from input range slider
   */
  private _setRange(val: number) {
    this.rangeValueTemp = val;
    this.rangeValueChange.emit(val);
  }

  /**
   * @description: checking whether the score has been custom selected
   */
  private _checkIsCustom() {
    if (this.minValue === 0 && this.maxValue === 100) {
      this._setRange(1);
    } else {
      this._setRange(4);
    }
  }

  /**
   * @description: function to close filter
   */
  private _closeFilter() {
    this.isOpen = false;
  }

  /**
   * @description: function to open filter
   */
  private _openFilter() {
    this.isOpen = true;
    this.showApply = false;
  }

  /**
   * @description: get score for histogram
   * @param: score value
   */
  private _getScore(val: number) {
    let score = 0;
    for (let i = 0; i < this.histogramPercentile.length; i++) {
      if (this.histogramPercentile[i] >= val) {
        score = i;
        break;
      }
    }
    return score;
  }

  // From the histogram data provided, prepare a list to be displayed as
  // histogram
  private _formatHistogram() {
    this.histogramList = this.histogramData.map((ele) =>
      Math.floor(((ele.count / this.modeScoreFrequency) * 100) / 2)
    );
  }
}
