import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared/shared.module';
import { Dashboard3Module } from '@modules/dashboard3/dashboard3.module';

import { UnconnectedTripsComponent } from './pages/unconnected-trips/unconnected-trips.component';

import { UnconnectedTripsService } from '@modules/unconnected-trips/services/unconnected-trips.service';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, Dashboard3Module],
  declarations: [UnconnectedTripsComponent],
  providers: [UnconnectedTripsService],
})
export class UnconnectedTripsModule {}
