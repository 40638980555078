/* eslint-disable @typescript-eslint/naming-convention */
// export const EventsList = {
//   BrakingAcclEvent: null,
//   SpeedingEvent: null,
//   CorneringEvent: null,
//   UpperSpeedingEvent: null,
//   ModeofOperation: null
// };

export const ValueList = {
  BrakingAcclEvent: [
    {
      value: 4.5,
      displayString: 'low 4.5 mph/sec',
    },
    {
      value: 6.0,
      displayString: 'medium 6.0 mph/sec',
    },
    {
      value: 8.0,
      displayString: 'severe 8.0 mph/sec',
    },
  ],
  SpeedingEvent: [
    {
      value: 5,
      displayString: 'low 5 mph over limit',
    },
    {
      value: 10,
      displayString: 'medium 10 mph over limit',
    },
    {
      value: 15,
      displayString: 'severe 15 mph over limit',
    },
  ],
  CorneringEvent: [
    {
      value: 4.5,
      displayString: 'low 4.5 mph/sec',
    },
    {
      value: 6.0,
      displayString: 'medium 6.0 mph/sec',
    },
    {
      value: 8.0,
      displayString: 'severe 8.0 mph/sec',
    },
  ],
  UpperSpeedingEvent: [
    {
      value: 75,
      displayString: 'low 75 mph',
    },
    {
      value: 80,
      displayString: 'medium 80 mph',
    },
    {
      value: 85,
      displayString: 'severe 85 mph',
    },
  ],
  ModeofOperation: [
    { value: 'yes', displayString: 'Yes' },
    { value: 'no', displayString: 'No' },
  ],
  TailGatingEvent: [
    {
      value: 10,
      displayString: 'Light-Duty',
    },
    {
      value: 20,
      displayString: 'Medium-Duty',
    },
    {
      value: 30,
      displayString: 'Heavy-Duty',
    },
  ],
};

export const FleetSettings = {
  brakingAcclEvent: {
    label: 'Braking and Acceleration Thresholds ',
    description: 'Setting for triggering hard braking and acceleration events',
    currentValue: null,
    newValue: null,
    valueList: ValueList['BrakingAcclEvent'],
  },
  speedingEvent: {
    label: 'Over Speeding Threshold ',
    description: 'Allowed deviation over posted speed limit',
    currentValue: null,
    newValue: null,
    valueList: ValueList['SpeedingEvent'],
  },
  corneringEvent: {
    label: 'Cornering Threshold ',
    description: 'Setting for triggering hard cornering events',
    currentValue: null,
    newValue: null,
    valueList: ValueList['CorneringEvent'],
  },
  upperSpeedingEvent: {
    label: 'Upper Speed Threshold',
    description: 'Maximum allowed speed',
    currentValue: null,
    newValue: null,
    valueList: ValueList['UpperSpeedingEvent'],
  },
  modeofOperation: {
    label: 'Realtime Processing ',
    description: 'Update dashboards in real-time',
    currentValue: null,
    newValue: null,
    valueList: ValueList['ModeofOperation'],
  },
};

export const EventWeight = [
  {
    eventName: 'Harsh Braking',
    value: 0,
  },
  {
    eventName: 'Harsh Acceleration',
    value: 0,
  },
  {
    eventName: 'Cornering',
    value: 0,
  },
  {
    eventName: 'Tail Gating',
    value: 0,
  },
  {
    eventName: 'Speed Limit Violation',
    value: 0,
  },
  {
    eventName: 'STOP Sign Violation',
    value: 0,
  },
  {
    eventName: 'Lane Drift',
    value: 0,
  },
];

export const ASSET_TYPES = [
  { label: 'Heavy Duty', value: 'heavy' },
  { label: 'Medium Duty', value: 'medium' },
  { label: 'Light Duty', value: 'light' },
];

const OPTIONS_SET_1 = [
  { label: 'Off', value: '-1' },
  { label: 'Low (4.5 mph/sec)', value: '4.5' },
  { label: 'Medium (6.0 mph/sec)', value: '6.0' },
  { label: 'High (8.0 mph/sec)', value: '8.0' },
];
export const HARD_BRAKING_OPTIONS = OPTIONS_SET_1;
export const HARD_ACCELERATION_OPTIONS = OPTIONS_SET_1;
export const HARD_CORNERING_OPTIONS = OPTIONS_SET_1;

export const MAX_SPEED_OPTIONS = [
  { label: 'Off', value: '-1' },
  { label: 'Low (75 mph)', value: '75' },
  { label: 'Medium (80 mph)', value: '80' },
  { label: 'High (85 mph)', value: '85' },
];

// export const SPEEDING_OPTIONS = [
//    { label: 'Low 5 mph over limit', value: '5' },
//    { label: 'Medium 10 mph over limit', value: '10' },
//    { label: 'High 15 mph over limit', value: '15' }
// ];

export const SPEEDING_OPTIONS = [
  { label: 'Low 0 mph over limit', value: '0' },
  { label: 'Medium 5 mph over limit', value: '5' },
  { label: 'High 10 mph over limit', value: '10' },
];

export const TOGGLE_OPTIONS = [
  { label: 'Off', value: false },
  { label: 'On', value: true },
];
