<div class="zc-body zc-app-wrap has-search-filter">
  <div class="container-fluid content-wrap">
    <app-display-container [show]="show">
      <div class="row">
        <!-- Trips -->
        <div class="col-sm-12 p-3">
          <div class="export-button">
            <div class="export-loading-text" *ngIf="isLoadingExportData">
              <span>Loading {{loadPercentageExport}}%</span>
            </div>
            <app-inline-spinner
              class="inline-spinner"
              *ngIf="isLoadingExportData"
              [popover]="popTemplate"
              [popoverContext]="context"
              placement="bottom"
              [outsideClick]="true"
            ></app-inline-spinner>
            <button class="btn" [disabled]="!anomalousTripListExport.length || isLoadingExportData" (click)="exportClicked()">
              <span [ngClass]="{
                'text-disabled': !anomalousTripListExport.length || isLoadingExportData
              }">Export to XLSX</span>
            </button>
          </div>
          <div class="card">
            <div class="card-header">
              Anomalous Trips During Last {{dateRange}} days ({{ totalUnconnectedTrips }})
            </div>
            <div class="card-body">
              <p>
                Sort the table by clicking either
                <span class="text-blue">Asset ID</span>,
                <span class="text-blue">Account Code</span> or
                <span class="text-blue">Driver Name</span>
              </p>

              <div *ngIf="anomalousTripList.length">
                <div class="table-fixed-header sort-table">
                  <table class="table table-bordered table-text-center">
                    <thead>
                      <tr>
                        <th class="width-15">Trip ID</th>
                        <th class="width-10 text-blue">
                          <span
                            [class.asc]="sortBy === 'assetID' && ascending"
                            [class.dsc]="sortBy === 'assetID' && !ascending"
                            (click)="sortOn('assetID')"
                          >
                            Asset ID
                          </span>
                        </th>
                        <th class="width-10 text-blue">
                          <span
                            [class.asc]="sortBy === 'accountCode' && ascending"
                            [class.dsc]="sortBy === 'accountCode' && !ascending"
                            (click)="sortOn('accountCode')"
                            >Account Code</span
                          >
                        </th>
                        <th class="width-10">Driver ID</th>
                        <th class="width-15 text-blue">
                          <span
                            [class.asc]="sortBy === 'driverName' && ascending"
                            [class.dsc]="sortBy === 'driverName' && !ascending"
                            (click)="sortOn('driverName')"
                            >Driver Name</span
                          >
                        </th>
                        <th class="width-15">Start Time</th>
                        <th class="width-10">Trip Distance (miles)</th>
                        <th class="width-10">Trip Duration</th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div class="table-400 table-responsive">
                  <table
                    class="
                      table
                      table-text-center
                      table-striped
                      table-bordered
                      table-hover
                    "
                  >
                    <tbody>
                      <tr
                        *ngFor="
                          let trip of anomalousTripList | sort: sortBy:ascending
                        "
                      >
                        <td class="width-15">{{ trip.tripID }}</td>
                        <td class="width-10">{{ trip.assetID }}</td>
                        <td class="width-10">{{ trip.accountCode }}</td>
                        <td class="width-10">{{ trip.driverID }}</td>
                        <td class="width-15">{{ trip.driverName }}</td>
                        <td class="width-15">{{ trip.startTime1 }}</td>
                        <td class="width-10">
                          {{
                            trip.tripDistance
                              | distance: 'miles'
                              | number: '2.0-2'
                          }}
                        </td>
                        <td class="width-10">
                          {{ trip.tripDuration | duration }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="paginator-wrap">
                  <app-paginator3
                    [pageLimit]="pageSize"
                    [length]="totalUnconnectedTrips"
                    (pageChange)="getUnconnectedTrips()"
                  >
                  </app-paginator3>
                </div>
              </div>

              <div class="no-content" *ngIf="!anomalousTripList.length">
                <h3>No Trips Found</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Trips -->
      </div>
    </app-display-container>
  </div>
</div>
