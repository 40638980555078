import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  public transform(value: number, to: string): number {
    if (value && value !== 0) {
      if (to === 'miles') {
        return +value * 0.621371;
      }
    }
    return value;
  }
}
