<div class="export-btn" *ngIf="config.showExport">
  <button class="btn" data-test="export-button" (click)="exportClicked()">Export (CSV)</button>
</div>
<div class="search-filter-container">
        <div class="bar-top">
        </div>

        <div class="search-filter">
          <span class="back-button" (click)="goBack()" *ngIf="config.backButton.visible">
              <img class="back-arrow" src="assets/images/arrow_down.png" alt="back-arrow">
              <span class="back-button-text">{{backText}}</span>
          </span>

          <div class="search-left">

              <app-driver-search *ngIf="config.showSearch"
                  (selectChange)="driverSelectionChange($event)">
              </app-driver-search>

              <app-unassigned-cameras-search *ngIf="config.showUnassignCamFilter"
                  (selectChange)="unassignCamSelectionChange($event)"></app-unassigned-cameras-search>

              <div class="dropdown-wrap mobile"  *ngIf="config.showDateFilter || config.showDriverFilter || config.showDutyTypeFilter">
                  <a class="dropdown-head" [class.active]="isOpen" (click)="toggleFilter()">
                      <span class="label">Filters</span>
                      <span class="arrow-down" *ngIf="!isOpen"></span>
                      <span class="arrow-up" *ngIf="isOpen"></span>
                  </a>
              </div>
          </div>

          <div class="search-right" [class.show]="isOpen">

            <app-date-filter *ngIf="config.showDateFilter"
                [value]="days"
                data-test="date-filter"
                (valueChange)="setDateRange($event)"
                (applyChanges)="applyFilter()"
                (discardChanges)="discardChanges()"
                [isExportSet]="isExportSet">
            </app-date-filter>

            <app-driver-filter *ngIf="config.showDriverFilter"
                [reset]="reset"
                data-test="driver-filter"
                [histogramData]="histogramData"
                [histogramPercentile]="histogramPercentile"
                [modeScoreFrequency]="modeScoreFrequency"
                [driverCount]="driverCount"
                [minScore]="minScore"
                [maxScore]="maxScore"
                [rangeValue]="rangeValue"
                (minScoreChange)="minScoreTemp = $event"
                (maxScoreChange)="maxScoreTemp = $event"
                (rangeValueChange)="rangeValueTemp = $event"
                (applyChanges)="applyFilter()"
                (discardChanges)="discardChanges()"
                [isExportSet]="isExportSet">
            </app-driver-filter>

            <app-location-filter *ngIf="config.showLocationFilter"
                [reset]="reset"
                [homeLocation]="homeLocation"
                (applyChanges)="applyFilter($event)"
                (discardChanges)="discardChanges()"
                (homeLocationChange)="setHomeLocation($event)"
                [isExportSet]="false"
                [isHomeLocationEditable]="isHomeLocationEditable">
            </app-location-filter>

            <app-duty-type-filter *ngIf="config.showDutyTypeFilter"
                [dutyType]="dutyType"
                data-test="duty-type-filter"
                (dutyTypeChange)="setDutyType($event)"
                (applyChanges)="applyFilter()"
                (discardChanges)="discardChanges()"
                [isExportSet]="isExportSet">
            </app-duty-type-filter>

            <div class="dropdown-apply mobile">
                <a (click)="applyAllFilters()">Apply</a>
            </div>
        </div>

        <!-- <div class="bar-bottom"></div> -->
</div>
