import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { SnackbarService } from '@app-core/services/snackbar.service';

const ASSET_NOT_FOUND = 'Asset not found';

@Injectable()
export class AssetvalidationAccessGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _zcFleet: ZCFleetService,
    private _snackbarService: SnackbarService
  ) {}

  /**
   * @description: using canactivate guard to check whenever user acces to smart fleet's panels
   */
  public canActivate(route: ActivatedRouteSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const assetId = route.paramMap.get('assetId');

    if (assetId === 'undefined') {
      this._snackbarService.error('Unable to navigate, please try again later.');
      return of(false);
    }

    return this._zcFleet.getAssetNameByAssetId(assetId).pipe(
      catchError((error) => {
        const errorMsg = error.error.message;
        if (errorMsg === ASSET_NOT_FOUND) {
          this._router.navigate(['/dashboard']); // TO-DO: update navigate router
          this._snackbarService.error(errorMsg);
        }
        return of(error);
      }),
      map(() => true)
    );
  }
}
