<div class="identification-card">
  <div class="text">
    <ng-container
      *ngIf="(divisionName$ | async) && (displayName$ | async); else loadingInfo"
    >
      <div class="division-name">
        <div class="bold-text">Division</div>
        <div
          class="normal-text"
          #tooltipDivision="matTooltip"
          (click)="tooltipDivision.toggle()"
          matTooltip="{{ divisionNameTooltip$ | async }}"
          matTooltipClass="text-name-tooltip"
          [matTooltipPosition]="divisionTooltipPosition"
        >
          {{ divisionName$ | async }}
        </div>
      </div>

      <div
        class="name"
        #tooltip="matTooltip"
        (click)="tooltip.toggle()"
        matTooltip="{{ nameTooltip$ | async }}"
        matTooltipClass="text-name-tooltip"
        [matTooltipPosition]="nameTooltipPosition"
      >
        {{ displayName$ | async }}
      </div>
    </ng-container>
  </div>

  <!-- TO-DO: unhide when groupBy supported for API Aggregate Asset Violations -->
  <div *appFeatureFlag="'showAssetSnapshotTables'">
    <ng-containter
      *ngIf="notLoadingState?.graphData | async; else loadingGraph"
    >
      <ng-container *ngIf="graphData$ | async as data; else loadingGraph">
        <div class="graph">
          <div class="chart-info">
            <div>
              <div
                class="text-filter-identification"
                *ngIf="!isCustomDateRange"
              >
                Past {{ data.numberOfDays }}
                <span>{{ data.numberOfDays > 1 ? 'Days' : 'Day' }}</span>
                Incident Trend
              </div>
              <div class="text-filter-identification" *ngIf="isCustomDateRange">
                Incident Trends For Selected Dates
              </div>
            </div>
            <div class="chart">
              <div>
                <highcharts-chart
                  [Highcharts]="highcharts"
                  [options]="chartOptions$ | async"
                  [(update)]="data.updateChart"
                  [oneToOne]="true"
                >
                </highcharts-chart>
                <div class="chart-incident-value">
                  <span class="pull-left">{{ firstGraphValue }}</span>
                  <span></span>
                  <span class="pull-right">{{ lastGraphValue }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="chart-info-average">
            <div>{{ averageGraphValue }}</div>
          </div>
        </div>
      </ng-container>
    </ng-containter>
  </div>
</div>

<!-- loading skeleton for info name and division -->
<ng-template #loadingInfo>
  <app-custom-skeleton-loader
    [theme]="{
      width: '265px',
      height: '80px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<!-- loading skeleton for graph chart-->
<ng-template #loadingGraph>

  <div class="no-graph">
    <app-custom-skeleton-loader
      [theme]="{
        'margin-top': '36px',
        width: '389px',
        height: '80px'
      }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>
