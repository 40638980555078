<app-card [showDetailButton]="false">
  <ng-container card-title>Key Metrics</ng-container>
  <ng-container card-body>
    <table class="metric-table">
      <tbody>
        <ng-container
          *ngIf="isNotLoading$ | async as isNotLoading; else skeletonLoading"
        >
          <ng-container *ngIf="internalMetricData$ | async as metricData">
            <tr *ngFor="let metricRow of metricHeaders; let i = index">
              <td
                class="metric-table-data"
                [ngClass]="{ 'padding-top': i === metricHeaders.length - 1 }"
                *ngFor="let metric of metricRow.values"
              >
                <ng-container
                  *ngTemplateOutlet="
                    metricLayout;
                    context: {
                      $implicit: metric,
                      data: metricData,
                      longestName: metricRow.longestName
                    }
                  "
                ></ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</app-card>

<ng-template #skeletonLoading>
  <tr *ngFor="let metricRow of metricHeaders; let i = index">
    <td
      class="metric-table-data"
      [ngClass]="{ 'padding-top': i === metricHeaders.length - 1 }"
      *ngFor="let metric of metricRow.values"
    >
      <ng-container
        *ngTemplateOutlet="
          metricLayout;
          context: {
            $implicit: metric,
            isLoading: true,
            longestName: metricRow.longestName
          }
        "
      ></ng-container>
    </td>
  </tr>
</ng-template>

<ng-template
  #metricLayout
  let-metric
  let-longestName="longestName"
  let-metricData="data"
  let-isLoading="isLoading"
>
  <div class="metric-container">
    <div class="metric-name real-value">{{ metric }}</div>
    <div class="metric-name place-holder">{{ longestName }}</div>
    <!-- Values data of metric -->
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container
        *ngTemplateOutlet="
          metricAggregate;
          context: { $implicit: filterMetricDataByName(metricData, metric) }
        "
      ></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #metricAggregate let-metricAggregate>
  <div class="metric-value">
    {{ metricAggregate.value | number: metricAggregate.decimalType }}
  </div>
  <div class="metric-difference">
    <ng-container
      *ngTemplateOutlet="
        metricAggregate.difference > 0 ? metricUp : metricDown;
        context: { $implicit: metricAggregate.difference }
      "
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #metricUp let-metricValue>
  <span class="metric-up"></span>
  UP {{ metricValue }}% YTD
</ng-template>

<ng-template #metricDown let-metricValue>
  <span class="metric-down"></span>
  DN {{ metricValue }}% YTD
</ng-template>

<!-- loading skeleton -->
<ng-template #loading>
  <div class="skeleton-loader">
    <app-custom-skeleton-loader
      [theme]="{
        width: '100%',
        height: '40px'
      }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>
