import { Injectable } from '@angular/core';
import { EnvironmentFlagSourceService } from './environment-flag-source.service';
import { Observable, of } from 'rxjs';
import { ProvideFlagSource } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {

  private _flagSource: ProvideFlagSource;

  constructor(flagSource: EnvironmentFlagSourceService) {
    this._flagSource = flagSource;
    this._flagSource.normalizeFlagSource();
  }

  // to be deprecated
  public isFlagSet(flagName: string): boolean {
    return this._flagSource.isFlagSet(flagName);
  }

  // We will use this method in the future, since it works nicely
  // with async flow
  public isFlagEnabled(flagName: string): Observable<boolean> {
    return of(this.isFlagSet(flagName));
  }
}
