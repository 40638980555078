import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { cloneDeep } from 'lodash-es';
import * as Highcharts from 'highcharts';
import { combineLatest, merge, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { map, distinctUntilChanged, tap, switchMap, shareReplay } from 'rxjs/operators';

import { LoadingService } from '@app-core/services/loading.service';
import { DateService } from '@app-core/services/date.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Data } from '@modules/dashboard3/services/data.service';
import { StorageService } from '@app-core/services/storage.service';
import { calcSumTotalIncidents } from '@modules/shared/utils';
import { IncidentStat } from '@modules/dashboard3/services/incident-stat.service';
import { DefaultInputModelV2, SearchFilterModel } from '@modules/dashboard3/dashboard3.models';
import { AVERAGE_SCORE_COLOR, BAD_SCORE_COLOR, GOOD_SCORE_COLOR } from '@modules/dashboard3/dashboard3.constants';

interface DateFilterValue {
  from: string;
  to: string;
  isCustomRange?: boolean;
}
export interface KeyMetric {
  name?: string;
  value: number | string;
  decimalType?: string;
}

const MAX_TEXT_LENGTH = 8;
const MS_PER_DAY = 1000 * 60 * 60 * 24;
const MILE_NUMBER = 100;
const SECONDS_OF_A_HOUR = 60 * 60;
const MILE_OF_A_KILOMETER = 0.62137 ;
const CHART_OPTION = {
  title: null,
  chart: {
    renderTo: 'container',
    type: 'column',
    width: null,
    height: 180,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    visible: true,
    title: {
      enabled: true,
      text: '# Of Drivers',
      style: {
        color: '#293239',
        'font-family': '"Open Sans", sans-serif',
        'font-style': 'normal',
        'font-weight': '700',
        'font-size': '12px',
        'line-height': '16px',
        'text-transform': 'uppercase',
      },
    },
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
  },
  tooltip: {
    formatter() {
      return this.points.reduce(
        (s, point) => s + '<br/>' + point.series.name + ': ' + point.y,
        'Score : ' + this.x
      );
    },
    shared: true,
  },
  xAxis: {
    min: null,
    max: 100,
    tickInterval: 10,
    tickLength: 20,
    endOnTick: true,
    lineWidth: 0,
    tickWidth: 0,
    labels: {
      style: {
        fontSize: 16,
        fontWeight: 'normal',
      },
      formatter() {
        if (this.value < 70) {
          return `<span style="fill: ${BAD_SCORE_COLOR};">${this.value}</span>`;
        } else if (this.value < 86) {
          return `<span style="fill: ${AVERAGE_SCORE_COLOR};">${this.value}</span>`;
        } else if (this.value < 101) {
          return `<span style="fill: ${GOOD_SCORE_COLOR};">${this.value}</span>`;
        }
      },
    },
  },
  plotOptions: {
    series: {
      pointWidth: 20,
    },
    column: {
      colorByPoint: true,
      pointWidth: 20,
      minPointLength: 3,
    },
  },
  series: [
    {
      data: [],
      showInLegend: false,
      name: 'Drivers',
      zoneAxis: 'x',
      pointPadding: 2,
      zones: [
        {
          value: 71,
          color: BAD_SCORE_COLOR,
        },
        {
          value: 86,
          color: AVERAGE_SCORE_COLOR,
        },
        {
          value: 101,
          color: GOOD_SCORE_COLOR,
        },
      ],
      pointWidth: 20,
      pointRange: 2,
      pointInterval: 2,
    },
  ],
};
@Component({
  selector: 'app-fleet-performance',
  templateUrl: './fleet-performance.component.html',
  styleUrls: ['./fleet-performance.component.scss'],
})
export class FleetPerformanceComponent implements OnDestroy {
  @Output() public isShowError = new EventEmitter<boolean>();

  public metricHeaders = [
    { values: ['Total Miles', 'Total Hours', 'Total Trips'], longestName: 'Total Hours' },
    { values: ['Incidents / 100mi', 'Incidents / Hour', 'Total Incidents'], longestName: 'Incidents / 100mi' },
  ];
  public dateFilterValue;
  public topIncidentData$: Observable<IncidentStat[]>;
  public totalIncident$: Observable<number>;
  public reload$: Observable<boolean>;
  public metricData$: Observable<KeyMetric[]>;
  public internalPerformanceScore$: Observable<number>;
  public internalTotalIncident$: Observable<number>;
  public internalValues$: Observable<any[]>;
  public chartOptions$: Observable<any>;
  public fleetPerformance$: Observable<any>;
  public hasGraphError = false;
  public leadboardAsc = false;

  public show = 'loading';
  public filterOptions: SearchFilterModel;
  public topIncidents: any = {};
  public values$ ;
  public totalMiles: any;
  public incidentPer100Mile: any;
  public totalhours: any;
  public incidentPerMin: any;
  public performance: any = {};
  public showRange = true;
  public displayStartDate = null;
  public displayEndDate = null;

  public range: any;
  public highcharts = Highcharts;
  public isNotLoading$: Observable<boolean>;
  public chartOptions = CHART_OPTION;
  public loadstatus = false;
  public updateChart = true;
  public tripcount = 0;
  public drivercount = 0;
  public totalincident = 0;
  public driver: any = {};
  public otherIndex = 5;
  public divisionTooltipPosition: TooltipPosition = 'below';

  private _dateFilter$ = new ReplaySubject<DateFilterValue>();
  public get dateFilter$(): Observable<DateFilterValue> {
    return this._dateFilter$;
  }
  private _trendParamsSub$ = new ReplaySubject<{params: DefaultInputModelV2}>();
  public get trendParams$(): Observable<{params: DefaultInputModelV2}> {
    return this._trendParamsSub$;
  }
  private _homeLocationList = [];
  private _filterChangeSubscription: any;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _subscription: Subscription = new Subscription();
  constructor(
    private _zcfleet: ZCFleetService,
    private _dateService: DateService,
    private _loading: LoadingService,
    private _data: Data,
    private _router: Router,
    private _storageService: StorageService
  ) {
    this._prepareUpstream();
    this._filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(
      (filterOptions) => {
        this.filterOptions = filterOptions;
        this.getData();
      }
    );
  }

  public ngOnDestroy() {
    // Unsubscribe from filter change event
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  /*
        desc: This wil generate fleet details based on duration filter
        params: empty
        result: it will return fleet driver details
      */

  // a and b are javascript Date objects
  public dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }

  public getData() {
    const { days, dutyType, minScore, maxScore } = this.filterOptions;
    this.range = this._dateService.getDateRange(days);
    let date = { from: null, to: null };
    if (days === 2) {
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      date.to = this._dateService.toDaysEndISO(this._dateService.customEndDate);
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
      this.showRange = true;
      this.range = date;

      const dFrom = new Date(this.range.from);
      const getdTo = new Date(this.range.to);
      const dTo = new Date(getdTo.setDate(getdTo.getDate() - 1));
      // eslint-disable-next-line radix
      const differenceInDays = this.dateDiffInDays(dFrom, dTo);
      this.displayEndDate = new Date(
        dTo.setDate(dTo.getDate() - differenceInDays)
      ).toISOString();
      this.displayStartDate = new Date(
        dFrom.setDate(dFrom.getDate() - differenceInDays)
      ).toISOString();

      console.log(differenceInDays);
    } else {
      this.showRange = false;
      date = this._dateService.getDateRangeInISO(days);
    }
    this._homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION_ABV');
    const trendsParams = {
      params: new DefaultInputModelV2(
        date.from,
        date.to,
        dutyType,
        minScore,
        maxScore,
        null,
        null,
        null,
        null,
        this._homeLocationList
      ),
    };
    this._trendParamsSub$.next(trendsParams);
    this.leadboardAsc = false;

    this._loading.show();
    this.show = 'loading';
    this.updateChart = false;
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._subscription = this.fleetPerformance$.subscribe(
      (res) => {
        const eventlist = [
          {
            name: 'Accelerating',
            value: res.eventCount['Harsh-Acceleration'],
            incidentClass: 'incident-accel',
          },
          {
            name: 'Braking',
            value: res.eventCount['Harsh-Braking'],
            incidentClass: 'incident-braking',
          },
          {
            name: 'Lane Drift',
            value: res.eventCount['Lane-Drift-Found'],
            incidentClass: 'incident-lane-drift',
          },
          {
            name: 'Cornering',
            value: res.eventCount['Cornering'],
            incidentClass: 'incident-cornering',
          },
          {
            name: 'Speeding',
            value: res.eventCount['Traffic-Speed-Violated'],
            incidentClass: 'incident-speeding',
          },
          {
            name: 'Tailgating',
            value: res.eventCount['Tail-Gating-Detected'],
            incidentClass: 'incident-tailgating',
          },
        ];
        this.drivercount = res.driverCount;
        this.tripcount = res.tripCount;
        this._loading.hide();
        const topIncidentslist: any = eventlist.sort((obj1, obj2) => obj2.value - obj1.value);
        this.driver = { ...res };
        this.topIncidents = topIncidentslist.slice(0, 5);
        this.totalincident = res.eventCount ? calcSumTotalIncidents(res.eventCount) : 0;
        this.performance = res;
        this.totalMiles = Number((res.tripDistance * 0.62137).toFixed(2));
        this.incidentPer100Mile =
          res.tripDistance > 0
            ? (this.totalincident * 100) / (this.totalMiles)
            : 0;
        this.totalhours = Number((res.tripDuration / (60 * 60)).toFixed(2));
        this.incidentPerMin = this.totalincident / this.totalhours;

        this.updateChart = true;
        this.loadstatus = true;
        this.isShowError.emit(false);
      },
      (err) => {
        console.error(`Received Error: ${err}`);
        this._loading.hide();
        this.show = 'error';
        this.isShowError.emit(true);
      }
    );
  }

  public goToFleetMetrics() {
    this._router.navigate(['/fleet-trends']);
  }

  public isLongText(text: number | string): boolean {
    if (text) {
      return text.toString().length > MAX_TEXT_LENGTH;
    }
  }

  private _prepareUpstream() {
    const distinctDateFilter$ = this.dateFilter$.pipe(distinctUntilChanged()).pipe(
      tap(value => this.dateFilterValue = value)
    );
    this.reload$ = merge(distinctDateFilter$).pipe(
      map(() => true)
    );

    const distinctTrendParams$ = this.trendParams$.pipe(distinctUntilChanged());
    this.fleetPerformance$ = combineLatest([
      distinctTrendParams$,
    ]).pipe(
      switchMap(([trendParams]) => this._zcfleet.getFleetPerformance(trendParams)),
      map((res) => res),
      shareReplay()
    );

    this.internalValues$ = this.fleetPerformance$.pipe(map(this._aggregateKeyFleet));
    this.internalPerformanceScore$ = this.fleetPerformance$.pipe(map(this._getPerformanceScore));
    this.internalTotalIncident$ = this.fleetPerformance$.pipe(map(this._getTotalIncident));
    this.metricData$ = this.fleetPerformance$.pipe(map(this._aggregateAssetMappingKeyMetrics));
    this.topIncidentData$ = this.fleetPerformance$.pipe(
      map(this._getTopIncidentAssetList)
    );
    this.totalIncident$ = this.fleetPerformance$.pipe(
      map(this._getTotalIncident)
    );
    this.chartOptions$ = this.fleetPerformance$.pipe(
      map(this._getChartOptions)
    );
  }

  private _aggregateKeyFleet(data): any[] {
    const totalMiles = Number((data.tripDistance * MILE_OF_A_KILOMETER).toFixed(2));
    const totalhours = Number((data.tripDuration / SECONDS_OF_A_HOUR).toFixed(2));
    const totalincident = data.eventCount ? calcSumTotalIncidents(data.eventCount) : 0;
    const incidentPer100Mile = data.tripDistance > 0
      ? (totalincident * MILE_NUMBER) / (totalMiles)
      : 0;
    const incidentPerMin = data.tripDuration > 0 ? totalincident / totalhours : 0;

    return [
      totalMiles  ,
      incidentPer100Mile ,
      totalhours ,
      incidentPerMin ,
      totalincident ,
      data.tripCount,
    ];
  }

  private _getPerformanceScore(data): number {
    return data;
  }

  private _getTotalIncident(data): number {
    return data.eventCount ? calcSumTotalIncidents(data.eventCount) : 0;
  }

  private _aggregateAssetMappingKeyMetrics(data): KeyMetric[] {
    const totalMiles = Number((data.tripDistance * MILE_OF_A_KILOMETER).toFixed(2));
    const totalHours = Number((data.tripDuration / SECONDS_OF_A_HOUR).toFixed(2));
    const totalIncident = data.eventCount ? calcSumTotalIncidents(data.eventCount) : 0;
    const incidentPerNMile = data.tripDistance > 0 ? (totalIncident * MILE_NUMBER) / totalMiles : 0;
    const incidentPerMin = data.tripDuration > 0 ? totalIncident / totalHours : 0;

    return [
      { name: 'Total Miles', value: totalMiles, decimalType: '1.0-2' },
      { name: 'Total Hours', value: totalHours, decimalType: '1.0-2' },
      { name: 'Total Trips', value: data.tripCount },
      { name: `Incidents / ${MILE_NUMBER}mi`, value: incidentPerNMile, decimalType: '1.0-2' },
      { name: 'Incidents / Hour', value: incidentPerMin, decimalType: '1.0-2' },
      { name: 'Total Incidents', value: totalIncident },
    ];
  }

  private _getTopIncidentAssetList(data): IncidentStat[] {
    if (data.eventCount) {
      return [
        {
          name: 'Accelerating',
          value: data.eventCount['Harsh-Acceleration'],
          incidentClass: 'incident-acceleration',
        },
        {
          name: 'Braking',
          value: data.eventCount['Harsh-Braking'],
          incidentClass: 'incident-braking',
        },
        {
          name: 'Lane Drift',
          value: data.eventCount['Lane-Drift-Found'],
          incidentClass: 'incident-lane-drift',
        },
        {
          name: 'Cornering',
          value: data.eventCount['Cornering'],
          incidentClass: 'incident-cornering',
        },
        {
          name: 'Speeding',
          value: data.eventCount['Traffic-Speed-Violated'],
          incidentClass: 'incident-speeding',
        },
        {
          name: 'Tailgating',
          value: data.eventCount['Tail-Gating-Detected'],
          incidentClass: 'incident-tailgating',
        },
        {
          name: 'Distraction',
          value: data.eventCount['Distracted-Driving'],
          incidentClass: 'incident-distraction',
        },
        {
          name: 'Stop Sign',
          value: data.eventCount['Traffic-STOP-Sign-Violated'],
          incidentClass: 'incident-stop-sign',
        },
      ];
    }
  }
  private _getChartOptions(data) {
    const chartOption = cloneDeep(CHART_OPTION);
    chartOption.series[0].data = [];
    for (let i = 0; i < data.distribution.length; i += 2) {
      const currentCount = data.distribution[i].count;
      const nextCount =
        (data.distribution[i + 1] && data.distribution[i + 1].count) || 0;
      chartOption.series[0].data.push([i, currentCount + nextCount]);
    }
    return chartOption;
  };
}
