import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission, PermissionManagerService } from '@app-core/services/permission-manager.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isAuthorized]',
})
export class IsAuthorizedDirective implements OnInit, OnChanges {

  /**
   * This input will be binded to the target permission.
   */
  @Input() public isAuthorized: Permission;
  @Input() public isAuthorizedReverse = false;

  private _hasView = false;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainerRef: ViewContainerRef,
    private _permissionManager: PermissionManagerService
  ) { }

  public ngOnInit(): void {
    this.configureView();
  }

  public ngOnChanges(_changes: SimpleChanges): void {
    this.configureView();
  }

  /**
   * Configure the view container, check for any changes or update.
   * Can clear of add new items to the current container
   */
  public configureView() {
    this._permissionManager.getPermissionObs(this.isAuthorized).subscribe(
      this._refreshViewOnPermission.bind(this),
      (_err) => this._refreshViewOnPermission(false)
    );
  }

  private _refreshViewOnPermission(isAuthorized: boolean) {
    // The use of this._hasView is to make sure that the new view
    // only need to be rendered once when there's no change on the
    // authorization
    const isAuthorizedWithReverseFlag = this.isAuthorizedReverse ? !isAuthorized : isAuthorized;
    if (isAuthorizedWithReverseFlag && !this._hasView) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
      this._hasView = true;
    } else if (!isAuthorizedWithReverseFlag && this._hasView) {
      this._viewContainerRef.clear();
      this._hasView = false;
    }
  }

}
