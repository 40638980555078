import { Component, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';

import { Data, defaultEventCount, DRIVER_INCIDENTS_GRAPH_OPTION } from '@modules/dashboard3/services/data.service';
import { INCIDENTS_CHART_LEGENDS } from '@modules/dashboard3/dashboard3.models';

const legends = INCIDENTS_CHART_LEGENDS;
const SPACING = {
  RIGHT: 19,
  LEFT: 2};
interface EventCount {
  total: number;
}

interface AggregateDataList {
  key: string;
  value: {
    tripDuration: number;
    tripCount: number;
    score: number;
    tripDistance: number;
    eventCount: EventCount & Record<string, number>;
  };
}

@Component({
  selector: 'app-incidents-overtime-chart',
  templateUrl: './incidents-overtime-chart.component.html',
  styleUrls: ['./incidents-overtime-chart.component.scss'],
})
export class IncidentsOvertimeChartComponent implements AfterViewInit {
  @Input() public updateIncidentChart = false;
  @Input() public showGraph = true;
  @Input() public set dataSource$(source: Observable<AggregateDataList[]>) {
    if (!source) {
      return;
    }
    this.internalDataSource$ = source.pipe(
      map(this._getIncidentOptions.bind(this)));
  }

  @Input()
  public set reload$(value: Observable<boolean>) {
    const incidentState = merge(value, this.internalDataSource$);
    const mapper = map(mergeValue => (mergeValue !== true && mergeValue !== undefined));
    this.notLoadingState$ = incidentState.pipe(mapper);
  }

  public highcharts = Highcharts;
  public notLoadingState$: Observable<boolean>;
  public internalDataSource$: Observable<any>;
  public chartLoading;
  public date = {
    from: null,
    to: null,
  };
  private _dateArray: any[];
  private _aggregateValueDefaults: any = {
    tripDuration: 0,
    tripCount: 0,
    score: 0,
    tripDistance: 0,
    eventCount: defaultEventCount,
  };


  constructor(
    private _data: Data,
    private _cd: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    // dummyOption to create an empty incident chart for skeleton loading
    const dummyOption = cloneDeep(DRIVER_INCIDENTS_GRAPH_OPTION);
    dummyOption.title.text = '';
    // change the height for an empty incident chart and skeleton overlap
    dummyOption.chart.height = 460;
    dummyOption.chart.spacingRight = SPACING.RIGHT;
    dummyOption.chart.spacingLeft = SPACING.LEFT;
    this.chartLoading = dummyOption;
    this._cd.detectChanges();
  }

  private _getIncidentOptions(eventData: AggregateDataList[]): any {
    const incidentsOption = cloneDeep(DRIVER_INCIDENTS_GRAPH_OPTION);
    this._getDataArray(incidentsOption);
    if (eventData) {
      const incidentsGraphData = this._formatData(eventData, this._dateArray);
      incidentsOption.title.text = '';
      incidentsOption.chart.spacingRight = SPACING.RIGHT;
      incidentsOption.chart.spacingLeft = SPACING.LEFT;
      if (!incidentsGraphData) {
        for (let i = 0; i < incidentsOption.series.length ||  0; i++) {
          incidentsOption.series[i].data = [];
          for (let j = 0; j < this._dateArray.length; j++) {
            // assigning -1 when no data so that grisliness are plotted
            incidentsOption.series[i].data[j] = -1;
          }
        }
        // get "month date" to display the legends
        // example: May 04
        incidentsOption.xAxis.categories = this._dateArray.map(ele =>
          moment(ele.label)
            .toString()
            .slice(4, 10)
        );
        incidentsOption.yAxis.min = 0;
        incidentsOption.yAxis.max = 20;
        return incidentsOption;
      } else {
        for (let i = 0; i < incidentsOption.series.length; i++) {
          incidentsOption.series[i].data = this._getSeriesByLegend(incidentsGraphData, legends[i]);
        }
        incidentsOption.xAxis.categories = this._getCategories(incidentsGraphData);
        return incidentsOption;
      }
    }
    return undefined;
  }

  private _formatData(aggregateData: AggregateDataList[], dateArray: any[]) {
    if (aggregateData && aggregateData.length === 0) {
      return;
    }
    let count = 0;
    const aggregateDataList: AggregateDataList[] = [];
    for (const date of dateArray) {
      if (aggregateData[count]) {
        if (date.label === aggregateData[count].key) {
          aggregateDataList.push(aggregateData[count]);
          count++;
        } else {
          const newAggregateData: AggregateDataList = {
            key: date.label,
            value: this._aggregateValueDefaults};
          aggregateDataList.push(newAggregateData);
        }
        // condition when the last element of the array is missing
      } else {
        const newAggregateData: AggregateDataList = {
          key: date.label,
          value: this._aggregateValueDefaults};
        aggregateDataList.push(newAggregateData);
      }
    }
    return aggregateDataList;
  }

  private _getDataArray(options: any) {
    const { days } = this._data.filterData;
    this.date = this._data.getDateRangeFromDays(days);
    const group = this._data.decideGroup(
      days,
      this.date,
      options,
      options
    );
    this._dateArray = group.dateArray;
  }

  private _getSeriesByLegend(eventData: AggregateDataList[], legend: string){
    return eventData.map(
      (ele) => ele.value.eventCount[legend]
    );
  }

  private _getCategories(eventData: AggregateDataList[]): any[] {
    return eventData.map(ele =>
      moment(ele.key)
        .toString()
        // get "month date" to display the legends
        // example: May 04
        .slice(4, 10)
    );
  }

}
