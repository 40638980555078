import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagDirective } from './directives/feature-flag.directive';

@NgModule({
  declarations: [FeatureFlagDirective],
  imports: [
    CommonModule,
  ],
  exports: [
    FeatureFlagDirective,
  ],
})
export class FeatureFlagModule { }
