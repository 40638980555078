<div class="zc-body zc-app-wrap has-search-filter">
    <div class="search-filter-container">
        <div class="search-filter">
            <div class="search-left">
                <app-search-filter page="flagged" (driverChange)="driverChange($event)">
                </app-search-filter>
            </div>
        </div>
    </div>

    <div class="zc-content-wrap page-margin">
        <div class="home" *ngIf="!incidentList?.length && show !== 'loading'">
            <img
                class="home-icon"
                (click)="goToDashboard()"
                [src]="imgSrc"
                (mouseout)="imgSrc = 'assets/images/path@2x.png'"
            />&nbsp;
            &nbsp; <span class="arrow"> > </span>
            <span class="route"> &nbsp; Flagged Incidents </span>
        </div>

        <div *ngIf="incidentList?.length && show !== 'loading'" class="fleet-page-wrap">
            <div class="home">
                <img (click)="goToDashboard()" src="assets/images/home.png" /> &nbsp;
                <span class="route"> > &nbsp; Flagged incidents </span>
            </div>
            <div class="side-heading"
            appDocumentClick
            (clickedInside)="clickedInside($event)">
                Flagged incidents
                <div class="showSortButton" [ngSwitch]="showSort" (click)="toggleMenu()">
                    <div class="selectedlist">
                        {{ displayFilter }}
                    </div>
                    <div>
                        <div class="arrow-icon arrow-down "></div>
                    </div>
                </div>
                <div
                    class="sort-card"
                    *ngIf="showDropdown"
                >
                    <button
                        class="option"
                        [disabled]="activeFilter === 'mostRecent'"
                        [class.bold]="activeFilter === 'mostRecent'"
                        (click)="onFiltering('mostRecent')"
                    >
                        Most-Least Recent
                    </button>
                    <button
                        class="option"
                        [disabled]="activeFilter === 'leastRecent'"
                        [class.bold]="activeFilter === 'leastRecent'"
                        (click)="onFiltering('leastRecent')"
                    >
                        Least-Most Recent
                    </button>
                    <button
                        class="option"
                        [disabled]="activeFilter === 'aZCategory'"
                        [class.bold]="activeFilter === 'aZCategory'"
                        (click)="onFiltering('aZCategory')"
                    >
                        Incident Category (A-Z)
                    </button>
                </div>
            </div>

            <div *ngFor="let incident of incidentList; index as i">
                <div class="flagged-incident-card" *ngIf="!incident?.challengeResolved">
                    <div class="incident-name">
                        {{ incident?.eventType }} on {{ incident?.timestamp | date: 'MMM' }}
                        {{ incident?.timestamp | date: 'd'}}{{ incident?.timestamp | date: 'd' | dateSuffix }}
                    </div>
                    <div class="incident-content">
                        <div class="left">
                            <div class="incident-media">
                                <div *ngIf="incident?._mediaLink && !isImage(incident?._mediaLink)">
                                    <video class="video" controls>
                                        <source [src]="incident?._mediaLink" type="video/mp4" />
                                        Browser not supported
                                    </video>
                                </div>
                                <div *ngIf="incident?._mediaLink && isImage(incident?._mediaLink)">
                                    <img
                                        class="video"
                                        [src]="incident?._mediaLink"
                                        alt="Event Image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <div class="map flagged-card-map">
                            <app-map
                                mapId="flaggedIncidentMap{{ i }}"
                                [mapheight]="250"
                                [markerList]="incident?.markerList"
                                [latlonList]="incident?.pathList"
                            ></app-map>
                        </div>
                        <div class="incident-details">
                            <div
                                class="trip_details"
                                [ngStyle]="{ height: '239px' }"
                            >
                                <div class="trip_list">
                                    <div>
                                        <img
                                            src="assets/images/map_icon/map.png"
                                            class="map_icons"
                                        />{{ incident?.startLocation }} to {{ incident?.endLocation }}
                                    </div>
                                    <div>
                                        <img
                                            src="assets/images/map_icon/clock.png"
                                            class="map_icons"
                                        />{{incident?.disputeTimeMoment | momentFormat: 'MM/DD/YYYY HH:mm z' }}
                                    </div>
                                    <div>
                                        <img
                                            src="assets/images/map_icon/hours.png"
                                            class="map_icons"
                                        />{{ incident?.duration | duration2: 'seconds':'h:m' }}
                                    </div>
                                    <div>
                                        <img
                                            src="assets/images/map_icon/mile.png"
                                            class="map_icons"
                                        />{{
                                            incident?.tripDistance
                                                | number: '1.0-0'
                                        }}
                                        <span *ngIf="incident?.tripDistance > 1">miles</span>
                                        <span *ngIf="incident?.tripDistance <= 1">mile</span>
                                    </div>
                                    <div>
                                        <img
                                            src="assets/images/map_icon/incident.png"
                                            class="map_icons"
                                        />{{ incident?.eventCount?.total }}
                                        <span *ngIf="incident?.eventCount?.total > 1">incidents</span>
                                        <span *ngIf="incident?.eventCount?.total <= 1">incident</span>
                                    </div>
                                    <div *ngIf="!incident?.showDriverName" (click)="showDriver(i)">
                                        <img
                                            src="assets/images/lock-blue.png"
                                            class="map_icons"
                                            style="margin-left: 5px"
                                        />
                                        Show Driver
                                    </div>
                                    <div *ngIf="incident?.showDriverName" (click)="showDriver(i)">
                                        <img
                                            src="assets/images/lock.svg"
                                            class="map_icons"
                                            style="margin-left: 5px"
                                        />
                                        {{ incident?.driverName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="horizontal-line" />
                    <div class="action-buttons">
                        <ng-container *ngIf="edvrSwitch">
                        <div class="enhance-video" (click)="openEnhanceVideoModal(incident)" style="cursor: pointer;">
                            <span> <img src="assets/images/enhance-blue.svg" /> </span>
                            <span class="button-text"> Enhance video</span>
                        </div>
                        </ng-container>
                        <ng-container *ngIf="incident?.showEnhanceVideoPopup">
                            <app-enhance-video-popup [incidentDetails]="incident"
                                (closePopup)="closeEnhanceVideoPopup(incident)"
                            ></app-enhance-video-popup>
                        </ng-container>
                        <div class="right-buttons">
                            <div class="report-bug" style="cursor: pointer;">
                                <span>
                                    <img src="assets/images/reject-blue.svg" />
                                </span>
                                <span class="button-text" (click)="rejectChallenge(incident)"
                                    >Reject Flag</span
                                >
                            </div>
                            <div class="report-bug" style="cursor: pointer;">
                                <span>
                                    <!-- Change for ZCW-505 -->
                                    <img src="assets/images/Check.svg" />
                                </span>
                                <span class="button-text" (click)="acceptChallenge(incident)"
                                    >Mark as Safe</span
                                >
                            </div>
                        </div>
                    </div>
                    <div [ngSwitch]="showPopup">
                        <div class="snackbar" *ngSwitchCase="'bug'">
                            Bug reported. This incident has been removed from the record.
                            <div
                                class="cancel-button"
                                [hidden]="hideCancel"
                                (click)="snackbarCancel('bug')"
                            >
                                Cancel
                            </div>
                        </div>
                        <div class="snackbar" *ngSwitchCase="'accepted'">
                            This incident is marked as safe. It no longer appears on the record.
                            <div
                                class="cancel-button"
                                [hidden]="hideCancel"
                                (click)="snackbarCancel('accept')"
                            >
                                Cancel
                            </div>
                        </div>
                        <div class="snackbar" *ngSwitchCase="'reject'">
                            Flag rejected. This incident will remain on the record.
                            <div
                                class="cancel-button"
                                [hidden]="hideCancel"
                                (click)="snackbarCancel('reject')"
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="flaggedIncidentsCount > 10 && currentCount < flaggedIncidentsCount && show !== 'loading'"
            (click)="loadMore()"
            class="load-more"
        >
            Load 10 more incidents
        </div>
        <div class="empty-card" *ngIf="flaggedIncidentsCount === 0">
            <p>There are no challenged incidents</p>
            <img src="/assets/images/info_2019-04-30/info@2x.png" />
        </div>
    </div>
</div>
