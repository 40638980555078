// Core modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';

// App moduels
import { SharedModule } from '@modules/shared/shared.module';

// Other modules
import { ChartsModule } from 'ng2-charts';
import { HighchartsChartModule } from 'highcharts-angular';
// import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { Ng5SliderModule } from 'ng5-slider';
import { UploaderModule } from '@trendster-io/ng-uploader';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { PaginatorTwoComponent } from './components/paginator2/paginator.component';
import { Paginator3Component } from './components/paginator3/paginator3.component';
import { DutyTypeFilterComponent } from './components/duty-type-filter/duty-type-filter.component';
import { DriverFilterComponent } from './components/driver-filter/driver-filter.component';
import { DateFilterComponent } from './components/date-filter/date-filter.component';
import { DriverSearchComponent } from './components/driver-search/driver-search.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { ExportModalComponent } from './components/export-modal/export-modal.component';
import { TableSortHeaderComponent } from './components/table-sort-header/table-sort-header.component';
import { LocationFilterComponent } from './components/location-filter/location-filter.component';

// Services
import { ZCFleetService } from './services/zcfleet.service';
import { Data } from './services/data.service';
import { ExportService } from './services/export.service';

// Directives
import { DocumentClickDirective } from './directives/document-click/document-click.directive';
import { SendGAOnClickDirective } from './directives/send-ga-on-click/send-ga-on-click.directive';
// New dashboard2

// Fleet
import { FleetDetailsComponent } from './pages/fleet-details/fleet-details.component';
import { LeadboardComponent } from './pages/fleet-details/leadboard/leadboard.component';
import { LeadboardCardComponent } from './pages/fleet-details/leadboard-card/leadboard-card.component';
import { FleetAllDriverTableComponent } from './pages/fleet-details/fleet-all-driver-table/fleet-all-driver-table.component';
import {
  FleetDisputedIncidentsTableComponent,
} from './pages/fleet-details/fleet-disputed-incidents-table/fleet-disputed-incidents-table.component';

// Driver
import { DriverDetail2Component } from './pages/driver-detail2/driver-detail2.component';
// Trip
import { TripDetails2Component } from './pages/trip-details2/trip-details2.component';
import { MapComponent } from './components/map/map.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MediaShowComponent } from './components/media-show/media-show.component';
import { MapTooltipComponent } from './components/map-tooltip/map-tooltip.component';

// Settings
import { Settings3Component } from './pages/settings/settings.component';
import { DutySettingsOptionsComponent } from './pages/settings/duty-settings-options/duty-settings-options.component';
import { FleetAllTripsTableComponent } from './pages/fleet-details/fleet-all-trips-table/fleet-all-trips-table.component';
import { DriverTripsTableComponent } from './pages/driver-detail2/driver-trips-table/driver-trips-table.component';
import { CustomSelectComponent } from './pages/settings/custom-select/custom-select.component';

// Export
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FleetScoreGraphComponent } from './pages/fleet-details/fleet-score-graph/fleet-score-graph.component';
import { DriverScoreGraphComponent } from './pages/driver-detail2/driver-score-graph/driver-score-graph.component';

import { FleetPerformanceComponent } from './pages/fleet-details/fleet-performance/fleet-performance.component';

import { DriverScorecardsComponent } from './pages/fleet-details/driver-scorecards/driver-scorecards.component';
import { TriprecapsComponent } from './pages/fleet-details/triprecaps/triprecaps.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserModule } from '@angular/platform-browser';
import { FleetMetricsComponent } from './pages/fleet-details/fleet-metrics/fleet-metrics.component';
import { FlaggedIncidentsComponent } from './pages/flagged-incidents/flagged-incidents.component';
import { AllTripsTableComponent } from './pages/fleet-details/all-trips-table/all-trips-table.component';
import { CommonTableModalComponent } from './pages/fleet-details/common-table-modal/common-table-modal.component';
import { CaptureNewIncidentModelComponent } from './pages/trip-details2/capture-new-incident-model/capture-new-incident-model.component';
import { EnhanceVideoModalComponent } from './pages/trip-details2/enhance-video-modal/enhance-video-modal.component';
import { EnhanceVideoPopupComponent } from './pages/trip-details2/enhance-video-popup/enhance-video-popup.component';
import { SettingsModalComponent } from './pages/settings/setting-modal/settings-modal.component';
import { SettingUploadCameraByIdComponent } from './pages/settings/setting-upload-camera-by-id/setting-upload-camera-by-id.component';
import {
  SettingUploadCameraFromFileComponent,
} from './pages/settings/setting-upload-camera-from-file/setting-upload-camera-from-file.component';
import { DriverScoreItemComponent } from './components/driver-score-item/driver-score-item.component';
import { IncidentStatComponent } from './components/incident-stat/incident-stat.component';
import { TransformIncidentStatDirective } from './directives/transform-incident-stat/transform-incident-stat.directive';
import { IncidentResponseCenterComponent } from './pages/incident-response-center/incident-response-center.component';
import { CoachLayoutComponent } from './components/coach-layout/coach-layout.component';
import { IncidentMediaControlComponent } from './components/incident-media-control/incident-media-control.component';
import { RemoveModalComponent } from './components/custom-modal/remove-modal/remove-modal.component';
import { CommentModalComponent } from './components/custom-modal/comment-modal/comment-modal.component';
import { CommentButtonGroupComponent } from './components/comment-button-group/comment-button-group.component';
// eslint-disable-next-line max-len
import { FleetSavedIncidentsTableComponent } from './pages/incident-response-center/fleet-saved-incidents-table/fleet-saved-incidents-table.component';
import { ZonarUiSearchableDropdownComponent, ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { IncidentDvrDetailComponent } from './components/incident-dvr-detail/incident-dvr-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { IncidentButtonControlComponent } from './components/incident-button-control/incident-button-control.component';
import { SearchFilterTableComponent } from './components/search-filter-table/search-filter-table.component';
import { AssetViewComponent } from './pages/asset-view/asset-view.component';
import { SearchFilterItemComponent } from './components/search-filter-table/search-filter-item/search-filter-item.component';
import { TotalIncidentCardComponent } from './components/total-incident-card/total-incident-card.component';
import { KeyMetricOverviewComponent } from './components/key-metric-overview/key-metric-overview.component';
import { IdentificationCardComponent } from './pages/asset-snapshot/identification-card/identification-card.component';
import { AssetSnapshotComponent } from './pages/asset-snapshot/asset-snapshot.component';
import { DriverHistoryComponent } from './components/driver-history/driver-history.component';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { AssetTripRecapComponent } from './components/asset-trip-recap-component/asset-trip-recap-component.component';
import { IncidentsOvertimeChartComponent } from './components/incidents-overtime-chart/incidents-overtime-chart.component';
import { IncidentMediaTableWrapperComponent } from './components/incident-media-table-wrapper/incident-media-table-wrapper.component';
import { ThrottleClickDirective } from './directives/throttle-click/throttle-click.directive';
import { TimeInputFieldComponent } from './components/time-input-field/time-input-field.component';
import { NewCaptureDvrModalComponent } from './pages/trip-details2/new-capture-dvr-modal/new-capture-dvr-modal.component';
import { DurationDropdownComponent } from './pages/trip-details2/duration-dropdown/duration-dropdown.component';
import { DvrSliderComponent } from './components/dvr-slider/dvr-slider.component';
import { RemoveCameraModalComponent } from './pages/settings/remove-camera-modal/remove-camera-modal.component';
import { InputPatternCheckDirective } from './directives/input-pattern-check.directive';
import { SnackbarCustomMessageComponent } from './components/snackbar-custom-message/snackbar-custom-message.component';
import { TopIncidentComponent } from './components/top-incident/top-incident.component';
import { HttpErrorComponent } from './pages/http-error/http-error.component';
import { UnassignedCamerasSearchComponent } from './components/unassigned-cameras-search/unassigned-cameras-search.component';
import { DivisionGraphComponent } from './pages/driver-detail2/division-graph/division-graph.component';
import { DriverTotalIncidentComponent } from './pages/driver-detail2/driver-total-incident/driver-total-incident.component';
import { DriverTripDrivenComponent } from './pages/driver-detail2/driver-trip-driven/driver-trip-driven.component';
import { StackableCustomSnackbarComponent } from './components/stackable-custom-snackbar/stackable-custom-snackbar.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ChartsModule,
    HighchartsChartModule,
    // NguiAutoCompleteModule,
    Ng5SliderModule,
    NgxSkeletonLoaderModule,
    ModalModule,
    ProgressbarModule,
    NgxDaterangepickerMd,
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    SlickCarouselModule,
    UploaderModule,
    NgxFilesizeModule,
    PopoverModule,
    ZonarUiSearchableDropdownModule,
    ScrollingModule,
    NgxSliderModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
  ],
  declarations: [
    // common components
    HeaderComponent,
    FooterComponent,
    PaginatorTwoComponent,
    Paginator3Component,
    DateFilterComponent,
    DriverFilterComponent,
    LocationFilterComponent,
    DutyTypeFilterComponent,
    DriverSearchComponent,
    SearchFilterComponent,
    TableSortHeaderComponent,
    MapComponent,
    MapPopupComponent,
    MapTooltipComponent,
    MediaShowComponent,
    ExportModalComponent,
    TriprecapsComponent,
    FlaggedIncidentsComponent,
    AllTripsTableComponent,
    CommonTableModalComponent,
    // Dashboard3
    // Fleet
    FleetDetailsComponent,
    LeadboardComponent,
    LeadboardCardComponent,
    FleetAllDriverTableComponent,
    FleetAllTripsTableComponent,
    FleetDisputedIncidentsTableComponent,
    FleetSavedIncidentsTableComponent,
    IncidentMediaTableWrapperComponent,
    FleetScoreGraphComponent,
    FleetMetricsComponent,
    // Driver
    DriverDetail2Component,
    DriverTripsTableComponent,
    DriverScoreItemComponent,
    IncidentStatComponent,
    DriverScoreGraphComponent,
    // Trip
    TripDetails2Component,
    MapComponent,
    MapPopupComponent,
    MediaShowComponent,
    IncidentMediaControlComponent,
    // Settings
    Settings3Component,
    DutySettingsOptionsComponent,
    CustomSelectComponent,
    FleetPerformanceComponent,
    DriverScorecardsComponent,
    // Directives
    DocumentClickDirective,
    SendGAOnClickDirective,
    TriprecapsComponent,
    AllTripsTableComponent,
    CommonTableModalComponent,
    CaptureNewIncidentModelComponent,
    EnhanceVideoModalComponent,
    EnhanceVideoPopupComponent,
    SettingsModalComponent,
    SettingUploadCameraByIdComponent,
    SettingUploadCameraFromFileComponent,
    TransformIncidentStatDirective,
    IncidentResponseCenterComponent,
    AssetViewComponent,
    IdentificationCardComponent,
    AssetSnapshotComponent,
    CoachLayoutComponent,
    RemoveModalComponent,
    CommentModalComponent,
    CommentButtonGroupComponent,
    IncidentDvrDetailComponent,
    IncidentButtonControlComponent,
    SearchFilterTableComponent,
    SearchFilterItemComponent,
    TotalIncidentCardComponent,
    KeyMetricOverviewComponent,
    DriverHistoryComponent,
    TooltipDirective,
    AssetTripRecapComponent,
    IncidentsOvertimeChartComponent,
    ThrottleClickDirective,
    TimeInputFieldComponent,
    NewCaptureDvrModalComponent,
    DurationDropdownComponent,
    DvrSliderComponent,
    RemoveCameraModalComponent,
    InputPatternCheckDirective,
    SnackbarCustomMessageComponent,
    TopIncidentComponent,
    HttpErrorComponent,
    UnassignedCamerasSearchComponent,
    DivisionGraphComponent,
    DriverTotalIncidentComponent,
    DriverTripDrivenComponent,
    StackableCustomSnackbarComponent,
  ],
  exports: [
    Paginator3Component,
    CoachLayoutComponent,
    ZonarUiSearchableDropdownComponent,
  ],
  providers: [ZCFleetService, Data, ExportService],
})
class Dashboard3Module {}

export { Dashboard3Module };
