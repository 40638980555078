/*
  @Tit1le: Fleet metrics page
  @Author: Stella Maria Mendonca
  @Description: consists of fleet avg score over time graph and fleet incidents over time graph graph
*/
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { DateService } from '@app-core/services/date.service';
import {
  Data,
  defaultEventCount,
} from '@modules/dashboard3/services/data.service';
import {
  FleetAvgScoreGraphModel,
  INCIDENTS_CHART_LEGENDS,
} from './../../../dashboard3.models';
import { LoadingService } from '@app-core/services/loading.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

const FLEET_AVG_SCORE_GRAPH_OPTION = {
  chart: {
    height: 300, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 20, // number represents pixels
    spacingRight: 57, // number represents pixels
    // eslint-disable-next-line quotes,@typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
  },
  title: {
    text: 'Average Score Over Time',
    align: 'left',
    margin: 33,
    spacingTop: 32,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: '#e9ebec',
    borderColor: '#e9ebec',
  },
  yAxis: {
    pointPlacement: 'on',
    gridLineWidth: 1,
    title: {
      text: 'Fleet Average',
    },
    min: null,
    max: 100,
    endOnTick: false,
    maxPadding: 0.25,
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'vertical',
    itemStyle: {
      color: '#293239',
      fontWeight: 'light',
      fontSize: '12px',
    },
  },
  plotOptions: {
    // area : {
    //     pointStart: 0
    // },
    line: {
      pointPlacement: 'on',
    },
    series: {
      connectNulls: false,
      marker: {
        lineWidth: 1,
        lineColor: 'white',
      },
      label: {
        connectorAllowed: false,
      },
      events: {
        legendItemClick: (event) => {
          event.preventDefault();
        },
      },
      states: {
        hover: {
          halo: {
            size: 0,
          },
        },
      },
    },
  },
  xAxis: {
    tickmarkPlacement: 'on',
    tickInterval: 1,
    gridLineWidth: 1,
    categories: [],
    endOnTick: false,
    maxPadding: 5,
    labels: {
      step: 1,
    },
  },
  series: [
    {
      name: 'Fleet Average',
      data: [],
      color: '#b8bcc0',
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const FLEET_INCIDENTS_GRAPH_OPTION = {
  chart: {
    height: 534, // Height of the chart in pixels
    type: 'line', // type of chart 'line', 'bar'...
    spacingLeft: 20, // number represents pixels
    spacingRight: 57, // number represents pixels
    // eslint-disable-next-line quotes, @typescript-eslint/quotes
    style: { fontFamily: "'Open Sans', sans-serif" },
  },

  title: {
    text: 'Incidents Over Time',
    align: 'left',
    margin: 33,
    spacingTop: 32,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    backgroundColor: '#e9ebec',
    borderColor: '#e9ebec',
  },

  yAxis: {
    pointPlacement: 'on',
    gridLineWidth: 1,
    title: {
      text: 'Number of Incidents',
      margin: 20,
    },
    min: 0,
    max: null,
    tickInterval: 2,
    // endOnTick: false,
    maxPadding: 0.25,
    tickWidth: 1,
    tickLength: 10,
    labels: {
      x: -20,
    },
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemStyle: {
      color: '#293239',
      fontWeight: 'light',
      fontSize: '12px',
    },
  },

  plotOptions: {
    line: {
      pointPlacement: 'on',
    },
    series: {
      connectNulls: false,
      marker: {
        lineWidth: 1,
        lineColor: 'white',
      },
      label: {
        connectorAllowed: false,
      },
      states: {
        hover: {
          halo: {
            size: 0,
          },
        },
      },
    },
  },

  xAxis: {
    tickmarkPlacement: 'on',
    tickInterval: 1,
    gridLineWidth: 1,
    categories: [],
    // endOnTick: false,
    min: null,
    max: null,
    maxPadding: 5,
    labels: {
      step: 1,
    },
  },
  series: [
    {
      name: 'Speeding',
      data: [],
      color: '#2a8596',
    },
    // {
    //     name: 'Max Speed',
    //     data: [],
    //     color: '#2a8596'
    // },
    {
      name: 'Accelerating',
      data: [],
      color: '#8ba600',
    },
    {
      name: 'Braking',
      data: [],
      color: '#e67300',
    },
    {
      name: 'Cornering',
      data: [],
      color: '#6134b1',
    },
    {
      name: 'Lane Drift',
      data: [],
      color: '#e5389d',
    },
    {
      name: 'Rolling Stop',
      data: [],
      color: '#a82a89',
    },
    {
      name: 'Tailgating',
      data: [],
      color: '#814911',
    },
    {
      name: 'Distraction',
      data: [],
      color: '#731042',
    },
  ],

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
        },
      },
    ],
  },
};

const legends = INCIDENTS_CHART_LEGENDS;

@Component({
  selector: 'app-fleet-score-graph',
  templateUrl: './fleet-score-graph.component.html',
  styleUrls: ['./fleet-score-graph.component.scss'],
})
export class FleetScoreGraphComponent implements OnInit, OnDestroy {
  public highcharts = Highcharts;
  public chartOptions = FLEET_AVG_SCORE_GRAPH_OPTION;
  public incidentsChartOptions = FLEET_INCIDENTS_GRAPH_OPTION;
  public updateChart = false;
  public filterChangeSubscription: Subscription;
  public filterOptions;
  public date = {
    from: null,
    to: null,
  };
  public dateArray = [];
  public showGraph = true;
  public homeLocationList = [];

  constructor(
    private _zcfleet: ZCFleetService,
    private _dateService: DateService,
    private _data: Data,
    private _loading: LoadingService
  ) {
    this.filterChangeSubscription = this._zcfleet.fleetFilterChange.subscribe(
      (filterOptions) => {
        this.filterOptions = filterOptions;
        this.getData();
      }
    );
  }

  public ngOnInit() {}

  public ngOnDestroy() {
    if (this.filterChangeSubscription) {
      this.filterChangeSubscription.unsubscribe();
    }
  }

  /**
   * (plotting) assign values to series property of chartOptions as an array
   */
  public avgScoreChart(fleetAvgScore) {
    if (fleetAvgScore.length) {
      this.clearPreviousValues();
      fleetAvgScore = this.formatData(fleetAvgScore);
      this.chartOptions.series[0].data = fleetAvgScore.map((ele) => ele.score);
      this.chartOptions.xAxis.categories = fleetAvgScore.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
      this.chartOptions.series[0].color = '#2d8ed9';
      this.updateChart = true;
    } else {
      this.chartOptions.yAxis.min = 0;
      this.chartOptions.series[0].data = [];
      for (let i = 0; i < this.dateArray.length; i++) {
        // assigning -1 when no data is available, so that gridlines are plotted in charts
        this.chartOptions.series[0].data[i] = -1;
      }
      this.chartOptions.xAxis.categories = this.dateArray.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
      this.showGraph = false;
    }
  }

  /**
   * format fleetAvgSCore such a way that if data(avgScore) is not available for a day,
   * then assign null so that it is not plotted in the graph
   *
   * @param fleetAvgScore : array of objects consisting date and coreesponding data, if there are no trips on a particular day,
   *  the data corresponding to that day will not be present
   */
  public formatData(fleetAvgScore) {
    let count = 0;
    for (const date of this.dateArray) {
      if (fleetAvgScore[count]) {
        if (date.label === fleetAvgScore[count].label) {
          date.score = fleetAvgScore[count].v2Score;
          date.eventCount = fleetAvgScore[count].eventCount;
          count++;
        } else {
          date.score = null;
          date.eventCount = defaultEventCount;
        }
        // condition when the last element of the array is missing
      } else {
        date.score = null;
        date.eventCount = defaultEventCount;
      }
    }

    return this.dateArray;
  }

  /**
   * api call to get fleet trend
   */
  public getData() {
    const { days } = this.filterOptions;
    if (days === 2) {
      this.date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      this.date.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        this.date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else if (days === 0 || days === -1) {
      this.date = this._dateService.getDateRangeInISO(7);
    } else {
      this.date = this._dateService.getDateRangeInISO(days);
    }

    const group = this._data.decideGroup(
      days,
      this.date,
      this.chartOptions,
      this.incidentsChartOptions
    );
    const groupBy = group.group;
    this.dateArray = group.dateArray;

    this.homeLocationList = JSON.parse(localStorage.getItem('HOME_LOCATION_ABV'));

    const params = new FleetAvgScoreGraphModel(
      this.date.from,
      this.date.to,
      groupBy,
      this.homeLocationList
    );
    const graphParams = {
      params: { ...params },
    };
    this._loading.show();
    this._zcfleet.getTrendsList(graphParams).subscribe(
      (res) => {
        this._loading.hide();
        const fleetAvgScore = res.data;
        this.avgScoreChart(fleetAvgScore);
        this.incidentsChart(fleetAvgScore);
        this.updateChart = true;
      },
      (err) => {
        console.error(`Received Error: ${err}`);
        this._loading.hide();
      }
    );
  }

  /**
   * (plotting) assign values to series property of incidentsChartOptions as an array
   */
  public incidentsChart(fleetAvgScore) {
    if (fleetAvgScore.length) {
      this.clearPreviousValues();
      for (let i = 0; i < this.incidentsChartOptions.series.length; i++) {
        this.incidentsChartOptions.series[i].data = this.dateArray.map(
          (ele) => ele.eventCount[legends[i]]
        );
      }
      this.incidentsChartOptions.xAxis.categories = this.dateArray.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
    } else {
      this.incidentsChartOptions.yAxis.min = 0;
      this.incidentsChartOptions.yAxis.max = 20;
      this.showGraph = false;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < this.incidentsChartOptions.series.length; i++) {
        this.incidentsChartOptions.series[i].data = [];
        for (let j = 0; j < this.dateArray.length; j++) {
          // assigning -1 when no data so that gridlines are plotted
          this.incidentsChartOptions.series[i].data[j] = -1;
        }
      }
      this.incidentsChartOptions.xAxis.categories = this.dateArray.map((ele) =>
        moment(ele.label).toString().slice(4, 10)
      );
    }
  }

  /**
   * before plotting a new graph clear the old values so that it does not carry forward
   */
  public clearPreviousValues() {
    this.incidentsChartOptions.yAxis.min = null;
    this.incidentsChartOptions.yAxis.max = null;
    this.chartOptions.yAxis.min = null;
    this.showGraph = true;
  }
}
