import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  public transform(moment: any, format: string): string {
    if (moment) {
      return moment.format(format);
    }

    return moment;
  }
}
