<div class="zc-body zc-app-wrap">
  <div class="zc-content-wrap">
    <div class="page-title">
      <img
        class="home-icon"
        (click)="goToDashboard()"
        src="assets/images/home_24px.svg"
      />
      <img class="arrow-icon" src="assets/images/arrow_forward_24px.svg" />
      <div class="route">
        <span> Asset View </span>
      </div>
    </div>

    <app-search-filter-table
      (dateChange)="onDateChange($event)"
      (filterChange)="onFilterChange($event)"
      [filterOptions]="dropdownFilterOptions"
      [refetchFilter]="refetchFilter"
    ></app-search-filter-table>

    <div class="asset-view-table">
      <app-table
        [dataSource$]="incidentListTable$"
        [displayedColumns]="isErrorLoadingData ? errorDisplayedColumns : displayedColumns"
        [colDescriptions]="isErrorLoadingData ? errorColDescriptions : colDescriptions"
        [customTemplates]="{
          assetName: hyperlinkAsset,
          driverName: hyperlinkDriver,
          action: actionButtons
        }"
        [sortHeader]="sortHeader"
        (sortChange)="sortChangeInternal($event)"
        [sortOrderChange$]="notifySortChange$"
        (pageChange)="updatePage($event)"
        [isHidePageSize]="false"
        [customPageSizeOptions]="[10, 20, 50]"
        [customItemsPerPageLabel]="customItemsPerPageLabel"
        [isShowFirstLastButtons]="true"
        [getUniqueKey]="getRowDataIdentifier"
        [isUseRipple]="true"
        [rippleTemplate]="templateRipple"
        [allowMultipleRowExpand]="true"
        [disableRowClickExpand]="true"
      ></app-table>
    </div>
  </div>
</div>

<ng-template #hyperlinkAsset let-data let-element="element">
  <ng-container *ngIf="data; else loading">
    <ng-container *ngIf="element.assetId; else loading">
      <a
        class="hyperlink-text"
        [routerLink]="['/assets', element.assetId]"
        style="text-decoration: underline; color: #0075b4"
        matTooltip="{{ data }}"
        matTooltipClass="asset-name-tooltip"
        [matTooltipPosition]="toolTipPosition.value"
        [matTooltipDisabled]="data"
      >
        {{ data }}
      </a>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <div style="padding-right: 10px;">
      <app-custom-skeleton-loader></app-custom-skeleton-loader>
    </div>
  </ng-template>
</ng-template>

<!-- hyperlink driver go to driver page when clicked -->
<ng-template #hyperlinkDriver let-asyncData let-element="element">
  <ng-container *ngIf="asyncData | async as data; else loading">
    <a
      class="hyperlink-text"
      [routerLink]="['/driver-detail']"
      [queryParams]="{
        driverId: element.driverId,
        driverName: element.driverName
      }"
      style="text-decoration: underline; color: #0075b4"
    >
      {{ data }}
    </a>
  </ng-container>
  <ng-template #loading>
    <div style="padding-right: 10px;">
      <app-custom-skeleton-loader></app-custom-skeleton-loader>
    </div>
  </ng-template>
</ng-template>

<!-- buttons -->
<ng-template #actionButtons let-asyncData let-element="element">
  <div class="asset-view-buttons">
    <ng-container *ngIf="asyncData | async as data; else buttonLoading">
      <app-comment-button-group
        [ngClass]="{ 'disabled-button': isDisabledAction }"
        [bookmarkInfo]="data"
        [isMomentObjCreated]="true"
        [showSpinner]="false"
        [isSelfUpdate]="true"
        (violationIdentifierChange)="
          violationIdentifier = $event.violationIdentifier
        "
      >
      </app-comment-button-group>
    </ng-container>
    <ng-template #buttonLoading>
      <div class="loading-action-buttons">
        <div class="loading-comment-button">
          <app-custom-skeleton-loader
            [theme]="{
              'height': '20px',
              'width': '20px',
              'top': '5px',
              'left': '13px'
            }">
          </app-custom-skeleton-loader>
        </div>
        <div class="loading-bookmark-button">
          <app-custom-skeleton-loader
            [theme]="{
              'height': '20px',
              'width': '19px',
              'top': '5px',
              'left': '10px'
            }">
          </app-custom-skeleton-loader>
        </div>
        <div class="loading-viewtrip-button" *ngIf="isErrorLoadingData">
          <app-custom-skeleton-loader
            [theme]="{
              'height': '32px',
              'width': '107px',
              'left': '8px'
            }">
          </app-custom-skeleton-loader>
        </div>
      </div>
    </ng-template>
    <div class="common-buttons" *ngIf="element.tripId && element.driverId">
      <a
        class="nav-link view-trip-button"
        [routerLink]="['/trip-detail']"
        [queryParams]="{
          tripId: element.tripId,
          driverId: element.driverId
        }"
      >
        View trip
      </a>
    </div>
  </div>
</ng-template>

<ng-template #templateRipple let-element="element" let-index="index">
  <app-incident-media-control [incidentData]="setMedia(element)" [selectedRowData]="element" [hideViewTripButton]="true"
    [switch]="false" [showSmallSizeMediaPanel]="true">
  </app-incident-media-control>
</ng-template>
