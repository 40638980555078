/**
 * Pipe which converts duration into specified format.
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration2',
})
export class Duration2Pipe implements PipeTransform {
  public transform(
    value: any = 0,
    unit: any = 'seconds',
    format = 'humanize'
  ): any {
    value = Math.abs(value);
    const duration = moment.duration(value, unit);
    let hours: any = duration.hours();
    let mins: any = duration.minutes();
    // const seconds = duration.seconds();
    if (format === 'HH:MM') {
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (mins < 10) {
        mins = '0' + mins;
      }
    }
    if (format === 'HH:MM') {
      return `${hours}:${mins}`;
    } else if (format === 'h:m') {
      return `${hours}h ${mins}m`;
    } else {
      return duration.humanize();
    }
  }
}
