import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { StorageService } from '@app-core/services/storage.service';

import { zcwAuthenticateConfig } from '../zcw-authenticate.config';
import { AuthenticateInterface, UserProfile } from './zcw-authenticate.interface';


@Injectable()
export class OidcAuthenticateAdapterService implements AuthenticateInterface {

  private _tokenReceived = new ReplaySubject<boolean>();
  private _userProfile$ = new ReplaySubject<UserProfile>();
  private _storage = new StorageService();

  constructor(
    private _oauthService: OAuthService
  ) {
  }

  public get tokenReceived$(): Observable<boolean> {
    return this._tokenReceived.asObservable();
  }

  public get userProfile$(): Observable<UserProfile> {
    return this._userProfile$.asObservable();
  }

  public initialize(): Promise<boolean> {
    this._initConfiguration();
    this._connectEvents();
    return this._loadDocumentAndLogin();
  }

  public logout() {
    this._oauthService.revokeTokenAndLogout();
  }

  private _initConfiguration(): void {
    this._oauthService.setStorage(localStorage);
    this._oauthService.configure(zcwAuthenticateConfig);
    this._oauthService.setupAutomaticSilentRefresh();
  }

  private _connectEvents(): void {
    this._oauthService.events.subscribe(event => {
      if (event.type === 'token_received') {
        this._tokenReceived.next(true);
      }
    });

    merge(
      of(this._oauthService.getIdToken()).pipe(
        filter(result => !!result)
      ),
      this.tokenReceived$
    ).subscribe(() => {
      this._userProfile$.next(this._convertClaimsToUserProfile(this._oauthService.getIdentityClaims()));
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private _convertClaimsToUserProfile(claims: object): UserProfile {
    return {
      email: claims && claims['email'],
      username: claims && claims['name'],
      state: this._storage.getStorageValue('state') || '',
    };
  }

  private _loadDocumentAndLogin(): Promise<boolean> {
    return this._oauthService.loadDiscoveryDocumentAndLogin();
  }
}
