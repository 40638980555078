<div class="row">

    <div class="col-lg-6">
        <div class="box">
            <h5>Accounts List</h5>
            <form #accountList="ngForm" novalidate>
                <div class="form-group row label-input-wrap">
                    <label class="col-md-3 col-form-label">
                        Search Accounts</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Account Code"
                            name="accountCode" #searchAccount
                            (keyup)="getAccountsList(searchAccount.value)" />
                    </div>
                </div>
            </form>

            <div class="table-fixed-header">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" class="width-50">Account code</th>
                            <th scope="col" class="width-50">Remove Account</th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="table-300 table-responsive">
                <table class="table table-bordered table-striped">
                    <tbody>
                        <tr *ngFor="let account of accountsList">
                            <td class="width-50">{{ account }}</td>
                            <td class="width-50">
                                <button type="button" class="btn btn-primary"
                                    (click)="removeAccount(account)" disabled>Remove</button>
                            </td>
                        </tr>
                        <tr *ngIf="!accountsList">
                            <div class="no-match-found">
                                <span>No Accounts Found</span>
                            </div>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

    <div class="col-lg-6">
        <div class="box">
            <h5>Add New Account</h5>
            <form #addAccountForm="ngForm" novalidate>
                <div class="form-group row label-input-wrap">
                    <label class="col-md-3 col-form-label">Account Code</label>
                    <div class="col-md-6">
                        <input type="text" name="accountCode" class="form-control"
                            placeholder="Account Code" [(ngModel)]="accountCode" required />
                    </div>
                    <div class="col-md-3">
                        <button type="button" class="btn btn-primary"
                            [disabled]="addAccountForm.invalid"
                            (click)="addNewAccount()">Add</button>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>
