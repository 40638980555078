import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Marker } from 'leaflet';
import { tap, filter, finalize, take, takeUntil, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import {
  DEFAULT_BUFFER_SIZE, EVENTS_INCIDENT_TYPE } from '@app-core/constants/constants';
import { DateService } from '@app-core/services/date.service';
import { GoogleTagManagerService } from '@app-core/services/google-tag-manager.service';
import { LoadingService } from '@app-core/services/loading.service';
import { StorageService } from '@app-core/services/storage.service';
import { fadeAnimation, slideInOut } from 'app/app.animation';
import { BookmarkInfo, LoadingEvent } from '@modules/dashboard3/components/comment-button-group/comment-button-group.component';
import { MapComponent } from '@modules/dashboard3/components/map/map.component';
import { EVENT_NAMES_MAP } from '@modules/dashboard3/dashboard3.models';
import { MapService, TripDetail } from '@modules/dashboard3/services/map.service';
import { GeoCodeResult, Point } from '@modules/dashboard3/services/reverseGeocode.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { DateFormatPipe } from '@modules/shared/pipes/date-format.pipe';
import { createMomentObjectUtc, getTimezoneString } from '@modules/shared/utils';
import { ASSET_TYPES } from '@modules/settings/common/settings.constants';
import { FeatureFlagDirective } from '@modules/feature-flag/directives/feature-flag.directive';
import { combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { SnackbarService } from '@app-core/services/snackbar.service';

import { LocalService } from './../../../../core/services/local.service';
import { ModalParamsModel } from './../../../dashboard3/dashboard3.models';
import { Data } from './../../services/data.service';
import { PermissionManagerService } from '@app-core/services/permission-manager.service';

interface TripCount {
  morningCount: number;
  afternoonCount: number;
  eveningCount: number;
  nightCount: number;
}

@Component({
  selector: 'app-trip-details2',
  templateUrl: './trip-details2.component.html',
  styleUrls: ['./trip-details2.component.scss'],
  animations: [fadeAnimation, slideInOut],
})
export class TripDetails2Component implements OnInit, OnDestroy {
  @ViewChild(FeatureFlagDirective) public featureFlagSmartDvr: FeatureFlagDirective;
  @ViewChild(MapComponent) public mapComponent: MapComponent;
  public loaderDVR = false;
  public tripId = '';
  public driverId = '';
  public driverName = '';
  public assetName = 'N/A';
  public assetId = '';
  public dutyType = {label: 'N/A type', value: ''};
  public tripDetail: TripDetail & { [key: string]: any };
  public markerList: Marker<any>[] = [];
  public pathList: any[] = [];

  public backText = '';
  public startLocation: string;
  public endLocation: string;
  public rowIncidentList: any[] = [];
  public incidentList: any;
  public searchData: any;

  public reset = false;
  public show = 'loading';
  public limit = 20;
  public sortBy = 'startTime';
  public sort = 'desc';
  public triplist: any[] = [];
  public selectedEvent: any;
  public selectedDvrEvent: any;
  public media = '';
  public tamperingMessage = 'Camera Not Connected';
  public cameraConnected: any;
  public tampered: any;
  public pathInfo;
  public eventsList;
  public showTamperingBlock = false;
  public sortListBy = 'Sorted By Time';
  public sortDVRListBy = 'Sorted By Time';
  public showList = false;
  public showDVRList = false;
  public appVersion = '';
  public loading = false;
  public captureList = false;
  public starttime = '';
  public endtime = '';
  public homeLocationList = [];
  public dvrEventList = [];
  public showDVRVideoModal = false;
  public dvrVideoURL: string;
  public incidentArrayIndex: number;
  public incidentDvrArrayIndex: number;
  public showEdvrVideoModal = false;
  public eDVRVideoURL = null;
  public eDVRTimeStamp = null;
  public eDVREventType = null;
  public tripDistance = null;
  public dvrStatus = 'PENDING';
  public displayTimeZone = null;
  public ongoingLastUpdate = null;
  public latestCapturedPosition = null;
  public eventTripMetadata: BookmarkInfo;
  public hidePopupMedia = false;
  public isValidating: boolean;
  public isSmartDvr: boolean;
  public isDvrModalLoading = false;
  public isSmartFleet$ = new ReplaySubject<boolean>(DEFAULT_BUFFER_SIZE);

  public get loadingChange$(): Observable<LoadingEvent> {
    return this._loadingChange$;
  }
  private _loadingChange$ = new ReplaySubject<LoadingEvent>();

  private _offset = 0;
  private _modalParams: ModalParamsModel = { state: false, component: '' };

  private _zoomLevel = {
    overview: 4,
    detail: 14,
  };

  private _permissionManager: boolean;
  private _destroy$ = new Subject<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _zcfleet: ZCFleetService,
    private _loading: LoadingService,
    private _mapService: MapService,
    private _router: Router,
    private _customDateFormatPipe: DateFormatPipe,
    private _activatedRoute: ActivatedRoute,
    private _data: Data,
    private _gtmService: GoogleTagManagerService,
    private _localService: LocalService,
    private _dateService: DateService,
    private _storageService: StorageService,
    private _title: Title,
    private _snackbarService: SnackbarService,
    private _permission: PermissionManagerService
  ) {
    this.incidentList = [];

    this._checkSmartFleet();

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;

    this._router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
      take(1)
    ).subscribe(() => {
      this._router.navigated = false;
      window.scrollTo(0, 0);
    });
  }

  public ngOnInit() {
    this._permission.getPermissionObs('fleet-ridecam-plus').subscribe(res => {
      this._permissionManager = res;
    });
    this._listenForQueryParams();
    this.setBackText();
    this._title.setTitle('Trip Details - Zonar Coach');
    this.appVersion = environment.version;
    this._listenEventTripMetadataRequest();
    this._listenCaptureRequest();
  }

  public ngOnDestroy() {
    // Reset the fromFleet flag when leaving the page
    this._data.fromFleet = false;
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * @description: function to get previous trip values
   * @param:
   */
  // public onPreviousTrip() {
  //   const { previousTripId } = this.tripDetail;
  //   if (previousTripId) {
  //     this._fetchNewTripDetails(previousTripId);
  //   }
  // }

  /**
   * @description: function to get next trip values
   * @param:
   */
  // public onNextTrip() {
  //   const { nextTripId } = this.tripDetail;
  //   if (nextTripId) {
  //     this._fetchNewTripDetails(nextTripId);
  //   }
  // }

  /** Combine violations, calibrations and video capture events */
  public combineEvents(res: any): any[] {
    const { violations = [], debug = [], customEvents = [] } = res || {};
    // If there are any violation, then do not show calibration debug events else show all debug events
    const filteredDebugEvents = violations.length
      ? debug.filter((event) => event.eventType !== 'Calibration')
      : [...debug];
    return [...customEvents, ...violations, ...filteredDebugEvents];
  }

  /**
   * @description: function to load event location
   * @param:list and pathInfo
   */
  public async loadEventsLocation(eventList: any[]) {
    /**
     * Create a marker list
     */
    const markerList: Marker<any>[] = [];
    const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);

    // ZCW-640 : Don't add incident type which is not available for now.
    eventList
      .filter((evt) => eventTypeAllowedList.includes(evt.eventType))
      .forEach((ele, index) => {
        const { latitude, longitude, eventType = '', timestampUTC = '' } = ele;
        // Exclude markers with lat lon 0
        if (+latitude || +longitude) {
          // generate an Icon
          const icon = ele.challengeAccepted
            ? this._mapService.getCircleicon(ele.eventType + '-circle')
            : this._mapService.getIcon(ele.eventType);

          // Generate a marker with the icon
          const marker = this._mapService.getMarker(
            +latitude,
            +longitude,
            icon,
            false,
            true
          );

          marker.on('click', () => {
            this.resetIncidentArrayIndex();
            const isMapSizeChanged = !this.selectedEvent;
            this.incidentArrayIndex = index;
            this.selectedEvent = ele;
            this._gtmService.incidentMarkerClick({
              incidentType: eventType,
              timestampUTC,
            });
            // this._mapService.showMedia(ele);
            if (this._permissionManager) {
              this._getSingleCommentByViolationIdentifier(ele);
            }
            this.mapComponent.focusMarker(this.incidentArrayIndex, this._zoomLevel.detail, isMapSizeChanged);
          });
          markerList.push(marker);
        }
      });
    markerList.push(
      ...await this._mapService.buildTripEndPointMarkers(this.tripDetail)
    );

    this.markerList = markerList.slice();
  }

  public driverChange() {
    this.gotoDriver();
  }

  // public onBackClick() {
  //   if (this._data.fromFleet) {
  //     this.gotoFleet();
  //   } else {
  //     this.gotoDriver();
  //   }
  // }

  /**
   * @description: function to navigate to driver details page
   * @param: driver Id and driver name
   */
  public gotoDriver() {
    // Set driver name
    // this._data.driverName = driver.driverName;

    this._router.navigate(['/driver-detail'], {
      // replaceUrl: true,
      queryParams: {
        driverId: this.driverId,
        driverName: this.driverName,
      },
    });
  }

  public gotoFleet() {
    this._router.navigate(['/dashboard']);
  }

  // Check if fromFleet flag is set
  // If true, set the back text to "Back to Fleet"
  // else set the back text to "Back to (Drivers name)"
  public setBackText() {
    this.backText = this._data.fromFleet ? 'Back to Fleet' : 'Back to Driver';
    // if (this._data.fromFleet) {
    //     this.backText = 'Fleet';
    // } else {
    //     this.backText = name;
    //     this._data.driverName = name;
    // }
  }

  /**
   * @description: function to set greeting text based on time of the day
   * @param: date
   * @returns: the valid greeting text based on current hour
   */
  public getGreetingText(date: string): string{
    if (date) {
      // return this._dateService.getGreetingTime(date);
      let currentTime = date;
      currentTime = this._customDateFormatPipe.transform(currentTime);
      const time = new Date(currentTime).getHours();

      return this._getGreetingByTime(time, true);
    }
  }

  public onTripChange(event) {
    if (event.target.value) {
      this.tripId = event.target.value;
      this._fetchNewTripDetails(this.tripId);
    }
  }

  /**
   * @description: function to get incident details based on page changes
   * @param:
   */
  public onIncidentChange(val) {
    this.incidentList = [];
    if (val === 'time') {
      this.sortListBy = ' Sorted By Time';
      this.incidentList = this.rowIncidentList.sort((a, b) =>
        a.time.localeCompare(b.time)
      );
      this.showList = false;
    } else {
      this.sortListBy = ' Sorted By Category';
      this.incidentList = this.rowIncidentList.sort((a, b) =>
        a.type[1].localeCompare(b.type[1])
      );
      this.showList = false;
    }
  }

  public onDVRIncidentChange(val) {
    if (val === 'time') {
      this.sortDVRListBy = ' Sorted By Time';
      this.dvrEventList = this.dvrEventList.sort((a, b) =>
        a.startTimeUTC.localeCompare(b.startTimeUTC)
      );
      this.showDVRList = false;
    } else if (val === 'status') {
      this.sortDVRListBy = ' Sorted By Status';
      this.dvrEventList = this.dvrEventList.sort((a, b) =>
        a.status.localeCompare(b.status)
      );
      this.showDVRList = false;
    }
  }

  /**
   * @description: function to check if clicked inside based on user input
   * @param: clicked inside boolean value
   */
  public clickedInside(clickedInside) {
    if (!clickedInside) {
      this.captureList = false;
    }
  }

  /**
   * checks if the event has an image file or video file
   *
   * @param eventDetails
   */
  public eventDetails(event, arrayIndex) {
    this.resetIncidentArrayIndex();
    const isMapSizeChanged = !this.selectedEvent; // true or null or undefined value

    this.incidentArrayIndex = arrayIndex;
    this.selectedEvent = event;
    if (this._permissionManager) {
      this._getSingleCommentByViolationIdentifier(event);
    }
    const mediaSrc = event.eventVideoFilename;
    if (mediaSrc && mediaSrc.indexOf('.jpg') > -1) {
      this.media = 'image';
    } else {
      this.media = 'video';
    }

    this.mapComponent.focusMarker(this.incidentArrayIndex, this._zoomLevel.detail, isMapSizeChanged);
    // this._mapService.showMedia(eventDetails);
  }

  public getEventType(name) {
    return EVENT_NAMES_MAP[name];
  }

  /**
   * makes reversegeodecode api call
   *
   * @param lat
   * @param lng
   */
  public async getAddress(lat, lng) {
    try {
      const address: any = await this._mapService.getReverseGeoCode(lat, lng);
      return address.address.City || address.address.Region || 'Unknown';
    } catch (error) {
      console.log('Could not get address--', error);
      return 'Unknown';
    }
  }

  /**
   * updates the incidents as accepted or rejected based on events emitted by child component
   *
   * @param value
   */
  public updateEventList(value) {
    const index = this.eventsList.findIndex(
      (ele) => ele.eventIndex === value.eventIndex
    );
    if (value.body.isAccepted) {
      this.eventsList[index].challengeAccepted = true;
    } else {
      this.eventsList[index].challengeAccepted = false;
    }
    this.eventsList[index].challengeResolved = true;
    this.loadEventsLocation(this.eventsList);
  }

  public updateEventChallengeProperties(value) {
    const applyChange = item => {
      item.challengeAccepted = value.challengeAccepted;
      item.challengeResolved = true;
    };

    this.eventsList.filter(ele => ele.eventIndex === value.eventIndex).forEach(applyChange);
    this.incidentList.filter(ele => ele.event.eventIndex === value.eventIndex).forEach(incident => applyChange(incident.event));
    this.loadEventsLocation(this.eventsList);
  }

  /**
   * @description: function to toggle flag captureList
   */
  public toggleCaptureList() {
    if (this.tripDetail) {
      this.captureList = !this.captureList;
    } else {
      this.captureList = false;
    }
  }

  /**
   * @description: function to open capture video modal
   * @param:
   */
  public captureVideoModel() {
    this.isDvrModalLoading = true;
    this.isSmartDvr = this.featureFlagSmartDvr.checkValidity();
    // using smart dvr clip capture if match conditions
    this.isSmartFleet$.pipe(
      finalize(() => this.isDvrModalLoading = false),
      catchError((error) => of(`Error when open capture video modal: ${error}`))
    ).subscribe(
      isSmartFleet => {
        if (isSmartFleet && this.isSmartDvr) {
          // using smart dvr clip capture
          this._modalParams.component = 'smartcapture';
        } else {
          // using normal dvr clip capture
          this._modalParams.component = 'capture';
        }
        this.captureList = false;
        this._modalParams.state = true;
        this._zcfleet.captureIncidentModal.next(this._modalParams);
      }
    );
  }

  public openDVRVideoModal(dvrVideoURL, dvrVideoStatus, index) {
    this.resetIncidentArrayIndex();
    this.showDVRVideoModal = true;
    this.dvrVideoURL = dvrVideoURL;

    const dvrVideoStatusList = {
      pending: ['PENDING', 'STARTED', 'ACKNOWLEDGED'],
      unavailable: ['UNAVAILABLE', 'FAILED', 'CANCELLED'],
      finished: ['FINISHED'],
    };

    if (dvrVideoStatusList.pending.includes(dvrVideoStatus)) {
      this.dvrStatus = 'PENDING';
    } else if (dvrVideoStatusList.unavailable.includes(dvrVideoStatus)) {
      this.dvrStatus = 'UNAVAILABLE';
    } else if (dvrVideoStatusList.finished.includes(dvrVideoStatus)) {
      this.dvrStatus = 'FINISHED';
    }

    this.incidentDvrArrayIndex = index;

    this.selectedDvrEvent = {
      challengeAccepted: false,
      challengeRaised: false,
      challengeResolved: false,
      coachingCompleted: false,
      eventType: 'Other-Event',
      status: this.dvrStatus,
      mediaLink: dvrVideoURL,
    };
  }

  public closeDVRVideoModal() {
    this.showDVRVideoModal = false;
  }

  public openEdvrVideoModal(eDVRVideoURL) {
    this.showEdvrVideoModal = true;
    this.eDVRVideoURL = eDVRVideoURL;
  }

  public closeEDVRVideoModal() {
    this.showEdvrVideoModal = false;
  }

  public resetIncidentArrayIndex() {
    this.incidentArrayIndex = undefined;
    this.incidentDvrArrayIndex = undefined;
    this.selectedEvent = null;
    this.selectedDvrEvent = null;

    this.mapComponent.unfocusMarker(true);
  }

  public updateTripMetadata(event): void {
    this._buildLoopOverOverwriteElement(this.incidentList, event.bookmark, event.eventIndex, true);
    this._buildLoopOverOverwriteElement(this.eventsList, event.bookmark, event.eventIndex);
    this.eventTripMetadata.bookmark = this.eventTripMetadata.eventIndex === event.eventIndex
      ? event.bookmark : this.eventTripMetadata.bookmark;
  }

  private _buildLoopOverOverwriteElement(elements: any[], statusBookmark: boolean, eventIndex: number, queryEvent?: boolean): void {
    for (let element of elements) {
      element = queryEvent ? element.event : element;
      if (element.eventIndex === eventIndex) {
        element.bookmark = statusBookmark;
      }
    }
  }

  /**
   * @description: function listen when update event trip metadata request
   */
  private _listenEventTripMetadataRequest() {
    this._zcfleet.refreshAPI$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this._getSingleCommentByViolationIdentifier(this.eventTripMetadata);
      });
  }

  /**
   * @description: Subscribe for driver ID sent from dashboard page
   *               and call the API
   */
  private _listenForQueryParams() {
    this._route.queryParams.pipe(takeUntil(this._destroy$)).subscribe((params) => {
      ({
        driverId: this.driverId,
        tripId: this.tripId,
        eDVRVideoURL: this.eDVRVideoURL,
        eDVREventType: this.eDVREventType,
        eDVRTimeStamp: this.eDVRTimeStamp,
      } = params);
      this._getTripDetails();
    });
  }

  /**
   * @description: function to get trip details based on trip and driver ids
   * @param: driverId and tripId
   * @returns: the trip details object
   */
  private _getTripDetails(): void {
    if (!this.driverId || !this.tripId) {
      return;
    }
    this._loading.show();
    this.loading = true;
    this.searchData = this._localService.getSearchFilterData();

    const params = {
      includeUploadRequests: true,
      includeUploadStats: true,
      ignoreCache: true,
    };
    this._zcfleet
      .getTripDetails(this.driverId, this.tripId, params, true)
      .subscribe(
        async (res) => {
          // this._loading.hide();
          this.tripDetail = res;
          this.assetName = res.asset.metadata && res.asset.metadata.assetNumber || 'N/A';
          this.assetId = res.asset && res.asset.assetId;
          this.dutyType = ASSET_TYPES.find(item => item.value === res.asset.dutyType);
          if (res['ongoing']) {
            delete this.tripDetail.lastLocation;
          }
          this.displayTimeZone = getTimezoneString(
            res.timezoneOffset,
            res.firstLocation.latitude,
            res.firstLocation.longitude
          );
          if (this.tripDetail['ongoing']) {
            // remove undefined pathInfo and pathInfo with undefined timestampUTC
            const pathInfoList = this.tripDetail['pathInfo'].filter(pathInfo =>
              pathInfo && pathInfo.timestampUTC);
            this.latestCapturedPosition = pathInfoList
              .slice()
              .reverse()
              .find((pathItem) => pathItem.timestampUTC);
            const isLastUpdateTimeStampUTC = this.latestCapturedPosition && this.latestCapturedPosition.timestampUTC;
            this.ongoingLastUpdate =  isLastUpdateTimeStampUTC ?
              createMomentObjectUtc(
                this.latestCapturedPosition.timestampUTC
              ).tz(this.displayTimeZone) : undefined;
          }
          this.tripDetail['startTimeMoment'] = createMomentObjectUtc(
            res.startTimeUTC
          ).tz(this.displayTimeZone);
          this.tripDistance = this.tripDetail.tripDistance * 0.62137;
          this.driverName = res.driverName;
          this.incidentList = [];
          this.dvrEventList = res.uploadRequests.map((dvr) => {
            dvr.dvrCreatedAtMoment = createMomentObjectUtc(dvr.createdAt).tz(
              this.displayTimeZone
            );
            dvr.startTimeMoment = createMomentObjectUtc(dvr.startTimeUTC).tz(
              this.displayTimeZone
            );
            return dvr;
          });
          this.onDVRIncidentChange('time');
          this.rowIncidentList = [];
          this.starttime = res.startTime;
          this.endtime = res.endTime;

          const addressList = await this._reverseGeocodeFromPoints(
            res.violations
          );

          const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);
          this.rowIncidentList = res.violations
            .filter((evt) => eventTypeAllowedList.includes(evt.eventType))
            .map((violation, index) => ({
              time: violation.timestamp,
              timeMoment: createMomentObjectUtc(violation.timestampUTC).tz(
                this.displayTimeZone
              ),
              type: EVENTS_INCIDENT_TYPE.find((x) => x[0] === violation.eventType),
              event: violation,
              locality:
              addressList[index] && addressList[index].locality
                ? addressList[index].locality
                : 'Unknown',
              district:
              addressList[index] && addressList[index].district
                ? addressList[index].district
                : 'NA',
            }));

          this.incidentList = this.rowIncidentList.sort((a, b) =>
            a.time.localeCompare(b.time)
          );
          this._getAPIData();
          this.setBackText();
          const cameraConnected = res.cameraSerialId === 'null' ? false : true;
          const tampered =
            cameraConnected && res.eventCount.total > 0
              ? this._zcfleet.getTamepringStatus(res)
              : false;
          this.tamperingMessage = tampered ? null : this.tamperingMessage;
          this.showTamperingBlock =
            !cameraConnected && !this.tripDetail['ongoing'] ? true : false;

          this.eventsList = this.combineEvents(res);
          this.pathInfo = res.pathInfo;
          this.loadEventsLocation(this.eventsList);
          this._preparePathList(res.pathInfo || []);
          // ({
          //     startLocation: this.startLocation,
          //     endLocation: this.endLocation
          // } = await this.getStartAndEndLocation(res.pathInfo));

          if (res.firstLocation && res.firstLocation.locationInfo) {
            if (res.firstLocation.locationInfo.City) {
              this.startLocation = res.firstLocation.locationInfo.City;
            } else if (res.firstLocation.locationInfo.Region) {
              this.startLocation = res.firstLocation.locationInfo.Region;
            }
          }
          if (res.lastLocation && res.lastLocation.locationInfo) {
            if (res.lastLocation.locationInfo.City) {
              this.endLocation = res.lastLocation.locationInfo.City;
            } else if (res.lastLocation.locationInfo.Region) {
              this.endLocation = res.lastLocation.locationInfo.Region;
            }
          }

          this.loading = false;
          if (this.eDVRVideoURL) {
            this.openEdvrVideoModal(this.eDVRVideoURL);
          }
        },
        (err) => {
          this.tripDetail = null;
          this._loading.hide();
          console.log('Trip details error--', err);
        }
      );
  }

  /**
   * @description: function to get geopoint from a list of lat, long value
   * @param: point objects that has latitude and longitude value
   * @returns: geocode object list
   */
  private async _reverseGeocodeFromPoints(
    points: Point[]
  ): Promise<GeoCodeResult[]> {
    const latLongPoints = points.map((point) => ({
      latitude: point.latitude,
      longitude: point.longitude,
    }));

    let locationResult: GeoCodeResult[] = [];
    try {
      locationResult = await this._mapService.getReverseGeoCodeMultiplePoint(
        latLongPoints
      );
    } catch (error) {
      locationResult = [];
    }

    return locationResult;
  }

  /**
   * @description: function to get API data params for the trip details http calls
   * @param:
   * @returns: driver trip list object with details
   */
  private _getAPIData() {
    const { days } = this.searchData;
    let range;
    if (days === 2) {
      const from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate
      );
      const to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate
      );
      range = { from, to };
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        range = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      range = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList = this._storageService.getStorageValue('HOME_LOCATION')?.length === 0
      ? this._storageService.getStorageValue('HOME_LOCATION_ABV')
      : this._storageService.getStorageValue('HOME_LOCATION');
    const driverTripListParam = {
      params: {
        startDate: range.from,
        endDate: range.to,
        limit: 50,
        offset: this._offset,
        sortBy: this.sortBy,
        sort: this.sort,
        divisions: this.homeLocationList,
      },
    };

    const challengesParams = {
      params: {
        startDate: range.from,
        endDate: range.to,
        offset: 0,
        raised: true,
        resolved: false,
        sort: 'asc',
        sortBy: 'timestamp',
      },
    };

    this._zcfleet.getFleetIncidents(challengesParams).subscribe((res) => {
      this._storageService.showNotification = res.length ? true : false;
    });

    // this._loading.show();
    this._zcfleet
      .getDriverTripList(this.driverId, driverTripListParam)
      .subscribe(
        (res) => {
          this._loading.hide();
          const list = [];
          const trip14DaysDate = new Date(
            +new Date(this.tripDetail.startTime.toLocaleString()) - 1209600000
          );
          const selectedDate = new Date(
            this.tripDetail.startTime.toLocaleString()
          );
          for (const item of res) {
            const dateStartTime = new Date(new Date(item.startTime.toLocaleString()).setHours(0, 0, 0, 0));
            if (
              trip14DaysDate <= dateStartTime &&
              selectedDate >= dateStartTime
            ) {
              const tripeDetail = {};
              tripeDetail['tripId'] = item.tripId;
              tripeDetail['startTime'] = item.startTime;
              const date = item.startTime.split('T');
              tripeDetail['date'] = date[0];
              list.push(tripeDetail);
            }
          }

          // In case there is no data to fill in the trip list
          if (list.length === 0) {
            const trip = {
              tripId: this.tripId,
              startTime: this.tripDetail.startTime,
              date: this.tripDetail.startTime.split('T')[0],
            };
            list.push(trip);
          }

          const uniqueAges = list
            .map((item) => item.date)
            .filter((value, index, self) => self.indexOf(value) === index);
          const indices = [];

          for (const eachUniqueAge of uniqueAges) {
            const test = list.map((item) =>
              item.date === eachUniqueAge ? item : null
            );

            const data = test.filter((e) => e);
            const eventlist = data.sort((obj1, obj2) => {
              const objDate1 = new Date(obj1.startTime);
              const objTime1 = objDate1.getTime();
              const objDate2 = new Date(obj2.startTime);
              const objTime2 = objDate2.getTime();
              return objTime1 - objTime2;
            });
            indices.push(eventlist);
          }

          // for (let k = 0; k < indices.length; k++) {
          for (const eachIndices of indices) {
            const totalTripCount: TripCount = {
              morningCount: 0,
              afternoonCount: 0,
              eveningCount: 0,
              nightCount: 0,
            };

            for (const item of eachIndices) {
              const tripData = {};
              tripData['tripId'] = item.tripId;
              tripData['startTime'] = item.startTime;
              let currentTime = item.startTime;
              currentTime = this._customDateFormatPipe.transform(currentTime);
              const time = new Date(currentTime).getHours();

              tripData['count'] = parseInt(
                this._getGreetingByTime(time, false, totalTripCount),
                10
              );

              this.triplist.push(tripData);
            }
          }
        },
        (err) => {
          this._loading.hide();
          console.log('Trip details error--', err);
        }
      );
  }

  private _getSingleCommentByViolationIdentifier(event) {
    const violationIdentifier = `${this.tripId}-${event.eventIndex}`;
    this._loadingChange$.next({ eventIndex: event.eventIndex, isLoading: true });
    combineLatest([
      this._zcfleet.getStatusBookmarkByViolationIdentifier(violationIdentifier),
      this._zcfleet.getSingleCommentByViolationIdentifier(violationIdentifier),
    ])
      .pipe(finalize(() => {
        this._loadingChange$.next({ eventIndex: event.eventIndex, isLoading: false });
      }))
      .subscribe(
        ([statusBookmark, commentViolation]) => {
          this.eventTripMetadata = this._buildBookmarkInfo({
            bookmark: statusBookmark.bookmark || false,
            eventIndex: event.eventIndex,
            eventType: event.eventType,
            comments: commentViolation || [],
            timestampUTC: event.timestampUTC,
          });
        },
        (_err) => {
          this._snackbarService.error('Something went wrong', 'Try Again').subscribe(() => {
            this._getSingleCommentByViolationIdentifier(event);
          });
        }
      );
  }

  private _buildBookmarkInfo(eventList) {
    return {
      ...eventList,
      tripId: this.tripId,
      driverId: this.driverId,
      displayTimeZone: this.displayTimeZone,
    };
  }

  /**
   * @description: function to fetch new trip details
   * @param: tripId
   */
  private _fetchNewTripDetails(newTripId: string) {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        ...this._activatedRoute.snapshot.queryParams,
        tripId: newTripId,
      },
      replaceUrl: true,
    });
  }

  /**
   * @description: function to prepare path list
   * @param: path list with lat long
   */
  private _preparePathList(list: any[]) {
    // Filter out the events where lat lon is 0
    const filteredList = list.filter(
      (event) => +event.latitude || +event.longitude
    );
    // Loop through filtered list and generate an array of lat-long
    const path = filteredList.map((event) =>
      this._mapService.getLatLong(event.latitude, event.longitude)
    );
    if (list.length) {
      path.push(
        this._mapService.getLatLong(
          list[list.length - 1].latitude,
          list[list.length - 1].longitude
        )
      );
    }
    this.pathList = path.slice();
  }

  /**
   * @description: function check time and return a greeting
   * @param time time of day
   * @returns A greeting depends on time
   */
  private _getGreetingByTime(
    time: number,
    useGreeting: boolean,
    totalTripCount?: TripCount
  ): string {
    const data = [
      // [start, end, greeting, count]
      [6, 11, 'Morning', useGreeting ? '' : 'morningCount'], // 6AM to 11AM Morning
      [12, 17, 'Afternoon', useGreeting ? '' : 'afternoonCount'], // 12AM to 5PM Afternoon
      [18, 22, 'Evening', useGreeting ? '' : 'eveningCount'], // 6PM to 22PM Evening
    ];
    for (const item of data) {
      if (time >= item[0] && time <= item[1]) {
        return useGreeting
          ? (item[2] as string)
          : (++totalTripCount[item[3]]).toString();
      }
    }
    // from 23PM to 5AM Night
    return useGreeting ? 'Night' : (++totalTripCount.nightCount).toString();
  }

  /**
   * @description: method listen when create capture request
   * @returns: dvrEventList
   */
  private _listenCaptureRequest() {
    this._zcfleet.isRefreshDvr$.pipe(
      filter(value => !!value),
      takeUntil(this._destroy$),
      tap(() => this.loaderDVR = true)
    ).subscribe(() => {});

    this._zcfleet.isRefreshDvr$.pipe(
      filter(value => !value),
      takeUntil(this._destroy$)
    ).subscribe(() => {
      const params = {
        includeUploadRequests: true,
        includeUploadStats: true,
        ignoreCache: true,
      };
      this._zcfleet
        .getTripDetails(this.driverId, this.tripId, params)
        .pipe(finalize(() => this.loaderDVR = false))
        .subscribe(async res => {
          this.dvrEventList = res.uploadRequests.map(dvr => {
            this.loaderDVR = false;
            dvr.dvrCreatedAtMoment = createMomentObjectUtc(dvr.dvrCreatedAt).tz(this.displayTimeZone);
            dvr.startTimeMoment = createMomentObjectUtc(dvr.startTimeUTC).tz(this.displayTimeZone);
            return dvr;
          });
        });
    });
  }

  private _checkSmartFleet() {
    this._permission.requestPermissionCheck('fleet-ridecam-plus');
    return this._permission.getPermissionObs('fleet-ridecam-plus').pipe(
      takeUntil(this.isSmartFleet$)
    ).subscribe(
      res => {
        this.isSmartFleet$.next(res);
        this.isSmartFleet$.complete();
      }
    );
  }

  /**
   * fetches start and and end location, if not available then assign 'unkonwn'
   *
   * @param pathInfo
   */
  // private async getStartAndEndLocation(pathInfo) {
  //     try {
  //         const { latitude: startLocationLat, longitude: startLocationLng } = pathInfo[0];

  //         const { latitude: endLocationLat, longitude: endLocationLng } = pathInfo[
  //             pathInfo.length - 1
  //         ];
  //         const locations = await Promise.all([
  //             this.getAddress(startLocationLat, startLocationLng),
  //             this.getAddress(endLocationLat, endLocationLng)
  //         ]);
  //         return {
  //             startLocation: locations[0],
  //             endLocation: locations[1]
  //         };
  //     } catch (error) {
  //         return {
  //             startLocation: 'Unknown',
  //             endLocation: 'Unknown'
  //         };
  //     }
  // }
}
