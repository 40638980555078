import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';

import { API } from '@app-core/constants/api.constants';

import { ExceptionsParams } from '@modules/exception-info/common/exception-info.model';

import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable()
export class ExceptionInfoService {
  constructor(private _http: HttpClient) {}

  public getVersionCrashCount(): Observable<any> {
    return this._getData(API.GET_VERSION_CRASH_COUNT, {});
  }

  public getExceptionInfo(param: ExceptionsParams): Observable<any> {
    return this._getData(API.GET_EXCEPTIONS, param);
  }

  /**
   * @description: Creating request object for GET requests
   * @param params
   */
  private _setParams(params) {
    return new HttpParams({
      fromObject: params,
    });
  }

  private _getData(url: string, params: any) {
    const options = {
      params: this._setParams(params),
    };
    return this._http.get(url, options).pipe(retry(3));
  }
}
