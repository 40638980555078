import { Component, Input, OnInit } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export interface KeyMetric {
  name?: string;
  value: number | string;
  difference: number | string;
  decimalType?: string;
}

interface KeyMetricHeaderRow {
  values: string[];
  longestName: string;
}

@Component({
  selector: 'app-key-metric-overview',
  templateUrl: './key-metric-overview.component.html',
  styleUrls: ['./key-metric-overview.component.scss'],
})

export class KeyMetricOverviewComponent implements OnInit {
  /**
   * Example of input is an array where the key is name and value
   * name is the total parameter you want to display, the value is the value of that total parameter
   * decimalType is an option to choose how many decimals to display: '1.0-0', '1.0-2'...
   * TO-DO difference percentage variation of parameter
   *Ex: [
      { name: <name>, value: <value>, decimalType: <decimalType>, difference: <difference> },
      ...
    ]);
   */
  @Input()
  public set metricData$(value: Observable<KeyMetric[]>) {
    this.internalMetricData$ = value.pipe(shareReplay());
  }

  @Input()
  public set reload$(value: Observable<boolean>) {
    this.isNotLoading$ = merge(value, this.internalMetricData$).pipe(
      // value is only boolean when we have a begin loading event
      // boolean => isLoading
      // metricData => !isLoading
      map(mergeValue => (mergeValue !== true && mergeValue !== undefined))
    );
  }

  /**
   * Example of input is an array where the key is values[], longestName
   * values array of total parameters, longestName is the longest total parameter
   *Ex: [
      { values: ['Total Miles', 'Total Hours' ...], longestName: <longestName> },
      ...
    ]);
   */
  @Input() public metricHeaders: KeyMetricHeaderRow[];

  public internalMetricData$: Observable<KeyMetric[]>;
  public isNotLoading$: Observable<boolean>;

  constructor() { }

  public ngOnInit() {}

  public filterMetricDataByName(data: KeyMetric[], keyName: string): KeyMetric {
    return data.find((element: KeyMetric) => element.name === keyName )
    || { value: 0, difference: 0 };
  }
}
