import { Injectable, Inject } from '@angular/core';
import { WINDOW_OBJECT } from './token.provider';

// eslint-disable-next-line no-shadow
export enum ToggleTableViewStatus {
  OPEN = 'open',
  CLOSE = 'close',
}

interface WindowWithGtag extends Window {
  gtag: (event: string, category: string, data: any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  constructor(@Inject(WINDOW_OBJECT) private _windowObj: WindowWithGtag) {}

  /**
   * Push Login event into datalayer.
   *
   * @param fleetId Fleet ID.
   * @param email Email address of the logged in user.
   */
  public login(fleetId = '', email = '') {
    const userId = window.btoa(email);
    this._pushEvent({
      event: 'login',
      eventCategory: 'Login',
      fleetId,
      userId,
    });
  }

  /**
   * Push driver card click event into datalayer.
   */
  public driverCardClick({ driverName = '', driverId = '' }) {
    this._pushEvent({
      event: 'driver_card_click',
      eventCategory: 'Driver card click',
      driverName,
      driverId,
    });
  }

  /**
   * Push toggle driver details table event into datalayer.
   *
   * @param status 'open' if the table is being opened, 'close' otherwise.
   */
  public toogleDriverDetailsTable(status: ToggleTableViewStatus) {
    this._pushEvent({
      event: 'toggle_driver_details_table',
      eventCategory: 'Toggle table view',
      eventLabel: 'Driver details table',
      eventAction: status,
    });
  }

  /**
   * Push toggle trip details table event into datalayer.
   *
   * @param status 'open' if the table is being opened, 'close' otherwise.
   */
  public toogleTripDetailsTable(status: ToggleTableViewStatus) {
    this._pushEvent({
      event: 'toggle_trip_details_table',
      eventCategory: 'Toggle table view',
      eventLabel: 'Trip details table',
      eventAction: status,
    });
  }

  /**
   * Push incident marker click event into datalayer.
   */
  public incidentMarkerClick({ incidentType = '', timestampUTC = '' }) {
    this._pushEvent({
      event: 'trip_incident_marker_click',
      eventCategory: 'Trip incident click',
      incidentType,
      timestampUTC,
    });
  }

  /**
   * Push request timing successfully event into datalayer.
   */
  public trackingPerformanceRequestEvent(label: string, time: number) {
    this._customPushEvent({
      action: 'Time of successful requests',
      event_category: 'Performance',
      event_label: label || '',
      value: time || 0,
    });
  }

  /**
   * Push number of successful request  event into datalayer.
   */
  public trackingSuccessRequestEvent(label: string, times: number) {
    this._customPushEvent({
      action: 'Number of successful requests',
      event_category: 'Availability',
      event_label: label || '',
      value: times || 0,
    });
  }

  public trackingNavigationEvent(label: string, times: string) {
    this._customPushEvent({
      action: 'Number of navigation times',
      event_category: 'Navigation',
      event_label: label || '',
      value: times || 0,
    });
  }

  private _gtag(event: string, category: string, data: any): void {
    this._windowObj.gtag(event, category, data);
  }
  /**
   * Helper function push events to datalayer array.
   */
  private _pushEvent({ event, eventCategory, ...otherInfo }) {
    // This check is required because all dev and qa environments will not have
    // window.dataLayer initialized since we have not configured GTM for these environments.
    if (Array.isArray((window as any).dataLayer)) {
      (window as any).dataLayer.push({
        event,
        eventCategory,
        ...otherInfo,
      });
    }
  }

  /**
   * Helper function push events to datalayer array.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _customPushEvent({ action, event_category, event_label, value }) {
    // This check is required because all dev and qa environments will not have
    // window.dataLayer initialized since we have not configured GTM for these environments.
    if (Array.isArray((window as any).dataLayer)) {
      this._gtag('event', action, {
        event_category,
        event_label,
        value,
      });
    }
  }
}
