import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class SearchFilterPipe implements PipeTransform {
  public transform(items: any[], searchText: string, key?: string): any[] {
    if (items && items.length) {
      if (!searchText) {
        return items;
      }
      const ob = items[0];
      if (typeof ob === 'object' && !key) {
        return items;
      }

      searchText = searchText.toLowerCase();

      return items.filter((item: string) => {
        if (key) {
          return item[key]
            ? item[key].toLowerCase().includes(searchText)
            : false;
        } else {
          return item ? item.toLowerCase().includes(searchText) : false;
        }
      });
    }
    return items;
  }
}
