<div class="driver-score-item-container"
    [ngClass]="{
        'no-incident': driver?.eventCount?.total === 0
    }">
    <ng-container *ngTemplateOutlet="displayDriversNameAsync"></ng-container>
    <div *ngIf="driver?.tripCount !== 0">
        <div class="scoreboard-card-body">
            <div class="col-6">
                <ng-container *ngTemplateOutlet="displayChartgraphAsync"></ng-container>
            </div>
            <div class="col-6">
                <ng-container *ngTemplateOutlet="displayIncidentsBlockAsync"></ng-container>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="displayScoreTrendAsync"></ng-container>
    </div>

    <ng-template #displayChartgraphAsync>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingChartgraph">
            <div class="scoreboard-card-body"
            *appTransformIncidentStat="let incidentStatList from driver;
                let pieChartOptions=scorePieChartOptions;
                options: { ignoreZero: true, sliceIndex: 4 }">
            <div class="average-score chartgraph col-6" *ngIf="pieChartOptions" >
                <highcharts-chart [Highcharts]="highcharts" [options]="pieChartOptions"
                    [oneToOne]="true"></highcharts-chart>
            </div>
        </div>
        </ng-container>
    </ng-template>

    <ng-template #displayIncidentsBlockAsync>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingIncidentsBlock">
            <div class="scoreboard-card-body"
            *appTransformIncidentStat="let incidentStatList from driver;
                let pieChartOptions=scorePieChartOptions;
                options: { ignoreZero: true, sliceIndex: 4 }">
                <div class="charttable col-6">
                    <table class="incident_table" *ngIf="driver?.eventCount?.total === 0"
                    style="min-width: 115px; margin-left: 17px; margin-top: 70px;">
                        <tr>
                            <td class="incident_text totalincident_block" style="width: 100px">Total Incidents</td>
                            <td class="totalincident_empty"></td>
                            <td class="totalincident_number numbercount">0</td>
                        </tr>
                    </table>
                    <app-incident-stat
                        [incidentStatList]="incidentStatList" [showOthers]="true" [showTotalIncidents]="true"
                    ></app-incident-stat>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #displayDriversNameAsync>
        <ng-container *ngIf="!isShowSkeletonLoading, else skeletonLoadingDriversName">
            <div class="scoreboard-card-head">
                <div class="driver-name">{{driver.driverName}}</div>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #displayTotalIncidentsAsync>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingTotalIncidents">
            <div class="scoreboard-card-body"
            *appTransformIncidentStat="let incidentStatList from driver;
                let pieChartOptions=scorePieChartOptions;
                options: { ignoreZero: true, sliceIndex: 4 }">
                <div class="average-score chartgraph col-6" *ngIf="pieChartOptions" >
                    <highcharts-chart [Highcharts]="highcharts" [options]="pieChartOptions"
                        [oneToOne]="true"></highcharts-chart>
                </div>
                <div class="charttable col-6">
                    <ng-container *ngTemplateOutlet="displayTotalIncidentsBlockAsync"></ng-container>
                </div>
            </div>
        </ng-container>
    </ng-template>

    <ng-template #displayTotalIncidentsBlockAsync>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingTotalIncidents">
            <table class="incident_table" *ngIf="driver?.eventCount?.total === 0"
            style="min-width: 115px; margin-left: 17px; margin-top: 70px;">
                <tr>
                    <td class="incident_text totalincident_block" style="width: 100px">Total Incidents</td>
                    <td class="totalincident_empty"></td>
                    <td class="totalincident_number numbercount">0</td>
                </tr>
            </table>
                <!-- Component list incident icon -->

            <app-incident-stat
                [incidentStatList]="incidentStatList" [showOthers]="true" [showTotalIncidents]="true"
            ></app-incident-stat>
        </ng-container>
    </ng-template>

    <ng-template #displayScoreTrendAsync>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingScoreTrend">
            <div class="textchart" *ngIf="tripList.length > 0 && lineOption">
                <div class="scorepast_days">Past {{numberOfDays | number:'1.0-0'}} Days Score Trend</div>
                <div class="text_detailst pt10">
                    <span class="pull-left">{{range?.from | date:'MM/dd'}}</span>
                    <span class="pull-right" *ngIf="showRange">{{range?.to | date:'M/dd' }}</span>
                    <span class="pull-right" *ngIf="!showRange">Today</span>
                </div>
                <highcharts-chart [Highcharts]="linecharts" [options]="lineOption"
                    [oneToOne]="true"></highcharts-chart>
                <div class="text_detailst">
                    <span class="pull-left">{{ scoreStat?.earliestScore }}</span>
    
                    <span class="pull-right">{{ scoreStat?.latestScore }}</span>
                </div>
                <div class="scoreincreased">
                    <span>Score {{scoreStat?.difference >= 0 ? 'increased' : 'decreased'}} by {{ scoreStat?.difference | absPipe }} points</span>
                </div>
                  <span *ngIf="isDistractedDisclaimerFlag" class="distraction-disclaimer"><img src="assets/images/info_24px.svg">
                    {{distractionDisclaimer}}</span>
            </div>
            <div class="view-link card_viewlink" data-test="score-card-view-detail-button" (click)="gotoDriverDetail(driver)">
                <span>View Details</span>
            </div> 
        </ng-container>
        <ng-container *ngIf="!isShowSkeletonLoading , else skeletonLoadingDiagram">
        </ng-container>
    </ng-template>

    <ng-template #skeletonLoadingDriversName>
        <app-custom-skeleton-loader
            [theme]="{
                width: '210px',
                height: '28px'
            }">
        </app-custom-skeleton-loader> 
    </ng-template>
    <!-- loading skeleton for total incident-->
    <ng-template #skeletonLoadingIncidentsBlock>
        <div class="loading-total-incident">
            <div class="loading-total-incident-stat">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '20px'
                }">
                </app-custom-skeleton-loader>
            </div>
            <div class="loading-incident-stat-list">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '24px'
                }">
                </app-custom-skeleton-loader>
            </div>
            <div class="loading-incident-stat-list">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '24px'
                }">
                </app-custom-skeleton-loader>
            </div>
            <div class="loading-incident-stat-list">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '24px'
                }">
                </app-custom-skeleton-loader>
            </div>
            <div class="loading-incident-stat-list">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '24px'
                }">
                </app-custom-skeleton-loader>
            </div>
            <div class="loading-incident-stat-list">
                <app-custom-skeleton-loader
                [theme]="{
                    width: '137px',
                    height: '24px'
                }">
                </app-custom-skeleton-loader>
            </div>
        </div>
    </ng-template>
    <ng-template #skeletonLoadingChartgraph>
        <div class="loading-chart-graph-stat">
            <app-custom-skeleton-loader
                [theme]="{
                    width: '178px',
                    height: '178px',
                    'border-radius': '50%'
                }">
            </app-custom-skeleton-loader> 
        </div>
    </ng-template>
    <ng-template #skeletonLoadingScoreTrend>
        <div class="loading-score-trend-stat">
            <app-custom-skeleton-loader
                [theme]="{
                    width: '210px',
                    height: '24px'
                }">
            </app-custom-skeleton-loader> 
        </div>
    </ng-template>
    <ng-template #skeletonLoadingDiagram>
        <app-custom-skeleton-loader
            [theme]="{
                width: '374px',
                height: '57px'
            }">
        </app-custom-skeleton-loader> 
        <div
            class="no-view-link card_viewlink"
            data-test="current-score-card-view-detail-button"
        >
            <span>View Details</span>
        </div>
    </ng-template>
    <div *ngIf="driver?.tripCount === 0">
        <div class="scoreboard-card-body">
            <div class="no-trip-record col-12">
                No driving during this period.
            </div>
        </div>
    </div>
</div>
