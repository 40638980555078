import { Component, Input, OnDestroy, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { SlickCarouselComponent } from 'ngx-slick-carousel';

import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { catchError, distinctUntilChanged, map } from 'rxjs/operators';

import { DateService } from '@app-core/services/date.service';
import { StorageService } from '@app-core/services/storage.service';

import { Data } from '@modules/dashboard3/services/data.service';
import { MapService } from '@modules/dashboard3/services/map.service';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { DateFormatPipe } from '@modules/shared/pipes/date-format.pipe';
import { convertAssetTripListForTripRecap } from '@modules/dashboard3/minimap_utils';
import { ErrorMessage } from '@modules/shared/components/error-message/error-message.component';
import { calcSumTotalIncidents } from '@modules/shared/utils';

import {
  FleetAllTripsModel,
  ModalParamsModel,
  SearchFilterModel,
} from './../../../dashboard3.models';
import {
  LOCAL_TRIP_RECAPS_KEY,
} from '@app-core/constants/constants';
import { TripRecapsStorage } from '../../fleet-details/triprecaps/triprecaps.component';

@Component({
  selector: 'app-driver-trip-driven',
  templateUrl: './driver-trip-driven.component.html',
  styleUrls: ['./driver-trip-driven.component.scss'],
})
export class DriverTripDrivenComponent implements OnDestroy, OnInit {
  @Input() public totalTrips: any;
  @Input() public driverIds: string[] = [];
  @Input() public driverName: string;
  @Input() public filterChangeObs$: Observable<SearchFilterModel>;
  @ViewChild('slickModal')
  public slickModal: SlickCarouselComponent;

  @Output() public isMainApiFailed = new EventEmitter<boolean>();

  public internalDriverTripChanges$: Observable<any>;
  public notLoadingState$: Observable<boolean>;
  public listData = null;
  public tripListPage = [];
  public allAssetsList = [];
  public filterOptions: SearchFilterModel;
  public slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    variableWidth: true,
    nextArrow: '',
    prevArrow: '',
    draggable: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  public show = 'loading';
  public limit = 5;
  public sortBy = 'startTime';
  public sort = 'desc';
  public reset = false;
  public triplist: any[] = [];
  public tripDetails: any[] = [];
  public dummyTripDetails: any[] = [];
  public isShowSkeleton = true;
  public isShowDummySkeleton = false;
  public showSortList = 'enable';
  public tablelimit = false;
  public cameraConnected: boolean;
  public prevtab = false;
  public olditemlist = 0;
  public nextarrow = true;
  public prevarrow = false;
  public showList = false;
  public sortListBy = 'Most Recent';
  public homeLocationList = [];
  public tripDataSubs$: Subscription;

  public showRetryButton = true;
  public get errorMessage$(): Observable<ErrorMessage> {
    return this._errorMessage$;
  }
  private _errorMessage$ = new ReplaySubject<ErrorMessage>();

  private _offset = 0;
  private _list = new BehaviorSubject([]);
  private _filterChangeSubscription: any;
  private _modalParams: ModalParamsModel = { state: false, component: '' };
  private _subscription: Subscription = new Subscription();

  constructor(
    private _zcfleet: ZCFleetService,
    private _dateService: DateService,
    private _data: Data,
    private _mapService: MapService,
    private _customDateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _storageService: StorageService
  ) {
    this.listData = this._list.asObservable();
  }

  public ngOnInit() {
    this.filterChangeObs$
      .pipe(distinctUntilChanged())
      .subscribe((filterOptions) => {
        // Unsubscribe prevous data request when filter change
        if (this.tripDataSubs$) {
          this.tripDataSubs$.unsubscribe();
        }
        // Show 4 skeletons by default for Trip Recaps when loading data
        this.dummyTripDetails = this._createDummyData(4);
        this.isShowDummySkeleton = true;
        this.setStatusForSortList('enable');
        this.filterOptions = filterOptions;
        this.tripDetails = [];
        this.triplist = [];
        this.olditemlist = 0;
        this._offset = 0;
        this.prevtab = false;
        this.prevarrow = false;
        this.showList = false;
        const slider = 'false';
        this.refreshList(slider);
      });
    this.getAllAssets();
  }

  public afterChange(event) {
    if (
      this.triplist.length - 4 === event.nextSlide ||
      this.triplist.length - 3 === event.nextSlide ||
      this.triplist.length - 2 === event.nextSlide ||
      this.triplist.length - 1 === event.nextSlide
    ) {
      this.nextarrow = false;
    } else {
      this.nextarrow = true;
    }
    if (
      (event.currentSlide === 2 && event.nextSlide === 0) ||
      (event.currentSlide === 3 && event.nextSlide === 0) ||
      (event.currentSlide === 4 && event.nextSlide === 0)
    ) {
      this.prevarrow = false;
    } else {
      this.prevarrow = true;
    }

    if (event.currentSlide === 0 && event.nextSlide === 0) {
      this.prevarrow = false;
    }
  }

  /*
        desc: This wil list driver trips card for every next slides
        params: empty
        result: it will return driver score list as slides
      */
  public next() {
    //op
    this._offset = this.triplist.length;
    this.limit = 5;
    if (this.totalTrips > this.triplist.length) {
      if (this.prevtab === true) {
        this.slickModal.slickNext();
        this.olditemlist += 5;
        if (this.olditemlist === this.triplist.length) {
          this.prevtab = false;
        }
      } else {
        const slider = 'true';
        this.isShowSkeleton = true;
        this.refreshList(slider);
      }
    } else {
      this.slickModal.slickNext();
    }
  }

  /*
        desc: This wil list driver trips card for every prev slides
        params: empty
        result: it will return driver score list as slides
      */
  public prev() {
    this.prevtab = true;
    const itemcount = this.olditemlist - 5;
    this.olditemlist = itemcount;
    this.slickModal.slickPrev();
  }

  public ngOnDestroy() {
    // Unsubscribe from filter change event
    if (this._filterChangeSubscription) {
      this._filterChangeSubscription.unsubscribe();
    }
  }

  public showTripsDrivenSortList() {
    if (!this.isShowDummySkeleton && !this.isShowSkeleton) {
      this.showList = !this.showList;
    }
  }

  /*
        desc: This wil list driver trips details based on duration filter
        params: It will takes event as params
        result: it will return driver trips list
      */

  public selectTrips(event) {
    this.isShowDummySkeleton = true;
    this.setStatusForSortList('enable');
    if (event === 'false') {
      this.sort = 'asc';
      this.showList = false;
      this.sortListBy = 'Least Recent';
    } else {
      this.sort = 'desc';
      this.showList = false;
      this.sortListBy = 'Most Recent';
    }
    this.showList = false;
    this.slickModal.slickGoTo(0);
    this.tripDetails = [];
    this.triplist = [];
    this.olditemlist = 0;
    this._offset = 0;
    this.prevtab = false;
    this.prevarrow = false;
    const slider = 'false';
    this.refreshList(slider);
    const tripFilter: TripRecapsStorage = {
      sort: this.sort,
      showList: this.showList,
      sortListBy: this.sortListBy,
    };
    this.setTripRecapsFilter(LOCAL_TRIP_RECAPS_KEY, tripFilter);
  }

  /*
        desc: This wil model pop for driver trips details based on duration filter
        params: empty
        result: it will return driver trips list
      */

  public toggleTable() {
    this._modalParams.state = true;
    this._modalParams.component = 'driverTrips';
    this._modalParams.drivername = this.driverName;
    this._zcfleet.commonTableModal.next(this._modalParams);
  }

  /*
        desc: This wil generate driver details based on duration filter
        params: It will takes status as boolean params
        result: it will return driver trip details
      */

  public refreshList(sliderstatus) {
    this.show = 'loading';
    this.limit = 5;
    this.getTripList()
      .then((res: any) => {
        this.show = res.length !== 0 ? 'data' : 'empty';
        if (res.length !== 0) {
          this.isShowSkeleton = true;
          this.olditemlist += res.length;
          const tripDetailsApis = (res || []).map((trip) =>
            this._zcfleet
              .getTripDetails(trip.driverId, trip.tripId)
              .pipe(catchError((_err) => of(null)))
          );
          this.tripDataSubs$ = forkJoin(tripDetailsApis)
            .pipe(map((tripDetails) => tripDetails.filter((item) => !!item)))
            .subscribe((results) => {
              this.triplist = [...this.triplist, ...results];
              results.forEach((trip) => this.getDriverTripDetails(trip));
              if (this.triplist.length <= 4) {
                this.nextarrow = false;
              } else {
                this.nextarrow = true;
              }
              this.isShowSkeleton = false;
              this.isShowDummySkeleton = false;
              if (sliderstatus === 'true') {
                this.slickModal.slickNext();
              }
            });
        } else if (this.triplist.length) {
          // if no trip, do not call slickNext
          this.slickModal.slickNext();
        } else {
          // if trip = 0, disable skeleton and show message "No data available"
          this.isShowSkeleton = false;
          this.isShowDummySkeleton = false;
          this.setStatusForSortList('disable');
        }
      })
      .catch((err) => {
        console.error(`Received Error: ${err}`);
        this.isShowSkeleton  = true;
        this.isShowDummySkeleton = true;
        this.setStatusForSortList('disable');
        this.show = 'loading';
      });
  }

  /*
        desc: This wil generate driver details based on duration filter
        params: It will takes trips as params
        result: it will return driver details
      */

  public getDriverTripDetails(tripInfo) {
    const tripDetail = convertAssetTripListForTripRecap(
      tripInfo,
      this._mapService,
      this._zcfleet
    );
    this.cameraConnected = tripDetail['cameraConnected'];
    this.tripDetails.push(tripDetail);
  }

  /*
        desc: This wil generate Combine violations, calibrations and video capture events
        params: It will takes tripes details params
        result: it will return driver trip violations and video capture
      */

  public combineEvents(res: any): any[] {
    let events = [];
    try {
      events = res.violations;
      // if there are violations , then do not show chalibration icon on map
      if (events.length === 0) {
        // Check to see if calibration and custom events are present
        // If so, then combine these with the violations
        if (res.debug && res.debug.length) {
          events = [...events, ...res.debug];
        }
      }
      if (res.customEvents && res.customEvents.length) {
        events = [...events, ...res.customEvents];
      }
    } catch (error) {
      console.error(error);
      return [];
    }

    return events;
  }

  /*
        desc: This wil generate trip name based on time started of trip
        params: It will takes trip starttime params
        result: it will return tripe name and count
      */

  public getGreetingText(date) {
    if (date) {
      // return this._dateService.getGreetingTime(date);
      let currentTime = date;
      currentTime = this._customDateFormatPipe.transform(currentTime);
      const time = new Date(currentTime).getHours();

      let greeting;
      if (time > 5 && time <= 11) {
        // 6AM to 11AM
        greeting = 'Morning';
      } else if (time > 11 && time <= 17) {
        // 12PM to 5PM
        greeting = 'Afternoon';
      } else if (time > 17 && time <= 22) {
        // 6PM to 10PM
        greeting = 'Evening';
      } else {
        greeting = 'Night'; // 8PM to 5AM
      }
      return greeting;
    }
  }

  /*
        desc: This wil generate driver trips based on duration filter
        params: empty
        result: it will return fleet driver trips
      */

  public getTripList() {
    const { days, dutyType, minScore, maxScore, ids } = this.filterOptions;
    // When minscore and maxscore filter is applied and if ids list is empty
    // then return an empty array
    // Because when the ids are empty, the API returns all trips, which is not
    // desired
    if (minScore !== 0 || maxScore !== 100) {
      if (ids.length === 0) {
        return Promise.resolve([]);
      }
    }
    let date = { from: null, to: null };
    if (days === 2) {
      const getDateRangeFromLocalStorage = JSON.parse(
        localStorage.getItem('CUSTOM_RANGE')
      );
      date.from = this._dateService.toDaysStartISO(
        this._dateService.customStartdate ||
          getDateRangeFromLocalStorage.data.from
      );
      date.to = this._dateService.toDaysEndISO(
        this._dateService.customEndDate || getDateRangeFromLocalStorage.data.to
      );
      if (
        this._dateService.customStartdate === undefined ||
        this._dateService.customEndDate === undefined
      ) {
        date = this._data.customRange.data;
        this._dateService.customStartdate = this._data.customRange.data.from;
        this._dateService.customEndDate = this._data.customRange.data.to;
      }
    } else {
      date = this._dateService.getDateRangeInISO(days);
    }
    this.homeLocationList =
      this._storageService.getStorageValue('HOME_LOCATION_ABV');
    const params = new FleetAllTripsModel(
      date.from,
      date.to,
      dutyType,
      this.limit,
      this._offset,
      this.sort,
      this.sortBy,
      this.homeLocationList
    );

    const OPTIONS = {
      ...params,
      driverIds: this.driverIds,
    };
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    return new Promise((resolve, reject) => {
      this._subscription = this._zcfleet.getTripList(OPTIONS).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          this.isMainApiFailed.emit(true);
        }
      );
    });
  }

  public gotoTripDetail(trip) {
    this._router.navigate(['/trip-detail'], {
      queryParams: {
        tripId: trip.tripId,
        driverId: trip.driverId,
      },
    });
  }

  public getAllAssets() {
    this._zcfleet.getAllAssets().subscribe(
      (res) => {
        this.allAssetsList = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public totalIncidentsEventType(eventCount: Record<string, string>): number {
    return calcSumTotalIncidents(eventCount);
  }

  public setStatusForSortList(key: string) {
    this.showSortList = key;
  }

  public getTripRecapsFilter(key: string) {
    const filterTripStorage = this._storageService.getStorageValue(key);
    if (filterTripStorage) {
      const values: TripRecapsStorage = JSON.parse(filterTripStorage);
      this.sort = values.sort;
      this.showList = values.showList;
      this.sortListBy = values.sortListBy;
    }
  }

  public setTripRecapsFilter(key: string, values: TripRecapsStorage) {
    this._storageService.setStorageValue(key, JSON.stringify(values));
  }

  private _createDummyData(itemNumber: number) {
    const result = [];
    for (let i = 0; i < itemNumber; i++) {
      result.push({
        driverId: i,
        driverName: i,
        lastTrip: i,
        totalTrip: i,
        totalIncident: i,
        topIncident: i,
      });
    }
    return result;
  }
}
