<header>
   <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand logo" routerLink="/dashboard">
         <img src="assets/images/coach-logo.png">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
         aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">

         <ul class="nav sub-nav">
            <li class="nav-item" routerLink="/dashboard" routerLinkActive="active">
               <a class="nav-link">
                  <i class="fa fa-dashboard"></i>
                  <span>Dashboard</span>
               </a>
            </li>
            <li class="nav-item"
               routerLink="/package-configuration"
               routerLinkActive="active"
               *ngIf="access.canAccess | async ">
               <a class="nav-link">
                  <i class="fa fa-list-ul"></i>
                  <span>Package Configuration</span>
               </a>
            </li>
            <li class="nav-item"
               routerLink="/exception-info"
               routerLinkActive="active"
               *ngIf="access.canAccess | async">
               <a class="nav-link">
                  <i class="fa fa-exclamation-triangle"></i>
                  <span>Exception Info</span>
               </a>
            </li>
            <li class="nav-item"
               routerLink="/unconnected-trips"
               routerLinkActive="active"
               *ngIf="access.canAccess | async">
               <a class="nav-link">
                  <i class="fa fa-plug"></i>
                  <span>Unconnected Trips</span>
               </a>
            </li>
         </ul>
         <div class="ml-auto">
            <ul class="nav">
               <li class="nav-item">
                  <button class="btn btn-primary" role="button" (click)="logout()"> Logout </button>
               </li>
            </ul>
         </div>
      </div>
   </nav>

</header>
