import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

interface Result {
  list: any[];
}

interface PageChange {
  limit: number;
  offset: number;
  activePage: number;
}

class Page {
  constructor(public pageNumber: number, public data: any[]) {}
}

const PAGE_LIMIT = 10;
const LENGTH = 1000;

@Component({
  selector: 'app-paginator3',
  templateUrl: './paginator3.component.html',
  styleUrls: ['./paginator3.component.scss'],
})
export class Paginator3Component implements OnInit, OnDestroy, OnChanges {
  @Input() public pageLimit: number = PAGE_LIMIT;
  @Input() public length = 0;
  @Input() public data: Observable<any>;
  @Output() public pageChangeData = new EventEmitter<Result>();
  @Output() public pageChange = new EventEmitter<PageChange>();
  @Output() public currentPageChange = new EventEmitter<number>();

  @Input() public reset = false;
  @Output() public resetChange = new EventEmitter<boolean>();

  public activePage = 0;
  public pageStart = 0;
  public pageEnd = 0;

  public imgBtnFirstEnabled = new Image();
  public imgBtnFirstDisabled = new Image();
  public imgBtnPreviousEnabled = new Image();
  public imgBtnPreviousDisabled = new Image();
  public imgBtnNextEnabled = new Image();
  public imgBtnNextDisabled = new Image();
  public imgBtnLastEnabled = new Image();
  public imgBtnLastDisabled = new Image();

  private _firstUpdate = true;
  private _pageArray: Page[] = [];
  private _subscription: Subscription;

  public ngOnInit() {
    // Preload Image when component init to not lose icon error
    this._initPreloadImage();
    this._initPaginator();
    if (this.data) {
      this._subscription = this.data.subscribe(
        (data) => {
          // Only push data if the data is an array and is not empty
          if (data && data.length !== 0) {
            this._pageArray.push(new Page(this.activePage, data));
            this.setPage(this.activePage);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  public ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.reset) {
      if (changes.reset.currentValue === true) {
        this._resetPaginator();
        // Set reset flag to false after some delay
        setTimeout(() => {
          this.resetChange.emit(false);
        }, 1);
        this._initPaginator();
      }
    }
    if (changes.length) {
      if (changes.length.currentValue > 0) {
        this._initPaginator();
      }
    }
  }

  public setPage(n: number) {
    this.activePage = n;
    const data = this._getPageData(this.activePage);
    if (data) {
      this.pageChangeData.emit({
        list: data.data,
      });
    } else {
      this.pageChange.emit({
        limit: this.pageLimit,
        offset: (this.activePage - 1) * this.pageLimit,
        activePage: this.activePage,
      });
    }
    this.currentPageChange.emit(this.activePage);
  }

  public firstPage() {
    this.setPage(this.pageStart);
  }

  public nextPage() {
    if (this._hasNext()) {
      this.setPage(this.activePage + 1);
    }
  }

  public previousPage() {
    if (this._hasPrevious()) {
      this.setPage(this.activePage - 1);
    }
  }

  public lastPage() {
    this.setPage(this.pageEnd);
  }

  public get first(): boolean {
    return this._hasPrevious();
  }

  public get previous(): boolean {
    return this._hasPrevious();
  }

  public get next(): boolean {
    return this._hasNext() && this.length > 0;
  }

  public get last(): boolean {
    return this._hasNext() && this.length > 0;
  }

  // Helpers
  public get endText() {
    return this.length > 0 ? `of ${this.pageEnd}` : '';
  }

  public setDirty() {
    this._pageArray = [];
  }

  private _hasPrevious(): boolean {
    return this.activePage > this.pageStart;
  }

  private _hasNext(): boolean {
    return this.activePage < this.pageEnd;
  }

  private _getPageData(pageNumber: number) {
    const result = this._pageArray.find((page) => page.pageNumber === pageNumber);

    return result;
  }

  private _resetPaginator() {
    this.activePage = 0;
    this.pageStart = 0;
    this.pageEnd = 0;
    this._pageArray = [];
    this._firstUpdate = true;
  }

  private _initPaginator() {
    // On first update, set the pageStart and activePage indexes to 1
    if (this._firstUpdate) {
      this.activePage = 1;
      this.pageStart = 1;
    }

    // Check to see if the inputs are valid
    // if not, take the default values
    const length = this.length && +this.length > 0 ? +this.length : LENGTH;
    const pageLength = +this.pageLimit > 0 ? +this.pageLimit : PAGE_LIMIT;

    // Check to see if page limit is specified and is correct
    this.pageEnd = Math.floor(length / pageLength);
    const rem = length % pageLength;
    if (rem !== 0) {
      this.pageEnd += 1;
    }

    if (this._firstUpdate) {
      this._firstUpdate = false;
    }
  }

  private _initPreloadImage() {
    this.imgBtnFirstEnabled.src = 'assets/images/button-first.png';
    this.imgBtnFirstDisabled.src = 'assets/images/button-first-disabled.png';
    this.imgBtnPreviousEnabled.src = 'assets/images/button-previous.png';
    this.imgBtnPreviousDisabled.src = 'assets/images/button-previous-disabled.png';
    this.imgBtnNextEnabled.src = 'assets/images/button-next.png';
    this.imgBtnNextDisabled.src = 'assets/images/button-next-disabled.png';
    this.imgBtnLastEnabled.src = 'assets/images/button-last.png';
    this.imgBtnLastDisabled.src = 'assets/images/button-last-disabled.png';
  }
}
