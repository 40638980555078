import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, mergeMap, map, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleTrackingService {
  constructor(
    private _router: Router,
    private _titleService: Title,
    private _activatedRoute: ActivatedRoute
  ) {}

  public initialize() {
    this._subscribeForTitleChanges();
  }

  /**
   * @description subscribe for route change event and update the title
   *  of the app
   */
  private _subscribeForTitleChanges(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        tap(console.log),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event: { title: string }) =>
        this._titleService.setTitle(event.title)
      );
  }
}
