import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, ActivationEnd } from '@angular/router';
import { catchError, takeUntil } from 'rxjs/operators';
import { HttpCancelRequestService } from './http-cancel-request.service';

export const INTERCEPTOR_SKIP = 'interceptorSkip';
export const INTERCEPTOR_SKIP_HEADER = new HttpHeaders({
  interceptorSkip: '',
});

@Injectable()
export class ManageHttpInterceptor implements HttpInterceptor {
  constructor(
    router: Router,
    private _httpCancelService: HttpCancelRequestService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        // route changed, cancel requests
        this._httpCancelService.cancelPendingRequest();
      }
    });
  }

  public intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (req.headers.has(INTERCEPTOR_SKIP)) {
      const headers = req.headers.delete(INTERCEPTOR_SKIP);
      return next.handle(req.clone({headers})).pipe(catchError(this._handleError.bind(this)));
    }
    return next.handle(req).pipe(takeUntil(this._httpCancelService.pendingHttpRequest$), catchError(this._handleError.bind(this)));
  }

  private _handleError(err: HttpErrorResponse): Observable<any> {
    switch (err.status) {
      case 401:
        localStorage.setItem('http-error', JSON.stringify({
          status: '401',
          url: err.url,
        }));
        this._httpCancelService.navigateToErrorPage();
        break;
      default:
        break;
    }
    return throwError(err);
  }
}
