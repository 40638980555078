import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EventBusService } from './event-bus.service';
import { INTERCEPTOR_REGEX_PATTERNS } from './token.provider';

// The below category belongs to event bus service, not category of Google Analytics category.
const GA_EVENT_CATEGORY = 'ga';
const GA_EVENT_TIMING_SUCCESS_REQUEST = 'PERFORMANCE_REQUEST';

@Injectable()
export class PerformanceTrackingInterceptorService implements HttpInterceptor {
  constructor(
    private _eventBusService: EventBusService,
    @Inject(INTERCEPTOR_REGEX_PATTERNS)
    private _regexPatterns: Record<string, RegExp[]>
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const start = performance.now();
    return next.handle(req).pipe(
      tap((res) => {
        if (
          res instanceof HttpResponse &&
          this.isUrlMatch(
            res.url,
            this._regexPatterns[GA_EVENT_TIMING_SUCCESS_REQUEST]
          )
        ) {
          const after = performance.now();
          this._sendPerformanceEvent(res.url, after, start);
        }
      })
    );
  }

  /**
   * Check if the request url matches regex
   */
  public isUrlMatch(url: string, patterns: RegExp[]): boolean {
    return patterns.some((pattern: RegExp) => pattern.test(url));
  }

  /**
   * send a performance event to event bus
   */
  private _sendPerformanceEvent(
    url: string,
    after: number,
    before: number
  ): void {
    this._eventBusService.emit({
      eventCategory: GA_EVENT_CATEGORY,
      eventName: GA_EVENT_TIMING_SUCCESS_REQUEST,
      value: {
        label: url,
        time: after - before,
      },
    });
  }
}
