import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFormat',
})
export class ArrayFormatPipe implements PipeTransform {
  public transform(array: any[], index: number, chunkSize: number = 10): any {
    if (array.length) {
      let i; let j;
      const temparray = [];
      for (i = 0, j = array.length; i < j; i += chunkSize) {
        temparray.push(array.slice(i, i + chunkSize));
      }
      return temparray[index - 1];
    } else {
      return array;
    }
  }
}
