import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { HomeLocationService } from './home-location.service';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
}) export class HomeLocationResolverService implements Resolve<any> {

  constructor(
    private _homeLocationService: HomeLocationService,
    private _storage: StorageService
  ) {}

  public resolve(): Observable<any> {
    const flag = this._storage.getStorageValue('FLAG_RESET_DIVISION');
    if (flag) {
      this._storage.setStorageValue('FLAG_RESET_DIVISION', false);
      return this._homeLocationService.getHomeLocations();
    }
  }
}
