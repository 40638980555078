<div class="pull-right ml-auto" [ngClass]="{ 'disable-interaction': isValidating[bookmarkInfo?.eventIndex] && showSpinner}">
  <ng-container *ngIf="isValidating[bookmarkInfo?.eventIndex] && showSpinner && !isUpdateIncidentBookmark">
      <app-inline-spinner class="inline-spinner"></app-inline-spinner>
  </ng-container>
  <a class="action-button action-add-button" data-toggle="modal"
      (click)="openModalCommentIncident(bookmarkInfo, $event)">
      <ng-container *ngIf="bookmarkInfo?.comments?.length <= 0, else comment">
          <img src="assets/images/actions_icon/add_comment.svg">
      </ng-container>
  </a>

  <a class="action-button action-save-button" data-toggle="modal" appThrottleClick
    (throttleClick)="updateIncidentBookmark(bookmarkInfo, $event)">
      <ng-container *ngIf="bookmarkInfo?.bookmark; else noBookmark">
        <ng-container *ngIf="!isUpdateIncidentBookmark; else bookmarkSpinner">
          <img src="assets/images/actions_icon/bookmark.svg">
        </ng-container>
      </ng-container>
  </a>
</div>

<ng-template #noBookmark>
  <ng-container *ngIf="!isUpdateIncidentBookmark; else bookmarkSpinner">
    <img src="assets/images/actions_icon/bookmark_border.svg">
  </ng-container>
</ng-template>

<ng-template #comment>
  <ng-container *ngIf="checkStatusUnreadComment(bookmarkInfo?.comments) > 0">
      <img src="assets/images/actions_icon/comment_blue.svg">
      <span class="badge">
          {{ checkStatusUnreadComment(bookmarkInfo?.comments) }}
      </span>
  </ng-container>

  <img *ngIf="checkStatusUnreadComment(bookmarkInfo?.comments) <= 0"
      src="assets/images/actions_icon/comment_black.svg">
</ng-template>

<ng-template #bookmarkSpinner>
  <app-inline-spinner class="inline-spinner-bookmark"
    [popover]="popTemplate"
    [popoverContext]="context"
    placement="bottom"
    [outsideClick]="true">
  </app-inline-spinner>
</ng-template>
