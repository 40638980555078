import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { PackageConfigComponent } from '@modules/package-config/pages/package-config/package-config.component';
import { ExceptionInfoComponent } from '@modules/exception-info/pages/exception-info/exception-info.component';
import { UnconnectedTripsComponent } from '@modules/unconnected-trips/pages/unconnected-trips/unconnected-trips.component';
// Dashboard3
import { FleetDetailsComponent } from '@modules/dashboard3/pages/fleet-details/fleet-details.component';
import { DriverDetail2Component } from '@modules/dashboard3/pages/driver-detail2/driver-detail2.component';
import { TripDetails2Component } from '@modules/dashboard3/pages/trip-details2/trip-details2.component';
import { Settings3Component } from '@modules/dashboard3/pages/settings/settings.component';
import { IncidentResponseCenterComponent } from '@modules/dashboard3/pages/incident-response-center/incident-response-center.component';
import { AssetViewComponent } from '@modules/dashboard3/pages/asset-view/asset-view.component';
import { AssetSnapshotComponent } from '@modules/dashboard3/pages/asset-snapshot/asset-snapshot.component';
import { FleetMetricsComponent } from '@modules/dashboard3/pages/fleet-details/fleet-metrics/fleet-metrics.component';
import { FlaggedIncidentsComponent } from '@modules/dashboard3/pages/flagged-incidents/flagged-incidents.component';
import { HttpErrorComponent } from '@modules/dashboard3/pages/http-error/http-error.component';

// Guards
import { AccessGuard } from '@app-core/zcw-auth-module/services/access.guard';
import { DashboardAccessGuard } from '@app-core/zcw-auth-module/services/dashboard.access.guard';
import { SmartFleetAccessGuard } from '@app-core/zcw-auth-module/services/smartfleet.access.guard';
import { AssetvalidationAccessGuard } from '@app-core/zcw-auth-module/services/asset-validation.access.guard';
import { HomeLocationResolverService } from '@app-core/services/home-location-resolver.service';

export const routes: Routes = [
  { path: '', redirectTo: 'callback', pathMatch: 'full' },

  {
    path: 'package-configuration',
    component: PackageConfigComponent,
    canActivate: [AccessGuard],
    data: { title: 'Package Config - Zonar Coach', navUseFullWidth: true },
  },
  {
    path: 'exception-info',
    component: ExceptionInfoComponent,
    canActivate: [AccessGuard],
    data: { title: 'Exception Info - Zonar Coach', navUseFullWidth: true },
  },
  {
    path: 'unconnected-trips',
    component: UnconnectedTripsComponent,
    canActivate: [AccessGuard],
    data: { title: 'Unconnected Trips - Zonar Coach', navUseFullWidth: true },
  },
  {
    path: 'dashboard',
    component: FleetDetailsComponent,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Dashboard - Zonar Coach', navDashboard: true },
    resolve: {
      homeLocations: HomeLocationResolverService,
    },
  },
  {
    path: 'incident-response-center',
    component: IncidentResponseCenterComponent,
    canActivate: [DashboardAccessGuard, SmartFleetAccessGuard],
    data: { title: 'Incident Response Center - Zonar Coach', navDashboard: true },
  },
  {
    path: 'asset-view',
    component: AssetViewComponent,
    canActivate: [DashboardAccessGuard, SmartFleetAccessGuard],
    data: { title: 'Asset View - Zonar Coach', navDashboard: true },
  },
  {
    path: 'assets/:assetId',
    component: AssetSnapshotComponent,
    canActivate: [DashboardAccessGuard, SmartFleetAccessGuard, AssetvalidationAccessGuard],
    data: { title: 'Asset Snapshot - Zonar Coach', navDashboard: true },
  },
  {
    path: 'driver-detail',
    component: DriverDetail2Component,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Driver Details - Zonar Coach', navDashboard: true },
  },
  {
    path: 'settings',
    component: Settings3Component,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Settings - Zonar Coach', navDashboard: true },
  },
  {
    path: 'fleet-trends',
    component: FleetMetricsComponent,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Fleet Trends - Zonar Coach', hideFooter: true, navDashboard: true },
  },
  {
    path: 'flagged-incidents',
    component: FlaggedIncidentsComponent,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Flagged Incidents - Zonar Coach', hideFooter: true, navDashboard: true },
  },
  {
    path: 'trip-detail',
    component: TripDetails2Component,
    canActivate: [DashboardAccessGuard],
    data: { title: 'Trip Details - Zonar Coach', hideFooter: true, navDashboard: true, navUseFullWidth: true },
  },
  {
    path: 'error',
    component: HttpErrorComponent,
    data: { title: 'Error - Zonar Coach', hideFooter: true, navDashboard: false, navHidden: true},
  },
  { path: 'callback', redirectTo: 'dashboard' },
  { path: '**',
    component: HttpErrorComponent,
    data: { title: 'Error - Zonar Coach', hideFooter: true, navDashboard: false, navHidden: true},
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
