<div class="multiselect-dropdown" (appClickOutside)="closeDropdown($event)">
  <!-- title dropdown -->
  <ng-container *ngTemplateOutlet="fieldset"></ng-container>
  <div #scrollToTop cdkScrollable class="dropdown-list scrollable" [hidden]="isLoading || !settings?.defaultOpen">
    <ul class="dropdown-list-header">
      <!-- Search item filter -->
      <ng-container *ngTemplateOutlet="searchFilter"></ng-container>
      <!-- Chips filter -->
      <ng-container *ngTemplateOutlet="chipsFilter"></ng-container>
    </ul>
    <ul class="dropdown-list-content">
      <!-- Item check all -->
      <ng-container *ngTemplateOutlet="itemCheckAll"></ng-container>

      <!-- Item check box -->
      <ng-container *ngTemplateOutlet="itemCheckboxList"></ng-container>

      <!-- Show message error -->
      <ng-container *ngTemplateOutlet="errorMessage"></ng-container>
    </ul>
  </div>
</div>

<!-- fieldset text -->
<ng-template #fieldset>
  <fieldset class="dropdown-btn" [ngClass]="{ 'selected-legend-border': settings?.defaultOpen }"
    (click)="toggleDropdown($event)">
    <ng-container *ngIf="settings?.defaultOpen; else fieldsetTitleDown">
      <legend *ngIf="settings?.defaultOpen" class="placeholder-legend">{{ settings?.dropdownPlaceholder }}</legend>
      <span class="fieldset-current-selected fieldset-title" *ngIf="settings?.defaultOpen">{{ displaySelectedItem()
        }}</span>
    </ng-container>
    <!-- button dropdown -->
    <span [ngClass]="{ 'arrow-down': !settings?.defaultOpen, 'arrow-up': settings?.defaultOpen }">
    </span>
  </fieldset>
</ng-template>

<!-- fieldset title down -->
<ng-template #fieldsetTitleDown>
  <span class="fieldset-title" *ngIf="!settings?.defaultOpen">
    <ng-container *ngIf="!isLoading; else loading">
      {{ displaySelectedItem(false) }}
    </ng-container>
  </span>
</ng-template>

<!-- loading skeleton -->
<ng-template #loading>
  <app-custom-skeleton-loader
    [theme]="{
      'width': '140px',
      'height': '10px'
    }">
  </app-custom-skeleton-loader>
</ng-template>

<!-- chips -->
<ng-template #chipsFilter>
  <div *ngIf="settings?.showChips">
    <span class="dropdown-btn">
      <span *ngIf="!isAllItemsSelected(true)">
        <span *ngFor="let item of selectedItems;let k = index" class="selected-item-container"
          [hidden]="k > (settings?.itemsShowLimit-1)">
          <span>{{item.name}}&nbsp;</span>
          <!-- chip icon, class names very important -->
          <button type="button" class="close selected-item-close-chips" aria-label="Close"
            (click)="onItemClick($event,item)">
            <span id="close-chip-icon" aria-hidden="true">&times;</span>
          </button>
        </span>
      </span>
    </span>
    <div [ngClass]="{ 'dropdown-multiselect--active': settings.defaultOpen }" class="selected-item-count">
      <span *ngIf="itemShowRemaining()>0 && !isAllItemsSelected(true)" matTooltip="{{ tooltipContent() }}">+{{itemShowRemaining()}}</span>
    </div>
  </div>
</ng-template>

<!-- search -->
<ng-template #searchFilter>
  <li class="filter-textbox" [hidden]="!settings?.showSearchFilter">
    <img class="search-icon" src="assets/images/search-input.svg">
    <input class="form-control" type="search"
      [placeholder]="settings?.isLocationFilter ? settings?.locationDropdownPlaceholder : settings?.dropdownPlaceholder"
      [(ngModel)]="filter.name"
      (ngModelChange)="onFilterTextChange($event)">
  </li>
</ng-template>

<!-- checkAll -->
<ng-template #itemCheckAll>
  <li (click)="toggleSelectAll()" *ngIf="searchTerm?.length <= 0 && settings?.enableCheckAll"
    class="multiselect-item-checkbox checkbox-all">
    <div class="border-select-all">
      <input type="checkbox" aria-label="multiselect-select-all" [checked]="isAllItemsSelected()" />
      <div>{{ settings?.selectAllText }}</div>
    </div>
  </li>
</ng-template>

<!-- check box list -->
<ng-template #itemCheckboxList>
  <li *ngFor="let item of dataSource | multiSelectFilter:filter; let i = index;" (click)="onItemClick($event,item)"
    class="multiselect-item-checkbox">
    <input type="checkbox" [attr.aria-label]="item.name" [checked]="isSelected(item)" />
    <div [innerHTML]="item.name | highlight: searchTerm">{{item.name}}</div>
  </li>
</ng-template>

<!-- show message error -->
<ng-template #errorMessage>
  <li class='no-filtered-data' *ngIf="dataSource.length !== 0 && (dataSource | multiSelectFilter:filter).length === 0">
    <div class="notify-text-no-result">{{settings?.noFilteredDataAvailablePlaceholderText}}</div>
  </li>
  <li class='no-filtered-data' *ngIf="dataSource.length === 0">
    <div>{{settings?.noDataAvailablePlaceholderText}}</div>
  </li>
</ng-template>
