import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Observable, of } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';

type TrackFunction = (_: any) => any;
type ItemType = any;

const DEFAULT_CONFIG = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  variableWidth: true,
  nextArrow: '',
  prevArrow: '',
  draggable: false,
  swipe: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 776,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export interface DataSource {
  list: ItemType[];
  totalSize: number;
}

export type GetNewPageFunc = (
  limit: number,
  skip: number
) => Observable<DataSource>;

interface AfterChangeEvent {
  event: any;
  currentSlide: number;
  slick: {
    options: {
      slidesToScroll: number;
    };
  };
}
interface OnBreakEvent {
  breakpoint: number;
}

@Component({
  selector: 'app-async-carousel',
  templateUrl: './async-carousel.component.html',
  styleUrls: ['./async-carousel.component.scss'],
})
export class AsyncCarouselComponent implements OnInit {
  @Input() public set dataSource$(value: Observable<DataSource> | DataSource) {
    const wrappedObs = (value instanceof Observable ? value : of(value));

    wrappedObs.subscribe(({ list, totalSize }) => {
      this.dataSourceInternal = list;
      this.currentListSize = list.length;
      this._indexBeforeLastSlide = this._calculateSlideIndexBeforeLastSlide();
      if (list.length < this.pageSize) {
        this.totalSize = list.length;
      } else {
        this.totalSize = totalSize;
      }
      this.modal.slickGoTo(0);
    }, error => console.error(error));
  }
  @Input() public itemTemplate: TemplateRef<any> = null;
  @Input() public skeletonTemplate: TemplateRef<any> = null;
  @Input() public noDataTemplate: TemplateRef<any> = null;
  @Input() public isFullLoading = false;
  @Input() public pageSize = 4;

  @ViewChild('slickModal', { static: true }) public modal: SlickCarouselComponent;

  public get scrollSize(): number {
    return this._scrollSize;
  }
  public set scrollSize(value: number) {
    this._scrollSize = value;
    this._indexBeforeLastSlide = this._calculateSlideIndexBeforeLastSlide();
  }

  public config = DEFAULT_CONFIG;
  public dataSourceInternal: ItemType[] = [];
  public isPartialLoading = false;

  public currentListSize = 0;
  public totalSize = 0;
  public currentSlideIndex = 0;

  private _scrollSize = 3;
  private _indexBeforeLastSlide = 0;

  constructor() {}

  @Input() public newPageGenerator: GetNewPageFunc = () => of({ list: [], totalSize: 0 });
  @Input() public trackBy: TrackFunction = (value) => value;

  public ngOnInit(): void {}

  public onButtonClick(handerFunc: () => void, target: any) {
    let obs = of({});
    const shoudLoadMore =
      this.currentSlideIndex >= this._indexBeforeLastSlide &&
      this.currentListSize < this.totalSize;

    if (shoudLoadMore && !this.isPartialLoading) {
      this.isPartialLoading = true;
      obs = obs.pipe(
        switchMap((_) => this.newPageGenerator(this.pageSize, this.currentListSize)),
        tap(({ list }: DataSource) => {
          this.isPartialLoading = false;
          this.dataSourceInternal = [...this.dataSourceInternal, ...list];
          this.currentListSize = this.dataSourceInternal.length;

          // Because light metrics do not support returning total trip number
          // so if next page is empty, we got full list
          if (list.length < this.pageSize ) {
            this.totalSize = this.currentListSize + list.length;
          }

          this._indexBeforeLastSlide =
            this._calculateSlideIndexBeforeLastSlide();
        })
      );
    }
    obs.pipe(take(1)).subscribe((_) => {});

    handerFunc.bind(target)();
  }

  public onBreak({ breakpoint }: OnBreakEvent) {
    const setting = this.config.responsive.find(
      (item) => item.breakpoint === breakpoint
    );
    if (setting) {
      this.scrollSize = setting.settings.slidesToScroll;
    } else {
      this.scrollSize = this.config.slidesToScroll;
    }
  }

  public afterChange({ currentSlide: currentSlideIndex }: AfterChangeEvent) {
    this.currentSlideIndex =
      currentSlideIndex > this.currentListSize
        ? this.currentListSize
        : currentSlideIndex;
  }

  private _calculateSlideIndexBeforeLastSlide(): number {
    const totalPage = Math.ceil(this.currentListSize / this._scrollSize);
    return (totalPage - 1) * this._scrollSize - this._scrollSize;
  }
}
