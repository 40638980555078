<div class="dropdown-wrap" appDocumentClick [width]="900" (clickedInside)="clickedInside($event)">
   <div>
       <div *ngIf="!isExportSet">
                <zui-searchable-dropdown class="duty-filter"
                    [options]="dutyTypeRangeList"
                    [searching]="false"
                    [isMultiple]="false"
                    [z_formGroup]="zuiFormGroup"
                    [selectOptionsControlName]="'currentDutyType'">
                </zui-searchable-dropdown>
       </div>
       <div class="filter-choice" *ngIf="isExportSet">
            <div class="filter-element " (click)="toggleFilter()">
                    <div class="filter-heading">Duty Type</div>
                    <div class="content-row">
                        <span class="filter-content">{{displayText}} </span>
                        <span *ngIf="isOpen" class="arrow-up"></span>
                        <span *ngIf="!isOpen" class="arrow-down"></span>
                    </div>
                </div>
       </div>
   </div>

    <div class="drop-down" [class.show]="isOpen">
        <div class="drop-down-content" data-test="duty-type-filter-content">
            <span class="heading">Duty Type</span>
            <div class="options-group">
                <button class="btn btn-option"
                    *ngFor="let dutyType of dutyTypeRangeList"
                    [class.active]="selectedDutyType === dutyType.value"
                    (click)="selectDutyType(dutyType.value)">
                    {{dutyType.title}}
                </button>
            </div>
        </div>
        <div class="dropdown-apply desktop">
        </div>
    </div>
</div>
