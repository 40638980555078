<!-- table and media control -->
<app-two-columns-layout>
  <div first-column>
    <app-table
      [displayedColumns]="displayedColumns"
      [colDescriptions]="colDescriptions"
      [customTemplates]="customTemplatesObj"
      [sortHeader]="sortHeader"
      [isDisabledClick]="isDisabledAction"
      [dataSource$]="internalDataSource$"
      [notLoadingState$]="notLoadingState$"
      [loadingDummyData]="dummyLoadData"
      [sortOrderChange$]="sortOrderChange$"
      [disableRowClickExpand]="disableRowExpand"
      [getUniqueKey]="getUniqueKey"
      (pageChange)="updatePage($event)"
      (sortChange)="sortChangeInternal($event)"
      (rowSelected)="setMedia($event)"
    >
    </app-table>
  </div>
  <div second-column>
    <app-incident-media-control
      [incidentData]="incidentData"
      [noMediaText]="noMediaText"
      [selectedRowData]="selectedIncident"
      [showSmallSizeMediaPanel]="showSmallSizeMediaPanel"
      [notLoadingState$]="notLoadingState$"
      [switch]="switch"
      (challengeUpdated)="updateIncidentList($event)"
      (isDisabledClick)="isDisabledClick($event)"
    >
    </app-incident-media-control>
  </div>
</app-two-columns-layout>

<!-- hyperlink driver go to driver page when clicked -->
<ng-template #hyperlinkDriver let-asyncData let-element="element">
  <ng-container *ngIf="asyncData | async as data; else loadingDriverName">
    <a
      class="hyperlink-text"
      [routerLink]="['/driver-detail']"
      [queryParams]="{
        driverId: element.driverId,
        driverName: setDriverName(data)
      }"
      style="text-decoration: underline; color: #0075b4"
    >
      {{ data }}
    </a>
  </ng-container>
  <ng-template #loadingDriverName>
    <div style="padding-right: 10px;">
      <app-custom-skeleton-loader
      [theme]="{
        width: '100px',
        height: '20px'
      }">
    </app-custom-skeleton-loader>
    </div>
  </ng-template>
</ng-template>

<!-- hyperlink asset go to asset page when clicked -->
<ng-template #hyperlinkAssetName let-asyncData let-element="element">
  <ng-container *ngIf="asyncData | async as data; else loadingAssetName">
    <ng-container *ngIf="element.assetId; else loading">
      <a
        class="hyperlink-text"
        [routerLink]="['/assets', element.assetId]"
        style="text-decoration: underline; color: #0075b4"
      >
        {{ data }}
      </a>
    </ng-container>
  </ng-container>
  <ng-template #loadingAssetName>
    <div style="padding-right: 10px;">
      <app-custom-skeleton-loader
      [theme]="{
        width: '152px',
        height: '20px'
      }">
    </app-custom-skeleton-loader>
    </div>
  </ng-template>
</ng-template>

