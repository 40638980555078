import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { FeatureFlagService } from '@modules/feature-flag/services/feature-flag.service';

@Directive({
  selector: '[appCheckBetaFeature]',
})
export class CheckBetaFeatureDirective implements OnInit, OnDestroy {
  @Input()
  public appCheckBetaFeature: string;

  private _onDestroy$ = new ReplaySubject();
  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _featureFlagService: FeatureFlagService
  ) {}

  public ngOnInit(): void {
    this._featureFlagService.isFlagEnabled(this.appCheckBetaFeature)
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe(res => {
        if (res) {
          this._renderer.addClass(this._elementRef.nativeElement, 'beta-graphic-version');
        }
      });
  }

  public ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
