import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from '@env/environment';

export const zcwAuthenticateConfig: AuthConfig = {
  issuer: `https://${environment.auth.domain}/`,
  clientId: environment.auth.clientID,
  responseType: 'code',
  redirectUri: environment.auth.redirect,
  scope: environment.auth.scope,
  logoutUrl: environment.logout_url,
  customQueryParams: {
    audience: environment.auth.audience,
  },
};
