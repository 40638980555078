// import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Uploader, UploaderService, UploadItem } from '@trendster-io/ng-uploader';

import {
  API,
} from '../../../dashboard3.models';
import { BsModalRef } from 'ngx-bootstrap';
import { TrackNetworkStatusService } from '@app-core/services/track-network-status.service';
import { ExcelService } from '@app-core/services/excel.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AddImeiEventResponse } from '../setting-upload-camera-by-id/setting-upload-camera-by-id.component';

export interface UploadResponse {
  item: UploadItem;
  res: any;
  message: string;
}

export interface UploadEvent {
  item: UploadItem;
  res?: any;
  err?: any;
  progress: number;
}

interface IMEIResponse {
  deviceId?: any;
  err: string;
  imei: string;
}

const MICROSOFT_EXCEL_MIME_TYPE = [
  'application/vnd.ms-excel', // excel 2003-2007 format
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // newer excel 2007 and later
  'text/csv', // csv file format
];

const ERROR_NO_DATA_REPORT = 'Error no data while saving report';
const NO_COMPANY_FOUND = 'No company found with the provided search parameters.';
const IMEI_INVALID = 'Some of the imei is invalid';
const NO_NETWORK_CONNECTION_MSG = 'Network is unavailable, please try again';
const UNABLED_READ_EXCEL_FILE = 'Uploaded file is not a valid Excel file';
const UNABLED_READ_CSV_FILE = 'Uploaded file is not a valid CSV file.';
const MAX_CAM_PER_FILE = 'Max camera per file exceeded';
@Component({
  selector: 'app-setting-upload-file',
  templateUrl: './setting-upload-camera-from-file.component.html',
  styleUrls: ['./setting-upload-camera-from-file.component.scss'],
})
export class SettingUploadCameraFromFileComponent implements OnInit, OnDestroy {
  public uploader: Uploader;
  public errorFiles: File[] = [];
  public errorIMEIList: IMEIResponse[] = [];
  public successResponse: UploadResponse;
  public errorResponse: UploadResponse;
  public fileErrorDetected: boolean;
  public uploadFileErrorState = undefined;
  public isUploading = false;
  public isSomeImeiValid = false;
  public isUploadBulkReload: Subject<boolean> = new Subject<boolean>();

  public get isSaveBtnDisabled() {
    return this.uploader.notUploadedItemsCount <= 0 || this.isUploadAvailable() || this.isUploading;
  }

  public get isSaveBtnEnabled() {
    return this.uploader.notUploadedItemsCount > 0 && this.isUploadCompleted() && !this.isUploading;
  }

  private _method: 'POST' | 'PUT' | 'PATCH';
  private _autoUpload: boolean;
  private _removeAfterUpload: boolean;
  private _queueLimit: number;
  private _concurrency: number;
  private _maxFileSize: number;
  private _allowedMimeTypes: string[];
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    // private _sanitizer: DomSanitizer,
    private _uploaderService: UploaderService,
    private _trackNetworkService: TrackNetworkStatusService,
    private _excelService: ExcelService,
    public bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this._method = 'POST';
    this._autoUpload = false; // Don't upload the item automatically when I add it to the queue
    this._removeAfterUpload = false; // Remove items from the queue after upload success
    this._queueLimit = 1; // Queue cannot contain more than 5 items
    this._concurrency = 1; // 1 upload at a time
    this._maxFileSize = 50 * 1024 * 1024; // 50MB
    this._allowedMimeTypes = MICROSOFT_EXCEL_MIME_TYPE;
    this._initUploader();
  }

  public ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this._destroy$.next();
    // This completes the subject properly
    this._destroy$.complete();
  }

  public onFileUploadSuccess(uploadEvent: UploadEvent) {
    this.isUploading = false;
    this.errorIMEIList = this._getErrorIMEIList(uploadEvent && uploadEvent.res);

    if (uploadEvent && this.errorIMEIList.length === 0) {
      this.successResponse = {
        item: uploadEvent.item,
        res: uploadEvent.res,
        message: 'Successfully upload file.',
      };
      this.isUploadBulkReload.next(true);
    } else if (this.errorIMEIList.length) {
      this.isSomeImeiValid = this.errorIMEIList.length < uploadEvent.res.length;
      uploadEvent.err = {
        error: {
          message: IMEI_INVALID,
        },
      };
      this.onFileUploadError(uploadEvent);
    }
  }

  public onFileUploadError(uploadEvent: UploadEvent) {
    const isOnline = this._trackNetworkService.isOnline;
    this.isUploading = false;
    if (uploadEvent) {
      this.errorResponse = {
        item: uploadEvent.item,
        res: uploadEvent.err,
        message: isOnline ? uploadEvent.err.error.message : NO_NETWORK_CONNECTION_MSG,
      };
      const errorMsg = this.errorResponse.message;
      console.log('Import file error', errorMsg);

      this.errorResponse.message = errorMsg.indexOf(NO_COMPANY_FOUND) === -1 ?
        errorMsg : NO_COMPANY_FOUND;

      switch (this.errorResponse.message) {
        case IMEI_INVALID:
          this.uploadFileErrorState = 'invalidIMEI';
          break;
        case UNABLED_READ_EXCEL_FILE:
        case UNABLED_READ_CSV_FILE:
          this.uploadFileErrorState = 'unableReadFileError';
          break;
        case NO_COMPANY_FOUND:
          this.uploadFileErrorState = 'noCompanyFoundError';
          break;
        case NO_NETWORK_CONNECTION_MSG:
          this.uploadFileErrorState = 'noNetworkError';
          break;
        case MAX_CAM_PER_FILE:
          this.uploadFileErrorState = 'exceedLimit';
          break;
        default:
          break;
      };

      const response = uploadEvent.res as AddImeiEventResponse[];
      if (response) {
        const failedList: AddImeiEventResponse[] = [];
        response.forEach(element => {
          if (!element.deviceId && element.err) {
            failedList.push(element);
          }
        });

        if (failedList.length === 0 || failedList.length < response.length) {
          this.isUploadBulkReload.next(true);
        } else {
          this.isUploadBulkReload.next(false);
        }
      } else {
        this.isUploadBulkReload.next(false);
      }
    }
  }

  public clearMessage() {
    this.successResponse = null;
    this.errorResponse = null;
    this.fileErrorDetected = false;
    this.errorFiles = [];
    this.uploadFileErrorState = undefined;
    this.isSomeImeiValid = false;
    this.errorIMEIList = [];
  }

  public onOptionsChange() {
    this._initUploader();
  }

  public onFilesDrop(files: File[]) {
    files.forEach(file => {
      try {
        let fileAlias: 'csvFile' | 'excelFile';
        const extension = file.name.slice(file.name.lastIndexOf('.'));
        if (extension === '.csv') {
          fileAlias = 'csvFile';
        } else {
          fileAlias = 'excelFile';
        }
        this.uploader.clearQueue();
        this.uploader.addItem({
          file,
          fileAlias,
        });
        this.clearMessage();
      } catch (err) {

        this.fileErrorDetected = true;
        this.errorFiles.push(file);

        if (err.name === 'NotAllowedFileType') {
          this.uploadFileErrorState = 'fileTypeError';
        } else if (err.name === 'FileSizeExceeded') {
          this.uploadFileErrorState = 'fileSizeExceeded';
        }  else {
          // TO-DO: Handle other error types
          console.log('other error');
        }
      }
    });
  }

  public onFilesChange(fileList: FileList): void {
    this.onFilesDrop(Array.from(fileList));
  }

  public truncateFileName(fileName: string, maximumLength: number): string {
    if (fileName && maximumLength && !isNaN(maximumLength) && maximumLength > 0 && fileName.length > maximumLength) {
      return fileName.slice(0, maximumLength) + '..';
    }
    return fileName;
  }

  public calculateUploadedSize(item: any): number {
    if (item && item.file && item.file.size
        && item.progress && item.progress >= 0 && item.progress <= 100) {
      const totalSize = item.file.size;
      const progress = item.progress;
      const value = totalSize * progress / 100;
      return isNaN(value) ? 0 : value;
    }
    return 0;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  // public getDataUri(obj: object) {
  //   return this._sanitizer.bypassSecurityTrustUrl('data:text/plain;base64,' +
  //                                                btoa(JSON.stringify(obj)));
  // }

  public downloadTheResult() {
    this.formatDownloadResultData(this.errorIMEIList);
  }

  public downloadTemplateFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/files/ImeiUploadTemplate.xls');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  public isUploadCompleted = () => !this.successResponse && !this.errorResponse && !this.fileErrorDetected;

  public isUploadAvailable = () => this.successResponse || this.errorResponse || this.fileErrorDetected;

  public uploadFile() {
    if (this.isSaveBtnEnabled) {
      this.isUploading = true;
      this.uploader.uploadAll();
    }
  }

  public formatDownloadResultData(data: IMEIResponse[]) {
    if (data.length === 0) {
      console.log(ERROR_NO_DATA_REPORT);
      return;
    }
    const imeiErrorList = data.map(item => ({
      IMEI: item.imei,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Error: item.err,
    }));

    try {
      const filename = 'download_result';
      const headers = [
        {},
      ];
      const utils = this._excelService.getSheetToJson(headers, {
        header: [],
        skipHeader: false,
      });
      const sheet = this._excelService.sheetAddJson(utils, imeiErrorList, {
        origin: 'A1',
      });

      this._excelService.convertToCSV(sheet, filename);

    } catch (error) {
      console.log('Error while saving report', error);
    }
  }

  private _initUploader(): void {
    this.uploader = this._uploaderService.getUploader({
      url: API.UPLOAD_CAMERA_FROM_FILE,
      method: this._method,
      autoUpload: this._autoUpload,
      removeAfterUpload: this._removeAfterUpload,
      queueLimit: this._queueLimit,
      concurrency: this._concurrency,
      maxFileSize: this._maxFileSize,
      allowedMimeTypes: this._allowedMimeTypes,
    });

    this.uploader.onItemSuccess.pipe(
      takeUntil(this._destroy$)
    ).subscribe(this.onFileUploadSuccess.bind(this));

    this.uploader.onItemError.pipe(
      takeUntil(this._destroy$)
    ).subscribe(this.onFileUploadError.bind(this));
  }

  private _getErrorIMEIList(res: any[]) {
    const errorIMEIList = [];
    if (Array.isArray(res) && res.length) {
      res.forEach(item => {
        if (item.err !== null) {
          errorIMEIList.push(item);
        }
      });
    }
    return errorIMEIList;
  }

}
