import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-duration-dropdown',
  templateUrl: './duration-dropdown.component.html',
  styleUrls: ['./duration-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DurationDropdownComponent,
    },
  ],
})
export class DurationDropdownComponent implements OnInit, ControlValueAccessor {

  @Input() public set tripLengthInMin(value: number) {
    if (value === 0) {
      this.setDisabledState(true);
    } else if (value < this.selectOptions.length) {
      for (let i = 0; i < this.selectOptions.length; i++ ) {
        if (i + 1 > value) {
          this.selectOptions[i].disabled = true;
        } else {
          this.selectOptions[i].disabled = false;
        }
      }
    }
  }

  public durationValue = 1;
  public disableDropDown = false;

  public selectOptions = [
    {value: 1, viewValue: '1 minute', disabled: false},
    {value: 2, viewValue: '2 minutes', disabled: false},
    {value: 3, viewValue: '3 minutes', disabled: false},
    {value: 4, viewValue: '4 minutes', disabled: false},
    {value: 5, viewValue: '5 minutes', disabled: false},
  ];

  constructor() { }

  public onChange = (_item: any) => {};

  public onTouch = (_item: any) => {};

  public writeValue(value: number): void {
    if (value > 0 && value <= this.selectOptions.length) {
      this.durationValue = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disableDropDown = isDisabled;
  }

  public ngOnInit(): void {
    this.onChange(this.selectOptions[0].value);
  }

  public notifySelectionChanged(selectedItem: any) {
    this.onChange(selectedItem.value);
  }

}
