import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import { environment } from '@env/environment';

// App modules
import { CoreModule } from '@app-core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { PackageConfigModule } from '@modules/package-config/package-config.module';
import { ExceptionInfoModule } from '@modules/exception-info/exception-info.module';
import { UnconnectedTripsModule } from '@modules/unconnected-trips/unconnected-trips.module';
import { Dashboard3Module } from '@modules/dashboard3/dashboard3.module';
import { SharedModule } from '@modules/shared/shared.module';

// Other modules
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ZonarUICoreModule } from '@zonar-ui/core';

// App component
import { AppComponent } from './app.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    BrowserAnimationsModule,
    PackageConfigModule,
    ExceptionInfoModule,
    UnconnectedTripsModule,
    Dashboard3Module,
    SharedModule,
    // App routing must come before any Zonar UI modules
    // or else some routing from these module will override our routing
    // which leads to the route of '' has some error
    AppRoutingModule,
    ZonarUICoreModule,
    LeafletModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    ToastrModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    PopoverModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.intermediate_url],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
