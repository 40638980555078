import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { EventBusToGaWiringService } from '@app-core/services/event-bus-to-ga-wire.service';
import { TitleTrackingService } from '@app-core/services/title-tracking.service';
import { NavigationTracking } from '@app-core/services/navigation-tracking.service';
import { PENDO_OBJECT } from '@app-core/services/token.provider';
import { StorageService } from '@app-core/services/storage.service';
import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';
import { UserProfile } from '@app-core/zcw-auth-module/services/zcw-authenticate.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _sub: Subscription;

  constructor(
    private _storage: StorageService,
    private _zcwAuthService: ZcwAuthenticateService,
    private _titleService: TitleTrackingService,
    private _navigationTracking: NavigationTracking,
    private _wiringService: EventBusToGaWiringService,
    @Optional() @Inject(PENDO_OBJECT) private _pendoObject: any
  ) {}

  public ngOnInit() {
    this._wiringService.initialize();
    this._titleService.initialize();
    this._navigationTracking.initialize();
    this._zcwAuthService.userProfile$.pipe(take(1)).subscribe((profile) => {
      this._initializePendoService(this._pendoObject, profile);
    });
  }

  public ngOnDestroy() {
    this._sub.unsubscribe();
  }

  /**
   * @description: Initialize pendo service if possible
   */
  private _initializePendoService(
    pendoObject: any,
    profile: UserProfile
  ): void {
    if (!pendoObject) {
      console.error(
        'Error initializing pendo service: the service object is unavailable'
      );
      return;
    }

    const email = profile.email;
    const accountCode = this._storage.getStorageValue('state');
    if (email && accountCode) {
      pendoObject.initialize({
        visitor: { id: email },
        account: { id: accountCode },
      });
    } else {
      console.error(
        'Cannot find email or account code: ',
        JSON.stringify(profile)
      );
    }
  }
}
