<div class="snackbar-content-wrapper">
  <div class="message-content">
    <div class="title">{{data.title}}</div>
    <div class="content" [class.no-title]="!data.title">
      <a class="link" *ngIf="data.linkMsg" (click)="execLinkAction()">{{data.linkMsg + ' '}}</a>
      <span>{{data.textMsg}}</span>
    </div>
  </div>
  <div class="action-content" *ngIf="data.action">
    <span (click)="closeSnackbar()">{{data.action}}</span>
  </div>
</div>
