/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { cloneDeep } from 'lodash-es';
import moment from 'moment-timezone';
import tzlookup from 'tz-lookup';
import { EVENTS_INCIDENT_TYPE } from '@app-core/constants/constants';

interface LocationData {
  latitude?: number;
  longitude?: number;
  timezoneOffset?: number;
  displayTimeZone?: string | number;
  startTime?: string;
  timestamp?: string;
  momentTime?: any;
  displayTime?: string;
}

export interface IncidentTypeListCount {
  Cornering?: number;
  'Distracted-Driving'?: number;
  'Harsh-Acceleration'?: number;
  'Harsh-Braking'?: number;
  'Lane-Drift-Found'?: number;
  'Tail-Gating-Detected'?: number;
  'Traffic-STOP-Sign-Violated'?: number;
  'Traffic-Speed-Violated'?: number;
  total?: number;
}

const SECOND_PER_MINUTE = 60;

/**
 * @description: function update timezone for trip details data
 * @param: It will takes trips as params
 * @returns: it will return trip details updated time zone
 */
export function updateTimezoneData(data: LocationData): LocationData {
  const result: LocationData = cloneDeep(data);
  const { latitude: lat, longitude: long } = data;
  result.displayTimeZone = calculateTimezone(
    data.startTime || data.timestamp,
    data.timezoneOffset,
    lat,
    long
  );
  return result;
}

/**
 * @description: function to calculate timezone
 * @param: time, timezoneOffset, lat, long
 * @returns: it will return timezone value
 */
export function calculateTimezone(
  time: string,
  timezoneOffset: number,
  lat: number,
  long: number
): string | number {
  let timezoneValue: string | number = '';
  try {
    timezoneValue = moment.tz(time, tzlookup(lat, long)).format('z');
  } catch (err) {
    if (timezoneOffset === undefined) {
      return '';
    }
    timezoneValue = Number(timezoneOffset / SECOND_PER_MINUTE);
  }
  return timezoneValue;
}

/**
 * @description: function to return the string represent timezone based on lat + long or offset
 * @param: timezoneOffset, lat, long
 * @returns: string value of timezone
 */
export function getTimezoneString(
  timezoneOffset: number,
  lat: number,
  long: number
): string {
  let timezone = 'Etc/GMT';
  try {
    timezone = tzlookup(lat, long);
  } catch (err) {
    /**
     * Etc/GMT is in reverse order from normal timezone presentation, for example
     * Etc/GMT+1 = -01:00
     * Etc/GMT-7 = +07:00
     */
    const offset = timezoneOffset ? timezoneOffset : 0;
    const offsetByHour = offset / SECOND_PER_MINUTE;
    const gmtPart = 'Etc/GMT';
    const sign = offsetByHour > 0 ? '-' : '+';
    timezone = gmtPart + sign + offsetByHour;
  }

  return timezone;
}

/**
 * @description: create moment object from time and timezone
 * @param: time, timezone
 * @returns: moment object
 */
export function createMomentObject(time: string, timezone: string): any {
  return moment.tz(time, timezone);
}

/**
 * @description: create moment object from UTC time
 * @param: time
 * @returns: moment object
 */
export function createMomentObjectUtc(time: string): moment.Moment {
  return moment.tz(time, 'UTC');
}

/**
 * @description: Calculate total incident types in EVENTS_INCIDENT_TYPE
 * @param: Object event incident
 * @returns: Sum total incident
 */
export function calcSumTotalIncidents(eventObj: Record<string, string>): number{
  let sum = 0;
  const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);
  for ( const event in eventObj ) {
    if (eventTypeAllowedList.includes(event) && eventObj.hasOwnProperty(event)) {
      sum += parseInt(eventObj[event], 10);
    }
  }
  return sum;
}

/**
 * @description: Format response data of incident of asset aggregate
 * @param: Object event incident
 * @returns: Format filtered data
 */
export function filterAssetEventCount(data: Record<string, number>): IncidentTypeListCount {
  const eventTypeAllowedList = EVENTS_INCIDENT_TYPE.map((evt) => evt[0]);
  const reducedData: IncidentTypeListCount = Object.keys(data)
    .filter((key) => eventTypeAllowedList.includes(key) || key === 'total')
    .reduce((cur, key) => Object.assign(cur, { [key]: data[key] }), {});
  reducedData.total = 0;
  Object.keys(reducedData).forEach(eventKey => {
    if (eventKey !== 'total') {
      reducedData.total += reducedData[eventKey];
    }
  });
  return reducedData;
}

/**
 * Return a unique list along with indexes for each unique item.
 * For example:
 *  Input: [ ["my tring", 1], ["my other string", 2], ["my string", 3] ]
 *  Output: [ ["my string", [1, 3]], ["my other string", [2]] ]
 */
export function makeUniqueWithIndex<T, R>(
  source: [T, R][],
  checkUniqueFn: (ele: T, ele2: T) => boolean
): [T, R[]][] {
  return source.reduce<[T, R[]][]>((acc, value) => {
    const duplicatedElement = acc.find((ele) =>
      checkUniqueFn(ele[0], value[0])
    );
    if (duplicatedElement) {
      duplicatedElement[1].push(value[1]);
      return acc;
    } else {
      return [...acc, [value[0], [value[1]]]];
    }
  }, []);
}
