<div class="row tripeboard-heading">
  <div class="col-md-12 p-0">
      <div [ngSwitch]="showSortList">
          <span class="heading-left" data-test="trip-recap-header">Trips Driven</span>
          <div *ngSwitchCase="'enable'" class= "selectedlist" (click)="showTripsDrivenSortList()">
              <ng-container *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonSortLoading">
                {{ sortListBy }}  <span class="arrow-down" ></span>
              </ng-container>
          </div>
          <div *ngSwitchCase="'disable'" class= "selectedlist disabled-selectedList">
            <ng-container *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonSortLoading">
                {{ sortListBy }}  <span class="arrow-down" ></span>
            </ng-container>
          </div>
          <div class="show-list" *ngIf="showList"  >
              <div class="category {{sortListBy === 'Most Recent' ? 'active-list' : ''}}"  (click)="selectTrips('true')" >
              Most Recent
              </div>
              <div class="category {{sortListBy === 'Least Recent' ? 'active-list' : ''}}" (click)="selectTrips('false')">
              Least Recent
              </div>
          </div>
          <div class="btn-group" role="group" aria-label="Basic example" (click)="toggleTable()" *ngIf="tripDetails.length > 0, else showDisabledButton">
            <span class="label" >Table View</span>
          </div>
          <ng-template #showDisabledButton>
            <ng-container *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonViewTableLoading">
              <div class="btn-group disabledButton" role="group" aria-label="Basic example">
                  <span class="label" >Table View</span>
              </div>
            </ng-container>
          </ng-template>
      </div>
  </div>
</div>

<div *ngIf="(!isShowDummySkeleton && tripDetails.length >= 0 && show !== 'error'), else showSkeletonLoading">
  <div *ngIf="tripDetails.length > 0, else showNoDataAvailable">
      <ng-container *ngTemplateOutlet=" displaySkeletonAsync; context: {values: tripDetails}"></ng-container>
  </div>
</div>
<ng-template #showSkeletonLoading>
  <ng-container *ngTemplateOutlet=" displaySkeletonAsync; context: {values: dummyTripDetails}"></ng-container>
</ng-template>
<ng-template #showNoDataAvailable>
  <!-- empty -->
  <div class="trip-recaps-no-data-block">
      <div class="trip-recaps-no-data-content">
          <div class="no-data-available-message">
              <span>
                  No data available
              </span>
              <p>
                  <br>There are no driver trips for this time period. <br>Please modify your search and try again.
              </p>
          </div>
      </div>
  </div>
</ng-template>


<!-- Contains content that shows error when api call fails -->
<div *ngIf="tripDetails.length <= 0 && show === 'error'">
  <div class="row tripeboard-heading">
      <div class="col-md-12 p-0">
          <span class="heading-left" data-test="trip-recap-header">Trips Driven</span>
      </div>
  </div>
  <app-card>
      <div card-error class="error-content">
          <app-error-message [errorMessageChange$]="errorMessage$" [showRetryButton]="showRetryButton" (tryAgainAction)="refreshList(false)"></app-error-message>
      </div>
  </app-card>
</div>

<app-common-table-modal [totalTrips]="totalTrips" [allAssets]="allAssetsList" [filterOptions] = "filterOptions" [driverId]="driverIds[0]"></app-common-table-modal>

<ng-template #displaySkeletonAsync let-values="values">
  <div class="tripeboard-content" data-test="trip-recap-cards">
      <div class="row tripeboard">
          <div class=" pos-r" *ngIf="prevarrow && tripDetails.length > 0">
              <div class="carousel-controls testimonial-carousel-controls">
                      <button class="control prev"  (click)="prev()">
                          <img class="chevron-icon" src="assets/images/left-chevron.png" alt="">
                      </button>
              </div>
          </div>

          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (beforeChange)="afterChange($event)">
              <div ngxSlickItem class="slide" [class.tripeboard-card]="(!isShowDummySkeleton && !isShowSkeleton)" *ngFor="let tripe of values; index as i">
                  <ng-container *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonTripDrivenLoading">
                    <div class="tripeboard-card-head" [ngClass]="{
                        'barberpole-border': tripe.ongoing
                    }">
                        <app-map mapId="eventMap_{{i}}" [ongoing]="tripe.ongoing" [mapheight]="!tripe.ongoing ? 200: 192"
                            [markerList]="tripe?.markerList" [latlonList]="tripe?.pathList" *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonAppMapLoading"></app-map>
                    </div>
                    <div class="tripeboard-card-body">
                        <div class="rows full-width">
                            <div class="tripename pt0" *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonDriverNameLoading">
                                {{tripe?.driverName}}'s {{getGreetingText(tripe?.startTime)}} Trip
                            </div>
                            <div *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonStartEndPointLoading">
                                <div class="tripedriver_name pt0" *ngIf="!tripe.ongoing, else ongoingTrip">
                                    {{tripe?.startPoint}} to {{tripe?.endPoint}}
                                </div>
                            </div>
                            <div *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonNumberIncidentsLoading">
                                <div *ngIf ="tripe.cameraConnected && !tripe.ongoing; else tamperedBlock" class="pt0">
                                    <button class="tripebtn-group-incd">{{totalIncidentsEventType(tripe?.eventCount)}}
                                        <span *ngIf="totalIncidentsEventType(tripe?.eventCount) > 1">Incidents</span>
                                        <span *ngIf="totalIncidentsEventType(tripe?.eventCount) <= 1">Incident</span>
                                    </button>
                                    <button class="tripebtn-group" [style.background]="tripe?.incident_type.color" *ngIf="tripe?.eventCount.total > 0">{{tripe?.incident_type.name}}</button>
                                </div>
                            </div>
                            <ng-template #tamperedBlock>
                                <div *ngIf="!tripe.ongoing" class="tampered">
                                        <span>
                                            <img class="dangerSign2" src="../../../../../../../assets/images/exclamation-red-circle.svg">
                                        </span>
                                        <span class="tamp-text">Camera Not Connected</span>
                                </div>
                            </ng-template>
                            <ng-template #ongoingTrip>
                                <div class="tripedriver_name ongoing-trip pt0">
                                    Vehicle in transit
                                </div>
                                <div class="tampered">
                                    <br/>
                                </div>
                            </ng-template>

                            <div class="tripe_details pt0" *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonTripeDetailsLoading">
                                <table style="width:100%" class="incident_table">

                                    <tr>
                                        <td class="tdnameleft">
                                            Start Date/Time
                                        </td>
                                        <!-- ZCW-729: display timezone in driver detail page with the new algorithm -->
                                        <td class="tdnameright">{{tripe?.startTimeMoment | momentFormat:'MM/DD/YYYY HH:mm z'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="tdnameleft">
                                                Duration
                                        </td>
                                        <td class="tdnameright">{{tripe?.duration |
                                            duration2:'seconds':'h:m'
                                            }}</td>
                                    </tr>
                                    <tr>
                                        <td class="tdnameleft">
                                                Distance
                                        </td>
                                        <td class="tdnameright">{{tripe?.tripDistance | distance: 'miles'
                                            |number:
                                            '1.0-0'}} mi</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="tripeboard-card-footer">
                                <div class="view-detail-skeleton enable-button" *ngIf="(!isShowDummySkeleton && !isShowSkeleton), else skeletonViewDetailLoading">
                                    <span (click)="gotoTripDetail(tripe)">View Trip</span>
                                </div>
                            </div>
                        </div>
                    </div>
                  </ng-container>
              </div>
          </ngx-slick-carousel>

          <div class=" pos-r" *ngIf="nextarrow && tripDetails.length > 0">
              <div class="carousel-controls testimonial-carousel-controls">
                  <button class="control next" (click)="next()">
                      <img class="chevron-icon" src="assets/images/right-chevron.png" alt="">
                  </button>
              </div>
          </div>
      </div>
  </div>
</ng-template>

<ng-template #skeletonAppMapLoading>
  <app-custom-skeleton-loader
      [theme]="{
          width: '100%',
          height: '182px',
          'border-radius': '0'
      }">
  </app-custom-skeleton-loader>
</ng-template>

<ng-template #skeletonDriverNameLoading>
  <div class="driver-name-skeleton">
      <app-custom-skeleton-loader
          [theme]="{
              width: '151px',
              height: '20px'
          }">
      </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template #skeletonStartEndPointLoading>
  <div class="tripedriver_name">
      <app-custom-skeleton-loader
          [theme]="{
              width: '151px',
              height: '20px',
              'margin-top': '5px'
          }">
      </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template  #skeletonTripeDetailsLoading>
  <div class="tripe-details-skeleton">
      <app-custom-skeleton-loader
          [theme]="{
              width: '100%',
              height: '142px'
          }">
      </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template #skeletonNumberIncidentsLoading>
  <div class="number-incident-skeleton">
      <app-custom-skeleton-loader
          [theme]="{
              width: '100px',
              height: '24px'
          }">
      </app-custom-skeleton-loader>
      <app-custom-skeleton-loader
              [theme]="{
                  width: '75px',
                  height: '24px',
                  'margin-left': '8px'
              }">
          </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template #skeletonViewDetailLoading>
  <div class="view-detail-skeleton">
      <span>View Trip</span>
  </div>
</ng-template>

<ng-template #skeletonTripDrivenLoading>
  <div class="trip-driven-skeleton">
    <app-custom-skeleton-loader
        [theme]="{
          width: '318px',
          height: '530px',
          margin: '0px 12px'
        }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template #skeletonSortLoading>
  <div class="skeleton-sort-loading">
    <app-custom-skeleton-loader
        [theme]="{
          width: '140px',
          height: '20px'
        }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>

<ng-template #skeletonViewTableLoading>
  <div class="skeleton-table-view-btn">
    <app-custom-skeleton-loader
        [theme]="{
          width: '120px',
          height: '36px',
          float: 'right'
        }">
    </app-custom-skeleton-loader>
  </div>
</ng-template>
