import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@modules/shared/shared.module';
import { Dashboard3Module } from '@modules/dashboard3/dashboard3.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { ExceptionInfoService } from '@modules/exception-info/services/exception-info.service';

import { ExceptionInfoComponent } from './pages/exception-info/exception-info.component';
import { ExceptionListComponent } from './components/exception-list/exception-list.component';

@NgModule({
  imports: [CommonModule, SharedModule, FormsModule, BsDatepickerModule, Dashboard3Module],
  declarations: [ExceptionInfoComponent, ExceptionListComponent],
  providers: [ExceptionInfoService],
})
export class ExceptionInfoModule {}
