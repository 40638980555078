import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface FlagData {
  key: string;
  flag: boolean;
}

@Injectable()
export class FlagService {
  public currentClearFlag: Observable<FlagData>;
  private _isClearFlag$ = new Subject<FlagData>();
  constructor() {
    this.currentClearFlag = this._isClearFlag$.asObservable();
  }

  public changeFlag(key: string, flag: boolean) {
    this._isClearFlag$.next({key, flag});
  }
}
