import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ACCESS_DOMAINS_WHITELIST } from '@app-core/constants/constants';
import { OZA8331_EMAIL_WHITELIST, OZA8331_ACCOUNT_CODE } from '@app-core/constants/constants';
import { ZcwAuthenticateService } from '@app-core/zcw-auth-module/services/zcw-authenticate.service';

interface AccountInfo {
    states: string[];
    whitelist: string[];
    isDefault: boolean;
}

type AccountCodes = 'OZA';

const ACCOUNT_INFO_MAPPER: Record<AccountCodes, AccountInfo> = {
  OZA: {
    states: OZA8331_ACCOUNT_CODE,
    whitelist: OZA8331_EMAIL_WHITELIST,
    isDefault: false,
  },
};

const DEFAULT_EDIT_PERMISSION = true;

// using accessService to validate the user
@Injectable()
export class AccessService {

    private _zonarRegEx = '^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(zonarsystems)\.com$';

    constructor(
        private _zcwAuthService: ZcwAuthenticateService
    ) {}

    /**
     * @description: Check if the current user has access to the application
     * @return: Observable of true when having access to the application otherwise return observable of false
     */
    public get canAccess(): Observable<boolean> {
      return this._getStateAndEmail().pipe(
        map(res => {
          const { state, email } = res;
          return this._canAccess(state, email);
        })
      );

    }

    /**
     * @description: Check has permission on resource with operator
     * @param: resource the reosource you want to check permission on,
     * @param: operator define the permission action on the resource
     * @return: Promise of true when has permission by page with operator otherwise return promise of false
     */
    public hasPermission(resource: string, op: 'C' | 'R' | 'U' | 'D'): Promise<boolean> {
      if (resource === 'setting' && op === 'U') {
        return this.canEditSettingPage.toPromise();
      }

      return Promise.resolve(false);
    }

    /**
     * @description: Check if the current user has edit permission on setting page
     * @return: Observable of true when having permission to edit otherwise return observable of false
     */
    public get canEditSettingPage(): Observable<boolean> {
      return this._getStateAndEmail().pipe(
        map(res => {
          const { state, email } = res;
          return this._canUserAccessOnAccount(state, email);
        })
      );
    }

    private _getStateAndEmail(): Observable<{ state: string; email: string }> {
      return this._zcwAuthService.userProfile$.pipe(
        map(res => ({ state: res.state, email: res.email })));
    }

    private _canAccess(state: string, email: string ): boolean {
      if (state && email) {
        return this._isEmailSubStringOfWhiteList(email, ACCESS_DOMAINS_WHITELIST);
      }
      return false;
    }

    private _canUserAccessOnAccount(state: string, email: string ): boolean {
      const { whitelist, isDefault } = this._getAccountInfoByState(state);

      if (state && email) {
        if (isDefault) {
          return DEFAULT_EDIT_PERMISSION;
        }

        return this._isEmailInWhiteList(email, whitelist)
                || !!email.match(this._zonarRegEx);
      }

      return false;
    }

    private _getAccountInfoByState(state: string): AccountInfo {
      const fallbackValue: AccountInfo = { states: [], whitelist: [], isDefault: true };

      return Object.entries(ACCOUNT_INFO_MAPPER)
        .map(([_, value]) => value) // this line will return a list of account info
        .find(value => value.states.includes(state)) || fallbackValue;
    }

    private _isEmailInWhiteList(email: string, list: string[]): boolean {
      return list.includes(email);
    }

    private _isEmailSubStringOfWhiteList(email: string, list: string[]): boolean {
      return list.some(item => email.indexOf(item) > -1);
    }
}
