<div class="search-filter-container">
  <div class="bar-top"></div>
  <div class="search-filter">
    <div class="search-filter-date" *ngIf="filterList.hasDateFilter">
      <app-date-filter
        [value]="days"
        (valueChange)="setDateRange($event)"
        (applyChanges)="applyFilter($event)"
        (discardChanges)="discardChanges()">
      </app-date-filter>
    </div>

    <!-- Asset Filter -->
    <div class="search-filter-content" *ngIf="filterList.hasAssetFilter">
      <app-search-filter-item
        [isLoading]="!filterOptions?.assetFilter?.enable"
        [inputData]="filterOptions?.assetFilter?.availableOptions"
        [inputSettings]="assetSettings"
        [clickOutSideReloadData]="true"
        (selectChange)="onItemSelect('assetFilter', $event)"
        (filterChange)="onFilterChange('assetFilter')"
        (toggleDropdownEvent)="onFilterChange('assetFilter', $event)">
      </app-search-filter-item>
    </div>

    <!-- Camera Imei Filter -->
    <div class="search-filter-content camera-imei" *ngIf="filterList.hasCameraImeisFilter">
      <app-search-filter-item
        [isLoading]="!filterOptions?.cameraImeisFilter?.enable"
        [inputData]="filterOptions?.cameraImeisFilter?.availableOptions"
        [inputSettings]="cameraImeisSettings"
        [clickOutSideReloadData]="true"
        (selectChange)="onItemSelect('cameraImeisFilter', $event)"
        (filterChange)="onFilterChange('cameraImeisFilter')"
        (toggleDropdownEvent)="onFilterChange('cameraImeisFilter', $event)">
      </app-search-filter-item>
    </div>

    <!-- Duty Type Filter -->
    <div class="search-filter-content duty-type-filter" *ngIf="filterList.hasDutyTypeFilter">
      <app-search-filter-item
        [isLoading]="!filterOptions?.dutyTypeFilter?.enable"
        [inputData]="filterOptions?.dutyTypeFilter?.availableOptions"
        [isAllowSortInputData]="false"
        [inputSettings]="dutyTypeSettings"
        [clickOutSideReloadData]="true"
        (selectChange)="onItemSelect('dutyTypeFilter', $event)"
        (filterChange)="onFilterChange('dutyTypeFilter')"
        (toggleDropdownEvent)="onFilterChange('dutyTypeFilter', $event)">
      </app-search-filter-item>
    </div>

    <!-- Driver Filter -->
    <div class="search-filter-content" *ngIf="filterList.hasDriverFilter">
      <app-search-filter-item
        [isLoading]="!filterOptions?.driverFilter?.enable"
        [inputData]="filterOptions?.driverFilter?.availableOptions"
        [inputSettings]="driverSettings"
        [clickOutSideReloadData]="true"
        (selectChange)="onItemSelect('driverFilter', $event)"
        (filterChange)="onFilterChange('driverFilter')"
        (toggleDropdownEvent)="onFilterChange('driverFilter', $event)">
      </app-search-filter-item>
    </div>

    <!-- Incident Type Filter -->
    <div class="search-filter-content" *ngIf="filterList.hasIncidentTypeFilter">
      <app-search-filter-item
        [isLoading]="!filterOptions?.incidentType"
        [inputData]="filterOptions?.incidentType?.availableOptions"
        [inputSettings]="incidentTypeSettings"
        [clickOutSideReloadData]="true"
        (selectChange)="onItemSelect('incidentType', $event)"
        (filterChange)="onFilterChange('incidentType')"
        (toggleDropdownEvent)="onFilterChange('incidentType', $event)">
      </app-search-filter-item>
    </div>

    <!-- Location Filter -->
    <app-location-filter *ngIf="filterList.hasLocationFilter"
      [reset]="reset"
      [showHeader]="false"
      [homeLocation]="homeLocation"
      (applyChanges)="applyFilter($event)"
      (discardChanges)="discardChanges()"
      [isExportSet]="false"
      [isHomeLocationEditable]="isHomeLocationEditable">
   </app-location-filter>
  </div>
</div>
