export class AddNewAccountParam {
  constructor(public accountcode: string) {}
}

export class UpdateAssetInfoParam {
  constructor(
    public assetID: string,
    public description: string,
    public assetNumber: string,
    public orgID: string,
    public packageName: string,
    public databaseID: string,
    public gpsID: string,
    public creationDate: string,
    public dutyType: string
  ) {}
}
