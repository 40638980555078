<div class="setting-modal-wrap">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <ng-content select="[header]"></ng-content>
    </h4>
    <div class="close-modal-icon" (click)="bsModalRef.hide()"></div>
  </div>
  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
