import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeZone',
})
export class TimeZonePipe implements PipeTransform {
  public transform(value: any, isDaylightSavingTime = false): any {
    const timeZoneValue = Number(value);
    if (!isDaylightSavingTime) { // This is US standard timezones, not timezones during daylight saving time
      if (timeZoneValue > 7 && timeZoneValue <= 8) {  // 8 hours behind UTC
        return 'PST';
      } else if (timeZoneValue > 6 && timeZoneValue <= 7) { // 7 hours behind UTC
        return 'MST';
      } else if (timeZoneValue > 5 && timeZoneValue <= 6) { // 6 hours behind UTC
        return 'CST';
      } else if (timeZoneValue > 4 && timeZoneValue <= 5) { // 5 hours behind UTC
        return 'EST';
      } else {
        return 'others';
      }
    } else {  // This is US timezones during daylight saving time
      if (timeZoneValue > 6 && timeZoneValue <= 7) {  // 7 hours behind UTC
        return 'PDT';
      } else if (timeZoneValue > 5 && timeZoneValue <= 6) { // 6 hours behind UTC
        return 'MDT';
      } else if (timeZoneValue > 4 && timeZoneValue <= 5) { // 5 hours behind UTC
        return 'CDT';
      } else if (timeZoneValue > 3 && timeZoneValue <= 4) { // 4 hours behind UTC
        return 'EDT';
      } else {
        return 'others';
      }
    }
  }
}
