import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-sort-header',
  templateUrl: './table-sort-header.component.html',
  styleUrls: ['./table-sort-header.component.scss'],
})
export class TableSortHeaderComponent implements OnInit{
  @Input() public checkForFleetPermission = false;
  // Name of the attribute on current header
  @Input() public sortOn: string;

  // Attribute which holds the sort order 'asc' or 'desc'
  @Input() public sort = 'asc';
  @Output() public sortChange = new EventEmitter<string>();

  // Attribute on which the sort is applied
  @Input() public sortBy: string;
  @Output() public sortByChange = new EventEmitter<string>();

  @Output() public sortClicked = new EventEmitter<void>();

  @Input() public reverseOrder = false;

  public imgArrowUp = new Image();
  public imgArrowDown = new Image();

  // Check to see if the current attribute is selected
  public get selected() {
    return this.sortBy === this.sortOn;
  }

  // Check if the the order is descending if the current header is the selected one
  // if order is reverse, then reverse the descending order
  public get desc() {
    const order = this.selected && this.sort === 'desc';
    return this.reverseOrder ? !order : order;
  }

  public ngOnInit() {
    // Preload Image when component init to not lose icon error
    this.imgArrowUp.src = 'assets/images/arrow_up_dark.png';
    this.imgArrowDown.src = 'assets/images/arrow_down_dark.png';
  }

  public clicked(e?: Event) {
    if (e) {
      e.stopPropagation();
    }
    // Upon click, change the sort order from ascending to descending and
    // vice versa
    if (this.sortBy === this.sortOn) {
      const sort = this.sort === 'asc' ? 'desc' : 'asc';
      this.sortChange.emit(sort);
    } else {
      // else change sort to ascending and make the current attribute as
      // selected
      this.sortChange.emit('asc');
      this.sortByChange.emit(this.sortOn);
    }

    this.sortClicked.emit();
  }
}
