<div class="paginator">
    <img src={{imgBtnFirstEnabled.src}} *ngIf="first" (click)="firstPage();">
    <img src={{imgBtnFirstDisabled.src}} *ngIf="!first">
    <span class="spacer"></span>
    <img src={{imgBtnPreviousEnabled.src}} *ngIf="previous" (click)="previousPage()">
    <img src={{imgBtnPreviousDisabled.src}} *ngIf="!previous">
    <span class="pages">{{activePage}} {{endText}}</span>
    <img src={{imgBtnNextEnabled.src}} *ngIf="next" (click)="nextPage()">
    <img src={{imgBtnNextDisabled.src}} *ngIf="!next">
    <span class="spacer"></span>
    <img src={{imgBtnLastEnabled.src}} *ngIf="last" (click)="lastPage();">
    <img src={{imgBtnLastDisabled.src}} *ngIf="!last">
</div>
