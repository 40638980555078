import { Injectable } from '@angular/core';
import { ZCFleetService } from '@modules/dashboard3/services/zcfleet.service';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

export type Permission = 'fleet-ridecam-plus' | 'fleet-get';
export type PermissionValidator = () => Observable<boolean>;
export type ValidatorMapper = Record<string, PermissionValidator>;
export type PermissionObservableMapper = Record<string, ReplaySubject<boolean>>;

@Injectable({
  providedIn: 'root',
})
export class PermissionManagerService {

  // The reason why want to use BehaviorSubject is to make sure
  // any subscription to an already updated stream will be relayed
  // to the new subscriber
  private _permissionObsList: PermissionObservableMapper = {};

  constructor(
    private _zcFleetService: ZCFleetService
  ) { }

  /**
   * Return an observable that will emit events for any changes on the permission
   * value of a particular permission.
   *
   * @param permission Target permission
   * @return Observable binded to the target permission
   */
  public getPermissionObs(permission: Permission): Observable<boolean> {
    return this._getPermissionSubject(permission).asObservable();
  }

  /**
   * Request for a checking for the provided permission.
   *
   * @param permission Target permission
   */
  public requestPermissionCheck(permission: Permission): void {
    const validator = this._getValidatorForPermission(permission);
    const emitAuthorization = (isAuthorized) => this._getPermissionSubject(permission).next(isAuthorized);

    validator().subscribe(
      isAuthorized => {
        emitAuthorization(isAuthorized);
      },
      err => {
        console.log(`Error check permission ${permission}`, err);
        emitAuthorization(false);
      }
    );
  }

  private _getPermissionSubject(permission: Permission): ReplaySubject<boolean> {
    if (this._permissionObsList[permission] === undefined) {
      this._permissionObsList[permission] = new ReplaySubject();
    }
    return this._permissionObsList[permission];
  }

  private _getValidatorForPermission(permission: Permission): PermissionValidator {
    const defaultValidator = () => of(false);
    return this._getValidatorMapper()[permission] || defaultValidator;
  }

  private _getValidatorMapper(): ValidatorMapper {
    return {
      'fleet-ridecam-plus': this._checkFleetRideCamPlus.bind(this),
    };
  }

  private _checkFleetRideCamPlus(): Observable<boolean> {
    return this._zcFleetService.isFleetContainsRideCamCamera().pipe(
      map(value => !value)
    );
  }
}
