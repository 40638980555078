import { CLEAR_FILTER_UNASSIGN_CAMERA_KEY } from '@app-core/constants/constants';
import { FlagData } from './../../../../core/services/flag.service';
import {
  Component,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { FlagService } from '@app-core/services/flag.service';

@Component({
  selector: 'app-unassigned-cameras-search',
  templateUrl: './unassigned-cameras-search.component.html',
  styleUrls: ['./unassigned-cameras-search.component.scss'],
})
export class UnassignedCamerasSearchComponent implements OnInit {
  @Output()
  public selectChange = new EventEmitter<any>();

  public searchKey = '';
  public selectedName = '';
  public isFocusClicked = false;
  public previousSearchKey = '';

  constructor(
    private _clearFlagService: FlagService
  ) {
    this._clearFlagService.currentClearFlag.subscribe((flagData: FlagData) =>{
      if (flagData && flagData.flag && flagData.key === CLEAR_FILTER_UNASSIGN_CAMERA_KEY) {
        this.cleanInput();
      }
    });
  }

  public ngOnInit() {
  }

  // On focus, set focus index to 0 and empty the search result array
  public onFocus() {
    this.isFocusClicked = true;
  }

  // On enter, pick the element from the current focus index
  public onEnter() {
    this.onSelect(this.searchKey);
  }

  // Set the input with the input text
  public onSelect(item) {
    this.previousSearchKey = item;
    this.selectChange.emit(item);
  }

  /**
   * @description: check whether the click has been inside the dropdown body or not
   * @param: clickinside boolean value
   */
  public clickedInside(clickedInside) {
    // The search executes after click out of the field.
    if (!clickedInside && this.isFocusClicked && this.previousSearchKey !== this.searchKey) {
      this.onSelect(this.searchKey);
    }

    // Refresh isEnterClicked and isFocusClicked flag refresh after click out of the field.
    if (!clickedInside) {
      this.isFocusClicked = false;
    }
  }

  public cleanInput() {
    this.searchKey = '';
    this.onSelect(this.searchKey);
  }
}
