import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  public transform(dateString: string): string {
    if (dateString) {
      const [datePart, timePart] = dateString.split('T');

      const [year, month, day] = datePart.split('-');
      const [hour, minute] = timePart.split(':');

      return `${month}/${day}/${year} ${hour}:${minute}`;
    }
  }
}
