<div [ngSwitch]="show" class="card-margin" data-test="saved-incidents-card">
  <div>
    <!-- incident response center layout -->
    <app-card
      [sortList]="sortList"
      [showSortList]="showOption"
      [updateSortList]="syncSortCard"
      [menuOptionTemplate]="menuOption"
      (changeSortBy)="notifySortChange($event)"
    >
      <div card-title>Saved Incidents</div>
      <div card-body *ngSwitchCase="'loading'">
        <app-incident-media-table-wrapper
          [dataSource$]="incidentListTable$"
          [reload$]="reload$"
          [colDescriptions]="colDescriptions"
          [showSmallSizeMediaPanel]="true"
          [sortOrderChange$]="reloadSort$"
        ></app-incident-media-table-wrapper>
      </div>
      <div card-body *ngSwitchCase="'data'">
        <app-incident-media-table-wrapper
          [dataSource$]="incidentListTable$"
          [sortOrderChange$]="notifySortChange$"
          [reload$]="reload$"
          [colDescriptions]="colDescriptions"
          [customTemplatesList]="savedIncidentCustomTemplates"
          [customTemplates]="{
            action: actionButtons
          }"
          [getUniqueKey]="getRowDataIdentifier"
          [isDisabledAction]="isDisabledAction"
          [incidentData]="incidentData"
          [selectedIncident]="selectedIncident"
          [showSmallSizeMediaPanel]="true"
          [switch]="switch"
          (rowSelected)="setMedia($event)"
          (pageChange)="updatePage($event)"
          (sortChange)="sortChangeInternal($event)"
          (challengeUpdated)="updateIncidentList($event)"
          (isDisabledClickEvent)="isDisabledClick($event)"
        ></app-incident-media-table-wrapper>
      </div>
    </app-card>
  </div>
</div>

<!-- TO-DO: This template needs component methods to handle events-->
<!-- buttons -->
<ng-template #actionButtons let-element="element" let-index="index">
  <app-comment-button-group 
    [ngClass]="{'disabled-button': isDisabledAction}"
    [bookmarkInfo]="element"
    [isMomentObjCreated]="true"
    [showSpinner]="false"
    (tripMetadataChange)="updateTable($event)"
    (violationIdentifierChange)="violationIdentifier = $event.violationIdentifier"
  >
  </app-comment-button-group>
</ng-template>

<!-- menu option-->
<ng-template #menuOption>
  <app-menu-option
    [options]="['PDF', 'CSV']"
    [showMeuOption]="showOption"
    (clickOption)="downloadFile($event)"
  ></app-menu-option>
</ng-template>
